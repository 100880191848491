import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import Amount from '../../../shared/components/Amount/Amount';
import Button from '../../../shared/components/Button';
import InfoBar from '../../../shared/components/InfoBar/InfoBar';
import InfoBarItem from '../../../shared/components/InfoBar/InfoBarItem';
import Label from '../../../shared/components/Label';
import { InfoBarCredit, ButtonContainer } from './styles';

import { parseAmount, splitAmount } from '../../../../helpers';

const initialState = {
  sign: '$',
  integer: '0',
  decimals: '00'
};

const CreditDetails = ({ openModal }) => {
  const [creditLimit, setCreditLimit] = useState({});
  const [availableCredit, setAvailableCredit] = useState({});
  const [progressBar, setProgressBar] = useState(0);
  const [amountToPay_, setAmountToPay_] = useState(initialState);

  const available = useSelector(
    state => state.companyReducer.companySummary.available_credit
  );

  const creditLine = useSelector(
    state => state.companyReducer.companySummary.credit_line
  );

  const companySummary = useSelector(
    state => state.companyReducer.companySummary
  );

  const amountToPay = companySummary.amount_to_pay;

  const getProgressBar = (creditLine, available) => {
    let creditAmount = Number(creditLine.replace(/[^0-9.]+/g, ''));
    let availableCredit = Number(available.replace(/[^0-9.]+/g, ''));

    let progress = (availableCredit * 100) / creditAmount;
    setProgressBar(progress);
  };

  const updateState = (value, method) => {
    if (!value) return;
    method(splitAmount(parseAmount(value)));
  };

  useEffect(() => {
    setCreditLimit(splitAmount(parseAmount(creditLine)));
    setAvailableCredit(splitAmount(parseAmount(available)));
    if (amountToPay) updateState(amountToPay, setAmountToPay_);
  }, [creditLine, available, amountToPay]);

  useEffect(() => {
    getProgressBar(creditLine, available);
    // eslint-disable-next-line
  }, [amountToPay, creditLine]);

  const openIntercom = () => {
    window.Intercom(
      'showNewMessage',
      'Me gustaría solicitar un incremento en mi línea de crédito'
    );
  };

  return (
    <InfoBarCredit>
      <InfoBar
        data-test="creditDetails"
        className="creditInfoBar"
        start={
          <InfoBarItem data-test="contentStart" style={{ paddingRight: 0 }}>
            <Label text="POR PAGAR" />
            {creditLimit.integer && (
              <Amount
                integer={`${amountToPay_.sign}  ${amountToPay_.integer}`}
                decimals={amountToPay_.decimals}
              />
            )}
          </InfoBarItem>
        }
        middle={
          <>
            <div className="smallContainter">
              <InfoBarItem
                data-test="contentStart"
                style={{ paddingRight: 0 }}
                className="creditDetailsSmall"
              >
                <Label text="POR PAGAR" />
                {creditLimit.integer && (
                  <Amount
                    integer={`${amountToPay_.sign}  ${amountToPay_.integer}`}
                    decimals={amountToPay_.decimals}
                  />
                )}
              </InfoBarItem>
              <InfoBarItem
                data-test="contentMiddle"
                style={{ paddingRight: 0 }}
              >
                <Label text="FECHA LÍMITE DE PAGO" />
                <span className="paymentDeadline">
                  {companySummary.payment_deadline}
                </span>
              </InfoBarItem>
            </div>

            <ButtonContainer>
              <Button
                data-test="payButton"
                text="Realizar pago"
                onClick={openModal}
                className="creditDetailsSmall"
              />
              <Button
                data-test="increaseLimitButton"
                text="Pedir incremento de crédito"
                onClick={openIntercom}
                className="creditDetailsSmall"
              />
            </ButtonContainer>
          </>
        }
        button={
          <>
            <Button
              data-test="payButton"
              text="Realizar pago"
              onClick={openModal}
            />
            <Button
              data-test="increaseLimitButton"
              text="Pedir incremento de crédito"
              onClick={openIntercom}
            />
          </>
        }
      />
    </InfoBarCredit>
  );
};

export default CreditDetails;
