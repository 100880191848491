/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import { ButtonContainer } from './IconButton.styles';

const IconButton = ({ icon, image, className, onClick }) => {
  return (
    <ButtonContainer
      data-test="buttonContainer"
      className={className}
      onClick={onClick}
    >
      <div data-test="iconContainer">
        {icon ? <Icon data-test="icon" icon={icon} /> : null}
        {image ? (
          <img data-test="image" src={image} width="1.4rem" alt="icon" />
        ) : null}
      </div>
    </ButtonContainer>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default IconButton;
