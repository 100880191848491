import { customAxiosInstance } from './axiosConfig';

const basePath = 'api/dashboard';

const activationCodePath = `${basePath}/activation-code`;
const cardActivationPath = `${basePath}/card-activation`;

export const activateCard = async body => {
  return customAxiosInstance().post(cardActivationPath, body);
};

export const requestActivationCode = async body => {
  return customAxiosInstance().post(activationCodePath, body);
};
