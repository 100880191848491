import { customAxiosInstance } from '../../../Services/axiosConfig';
import log from 'loglevel';
import { approveCardChangePath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const approveCardChangeRequestService = async ({
  cardId,
  cardRequestId
}) => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      `${approveCardChangePath}${cardRequestId}`
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'approve-card-change-service'
    });
  } catch (error) {
    log.error('approve-card-change-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
