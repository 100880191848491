const getAddressStatesHandler = ({ getAddressStates }) => async () => {
  const { error, data: addressStatesData } = await getAddressStates();

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  const addressStates = addressStatesData.map(({ state_id, state_name }) => ({
    stateId: state_id,
    stateName: state_name
  }));

  return {
    isSuccess: true,
    addressStates
  };
};

export { getAddressStatesHandler };
