import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';
import { getBillingCyclesPath } from './api-paths';

export const getBillingCyclesService = async () => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      `${getBillingCyclesPath}`
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'account-service'
    });
  } catch (error) {
    log.error('account-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
