import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import UserTypeInfo from './UserTypeInfo';

const useStyles = makeStyles({
  MuiAccordianRoot: {
    backgroundColor: '#F4FBFF',
    boxShadow: 'none',
    position: 'inherit',
    margin: '20px -24px !important'
  },
  MuiTypoRoot: {
    fontFamily: 'Quicksand',
    fontWeight: 600,
    fontSize: '16px',
    color: '#000000'
  },
  Icon: {
    fontFamily: 'Quicksand',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '15px',
    color: '#939ca9',
    transform: 'none !important'
  }
});

const MobileUserTypeInfoDisplay = () => {
  const [expanded, setExpanded] = useState('closed');
  const classes = useStyles();

  const handleChange = state => (event, newExpanded) => {
    setExpanded(newExpanded ? state : false);
  };

  return (
    <Accordion
      expanded={expanded === 'open'}
      onChange={handleChange('open')}
      classes={{
        root: classes.MuiAccordianRoot
      }}
    >
      <AccordionSummary
        expandIcon={expanded === 'open' ? 'Hide' : 'Show'}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          expandIcon: classes.Icon,
          expanded: classes.Icon
        }}
      >
        <Typography
          classes={{
            root: classes.MuiTypoRoot
          }}
        >
          {expanded === 'open'
            ? 'Tipo de usuario'
            : 'Tipos de usuario y límites de gasto'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <UserTypeInfo showHeader={false} />
      </AccordionDetails>
    </Accordion>
  );
};

export default MobileUserTypeInfoDisplay;
