import { addSpeiTagAction } from '../redux/tag-action';

const createSpeiTagHandler = ({ dispatch, createSpeiTag }) => async (
  speiTag = {},
  saveOnRedux = true
) => {
  const { error } = await createSpeiTag(speiTag);

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  if (saveOnRedux) dispatch(addSpeiTagAction({ tag: speiTag }));
  return { isSuccess: true };
};

export { createSpeiTagHandler };
