// React
import React, { useEffect } from 'react';

// Auxiliar libraries
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

// Own code
import Nabvar from '../../components/Shared/OnBoardingNavbar/OnBoardingNavbar';
import { PURGE } from '../../reducers/types';
import RedButton from '../../components/Shared/RedBorderButton/RedBorderButton';

// Assets
import Image_Confirm from '../../assets/message_sent .svg';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  imageSize: {
    width: '9.375rem',
    height: '2.8125rem'
  },
  buttonPos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'ceter'
  },
  buttonWrapper: {
    fontFamily: 'Barlow',
    display: 'flex',
    justifyContent: 'center'
  },
  textSize: {
    fontSize: '1.4rem'
  }
}));

const ConfirmAccount = ({ history }) => {
  const dispatch = useDispatch();

  const { email } = history.location.state
    ? history.location.state
    : { email: null };

  // Redux
  const userSelector = useSelector(state => state.userInfoReducer.user);
  const userSelectorEmail = userSelector.email
    ? userSelector.email
    : 'correo electrónico';

  // Hooks
  const [userEmail] = useState(email ? email : userSelectorEmail);
  const [userStatus] = useState(
    userSelector.status ? userSelector.status : null
  );

  useEffect(() => {
    if (userStatus === 'Account not validated' || email) {
      dispatch({ type: PURGE });
    } else {
      history.push('/');
    }
    // eslint-disable-next-line
  }, [email, userStatus]);

  const goToHome = () => {
    history.push('/');
    dispatch({ type: PURGE });
  };

  const classes = useStyles();
  return (
    <div>
      <Nabvar />
      <Grid container>
        <Grid xs />
        <Grid item xs={6} className={classes.buttonPos}>
          <img src={Image_Confirm} alt="" />
          <Container>
            <h1>Confirma tu cuenta</h1>
            <p className={classes.textSize}>
              Te hemos enviado un correo a <span>{`${userEmail}`}</span>, accede
              a él y da click en el botón para que puedas continuar con tu
              proceso de registro.
            </p>
            <div className={classes.buttonWrapper}>
              <RedButton copy="Salir" onClick={goToHome}></RedButton>
            </div>
          </Container>
        </Grid>
        <Grid xs />
      </Grid>
    </div>
  );
};

export default ConfirmAccount;
