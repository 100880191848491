import React from 'react';
import PropTypes from 'prop-types';

import { StyledItem } from './styles';

const Item = ({ text, value, onClick }) => {
  return (
    <StyledItem value={value} onClick={onClick}>
      {text}
    </StyledItem>
  );
};

Item.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default Item;
