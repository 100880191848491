import log from 'loglevel';
import { softwallPath } from '../../../constants';
import { states } from '../../state-machine/state-machine-definition';
import enterpriseActionsManager from '../enterprise-actions-manager';
import { updateStatusHandler } from './update-enterprise-status-handler';

const getCompletedSteps = historySteps =>
  historySteps.filter(el => Object.keys(states).includes(el));

const redirectAccordingStatus = (completedSteps, latest, redirect) =>
  (completedSteps.length === Object.keys(states).length
    ? redirect.push(softwallPath)
    : redirect.push(enterpriseActionsManager[latest].redirect));

const validateOnboardingSteps = async (redirect, state) => {
  const res = await updateStatusHandler(state);
  const {
    onboarding_progress: { history, latest }
  } = res[0];

  const completedSteps = getCompletedSteps(history);
  redirectAccordingStatus(completedSteps, latest, redirect);
};

const getFormProcessHandler = ({ getFormProcess }) => async (
  dispatch,
  history,
  goToNextStep,
  state,
  data
) => {
  if (!getFormProcess && !enterpriseActionsManager[state].isServiceRequired) {
    log.info('No form process required');
    goToNextStep();
    validateOnboardingSteps(history, state);
    return;
  }

  const { error } = await getFormProcess(data);
  if (error) return;

  if (enterpriseActionsManager[state].action)
    await dispatch(enterpriseActionsManager[state].action(data));
  goToNextStep();
  validateOnboardingSteps(history, state);
};

export { getFormProcessHandler };
