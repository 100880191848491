/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

// Auxiliar libraries
import log from 'loglevel';
import PropTypes from 'prop-types';

// Components
import InformationModal from '../../../shared/components/ModalInformation';
import TextField from '../../../shared/components/TextField/TextField';
import TextFieldAmount from '../../../shared/components/TextFieldAmount/TextFieldAmount';

const ModalCardChanges = ({
  handleSubmit,
  handleModalClose,
  initialValues
}) => {
  log.debug('modal card changes - initial values', initialValues);

  return (
    <InformationModal
      title="Aprobar tarjeta"
      primaryButtonText="Aprobar"
      onPrimaryButtonClick={handleSubmit}
      secondaryButtonText="Declinar"
      onSecondaryButtonClick={handleModalClose}
    >
      <TextField
        data-test="creditCardName"
        className="formField"
        label="Nombre"
        name="creditCardName"
        value={initialValues.creditCardName}
        disabled
      />
      <TextFieldAmount
        data-test="spendLimit"
        className="formField"
        label="Límite de gasto"
        name="spendLimit"
        value={initialValues.spendLimit}
        disabled
      />
      <TextField
        data-test="ownerName"
        className="formField"
        label="Dueño de la tarjeta"
        name="ownerName"
        value={initialValues.ownerName}
        disabled
      />
      <TextField
        data-test="cardType"
        className="formField"
        label="Tipo del tarjeta"
        name="cardType"
        value={initialValues.isVirtual ? 'Virtual' : 'Física'}
        disabled
      />
    </InformationModal>
  );
};

ModalCardChanges.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default ModalCardChanges;
