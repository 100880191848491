import React from 'react';

import { useFormik } from 'formik';

import TextField from '../../../shared/components/TextField';
import OnboardingErrorBoundary from '../OnboardingErrorBoundary/OnboardingErrorBoundary';
import OnboardingLayout from '../../components/OnboardingLayout/OnboardingLayout';
import SearchableSelect from '../../components/SearchableSelect/SearchableSelect';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';

import DateFnsUtils from '@date-io/date-fns/build/';
import { es } from 'date-fns/locale';

import { ThemeProvider, useTheme } from '@material-ui/core';

import { RadioButtonsContainer } from './styles';

import { convertDate } from '../../helpers/datepicker-helpers';
import countriesCatalog from '../../../../catalogs/catalog_countries.json';
import nationalities from '../../../../catalogs/nationalities.json';
import setInputProps from '../../helpers/set-input-props';
import { validationSchema } from './validationSchema';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const Component = ({
  userId,
  activeStep,
  steps,
  goToNextStep,
  userName,
  storedData
}) => {
  const initialValues = storedData;
  initialValues.userId = userId;
  initialValues.birthDate = storedData.birthDate
    ? convertDate(storedData.birthDate, '-', '/')
    : '';

  const theme = useTheme();

  const formik = useFormik({
    initialValues,
    validationSchema
  });

  const parseDate = date => {
    const fullYear = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${day}-${month}-${fullYear}`;
  };

  const handleGoNext = () => {
    const data = formik.values;
    data.birthDate = parseDate(formik.values.birthDate);
    goToNextStep(data);
  };

  const toInputUppercase = e => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  return (
    <OnboardingLayout
      title={`Datos personales de ${userName}`}
      description="Completa la siguiente información para continuar."
      onPrimaryButtonClick={handleGoNext}
      primaryButtonText="Continuar"
      isPrimaryButtonDisabled={!formik.isValid || !formik.dirty}
      isModalVisible
      steps={steps}
      activeStep={activeStep}
    >
      <RadioButtonsContainer>
        <legend>Sexo (como aparece en tu INE)</legend>
        <div className="inputsContainer">
          <label htmlFor="male">
            <input
              type="radio"
              value="Hombre"
              name="gender"
              onChange={formik.handleChange}
            />
            Hombre
          </label>
          <label htmlFor="female">
            <input
              type="radio"
              value="Mujer"
              name="gender"
              checked={formik.values.gender === 'Mujer'}
              onChange={formik.handleChange}
            />
            Mujer
          </label>
        </div>
      </RadioButtonsContainer>

      <ThemeProvider
        theme={theme => ({
          ...theme,
          overrides: {
            ...theme.overrides,
            MuiFormControl: {
              marginNormal: {},
              root: {}
            },
            MuiInputLabel: {
              root: {
                fontSize: '1.7rem'
              }
            }
          }
        })}
      >
        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
          <DatePicker
            disableFuture
            id="date-picker-dialog"
            openTo="year"
            views={['year', 'month', 'date']}
            label="Fecha de nacimiento"
            inputVariant="outlined"
            fullWidth
            format="dd/MM/yyyy"
            onBlur={formik.handleBlur}
            error={formik.errors.birthDate}
            helperText={formik.errors.birthDate}
            onChange={date => {
              formik.setFieldValue('birthDate', date);
            }}
            value={formik.values.birthDate}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>

      <SearchableSelect
        name="countryOfBirth"
        label="País de nacimiento"
        options={countriesCatalog}
        formik={formik}
      />

      <SearchableSelect
        name="nationality"
        label="Nacionalidad"
        options={nationalities.nationalities}
        formik={formik}
      />

      <TextField
        {...setInputProps(formik, formik.handleChange, 'CURP', 'curp', 'text')}
        onInput={toInputUppercase}
      />
      <TextField
        {...setInputProps(formik, formik.handleChange, 'RFC', 'rfc', 'text')}
        onInput={toInputUppercase}
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Ocupación',
          'occupation',
          'text'
        )}
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Número de serie de Firma Electrónica Avanzada',
          'serialNumberFiel',
          'text'
        )}
        endAdornment={
          <Tooltip
            className="tooltip"
            label="Número de serie de la Firma Electrónica Avanzada"
            text="Son 20 dígitos que puedes encontrar en tu Constancia de Firma Electrónica Avanzada ó en el comprobante de Certificado de Sello Digital."
          />
        }
      />
    </OnboardingLayout>
  );
};

const FreelancerBasicInfo = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="FreelancerBasicInfo"
      props={props}
    />
  );
};

export default FreelancerBasicInfo;
