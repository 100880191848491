import styled from 'styled-components';

export const MobileButtonContainer = styled.div`
  margin: 2em;
  text-align: center;

  @media screen and (min-width: 769px) {
    display: none;
  }
`;
