/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { Helper, TooltipContainer, ChildHover } from './TooltipHelper.styles';
import Icon from '../Icon/Icon';

const TooltipHelper = ({ children, helper, icon }) => {
  return (
    <TooltipContainer>
      <ChildHover>{children}</ChildHover>
      {helper ? (
        <Helper data-test="helperText" className="helperText">
          {icon ? <Icon icon={icon} /> : null}
          {helper}
        </Helper>
      ) : null}
    </TooltipContainer>
  );
};

TooltipHelper.propTypes = {
  children: PropTypes.object,
  helper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string
};

export default TooltipHelper;
