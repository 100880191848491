const buroSubmitHandler = ({ buroSubmit }) => async ({ uuid, nip }) => {

  const { error, data } = await buroSubmit({
    uuid,
    nip
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  let isSuccess = true;
  if(data?.response === "BAD"){
    isSuccess = false;
  }

  return { isSuccess, data };
};

export { buroSubmitHandler };
