import styled from 'styled-components';

import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const EntryStatementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 69.4rem;
  height: 7rem;
  border-radius: 1.2rem;
  padding: 0 3.2rem;

  .download {
    visibility: hidden;
  }

  .download path {
    fill: ${colors.sense1};
  }

  &:hover {
    box-shadow: 0 11px 22px -8px #e5e8ed;
    border: solid 1px #1790cf;
    background-color: white;

    .download {
      visibility: visible;
      cursor: pointer;
    }
  }
`;
