import React, { Fragment } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Formik } from 'formik';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { currentOnboardingStepPersistedData } from '../../../selectors';

import Form from './FormContent';
import { MainTitle } from '../../Shared';
import { validationSchema } from './validator';

import { useStyles } from './FormStyles';
import { Severity, captureEvent } from '@sentry/browser';

const values = {
  mercantilBusiness: '',
  incorporationDate: moment().format('YYYY-MM-DD'),
  email: '',
  phoneNumber: '',
  rfc: '',
  serial_number_fiel: '',
  nationality: false
};

const Component = props => {
  const classes = useStyles();
  const userInfoState = useSelector(
    state => state.userInfoReducer.user.company_information
  );
  const persistedFormData = useSelector(currentOnboardingStepPersistedData);

  return (
    <Fragment>
      {userInfoState ? (
        <div>
          <div className={classes.header}>
            {userInfoState.payer_name ? (
              <MainTitle text={`Datos de ${userInfoState.payer_name}`} />
            ) : (
              <MainTitle text="Datos de la compañía"></MainTitle>
            )}
            <p className={classes.instructions}>
              Completa la siguiente información para continuar:
            </p>
          </div>
          <div className={classes.formContainer}>
            <Formik
              onSubmit={props.submit}
              className={classes.form}
              initialValues={persistedFormData || values}
              validationSchema={validationSchema}
              isInitialValid={validationSchema.isValidSync(values)}
            >
              {props => <Form {...props} />}
            </Formik>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

const FormStep1 = props => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        captureEvent({
          level: Severity.Warning,
          message: `errorBoundary: ${error.message}`,
          extra: {
            component: 'FormStep1',
            errorBoundary: true,
            errorName: error.name,
            props: props
          }
        });
        return <></>;
      }}
    >
      <Component {...props} />
    </ErrorBoundary>
  );
};

export default FormStep1;
