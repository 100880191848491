import React from 'react';
import { MainTitle } from '../../components/Shared';
import { makeStyles } from '@material-ui/core/styles';
import image from '../../assets/message_sent .svg';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Barlow',
    fontSize: '4.5rem',
    color: '#323132',
    margin: '0'
  },
  text: {
    color: '#323132',
    fontSize: '1.4rem'
  },
  instructions: {
    margin: '2rem 0'
  },
  image: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const Success = ({ companyTypeId }) => {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item xs={12} md={10}>
        <img className={classes.image} src={image} alt="success" />
        <MainTitle
          className={classes.root}
          text="¡Has completado tu registro! Estamos validando tus documentos, en breve te tendremos una respuesta."
        />
        <p className={`${classes.text} ${classes.instructions}`}>
          En tu videollamada, recuerda tener a la mano estos documentos:
        </p>
        <ul>
          {companyTypeId === 1 ? (
            <>
              <li className={classes.text}>Acta Constitutiva</li>
              <li className={classes.text}>
                Acta donde te nombren como apoderado legal
              </li>
              <li className={classes.text}>
                Tu INE como apoderado legal de la empresa
              </li>
              <li className={classes.text}>
                Boleta de inscripción en el Registro Público de Comercio
              </li>
            </>
          ) : (
            <li className={classes.text}>Tu INE</li>
          )}

          <li className={classes.text}>Cédula de Identificación Fiscal</li>
          <li className={classes.text}>
            Constancia de la Firma Electrónica Avanzada
          </li>
          <li className={classes.text}>Comprobante de domicilio</li>
          <li className={classes.text}>Estado de cuenta bancario</li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default Success;
