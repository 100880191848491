// React imports
import React, { Fragment } from 'react';
// Auxiliary librarys imports
import { Card } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
// Own Components
import CustomTooltip from '../CustomTooltip';
// Assets
import uploaded from '../../assets/ok.svg';
import uploadIcon from '../../assets/less.svg';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '1rem',
    paddingBottom: '1rem'
  },
  cardsContainer: {
    margin: '0 auto'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'spaceAround',
    width: '100%',
    padding: '0 0.3rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  contentAlign: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  errorText: {
    color: 'red'
  },
  fileName: {
    height: '2.4rem',
    fontSize: '1rem',
    color: '#989898',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fontNormal: {
    fontFamily: 'Barlow',
    width: '70%',
    fontSize: '1.2rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.6rem'
    },
    padding: '0.5rem'
  },
  fontSuccess: {
    color: '#22c70c'
  },
  height: {
    height: '9.5rem',
    boxSizing: 'border-box'
  },
  tooltipSize: {
    width: '2rem'
  },
  hidden: {
    display: 'none'
  }
}));

const ClickableCard = ({
  cardText,
  error,
  file,
  isUploaded,
  onClick,
  tooltip
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Card
        className={`${classes.height} ${classes.cardContent}`}
        onClick={onClick}
      >
        <div className={classes.contentAlign}>
          <div className={classes.cardContent}>
            <img src={!isUploaded ? uploadIcon : uploaded} alt="upload" />
            <span
              className={`${classes.fontNormal} ${
                !isUploaded ? '' : classes.fontSuccess
              } ${classes.filesAlignment}`}
            >
              {cardText}
              {file && (
                <p
                  className={`${error ? classes.errorText : classes.fileName}`}
                >
                  {file}
                </p>
              )}
            </span>
            {tooltip && (
              <CustomTooltip
                title={tooltip}
                tooltipSize={classes.tooltipSize}
              />
            )}
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

export default ClickableCard;
