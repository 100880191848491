import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  btnPrimary: {
    width: '16.8rem',
    height: '4.8rem',
    [theme.breakpoints.down('sm')]: {
      width: '13rem',
      fontSize: '1.3rem'
    },
    textTransform: 'none',
    borderRadius: '2.4rem',
    fontSize: '1.6rem'
  }
}));

const PrimaryButton = ({
  id,
  type,
  className,
  disabled,
  onClick,
  dataTest,
  text
}) => {
  const classes = useStyles();
  return (
    <Button
      id={id}
      type={type}
      variant="contained"
      color="secondary"
      className={className || classes.btnPrimary}
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest || 'PrimaryButton'}
    >
      {text}
    </Button>
  );
};

export default PrimaryButton;
