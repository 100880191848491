import * as yup from 'yup';

const requiredFieldMessage = 'Campo requerido';
const cardPinPattern = /^[0-9]{4}$/;

export const validationSchema = yup.object({
  cardPin: yup
    .string()
    .max(4, 'Máximo 4 caracteres')
    .matches(new RegExp(cardPinPattern), {
      message: 'El PIN debe ser un valor numérico de cuatro dígitos'
    })
    .required(requiredFieldMessage),
  confirmCardPin: yup
    .string()
    .max(4, 'Máximo 4 caracteres')
    .matches(new RegExp(cardPinPattern), {
      message: 'El PIN debe ser un valor numérico de cuatro dígitos'
    })
    .required(requiredFieldMessage)
    .oneOf([yup.ref('cardPin'), null], 'El PIN debe coincidir')
});
