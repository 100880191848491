import React, { createContext, useState } from 'react';
import log from 'loglevel';

export const RfcContext = createContext();

export const RfcProvider = ({ children }) => {
  const [rfc, setRfc] = useState('');
  log.info('rfc:', rfc)
  return (
    <RfcContext.Provider value={{ rfc, setRfc }}>
      {children}
    </RfcContext.Provider>
  );
};
