import styled from 'styled-components';
import { colors, fonts } from '../../Dashboard4.0/assets/styles/styles';

export const Code = styled.pre`
  background: #f0f0f0;
  font-size: 1.55rem;
  padding: 1rem;
  margin-left: 3rem;
  tab-size: 4;
  line-height: 1.3em;
  overflow: auto;
`;

export const InlineCode = styled.code`
  background: #f0f0f0;
  font-size: 1.55rem;
  padding: 1rem;
  margin-left: 3rem;
  tab-size: 4;
  margin-top: 2rem;
`;

export const Language = styled.pre`
  font-size: 1.55rem;
  display: block;
  margin-left: 2.5rem;
  ${fonts.barlowSemiBold}
`;

export const Paragraph = styled.p`
  ${fonts.barlowRegular};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 2.5rem;
  color: rgb(27, 35, 45);
  font-size: 1.65rem;
  line-height: 2.5rem;
  letter-spacing: 0.05rem;
`;

export const Title = styled.h1`
  ${fonts.quickSandMedium};
  letter-spacing: 0.21px;
  padding: 3rem 0 2rem;
  color: #909090;
  font-size: 1.7rem;
  text-transform: uppercase;
`;

export const SectionTitle = styled.h2`
  letter-spacing: 0.21px;
  padding-top: 1rem;
  padding-bottom: 0.4rem;
  ${fonts.barlowSemiBold};
  color: ${colors.body};
  font-size: 2rem;
  margin: 0;
`;

export const ParagraphTitle = styled.h3`
  ${fonts.quickSandSemiBold};
  font-size: 1.8rem;
  letter-spacing: 0.21px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  color: ${colors.body};
`;

export const Table = styled.table`
  ${fonts.quickSandMedium};
  margin: 2rem 0 3.5rem 3rem;
  border-collapse: collapse;
  color: rgb(27, 35, 45);
  font-size: 1.55rem;
  text-align: left;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid rgb(227, 227, 227);
`;

export const Th = styled.th`
  padding-bottom: 1rem;
  padding-right: 2rem;
`;

export const Td = styled.td`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 2.5rem;
`;

export const Container = styled.div`
  height: 100vh;
  overflow: scroll;
  padding-left: 2rem;
  padding-right: 2rem;
  flex: 1;

  @media print {
    height: auto;
  }

  @page {
    size: landscape;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .overflow {
    overflow: auto;

    ::-webkit-scrollbar-thumb {
      background: gray;
    }

    ::-webkit-scrollbar {
      width: 5px;
    }
  }
`;
