import React, { useState } from 'react';

import WaitingRedLines from '../../../../components/WaitingRedLines/WaitingRedLines';
import ExcelRenderer from '../ExcelRenderer';
import Icon from '../../../shared/components/Icon/Icon';

import { ReactComponent as Success } from '../../../../Dashboard4.0/assets/images/transfer-check.svg';
import { ReactComponent as Error } from '../../../../Dashboard4.0/assets/images/transfer-remove.svg';
import { ReactComponent as Warning } from '../../../../Dashboard4.0/assets/images/transfer-warning.svg';
import { ReactComponent as Expand } from '../../../../Dashboard4.0/assets/images/expand.svg';
import { ReactComponent as Plus } from '../../../../Dashboard4.0/assets/images/plus.svg';

import {
  AccordionContainer,
  AccordionItem,
  AccordionHeader,
  AccordionTitle,
  AccordionBody,
  IconExpand,
  TooltipContainer
} from './BatchTransfer.styles';

const Accordion = ({ transactions, rows, ready }) => {
  const initialValues = {
    accordionSuccess: false,
    accordionDuplicates: false,
    accordionErrors: false,
    accordionNewsBeneficiaries: false
  };
  const [accordionItem, setAccordionItem] = useState(initialValues);

  const expandTable = index => {
    setAccordionItem({ [index]: !accordionItem[index] });
  };

  return (
    <AccordionContainer>
      {transactions.success.length > 1 && (
        <AccordionItem>
          <AccordionHeader
            type="success"
            onClick={() => expandTable('accordionSuccess')}
          >
            <Success />
            <AccordionTitle>
              <span>
                {transactions.success.length - 1} de {rows.length - 1}{' '}
                transferencias{' '}
              </span>
              fueron detectadas correctamente
            </AccordionTitle>
            <IconExpand active={accordionItem.accordionSuccess}>
              <Expand />
            </IconExpand>
          </AccordionHeader>
          {accordionItem.accordionSuccess && (
            <AccordionBody data-test="FileLoader">
              {ready ? (
                <ExcelRenderer rows={transactions.success} sm />
              ) : (
                <WaitingRedLines />
              )}
            </AccordionBody>
          )}
        </AccordionItem>
      )}
      {transactions.duplicates.length > 1 && (
        <AccordionItem>
          <AccordionHeader
            type="warning"
            onClick={() => expandTable('accordionDuplicates')}
          >
            <Warning className="warning" />
            <AccordionTitle>
              <span>
                {transactions.duplicates.length - 1} de {rows.length - 1}{' '}
                transferencias
              </span>{' '}
              están duplicadas. Serán enviadas a menos que subas otro archivo.
            </AccordionTitle>
            <IconExpand active={accordionItem.accordionDuplicates}>
              <Expand />
            </IconExpand>
          </AccordionHeader>
          {accordionItem.accordionDuplicates && (
            <AccordionBody data-test="FileLoader">
              {ready ? (
                <ExcelRenderer rows={transactions.duplicates} />
              ) : (
                <WaitingRedLines />
              )}
            </AccordionBody>
          )}
        </AccordionItem>
      )}
      {transactions.errors.length > 1 && (
        <AccordionItem>
          <AccordionHeader
            type="error"
            onClick={() => expandTable('accordionErrors')}
          >
            <Error />
            <AccordionTitle>
              <span>
                {transactions.errors.length - 1} de {rows.length - 1}{' '}
                transferencias
              </span>{' '}
              no serán agregadas por errores en el archivo.
            </AccordionTitle>
            <IconExpand active={accordionItem.accordionErrors}>
              <Expand />
            </IconExpand>
          </AccordionHeader>
          {accordionItem.accordionErrors && (
            <AccordionBody data-test="FileLoader">
              {ready ? (
                <ExcelRenderer rows={transactions.errors} />
              ) : (
                <WaitingRedLines />
              )}
            </AccordionBody>
          )}
          <TooltipContainer
            onClick={() =>
              window
                .open(process.env.REACT_APP_MULTITRANSFERS_HELP_LINK, '_blank')
                .focus()
            }
          >
            <Icon icon="question" />
          </TooltipContainer>
        </AccordionItem>
      )}
      {transactions.news && transactions.news.length > 1 && (
        <AccordionItem>
          <AccordionHeader
            type="success"
            onClick={() => expandTable('accordionNewsBeneficiaries')}
          >
            <Plus class="plus" />
            <AccordionTitle>
              <span>
                {transactions.news.length - 1}{' '}
                {transactions.news.length > 2
                  ? 'nuevos beneficiarios'
                  : 'nuevo beneficiario'}
              </span>{' '}
              fueron detectados. Los guardaremos
            </AccordionTitle>
            <IconExpand active={accordionItem.accordionNewsBeneficiaries}>
              <Expand />
            </IconExpand>
          </AccordionHeader>
          {accordionItem.accordionNewsBeneficiaries && (
            <AccordionBody data-test="FileLoader">
              {ready ? (
                <ExcelRenderer rows={transactions.news} />
              ) : (
                <WaitingRedLines />
              )}
            </AccordionBody>
          )}
        </AccordionItem>
      )}
    </AccordionContainer>
  );
};

export default Accordion;
