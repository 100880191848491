// React imports
import React, { useContext, useEffect, useState } from 'react';

// Auxiliary librarys imports
import { Grid, Hidden, Modal } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import withWidth from '@material-ui/core/withWidth';

// Own Components
import { Context as BiometricContext } from '../../context/BiometricContext';
import { PrimaryButton, WhiteButton } from '../Shared';
import { convertB64ToFile, getBase64, getFileName } from '../../helpers';

// Assets
import { CustomModalStyles } from './customModalStyles';
import face from './marker.svg';
import IDFront from '../../assets/ine_face.svg';
import IDBack from '../../assets/ine_fingerprint.svg';
import IdVerificationFile from './IdVerificationFile';
import { icons } from '../../Dashboard4.0/assets/images/icons';

const videoConstraints = {
  width: 720,
  height: 480,
  facingMode: 'user'
};

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: '80vh',
    overflow: 'scroll',
    padding: '1rem'
  };
};

const CustomModal = ({
  styledButton: Component,
  disabled,
  goToNext,
  width,
  isRedesign
}) => {
  // Redux
  const company_type_id = useSelector(
    state => state.userInfoReducer.user.company_type_id
  );

  // Context
  const {
    state: { errorMessage, kyc_res },
    KYCPersonDocumentRegister,
    changeKycRes
  } = useContext(BiometricContext);

  // Hooks
  const [imgSrc, setImgSrc] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [ineFront, setIneFront] = useState('');
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [photo, setPhoto] = useState('');
  const [step, setStep] = useState(0);
  const [mirrored, setMirrored] = useState(true);
  const [file, setFile] = useState(null);
  const [resetInput, setResetInput] = useState(false);

  const selfieDocumentID = company_type_id === 2 ? 13 : 14;
  const ineFrontID = company_type_id === 2 ? 1 : 9;
  const ineBackID = company_type_id === 2 ? 11 : 12;

  // Classes
  const classes = CustomModalStyles();

  // Other vars
  const copy = [
    {
      text: (
        <>
          Ahora tómate una selfie, retira cualquier objeto de tu rostro{' '}
          <span className={classes.hideOnMobile}>
            y colócalo dentro de óvalo.
          </span>
        </>
      )
    },
    {
      text:
        'Coloca la parte de enfrente de tu INE para continuar con el registro',
      image: `${isRedesign ? '' : IDFront}`
    },
    {
      text: 'Coloca el reverso de tu INE para continuar con el registro',
      image: `${isRedesign ? '' : IDBack}`
    }
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const webcamRef = React.useRef(null);
  const takePhoto = React.useCallback(() => {
    setMirrored(false);
    setTimeout(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }, process.env.REACT_APP_IMAGE_MIRRORING_TIMEOUT);
  }, [webcamRef]);

  const uploadPhoto = async () => {
    setMirrored(true);
    let image = convertB64ToFile(imgSrc, 'fileName.jpg');

    //If photo is empty it will store the value there
    if (!photo) {
      setPhoto(image);

      uploadDocument('selfie.jpg', image, selfieDocumentID);
      setStep(1);
      setImgSrc('');
    }
    //if there's a photo but no INE front, it will store it there
    if (photo && !ineFront) {
      setIneFront(image);

      uploadDocument('ine_anverso.jpg', image, ineFrontID);

      setStep(2);
      setImgSrc('');
    }
    //If there's a photo and an Ine front, it will store the image
    //in ineBack and send you to the next step
    if (photo && ineFront) {
      uploadDocument('ine_reverso.jpg', image, ineBackID);
      setImgSrc('');
      goToNext();
    }
  };

  const uploadDocument = (fileName, content, documentId) => {
    let document = {
      fileName: fileName,
      file: content,
      kyc: documentId
    };

    KYCPersonDocumentRegister(document);
  };

  const tryAgain = () => {
    setMirrored(true);
    setImgSrc('');
    setResetInput(true);
  };

  const validateFile = async (e, element) => {
    setResetInput(false);
    const file = e.target;
    const fileName = getFileName(file.name, element.keyword);
    const documentId = element.kyc_document_type_id;

    if (!file) {
      setFile(null);
      return;
    }

    const fileToB64 = await getBase64(file.file);

    //Check if file exists
    if (file.file) {
      // If file exists, check if file is a PDF or an Image,
      // If file is a file, display PDF icon, else, display preview of the Image
      let imgSrc =
        file.file.type !== 'application/pdf'
          ? `data:image/jpeg;base64,${fileToB64}`
          : icons.pdfIcon.src;

      // Set ImgSrc
      setImgSrc(imgSrc);
    } else {
      // If no file exits, set ImgSrc as empty
      setImgSrc('');
    }

    setFile({
      fileName: fileName,
      content: file.file,
      type: file?.file?.type,
      kyc: documentId
    });
  };

  const uploadFile = async () => {
    uploadDocument(file.fileName, file.content, file.kyc);

    if (step === 1) {
      const fileToB64 = await getBase64(file.content);
      const imgSrc = fileToB64 ? `data:image/jpeg;base64,${fileToB64}` : '';

      setStep(2);
      setFile(null);
      setResetInput(true);
      setIneFront(imgSrc);
      setImgSrc(imgSrc);
      return;
    }

    goToNext();
  };

  const pictureFrame = (
    <div className={classes.pictureStyles}>
      <div className={`${classes.pictureEdges}`}>
        {step === 1
          ? 'Colocar tu INE en el rectángulo y presiona “Tomar foto”'
          : 'Asegúrate de que puedas leer claramente toda la información. En caso de que no, por favor volver a tomar la foto.'}
      </div>
    </div>
  );

  useEffect(() => {
    changeKycRes(null);
    // eslint-disable-next-line
  }, [kyc_res, errorMessage, photo]);

  return (
    <div>
      <div className={classes.button}>
        {Component ? (
          <Component onClick={handleOpen} />
        ) : (
          <PrimaryButton
            text="Verificar"
            disabled={disabled}
            onClick={handleOpen}
          />
        )}
      </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div
          style={modalStyle}
          className={`${classes.paper} ${classes.modalContent} ${
            isRedesign ? classes.paperRedesign : ''
          }`}
        >
          <Grid className={classes.modalHeader} container justify="center">
            <span
              className={`${classes.modalTitle} ${
                isRedesign ? ` ${classes.modalTitleRedesign}` : ''
              }`}
            >
              {copy[step].text}
            </span>
            {copy[step].image && (
              <img
                alt="example"
                className={classes.miniImg}
                src={copy[step].image}
              />
            )}
          </Grid>
          <div className={classes.cameraRedesign}>
            {imgSrc ? (
              <>
                <img
                  src={imgSrc}
                  alt="Example frame"
                  width={file?.type === 'application/pdf' ? '12.5%' : '50%'}
                  height="inherit"
                  onLoad={() => setImageLoaded(true)}
                  onError={() => setImageLoaded(false)}
                  style={{ display: imageLoaded ? 'block' : 'none' }}
                />

                {step !== 0 ? (
                  <div className={classes.copyMessage}>
                    Asegúrate de que puedas leer claramente toda la información.
                    <br></br>
                    En caso de que no, por favor volver a tomar la foto.
                  </div>
                ) : null}
              </>
            ) : (
              <>
                {step === 0 && (
                  <img
                    src={face}
                    alt="Face mask"
                    className={classes.faceMask}
                  />
                )}
                <Webcam
                  audio={false}
                  height={width === 'xs' ? 296 : 296}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={width === 'xs' ? 250 : 419}
                  videoConstraints={videoConstraints}
                  screenshotQuality={1}
                  mirrored={mirrored}
                />
                <Hidden xsDown>
                  {(step === 1 || step === 2) && pictureFrame}
                </Hidden>
              </>
            )}
          </div>
          <Grid
            className={classes.buttonsCenter}
            container
            justify="center"
            spacing={2}
          >
            <Grid item sm={6}>
              <WhiteButton
                copy="Intentar de nuevo"
                onClick={tryAgain}
                customClass={classes.marginZero}
                disabled={!imgSrc}
              />
            </Grid>
            <Grid item sm={6}>
              <PrimaryButton
                onClick={imgSrc ? uploadPhoto : takePhoto}
                text={imgSrc ? 'Subir' : 'Tomar foto'}
                disabled={file?.content}
              />
            </Grid>
          </Grid>

          {step !== 0 ? (
            <IdVerificationFile
              selfieDocumentID={step === 1 ? ineFrontID : ineBackID}
              file={file}
              resetInput={resetInput}
              onChange={validateFile}
              uploadFile={uploadFile}
            />
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default withWidth()(CustomModal);
