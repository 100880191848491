import { SET_PAGE, PURGE } from './types';

const INITIAL_STATE = {
  currentPage: 'home',
  previousPage: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        currentPage: action.payload,
        previousPage: state.currentPage
      };
    case PURGE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
