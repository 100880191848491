import { createMachine } from '../../helpers/finite-state-machine';

export const states = {
  BLOCK_CONFIRMATION: 'BLOCK_CONFIRMATION',
  AUTHENTICATION: 'AUTHENTICATION'
};

export const events = {
  CONFIRM: 'CONFIRM',
  RESET: 'RESET'
};

export const machine = createMachine({
  initialState: states.BLOCK_CONFIRMATION,
  [states.BLOCK_CONFIRMATION]: {
    transitions: {
      [events.CONFIRM]: {
        target: states.AUTHENTICATION
      },
      [events.RESET]: {
        target: states.BLOCK_CONFIRMATION
      }
    }
  },
  [states.AUTHENTICATION]: {
    transitions: {
      [events.RESET]: {
        target: states.BLOCK_CONFIRMATION
      }
    }
  }
});
