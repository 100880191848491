import React from 'react';

import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';
import FEA from '../../../../components/FEA/FEA';

const DigitalSignContract = () => {
  return (
    <RegistrationPageLayout
      isModalVisible={true}
      showLogout={true}
      isPrimaryButtonHidden={true}
    >
      <FEA />
    </RegistrationPageLayout>
  );
};

export default DigitalSignContract;
