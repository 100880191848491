import styled, { css } from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

const BaseStyles = css`
  width: 100%;

  @media (min-width: 990px) {
    /* max-width: 38.4rem; */
  }
`;

export const ProgressBarContainer = styled.div`
  ${BaseStyles};
  height: ${({ height }) => height || '3.9rem'};
  border-radius: 1.95rem;
  position: relative;
  background-color: ${colors.primary4};
  overflow: hidden;
  margin: 0 auto;
`;

const getWidth = ({ progress }) => {
  const width = progress || '100';
  return `${width}%`;
};

export const Progress = styled.div`
  ${BaseStyles};
  width: ${getWidth};
  height: 3.9rem;
  color: white;
  background-image: linear-gradient(
    82deg,
    ${colors.primaryGs},
    ${colors.primary}
  );
  position: relative;
  overflow: hidden;
`;

const getColor = ({ color }) => {
  const colorSelector = {
    white: 'white',
    gray: `${colors.carbon1}`
  };

  return colorSelector[color || 'white'];
};

const getAmountContanierWidth = ({ width }) => {
  return width ? `${width}px` : '100%';
};

export const AmountContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${getAmountContanierWidth};
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;

  .amount {
    min-width: max-content;
    color: ${getColor};
  }
`;

export const Content = styled.span`
  min-width: max-content;
`;

export const TextContainer = styled.div`
  font-family: Quicksand;
  font-size: 1.7rem;
  font-weight: 600;
  color: ${getColor};
  min-width: max-content;
  margin: o auto;
`;
