import React from 'react';

import EditableSelect from '../../../shared/components/EditableSelect';

const SearchableSelect = ({ formik, name, label, options, onChange }) => {
  const handleChange = e => {
    if (!e) return;
    if (onChange) onChange(e);

    formik.setFieldValue(name, e);
  };

  return (
    <EditableSelect
      label={label}
      name={name}
      onChange={handleChange}
      onBlur={formik.handleBlur}
      error={formik.errors[name] && formik.touched[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      value={formik.values[name]}
      options={options.map(({ id, name }) => ({
        label: name,
        value: id
      }))}
      isCreatable={false}
      isSearchable={true}
    />
  );
};

export default SearchableSelect;
