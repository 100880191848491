import { SET_DATES, PURGE } from './types';

const INITIAL_STATE = {
  dates: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DATES:
      return { ...state, dates: action.payload };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
