import * as yup from 'yup';

import { patterns_metadata } from '../../../../catalogs/patterns';
import {
  INVALID_DATE_MESSAGE,
  MAX_CHARS_MESSAGE,
  REQUIRED_MESSAGE
} from '../../constants';

export const firstStepValidations = yup
  .object({
    phoneNumber: yup.string().required(REQUIRED_MESSAGE),
    gender: yup.string().required(REQUIRED_MESSAGE),
    birthdate: yup
      .string()
      .test('date validator', 'Fecha inválida', function (date) {
        if (!date)
          return this.createError({
            message: REQUIRED_MESSAGE
          });

        const arrDate = date.split('/');
        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if (
          arrDate[2] % 400 === 0 ||
          (arrDate[2] % 100 !== 0 && arrDate[2] % 4 === 0)
        ) {
          monthLength[1] = 29;
        }

        if (
          arrDate[2] < 1000 ||
          arrDate[3] > 3000 ||
          arrDate[1] === 0 ||
          arrDate[1] > 12
        ) {
          return this.createError({
            message: INVALID_DATE_MESSAGE
          });
        }

        if (arrDate[0] > 0 && arrDate[0] >= monthLength[arrDate[1] - 1]) {
          return this.createError({
            message: INVALID_DATE_MESSAGE
          });
        }

        const formattingDate = new Date(
          +arrDate[2],
          arrDate[1] - 1,
          +arrDate[0]
        );

        const parsedDate = Date.parse(formattingDate);
        const birthdateYear = formattingDate.getFullYear();
        const minDate = new Date().getFullYear() - 18;

        if (Number.isNaN(parsedDate)) {
          return this.createError({
            message: INVALID_DATE_MESSAGE
          });
        }

        if (birthdateYear > minDate) {
          return this.createError({
            message: 'Debes ser mayor de edad'
          });
        }

        return true;
      }),
    curp: yup
      .string()
      .length(18, 'Debes ingresar 18 dígitos')
      .matches(patterns_metadata.patternCurp, 'Ingresa un CURP válido')
      .required(REQUIRED_MESSAGE),
    rfc: yup
      .string()
      .min(13, MAX_CHARS_MESSAGE)
      .max(13, MAX_CHARS_MESSAGE)
      .matches(patterns_metadata.patternRFC, 'Ingresa un RFC válido')
      .required(REQUIRED_MESSAGE)
  })
  .shape();
