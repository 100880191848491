/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// Components
import ErrorBoundaryHandler from '../../../../ErrorBoundary';
import Movements from './Movements';

// Services
import { getShortDate, parseAmount } from '../../../../helpers';

// Assets
import { avatarColors } from '../../../../Dashboard4.0/assets/styles/styles';
import CardSvg from '../../../../Dashboard4.0/assets/images/CardSvg';

import SpanishJson from '../../../../catalogs/spanish.json';
import {
  transactionDescriptions,
  transactionIcons,
  transactionTypeIds,
  transactionTypes
} from './constants';

export const Component = ({
  paymentOrders,
  firstAction,
  secondAction,
  multiTransferAction,
  reversalsAction,
  type
}) => {
  const getColors = (ix, el) => {
    return el.status === 'rejected' ? avatarColors[6] : avatarColors[ix % 6];
  };

  const getSecondaryText = ({
    beneficiary_card_number,
    beneficiary_clabe_code,
    beneficiary_banking_institution,
    transaction_type_id
  }) => {
    const beneficiaryAccountCode =
      beneficiary_clabe_code || beneficiary_card_number;

    /*
    TODO: Set different messages between payment orders created for CLABE code
    and card number (there's no design for it). Currently, for those linked to
    a card number, the custom message is the two last digits of that number.
    */
    const customMessage =
      beneficiary_banking_institution !== 'multiTransfer'
        ? beneficiaryAccountCode?.substring(14) || ''
        : 'Click para ver detalles';

    const bankingInstitution = beneficiary_banking_institution || '';

    if (
      transactionTypes.deltaiColor.includes(transaction_type_id) ||
      transactionTypes.internalTransactions.includes(transaction_type_id)
    )
      return '';

    return `${bankingInstitution} ⸱ ${customMessage}`;
  };

  const getLabelforCreditCard = card => {
    const digits = card.last_four_digits || '';
    const name = card.credit_card_name || '';
    const transactionId = card.transaction_type_id;
    if (transactionTypes.noDetailsTransaction.includes(transactionId))
      return '';

    return `${name} ⸱ ${digits}`;
  };

  const createTransaction = (el, ix, isReversal = false) => {
    const type = el.transaction_type_id;
    const transaction = {
      mainText: el.beneficiary_alias || '',
      secondaryText: getSecondaryText(el) || '',
      dateTime: el.transaction_datetime,
      description: el.description,
      amount: parseAmount(el.amount),
      type: setDeltaiColor(type),
      avatarIcon: getAvatarIcon(el),
      colors: getColors(ix, el),
      status: el.status,
      tags: el.tags || [],
      isMultiTransferItem:
        el.beneficiary_banking_institution === 'multiTransfer'
    };

    if (!isReversal) {
      transaction.isDateVisible =
        ix > 0 ? setDateVisibility(el, paymentOrders[ix - 1]) : true;
    }

    if (transactionTypes.paymentOrders.includes(type)) {
      transaction.description = el.description
        ? el.description
        : transactionDescriptions[type];
      transaction.mainText = SpanishJson.transactionsTypes[type];
    }

    if (transactionTypes.creditCardTransactions.includes(type)) {
      transaction.svg = CardSvg;
      transaction.secondaryText = getLabelforCreditCard(el);
      transaction.description =
        type === transactionTypeIds.reversal
          ? `${el.description} (Reverso)`
          : el.description;
    }

    return transaction;
  };

  const getValues = (el, ix) => {
    const paymentOrder = createTransaction(el, ix);

    /*
       REVERSAL TRANSACTIONS
    1. reversal_transactions is constantly retrieved in the API response and will always be an array,
    2. if reversal_transactions exists (length > 0), we can access the elements of that array, take the first one, which guides us to have the 1:1 relationship. If more elements exist in the array, we won't consider them (and that would be a different implementation for the 1:m relationship).
    */

    if (el?.reversal_transactions?.length > 0) {
      paymentOrder.has_reversal = true;
      paymentOrder.reversal_transactions = el.reversal_transactions.map(
        (reversal, ix) => createTransaction(reversal, ix, true)
      );
    }

    return paymentOrder;
  };

  const setDeltaiColor = type => {
    if (transactionTypes.deltaiColor.includes(type)) return 'success';
  };

  const getAvatarIcon = el => {
    return el.batch_process_name
      ? transactionIcons[8]
      : transactionIcons[el.transaction_type_id];
  };

  const setDateVisibility = (crnttELement, prvElement) => {
    if (!prvElement) return true;
    const date1 = getShortDate(crnttELement.transaction_datetime);
    const date2 = getShortDate(prvElement.transaction_datetime);
    if (date1 === date2) {
      return false;
    }

    return true;
  };

  const setActions = (el, action) => {
    if (checkIfHasDetails(el)) return false;
    const actions = {
      firstAction: firstAction(el),
      secondAction: secondAction(el),
      thirdAction: secondAction(el),
      multiTransferAction: () => multiTransferAction(el),
      reversalsAction: () => reversalsAction(el?.reversal_transactions || [])
    };

    return actions[action];
  };

  const checkIfHasDetails = el => {
    let type = el.transaction_type_id;

    if (transactionTypes.noDetailsTransaction.includes(type)) return true;
  };

  return (
    <Movements
      orders={paymentOrders}
      getValues={getValues}
      setActions={setActions}
      type={type}
    />
  );
};

Component.propTypes = {
  paymentOrders: PropTypes.array.isRequired,
  firstAction: PropTypes.func,
  secondAction: PropTypes.func,
  type: PropTypes.string
};

const ManageTransactionTypes = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="ManageTransactionTypes"
    />
  );
};

export default ManageTransactionTypes;
