export const decideCardRequestHandler = ({ decideCardRequest }) => async ({
  cardId,
  cardRequestId
}) => {
  const { error } = await decideCardRequest({ cardId, cardRequestId });

  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true };
};
