import * as yup from 'yup';
import { PHYSICAL } from '../../constants';
import { cardLimitValidation } from '../../helpers/cardLimitValidation';

const requiredFieldMessage = 'Campo requerido';
const patternAmount = /(^([$]?)(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$)/;
const cardPinPattern = /^[0-9]{4}$/;

export const validationSchema = yup.lazy(
  ({ cardOwnerSpendLimit, cardOwnerId, userRoleId }) =>
    yup.object({
      creditCardName: yup
        .string()
        .max(40, 'Máximo 40 caracteres')
        .required(requiredFieldMessage),
      cardLimit: yup
        .string()
        .min(1, '')
        .max(18, 'Monto máximo excedido')
        .matches(new RegExp(patternAmount), {
          message: 'Ingresa un monto válido',
          excludeEmptyString: true
        })
        .test({
          name: 'validator-custom-amount',
          test: function (amount) {
            if (!amount) {
              return this.createError({
                message: requiredFieldMessage
              });
            }

            if (amount && amount.includes('.')) {
              return this.createError({
                message: 'El número no debe tener decimales'
              });
            }

            if (amount && Number(amount.replace('$', '')) === 0) {
              return this.createError({
                message: 'Por favor ingresa una cantidad mayor a 0'
              });
            }

            if (!cardOwnerId)
              return this.createError({
                message: 'Por favor, selecciona un usuario primero'
              });

            const validation = cardLimitValidation(
              Number(cardOwnerSpendLimit.replace(/([$]|[,])/g, '')),
              Number(amount.replace(/([$]|[,])/g, '')),
              userRoleId
            );

            if (!validation.isValid) {
              return this.createError({
                message: validation.errorMessage
              });
            }

            return true;
          }
        })
        .required(requiredFieldMessage),
      cardType: yup.string().required(requiredFieldMessage),
      cardOwnerId: yup.string().required(requiredFieldMessage),
      isSuperAdmin: yup.boolean().required(requiredFieldMessage),
      requestingCardForSelf: yup.boolean().required(requiredFieldMessage),
      cardPin: yup
        .string()
        .when(['cardType', 'requestingCardForSelf', 'isSuperAdmin'], {
          is: (cardType, requestingCardForSelf, isSuperAdmin) =>
            cardType === PHYSICAL &&
            requestingCardForSelf === true &&
            isSuperAdmin,
          then: yup
            .string()
            .max(4, 'Máximo 4 caracteres')
            .matches(new RegExp(cardPinPattern), {
              message: 'El PIN debe ser un valor numérico de cuatro dígitos'
            })
            .required(requiredFieldMessage)
        }),
      confirmCardPin: yup
        .string()
        .when(['cardType', 'requestingCardForSelf', 'isSuperAdmin'], {
          is: (cardType, requestingCardForSelf, isSuperAdmin) =>
            cardType === PHYSICAL &&
            requestingCardForSelf === true &&
            isSuperAdmin,
          then: yup
            .string()
            .max(4, 'Máximo 4 caracteres')
            .matches(new RegExp(cardPinPattern), {
              message: 'El PIN debe ser un valor numérico de cuatro dígitos'
            })
            .required(requiredFieldMessage)
            //Should match cardPin field
            .oneOf([yup.ref('cardPin'), null], 'El PIN debe coincidir')
        })
    })
);
