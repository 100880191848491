/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
  FileInputContainer,
  FileInputLabel,
  FileName,
  Icon,
  InputContainer,
  InputButtonContainer
} from './FileInput.styles';
import { Input } from '../TextField/TextField.styles';
import InputButton from '../InputButton';

import cer from '../../../../Dashboard4.0/assets/images/cer.svg';
import key from '../../../../Dashboard4.0/assets/images/icons-file-types-key.svg';
import placeholder from '../../../../Dashboard4.0/assets/images/icons-file-types-template.svg';

const FileInput = ({
  accept,
  className,
  fileName,
  icon,
  label,
  onChange,
  type,
  ...otherProps
}) => {
  const inputRef = useRef(null);

  const iconSelector = icon => {
    const icons = {
      cer: cer,
      key: key,
      default: placeholder
    };

    return icons[icon || 'default'];
  };

  const handleClick = () => {
    if (fileName) {
      onChange({ target: { value: '', files: [] } });
      return;
    }
    inputRef.current.value = null; // clear the value of the input element whenever the user clicks on the input select button, This way every file selection is considered a new selection regardles of the filename
    inputRef.current.click();
  };

  return (
    <FileInputContainer
      className={className}
      onClick={otherProps.onClick ? otherProps.onClick : handleClick}
    >
      <Icon src={iconSelector(icon)} alt="documento" />
      <InputContainer>
        <FileInputLabel
          htmlFor={'file-input'}
          label={label}
          hasValue={fileName}
        >
          {label}
        </FileInputLabel>
        <Input
          id="file-input"
          type={type}
          ref={inputRef}
          onChange={onChange}
          accept={accept || '.jpg, .jpeg, .png, .pdf'}
          {...otherProps}
        />
        {fileName ? (
          <FileName data-testid="fileName">
            {fileName.length > 35
              ? `${fileName.substring(0, 35)}...`
              : fileName}
          </FileName>
        ) : null}
      </InputContainer>
      <InputButtonContainer>
        <InputButton
          data-test="inputButton"
          type={!fileName ? 'add' : 'remove'}
        />
        {otherProps.endAdornment ? (
          <div className="fileInputEndAdorment">{otherProps.endAdornment}</div>
        ) : null}
      </InputButtonContainer>
    </FileInputContainer>
  );
};

FileInput.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string
};

export default FileInput;
