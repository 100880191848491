// React imports
import React, { Fragment } from 'react';

// Auxiliary libraries imports
import { makeStyles } from '@material-ui/core/styles';

// Assets
import ClickableCard from '../ClickableCard/ClickableCard';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  }
}));

const InputCard = ({
  cardText,
  error,
  file,
  isUploaded,
  name,
  onChange,
  onClick,
  tooltip,
  accept
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <input
        name={name}
        className={classes.input}
        onChange={onChange}
        multiple
        id={name}
        type="file"
        accept={accept || '.jpg, .jpeg, .png, .pdf'}
      />
      <label htmlFor={name}>
        <ClickableCard
          cardText={cardText}
          error={error}
          file={file}
          isUploaded={isUploaded}
          onClick={onClick}
          tooltip={tooltip}
        />
      </label>
    </Fragment>
  );
};

export default InputCard;
