import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { freelancerBasicInfo } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';
import UpdateLegalPersonCreator from '../domain/update-legal-person';

const updateLegalPersonService = async data => {
  try {
    const { status, error } = await customAxiosInstance().put(
      freelancerBasicInfo,
      UpdateLegalPersonCreator(data)
    );
    return extractContentFromResponse({
      status,
      error,
      moduleName: 'update-legal-person-entity'
    });
  } catch (error) {
    log.error('update-legal-person-entity', error);

    return { error: { message: 'Not able to send request!' } };
  }
};

export default updateLegalPersonService;
