import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { checkPermissions } from '../permissions/checkPermissions';
import { cleanStateAction } from '../redux/role-permissions-actions';
import {
  allPermissionsSelector,
  rulesSelector
} from '../redux/role-permissions-selectors';

const HasPermissions = ({
  perform,
  accessAction,
  restrictedAction,
  apiDocsEnabled
}) => {
  const dispatch = useDispatch();
  const allPermissions = useSelector(allPermissionsSelector);
  const rules = useSelector(rulesSelector);

  useEffect(() => {
    if (!Array.isArray(rules)) dispatch(cleanStateAction());
    // eslint-disable-next-line
  }, [rules]);

  return checkPermissions(allPermissions, rules, perform, apiDocsEnabled)
    ? accessAction()
    : restrictedAction();
};

HasPermissions.propTypes = {
  perform: PropTypes.string,
  accessAction: PropTypes.func.isRequired,
  restrictedAction: PropTypes.func.isRequired
};

export default HasPermissions;
