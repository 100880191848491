import {
  createBeneficiaryService,
  updateBeneficiaryService,
  createPaymentOrderService,
  getBankingInstitutionsService,
  getBeneficiariesService,
  getPaymentOrderService,
  deletePaymentAccount
} from '../Services/spei';
import {
  CLEAR_BENEFICIARY,
  CLEAR_PAYMENT_ACCOUNT,
  CLEAR_PAYMENT_ORDER,
  CREATE_BENEFICIARY,
  CREATE_PAYMENT_ORDER,
  GET_BANKING_INSTITUTIONS,
  GET_BENEFICIARIES,
  GET_PAYMENT_ORDER,
  CLEAR_ORDER,
  SET_BENEFICIARIES,
  SET_PAYMENT_ORDERS
} from '../reducers/types';
import { setAction } from '.';

export const clearCreaters = dispatch => {
  dispatch({ type: CLEAR_BENEFICIARY });
  dispatch({ type: CLEAR_PAYMENT_ACCOUNT });
  dispatch({ type: CLEAR_PAYMENT_ORDER });
};

export const clearPaymentOrder = dispatch => {
  dispatch({ type: CLEAR_ORDER });
};
const modifyBeneficiaryCreator = modificationServiceMethod => (
  dispatch,
  body
) => {
  let beneficiaryData = { ...body };
  if (beneficiaryData.beneficiary_account_type === 'clabe_code') {
    delete beneficiaryData.banking_institution_id;
  }

  modificationServiceMethod(beneficiaryData)
    .then(res => {
      let payloadRes = {
        data: body,
        res: res['data'][0],
        status: res['status'],
        success: true,
        error: false
      };

      setAction(res, CREATE_BENEFICIARY, dispatch, payloadRes);
    })
    .catch(() => {
      let payloadRes = {
        data: body,
        res: [],
        success: false,
        error: true
      };

      dispatch({ type: CREATE_BENEFICIARY, payload: payloadRes });
    });
};

export const updateBeneficiary = modifyBeneficiaryCreator(
  updateBeneficiaryService
);
export const createBeneficiary = modifyBeneficiaryCreator(
  createBeneficiaryService
);

export const createPaymentOrder = async (dispatch, body) => {
  const errorResponse = {
    data: body,
    res: [],
    success: false,
    error: true
  };

  const { data, status } = await createPaymentOrderService(body);

  if (status === 201) {
    let payloadRes = {
      data: body,
      res: data[0],
      status: status,
      success: true,
      error: false
    };
    dispatch({ type: CREATE_PAYMENT_ORDER, payload: payloadRes });
    return payloadRes;
  } dispatch({ type: CREATE_PAYMENT_ORDER, payload: errorResponse });
};

export const getBankings = dispatch => {
  getBankingInstitutionsService()
    .then(res => {
      let payloadRes = {
        res: res['data'],
        status: res['status'],
        success: true,
        error: false
      };

      dispatch({ type: GET_BANKING_INSTITUTIONS, payload: payloadRes });
    })
    .catch(() => {
      let payloadRes = {
        res: [],
        success: false,
        error: true
      };

      dispatch({ type: GET_BANKING_INSTITUTIONS, payload: payloadRes });
    });
};

export const getBeneficiaries = dispatch => {
  getBeneficiariesService()
    .then(res => {
      let paymentAccount = res['data'];
      let payloadRes = {
        res: paymentAccount || [],
        success: true,
        error: false
      };

      dispatch({ type: GET_BENEFICIARIES, payload: payloadRes });
    })
    .catch(() => {
      let payloadRes = {
        res: [],
        success: false,
        error: true
      };

      dispatch({ type: GET_BENEFICIARIES, payload: payloadRes });
    });
};

export const getPaymentOrder = (dispatch, id) => {
  getPaymentOrderService(id)
    .then(res => {
      let payloadRes = {
        res: res,
        success: true,
        error: false
      };

      dispatch({ type: GET_PAYMENT_ORDER, payload: payloadRes });
    })
    .catch(() => {
      let payloadRes = {
        res: [],
        success: false,
        error: true
      };

      dispatch({ type: GET_PAYMENT_ORDER, payload: payloadRes });
    });
};

export const setBeneficiary = (dispatch, body) => {
  dispatch({ type: CREATE_BENEFICIARY, payload: body });
};

export const setPaymentOrder = (dispatch, body) => {
  let payload = {
    data: body,
    res: [],
    success: false,
    error: false
  };

  dispatch({ type: CREATE_PAYMENT_ORDER, payload: payload });
};

export const deleteRecipient = async (dispatch, body) => {
  try {
    let res = await deletePaymentAccount(body);
    if (res.status === 200) {
      dispatch({ type: SET_BENEFICIARIES, payload: body });
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateTransactionList = (dispatch, body) => {
  dispatch({ type: SET_PAYMENT_ORDERS, payload: body });
};
