import React from 'react';
import { useHistory } from 'react-router-dom';
import RegistrationPageLayout from '../../micro-frontends/shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <RegistrationPageLayout
      title="Página no encontrada"
      description="Lo sentimos, la página que buscas no existe"
      onPrimaryButtonClick={() => {
        history.goBack();
      }}
      primaryButtonText="Regresar"
      secondaryButtonText="Página de inicio"
      isModalVisible={true}
      onSecondaryButtonClick={() => {
        history.push('/');
      }}
    ></RegistrationPageLayout>
  );
};

export default NotFoundPage;
