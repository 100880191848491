import log from 'loglevel';


export const extractContentFromResponse = ({
  data,
  status,
  error,
  isSingleElement,
  moduleName
}) => {

  if (status === 200 || status === 201) {
    log.debug(moduleName, status, data);
    return { data: isSingleElement ? data[0] : data };
  }

  log.error(moduleName, status, error, data);

  return { error: data[0] || `Error code: ${status}` };
};
