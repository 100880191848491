import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import ComplimentaryForm from './ComplementaryForm';
import OnboardingErrorBoundary from '../OnboardingErrorBoundary/OnboardingErrorBoundary';
import OnboardingLayout from '../../components/OnboardingLayout/OnboardingLayout';
import TextField from '../../../shared/components/TextField';

import { Form } from './style';

import { attorneyInLawValidation } from './validationSchema';
import { attorneyInFactAssociationId } from '../../constants';
import setInputProps from '../../helpers/set-input-props';

const Component = ({ steps, activeStep, goBack, goToNextStep, userId }) => {
  const [isValid, setIsValid] = useState(false);
  const [complementaryData, setComplementaryData] = useState({});
  const [hasShareHolder, setHasShareHolder] = useState(false);

  const formik = useFormik({
    initialValues: {
      associationTypeId: attorneyInFactAssociationId,
      userId
    },
    validationSchema: attorneyInLawValidation
  });

  const validateIsAttorneyInLaw = (form, complementaryForm, setIsValid) => {
    const isAttorneyFormValid = form.isValid && form.dirty;
    if (
      isAttorneyFormValid &&
      form.values.isYou === 'true' &&
      form.values.shareHolder === 'true'
    )
      setIsValid(true);
    else if (isAttorneyFormValid && complementaryForm.isValid) setIsValid(true);
    else setIsValid(false);
  };

  const handleHasShareHolder = (e, value) => {
    formik.handleChange(e);
    setHasShareHolder(value);
    formik.setFieldValue('isYou', false);
  };

  const handlePersonType = e => {
    setComplementaryData({});
    formik.handleChange(e);
  };

  const parseControllingStake = (e, formik) => {
    formik.handleChange(e);
    formik.setFieldValue('percentage', Number(e.target.value));
  };

  const handleSubmit = () => {
    goToNextStep({
      attorneyForm: formik.values,
      complementaryData
    });
  };

  useEffect(() => {
    validateIsAttorneyInLaw(formik, complementaryData, setIsValid);
  }, [formik, complementaryData]);

  return (
    <OnboardingLayout
      title="Datos del propietario real"
      onPrimaryButtonClick={handleSubmit}
      primaryButtonText="Continuar"
      secondaryButtonText="Regresar"
      isPrimaryButtonDisabled={!isValid}
      isModalVisible
      onSecondaryButtonClick={goBack}
      steps={steps}
      activeStep={activeStep}
    >
      <Form action="">
        <p>
          ¿La empresa cuenta con un socio o accionista persona física con el 25%
          o más de las acciones o partes sociales de la compañía?
        </p>
        <div className="formQuestion">
          <input
            type="radio"
            name="shareHolder"
            id="shareHolderYes"
            value
            onChange={e => handleHasShareHolder(e, true)}
          />
          <label className="questionLabel" htmlFor="shareHolderYes">
            Sí
          </label>
          <input
            type="radio"
            name="shareHolder"
            id="shareHolderNo"
            value={false}
            onChange={e => handleHasShareHolder(e, false)}
          />
          <label className="questionLabel" htmlFor="shareHolderNo">
            No
          </label>
        </div>
        {formik.values.shareHolder === 'true' && formik.dirty ? (
          <>
            <p>¿Eres tú?</p>
            <div className="formQuestion">
              <input
                type="radio"
                name="isYou"
                id="isYouYes"
                value
                onChange={handlePersonType}
              />
              <label className="questionLabel" htmlFor="isYouYes">
                Sí
              </label>
              <input
                type="radio"
                name="isYou"
                id="IsYouNo"
                value={false}
                onChange={handlePersonType}
              />
              <label className="questionLabel" htmlFor="IsYouNo">
                No
              </label>
            </div>
          </>
        ) : null}

        {formik.values.shareHolder === 'true' &&
        formik.values.isYou === 'true' ? (
          <>
            <p>¿Cuál es tu porcentaje de acciones?</p>
            <TextField
              {...setInputProps(
                formik,
                e => parseControllingStake(e, formik),
                'Porcentaje (%)',
                'percentage',
                'text'
              )}
              id="percentage"
            />
          </>
        ) : null}
      </Form>
      {formik.values.isYou === 'false' ||
      formik.values.shareHolder === 'false' ? (
        <ComplimentaryForm
          setFormData={setComplementaryData}
          hasShareHolder={hasShareHolder}
          parseControllingStake={parseControllingStake}
        />
      ) : null}
    </OnboardingLayout>
  );
};

const AttorneyInLaw = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="AttorneyInLaw"
      props={props}
    />
  );
};

export default AttorneyInLaw;
