import { customAxiosInstance } from './axiosConfig';

const basePath = '/api/dashboard/';
const contract = `${basePath}contract`;
const verifyPath = `${basePath}verifydocument`;
const validateCerPath = `${basePath}validate`; //validate with backend
const signedContract = `${basePath}signed-contract`;

export const contractFea = async body => {
  return customAxiosInstance().post(contract, body);
};

export const getContractFea = async () => {
  return customAxiosInstance().get(contract);
};

export const verifyDocument = async body => {
  return customAxiosInstance().post(verifyPath, body);
};

export const validateCert = async body => {
  return customAxiosInstance().post(validateCerPath, body);
};

export const getDigitalContract = () => {
  return customAxiosInstance().get(contract);
};

export const getSignedContract = () => {
  return customAxiosInstance().get(signedContract);
};
