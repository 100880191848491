import createDataContext from './createDataContext';

const viewsContext = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SHOW_PASSWORD_RECOVERY':
      return { ...state, isVisible: payload };
    default:
      return state;
  }
};

const isPasswordRecoveryVisible = dispatch => isVisible => {
  dispatch({
    type: 'SHOW_PASSWORD_RECOVERY',
    payload: isVisible
  });
};

export const { Provider, Context } = createDataContext(
  viewsContext,
  { isPasswordRecoveryVisible },
  { isVisible: false }
);
