"use client";

class Geo {
    static #enabled;
    static #task;
    static #latitude = 0;
    static #longitude = 0;
    static #lastUpdate = -1;
    
    static hasSupport(){
        console.log(navigator?.geolocation);
        Geo.#enabled = navigator?.geolocation !== undefined;
        return Geo.#enabled;
    }

    static #updateCoords(position){
        Geo.#enabled = true;
        console.log("Geo :: #updateCoords :: position = ", position);

        const coords = position.coords;
        Geo.#latitude = coords.latitude;
        Geo.#longitude = coords.longitude;
        Geo.#lastUpdate = new Date();

        if ( Geo.#task === undefined){
            Geo.#task = setInterval(
                Geo.#getCurrentPosition,
                10000,
            );
        }
    }

    static getData(){
        return {
            lat: Geo.#latitude,
            lng: Geo.#longitude,
            lastUpdate: Geo.#lastUpdate,
        };
    }

    static getIsEnabled(){
        return Geo.#enabled;
    }
    static #handleError(error){
        Geo.#enabled = false;
        console.log("Geo :: #handleError :: error = ", error);
        const code = error?.code ?? -1;
        switch ( error?.code){
            case 0: // Noy supported
            case 1: // Bloked by user
                // window.location = "/error";
                break;
        }
    }
    static init(){
        const isEnabled = Geo.hasSupport();
        if ( isEnabled  === false){
            Geo.#handleError({code : 0});
            return;
        }

        if ( Geo.#task !== undefined){
            return;
        }

        console.log("Geo :: init");

        Geo.#getCurrentPosition();
    }

    static #getCurrentPosition(){
        navigator.geolocation.getCurrentPosition(
            Geo.#updateCoords,
            Geo.#handleError,
            {
                enableHighAccuracy: true
            }
        );
    }
}

export default Geo;