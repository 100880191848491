import React from 'react';

import { Resizable } from 're-resizable';

import NotFound from './notFound.png';

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const Image = props => {
  const { alt, height, imgWidth, src, width } = props;

  const [image, setImage] = React.useState(src);

  let brokenImage = false;

  const onError = e => {
    setImage(NotFound);
  };

  return (
    <Resizable
      style={style}
      defaultSize={{
        width: width,
        height: height
      }}
    >
      {!brokenImage ? (
        <img src={image} onError={e => onError(e)} alt={alt} width={imgWidth} />
      ) : null}
    </Resizable>
  );
};

export default Image;
