import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const StatusColors = {
  approved: colors.completed,
  rejected: colors.subtle,
  pending: colors.almostBlack,
  success: colors.completed
};

export const StatusText = styled.p`
  font-family: ${fonts.barlowRegular};
  font-size: 14px;
  color: ${props => (props.status ? StatusColors[props.status] : '#000000')};

  @media screen and (max-width: 443px) {
    font-size: 10px;
  }
`;
