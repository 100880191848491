import styled from 'styled-components';
import Icon from '../../../shared/components/Icon/Icon';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const DropdownContainer = styled.div`
  ul {
    margin: -0.4rem 0 0;

    li {
      color: ${colors.almostBlack};
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.almostBlack};

  .dropDownIcon {
    color: ${colors.almostBlack};
    path {
      fill: ${colors.almostBlack};
    }
  }
`;

export const StyledIcon = styled(Icon)`
  transform: rotate(90deg);
  margin-left: 1.6rem;
  width: 1rem;
  height: 1rem;
`;
