'use client';
import React from 'react';
import Header from '../components/header';
import Panel from '../components/panel';
import HSpacing from '../components/spacing/horizontal-spacing';
import Heading from '../components/heading';
import Center from '../components/center';
import Button from '../components/button';
import Footer from '../components/footer';

import { useDispatch } from 'react-redux';
import { CenterText } from '../utils/styles';
import { service } from '../service';
import { closeSession } from '../../../Services/authService';
import { PURGE } from '../../../reducers/types';

function View({ history }) {
  // if (isLoading) return <WaitingRedLines />;
  const dispatch = useDispatch();

  const logout = async () => {
    try {
      closeSession();
  } finally {
      dispatch({ type: PURGE });
  }
  };
  return (
    <div>
      <Header />
      <HSpacing times={3} />
      <Panel>
        <HSpacing times={4} />
        <Center>
          <div
            style={{
              width: '416px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <Heading>¡Información relevante para ti! 🚀</Heading>
          </div>
        </Center>
        <HSpacing times={4} />

        <Center>
          <CenterText>
            Necesitamos la colaboración del Representante Legal de tu empresa
            para volver a autorizar la consulta al Buró de Crédito e Información
            Fiscal de la empresa.
          </CenterText>
        </Center>
        <HSpacing times={2} />
        <Center>
          <CenterText>
            Lo único que necesita hacer es ingresar a su cuenta y seguir los
            pasos para autorizar la consulta. En delt.ai, estamos comprometidos
            a garantizar que aproveches al máximo tu línea de crédito con
            nosotros.
          </CenterText>
        </Center>
        <HSpacing times={2} />
        <Center>
          <CenterText>
            Una vez que el Representante Legal complete la información, podrás
            acceder nuevamente a la plataforma.
          </CenterText>
        </Center>

        <HSpacing times={5} />
        <Center>
          <Button
            style={{ fontWeight: 600 }}
            width={432}
            label="Continuar"
            onClick={logout}
          />
        </Center>
      </Panel>
      <Footer />
    </div>
  );
}

export default function Page({ history }) {
  return (
    <>
      <View history={history} />
    </>
  );
}
