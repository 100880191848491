import styled from 'styled-components';

import {
  colors,
  gradients
} from '../../../../Dashboard4.0/assets/styles/styles';

export const ButtonContainer = styled.div`
  height: 9.2rem;
  min-width: fit-content;
  width: fit-content;
  border-radius: 0 1.2rem 1.2rem 0;
  border: 1 px solid ${colors.carbon3};
  background-image: ${gradients.palette0Gradient};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  &:hover {
    cursor: auto;
  }

  @media (max-width: 990px) {
    padding: 0 2rem;
  }
  @media (max-width: 768px) {
    background: transparent;
  }
`;

const getBorderStyles = ({ start, columnGap, noBorders }) => {
  return `
  border-radius: ${start && columnGap ? '1.2rem' : '1.2rem 0 0 1.2rem'};
  margin-right: ${columnGap ? '0.8rem' : '0'};
  ${noBorders && 'border-radius: 0'};
  `;
};

export const CreditDetailsContainer = styled.div`
  height: 9.2rem;
  display: flex;
  width: fit-content;

  @media (min-width: 768px) {
    width: 100%;
    padding: 2rem 2.5rem;
    ${getBorderStyles}
  }
  @media (max-width: 768px) {
    border-radius: 1.2rem !important;
  }
  @media (max-width: 990px) {
    padding: 1rem 2rem;
  }
  box-shadow: 0 11px 22px -8px rgba(198, 204, 213, 0.4);
  border: solid 1px ${colors.carbon3};
`;

export const InfoBarContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 94.8rem;
  position: relative;
  margin: 1rem 0px 0px 0px;

  h2 {
    max-width: 183px;
  }

  .creditInfoBar {
    background: red;
  }

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    .infoBarContentStart {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  .infoBarBtnContainer {
    display: none;
    @media screen and (min-width: 768px) {
      display: flex;
    }
  }
`;

export const InfoBarItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  justify-content: center;
  align-items: ${props => (props.align ? props.align : 'center')};
  align-content: center;
  width: 100%;
  grid-row-gap: 0.9rem;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    min-width: 12.2rem;
    margin: ${props => (props.margin ? `0 ${props.margin}` : '0rem')};
  }
  @media (max-width: 500px) {
    margin: 0;
    max-width: 100%;
  }
`;

export const Tooltip = styled.div`
  color: #b4bac3;
  font-size: 1.1rem;
  background: rgba(38, 51, 67, 0.95);
  border-radius: 5px;
  position: absolute;
  padding: 0.5rem 2rem;
  bottom: -2rem;
`;
