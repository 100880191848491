/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Helper,
  Input,
  InputContainer,
  Label,
  StartAdornment,
  TextFieldContainer
} from './TextField.styles';
import { getCursorPositionForAmountField } from '../TextFieldAmount/getCursorPositionAmountField';

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

// eslint-disable-next-line react/display-name
const TextField = forwardRef(
  ({ label, onChange, isAmountTextField, ...otherProps }, ref) => {
    const [focused, setFocused] = useState(false);
    const [hasValue, setHasValue] = useState(otherProps.hasValue || null);
    const [inputRef, setInputFocus] = useFocus();
    const setIsFocused = () => setFocused(!focused);

    const handleChange = e => {
      // this below needs to be here until this TextField refs won't be properly pushed forward. This is good idea for refactor.
      const { modifiedEvent, newPosition } = isAmountTextField
        ? getCursorPositionForAmountField(e)
        : {};

      const { value } = modifiedEvent ? modifiedEvent.target : e.target;

      setHasValue(value || null);
      if (onChange) onChange(modifiedEvent || e);

      if (isAmountTextField) {
        // setting cursor to new position need to take place AFTER modified value will be passed down by props to the TextField
        setTimeout(() => {
          inputRef.current.setSelectionRange(newPosition, newPosition);
        }, 0);
      }
    };

    const handleBlur = e => {
      setFocused(false);
      if (otherProps.onBlur) otherProps.onBlur(e);
    };

    useEffect(() => {
      if (otherProps.value) {
        setHasValue(true);
      }
      //eslint-disable-next-line
    }, [otherProps.value]);

    return (
      <TextFieldContainer
        error={otherProps.error}
        focused={focused}
        data-test="textFieldComponent"
        data-testid="textFieldComponent"
        className={otherProps.className ? otherProps.className : 'textField'}
        onClick={
          otherProps.disabled && otherProps.onDisabledFieldClick
            ? otherProps.onDisabledFieldClick
            : e => setInputFocus()
        }
        onBlur={handleBlur}
      >
        {otherProps.startAdornment ? (
          <StartAdornment
            data-test="startAdornment"
            hasValue={hasValue}
            focused={focused}
          >
            {otherProps.startAdornment}
          </StartAdornment>
        ) : null}

        <InputContainer>
          {label && (
            <Label
              data-test="labelComponent"
              data-testid="labelComponent"
              focused={focused}
              hasValue={hasValue}
              length={label.length}
            >
              {label}
            </Label>
          )}

          <Input
            data-test="inputComponent"
            data-testid="inputComponent"
            focused={focused}
            onChange={handleChange}
            hasValue={hasValue}
            value={otherProps.value}
            ref={ref || inputRef}
            name={otherProps.name}
            onFocus={setIsFocused}
            onBlur={setIsFocused}
            startAdornment={otherProps.startAdornment}
            onKeyDown={otherProps.onKeyDown}
            type={otherProps.type}
            autoFocus={otherProps.autofocus}
            {...otherProps}
          />
          <Helper className={otherProps.helperTextClass}>
            {otherProps.helperText}
          </Helper>
        </InputContainer>

        {otherProps.endAdornment ? (
          <div className="endAdornment">{otherProps.endAdornment}</div>
        ) : null}
      </TextFieldContainer>
    );
  }
);

TextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func
};

export default TextField;
