import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';
import { twoFactorAuthPath } from './apiPaths';

export const requestTwoFactorAuthURI_service = async () => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      twoFactorAuthPath
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'Two Factor Authentication-service'
    });
  } catch (error) {
    log.error('Two Factor Authentication-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
