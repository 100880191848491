import React from 'react';
import { TextField } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});

const Input = ({
  label,
  onChange,
  value,
  InputProps,
  name,
  className,
  type,
  helperText,
  error,
  id,
  onBlur,
  inputProps,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TextField
        error={error}
        className={className || classes.root}
        label={label}
        onChange={onChange}
        value={value}
        name={name}
        InputProps={InputProps}
        type={type || 'text'}
        helperText={helperText}
        onBlur={onBlur}
        id={id}
        inputProps={inputProps}
        {...otherProps}
      />
    </React.Fragment>
  );
};

export default Input;
