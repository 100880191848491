import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';
import LabelInfo from '../../../shared/components/LabelInfo';

const classes = css`
  .label-topMargin {
    display: inline-block;
    margin-top: 2.6rem;
    margin-right: 0.5rem;
  }
  .label_info-margin {
    margin: 1.1rem 0 0.9rem;
    font-weight: 600;
  }
  .label-content {
    font-size: 1.35rem;
    font-weight: normal;
    font-family: ${fonts.barlowRegular};
    line-height: 1.33;
    letter-spacing: 0.1px;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-width: 18rem;
  border-radius: 12px;
  border: solid 0.1rem ${colors.carbon};
  background-color: ${colors.white};
  padding: 3rem;
  ${classes}
`;

export const DateLabel = styled(LabelInfo)`
  color: ${colors.primary};
`;

export const DateLabelContainer = styled.div`
  border: solid 0.8px rgba(23, 144, 207, 0.5);
  background-color: rgba(23, 144, 207, 0.05);
  border-radius: 2px;
  width: fit-content;
  padding: 0 6px;
`;
