import  "./index.scss";

import React from "react";

import {Component} from "react";
import HSpacing from "../spacing/horizontal-spacing";
import Button from "../button";
import { closeSession } from '../../../../Services/authService';
import { PURGE } from '../../../../reducers/types';
import Logo from "../../assets/logo_horizontal_color.svg";
import { useDispatch } from 'react-redux';

class View extends Component{
    #dispatch;
    #history;
    constructor(props) {
        super(props);
        this.#dispatch = this.props.dispatch
        this.#dispatch.bind(this);
        this.#history = this.props.history;
    }
    onClickBrand = () => {
        if(this.props.onClickBrand){
            this.props.onClickBrand()
        }
    }
    onClickExit = () => {
        try {
            closeSession();
        } finally {
            this.#dispatch({ type: PURGE });
        }
    }
    render() {
        return(
            <>
                <HSpacing times={3}/>
                <div className="header">
                    <div className="image">
                        <img alt="" src={Logo} width={168} height={53} onClick={this.onClickBrand}/>
                    </div>
                    <Button type="secondary" label="Salir" width={160} onClick={this.onClickExit}/>
                </div>
            </>
        )
    }
}

const Header = ({history}) => {
    const dispatch = useDispatch();
    return <View dispatch={dispatch}/>
}
export default Header;
