import React from 'react';

// Shared Components
import { icons } from '../../../../Dashboard4.0/assets/images/icons';

// Styled Components
import {
  CardActivationInfoContainer,
  CardBlackBar,
  CardChip,
  CardHolderName,
  CardInfoContainer,
  CardNumber,
  CardSecureInfoContainer,
  CirrusLogo,
  InfoContainer,
  InfoSpan,
  SectionEnd,
  SecurityBar
} from './CardActivationInfo.styles';

const CardActivationInfo = () => {
  return (
    <CardActivationInfoContainer>
      {/* Card Side bar */}
      <CardBlackBar>
        <div className="strip"></div>
      </CardBlackBar>
      {/* Card Info */}
      <CardInfoContainer>
        <CirrusLogo alt="Cirrus logo" src={icons.CardCirusLogo.src} />
        <CardHolderName>Juan Ignacio González</CardHolderName>
        <CardNumber>
          <span className="marginRight">5119</span>
          <span className="marginRight">2500</span>
          <span className="marginRight">3840</span>
          <span className="lastFourDigits">1903</span>
        </CardNumber>
        <CardSecureInfoContainer>
          <div className="secureInfoDiv">
            <p className="secureInfoTitle">EXP DATE</p>
            <p className="secureInfoValue">08/25</p>
          </div>
          <div className="secureInfoDiv">
            <p className="secureInfoTitle">SECURITY CODE</p>
            <p className="secureInfoValue">123</p>
          </div>
        </CardSecureInfoContainer>
        <InfoContainer>
          <InfoSpan className="danger">Firma Autorizada</InfoSpan>
          <SecurityBar />
          <InfoSpan>No válida sin firma</InfoSpan>
          <InfoSpan>
            Teléfono de asistencia: +52 55 9337 2225 ayuda@delt.ai{' '}
          </InfoSpan>
          <InfoSpan className="muted">
            El usuario de esta tarjeta de crédito implica la aceptación del
            contrato correspondiente y de sus modificaciones que ocurran. Se
            podrá utilizar a nivel nacional e internacional en forma
            electrónica. No transferible.{' '}
          </InfoSpan>
          <SectionEnd>
            <CardChip />
            <InfoSpan className="muted end">
              Esta tarjeta es propiedad del emisor y debe ser devuelta al ser
              solicitada
            </InfoSpan>
          </SectionEnd>
        </InfoContainer>
      </CardInfoContainer>
    </CardActivationInfoContainer>
  );
};

export default CardActivationInfo;
