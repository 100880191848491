import { buroGenerateHandler } from './eventHandlers/buroGenerate';
import { buroNipHandler } from './eventHandlers/buroNip';
import { buroResendNipHandler } from './eventHandlers/buroResendNip';
import { buroSubmitHandler } from './eventHandlers/buroSubmit';
import { customerUpdatePhoneHandler } from './eventHandlers/customerUpdatePhone';
import { complianceCredentialsHandler } from './eventHandlers/complianceCredentials';
import { agreementListHandler } from './eventHandlers/agreementList';
import { agreementUpdateHandler } from './eventHandlers/agreementUpdate';

import { buroGenerateService } from './infrastructure/buroGenerate';
import { buroNipService } from './infrastructure/buroNip';
import { buroResendNipService } from './infrastructure/buroResendNip';
import { buroSubmitService } from './infrastructure/buroSubmit';
import { customerUpdatePhoneService } from './infrastructure/customerUpdatePhone';
import { complianceCredentialsService } from './infrastructure/complianceCredentials';
import { agreementListService } from './infrastructure/agreementList';
import { agreementUpdateService } from './infrastructure/agreementUpdate';

const service = () => ({
  buroGenerate: buroGenerateHandler({
    buroGenerate: buroGenerateService
  }),
  buroNip: buroNipHandler({
    buroNip: buroNipService
  }),
  buroResendNip: buroResendNipHandler({
    buroResendNip: buroResendNipService
  }),
  buroSubmit: buroSubmitHandler({
    buroSubmit: buroSubmitService
  }),
  customerUpdatePhone: customerUpdatePhoneHandler({
    customerUpdatePhone: customerUpdatePhoneService
  }),
  complianceCredentials: complianceCredentialsHandler({
    complianceCredentials: complianceCredentialsService
  }),
  agreementList: agreementListHandler({
    agreementList: agreementListService
  }),
  agreementUpdate: agreementUpdateHandler({
    agreementUpdate: agreementUpdateService
  }),
});

export { service };
