const agreementUpdateHandler = ({ agreementUpdate }) => async ({
  uuid,
  agreementUuid,
  ip,
  lat,
  lng
}) => {
  const { error, data } = await agreementUpdate({
    uuid,
    agreementUuid,
    ip,
    lat,
    lng
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  let isSuccess = true;
  if (data?.response === 'BAD') {
    isSuccess = false;
  }

  return { isSuccess, data };
};

export { agreementUpdateHandler };
