import log from 'loglevel';
import { SET_FAILED_BATCHES } from '../reducers/types';
import { getFailedTransactions, deleteBatchById } from '../Services/spei';

export const getFailedBatches = dispatch => {
  getFailedTransactions()
    .then(data => {
      if (data.status === 200) {
        dispatch({ type: SET_FAILED_BATCHES, payload: data.data });
      }
    })
    .catch(e => log.error(e));
};

export const deleteBatch = (dispatch, id) => {
  deleteBatchById(id)
    .then(data => {
      if (data.status === 200) {
        getFailedBatches(dispatch);
      }
    })
    .catch(e => log.error(e));
};
