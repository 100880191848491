import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { cardTransactionsPath, speiTagsPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const deleteCardTransactionTagService = async ({
  tag: { tagLabel, tagId, cardId, transactionId } = {}
}) => {
  const transactionPath = cardId ? cardTransactionsPath : speiTagsPath;
  try {
    const { data, status, error } = await customAxiosInstance().delete(
      transactionPath,
      {
        data: {
          credit_card_id: cardId,
          [cardId
            ? 'credit_card_transaction_id'
            : 'stp_transaction_id']: transactionId,
          tag_id: tagId,
          tag_label: tagLabel
        }
      }
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'delete-card-transaction-tag-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('delete-card-transaction-tag-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
