// User info reducer
export const USER_DATA = 'USER_DATA';
export const SAVE_USER_EMAIL = 'SAVE_USER_EMAIL';
export const SAVE_REFRESH_TOKEN = 'SAVE_REFRESH_TOKEN';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const SAVE_TOKEN_EXP = 'SAVE_TOKEN_EXP';
export const SET_SPENDING_LIMIT = 'SET_SPENDING_LIMIT';
export const PURGE = 'PURGE';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const CUSTOMER_INFO  = 'CUSTOMER_INFO';

// SPEI Reducer
export const CLEAR_BENEFICIARY = 'CLEAR_BENEFICIARY';
export const CLEAR_PAYMENT_ACCOUNT = 'CLEAR_PAYMENT_ACCOUNT';
export const CLEAR_PAYMENT_ORDER = 'CLEAR_PAYMENT_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const CREATE_BENEFICIARY = 'CREATE_BENEFICIARY';
export const CREATE_MULTI_BENEFICIARIES = 'CREATE_MUKTI_BENEFICIARIES';
export const CREATE_PAYMENT_ACCOUNT = 'CREATE_PAYMENT_ACCOUNT';
export const CREATE_PAYMENT_ORDER = 'CREATE_PAYMENT_ORDER';
export const GET_BANKING_INSTITUTIONS = 'GET_BANKING_INSTITUTIONS';
export const GET_BENEFICIARIES = 'GET_BENEFICIARIES';
export const GET_PAYMENT_ORDER = 'GET_PAYMENT_ORDER';
export const SET_BENEFICIARIES = 'SET_BENEFICIARIES';
export const SET_PAYMENT_ORDERS = 'SET_PAYMENT_ORDERS';

// Transaction Reducer
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const SET_TRANSACTIONS_ERRORS = 'SET_TRANSACTIONS_ERRORS';
export const SET_TRANSACTIONS_TYPE_FILTER = 'SET_TRANSACTIONS_TYPE_FILTER';
export const ADD_TAG_TO_TRANSACTION = 'ADD_TAG_TO_TRANSACTION';
export const REMOVE_TAG_FROM_TRANSACTION = 'REMOVE_TAG_FROM_TRANSACTION';

// Invoice Reducer
export const GET_INVOICES = 'GET_INVOICES';
export const SET_INVOICES_ERRORS = 'SET_INVOICES_ERRORS';
export const SET_INVOICES_TYPE_FILTER = 'SET_TRANSACTIONS_TYPE_FILTER';

// change page Reducer
export const SET_PAGE = 'SET_PAGE';
export const RETURN_PAGE = 'RETURN_PAGE';

// Users reducer types
export const CLEAR_OBJECT_CREATE = 'CLEAR_OBJECT_CREATE';
export const CLEAR_OBJECT_GET = 'CLEAR_OBJECT_GET';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER_ID = 'GET_USER_ID';
export const GET_USERS = 'GET_USERS';
export const UPDATE_USER = 'UPDATE_USER';

// Users reducer state
export const createUser = 'createUser';
export const deleteUser = 'deleteUser';
export const getUserById = 'getUserById';
export const getUsers = 'getUsers';
export const updateUser = 'updateUser';

// Company reducer state
export const GET_COMPANY_SUMMARY = 'GET_COMPANY_SUMMARY';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Company reducer state
export const MANAGE_DOCUMENTS_MODAL = 'MANAGE_DOCUMENTS_MODAL';
export const UPDATE_MISSING_COUNT = 'UPDATE_MISSING_COUNT';
export const UPDATE_MISSING_DOCUMENTS = 'UPDATE_MISSING_DOCUMENTS';
export const UPDATE_REJECTED_COUNT = 'UPDATE_REJECTED_COUNT';
export const UPDATE_REJECTED_DOCUMENTS = 'UPDATE_REJECTED_DOCUMENTS';
export const RESET_POSTED_DOCUMENTS = 'RESET_POSTED_DOCUMENTS';
export const UPDATE_POSTED_DOCUMENTS = 'UPDATE_POSTED_DOCUMENTS';

// Onboarding Steps reducer
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_IS_UPDATED = 'SET_IS_UPDATED';
export const SET_IS_UPDATE_REQUIRED = 'SET_IS_UPDATE_REQUIRED';
export const SET_LAST_STEP = 'SET_LAST_STEP';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const PERSIST_FORM_DATA = 'PERSIST_FORM_DATA';

// Loading
export const SET_LOADING = 'SET_LOADING';

// API health status
export const SET_API_STATUS = 'SET_API_STATUS';

// Statements reducer
export const SET_DATES = 'SET_DATES';

// Faled Batches
export const SET_FAILED_BATCHES = 'SET_FAILED_BATCHES';

// Invoices reducer state
export const CLEAR_INVOICE = 'CLEAR_INVOICE';
export const SET_INVOICES = 'GET_INVOICES';
export const SET_INVOICE = 'SET_INVOICE';
export const SET_PDF = 'SET_PDF';
export const SET_XML = 'SET_XML';
export const SET_CFDI = 'SET_CFDI';
export const SET_RFCS = 'SET_RFCS';
export const SET_STATUS = 'SET_STATUS';

// Caché Reducer
export const SET_ATTEMPS = 'SET_ATTEMPS';
export const SET_LASTEST_VERSION = 'SET_LASTEST_VERSION';
export const SET_LOADING_VERSIONINNG = 'SET_LOADING_VERSIONINNG';
