import styled from 'styled-components';

export const CardActivationInfoContainer = styled.div`
  width: 30rem;
  height: 46rem;
  display: flex;
  flex-direction: row;
  background-color: #fff;
`;

export const CardBlackBar = styled.div`
  width: 25%;
  height: 100%;
  background-color: #2b2b2b;
  padding: 0 2rem;

  .strip {
    width: 100%;
    height: 100%;
    background-color: #000;
  }
`;

export const CardInfoContainer = styled.div`
  width: 75%;
  height: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const CirrusLogo = styled.img`
  width: 10rem;
  height: 10rem;
  margin-bottom: 1rem;
`;

export const CardHolderName = styled.p`
  font-family: Barlow;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0.1rem 0;
`;

export const CardNumber = styled.p`
  font-family: Barlow;
  font-size: 2.2rem;
  font-weight: 500;
  margin: 0.5rem 0;
  color: rgba(50, 49, 50, 0.4);

  .marginRight {
    margin-right: 0.3rem;
  }

  .lastFourDigits {
    color: #000;
    border-color: red;
    border-width: 0.2rem;
    border-style: solid;
    display: inline-block;
    padding: 0.2rem;
  }
`;

export const CardSecureInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

  .secureInfoDiv {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 1.4rem;

    .secureInfoTitle {
      width: 30%;
      font-size: 1rem;
    }

    .secureInfoValue {
      font-weight: 500;
    }
  }
`;

export const InfoContainer = styled.div`
  width: 100%;

  .danger {
    color: red;
  }

  .muted {
    color: rgba(50, 49, 50, 0.4);
    font-size: 0.8rem;
  }

  .end {
    width: 70%;
  }
`;

export const InfoSpan = styled.span`
  font-size: 1.1rem;
  display: inline-block;
  margin-bottom: 1rem;
`;

export const CardChip = styled.div`
  width: 3rem;
  height: 4rem;
  border-radius: 0.8rem;
  background-image: linear-gradient(
    49deg,
    rgba(232, 222, 143, 1) 0%,
    rgba(113, 176, 106, 0.9472163865546218) 100%
  );
`;

export const SecurityBar = styled.div`
  height: 2.8rem;
  width: 100%;
  margin-bottom: 0.8rem;
  background-color: #d1d1d1;
`;

export const SectionEnd = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
