import React from 'react';

import card from '../../../../Dashboard4.0/assets/images/card-b-2.png';

import {
  CustomButton,
  CustomTitle,
  ContentWrapper,
  FlexContainer,
  SuccessMessage,
  Image
} from './CardActivation.styles';

const Step4 = ({ close }) => {
  return (
    <ContentWrapper>
      <CustomTitle text="Tarjeta activada" />
      <FlexContainer>
        <Image src={card} alt="tarjeta deltai" />
        <SuccessMessage text="Ya puedes empezar a utilizar la tarjeta de crédito para negocios más avanzada de México." />
      </FlexContainer>
      <CustomButton text="Continuar" size="lg" onClick={close} />
    </ContentWrapper>
  );
};

export default Step4;
