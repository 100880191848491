import log from 'loglevel';

import { customAxiosInstance } from '../../../Services/axiosConfig';
import { onboardingStatusListPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const getOnboardingStatusesService = async () => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      onboardingStatusListPath
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'get-onboarding-statuses-service'
    });
  } catch (error) {
    log.error('get-onboarding-statuses-service', error);

    return { error: { message: 'Unable to send request' } };
  }
};
