import {
  CLEAR_BENEFICIARY,
  CLEAR_PAYMENT_ACCOUNT,
  CLEAR_PAYMENT_ORDER,
  CREATE_BENEFICIARY,
  CREATE_PAYMENT_ACCOUNT,
  CREATE_PAYMENT_ORDER,
  GET_BANKING_INSTITUTIONS,
  GET_BENEFICIARIES,
  GET_PAYMENT_ORDER,
  PURGE,
  CLEAR_ORDER,
  SET_BENEFICIARIES,
  SET_PAYMENT_ORDERS
} from './types';

const create_object = {
  data: {},
  success: false,
  error: false,
  res: {}
};

const get_object = {
  res: [],
  success: false,
  error: false
};

const INITIAL_STATE = {
  bankings: get_object,
  beneficiaries: get_object,
  beneficiary: create_object,
  createPaymentOrder: create_object,
  createMultiBeneficiaries: null,
  getPaymentOrder: {
    id: null,
    res: {},
    success: false,
    error: false
  },
  paymentAccount: create_object,
  paymentsOrders: get_object
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_BENEFICIARY:
      return { ...state, beneficiary: create_object };
    case CLEAR_PAYMENT_ACCOUNT:
      return { ...state, paymentAccount: create_object };
    case CLEAR_PAYMENT_ORDER:
      return { ...state, createPaymentOrder: create_object };
    case CLEAR_ORDER:
      return {
        ...state,
        getPaymentOrder: { id: null, res: {}, success: false, error: false }
      };
    case CREATE_BENEFICIARY:
      return { ...state, beneficiary: action.payload };
    case CREATE_PAYMENT_ACCOUNT:
      return { ...state, paymentAccount: action.payload };
    case CREATE_PAYMENT_ORDER:
      return { ...state, createPaymentOrder: action.payload };
    case GET_BANKING_INSTITUTIONS:
      return { ...state, bankings: action.payload };
    case GET_BENEFICIARIES:
      return { ...state, beneficiaries: action.payload };
    case GET_PAYMENT_ORDER:
      return { ...state, getPaymentOrder: action.payload };
    case PURGE:
      return INITIAL_STATE;
    case SET_BENEFICIARIES:
      return {
        ...state,
        beneficiaries: {
          ...state.beneficiaries,
          res: state.beneficiaries.res.filter(
            beneficiary => beneficiary.beneficiary_id !== action.payload
          )
        }
      };
    case SET_PAYMENT_ORDERS:
      return {
        ...state,
        paymentsOrders: { ...state.paymentsOrders, res: action.payload }
      };
    default:
      return state;
  }
};
