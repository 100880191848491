//Yup is a JavaScript object schema validator and object parser check documentation for more details https://www.npmjs.com/package/yup
import * as yup from 'yup';
import { validateMaxAliasLength } from '../../helpers/helpers';

let requiredFieldMessage = 'Campo requerido';

export const validationSchema = yup.object({
  company_type_id: yup.number(),
  company_legal_name: yup.string().when('initialState.company_type_id', {
    is: 1,
    then: yup
      .string()
      .min(2, 'Mínimo 2 caracteres')
      .max(60, 'Máximo 60 caracteres')
      .required(requiredFieldMessage),
    otherwise: yup.string()
  }),
  name: yup
    .string()
    .min(2, 'Mínimo 2 caracteres')
    .max(60, 'Máximo 60 caracteres')
    .required(requiredFieldMessage),
  surname_1: yup
    .string()
    .min(2, 'Mínimo 2 caracteres')
    .max(60, 'Máximo 60 caracteres')
    .required(requiredFieldMessage),
  email: yup
    .string()
    .email('Correo electrónico inválido')
    .test('alias max', 'Máximo 30 caracteres', value => {
      return validateMaxAliasLength(value);
    })
    .required(requiredFieldMessage),
  password: yup
    .string()
    .min(8, 'Mínimo 8 caracteres')
    .required(requiredFieldMessage),
  company_commercial_name: yup.string().when('initialState.company_type_id', {
    is: 1,
    then: yup.string().required(requiredFieldMessage),
    otherwise: yup.string()
  })
});
