const basePath = '/api/dashboard';
const POST = 'post';
const GET = 'get';
const PUT = 'put';
const DELETE = 'delete';

const generalEndpoints = {
  general: [
    { path: '/reset-password', methods: [POST] },
    { path: '/restore-password', methods: [POST] },
    { path: '/company', methods: [GET] },
    { path: '/logout', methods: [GET] },
    { path: '/permissions', methods: [GET] },
    { path: '/password-activation', methods: [PUT] },
    { path: '/user/selfie', methods: [POST] },
    { path: '/health', methods: [GET] },
    { path: '/login', methods: [POST] },
    { path: '/team/profile-completion', methods: [PUT] }
  ]
};

const basicPermissions = {
  'card:view_own': [
    { path: '/cards', methods: [GET] },
    { path: '/card', methods: [GET, POST] }
  ],
  'user:view_own': [{ path: '/team/company/user', methods: [GET] }],
  'transaction:view_own': [
    {
      path: '/spei/',
      methods: [GET, POST, DELETE]
    },
    {
      path: '/transaction',
      methods: [GET]
    },
    {
      path: '/transaction_by_type',
      methods: [GET]
    }
  ],
  departments: [{ path: '/department', methods: [GET] }],
  ...generalEndpoints
};

const adminRestrictions = {
  'user:approve': [{ path: '/admin', methods: [GET, POST, PUT, DELETE] }],
  'user:delete': [{ path: '/admin', methods: [GET, DELETE] }],
  'card:update': [{ path: '/team/update-requests', methods: [GET] }]
};

const basicRules = Object.values(basicPermissions);
const adminRules = Object.values(adminRestrictions);

export const validateRequestPermission = (role, request) => {
  const { method, url } = request;
  const path = url.replace(basePath, '');

  return true; // TO FIX
  // if (role === 1 || !role) return true;
  // return getRolePermissions(method, path, role);
};

const getRolePermissions = (method, path, role) => {
  let validation = role === 2;
  const rules = role === 2 ? adminRules : basicRules;

  const helper = (item, path, method) => {
    if (!item.length) {
      return false;
    }
    if (item[0].length) {
      helper(item[0], path, method);
    } else {
      const pathAndMethodMatch =
        path.includes(item[0].path) && item[0].methods.includes(method);
      const isAdminRestrictedPath = pathAndMethodMatch && role === 2;
      const isBasicUserAllowed = pathAndMethodMatch && role === 3;

      if (isAdminRestrictedPath) {
        validation = false;
        return;
      }
      if (isBasicUserAllowed) {
        validation = true;
        return;
      }
    }
    helper(item.slice(1, item.length), path, method);
  };
  helper(rules, path, method);
  return validation;
};
