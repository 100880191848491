/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { Icon, EmptyState } from './EmptyStateCopy.styles';

const EmptyStateCopy = ({ searchParam, copy }) => {
  return (
    <EmptyState>
      <Icon />
      {copy} <span className="bold">{searchParam}</span>
    </EmptyState>
  );
};

EmptyStateCopy.propTypes = {
  searchParam: PropTypes.string,
  copy: PropTypes.string
};

export default EmptyStateCopy;
