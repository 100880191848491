const EnterPriseBasicInfoCreator = ({
  companyLegalName,
  mercantilBusiness,
  incorporationDate,
  rfcCompany,
  companyNationality,
  companySerialNumberFiel
}) =>
  Object.freeze({
    company_legal_name: companyLegalName,
    mercantil_business: mercantilBusiness,
    nationality_id: companyNationality,
    rfc: rfcCompany,
    serial_number_fiel: companySerialNumberFiel,
    incorporation_date: incorporationDate
  });

export default EnterPriseBasicInfoCreator;
