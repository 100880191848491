import { makeStyles } from '@material-ui/core/styles';

export const CustomModalStyles = makeStyles(theme => ({
  buttonsCenter: {
    display: 'flex',
    margin: '2rem 1.5rem 0',
    width: '90%',
    justifyContent: 'center',
    textAlign: 'center'
  },
  cameraRedesign: {
    width: '45rem',
    height: '30rem',
    [theme.breakpoints.only('xs')]: {
      width: '25rem',
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    'z-index': '2',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  faceMask: {
    width: 'inherit',
    height: 'inherit',
    position: 'absolute',
    zIndex: '1000',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  miniImg: {
    height: '4.5rem',
    width: '8rem',
    marginLeft: '0.5rem'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalHeader: {
    padding: '1rem'
  },
  modalTitle: {
    fontSize: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem'
    },
    verticalAlign: 'middle',
    width: '30rem',
    textAlign: 'center'
  },
  modalTitleRedesign: {
    fontSize: '2.4rem',
    fontFamily: 'Quicksand',
    textAlign: 'center',
    width: '100%',
    paddingBottom: '3rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.4rem',
      paddingBottom: '0.5rem'
    }
  },
  paper: {
    position: 'absolute',
    width: '50rem',
    [theme.breakpoints.only('xs')]: {
      width: '80vw'
    },
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  paperRedesign: {
    border: 'none',
    borderRadius: '1rem',
    width: '57rem',
    padding: '3.1rem 7rem',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem'
    }
  },
  pictureEdges: {
    position: 'absolute',
    top: '21rem',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      width: '2.4rem',
      height: '2.4rem'
    }
  },
  pictureStyles: {
    position: 'absolute',
    width: '419px',
    height: '280px',
    'z-index': '2',
    border: 'solid #0000008c',
    borderWidth: '3.8rem 2.8rem 4.9rem 2.8rem',
    fontSize: '1.1rem',
    color: '#fff',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    letterSpacing: '0.2125px'
  },
  marginZero: {
    margin: 0
  },
  separator: {
    margin: '3rem 0',
    width: '100%',
    height: '1px',
    background: 'rgba(28, 38, 51, 0.5)'
  },
  text: {
    fontSize: '1.6rem',
    fontFamily: 'Quicksand',
    width: '100%',
    textAlign: 'center'
  },
  fileInput: {
    width: '80%'
  },
  buttonLg: {
    width: '100%',
    marginTop: '3rem'
  },
  copyMessage: {
    fontFamily: 'Quicksand',
    fontSize: '1.1rem',
    fontWeight: '400',
    letterSpacing: '0.218182px',
    margin: '1rem 0 1.2rem'
  }
}));
