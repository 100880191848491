import log from 'loglevel';

import { customAxiosInstance } from '../../../../Services/axiosConfig';
import { extractContentFromResponse } from '../../../../helpers/extractContentFromResponse';

const spendLimitPath = '/api/dashboard/user/spend-limit';

export const getSpendLimitService = async () => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      spendLimitPath
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'spend-limit-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('spend-limit-service');
    return { error: { message: 'Unable to send request' } };
  }
};
