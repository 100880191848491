/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../shared/components/Button';
import Details from '../../../shared/components/Details';
import Title from '../../../shared/components/Title';

import {
  BeneficiaryDeleteOverlay,
  RemoveEntryDetailsContainer
} from './BeneficiaryEntry.styles';

const RemoveEntry = ({ alias, handleCancel, handleDelete, ...props }) => {
  return (
    <BeneficiaryDeleteOverlay {...props} data-test="removeEntryComponent">
      <RemoveEntryDetailsContainer>
        <Title className="titleRemove" text="Eliminar beneficiario" />
        <Details className="detailsRemove" mainText={alias} />
      </RemoveEntryDetailsContainer>

      <div className="buttonsContainer">
        <Button
          text="Cancelar"
          size="sm"
          variant="overlaySecondary"
          onClick={handleCancel}
          data-test="cancelButton"
          className="buttonRemoveCancel"
        />
        <Button
          style={{ marginLeft: '1.6rem' }}
          text="Eliminar"
          size="sm"
          variant="overlayPrimary"
          onClick={handleDelete}
          data-test="deleteButton"
        />
      </div>
    </BeneficiaryDeleteOverlay>
  );
};

RemoveEntry.propTypes = {
  alias: PropTypes.string,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func
};

export default RemoveEntry;
