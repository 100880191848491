import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 3.4fr;
  margin-bottom: 3.9rem;
  width: 50%;
  float: right;
  .label {
    font-size: 1.4rem;
    font-weight: 500;
  }
  .link {
    margin-top: 0.7rem;
    text-decoration-line: underline;
    text-decoration-color: #939ca9;
    &:hover {
      cursor: pointer;
    }
  }
  @media (min-width: 360px) {
    width: 100%;
  }
`;
