import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const RadioButtonsContainer = styled.div`
  font-size: 1.4rem;

  .inputsContainer {
    margin: 1.2rem 0 2.1rem;
  }

  input,
  label:first-child {
    margin-right: 1.2rem;
  }

  input[type='radio'] {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid black;
    outline: none;
    cursor: pointer;
    &:checked {
      background: ${colors.deltai};
      border-color: ${colors.deltai};
    }
  }

  .tooltip {
    right: 0;
  }
`;
