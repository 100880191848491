import { customAxiosInstance } from '../../../Services/axiosConfig';
import log from 'loglevel';
import { updateCardStatusPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const changeCardStatusService = async ({
  cardId,
  cardStatusId,
  userId
}) => {
  try {
    const { data, status, error } = await customAxiosInstance().put(
      `${updateCardStatusPath}${cardId}`,
      {
        card_status_id: cardStatusId,
        user_id: userId
      }
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'change-card-status-service'
    });
  } catch (error) {
    log.error('change-card-status-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
