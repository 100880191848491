import styled from 'styled-components';
import { fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const getColorAccordingStatus = ({ status }) => {
  const colors = {
    approved: '#1790CF',
    pending: '#939CA9',
    rejected: '#ED2246',
    reversal: '#1790CF'
  };

  return colors[status] || colors.pending;
};

export const Container = styled.div`
  padding-bottom: 1rem;
`;

export const Label = styled.span`
  ${fonts.quickSandSemiBold};
  font-size: 1.35rem;
  line-height: 19px;
  letter-spacing: 0.17px;
  display: block;
  color: #939ca9;
  word-break: break-word;
`;

export const StatusLabel = styled.span`
  ${fonts.barlowMedium};
  font-size: 1.5rem;
  line-height: 19px;
  letter-spacing: 0.9px;
  display: block;
  color: ${getColorAccordingStatus};
  word-break: break-word;
`;

export const Value = styled.span`
  ${fonts.barlowRegular};
  font-size: 1.55rem;
  line-height: 18.6px;
  letter-spacing: 0.91px;
  display: block;
  word-break: break-word;
`;

export const Message = styled.span`
  ${fonts.barlowRegular};
  font-size: 1.55rem;
  line-height: 18.6px;
  letter-spacing: 0.91px;
  display: block;
  word-break: break-word;
`;
