import * as yup from 'yup';
import { patterns_metadata } from '../../../../catalogs/patterns';
import {
  INVALID_DATE_MESSAGE,
  LENGTH_MESSAGE,
  REQUIRED_MESSAGE
} from '../../constants';
import { differenceInYears } from 'date-fns';

export const validationSchema = yup
  .object({
    gender: yup.string().required(REQUIRED_MESSAGE),
    birthDate: yup
      .date()
      .required(REQUIRED_MESSAGE)
      .test('birthDate greater than 18', 'Debes ser mayor de edad', value => {
        return differenceInYears(new Date(), value) >= 18;
      }),

    countryOfBirth: yup.string().required(REQUIRED_MESSAGE),
    curp: yup
      .string()
      .length(18, 'Debes ingresar 18 caracteres')
      .matches(patterns_metadata.patternCurp, 'Ingresa un CURP válido')
      .required(REQUIRED_MESSAGE),
    rfc: yup
      .string()
      .min(13, LENGTH_MESSAGE)
      .max(13, LENGTH_MESSAGE)
      .matches(patterns_metadata.patternRFC, 'Ingresa un RFC válido')
      .required(REQUIRED_MESSAGE),
    occupation: yup.string().required(REQUIRED_MESSAGE),
    nationality: yup.string().required(REQUIRED_MESSAGE),
    serialNumberFiel: yup.string().required(REQUIRED_MESSAGE)
  })
  .shape();
