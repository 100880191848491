import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

//components
import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/';

//redux
import { userEmailSelector } from '../../redux/register-selectors';

//styles
import { StyledButton as Button, Container } from './styles';

import { service } from '../../service';

const RegistrationSuccess = ({ history }) => {
  const userEmail = useSelector(userEmailSelector);

  const { email } = history.location?.state || { email: userEmail };

  const resendEmail = () => {
    service().resendActivation(email);
  };

  useEffect(() => {
    if (!email) {
      history.push('/login');
    }
  }, [history, email]);

  return (
    <RegistrationPageLayout
      title="Revisa tu correo"
      isPrimaryButtonHidden={true}
      isModalVisible={true}
      showLogout={true}
    >
      <Container>
        <p>
          Te hemos enviado un correo a {email}. Por favor confirma tu cuenta
          dándole click al botón de &quot;Verificar correo electrónico.&quot;
        </p>
        <p>
          Tip: Accede a tu correo desde tu computador para una mejor experiencia
          (en vez de tu teléfono{' '}
          <span role="img" aria-label="emoji">
            😬
          </span>
          )
        </p>
        <p>Si no lo has recibido aún, dale click a :</p>
        <Button
          text="Reenviar correo"
          variant="outlined"
          onClick={resendEmail}
        />
      </Container>
    </RegistrationPageLayout>
  );
};

export default RegistrationSuccess;
