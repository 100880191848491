import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const ModalContent = styled.div`
  .flexAlign {
    display: flex;
    justify-content: center;
  }

  .two-factor-auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0;
  }

  .two-factor-auth-input {
    width: 60px;
    height: 80px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    margin-right: 12px;
    text-transform: uppercase;
    color: #000;
    font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
      sans-serif;
    border: 1px solid #dadada;
    border-radius: 10px;
    background: #fff;
    background-clip: padding-box;

    @media (max-width: 580px) {
      width: 45px;
      height: 65px;
    }
  }

  .two-factor-auth-input-error {
    border: 1px solid ${colors.deltai};
  }

  .primaryButton,
  .secondaryButton {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
  }

  .secondaryButton {
    width: 90%;
  }

  .two-factor-auth-QRcode-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .two-factor-secret-container {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .two-factor-secret {
    font-weight: 600;
    font-size: 14px;
  }

  .copy-secret-icon-container {
    cursor: pointer;
  }

  .two-factor-auth-otp-error {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: ${colors.deltai};
    margin-top: -40px;
    margin-bottom: 40px;
  }
`;
