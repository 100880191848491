import styled from 'styled-components';
import { fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    .tooltipText {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const TooltipText = styled.span`
  visibility: hidden;
  width: fit-content;
  background: rgba(38, 51, 67, 0.95);
  border-radius: 5px;
  color: #b4bac3;
  text-align: center;
  padding: 0.5rem 1.2rem;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 75%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 11px;
  ${fonts.barlowMedium}
`;
