import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Grid, withWidth } from '@material-ui/core';
import { useFormik } from 'formik';

import CustomTextField from '../CustomTextField';

import { Context as formContext } from '../../../../context/formContext';
import { getSpacing } from '../../../../helpers';
import { validateName } from '../validator';

const initialState = {
  name: '',
  surname_1: '',
  surname_2: ''
};

const FormName = ({ resetForm, width }) => {
  const [formData, setFormData] = useState(initialState);

  const { setFormName } = useContext(formContext);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validateName,
    enableReinitialize: true
  });

  const handleChange = e => {
    formik.handleChange(e);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    setFormName({
      isValid: formik.isValid,
      values: formik.values
    });
    // eslint-disable-next-line
  }, [formik.isValid, formik.values]);

  useEffect(() => {
    if (resetForm) {
      setFormData(initialState);
      setFormName({
        isValid: false,
        values: initialState
      });
      formik.resetForm();
    }
    //eslint-disable-next-line
  }, [resetForm]);

  return (
    <Fragment>
      <CustomTextField
        label="Nombre(s)"
        name="name"
        onChange={handleChange}
        error={formik.errors.name}
        value={formData.name}
      />
      <Grid container spacing={getSpacing(width)}>
        <Grid item xs={12} md>
          <CustomTextField
            label="Apellido paterno"
            name="surname_1"
            onChange={handleChange}
            error={formik.errors.surname_1}
            value={formData.surname_1}
          />
        </Grid>
        <Grid item xs={12} md>
          <CustomTextField
            label="Apellido materno"
            name="surname_2"
            onChange={handleChange}
            error={formik.errors.surname_2}
            value={formData.surname_2}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withWidth()(FormName);
