import log from 'loglevel';

import { customAxiosInstance } from '../../../../../Services/axiosConfig';
import { extractContentFromResponse } from '../../../../../helpers/extractContentFromResponse';
import { nationalCreditPath } from './api-paths';

export const getCreditCardMovement = async (trackingKey) => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      nationalCreditPath(trackingKey),
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'get-credit-card-movement',
      isSingleElement: true,
    });
  } catch (error) {
    log.error('get-credit-card-movement', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
