import styled, { css } from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

const classes = css`
  .download path {
    fill: ${colors.sense1};
  }
  .mail path {
    fill: ${colors.blue2faced};
  }
  .rejected {
    color: ${colors.pallet0Text};
  }
  .cursor:hover {
    cursor: pointer;
  }

  .overlayArea {
    overflow: visible;
    width: auto;
  }

  .overflow {
    overflow: auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  ${classes}

  .detailsComponent {
    flex-wrap: wrap;
  }
`;

export const HideContainer = styled.div`
  margin-top: 100rem;
  position: absolute;
`;
