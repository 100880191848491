import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import Clamp from 'react-multiline-clamp';
import { copyToClipboard } from '../../../../helpers/helpers';

import Avatar from '../../../shared/components/Avatar/Avatar';
import Button from '../../../shared/components/Button/Button';
import { Concept } from '../../../credit/components/EntryBalance/EntryBalance.styles';
import Icon from '../../../shared/components/Icon/Icon';
import InfoBar from '../../../shared/components/InfoBar/InfoBar';
import InfoBarItem from '../../../shared/components/InfoBar/InfoBarItem';
import Label from '../../../shared/components/Label/Label';

// import { InfoBar } from './AccountDetails.styles.js';

import { avatarColors } from '../../../../Dashboard4.0/assets/styles/styles';

const AccountDetails = () => {
  const companySummary = useSelector(
    state => state.companyReducer.companySummary
  );
  const companyInformation = useSelector(
    state => state.userInfoReducer.user.company_information
  );

  const [clabe] = useState(
    companySummary ? companySummary.clabe_code : 'No disponible'
  );
  const [payerName] = useState(
    companyInformation ? companyInformation.payer_name : 'No disponible'
  );

  const [buttonText, setButtonText] = useState('Copiar datos');

  const copyData = () => {
    let data = `
  Cuenta: ${payerName}
  CLABE: ${clabe}
  Banco: STP
  `;
    copyToClipboard(data);
    changeButtonText();
  };

  const changeButtonText = () => {
    setButtonText('Copiados');
    setTimeout(() => {
      setButtonText('Copiar datos');
    }, 1500);
  };

  return (
    <InfoBar
      data-test="accountDetails"
      start={
        <>
          <InfoBarItem style={{ minWidth: 'auto' }} data-test="contentStart">
            <Avatar colors={avatarColors[7]}>
              <Icon
                className="avatarIconColor"
                height="3rem"
                width="3rem"
                icon="deltai"
              />
            </Avatar>
          </InfoBarItem>
          <InfoBarItem
            style={{ minWidth: 'auto' }}
            tooltipContent={payerName}
            align={'flex-start'}
            margin={'20px'}
          >
            <Label text="CUENTA" />
            <Concept>
              <Clamp lines={2}>
                <span>{payerName}</span>
              </Clamp>
            </Concept>
          </InfoBarItem>
          <InfoBarItem
            style={{ minWidth: 'auto' }}
            align={'flex-start'}
            margin={'20px'}
          >
            <Label text="MI CLABE" />
            <Concept text={clabe} />
          </InfoBarItem>
          <InfoBarItem
            style={{ minWidth: 'auto' }}
            align={'flex-start'}
            margin={'20px'}
          >
            <Label text="MI BANCO" />
            <Concept text="STP" />
          </InfoBarItem>
        </>
      }
      button={
        <Button
          success={buttonText === 'Copiados'}
          text={buttonText}
          data-test="copyButton"
          onClick={copyData}
        />
      }
    />
  );
};

export default memo(AccountDetails);
