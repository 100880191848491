import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const getColumns = ({ action }) => {
  if (action)
    return `
      grid-template-columns: 8fr 5fr;
      `;
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12rem 0;
  border-bottom: 0.1rem solid ${colors.carbon3};

  & > svg {
    filter: drop-shadow(20px 20px 100px rgba(0, 0, 0, 0.2));
  }
`;

export const EmptyContainer = styled.div`
  width: 45.9rem;
  height: 8.1rem;
  margin-left: 2.9rem;

  button {
    margin-left: 1.6rem;
    width: max-content;
  }
`;

export const Grid = styled.div`
  width: 100%;
  margin-top: 0.8rem;

  display: grid;
  ${getColumns}
`;

export const Description = styled.span`
  font-family: ${fonts.barlowRegular};
  font-size: 1.55rem;
  line-height: 1.45;
  letter-spacing: 0.19px;
  color: ${colors.body3};
`;
