import React, { useEffect, useState } from 'react';

import Layout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';
import Calendly from '../../../shared/components/Calendly/Calendly';
import { calendlyURL } from '../../constants';

import { ModalDescription, Wrapper } from './styles.js';

import { useLocation } from 'react-router-dom'

import { service } from '../../service/index';
import HubSpotScheduler from '../../components/HubSpotScheduler';

const ScheduleInterviewPage = ({ history }) => {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const base64Encoding = searchParams.get('k');

  let [signUp, setSignUp] = useState(true);

  const goToNextStep = async () => {
    const customeInfo = JSON.parse(atob(base64Encoding));
    const customerUuid = customeInfo.customer_uuid
    if(customerUuid){
      const { isSuccess } = await service().getCustomerData({
        customerUuid
      });
      if(isSuccess){
        history.push('/tpa/onboarding/softwall');
      }else{
        console.log("interview error");
      }
    }
  };

  useEffect(()=>{
    const callSignUp = async () => {
      console.log("base64Encoding ", base64Encoding);
      const { isSuccess } = await service().getSignUp({
        base64Encoding
      })
      if(!isSuccess){
        console.log("something went wrong!");
        setSignUp(false);
      }
    }
    callSignUp();
  }, [])
  
  return (
    <>
      {signUp ? (<Wrapper>
        <Layout
          showLogout
          title="Agenda tu entrevista de crédito"
          description={
            <ModalDescription>
              Durante la entrevista, te haremos preguntas acerca de tu negocio necesarias para la aprobación de tu aplicación de crédito.
            </ModalDescription>
          }
          isPrimaryButtonHidden
          isModalVisible
        >
          <Calendly
            calendlyUrl={calendlyURL}
            className="calendly"
            onScheduled={goToNextStep}
          />
        </Layout>
      </Wrapper>): null}
    </>
  );
};

export default ScheduleInterviewPage;
