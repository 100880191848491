import { customAxiosInstance } from '../../../Services/axiosConfig';
import log from 'loglevel';
import { approveCardCreationPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const approveCardCreationRequestService = async ({ cardRequestId }) => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      `${approveCardCreationPath}${cardRequestId}`
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'approve-card-creation-service'
    });
  } catch (error) {
    log.error('approve-card-creation-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
