import React, { useState } from 'react';

import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, InputAdornment, IconButton } from '@material-ui/core';

import Button from '../../micro-frontends/shared/components/Button/Button';
import Contract from '../FEAContract/Contract';
import MultipartFileInput from '../../micro-frontends/shared/components/MultipartFileInput/MultipartFileInput';
import TextField from '../../micro-frontends/shared/components/TextField';
import { Alert } from '@material-ui/lab';

import {
  contractFea,
  getDigitalContract,
  verifyDocument
} from '../../Services/fea';
import { ReactComponent as LockSvg } from './lock-red.svg';
import { ReactComponent as LockSvgGray } from './lock-gray.svg';
import { updateUserStatus } from '../../actionCreators/authAction';
import { useFormik } from 'formik';
import { validation } from './validation';
import { useMixPanel } from '../../micro-frontends/shared/mixpanel';
import { signContractPath } from '../../micro-frontends/sign-contract/constants';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    maxWidth: '600px',
    margin: '0 auto'
  },
  buttonContract: {
    position: 'inherit !important'
  },
  buttonContainer: {
    marginTop: '5rem'
  },
  cards: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkbox: {
    marginTop: '1.5rem',
    fontSize: '1.6rem',
    color: '#666566',
    display: 'flex'
  },
  error: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1.2rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginTop: '8px',
    minHeight: '1em',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: '0.03333em',
    marginLeft: '2.4rem'
  },
  errorContract: {
    color: '#ed2246',
    fontSize: '1.2rem',
    fontWeight: '400',
    letterSpacing: '0.03333em',
    textAlign: 'center'
  },
  fileInput: {
    width: '32rem'
  },
  textField: {
    margin: '0 auto',
    paddingBottom: '3.2rem',
    width: '100%'
  },
  instructions: {
    color: '#989898',
    fontSize: '1.6rem',
    textAlign: 'center'
  },
  instructionsDark: {
    color: '#323132'
  },
  title: {
    fontFamily: 'Quicksand',
    fontSize: '2.4rem',
    textAlign: 'center',
    color: '#1C2633'
  },
  text: {
    fontFamily: 'Quicksand',
    fontSize: '1.4rem',
    textAlign: 'center',
    color: '#1C2633'
  },
  contractButtonContainer: {
    textAlign: 'center'
  },
  contractContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  downloadButton: {
    position: 'absolute',
    fontSize: '1.4rem',
    color: '#989898'
  },
  agreementTitle: {
    fontSize: '2.4rem',
    color: '#323132'
  },
  checkboxLabel: {
    width: 'fit-content'
  }
});

const FEA = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [agreement, setAgreement] = useState(false);
  const [creditReport, setCreditReport] = useState(false);
  const [contract, setContract] = useState(false);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [sendMixPanelEvent] = useMixPanel();

  const initialState = {
    agreement: false,
    certificate: '',
    creditReport: false,
    keyfile: '',
    password: ''
  };

  const formik = useFormik({
    initialValues: {
      initialState
    },
    isInitialValid: validation.isValidSync(initialState),
    validationSchema: validation
  });

  const goBack = () => history.push(signContractPath);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getContract = () => {
    getDigitalContract()
      .then(response => {
        setContract('data:application/pdf;base64,' + response.data[0].contract);
      })
      .catch(error => log.error('Loglevel: ', error));
  };

  const saveFile = async (e, type) => {
    if (!e.target.file) return;
    const file = e.target.file;
    const fileData = {
      target: {
        value: {
          name: file.name,
          lastModified: file.lastModified,
          size: file.size,
          type: file.type,
          file: file
        }
      }
    };

    fileData.target['name'] = type;
    formik.handleChange(fileData);
  };

  const setPassword = e => {
    formik.handleChange(e);
  };

  const setCheckboxVal = (name, val) => {
    const value = {
      target: {
        value: val,
        name: name
      }
    };

    formik.handleChange(value);
    name === 'agreement' ? setAgreement(val) : setCreditReport(val);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const sign = () => {
    const transformKycMultipart = () => {
      const formData = new FormData();
      formData.append('certificate', formik.values.certificate.file);
      formData.append('key_file', formik.values.keyfile.file);
      formData.append('password', formik.values.password);

      return formData;
    };

    const body = transformKycMultipart();

    verifyDocument(body)
      .then(data => {
        if (data.status === 200) {
          body.append('timestamp', moment.utc());
          body.append('file_to_sign', contract);

          delete body.certificate;

          contractFea(body)
            .then(data => {
              if (data.status === 200) {
                updateUserStatus('User validated', dispatch);
                sendMixPanelEvent('New customer', {});
                history.push('/activity');
              }
            })
            .catch(e => log.error('Loglevel: ', e));
        }
      })
      .catch(e => log.error('Loglevel: ', e));
  };

  useEffect(() => {
    return getContract();
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <h1 className={classes.title}>Firma de Contrato </h1>
        <p className={classes.text}>
          Firma tu contrato digitalmente con el certificado y llave privada de
          tu e.firma emitida por el SAT
        </p>
      </div>
      <div className={classes.contractButtonContainer}>
        <Button
          variant="outlined"
          text="Revisa tu contrato"
          className={`logoutBtn ${classes.buttonContract}`}
          onClick={handleOpen}
        />
      </div>

      <div style={{ padding: '0 1.2rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.cards}>
            <MultipartFileInput
              className={classes.fileInput}
              label="Certificado (.cer)"
              type="file"
              onChange={e => saveFile(e, 'certificate')}
              documentName="certificate"
              accept={'.cer'}
            />
            {formik?.errors?.certificate && (
              <Alert severity="error">{formik.errors.certificate}</Alert>
            )}

            <MultipartFileInput
              className={classes.fileInput}
              label="CLAVE privada (.key)"
              type="file"
              onChange={e => saveFile(e, 'keyfile')}
              documentName="keyfile"
              accept={'.key'}
            />
            {formik?.errors?.keyfile && (
              <Alert severity="error">{formik.errors.keyfile}</Alert>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Contraseña de la firma electrónica"
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            margin="normal"
            value={formik.password}
            onChange={setPassword}
            onBlur={formik.handleBlur}
            error={formik.errors.password && formik.touched.password}
            helperText={formik.errors.password}
            autoComplete={'off'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <LockSvgGray /> : <LockSvg />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>

        <div className={classes.checkbox}>
          <input
            id="agreement"
            name="agreement"
            type="checkbox"
            className="checkbox-round"
            onChange={e => setCheckboxVal('agreement', !agreement)}
            onBlur={formik.handleBlur}
          />

          <label htmlFor="agreement" className={classes.checkboxLabel}>
            Acepto el contrato y sus cláusulas
          </label>
        </div>
        <Contract contract={contract} open={open} handleClose={handleClose} />
        {formik.errors.agreement ? (
          <div className={classes.error}>{formik.errors.agreement}</div>
        ) : null}
        <div className={classes.checkbox}>
          <input
            id="creditReport"
            name="creditReport"
            type="checkbox"
            className="checkbox-round"
            onChange={e => setCheckboxVal('creditReport', !creditReport)}
            onBlur={formik.handleBlur}
          />
          <label htmlFor="creditReport" className={classes.checkboxLabel}>
            Acepto que delt.ai consutle mi historial crediticio en el Buró de
            Crédito
          </label>
        </div>
        {formik.errors.creditReport ? (
          <div className={classes.error}>{formik.errors.creditReport}</div>
        ) : null}

        <div className={classes.buttonContainer}>
          {!contract ? (
            <div className={classes.errorContract}>
              Error al traer tu contrato, por favor inténtalo más tarde
            </div>
          ) : null}

          <Button
            text="Continuar"
            disabled={!formik.isValid || !contract}
            onClick={sign}
            className="formButton"
          />
          <Button
            onClick={goBack}
            text="Regresar"
            className="formButton"
            variant="overlaySecondary"
          />
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default FEA;
