/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  AmountContainer,
  Concept,
  Digits,
  InterestRate,
  ItemContainer
} from './CommissionsAndFees.styles';

const Item = ({ concept, interestRate, amount }) => {
  return (
    <ItemContainer>
      <Concept text={concept} />
      <InterestRate text={interestRate} />
      <AmountContainer>
        <Digits text={amount} />
      </AmountContainer>
    </ItemContainer>
  );
};

Item.propTypes = {
  concept: PropTypes.string,
  interestRate: PropTypes.string,
  amount: PropTypes.string
};

export default memo(Item);
