import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const getColumns = ({ columns }) => {
  let templateColumns = 'grid-template-columns:  10fr 3fr 2fr';
  if (columns) templateColumns = `grid-template-columns: ${columns};`;

  return templateColumns;
};

export const HeaderContainer = styled.div`
  height: 4.7rem;
  width: 100%;

  display: grid;
  align-items: center;
  justify-items: center;

  @media (max-width: 768px) {
    & > span:nth-child(1) {
      grid-column: 1 / 2;
      padding-left: 1rem;
    }
    width: auto;
  }

  & span:first-child {
    padding-left: 2.4rem;
  }

  border-radius: 1.2rem;
  box-shadow: 0 11px 22px -8px #e5e8ed;
  border: solid 1px ${colors.carbon3};
  background-color: ${colors.carbon4};
  color: ${colors.carbon};
  font-family: ${fonts.barlowSemiBold};
  font-size: 1.2rem;
  line-height: 1.08;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
  ${getColumns}
`;
