/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { CustomLink } from './Link.styles';

const Link = ({ to, children, ...otherProps }) => {
  return (
    <>
      {to ? (
        <CustomLink href={to} {...otherProps}>
          {children}
        </CustomLink>
      ) : null}
    </>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
  otherProps: PropTypes.any
};

export default Link;
