import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';
import {
  AmountContainer,
  Container,
  Details,
  DetailsContainer,
  DetailsLabel,
  DetailsText,
  Logo,
  MainTitle,
  ModalContainer,
  TrackingKeyContainer
} from './ProofOfPayments.styles';

export const CustomContainer = styled(Container)`
  width: 70rem;
  background: #b4b8bc;
`;

export const CustomDetailsContainer = styled(DetailsContainer)`
  width: 60.4rem;
  margin: 10rem 0 6rem;
  max-width: 100%;
`;

export const CustomAmount = styled(AmountContainer)`
  background: #cbc3db;
  width: 37.4rem;
  height: 14.6rem;
`;

export const CustomLogo = styled(Logo)`
  width: 12rem;
  height: 6.4rem;
`;

export const CustomMain = styled(MainTitle)`
  font-size: 2rem;
  letter-spacing: 0.19px;
`;

export const CustomSecondary = styled(CustomMain)`
  letter-spacing: 0.91px;
  color: ${colors.carbon};
`;

export const CustomBaseDescription = styled(CustomMain)`
  margin: 2rem 0 8.2rem;
  color: ${colors.carbon};
  text-align: center;
`;

export const CustomDescription = styled(CustomBaseDescription)`
  padding: 2rem 2.4rem;
  margin: 8rem 0 0;
  color: ${colors.body};
  padding: 2rem 0;
  margin: 2rem 0 0;
  background: ${colors.carbon4};
`;

export const CustomContent = styled.div`
  padding: 4.6rem 4rem 0 4rem;
  width: 57.4rem;

  padding: 4.6rem 4rem 0 4rem;
  width: 57.4rem;
`;

export const CustomDetails = styled(Details)`
  display: grid;
  grid-template-columns: 6.5fr 10fr;
  margin: 2rem 0 0 0;
`;

export const CustomDetailsLabel = styled(DetailsLabel)`
  font-size: 2.35rem;
  line-height: 1.41;
  letter-spacing: 0.17px;
`;

export const CustomDetailsText = styled(DetailsText)`
  font-size: 2.5rem;
  letter-spacing: 0.19px;
`;

export const KeyContainer = styled(TrackingKeyContainer)`
  height: 9.2rem;
`;

export const CustomModal = styled(ModalContainer)`
  width: 61rem;
  height: 100rem;
`;
