const registerUserHandler = ({ registerUser }) => async userData => {
  const { error, data } = await registerUser(userData);

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  return { isSuccess: true, data };
};

export { registerUserHandler };
