import React from 'react';
import card_icon from '../../../../assets/card_icon.png';
import LimitsIcon from '../../../../assets/Limit_de_gasto.png';
import TagsIcon from '../../../../assets/Tags.png';
import CardTypeLabel from './CardTypeLabel';
import { AvatarsContainer } from './styles';

const CardInfo = [
  {
    header: 'Límite de gasto',
    primaryInfo:
      'Este es el monto que podrás gastar en esta tarjeta por mes. Es un límite que decides tú para controlar su gasto. Se resetea cada día 25 junto con tu ciclo de crédito. ',
    icon: LimitsIcon
  },
  {
    header: 'Tags',
    primaryInfo:
      'Categoriza tus gastos de la manera más fácil y personalizada. Al asignar un Tag a una tarjeta, automáticamente sus gastos vendrán tagueados.',
    icon: TagsIcon
  },
  {
    header: 'Virtual vs. física',
    primaryInfo: `Las tarjetas virtuales se generan y están disponibles al instante, y son la mejor opción para compras y pagos en línea.`,
    secondaryinfo: `      
      Las tarjetas físicas serán enviadas a la dirección que nos digas y son preferibles para gastos en persona, como viajes o restaurantes.`,
    icon: card_icon
  }
];

const CardTypeInfo = () => {
  return (
    <div>
      <AvatarsContainer>
        {CardInfo.map(({ header, primaryInfo, secondaryinfo, icon }, index) => {
          return (
            <CardTypeLabel
              key={index}
              header={header}
              primaryInfo={primaryInfo}
              secondaryinfo={secondaryinfo}
              icon={icon}
            />
          );
        })}
      </AvatarsContainer>
    </div>
  );
};

export default CardTypeInfo;
