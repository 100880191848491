const clabeValidation = clabe => {
  const cleanClabe = clabe.replace(/\s/g, '');
  const clabeArr = cleanClabe.split('');
  const clabeFiltered = clabeArr.filter(el => !Number.isNaN(parseInt(el)));
  return clabeFiltered.length === 18;
};

const hasValidTransactionLength = (transaction, length) =>
  transaction.filter(item => item !== '').length === length;

const isNumber = (value) => Number.isNaN(Number(value));

const hasValidPaymentConceptLength = concept => concept.length <= 40;

const hasValidReferenceLength = reference => reference.length <= 7;

const validateReferenceNumber = referenceNumber =>
  Number.isInteger(Number(referenceNumber));

const beneficiaryExist = (beneficiaries, clabe) => {
  return beneficiaries
    .map(beneficiary => beneficiary.beneficiary_account_code)
    .includes(clabe);
};

const validationNewsBeneficiaries = (row, index, cols, beneficiaries) => {
  return (
    index > 0 &&
    validationSuccessTransactions(row, cols, beneficiaries) &&
    !beneficiaryExist(beneficiaries, row[0]) &&
    clabeValidation(row[0])
  );
};

const validationSuccessTransactions = (row, cols, beneficiaries) => {
  let referenceNumber

  switch (cols.length) {
    case 7:
      referenceNumber = row[6]

      return (
        hasValidTransactionLength(row, 7) &&

        // 'clabe' (CLABE code)
        clabeValidation(row[0]) &&

        // 'conceptoPago' (description)
        hasValidPaymentConceptLength(row[4]) &&

        // 'monto' (amount)
        !isNumber(row[5]) &&

        // 'referenciaNumerica' (reference number)
        hasValidReferenceLength(referenceNumber) &&
        validateReferenceNumber(referenceNumber)
      );
    case 4:
      referenceNumber = row[3]

      return (
        hasValidTransactionLength(row, 4) &&

        // 'monto' (amount)
        !isNumber(row[0]) &&

        // 'cuentaBeneficiario' (CLABE code)
        clabeValidation(row[1]) &&

        // 'conceptoPago' (description)
        hasValidPaymentConceptLength(row[2]) &&

        // 'referenciaNumerica' (reference number)
        hasValidReferenceLength(referenceNumber) &&
        validateReferenceNumber(referenceNumber)
      );
    default:
      return false;
  }
};

export const getTransactions = (rows, cols, beneficiaries) => {
  const headers = rows[0];
  const errors = [headers];
  const success = [headers];
  const duplicates = [headers];

  rows
    .map(
      row => row.map(
        el => el.trim()
      )
    )
    .forEach((row, index) => {
    if (index === 0) return;

    const isValid = validationSuccessTransactions(row, cols, beneficiaries);

    if (!isValid) {
      errors.push(row);
    } else if (
      isValid &&
      index > 0 &&
      rows.map(el => el.join()).indexOf(row.join()) !== index
    ) {
      duplicates.push(row);
    } else {
      success.push(row);
    }
  });

  const news = rows
    .filter((row, index) => {
      return (
        index === 0 ||
        validationNewsBeneficiaries(row, index, cols, beneficiaries)
      );
    })
    .filter((row, index, self) => {
      return self.map(el => el[0]).indexOf(row[0]) === index;
    });

  return {
    success,
    duplicates,
    errors,
    news
  };
};
