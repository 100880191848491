export const AddressCreator = ({
  data: {
    postal_code,
    settlement,
    municipality,
    state_id,
    city,
    street,
    external_num,
    internal_num
  }
}) =>
  Object.freeze({
    postalCode: postal_code,
    settlement: settlement,
    municipality: municipality,
    stateId: state_id,
    city: city,
    street: street,
    externalNum: external_num,
    internalNum: internal_num
  });
