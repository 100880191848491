import {
  API_DOCUMENTATION,
  API_DOCUMENTATION_ERROR,
  HEADERS,
  PYTHON_SDK_URL
} from './apidocs-types';

export const setDocumentationAction = documentation => ({
  type: API_DOCUMENTATION,
  payload: documentation
});

export const setDocumentationErrorAction = error => ({
  type: API_DOCUMENTATION_ERROR,
  payload: error
});

export const setHeaders = headers => ({
  type: HEADERS,
  payload: headers
});

export const setPythonSdkUrl = python_sdk_url => ({
  type: PYTHON_SDK_URL,
  payload: python_sdk_url
});
