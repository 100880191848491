import React, { useEffect } from 'react';

import { useFormik } from 'formik';

import TextField from '../../../shared/components/TextField';
import { Form } from './style.js';
import setInputProps from '../../helpers/set-input-props';

import { complementaryFormValidation } from './validationSchema';
import { directorAssociationId, ownerAssociationId } from '../../constants';
import Paragraph from '../../../shared/components/Paragraph/Paragraph';

const ComplementaryForm = ({
  setFormData,
  hasShareHolder,
  parseControllingStake
}) => {
  const formik = useFormik({
    initialValues: {
      associationTypeId: '',
      name: '',
      surname: '',
      surname2: '',
      title: ''
    },
    validationSchema: complementaryFormValidation
  });

  useEffect(() => {
    formik.setFieldValue('hasShareHolder', hasShareHolder);
    if (hasShareHolder)
      formik.setFieldValue('associationTypeId', ownerAssociationId);
    else formik.setFieldValue('associationTypeId', '');
    // eslint-disable-next-line
  }, [hasShareHolder]);

  useEffect(() => {
    setFormData({
      isValid: formik.dirty && formik.isValid,
      values: formik.values
    });
    // eslint-disable-next-line
  }, [formik.dirty, formik.isValid, formik.values]);

  return (
    <div>
      <form action="">
        <Form>
          <h2>Propietario real</h2>
          <p>
            Necesitamos que nos proporciones los datos del Administrador o
            Gerente Único, Presidente del Consejo de Administración o alguna
            persona que tenga control sobre las decisiones más importantes de la
            empresa.
          </p>
          {!hasShareHolder ? (
            <div className="formQuestionVertical">
              <label className="questionLabel" htmlFor="president">
                <input
                  {...setInputProps(
                    formik,
                    formik.handleChange,
                    'president',
                    'associationTypeId',
                    'radio'
                  )}
                  value={directorAssociationId}
                  id="president"
                />
                Presidente del Consejo de Administración
              </label>
              <label className="questionLabel" htmlFor="administrator">
                <input
                  {...setInputProps(
                    formik,
                    formik.handleChange,
                    'administrator',
                    'associationTypeId',
                    'radio'
                  )}
                  value={directorAssociationId}
                  id="administrator"
                />
                Administrador único
              </label>
            </div>
          ) : null}
        </Form>
        <TextField
          {...setInputProps(
            formik,
            formik.handleChange,
            'Nombre(s)',
            'name',
            'text'
          )}
        />
        <TextField
          {...setInputProps(
            formik,
            formik.handleChange,
            'Apellido paterno',
            'surname',
            'text'
          )}
        />
        <TextField
          {...setInputProps(
            formik,
            formik.handleChange,
            'Apellido materno',
            'surname2',
            'text'
          )}
        />
        <TextField
          {...setInputProps(
            formik,
            formik.handleChange,
            'Cargo',
            'title',
            'text'
          )}
        />
        {hasShareHolder ? (
          <>
            <Paragraph text="¿Cuál es el porcentaje de acciones del propietario real?" />
            <TextField
              {...setInputProps(
                formik,
                formik.handleChange,
                'Porcentaje (%)',
                'percentage',
                'text'
              )}
              onChange={e => parseControllingStake(e, formik)}
              id="percentage"
            />
          </>
        ) : null}
      </form>
    </div>
  );
};

export default ComplementaryForm;
