import { customAxiosInstance } from '../../../Services/axiosConfig';
import log from 'loglevel';
import { declineCardCreationPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const declineCardCreationRequestService = async ({ cardRequestId }) => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      `${declineCardCreationPath}${cardRequestId}`
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'decline-card-creation-service'
    });
  } catch (error) {
    log.error('decline-card-creation-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
