import { SET_API_STATUS } from '../reducers/types';

import { getApiStatus } from '../Services/apiHealthStatus';

import log from 'loglevel';

export const checkAPIStatus = async dispatch => {
  try {
    const res = await getApiStatus();
    dispatch({
      type: SET_API_STATUS,
      payload: res.status
    });
  } catch (error) {
    log.error('Loglevel: ', error);
  }
};

export const closeApiStatusAlert = dispatch => {
  dispatch({
    type: SET_API_STATUS,
    payload: 'healthy'
  });
};
