import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const getColumns = ({ columns }) => {
  if (!columns) return `grid-template-columns: 2fr 2fr 8fr 3fr 3fr;`;
  if (columns === 4) return `grid-template-columns: 0fr 7fr 3fr 3fr;`;
};

const getHoverColumns = ({ columns }) => {
  if (!columns) return;
  if (columns === 4) return `grid-template-columns: 0fr 7fr 3fr 3fr;`;
  return `grid-template-columns: 1fr 1fr 5fr 3fr 3fr;`;
};

export const InvoiceItemContainer = styled.div`
  max-width: 94.8rem;
  width: 100%;
  height: 9.7rem;
  border-radius: 1.2rem;
  background-color: white;
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  padding: 0 1.2rem;
  cursor: pointer;
  ${getColumns};

  .sucessAmount {
    color: #45bd01;
  }

  @media (max-width: 768px) {
    grid-template-columns: 2fr 8fr 3fr 3fr;
    margin: 0;
    padding: 0;

    h2,
    & > span {
      font-size: 1.4rem;
      text-overflow: ellipsis;
      margin: 0;
    }
    div > span {
      font-size: 1.2rem;
      text-overflow: ellipsis;
      margin: 0;
    }

    & > div:nth-child(2) {
      display: none;
    }
  }

  &:hover {
    border-radius: 1.2rem;
    box-shadow: 0 1.1rem 2.2rem -0.8rem #e5e8ed;
    border: solid 1px ${colors.primary};
    ${getHoverColumns}
  }
`;

export const InvoiceItemDetailsContainer = styled.div`
  justify-self: self-start;
  padding: 0 1rem;
`;

export const ActionButton = styled.button`
  width: fit-content;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 100px;
  background: transparent;
  border: 1px solid #1c2633;
  font-size: 12px !important;
  text-transform: none;
  font-weight: 600;
  text-align: center;
`;
