import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { twoFactorAuthenticationSetupPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const enable2FA_service = async () => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      twoFactorAuthenticationSetupPath,
      {
        two_factor_authentication_flag: true
      }
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'enable 2FA-service'
    });
  } catch (error) {
    log.error('enable 2FA-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
