'use client';
import Header from '../components/header';
import Panel from '../components/panel';
import HSpacing from '../components/spacing/horizontal-spacing';
import Heading from '../components/heading';
import Center from '../components/center';
import Button from '../components/button';
import Bullet from '../components/bullet/bullet';
import BodyText from '../components/body-text';
import Footer from '../components/footer';
import { tpaNipVerificationPath } from '../utils/constants';

import { useSelector } from 'react-redux';

import { CenterText, BulletPoint, BulletPointInfo } from '../utils/styles';

import { service } from '../service';

import React from 'react';

function View({ history }) {
  // if (isLoading) return <WaitingRedLines />;
  const customerData = useSelector(state => state.userInfoReducer.customer);

  const buroGenerate = async () => {
    const { customer, account } = customerData;
    await service().buroGenerate({ customer, account });
    history.replace(tpaNipVerificationPath);
  };
  return (
    <div>
      <Header />
      <HSpacing times={3} />
      <Panel>
        <HSpacing times={4} />
        <Center>
          <div
            style={{
              width: '416px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <Heading>¡Información relevante para ti! 🚀</Heading>
          </div>
        </Center>
        <HSpacing times={4} />

        <Center>
          <CenterText>
            En delt.ai estamos comprometidos en garantizar que aproveches al
            máximo tu línea de crédito. Para asegurar que las condiciones de tu
            crédito sean óptimas necesitamos de tu colaboración para completar
            los siguientes pasos:
          </CenterText>
        </Center>
        <HSpacing times={2} />
        <BulletPoint>
          <Bullet size={6} />
          <BodyText style={{ fontWeight: 500 }}>
            Verifica tus datos como Representante Legal
          </BodyText>
        </BulletPoint>
        <BulletPointInfo>
          <BodyText>
            Te enviaremos un NIP a tu celular para validar tus datos personales.
          </BodyText>
        </BulletPointInfo>

        <HSpacing times={3} />
        <BulletPoint>
          <Bullet size={6} />
          <BodyText style={{ fontWeight: 500 }}>
            Re-autorización de consulta de buró de crédito
          </BodyText>
        </BulletPoint>
        <BulletPointInfo>
          <BodyText>
            Deberás ingresar el mismo NIP que enviamos a tu celular para
            autorizar la consulta de tu historial crediticio.
          </BodyText>
        </BulletPointInfo>

        <HSpacing times={3} />
        <BulletPoint>
          <Bullet size={6} />
          <BodyText style={{ fontWeight: 500 }}>
            Re-autorización de consulta del SAT
          </BodyText>
        </BulletPoint>
        <BulletPointInfo>
          <BodyText>
            Ingresar tus credenciales del SAT para consultar la información
            fiscal de la empresa.
          </BodyText>
        </BulletPointInfo>

        <HSpacing times={5} />
        <Center>
          <Button
            style={{ fontWeight: 600 }}
            width={432}
            label="Continuar"
            onClick={buroGenerate}
          />
        </Center>
      </Panel>
      <Footer />
    </div>
  );
}

export default function Page({ history }) {
  return (
    <>
      <View history={history} />
    </>
  );
}
