const unavailable = 'No disponible';

export const selectCompanyTypeId = state =>
  state.userInfoReducer.user.company_type_id;

export const selectCreditAmount = state =>
  state.companyReducer.companySummary.credit_line;

export const selectContractSignedDAte = state =>
  state?.userInfoReducer?.user?.company_information?.contract_start_date;

export const selectAccountCreationDate = state =>
  state?.userInfoReducer?.user?.account_creation_date;

export const selectAccountInfo = state => {
  const accountType =
    selectCompanyTypeId(state) === 2 ? 'Freelancer' : 'Empresa';
  const creditAmount = selectCreditAmount(state);
  const contractSigned = selectContractSignedDAte(state);
  const accountCreationDate = selectAccountCreationDate(state);

  return {
    'Account credit size': creditAmount || unavailable,
    'Account type': accountType || unavailable,
    'Contract signed': contractSigned || unavailable,
    'Account created date': accountCreationDate || unavailable
  };
};
