import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  button: {
    textAlign: 'center',
    marginTop: '2.5rem'
  },
  checkbox: {
    marginTop: '2.5rem',
    color: '#666566',
    fontWeight: '100'
  },
  dropdown: {
    marginTop: '16px' //patch in px to margin-top in px from material ui class
  },
  form: {
    width: '36rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    margin: '0 auto'
  },
  formContainer: {
    margin: '0 auto'
  },
  instructions: {
    fontSize: '1.8rem',
    color: '#989898'
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  nationality: {
    fontSize: '1.6rem'
  },
  header: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    margin: '0 auto'
  }
}));
