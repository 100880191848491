/* eslint-disable react/require-default-props */
import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { HeaderContainer } from './HeaderTable.styles';

const Header = ({ text1, text2, text3, texts, columns, className }) => (
  <HeaderContainer columns={columns} className={className}>
    {texts && texts.map(text => <span key={text}>{text}</span>)}
    {text1 && <span>{text1}</span>}
    {text2 && <span>{text2}</span>}
    {text3 && <span>{text3}</span>}
  </HeaderContainer>
);

Header.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string),
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string
};

export default memo(Header);
