// React
import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { clearTags } from '../../../micro-frontends/tags/redux/tag-action';
import { createPaymentOrder } from '../../../actionCreators/speiAction';
import { tagsSelector } from '../../../micro-frontends/tags/redux/tag-selectors';

// Shared Buttons
import Button from '../../../micro-frontends/shared/components/Button/Button';
import ProgressBar from '../../../micro-frontends/shared/components/ProgressBar';
import WaitingRedLines from '../../../components/WaitingRedLines/WaitingRedLines';

// Styles
import { ConfirmButtonContainer, SweetButton } from './TransactionForm.styles';
import { ProgressBarContainer } from './styles';

// Helpers
import { useMixPanel } from '../../../micro-frontends/shared/mixpanel';
import { service as tagService } from '../../../micro-frontends/tags/service';

const successText = 'Transferencia completada';
const confirmText = 'Confirmar y continuar';
const editTransfer = 'Editar transferencia';
const exitTransfer = 'Detener transferencia';

const ButtonContainer = ({
  beneficiary,
  transaction,
  onClose,
  showConfirmView
}) => {
  const [confirm, setConfirm] = useState(false);
  const [mainText, setMainText] = useState(confirmText);
  const [secondaryText, setSecondaryText] = useState(editTransfer);
  const [progress, setProgress] = useState(0);
  const [sendMixPanelEvent] = useMixPanel();

  const dispatch = useDispatch();
  const paymentOrder = useSelector(
    state => state.speiReducer.createPaymentOrder
  );
  const error = paymentOrder.error;
  const transactionSuccess = paymentOrder.success;
  const transactionState = paymentOrder.data;
  const transactionId = paymentOrder.res.transaction_id;
  const tagsAdded = useSelector(tagsSelector);

  const createTransaction = async () => {
    let body = {
      beneficiary_id: beneficiary.beneficiary_id,
      amount: transaction.amount,
      payment_description: transaction.concept
    };

    if (transaction.reference_number)
      body.reference_number = transaction.reference_number;

    setMainText('');
    await createPaymentOrder(dispatch, body);
  };

  const handleSaveTags = async transactionId => {
    const tags = tagsAdded;
    await tags.forEach(async tag => {
      await tagService({ dispatch }).createSpeiTag({
        ...tag,
        transactionId
      });
    });

    dispatch(clearTags());
  };

  const calculateProgress = () => {
    setTimeout(() => {
      let value = progress;
      setProgress(value + 1);
    }, 30);
  };

  const handleConfirm = value => {
    setConfirm(value);
    setProgress(0);
    if (value) setSecondaryText(exitTransfer);
    else setSecondaryText(editTransfer);
  };

  useEffect(() => {
    if (transactionState && !transactionSuccess) {
      setMainText(confirmText);
      setProgress(0);
      handleConfirm(false);
    } else setMainText(successText);
    //eslint-disable-next-line
  }, [transactionSuccess, transactionState]);

  useEffect(() => {
    if (progress < 120 && confirm) calculateProgress();
    //eslint-disable-next-line
  }, [progress, confirm]);

  useEffect(() => {
    if (progress === 120) {
      createTransaction();
      setConfirm(false);
    }
    //eslint-disable-next-line
  }, [progress]);

  useEffect(() => {
    if (transactionSuccess) {
      sendMixPanelEvent('Transfer', {
        'Transaction Amount': transaction.amount,
        'Transaction Status': 'Success'
      });

      if (transactionId) handleSaveTags(transactionId);
    }

    if (error) {
      sendMixPanelEvent('Transfer', {
        'Transaction Amount': transaction.amount,
        'Transaction Status': 'Fail'
      });
    }
    // eslint-disable-next-line
  }, [transactionSuccess, transactionId, error]);

  return (
    <ConfirmButtonContainer data-test="buttonContainer">
      {!confirm && mainText ? (
        <Button
          className="confirmButton"
          success={mainText === successText}
          variant={mainText === successText ? 'success' : ''}
          text={mainText}
          data-test="confirmButton"
          onClick={
            mainText === confirmText ? e => handleConfirm(true) : onClose
          }
        />
      ) : null}

      {confirm && progress <= 120 ? (
        <ProgressBarContainer>
          <ProgressBar
            data-test="progressBar"
            progress={progress}
            textContent="Realizando transferencia"
            className="progressBar"
          />
        </ProgressBarContainer>
      ) : null}

      {mainText && mainText !== successText ? (
        <SweetButton
          data-test="sweetButton"
          onClick={
            !confirm ? e => showConfirmView(false) : e => handleConfirm(false)
          }
        >
          {secondaryText}
        </SweetButton>
      ) : null}

      {!mainText ? <WaitingRedLines /> : null}
    </ConfirmButtonContainer>
  );
};

export default ButtonContainer;
