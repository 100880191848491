/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useCallback, useState, useEffect } from 'react';

// Auxiliar libraries
import log from 'loglevel';
import PropTypes from 'prop-types';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Modal from '../../../shared/components/Modal';
import InformationModal from '../../../shared/components/ModalInformation';
import ModalAuth from '../../components/modal-auth';

// Others
import { service } from '../../service';
import {
  isValidCardIdSelector,
  validCardIdsSelector,
  selectEmail
} from '../../redux/cards-selectors';
import { cardsLink } from '../../constants';
import { machine, states, events } from './state-machine-definition';

const BlockCardModal = ({
  blockCardModal,
  setBlockCardModal,
  cardId,
  userId
}) => {
  const [authError, setAuthError] = useState(null);
  const [state, setState] = useState(machine.value);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const validCardIds = useSelector(validCardIdsSelector);
  const isValidCardId = useSelector(isValidCardIdSelector(cardId));
  const email = useSelector(selectEmail);

  useEffect(() => {
    const resetModalState = () =>
      setState(machine.transition(state, events.RESET));

    return resetModalState;
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleModalClose = () => {
    setBlockCardModal(false);
  };

  if (!isValidCardId) {
    log.warn(
      `Invalid card id ${cardId} passed as url parameter! Valid ones: ${validCardIds.join(
        ', '
      )}`
    );
    return <Redirect to={cardsLink} />;
  }

  const handleConfirmation = () =>
    setState(machine.transition(state, events.CONFIRM));

  const handleAuth = async ({ password }) => {
    const { isSuccess, error } = await service().blockCard({
      password,
      email,
      cardId,
      userId
    });

    if (isSuccess) {
      await service({ dispatch }).getCards();
      handleModalClose();
    } else {
      setAuthError(error);
    }
  };

  const getModalBasedOnState = () => {
    switch (state) {
      case states.BLOCK_CONFIRMATION:
        return (
          <InformationModal
            title="Bloquear tarjeta"
            description="Al bloquear una tarjeta, se inhabilita su uso hasta ser desbloqueada."
            primaryButtonText="Confirmar bloqueo"
            onPrimaryButtonClick={handleConfirmation}
            secondaryButtonText="Cancelar"
            onSecondaryButtonClick={handleModalClose}
          />
        );
      case states.AUTHENTICATION:
        return (
          <ModalAuth
            handleSubmit={handleAuth}
            handleModalClose={handleModalClose}
            authError={authError}
          />
        );
      default:
    }
  };

  return (
    <Modal
      open={blockCardModal}
      onClose={handleModalClose}
      showCrossSign={false}
      disableBackDropClick={false}
      showCloseOnModal
    >
      {getModalBasedOnState()}
    </Modal>
  );
};

BlockCardModal.propTypes = {
  history: PropTypes.object
};

export default BlockCardModal;
