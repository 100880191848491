import { patterns_metadata as patterns } from '../../../catalogs/patterns';

//Yup is a JavaScript object schema validator and object parser check documentation for more details https://www.npmjs.com/package/yup
let yup = require('yup');

let fieldRequiredMessage = 'Campo requerido';

export const validationSchema = yup
  .object({
    postal_code: yup
      .string('Código postal')
      .required(fieldRequiredMessage)
      .matches(patterns.patternPostalCode, 'Ingresa un código postal válido'),
    settlement: yup.string('').required(fieldRequiredMessage),
    municipality: yup.string('').required(fieldRequiredMessage),
    state: yup.string('').required(fieldRequiredMessage),
    city: yup.string('').required(fieldRequiredMessage),
    street: yup.string('').required(fieldRequiredMessage),
    external_num: yup.string('').required(fieldRequiredMessage)
  })
  .shape();
