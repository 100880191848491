import React, {useState} from 'react';
import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

import Image_Confirm from '../../../../assets/message_sent .svg';
import { CustomModalStyles } from '../../../../components/CustomModal/customModalStyles';

import LegalTerms from '../../../register/components/LegalTerms/LegalTerms';
import Button from '../../../../micro-frontends/shared/components/Button/Button';
import { interviewScheduleCalendarPath } from '../../constants';
import { useLocation } from 'react-router-dom'

import logo from '../../../../assets/landing-page/logo.png';
import screens from '../../../../assets/landing-page/screen.png'
import bank from '../../../../assets/landing-page/bank.svg'
import wallet from '../../../../assets/landing-page/wallet.svg'
import sun from '../../../../assets/landing-page/sun-moon.svg'
import companies from '../../../../assets/landing-page/companies.png'
import combinator from '../../../../assets/landing-page/combinator.png'
import profeco from '../../../../assets/landing-page/profeco.png'

import  CustomCheckbox  from '../../../shared/components/CheckBox/Checkbox';
import  AButton  from '../../../shared/components/AButton/AButton';

import Form from 'react-bootstrap/Form';

import './styles.css';

const LegalTermsPage = ( {history} ) => {
  const classes = CustomModalStyles();
  let [checkButton, setCheckButton ]  = useState(false);
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const base64Encoding = searchParams.get('k');

  const toggleButton = () =>{
    setCheckButton(!checkButton);
  }

  const goToScheduleMeet = () =>{
    history.push(`${interviewScheduleCalendarPath}?k=${base64Encoding}`);
  }
  return (
    <>
      <div className='main-container'>
        <div>
          <img
            className='logo-image'
            src={logo}
          />
        </div>
        <div className='start-now-main-container'>
          <div className='start-now-text-container'>
            <div className='head-line-text head-line-container'> Obtén un crédito para tu negocio </div>
            <div className='section-text credit-text-section'>
                ¡Queremos crecer contigo y tu negocio! Te ofrecemos una línea de crédito revolvente con <span className='text-bold'>pagos cada 45 días , 100% en línea y sin historial crediticio.</span>
                Agenda una llamada para más información y asegúrate de incluir al representante legal de tu empresa.
            </div>
            <div className='check-box-container'>
              <CustomCheckbox
                text={
                  <span className='info-text'>
                    Acepto el <a className='info-text text-bold'
                      href="https://delt.ai/aviso-privacidad">Aviso de privacidad</a> y <a className='info-text text-bold'
                      href="https://delt.ai/terminos-condiciones">Términos y Condiciones</a>
                  </span>
                }
                onChange={toggleButton}
              />
            </div>
            <div className='a-botton-container'>
              <AButton
                disabled={!checkButton}
                onClick={goToScheduleMeet}
                text={
                  <span className='a-botton-text'>
                    Empezar ahora
                  </span>
                }
              />
            </div>
          </div>
          <img
            className='screen-image'
            src={screens}
          />
        </div>
        <div className='icons-section-container'>
          <div className='icon-section-container'>
            <img
              src={bank}
            />
            <div className='title-text'>
              Crédito revolvente y flexible <span className='gray-text'>para impulsar tu negocio</span>
            </div>
          </div>
          <div className='icon-section-container'>
            <img
              src={wallet}
            />
            <div className='title-text'>
              Tarjetas Mastercard <span className='gray-text'>con crédito disponible para tu equipo</span>
            </div>
          </div>
          <div className='icon-section-container'>
            <img
              src={sun}
            />
            <div className='title-text'>
              Transferencias SPEI® <span className='gray-text'>en segundos y sin costo</span>
            </div>
          </div>
        </div>
        <div className='container-bottom-page'>
          <div className='container-bottom-bg'></div>
          <img
            className='logo-computers'
            src={companies}
          />
          <div className='schedule-meet-container'>
            <div className='subtitle-text subtitle-container'>
              Para  continuar con tu solicitud es necesario <span className='text-bold'>agendar una llamada</span>
            </div>
            <div className='section-text schedule-section-container'>
              Queremos conocerte, por eso es muy importante que el representante legal de tu empresa se encuentre en esta reunión.
            </div>
            <AButton
              disabled={!checkButton}
              text={
                <span className='a-botton-text'>
                  Agendar llamada
                </span>
              }
              onClick={goToScheduleMeet}
            />
          </div>
        </div>
        <div className='footer-text'>
          delt.ai (Happy Days Tech S.A.P.I. de C.V.), es una empresa independiente a Inteligencia en Finanzas, S.A.P.I. de C.V., Institución de Fondos de Pago Electrónico y se limita a ofrecer el servicio de crédito, el cual no es supervisado o garantizado por ninguna autoridad financiera. Independientemente de lo anterior, delt.ai se sujeta al régimen de actividades vulnerables establecido en la Ley Federal de Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita.
        </div>
      </div>
      <div className='footer-info-container'>
        <div>
          <img
            className='logo-image-sm'
            src={logo}
          />
        </div>
        <div className='footer-info-options'>
          <div>
            <div>
              <a href="https://www.ycombinator.com/">
                <img
                  className='img-combinator'
                  src={combinator}
                />
              </a>
            </div>
            <div>
              <img
                src={profeco}
              />
            </div>
          </div>
          <div>
            <div>
              <span className='header-text'>Productos</span>
              <ul className='list-unstyled'>
                <li className='element-list'>
                  <a className='bottom-links' href="https://delt.ai/credito-empresarial">Línea de crédito revolvente</a>
                </li>
                <li className='element-list'>
                  <a className='bottom-links' href="https://delt.ai/transferencias-bancarias">Transferencias bancarias</a>
                </li>
                <li className='element-list'>
                  <a className='bottom-links' href="https://delt.ai/tarjeta-corporativa">Tarjetas corporativas</a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
            <span className='header-text'>Soporte</span>
              <ul className='list-unstyled'>
                <li className='element-list'>
                  <a className='bottom-links' href="https://delt.ai/agenda-demo">Por correo electrónico</a>
                </li>
                <li className='element-list'>
                  <a className='bottom-links' href="http://ayuda.delt.ai/es/">Centro de Ayuda</a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <span className='header-text'>Compañia</span>
              <ul className='list-unstyled'>
                <li className='element-list'>
                  <a className='bottom-links' href="https://delt.ai/blog">Blog</a>
                </li>
                <li className='element-list'>
                  <a className='bottom-links' href="https://delt.ai/sobre-nosotros">Sobre nosotros</a>
                </li>
                <li className='element-list'>
                  <a className='bottom-links' href="https://delt.ai/clientes">Nuestros clientes</a>
                </li>
                <li className='element-list'>
                  <a className='bottom-links' href="https://delt.ai/vacantes">Trabaja con nosotros</a>
                </li>
                <li className='element-list'>
                  <a className='bottom-links' href="https://delt.ai/sala-de-prensa">Sala de prensa</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalTermsPage;
