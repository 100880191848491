import React from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import {
  StyledModal,
  StyledTitle as Title,
  Subtitle,
  SubtleButton
} from './styles';
import UserTypeInfo from './UserTypeInfo';
import MobileUserTypeInfoDisplay from './MobileUserTypeInfoDisplay';
import { useSelector } from 'react-redux';
import { userSelector } from '../../redux/crud-selectors';

const Modal = ({
  buttonText,
  disabled,
  handleDelete,
  handleSubmit,
  handleDecline,
  open,
  onClose,
  title,
  type,
  submitText,
  subtitle
}) => {
  const user = useSelector(userSelector);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      showCloseOnModal={true}
      disableBackDropClick={false}
    >
      <div className="formContainer">
        {type === 'update' && user ? (
          <Title size="large" text={`${user?.name} ${user?.surname_1}`} />
        ) : (
          <Title size="large" text={title} />
        )}
        <Subtitle>{subtitle}</Subtitle>
        <div className="moblieContainer">
          <MobileUserTypeInfoDisplay />
        </div>
        <Form
          disabled={disabled}
          handleClose={onClose}
          type={type}
          handleDelete={handleDelete}
          onSubmit={handleSubmit}
          submitText={submitText}
        />
        <SubtleButton onClick={handleDecline}>{buttonText}</SubtleButton>
      </div>
      <div className="userTypeInfoContainer">
        <UserTypeInfo />
      </div>
    </StyledModal>
  );
};

Modal.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string
};

export default Modal;
