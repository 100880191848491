import styled from 'styled-components';

export const InfoBarWrapper = styled.div`
  white-space: nowrap;

  @media (min-width: 760px) {
  }
`;

export const InfoBarItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  height: 100%;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;
