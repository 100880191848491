import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormComponent from './FormComponent';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  textContainer: {
    display: 'block',
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

function Row(props) {
  const { row, handleDelete, handleSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.environment.toUpperCase()}</TableCell>
        <TableCell align="left">{row.ip_whitelist}</TableCell>
        <TableCell align="left">{row.fingerprint}</TableCell>
        <TableCell align="left" className={classes.textContainer}>
          {row.public_key}
        </TableCell>
        <TableCell align="left">
          <DeleteRoundedIcon
            fontSize="small"
            color="secondary"
            style={{
              cursor: 'pointer'
            }}
            onClick={() =>
              handleDelete({
                api_key_user_id: row.api_key_user_id,
                environment: row.environment
              })
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <FormComponent
                data={row}
                handleSubmit={handleSubmit}
                setOpen={setOpen}
                open={open}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    // maxWidth: 1080,
    margin: 'auto',
    marginBottom: '2rem'
  },
  header: {
    backgroundColor: '#f7f8fa',
    border: 'solid 1px #ebeef2',
    boxShadow: '0 11px 22px -8px #e5e8ed',
    fontFamily: 'Barlow',
    fontWeight: '600',
    fontSize: '1.2rem',
    letterSpacing: '0.075rem',
    textTransform: 'uppercase'
  },
  headerText: {
    color: '#939ca9',
    fontFamily: 'Barlow',
    fontWeight: '600',
    fontSize: '1.2rem'
  }
});

export default function ApiKeysFormComponent({
  headers,
  APIKeys,
  handleDelete,
  handleSubmit
}) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="collapsible table">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell />
            {headers?.map(header => {
              return (
                <TableCell key={header} className={classes.headerText}>
                  {header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {APIKeys.map(row => (
            <Row
              key={row.api_key_user_id}
              row={row}
              handleDelete={handleDelete}
              handleSubmit={handleSubmit}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
