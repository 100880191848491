import React from 'react';

import { Route } from 'react-router-dom';
import ScheduleInterviewPage from './pages/ScheduleInterviewPage/ScheduleInterviewPage';

import {
  interviewSchedulePath,
  interviewScheduleCalendarPath
} from './constants';
import LegalTermsPage from './pages/termsAndConditions/TermsAndConditions';

const PrequalificationTpa = () => {
  return (
    <>
      <Route
        path={interviewSchedulePath}
        component={LegalTermsPage}
        redirect="/"
      />

      <Route
        path={interviewScheduleCalendarPath}
        component={ScheduleInterviewPage}
        redirect="/"
      />
    </>
  );
};

export default PrequalificationTpa;
