/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  isSuperAdminSelector,
  savedFilterByType,
  selectAvailablePhysicalCards,
  selectAvailableVirtualCards,
  selectCards,
  selectCardsError,
  selectCardSuccess,
  userTypeSelector
} from '../../redux/cards-selectors';
import { setFilterByType } from '../../redux/cards-actions';

// Auxiliar libraries
import { debounce } from 'lodash';

// Shared Components
import Button from '../../../shared/components/Button/Button';
import Layout from '../../../shared/components/Layout/Layout';
import RemainingCards from '../../components/remaining-cards';
import WaitingRedLines from '../../../../components/WaitingRedLines/WaitingRedLines';
import HasPermissions from '../../../../role-based-access/components/HasPermissions';
import EntryEmptyState from '../../../shared/components/EntryEmptyState/EntryEmptyState';

// Card Components
import NoCardsPlaceholder from '../../components/no-cards-placeholder';
import NoResultPlaceholder from '../../components/no-result-placeholder';
import PageButtonsHeader from '../../components/page-buttons-header';
import TableHeader from '../../components/table-header';
import TableRowCard from '../../components/table-row-card';
import FilterCards from '../../components/filter-cards/FliterCards';

//Modals
import NewCardModal from '../new-card-modal';
import CardActivationModal from '../card-activation-modal';
import CardDetailsModal from '../card-details-modal';
import ReportCardLostModal from '../report-lost-modal';
import RemoveTagModal from '../remove-tag-modal';
import BlockCardModal from '../block-card-modal';
import UnBlockCardModal from '../unblock-card-modal';
import CardPinModal from '../card-pin-modal';
import ApprovalModal from '../approval-modal';

// Others
import { ADMIN_CREATE_REQUEST } from '../../constants';
import { service } from '../../service';
import { newCardLink } from '../../constants';
import useViewportWidth from '../../../../hooks/useViewportWidth';
import loading from '../../../../assets/waiting-red-lines.gif';
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';

const Component = () => {
  const [selectedCard, setSelectedCard] = useState({});
  const savedFliterType = useSelector(savedFilterByType);
  const [filterQuery, setFilterQuery] = useState('');
  const [filteredCards, setFilteredCards] = useState([]);
  const [filteredCardsByType, setFilteredCardsByType] = useState([]);
  const [newCardModal, setNewCardModal] = useState(false);
  const [cardActivationModal, setCardActivationModal] = useState(false);
  const [cardDetailsModal, setCardDetailsModal] = useState(false);
  const [blockCardModal, setBlockCardModal] = useState(false);
  const [unblockCardModal, setUnblockCardModal] = useState(false);
  const [reportCardLostModal, setReportCardLostModal] = useState(false);
  const [removeTagModal, setRemoveTagModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [createCardPinModal, setCreateCardPinModal] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [Tag, setTag] = useState({});
  const dispatch = useDispatch();
  const cards = useSelector(selectCards);
  const success = useSelector(selectCardSuccess);
  const error = useSelector(selectCardsError);
  const userType = useSelector(userTypeSelector);
  const viewportWidth = useViewportWidth();
  const virtualCreditCards = useSelector(selectAvailableVirtualCards);
  const physicalCreditCards = useSelector(selectAvailablePhysicalCards);
  const isSuperAdmin = useSelector(isSuperAdminSelector);

  const isMobile = viewportWidth < 769;
  const columns = !isMobile
    ? '1.7fr 1fr 1fr 1.3fr 0.8fr 0.8fr 1.4fr'
    : '1.5fr 1fr 1fr 1.3fr 0.8fr 0.8fr';
  const columnNames = isMobile
    ? ['Nombre', 'Disponible', 'Límite', 'Usuario', 'Número', 'Estatus']
    : [
        'Nombre',
        'Disponible',
        'Límite',
        'Usuario',
        'Número',
        'Tipo',
        'Estatus'
      ];

  const filterCardsByQuery = debounce(query => {
    if (!query) {
      setFilterQuery('');
      return;
    }

    setFilterQuery(query);
  }, 1000);

  const filterCardsByType = type => {
    if (!type) {
      return;
    }

    if (type === savedFliterType) {
      dispatch(setFilterByType(''));
      return;
    }
    dispatch(setFilterByType(type));
    let isVirtual = type === 'Virtual' ? 1 : 0;
    return setFilteredCardsByType(
      cards.filter(card => card.isVirtual === isVirtual)
    );
  };

  const requestCards = () => {
    window.Intercom('showNewMessage', 'Quiero solicitar más tarjetas');
  };

  const toggleNewCardModal = card => {
    setSelectedCard(card);
    setNewCardModal(!newCardModal);
  };

  const togglecardActivationModal = card => {
    setSelectedCard(card);
    setCardActivationModal(!cardActivationModal);
  };
  const toggleCardDetailsModal = card => {
    setSelectedCard(card);
    setCardDetailsModal(!cardDetailsModal);
  };

  const toggleBlockCardModal = card => {
    setSelectedCard(card);
    setBlockCardModal(!blockCardModal);
  };
  const toggleUnblockCardModal = card => {
    setSelectedCard(card);
    setUnblockCardModal(!unblockCardModal);
  };

  const toggleReportCardLostModal = card => {
    setSelectedCard(card);
    setReportCardLostModal(!reportCardLostModal);
  };

  const toggleApprovalModal = card => {
    setSelectedCard(card);
    setApprovalModal(!approvalModal);
  };

  const toggleCreateCardPinModal = card => {
    setSelectedCard(card);
    setCreateCardPinModal(!createCardPinModal);
  };

  const toggleRemoveTagModal = card => {
    setSelectedCard(card);
    setRemoveTagModal(!removeTagModal);
  };

  const getAvailableCards = async () => {
    await service({
      dispatch
    }).getAvailableCardsRequest();
  };

  useEffect(() => {
    service({ dispatch }).getCards();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAvailableCards();
  }, []);

  useEffect(() => {
    if (filteredCards.length === 0 && (savedFliterType || filterQuery)) {
      return setNotFound(true);
    }

    setNotFound(false);
  }, [filteredCards]);

  useEffect(() => {
    if (!savedFliterType) {
      setFilteredCardsByType([]);
      return;
    }
    const isVirtual = savedFliterType === 'Virtual' ? 1 : 0;
    const filteredCardsArr = cards.filter(card => card.isVirtual === isVirtual);

    setFilteredCardsByType(filteredCardsArr);
  }, [savedFliterType]);

  useEffect(() => {
    if (filterQuery && savedFliterType) {
      let filteredCardsArr = filteredCardsByType.filter(
        card =>
          card.creditCardName
            ?.toLowerCase()
            .includes(filterQuery.toLowerCase()) ||
          card.lastFourDigits
            ?.toLowerCase()
            .includes(filterQuery.toLowerCase()) ||
          card.ownerName?.toLowerCase().includes(filterQuery.toLowerCase())
      );
      //Sort cards so active cards show first
      let filteredCardsArrSorted = filteredCardsArr.sort(
        (x, y) => y.isActive - x.isActive
      );
      setFilteredCards(filteredCardsArrSorted);
      return;
    }

    if (filterQuery) {
      setFilteredCards(
        cards.filter(
          card =>
            card.creditCardName
              ?.toLowerCase()
              .includes(filterQuery.toLowerCase()) ||
            card.lastFourDigits
              ?.toLowerCase()
              .includes(filterQuery.toLowerCase()) ||
            card.ownerName?.toLowerCase().includes(filterQuery.toLowerCase())
        )
      );
      return;
    }

    if (savedFliterType) {
      if (filteredCardsByType.length > 0) {
        setFilteredCards(filteredCardsByType);
      }
      let isVirtual = savedFliterType === 'Virtual' ? 1 : 0;
      let filteredCardsArr = cards.filter(card => card.isVirtual === isVirtual);
      //Sort cards so active cards show first
      let filteredCardsArrSorted = filteredCardsArr.sort(
        (x, y) => y.isActive - x.isActive
      );
      setFilteredCards(filteredCardsArrSorted);
      return;
    }
    if (!filterQuery && !savedFliterType) {
      setFilteredCards(cards);
    }

    // eslint-disable-next-line
  }, [filterQuery, savedFliterType, cards]);

  if (filteredCards === null) {
    return <WaitingRedLines />;
  }

  return (
    <>
      <Layout title="Tarjetas" className="cardsTitle">
        <PageButtonsHeader>
          <RemainingCards
            physicalCards={physicalCreditCards}
            virtualCards={virtualCreditCards}
            requestCards={requestCards}
          />
        </PageButtonsHeader>
        <FilterCards
          query={filterQuery}
          searchFn={filterCardsByQuery}
          selectFilter={filterCardsByType}
          selectedOption={savedFliterType}
        />
        <TableHeader columnNames={columnNames} columns={columns} />

        {!success && !error && (
          <EntryEmptyState
            img={loading}
            imgWidth={80}
            data-test="EntryEmptyState"
            title="Sincronizando tarjetas"
            description="Tus tarjetas se están sincronizando, esto podría tomar algunos segundos."
          />
        )}
        {error && (
          <NoResultPlaceholder
            text={'Error en la plataforma, por favor inténtalo más tarde.'}
          />
        )}
        {filteredCards.length > 0 &&
          filteredCards.map((card, index) => (
            <TableRowCard
              key={`${card.userId}-${card.creditCardName}-${card.creditCardId}`}
              card={card}
              columns={columns}
              index={index}
              toggleNewCardModal={toggleNewCardModal}
              toggleCardDetailsModal={toggleCardDetailsModal}
              toggleCreateCardPinModal={toggleCreateCardPinModal}
              toggleApprovalModal={toggleApprovalModal}
              toggleBlockCardModal={toggleBlockCardModal}
              toggleUnblockCardModal={toggleUnblockCardModal}
            />
          ))}
        {notFound && (
          <NoResultPlaceholder
            text={'Ningún tarjetas coincide con'}
            searchPhrase={filterQuery}
          />
        )}
        {cards.length === 0 && success && (
          <HasPermissions
            perform="only:admin"
            accessAction={() => (
              <NoCardsPlaceholder newCardLink={newCardLink} />
            )}
            restrictedAction={() => (
              <NoCardsPlaceholder newCardLink={newCardLink} noAdmin />
            )}
          />
        )}
      </Layout>
      {newCardModal && (
        <NewCardModal
          openModal={newCardModal}
          setNewCardModal={setNewCardModal}
          setSelectedCard={setSelectedCard}
          toggleCardDetailsModal={toggleCardDetailsModal}
        />
      )}

      {cardDetailsModal && (
        <CardDetailsModal
          cardDetailsModal={cardDetailsModal}
          setCardDetailsModal={setCardDetailsModal}
          toggleReportCardLostModal={toggleReportCardLostModal}
          toggleBlockCardModal={toggleBlockCardModal}
          toggleUnblockCardModal={toggleUnblockCardModal}
          toggleRemoveTagModal={toggleRemoveTagModal}
          toggleCreateCardPinModal={toggleCreateCardPinModal}
          setTag={setTag}
          cardId={selectedCard.creditCardId}
        />
      )}
      {blockCardModal && (
        <BlockCardModal
          blockCardModal={blockCardModal}
          setBlockCardModal={setBlockCardModal}
          userId={selectedCard.userId}
          cardId={selectedCard.creditCardId}
        />
      )}
      {unblockCardModal && (
        <UnBlockCardModal
          unblockCardModal={unblockCardModal}
          setUnblockCardModal={setUnblockCardModal}
          userId={selectedCard.userId}
          cardId={selectedCard.creditCardId}
        />
      )}
      {reportCardLostModal && (
        <ReportCardLostModal
          reportCardLostModal={reportCardLostModal}
          setReportCardLostModal={setReportCardLostModal}
          toggleNewCardModal={toggleNewCardModal}
          userId={selectedCard.userId}
          cardId={selectedCard.creditCardId}
        />
      )}
      {removeTagModal && (
        <RemoveTagModal
          removeTagModal={removeTagModal}
          setRemoveTagModal={setRemoveTagModal}
          toggleNewCardModal={toggleNewCardModal}
          tag={Tag}
          cardId={selectedCard.creditCardId}
        />
      )}
      {createCardPinModal && (
        <CardPinModal
          openModal={createCardPinModal}
          setCreateCardPinModal={setCreateCardPinModal}
          cardId={selectedCard.creditCardId}
        />
      )}
      {cardActivationModal && (
        <CardActivationModal
          openModal={cardActivationModal}
          setCardActivationModal={setCardActivationModal}
          togglecardActivationModal={togglecardActivationModal}
        />
      )}
      {approvalModal && (
        <ApprovalModal
          approvalModal={approvalModal}
          setApprovalModal={setApprovalModal}
          cardRequestId={selectedCard.cardAdminRequestId}
          cardId={selectedCard.creditCardId}
          onApprove={
            isSuperAdmin &&
            ADMIN_CREATE_REQUEST === selectedCard.requestStatusDescription
              ? service().approveCardCreationRequest
              : service().approveCardChangeRequest
          }
          onDecline={
            isSuperAdmin &&
            ADMIN_CREATE_REQUEST === selectedCard.requestStatusDescription
              ? service().declineCardCreationRequest
              : service().declineCardChangeRequest
          }
          getCardRequest={
            isSuperAdmin &&
            ADMIN_CREATE_REQUEST === selectedCard.requestStatusDescription
              ? service().getCardCreationRequest
              : service().getCardChangeRequest
          }
        />
      )}
    </>
  );
};

const CardsListPage = props => (
  <ErrorBoundaryHandler component={<Component {...props} />} name="Cards" />
);

export default CardsListPage;
