import {
  PURGE,
  MANAGE_DOCUMENTS_MODAL,
  UPDATE_REJECTED_COUNT,
  UPDATE_REJECTED_DOCUMENTS,
  UPDATE_MISSING_COUNT,
  UPDATE_MISSING_DOCUMENTS,
  RESET_POSTED_DOCUMENTS,
  UPDATE_POSTED_DOCUMENTS
} from './types';

const INITIAL_STATE = {
  missingDocuments: {},
  countMissingDocs: {},
  rejectedDocuments: {},
  countRejectedDocs: {},
  modal: {
    selfie: false,
    ineFront: false,
    ineBack: false,
    open: false
  },
  postedDocuments: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_MISSING_COUNT:
      return {
        ...state,
        countMissingDocs: action.payload
      };
    case UPDATE_MISSING_DOCUMENTS:
      return {
        ...state,
        missingDocuments: action.payload
      };

    case UPDATE_REJECTED_COUNT:
      return {
        ...state,
        countRejectedDocs: action.payload
      };
    case UPDATE_REJECTED_DOCUMENTS:
      return {
        ...state,
        rejectedDocuments: action.payload
      };
    case MANAGE_DOCUMENTS_MODAL:
      return { ...state, modal: action.payload };
    case UPDATE_POSTED_DOCUMENTS:
      return { ...state, postedDocuments: state.postedDocuments + 1 };
    case RESET_POSTED_DOCUMENTS:
      return { ...state, postedDocuments: 0 };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
