import React, { useState } from 'react';

// Auxiliar libraries
import { useSelector } from 'react-redux';

// Components
import { StyledModal } from './styles';
import CardTypeInfo from '../../components/card-type-info/CardTypeInfo';
import Form from './Form';

// Others

import Title from '../../../shared/components/Title/Title';
import MobileCardTypeInfoDisplay from '../../components/card-type-info/MobileCardTypeInfoDisplay';
import RemoveTagModal from '../remove-tag-modal';

const ModalCardDetails = ({
  handleSubmit,
  handleModalClose,
  handleSaveTag,

  tags
}) => {
  const [removeTagModal, setRemoveTagModal] = useState(false);
  const [Tag, setTag] = useState({});

  const openDeleteTagModal = tag => {
    setTag({
      isNewCard: true,
      tag: tag
    });
    toggleRemoveTagModal();
  };

  const toggleRemoveTagModal = () => {
    setRemoveTagModal(!removeTagModal);
  };

  return (
    <StyledModal
      open={true}
      onClose={handleModalClose}
      showCloseOnModal={true}
      disableBackDropClick={false}
    >
      <div className="formContainer">
        <Title className="formTitle" size="large" text={'Nueva tarjeta'} />
        <div className="moblieContainer">
          <MobileCardTypeInfoDisplay />
        </div>

        <Form
          tags={tags}
          handleSaveTag={handleSaveTag}
          handleDeleteTag={openDeleteTagModal}
          handleSubmit={handleSubmit}
          handleModalClose={handleModalClose}
        />
      </div>
      <div className="cardTypeInfoContainer">
        <CardTypeInfo />
      </div>
      {removeTagModal && (
        <RemoveTagModal
          removeTagModal={removeTagModal}
          setRemoveTagModal={setRemoveTagModal}
          tag={Tag}
        />
      )}
    </StyledModal>
  );
};
export default ModalCardDetails;
