import React, { useEffect } from 'react';

// Auxiliary libraries
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Componentes
import { PrivateRoute } from '../../routing/PrivateRoute';

// Helpers
import { checkPermissions } from '../permissions/checkPermissions';
import {
  allPermissionsSelector,
  enabledApiDocsSelector,
  rulesSelector
} from '../redux/role-permissions-selectors';
import { cleanStateAction } from '../redux/role-permissions-actions';

const validateAccessRoute = (path, allPermissions, rules, apiDocsEnabled) => {
  if (!rules) return;
  let isValidRoute = false;

  switch (path) {
    case '/credit':
      isValidRoute = checkPermissions(allPermissions, rules, 'credit:view');
      break;
    case '/invoices':
      isValidRoute = checkPermissions(allPermissions, rules, 'invoice:view');
      break;
    case '/apiDocs':
      isValidRoute = checkPermissions(
        allPermissions,
        rules,
        'apiDocs:view',
        apiDocsEnabled
      );
      break;
    default:
      isValidRoute = true;
      break;
  }

  return isValidRoute;
};

const RouteValidations = ({
  userType,
  component: Component,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const allPermissions = useSelector(allPermissionsSelector);
  const rules = useSelector(rulesSelector);
  const apiDocsEnabled = useSelector(enabledApiDocsSelector);

  useEffect(() => {
    if (!Array.isArray(rules)) dispatch(cleanStateAction());
    // eslint-disable-next-line
  }, [rules]);
  return (
    <Route
      {...otherProps}
      render={() =>
        validateAccessRoute(
          otherProps.location.pathname,
          allPermissions,
          rules,
          apiDocsEnabled
        ) ? (
          <PrivateRoute
            path={otherProps.location.path}
            component={Component}
            validate={otherProps.validate}
            redirect={otherProps.redirect}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/'
            }}
          />
        )
      }
    />
  );
};

RouteValidations.propTypes = {
  userType: PropTypes.string,
  component: PropTypes.any
};

export default RouteValidations;
