import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const getAlign = ({ align }) => {
  if (align)
    return `
        text-align: ${align};
    `;
};

export const MainTitle = styled.p`
  font-family: ${fonts.barlowSemiBold};
  font-size: 1.55rem;
  letter-spacing: 0.91px;
  line-height: 1.86rem;
  color: ${colors.body};
  margin: 0.5rem;
`;

export const LabelContainer = styled.div`
  line-height: 0.5rem;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  ${getAlign}
`;
