import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const Container = styled.div``;

export const Header = styled.div`
  font-size: 16px;
  line-height: 19px;
  p {
    margin: 0;
  }
`;

export const Button = styled.div`
  ${fonts.quickSandSemiBold};
  background: ${colors.deltai};
  margin: 2.4rem 0 8rem;
  color: ${colors.white};
  width: 15.3rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
`;

export const Section = styled.div`
  .description {
    display: block;
    font-size: 15.5px;
    line-height: 22px;
    color: ${colors.carbon};
    padding-bottom: 0.8rem;
    border-bottom: 1px solid ${colors.carbon3};
    margin-bottom: 1.3rem;
  }
`;

export const Skeleton = styled.div`
  display: grid;
  grid-template-columns: 1fr 9fr;
`;

export const SidebarSkeleton = styled.div`
  width: 14.7rem;
  height: 38.8rem;
  border-radius: 10px 10px 0 0;
  border-top: 1px solid ${colors.carbon3};
  border-left: 1px solid ${colors.carbon3};
  border-right: 1px solid ${colors.carbon3};
  padding: 3.5rem 0;

  div {
    background: ${colors.subtleShade};
    border-radius: 4px;
    width: 11.2rem;
    height: 2.1rem;
    margin: 0 1.2rem 0.8rem;

    &:nth-child(9) {
      opacity: 0.6;
    }
    &:nth-child(10) {
      opacity: 0.4;
    }
    &:nth-child(11) {
      opacity: 0.2;
    }
  }

  .active {
    background: rgba(23, 144, 207, 0.1);
    margin: 0 0 0.8rem;
    border-radius: 0;
    height: 3.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.6rem 1.1rem;
    color: ${colors.carbon};
  }
`;

export const MainSkeleton = styled.div`
  margin: 1rem 0;
  padding: 0 2rem;

  .subtitle {
    margin-bottom: 1.6rem;
  }

  div {
    background: ${colors.subtleShade};
    border-radius: 4px;
    width: 27rem;
    height: 2.1rem;
    margin-bottom: 0.8rem;
    &:nth-child(4) {
      width: 38.4rem;
      height: 3.2rem;
    }
    &:nth-child(6) {
      height: 8.5rem;
      width: 38.4rem;
    }
  }

  .title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 1.6rem;
  }

  span {
    display: block;
  }
`;
