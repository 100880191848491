/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// styled
import { Container, Concept, CustomAmount } from './EntryBalance.styles';
import { EntryDetailsContainer } from '../../../activity/components/EntryComponent/EntryComponent.styles';

// Components
import Amount from '../../../shared/components/Amount/Amount';
import Date from '../../../shared/components/Date';
import Details from '../../../shared/components/Details/Details';
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';
import { splitAmount, parseAmount } from '../../../../helpers/helpers';
import LineComplements from './LineComplements';

const getAmount = amount => {
  let parsedAmount = parseAmount(amount);
  return splitAmount(parsedAmount);
};

const getType = color => {
  if (color === 'primary') return 'focused';
  return 'linear';
};

export const Component = ({
  amount,
  balanceDate,
  balanceHour,
  color,
  concept,
  day,
  description,
  month,
  className
}) => {
  const [digits] = useState(getAmount(amount));
  const [type] = useState(getType(color));

  return (
    <Container type={color} className={className}>
      {day && month ? (
        <Date day={day} month={month} />
      ) : (
        <LineComplements type={type} />
      )}
      <EntryDetailsContainer className="entryDetailsContainer">
        <Concept text={concept} color={color} />
        <Details
          mainText={balanceDate}
          secondaryText={balanceHour || description}
        />
      </EntryDetailsContainer>
      {amount && (
        <CustomAmount type={color}>
          <Concept text={digits.sign} color={color} />
          <Amount
            integer={digits.integer}
            decimals={digits.decimals}
            color={color}
          />
        </CustomAmount>
      )}
    </Container>
  );
};

Component.propTypes = {
  amount: PropTypes.string,
  balanceDate: PropTypes.string,
  balanceHour: PropTypes.string,
  color: PropTypes.string,
  concept: PropTypes.string.isRequired,
  day: PropTypes.string,
  description: PropTypes.string,
  month: PropTypes.string
};

const EntryBalance = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="EntryBalance"
    />
  );
};

export default EntryBalance;
