import styled, { css } from 'styled-components';

import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const Container = styled.span`
  display: inline-block;
  z-index: 9999;
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const HelperIcon = styled.span`
  margin-top: 2.4rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const HelperContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  padding: 2rem 2.2rem 2.1rem 1.5rem;
  margin: -4.5rem 0 0 -1.6rem;
  border-radius: 0.8rem;
  backdrop-filter: blur('0.4rem');
  box-shadow: 0 0.2rem 0.8rem -0.2rem rgba(23, 144, 207, 0.2);
  border: solid 1px rgba(23, 144, 207, 0.5);
  background-color: #fbfdff;
  z-index: 10;
`;

export const HelperText = styled.div`
  font-size: 1.4rem;
  min-width: 15rem;
  line-height: 1.29;
  letter-spacing: 0.18px;
  color: ${colors.primary};
  margin-left: 1.2rem;
  font-weight: 400;

  div {
    margin-top: 0.1rem;
  }

  a {
    color: inherit;
  }

  .tooltipLabel {
    font-weight: 600;
    font-size: 1.4rem;
    color: ${colors.carbon};
  }
`;
