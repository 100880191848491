import React, { useState } from 'react';
import { avatarColors } from '../../../../Dashboard4.0/assets/styles/styles';

import BeneficiaryEntry from './BeneficiaryEntry';

import { getInitials, formatAccountNumber } from '../../../../helpers';

const BeneficiaryEntryContainer = ({
  id,
  index,
  alias,
  fullName,
  deleteBeneficiary,
  bankingInstitution,
  accountCode,
  onClick
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleMouseOver = () => {
    setIsMouseOver(!isMouseOver);
  };

  const showDeleteOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const handleDelete = () => {
    deleteBeneficiary(id);
  };

  const validateIsPropAvailable = prop => {
    if (!prop) return 'No disponible';
    return prop;
  };

  const getAccountInformation = bankingInstitution => {
    return validateIsPropAvailable(bankingInstitution);
  };

  const getAccountCode = accountCode => {
    let formatedAccountCode = accountCode
      ? formatAccountNumber(accountCode)
      : validateIsPropAvailable(accountCode);

    return formatedAccountCode;
  };

  return (
    <>
      <BeneficiaryEntry
        alias={validateIsPropAvailable(alias)}
        initials={getInitials(validateIsPropAvailable(fullName))}
        colors={avatarColors[index % 6]}
        fullName={validateIsPropAvailable(fullName)}
        isMouseOver={isMouseOver}
        showOverlay={showOverlay}
        handleMouseOver={handleMouseOver}
        showDeleteOverlay={showDeleteOverlay}
        handleDelete={handleDelete}
        bankingDetails={getAccountInformation(bankingInstitution)}
        accountCode={getAccountCode(accountCode)}
        onClick={onClick}
      />
    </>
  );
};

export default BeneficiaryEntryContainer;
