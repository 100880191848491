import React, { Fragment, useState, useEffect, useContext } from 'react';

import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import InputCard from '../../InputCard/InputCard';
import { FormAttorneyInFact } from './PersonTypeForms/FormAttorneyInFact';
import { FormDirectorMember } from './PersonTypeForms/FormDirectorMember';
import { FormOwner } from './PersonTypeForms/FormOwner';
import OwnersList from './OwnersList';
import ShareholderToggle from './ShareholderToggle';

import { Context as formContext } from '../../../context/formContext';
import { getFileName } from '../../../helpers';
import { setUpdatedRequired } from '../../../actionCreators/onboardingStepsActions';
import {
  validateSchemaOwner,
  validateDirectorMember,
  validateAttorneyInLaw
} from './validator';

import { useStyles } from './FormStyles';

const cardStyles = makeStyles({
  documentCard: {
    width: '15rem'
  },
  card: {
    margin: '2.5rem 0'
  }
});

const valuesAttorneyInFact = {
  rfc: '',
  curp: ''
};

const valuesDirectorMember = {
  charge: ''
};

const kindPersonsState = {
  isShareholder: null,
  hasShareholders: null,
  hasDirectorMember: null
};

export const Form = props => {
  // Hooks
  const [document, setDocument] = useState();
  const [fileName] = useState('');
  const [kindPersons, setKindPersons] = useState(kindPersonsState);
  const [hasShares, setHasShares] = useState(false);
  const [initialState, setInitialState] = useState(true);

  // Redux
  const dispatch = useDispatch();

  // Context
  const {
    state: { formStateValue },
    setPersons,
    removeOwnerPerson
  } = useContext(formContext);

  const valuesOwner = {
    isMasterAccount: kindPersons.isShareholder,
    controlling_stake: '',
    owners: formStateValue.ownerPersons.owners
  };

  // Styles
  const classes = useStyles();

  const handlePersons = (value, person) => {
    setKindPersons({
      ...kindPersons,
      [person]: value
    });

    setInitialState(false);
    setUpdatedRequired(dispatch, true);

    if (value) setPersons(person);
    else setPersons(false);
  };

  const removeShareholders = (owners, isMasterAccount) => {
    let result = owners.filter(el => {
      if (isMasterAccount) return el.isMasterAccount !== true;
      return el.isMasterAccount === true;
    });

    let total =
      result.length > 0
        ? result
            .map(item => Number(item.controlling_stake))
            .reduce((prev, next) => prev + next)
        : 0;

    removeOwnerPerson({
      owners: result,
      total: total.toFixed(2)
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.handleSubmit();
  };

  const uploadDocument = async e => {
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    const fileName = getFileName(file.name, 'constancia_poderes');
    const powerOfAttorney = [
      {
        fileName: fileName,
        file: file,
        kyc: 8
      }
    ];
    setDocument(powerOfAttorney);
    props.getDocument(powerOfAttorney);
  };

  const validateHasShares = owners => {
    let hasShares = false;
    owners.forEach(el => {
      if (el.isMasterAccount) hasShares = true;
    });

    setHasShares(hasShares);
  };

  useEffect(() => {
    setPersons(false);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    validateHasShares(formStateValue.ownerPersons.owners);
    //eslint-disable-next-line
  }, [formStateValue.ownerPersons.total]);

  useEffect(() => {
    if (!hasShares)
      removeShareholders(formStateValue.ownerPersons.owners, true);
    //eslint-disable-next-line
  }, [hasShares]);

  useEffect(() => {
    if (!kindPersons.hasShareholders)
      removeShareholders(formStateValue.ownerPersons.owners);
    //eslint-disable-next-line
  }, [kindPersons.hasShareholders]);

  useEffect(() => {
    if (
      kindPersons.hasShareholders !== null &&
      !kindPersons.hasShareholders &&
      kindPersons.isShareholder !== null &&
      !kindPersons.isShareholder &&
      !initialState
    )
      setPersons('hasDirectorMember');
    //eslint-disable-next-line
  }, [kindPersons, initialState]);

  const Card = () => {
    const classes = cardStyles();
    return (
      <div className={classes.card}>
        <InputCard
          className={classes.documentCard}
          tooltip="La constancia de poderes es el instrumento público que te dieron en la notaría o un corredor público en el que fuiste nombrado apoderado o administrador único de la PYME, o te otorgaron facultades como el presidente del Consejo de Administración."
          onChange={uploadDocument}
          name="Constancia de poderes"
          cardText="Subir constancia de poderes"
          isUploaded={document}
          file={fileName}
        />
      </div>
    );
  };

  return (
    <Fragment>
      <Formik
        onSubmit={props.submit}
        className={classes.form}
        initialValues={valuesAttorneyInFact}
        validationSchema={validateAttorneyInLaw}
        isInitialValid={validateAttorneyInLaw.isValidSync(valuesAttorneyInFact)}
      >
        {props => <FormAttorneyInFact {...props} />}
      </Formik>

      <form className={classes.form} onSubmit={handleSubmit}>
        <Card />

        <ShareholderToggle
          classes={classes}
          label="¿Tengo acciones o partes sociales de la empresa?"
          activateFn={e => handlePersons(true, 'isShareholder')}
          deactivateFn={e => {
            handlePersons(false, 'isShareholder');
            setHasShares(false);
          }}
        />

        {/* Display when the master account is shareholder */}
        {kindPersons.isShareholder ? (
          <FormOwner isShareholder={kindPersons.isShareholder} />
        ) : null}

        <ShareholderToggle
          classes={classes}
          info={true}
          label="¿Existe(n) Accionista(s) o Socio(s) con más del 25% de
              participación?"
          activateFn={e => handlePersons(true, 'hasShareholders')}
          deactivateFn={e => handlePersons(false, 'hasShareholders')}
        />

        {/* Display when the master account doesn't have controlling stake */}
        {kindPersons.hasShareholders ? (
          <Formik
            onSubmit={props.submit}
            className={classes.form}
            initialValues={valuesOwner}
            validationSchema={validateSchemaOwner}
            isInitialValid={validateSchemaOwner.isValidSync(valuesOwner)}
          >
            {props => <FormOwner {...props} />}
          </Formik>
        ) : null}

        {formStateValue.ownerPersons.owners.length > 0 &&
        (kindPersons.isShareholder || kindPersons.hasShareholders) ? (
          <OwnersList />
        ) : null}
      </form>

      {/* Display when the company doesn't have owners with controlling stake >= 25 % */}
      {formStateValue.kindPersonValue === 'hasDirectorMember' ? (
        <Formik
          onSubmit={props.submit}
          className={classes.form}
          initialValues={valuesDirectorMember}
          validationSchema={validateDirectorMember}
          isInitialValid={validateDirectorMember.isValidSync(
            valuesDirectorMember
          )}
          setFieldTouched
        >
          {props => <FormDirectorMember {...props} />}
        </Formik>
      ) : null}
    </Fragment>
  );
};
