import * as yup from 'yup';

const requiredFieldMessage = 'Campo requerido';

export const validationSchema = yup.lazy(({ ownerSpendLimit }) =>
  yup.object({
    creditCardName: yup
      .string()
      .max(40, 'Máximo 40 caracteres')
      .required(requiredFieldMessage),
    spendLimit: yup
      .string()
      .typeError('Debes especificar un número')
      .test({
        name: 'validator-custom-amount',
        test: function (amount) {
          if (amount && amount.includes('.')) {
            return this.createError({
              message: 'El número no debe tener decimales'
            });
          }

          if (amount && Number(amount.replace('$', '')) === 0) {
            return this.createError({
              message: 'Por favor ingresa una cantidad mayor a 0'
            });
          }

          return true;
        }
      })
      .required(requiredFieldMessage)
  })
);
