import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const StyledInput = styled.input`
  border: none;
  caret-color: ${colors.primary};
  color: ${colors.carbon};
  font-family: ${fonts.barlowMedium};
  font-size: 1.4rem;
  font-stretch: normal;
  font-style: normal;
  height: 2.7rem;
  letter-spacing: 0.18px;
  line-height: 1.93;
  outline: none;
  padding: 0;
  width: ${({ widthFor40chars }) => (widthFor40chars ? '33rem' : '12.1rem')};
  background: none;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 2.9rem;
  padding-left: 1.6rem;

  svg {
    margin-right: 0.8rem;
  }
  svg path {
    fill: ${colors.carbon};
  }
  &:focus-within svg path {
    fill: ${colors.primary};
  }
`;
