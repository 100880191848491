import React, { memo, useState, useEffect } from 'react';

import log from 'loglevel';

import SelectFile from './SelectFile';
import FileDetails from './FileDetails';
import { ReactComponent as Arrow } from '../../../../Dashboard4.0/assets/images/arrow.svg';
import { BackButton } from '../BatchTransfer/BatchTransfer.styles';

import { downloadFile, fileFormat } from '../../../../helpers/fileManager';
import { getTemplateMultibeneficiaries } from '../../../../Services/spei';

const AddMultiBeneficiaries = ({ onClose, changePage }) => {
  const [step, setStep] = useState(1);
  const [file, setFile] = useState();
  const [isValid, setIsValid] = useState(false);
  const [template, setTemplate] = useState();
  const [done, setDone] = useState(false);

  const resetFile = () => {
    setStep(1);
    setFile(null);
  };

  const handleBack = () => {
    setStep(step > 1 ? step - 1 : 1);
  };

  const downloadTemplate = () => {
    downloadFile(
      fileFormat.csv,
      'base64',
      template,
      'plantilla_multiples_beneficiarios',
      'csv'
    );
  };

  const onChange = e => {
    if (e.target) setFile(e.target);
  };

  const getTemplate = async () => {
    const { data, error, status } = await getTemplateMultibeneficiaries();

    if (error || !data[0]?.multiple_beneficiaries_template) {
      log.debug('get-beneficiaries-template', status, data, error);
      return;
    }

    setTemplate(data[0]?.multiple_beneficiaries_template);
  };

  useEffect(() => {
    if (file && file.value) setIsValid(true);
    else setIsValid(false);
  }, [file]);

  useEffect(() => {
    getTemplate();
  }, []);

  return (
    <div data-test="MultiBeneficiaries">
      {step !== 5 && step !== 1 && !done ? (
        <BackButton onClick={() => handleBack()}>
          <Arrow /> Regresar
        </BackButton>
      ) : null}
      {step === 1 ? (
        <SelectFile
          onChange={onChange}
          template={template}
          downloadTemplate={downloadTemplate}
          submit={setStep}
          isValid={isValid}
        />
      ) : null}
      {step === 2 ? (
        <FileDetails
          file={file}
          onClose={onClose}
          resetFile={resetFile}
          handleBack={() => setStep(1)}
          done={setDone}
          changePage={changePage}
        />
      ) : null}
    </div>
  );
};

export default memo(AddMultiBeneficiaries);
