import { customAxiosInstance } from '../../../Services/axiosConfig';
import {
  CreateDepartments,
  CreateDepartment
} from '../object-creators/object-creators';
import { validateStatus } from './validateStatus';

const departmentPath = '/api/dashboard/department';

export const getDepartmentsService = async () => {
  const response = await customAxiosInstance().get(departmentPath);
  const { error, data } = validateStatus(response, 'data');
  if (error) {
    return { error };
  }
  const departments = CreateDepartments(data);
  return { departments };
};

export const createDepartmentsService = async departmentName => {
  const response = await customAxiosInstance().post(departmentPath, {
    department_name: departmentName
  });
  const { error, data } = validateStatus(response, 'data');
  if (error) {
    return { error };
  }

  const department = CreateDepartment({
    ...data[0],
    department_name: departmentName
  });
  return { department };
};
