import jwtDecode from 'jwt-decode';

import { customAxiosInstance } from './axiosConfig';
import log from 'loglevel';

const basePath = '/api/dashboard';
const login = `${basePath}/login`;
export const logOutUrl = `${basePath}/logout`;
const resetUrl = `${basePath}/reset-password`;
const refreshTokenPath = `${basePath}/refresh`;
const dashboardPath =
  process.env.REACT_APP_DASHBOARD_4 === 'true' ? '/activity' : '/transfers';

export const LogIn = doc => {
  removeAuthToken();
  return customAxiosInstance().post(login, doc);
};

export const saveAuthToken = token => {
  localStorage.setItem('authToken', token);
};

export const removeAuthToken = token => {
  localStorage.removeItem('authToken');
};

export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const saveRefreshToken = refreshToken => {
  localStorage.setItem('refreshToken', refreshToken);
};

export const removeRefreshToken = () => {
  localStorage.removeItem('refreshToken');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

export const parseToken = token => {
  return jwtDecode(token);
};

export const cleanLocalStorage = () => {
  localStorage.clear();
};

export const getUserTokenInfo = () => {
  let token = getAuthToken();
  let userInfo = {};
  if (token) {
    userInfo = parseToken(token).user_claims;
  }

  return userInfo;
};

export const isUserValidated = status => {
  return status === 'User validated' || status === 'Active';
};

export const validateUserInfo = status => {
  if (!isUserValidated(status)) {
    switch (status) {
      case 'Account not validated':
        return '/confirm-account';
      case 'Incomplete Profile':
        return '/onboarding';
      case 'Documents not uploaded':
        return 'softwall';
      case 'Documents not validated':
        return 'uploadedDocs';
      case 'Signature pending':
        return 'signature';
      case 'Pending approval':
        return '/new-user-registration';
      case 'Pending profile completion':
        return '/new-user-registration';
      case 'Pending password creation':
        return '/new-user-invitation';
      case 'Pending profile completion approval':
        return '/new-user-registration';
      default:
        return '/onboarding';
    }
  }
  return 'success';
};

export const validateUserStatus = (status, history) => {
  if (status === 'Account not validated') {
    history.push('/confirm-account');
    return;
  }

  if (isUserValidated(status)) {
    history.push(dashboardPath);
    return;
  }

  history.push('/onboarding');
};

export const logOut = async () => {
  if (getAuthToken()) {
    return customAxiosInstance().get(logOutUrl);
  }
};

export const refreshTokenService = () => {
  const refreshToken = getRefreshToken();
  saveAuthToken(refreshToken);
  return customAxiosInstance().post(refreshTokenPath);
};

export const closeSession = async () => {
  try {
    await logOut();
  } catch (error) {
    log.error('Loglevel: ', error);
  } finally {
    cleanLocalStorage();
  }
};

export const sendResetEmail = data => {
  return customAxiosInstance().post(resetUrl, data);
};
