import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Pages
import CardsListPage from './pages/cards-list-page';

// Others
import HasPermissions from '../../role-based-access/components/HasPermissions';
import { cardsLink } from './constants';
import { userTypeSelector } from './redux/cards-selectors';

const CardsModule = () => {
  const userType = useSelector(userTypeSelector);
  const redirect = () => <Redirect to="/" />;

  return (
    <HasPermissions
      role={userType}
      perform="card:view_own"
      accessAction={() => (
        <>
          <Route path={cardsLink} component={CardsListPage} />
        </>
      )}
      restrictedAction={redirect}
    />
  );
};

export default CardsModule;
