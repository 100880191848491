/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../TextField';

import { formatAmount } from '../../../../helpers';

const TextFieldAmount = ({ label, onChange, value, ...otherProps }) => {
  const handleChange = e => {
    const formattedAmount = formatAmount(e.target.value) || '';
    e.target.value = formattedAmount.split(',').join('');
    onChange(e);
  };

  return (
    <TextField
      data-test="textField"
      startAdornment={'$'}
      label={label}
      onChange={handleChange}
      value={formatAmount(value) || ''}
      isAmountTextField
      {...otherProps}
    />
  );
};

TextFieldAmount.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func
};

export default TextFieldAmount;
