const basePath = '/api/dashboard';
const speiBasePath = `${basePath}/spei`;
export const paymentOrderPath = orderId =>
  `${speiBasePath}/payment-order/${orderId}`;

export const nationalCreditPath = orderId =>
  `${basePath}/credit-transaction/${orderId}`;

export const paymentOrdersPath = batchProcessId =>
  `${basePath}/batch-payment-order/${batchProcessId}`;
