import { createMachine } from '../../helpers/finite-state-machine';

export const states = {
  AUTHENTICATION: 'AUTHENTICATION',
  PROVIDE_BASIC_INFO: 'PROVIDE_BASIC_INFO',
  VIRTUAL_CARD_SUCCESS: 'VIRTUAL_CARD_SUCCESS',
  VIRTUAL_CARD_FAILURE: 'VIRTUAL_CARD_FAILURE',
  PHYSCIAL_CARD_ADDRESS: 'PHYSCIAL_CARD_ADDRESS',
  PHYSCIAL_CARD_SUCCESS: 'PHYSCIAL_CARD_SUCCESS',
  PHYSCIAL_CARD_FAILURE: 'PHYSCIAL_CARD_FAILURE',
  PENDING_SUPER_ADMIN_APPROVAL: 'PENDING_SUPER_ADMIN_APPROVAL',
  PENDING_CARD_PIN_CONFIRMATION: 'PENDING_CARD_PIN_CONFIRMATION',
  AVAILABLE_CARD_LIMIT_REACHED: 'AVAILABLE_CARD_LIMIT_REACHED'
};

export const events = {
  AUTHENTICATED: 'AUTHENTICATED',
  SUCEED_WITH_VIRTUAL_CARD: 'SUCEED_WITH_VIRTUAL_CARD',
  FAILED_WITH_VIRTUAL_CARD: 'FAILED_WITH_VIRTUAL_CARD',
  NEEDED_ADDRESS: 'NEEDED_ADDRESS',
  SUCEED_WITH_PHYSCIAL_CARD: 'SUCEED_WITH_PHYSCIAL_CARD',
  FAILED_WITH_PHYSCIAL_CARD: 'FAILED_WITH_PHYSCIAL_CARD',
  REQUEST_PIN_FROM_CARD_OWNER: 'REQUEST_PIN_FROM_CARD_OWNER',
  REQUEST_SUPER_ADMIN_APPROVAL: 'REQUEST_SUPER_ADMIN_APPROVAL',
  COME_BACK_STEP: 'COME_BACK_STEP',
  RESET: 'RESET'
};

export const machine = createMachine({
  initialState: states.AUTHENTICATION,
  [states.AUTHENTICATION]: {
    transitions: {
      [events.AUTHENTICATED]: {
        target: states.PROVIDE_BASIC_INFO
      },
      [events.RESET]: {
        target: states.AUTHENTICATION
      }
    }
  },
  [states.PROVIDE_BASIC_INFO]: {
    transitions: {
      [events.SUCEED_WITH_VIRTUAL_CARD]: {
        target: states.VIRTUAL_CARD_SUCCESS
      },
      [events.FAILED_WITH_VIRTUAL_CARD]: {
        target: states.VIRTUAL_CARD_FAILURE
      },
      [events.FAILED_WITH_PHYSCIAL_CARD]: {
        target: states.PHYSCIAL_CARD_FAILURE
      },
      [events.NEEDED_ADDRESS]: {
        target: states.PHYSCIAL_CARD_ADDRESS
      },
      [events.REQUEST_PIN_FROM_CARD_OWNER]: {
        target: states.PENDING_CARD_PIN_CONFIRMATION
      },
      [events.REQUEST_SUPER_ADMIN_APPROVAL]: {
        target: states.PENDING_SUPER_ADMIN_APPROVAL
      },
      [events.RESET]: {
        target: states.PROVIDE_BASIC_INFO
      }
    }
  },
  [states.VIRTUAL_CARD_SUCCESS]: {
    transitions: {
      [events.CLOSE_MODAL]: {
        target: states.REDIRECT_TO_PAGE
      },
      [events.RESET]: {
        target: states.PROVIDE_BASIC_INFO
      }
    }
  },
  [states.VIRTUAL_CARD_FAILURE]: {
    transitions: {
      [events.COME_BACK_STEP]: {
        target: states.PROVIDE_BASIC_INFO
      },
      [events.RESET]: {
        target: states.PROVIDE_BASIC_INFO
      }
    }
  },
  [states.PHYSCIAL_CARD_ADDRESS]: {
    transitions: {
      [events.SUCEED_WITH_PHYSCIAL_CARD]: {
        target: states.PHYSCIAL_CARD_SUCCESS
      },
      [events.FAILED_WITH_PHYSCIAL_CARD]: {
        target: states.PHYSCIAL_CARD_FAILURE
      },
      [events.COME_BACK_STEP]: {
        target: states.PROVIDE_BASIC_INFO
      },

      [events.RESET]: {
        target: states.PROVIDE_BASIC_INFO
      }
    }
  },
  [states.PHYSCIAL_CARD_SUCCESS]: {
    transitions: {
      [events.RESET]: {
        target: states.PROVIDE_BASIC_INFO
      }
    }
  },
  [states.PHYSCIAL_CARD_FAILURE]: {
    transitions: {
      [events.RESET]: {
        target: states.PROVIDE_BASIC_INFO
      },
      [events.COME_BACK_STEP]: {
        target: states.PHYSCIAL_CARD_ADDRESS
      }
    }
  },
  [states.PENDING_CARD_PIN_CONFIRMATION]: {
    transitions: {
      [events.RESET]: {
        target: states.PROVIDE_BASIC_INFO
      }
    }
  },
  [states.PENDING_SUPER_ADMIN_APPROVAL]: {
    transitions: {
      [events.RESET]: {
        target: states.PROVIDE_BASIC_INFO
      }
    }
  },
  [states.AVAILABLE_CARD_LIMIT_REACHED]: {
    transitions: {
      [events.RESET]: {
        target: states.AVAILABLE_CARD_LIMIT_REACHED
      }
    }
  }
});
