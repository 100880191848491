import React from 'react';
import Container from '@material-ui/core/Container';
import logo from '../../../assets/logo-white.png';
import { makeStyles } from '@material-ui/core/styles';
import image from '../../../assets/onboarding.jpeg';

const useStyles = makeStyles(props => ({
  background: {
    backgroundImage:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.8) 62%)',
    height: '100%'
  },
  image: props => ({
    backgroundImage: `url(${image})`,
    height: '100%',
    backgroundSize: 'cover'
  }),
  textWhite: {
    color: '#fff'
  },
  font: {
    fontFamily: 'Barlow'
  },
  imgTitle: {
    fontWeight: 600,
    fontSize: '2.4rem',
    height: '5.8rem'
  },
  containerWidth: {
    width: '38rem',
    paddingBottom: '14rem'
  },
  imgText: {
    fontSize: '1.4rem'
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'flex-end'
  }
}));

const SideBanner = props => {
  const classes = useStyles(props);
  return (
    <div className={`${classes.image} ${classes.textWhite}`}>
      <div className={`${classes.background} ${classes.flexEnd}`}>
        <Container className={classes.containerWidth}>
          <img src={logo} alt="deltai logo" />
          {props.renderText && (
            <React.Fragment>
              <h3 className={classes.imgTitle}>
                Solicita tu tarjeta de crédito corporativa
              </h3>
              <p className={classes.imgText}>
                Lleva tu control financiero, sin que te cause dolor de cabeza.
                Revisa tus gastos y obligaciones administrativas de forma
                sencilla.
              </p>
            </React.Fragment>
          )}
        </Container>
      </div>
    </div>
  );
};

export default SideBanner;
