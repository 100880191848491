/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { InfoBarItemContainer, Tooltip } from './InfoBar.styles';

const InfoBarItem = ({
  children,
  className,
  style,
  tooltipContent,
  align,
  margin
}) => {
  const [show, setShow] = useState(false);

  return children ? (
    <InfoBarItemContainer
      data-test="infoBarItem"
      style={style}
      align={align}
      margin={margin}
      className={className}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}

      {tooltipContent && show ? <Tooltip>{tooltipContent}</Tooltip> : null}
    </InfoBarItemContainer>
  ) : null;
};

InfoBarItem.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default InfoBarItem;
