import React from 'react';
import Layout from '../../../shared/components/Layout/Layout';
import {
  Container,
  Header,
  Button,
  Section,
  Skeleton,
  SidebarSkeleton,
  MainSkeleton
} from './styles';

const RequestAPIAccess = () => {
  const openIntercom = () => {
    window.Intercom('showNewMessage', 'Me gustaría solicitar acceso al API');
  };

  return (
    <Layout title="API">
      <Container>
        <Header>
          <p>
            delt.ai te permite utilizar nuestra infraestructura para automatizar
            tus operaciones financieras.
          </p>
          <Button onClick={openIntercom}>Pedir acceso</Button>
        </Header>
        <Section>
          <span className="description">
            Cuando tengas acceso, verás la documentación aquí.
          </span>
          <Skeleton>
            <SidebarSkeleton>
              <div></div>
              <div className="active">SPEI</div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </SidebarSkeleton>
            <MainSkeleton>
              <span className="title">Create payment order</span>
              <span className="subtitle">
                This endpoint creates a single transaction
              </span>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </MainSkeleton>
          </Skeleton>
        </Section>
      </Container>
    </Layout>
  );
};

export default RequestAPIAccess;
