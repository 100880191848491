/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import {
  StyledIcon as Icon,
  DropdownContainer,
  IconContainer
} from './AccountStatementYearSelector.styles';

const SelectedOption = ({ option }) => {
  return (
    <>
      <IconContainer data-testid="selected-option">
        {option} <Icon icon="next" className={'dropDownIcon'} />
      </IconContainer>
    </>
  );
};

const AccountStatementYearSelector = ({ year, onSelect, options }) => {
  return (
    <DropdownContainer>
      <Dropdown
        label={<SelectedOption option={year} />}
        options={options || []}
        keys={{ value: 'id', label: 'name' }}
        onSelect={e => onSelect(e.value)}
      />
    </DropdownContainer>
  );
};

AccountStatementYearSelector.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.array,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default memo(AccountStatementYearSelector);
