import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../shared/components/Tooltip/Tooltip';

import {
  MultipleTransfersButtonContainer,
  MultipleTransfersText
} from './SelectBeneficiary.styles';

const MultipleTransfersButton = ({ onClick }) => {
  return (
    <MultipleTransfersButtonContainer onClick={onClick}>
      <MultipleTransfersText>Enviar multiTransfer</MultipleTransfersText>
      <Tooltip
        classContainer="tooltip"
        label="MULTITRANSFER"
        text="Aquí puedes enviar miles de transferencias desde una sola orden"
        IconColor="white"
      />
    </MultipleTransfersButtonContainer>
  );
};

MultipleTransfersButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default MultipleTransfersButton;
