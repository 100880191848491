import { createMachine } from '../../../cards/helpers/finite-state-machine';

export const states = {
  COMPANY_DATA_STEP: 'COMPANY_DATA_STEP',
  ADDRESS_STEP: 'ADDRESS_STEP',
  REAL_OWNERSHIP: 'REAL_OWNERSHIP',
  BANK_DATA_STEP: 'BANK_DATA_STEP',
  IDENTITY_STEP: 'IDENTITY_STEP',
  DOCUMENTS_STEP: 'DOCUMENTS_STEP'
};

export const events = {
  COME_BACK_STEP: 'COME_BACK_STEP',
  GO_FORWARD_STEP: 'GO_FORWARD_STEP',
  REDIRECT: 'REDIRECT'
};

export const handleMachine = REDIRECT_STEP => ({
  createMachine: createMachine({
    initialState: states.COMPANY_DATA_STEP,
    [states.COMPANY_DATA_STEP]: {
      transitions: {
        [events.GO_FORWARD_STEP]: {
          target: states.ADDRESS_STEP
        },
        [events.REDIRECT]: {
          target: states[REDIRECT_STEP]
        }
      }
    },
    [states.ADDRESS_STEP]: {
      transitions: {
        [events.GO_FORWARD_STEP]: {
          target: states.REAL_OWNERSHIP
        },
        [events.COME_BACK_STEP]: {
          target: states.COMPANY_DATA_STEP
        },
        [events.REDIRECT]: {
          target: states[REDIRECT_STEP]
        }
      }
    },
    [states.REAL_OWNERSHIP]: {
      transitions: {
        [events.GO_FORWARD_STEP]: {
          target: states.BANK_DATA_STEP
        },
        [events.COME_BACK_STEP]: {
          target: states.ADDRESS_STEP
        },
        [events.REDIRECT]: {
          target: states[REDIRECT_STEP]
        }
      }
    },
    [states.BANK_DATA_STEP]: {
      transitions: {
        [events.GO_FORWARD_STEP]: {
          target: states.IDENTITY_STEP
        },
        [events.COME_BACK_STEP]: {
          target: states.REAL_OWNERSHIP
        },
        [events.REDIRECT]: {
          target: states[REDIRECT_STEP]
        }
      }
    },
    [states.IDENTITY_STEP]: {
      transitions: {
        [events.GO_FORWARD_STEP]: {
          target: states.DOCUMENTS_STEP
        },
        [events.COME_BACK_STEP]: {
          target: states.BANK_DATA_STEP
        },
        [events.REDIRECT]: {
          target: states[REDIRECT_STEP]
        }
      }
    },
    [states.DOCUMENTS_STEP]: {
      transitions: {
        [events.COME_BACK_STEP]: {
          target: states.IDENTITY_STEP
        },
        [events.REDIRECT]: {
          target: states[REDIRECT_STEP]
        },
        [events.GO_FORWARD_STEP]: {
          target: states.DOCUMENTS_STEP
        }
      }
    }
  })
});
