import {
  freelancerAddressPath,
  freelancerBankPath,
  freelancerDocuments,
  freelancerIdentity,
  freelancerInfoPath
} from '../../constants';
import { states } from '../state-machine/state-machine-definition';

export const freelancerRoutes = {
  [freelancerInfoPath]: {
    path: freelancerInfoPath,
    state: states.PERSONAL_DATA_STEP,
    index: Object.keys(states).indexOf(states.PERSONAL_DATA_STEP),
    label: 'Datos Personales'
  },
  [freelancerAddressPath]: {
    path: freelancerAddressPath,
    state: states.ADDRESS_STEP,
    index: Object.keys(states).indexOf(states.ADDRESS_STEP),
    label: 'Domicilio Fiscal'
  },
  [freelancerBankPath]: {
    path: freelancerBankPath,
    state: states.BANK_DATA_STEP,
    index: Object.keys(states).indexOf(states.BANK_DATA_STEP),
    label: 'Cuenta Bancaria'
  },
  [freelancerIdentity]: {
    path: freelancerIdentity,
    state: states.IDENTITY_STEP,
    index: Object.keys(states).indexOf(states.IDENTITY_STEP),
    label: 'Identidad'
  },
  [freelancerDocuments]: {
    path: freelancerDocuments,
    state: states.DOCUMENTS_STEP,
    index: Object.keys(states).indexOf(states.DOCUMENTS_STEP),
    label: 'Documentos'
  }
};
