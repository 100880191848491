/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import { ButtonContainer } from './Buttons.styles';

const Button = ({ text, onClick, disabled, ...otherProps }) => {
  return (
    <ButtonContainer
      type="button"
      data-test="button"
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {text}
      {otherProps.success && (
        <Icon
          className="checkmarkIcon"
          width="1.1rem"
          height="0.9rem"
          icon="checkmark"
        />
      )}
    </ButtonContainer>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default Button;
