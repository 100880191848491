import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import {
  selectDocumentation,
  selectDocumentationError,
  selectPythonSdkUrl
} from '../redux/apidocs-selectors';
import { getDocumentation } from '../event-handlers';

import { enabledApiDocsSelector } from '../../../role-based-access/redux/role-permissions-selectors';
import HasPermissions from '../../../role-based-access/components/HasPermissions';
import { GridContainer } from '../styles';
import Layout from '../../shared/components/Layout/Layout';
import WaitingRedLines from '../../../components/WaitingRedLines/WaitingRedLines';
import ApiSections from '../components/ApiSections/ApiSections';
import RequestAPIAccess from '../components/RequestAPIAccess/RequestAPIAccess';
import Docs from '../components/Docs';
import { ReactComponent as PrintIcon } from '../../../Dashboard4.0/assets/images/printer.svg';

const ApiDocs = () => {
  const dispatch = useDispatch();
  const documentation = useSelector(selectDocumentation);
  const error = useSelector(selectDocumentationError);
  const userType = useSelector(state => state.rolePermissionsReducer.userType);
  const apiDocsEnabled = useSelector(enabledApiDocsSelector);
  const python_sdk_url = useSelector(selectPythonSdkUrl);
  const componentRef = useRef();
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
  ];

  useEffect(() => {
    const make_request = (
      process.env.REACT_APP_ENV === 'development' || !documentation
    );
    if (make_request) {
      getDocumentation(dispatch);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <HasPermissions
      role={userType}
      perform={"public_api:view_docs"}
      apiDocsEnabled={apiDocsEnabled}
      accessAction={() => (
        <Layout fullScreenMode>
          {!documentation && !error && (
            <WaitingRedLines backgroundRGBA={'rgba(255,255,255,0)'} />
          )}

          {documentation && !error && (
            <div style={{
              width: "100%",
              height: "100%"
            }}>
              <GridContainer>
                <ReactToPrint
                  trigger={() =>
                    <button style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        cursor: 'pointer',
                    }}>
                      <PrintIcon style={{
                          width: '30px',
                          height: '30px',
                      }}/>
                    </button>
                  }
                  content={() => componentRef.current}
                  documentTitle={"delt.ai API"}
                />

                <div style={{
                  position: 'absolute',
                  top: 10,
                  right: 60,
                  width: 100,
                  cursor: 'pointer',
                }}>
                  <Select
                    defaultValue={languageOptions[0]}
                    blurInputOnSelect
                    isClearable={false}
                    isSearchable={false}
                    options={languageOptions}
                    onChange={(event) => {
                      i18n.changeLanguage(event.value);
                    }}
                  />
                </div>

                <ApiSections documentation={documentation} />

                <Docs
                  ref={componentRef}
                  headers={Object.keys(documentation)}
                  documentation={documentation}
                  python_sdk_url={python_sdk_url}
                />
              </GridContainer>
            </div>
          )}
        </Layout>
      )}
      restrictedAction={() => (
        documentation && <RequestAPIAccess />
      )}
    />
  );
};

export default ApiDocs;
