import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { twoFactorAuthLoginPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const twoFactorLoginService = async ({ email, password, code }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      twoFactorAuthLoginPath,
      {
        email,
        password,
        code
      }
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: '2FA-login-service'
    });
  } catch (error) {
    log.error('2FA-login-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
