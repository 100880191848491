import {
  API_DOCUMENTATION,
  API_DOCUMENTATION_ERROR,
  HEADERS,
  PYTHON_SDK_URL,
  PURGE
} from './apidocs-types';

const initialState = {
  documentation: null,
  headers: [],
  error: null,
  python_sdk_url: null,
};

const apidocsReducer = (state = initialState, action) => {
  switch (action.type) {
    case API_DOCUMENTATION:
      return {
        ...state,
        documentation: action.payload
      };
    case API_DOCUMENTATION_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case HEADERS:
      return {
        ...state,
        headers: action.payload
      };
    case PYTHON_SDK_URL:
      return {
        ...state,
        python_sdk_url: action.payload
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default apidocsReducer;
