import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;

  .formTitle {
    font-size: 2.4rem;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }
`;

export const Paragraph = styled.p`
  ${fonts.quickSandMedium}
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2rem;
`;

export const ErrorMessage = styled.label`
  ${fonts.quickSandSemiBold};
  color: ${colors.deltai};
  font-size: 1.4rem;
  text-align: center;
  padding: 0 0 1.2rem;
`;
