import "./index.scss";
import React, { useRef, useState } from "react";

function Digits({ numberOfDigits, onFullfilled, header }) {
    const [digit, setDigit] = useState(new Array(numberOfDigits).fill(""));
    const digitBoxReference = useRef([]);

    function handleChange(value, index) {
        let newArr = [...digit];
        if (!/^(?:\d+|)$/.test(value)) {
            return;
        }
        newArr[index] = value;
        setDigit(newArr);

        if (value && index < numberOfDigits - 1) {
            digitBoxReference.current[index + 1].focus();
        }
        const code = newArr.join("");
        onFullfilled(code);
    }

    function handleBackspaceAndEnter(e, index) {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            digitBoxReference.current[index - 1].focus();
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            digitBoxReference.current[index + 1].focus();
        }
    }

    return (
        <div>
            <div className="header-container">{header}</div>

            <div className="digit-container">
                {digit.map((digit, index) => (
                    <input
                        key={index}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                        ref={(reference) =>
                            (digitBoxReference.current[index] = reference)
                        }
                        className={`digit`}
                    />
                ))}
            </div>
        </div>
    );
}

export default Digits;
