import React from 'react';

import PropTypes from 'prop-types';
import { HeaderContainer } from './styles';

const TableHeader = ({ columnNames, columns }) => {
  return (
    <HeaderContainer columns={columns}>
      {columnNames.map(name => (
        <span key={name}>{name}</span>
      ))}
    </HeaderContainer>
  );
};

TableHeader.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.string
};

export default TableHeader;
