import React, { useState, useEffect } from 'react';

// Components
import InformationModal from '../../../shared/components/ModalInformation';
import TextField from '../../../shared/components/TextField/TextField';

// Others
import { useFormik } from 'formik';
import { validationSchema } from './validation-schema';

const Form = ({ handleSubmit }) => {
  const [form, setForm] = useState({
    cardPin: '',
    confirmCardPin: ''
  });
  const formik = useFormik({
    initialValues: form,
    validationSchema: validationSchema
  });

  const handleChange = e => {
    formik.handleChange(e);
  };

  useEffect(() => {
    formik.setFieldTouched();
    // eslint-disable-next-line
  }, []);

  return (
    <InformationModal
      title="Configura el NIP de tu tarjeta"
      description="El NIP debe ser de 4 dígitos. Recuerda anotarlo en algún lado. 
      Una vez configurado, no podrás cambiarlo."
      primaryButtonText="Continuar"
      isPrimaryButtonDisabled={!formik.isValid}
      onPrimaryButtonClick={() => handleSubmit(formik.values)}
    >
      <TextField
        data-test="cardPin"
        className="formField"
        label="NIP de tarjeta"
        name="cardPin"
        type="text"
        maxLength="4"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        onFocus={() => formik.setFieldTouched('cardPin')}
        error={formik.errors.cardPin && formik.touched.cardPin}
        helperText={formik.touched.cardPin && formik.errors.cardPin}
        value={formik.values.cardPin}
      />
      <TextField
        data-test="confirmCardPin"
        className="formField"
        label="Confirmar NIP de tarjeta"
        name="confirmCardPin"
        type="text"
        maxLength="4"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        onFocus={() => formik.setFieldTouched('confirmCardPin')}
        error={formik.errors.confirmCardPin && formik.touched.confirmCardPin}
        helperText={
          formik.touched.confirmCardPin && formik.errors.confirmCardPin
        }
        value={formik.values.confirmCardPin}
      />
    </InformationModal>
  );
};

export default Form;
