const OnboardingTokenGuard = state => {
  const tokenStatus = state?.userInfoReducer?.user?.status;
  const validTokenStatues = [
    'Documents not validated',
    'Documents not uploaded',
    'Incomplete Profile'
  ];

  return validTokenStatues.includes(tokenStatus);
};

export default OnboardingTokenGuard;
