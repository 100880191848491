import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const statuses = {
  approve: 'approve',
  update: 'update',
  approval_profile_completion: 'approval_profile_completion',
  invitation_sent: 'invitation_sent',
  approval_user_creation: 'approval_user_creation',
  admin_delete_requested: 'admin_delete_requested',
  admin_update_requested: 'admin_update_requested',
  active: 'active'
};

export const dictionary = {
  'Pending profile completion approval': 'approval_profile_completion',
  'Pending new password creation': 'invitation_sent',
  'Pending profile completion': 'invitation_sent',
  'Pending approval': 'approval_user_creation',
  latest_delete_request: 'admin_delete_requested',
  latest_updated_requested: 'admin_update_requested',
  active: 'active'
};

const TO_REVIEW = 'Por aprobar';
const GENERAL_ADMIN_COPY =
  ' ha solicitado un cambio en este usuario. Para que el cambio se complete, necesitamos de tu aprobación.';

export const labels = {
  create: {
    title: 'Delega e invita a tu equipo',
    buttonText: 'Cancelar',
    submitText: 'Invitar',
    subtitle: '¿Quién más de tu equipo necesita acceso a tu cuenta?'
  },
  update: {
    title: 'Miembro',
    buttonText: 'Cerrar',
    submitText: 'Guardar cambios'
  },
  default: {
    status: 'Activo',
    title: 'Miembro',
    buttonText: 'Cancelar',
    submitText: 'Continuar',
    isCTAactive: false
  },
  approval_profile_completion: {
    status: TO_REVIEW,
    color: colors.primary,
    userName: '',
    get tooltip() {
      return `${this.userName} ha llenado su registro. Para que pueda usar la plataforma, necesitamos de tu aprobación.`;
    },
    title: 'Aprobar miembro',
    submitText: 'Aprobar',
    buttonText: 'Cancelar',
    subtitle:
      'Este usuario ha llenado su registro. Para agregarlo a tu equipo necesitamos de tu aprobación.',
    disabled: true,
    isCTAactive: true
  },
  invitation_sent: {
    status: 'Enviado',
    adminStatus: 'Reenviar invitación',
    color: colors.primary,
    userName: '',
    resendCopy:
      'no ha aceptado su invitación. Da click en "Reenviar invitación" para enviarla de nuevo.',
    get tooltip() {
      return `${this.userName} ${this.resendCopy}`;
    },
    disabled: true,
    title: 'Reenviar invitación',
    get subtitle() {
      return `Este usuario ${this.resendCopy}`;
    },
    submitText: 'Reenviar invitación',
    buttonText: 'Cerrar',
    isCTAactive: true
  },
  approval_user_creation: {
    status: TO_REVIEW,
    color: colors.primary,
    admin: '',
    get tooltip() {
      return `${this.admin} ha solicitado la creación de este usuario. Para que complete su registro en tu equipo, necesitamos de tu aprobación.`;
    },
    title: 'Confirmación de usuario',
    submitText: 'Confirmar usuario',
    buttonText: 'Cancelar',
    subtitle:
      'Un administrador solicitó crear este usuario. Para agregarlo a tu equipo necesitamos de tu aprobación.',
    disabled: true,
    isCTAactive: true
  },
  admin_delete_requested: {
    status: TO_REVIEW,
    color: colors.primary,
    admin: '',
    get tooltip() {
      return `${this.admin} ${GENERAL_ADMIN_COPY}`;
    },
    title: 'Eliminar',
    submitText: 'Eliminar',
    buttonText: 'Declinar',
    subtitle: 'Un administrador solicitó eliminar este usuario',
    disabled: true,
    isCTAactive: true
  },
  admin_update_requested: {
    status: TO_REVIEW,
    color: colors.primary,
    admin: '',
    get tooltip() {
      return `${this.admin} ${GENERAL_ADMIN_COPY}`;
    },
    title: 'Solicitud de cambio',
    submitText: 'Aprobar cambios',
    buttonText: 'Declinar',
    subtitle: 'Un administrador solicitó editar a este usuario',
    disabled: true,
    isCTAactive: true
  }
};
