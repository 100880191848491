import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Pages
import userInfo from './pages/userInfo';
import commonUserInfo from './pages/commonUserInfo';
import tpaNipVerification from './pages/tpaNipVerification';
import tpaCreditHistory from './pages/tpaCreditHistory';
import tpaAuthorization from './pages/tpaAuthorization';
import tpaSyntage from './pages/tpaSyntage';
import tpaCompliance from './pages/tpaCompliance';
import tpaSuccess from './pages/tpaSuccess';

// Others
import {
  customerInfoPath,
  commonCustomerInfoPath,
  tpaNipVerificationPath,
  tpaCompliancePath,
  tpaCreditHistoryPath,
  tpaAuthorizationPath,
  tpaSyntagePath,
  tpaSuccessPath
} from './utils/constants';

const KycModule = () => (
  <Switch>
    <Route exact path={customerInfoPath} component={userInfo} />
    <Route exact path={commonCustomerInfoPath} component={commonUserInfo} />
    <Route exact path={tpaNipVerificationPath} component={tpaNipVerification} />
    <Route exact path={tpaCreditHistoryPath} component={tpaCreditHistory} />
    <Route exact path={tpaAuthorizationPath} component={tpaAuthorization} />
    <Route exact path={tpaSyntagePath} component={tpaSyntage} />
    <Route exact path={tpaCompliancePath} component={tpaCompliance} />
    <Route exact path={tpaSuccessPath} component={tpaSuccess} />
  </Switch>
);

export default KycModule;
