/* eslint-disable react/require-default-props */
import React from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// Components
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import FilterBar from '../../../shared/components/FilterBar';

import { FilterBarContainer } from './styles';

const FilterCards = ({ searchFn, selectFilter, query, selectedOption }) => {
  const dropdown = () => {
    return (
      <Dropdown
        icon="filter"
        iconWidth={'1.6rem'}
        iconHeight={'1.6rem'}
        label="Filtrar"
        options={[
          { name: 'Virtual', id: 'Virtual' },
          { name: 'Física', id: 'Física' }
        ]}
        keys={{ value: 'id', label: 'name' }}
        onSelect={e => selectFilter(e.value)}
        selectedOption={selectedOption}
        checkbox
      />
    );
  };

  return (
    <FilterBarContainer>
      <FilterBar
        className="filterBar"
        searchIcon="search"
        searchPlaceholder="Buscar tarjetas"
        searchFn={searchFn}
        rightElement={dropdown}
        query={query}
      />
    </FilterBarContainer>
  );
};

FilterCards.propTypes = {
  searchFn: PropTypes.func,
  selectFilter: PropTypes.func,
  selectDownload: PropTypes.func,
  query: PropTypes.string
};

export default FilterCards;
