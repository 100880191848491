import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { rejectedDocuments } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const getRejectedDocumentsService = async type => {
  if (!type) return;
  try {
    const { data, status, error } = await customAxiosInstance().get(
      rejectedDocuments(type)
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      isSingleElement: true,
      moduleName: 'rejected-documents-service'
    });
  } catch (error) {
    log.error('rejected-documents-service', error);

    return { error: { message: 'Unable to send request' } };
  }
};
