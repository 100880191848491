import { customAxiosInstance, defaultTimeOut } from './axiosConfig';

// Spei base path
const speiBasePath = `/api/dashboard/spei`;

// Check delt.ai SPEI collection on Postman for details
const paymentAccountPath = `${speiBasePath}/payment-account`;
const paymentAccountCsvPath = `${paymentAccountPath}/csv`;
const paymentOrderPath = `${speiBasePath}/payment-order`;
const bankingInstPath = `${speiBasePath}/banking-institution`;
const failedBatches = `${speiBasePath}/failed-payment-order`;
const failedBatchesById = `api/dashboard/payment-order/failed`;
const multiTransfers = `${speiBasePath}/batch-process`;

export const createBeneficiaryService = async body => {
  return customAxiosInstance().post(paymentAccountPath, body);
};

export const updateBeneficiaryService = async body => {
  return customAxiosInstance().put(paymentAccountPath, body);
};

export const createPaymentOrderService = async body => {
  return customAxiosInstance().post(paymentOrderPath, body);
};

export const getBankingInstitutionsService = async () => {
  return customAxiosInstance().get(bankingInstPath);
};

export const getBeneficiariesService = async () => {
  return customAxiosInstance().get(paymentAccountPath);
};

export const getPaymentOrderService = async orderId => {
  let url = `${paymentOrderPath}/${orderId}`;
  return customAxiosInstance().get(url);
};

export const sendTransactionByEmail = async body => {
  const path = '/api/dashboard/transaction/email';
  return customAxiosInstance().post(path, body);
};

export const sendCsvFile = async (name, file, type, transferName) => {
  const formData = new FormData();
  formData.append('csv_file', file);
  formData.append('batch_process_name', transferName);

  return customAxiosInstance(defaultTimeOut, 'multipart/form-data').post(
    type ? paymentAccountCsvPath : multiTransfers,
    formData
  );
};

export const getTemplateTransfer = async () => {
  return customAxiosInstance().get(multiTransfers);
};

export const deletePaymentAccount = id => {
  return customAxiosInstance().delete(`${paymentAccountPath}/${id}`);
};

export const getFailedTransactions = () => {
  return customAxiosInstance().get(failedBatches);
};

export const getFailedBatchById = id => {
  return customAxiosInstance().get(`${failedBatchesById}/${id}?download=true`);
};

export const deleteBatchById = id => {
  return customAxiosInstance().delete(`${failedBatches}/${id}`);
};

export const getBeneficiariesCsv = () => {
  return customAxiosInstance().get(`${paymentAccountPath}/csv`);
};

export const getTemplateMultibeneficiaries = async () => {
  return customAxiosInstance().get(`${paymentAccountCsvPath}/template`);
};
