import { onboardingActionTypes } from './onboardingActionTypes';

export const setAdressAction = formData => ({
  type: onboardingActionTypes.SET_ADDRESS,
  payload: formData
});

export const setEnterpriseSteps = steps => ({
  type: onboardingActionTypes.SET_ENTERPRISE_STEPS,
  payload: steps
});

export const setFreelancerSteps = steps => ({
  type: onboardingActionTypes.SET_FREELANCER_STEPS,
  payload: steps
});

export const setFreelancerBasicInfoAction = formData => ({
  type: onboardingActionTypes.SET_FREELANCER_INFO,
  payload: formData
});

export const setBankInformation = formData => ({
  type: onboardingActionTypes.SET_BANK_INFORMATION,
  payload: formData
});

export const setEnterpriseInfoAction = formData => ({
  type: onboardingActionTypes.SET_ENTERPRISE_INFO,
  payload: formData
});

export const setOnboardingStatus = status => ({
  type: onboardingActionTypes.SET_STATUS,
  payload: status
});
