import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { captureEvent, Severity } from '@sentry/browser';
import PropTypes from 'prop-types';

import EmptyState from './EmptyState';

const OnboardingErrorBoundary = ({ component, name, props }) => {
  return (
    <ErrorBoundary
      data-test="OnboardingErrorBoundary"
      fallbackRender={({ error, resetErrorBoundary }) => {
        captureEvent({
          level: Severity.Warning,
          message: `errorBoundary: ${error.message}`,
          extra: {
            component: { name },
            errorBoundary: true,
            errorName: error.name
          }
        });
        return (
          <EmptyState
            steps={props?.steps}
            activeStep={props?.activeStep}
            onPrimaryButtonClick={resetErrorBoundary}
            secondaryButtonText={props?.goBack ? 'Regresar' : ''}
            onSecondaryButtonClick={props?.goBack}
          />
        );
      }}
    >
      {component}
    </ErrorBoundary>
  );
};

OnboardingErrorBoundary.propTypes = {
  component: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  props: PropTypes.object
};

export default OnboardingErrorBoundary;
