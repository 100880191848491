import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import { Option, Label, RadioButtonContainer } from './radio-button.styles';

// eslint-disable-next-line react/display-name
const RadioButton = ({
  label,
  name,
  disabledOA,
  tooltipOA,
  disabledOB,
  optionA,
  optionB,
  onChange,
  className,
  className2,
  className3,
  value
}) => {
  const handleChange = newValue => () =>
    onChange && onChange({ target: { value: newValue, name } });

  return (
    <RadioButtonContainer className={className}>
      <Label className={className2}>{label}</Label>
      <div className={className3}>
        <Tooltip title={disabledOA && tooltipOA ? tooltipOA : ''}>
          <Option
            type="button"
            data-testid="radioOptionA"
            checked={value === optionA.value}
            onClick={handleChange(optionA.value)}
            disabled={disabledOA}
          >
            {optionA.label}
          </Option>
        </Tooltip>
        <Option
          type="button"
          data-testid="radioOptionB"
          checked={value === optionB.value}
          onClick={handleChange(optionB.value)}
          disabled={disabledOB}
        >
          {optionB.label}
        </Option>
      </div>
    </RadioButtonContainer>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  optionA: PropTypes.string,
  optionB: PropTypes.string,
  onChange: PropTypes.func
};

export default RadioButton;
