import React from "react";

import  "./index.scss";

const Bullet = ({ size = 5, color = "#ED2246", style }) => {
    return (
        <div className="hint-icon" style={{ ...style }}>
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <circle cx={size / 2} cy={size / 2} r={size / 2} fill={color} />
            </svg>
        </div>
    );
};

export default Bullet;
