import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { RfcContext } from '../../../../context/RfcContext';
import log from 'loglevel';
import { alert } from '../../../../helpers/alertsManager';
import { errorMessages } from '../../../kyc/utils/constants';

// Shared Components
import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';
import TextField from '../../../shared/components/TextField';
import Paragraph from '../../../shared/components/Paragraph';

// Custom Components
import { StyledLink } from '../registerPage/styles';
import LegalTerms from '../../components/LegalTerms/LegalTerms';
import UserType from '../../components/UserType/UserType';

// Services
import { service } from '../../service';

// Helpers
import { confirmAccountPath } from '../../constants';
import { CompanyRegister } from '../../domain/userData';
import { validationSchema } from '../../helpers/validationSchema';

const PymeForm = () => {
  const history = useHistory();
  const { rfc, setRfc } = useContext(RfcContext);

  const initialValues = {
    companyTypeId: 1,
    name: '',
    surname1: '',
    surname2: '',
    rfc: rfc || '',
    companyCommercialName: '',
    companyLegalName: '',
    email: '',
    password: ''
  };

  const createUser = async () => {
    const { isSuccess } = await service().registerUser(
      CompanyRegister(formik.values)
    );

    if (isSuccess)
      history.push({
        pathname: confirmAccountPath,
        state: {
          email: formik.values.email
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema
  });

  const setData = e => {
    formik.handleChange(e);
    if (e.target.name === 'rfc') {
      setRfc(e.target.value.toUpperCase());
      formik.setFieldValue('rfc', e.target.value.toUpperCase());
    }
  };

  const setInputProps = (name, type) => {
    return {
      name: name,
      onChange: setData,
      id: name,
      value: formik.values[name],
      onInput: e => formik.setFieldValue(name, e.target.value.toUpperCase()),
      onBlur: async e => {
        formik.handleBlur(e); 
        if (name === 'rfc') {
          await checkRFCExists(e.target.value.toUpperCase()); 
        }
      },
      error: formik.errors[name] && formik.touched[name],
      helperText: formik.errors[name],
      type: type
    };
  };

  const checkRFCExists = async (rfc) => {
    try {
      log.info('RFC:', rfc);
      
      const response = await service().checkRFC(rfc);
      const exists_rfc = response.data && response.data.length > 0 && response.data[0].exists_rfc;
      console.log('RFC validated:', exists_rfc);
  
      if ( exists_rfc ) {
        formik.setFieldError('rfc', 'El RFC ya está registrado. Intente nuevamente.');
        alert('error', errorMessages["0001.MESSAGE-ERROR_RFC.001"] || "El RFC ya está registrado. Intente nuevamente.");
        formik.setFieldValue('rfc', '');
        setRfc('');
      } else {
        formik.setFieldError('rfc', '');
      }
    } catch (error) {
      log.error('Error al validar el RFC:', error);
      formik.setFieldError('rfc', 'Ocurrió un error durante la validación.');
    }
  };
  
  return (
    <RegistrationPageLayout
      title="Crear mi cuenta"
      onPrimaryButtonClick={createUser}
      primaryButtonText={'Empezar registro'}
      isPrimaryButtonDisabled={!formik.isValid || !formik.dirty}
      isModalVisible
      secondaryActionComponent={
        <p>
          ¿Ya tienes una cuenta con nosotros?
          <br />
          <StyledLink fontSize="1.7rem" to="/login">
            Inicia sesión
          </StyledLink>
        </p>
      }
    >
      {process.env.REACT_APP_ACTIVATE_FREELANCER_ONBOARDING === 'true' && (
        <>
          <UserType userType="pyme" />
          <Paragraph text="Para una cuenta empresarial, necesitas registrarte como Persona Moral. Recuerda tener los datos del Representante Legal a mano." />
        </>
      )}
      <TextField 
        label="Nombre(s) del representante legal" 
        {...setInputProps('name', 'text')}
       />
      <TextField 
        label="Apellido paterno del representante legal" 
        {...setInputProps('surname1', 'text')}
       />
      <TextField 
        label="Apellido materno del representante legal" 
        {...setInputProps('surname2', 'text')} 
      />
      <TextField 
        label="RFC de la empresa" 
        id="rfc" {...setInputProps('rfc', 'text')}  
      />
      <TextField 
        label="Nombre comercial de tu empresa" 
        {...setInputProps('companyCommercialName', 'text')} 
      />
      <TextField 
        label="Nombre legal de tu empresa" 
        {...setInputProps('companyLegalName', 'text')} 
      />
      <TextField 
        label="Página web o perfil de red social (opcional)"
        {...setInputProps('website', 'text')} 
      />
      <TextField 
        label="Número de teléfono (Whatsapp preferentemente)" 
        {...setInputProps('phoneNumber', 'tel')} 
      />
      <TextField 
        label="Email del representante legal" 
        {...setInputProps('email', 'email')} 
      />
      <TextField 
        label="Contraseña" 
        {...setInputProps('password', 'password')} 
      />
      <LegalTerms 
        {...setInputProps('legalTerms')} 
        onChange={e => formik.setFieldValue('legalTerms', e)} 
      />
    </RegistrationPageLayout>
  );
};

export default PymeForm;
