/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { HeaderContainer } from './Modal.styles';

const Header = ({ children, ...otherProps }) => {
  return (
    <>
      {children ? (
        <HeaderContainer data-test="headerComponent" {...otherProps}>
          {children}
        </HeaderContainer>
      ) : null}
    </>
  );
};

Header.propTypes = {
  children: PropTypes.any,
  otherProps: PropTypes.any
};

export default Header;
