import React from 'react';
import { Header, UserTypeParagraph, UserTypeTitle } from './styles';
import admin_avatar from '../../../../assets/admin_avatar.svg';
import basic_avatar from '../../../../assets/basic_avatar.svg';
import engineer_avatar from '../../../../assets/engineer_avatar.svg';
import spending_limits_icon from '../../../../assets/spending_limits_icon.png';
import UserTypeLabel from './UserTypeLabel';

const UserTypes = [
  {
    type: 'Admin',
    primaryInfo:
      'Puede visualizar toda la actividad de la cuenta pero requiere de la autorización del super admin (tú) para crear usuarios y tarjetas, y ajustar límites de gasto.',
    icon: admin_avatar
  },
  {
    type: 'Básico',
    primaryInfo:
      'Solo puede visualizar su propia actividad e información. No tiene acceso a información sobre balances de la cuenta.',
    icon: basic_avatar
  },
  {
    type: 'Ingeniero',
    primaryInfo: 'Solamente tiene acceso a la documentación API.',
    icon: engineer_avatar
  }
];

const UserTypeInfo = ({ showHeader = true }) => {
  return (
    <div>
      {showHeader && <Header>Tipos de usuario</Header>}
      <div className="avatarsContainer">
        {UserTypes.map(({ type, primaryInfo, icon }, index) => {
          return (
            <UserTypeLabel
              key={index}
              type={type}
              primaryInfo={primaryInfo}
              icon={icon}
            />
          );
        })}
      </div>
      <Header>Límites de gasto</Header>
      <div className="spendingLimitsContainer">
        <UserTypeLabel
          icon={spending_limits_icon}
          primaryInfo={
            'Con el límite de gasto, puedes controlar cuanto crédito puede disponer el usuario por mes.'
          }
          secondaryinfo={
            'Este límite lo puedes cambiar cuando quieras y se resetea el 25 de cada mes, junto con el corte del periodo de crédito.'
          }
        />
      </div>
    </div>
  );
};

export default UserTypeInfo;
