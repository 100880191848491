import { v4 as uuidv4 } from 'uuid';

export const dummyInvoices = [
  {
    invoice_type_id: 2,
    uuid: '9643ee1c-c6ce-4d48-8847-5ca3018e3195',
    amount: '1920.720000',
    currency_code: 'MXN',
    issuer_name: 'Ingenieros Fuentes-Hernández S.A.P.I. DE C.V.',
    is_issuer: false,
    receiver_rfc: 'DLT150819TU4',
    receiver_name: 'Grupo Financiero Méndez de Félix S.C.',
    payment_date: null,
    issuer_rfc: 'RHV1004094O4',
    invoice_date: '2015-09-10T08:54:13+00:00',
    invoice_status: 'VIGENTE'
  },
  {
    invoice_type_id: 1,
    uuid: '9643ee1d-8855-4b1f-85d6-58672cb644d2',
    amount: '227.240000',
    currency_code: 'MXN',
    issuer_name: 'Ingenieros Fuentes-Hernández S.A.P.I. DE C.V.',
    is_issuer: true,
    receiver_rfc: 'DLT150819TU4',
    receiver_name: 'Grupo Financiero Méndez de Félix S.C.',
    payment_date: null,
    issuer_rfc: 'RHV1004094O4',
    invoice_date: '2015-09-08T23:17:22+00:00',
    invoice_status: 'VIGENTE'
  },
  {
    invoice_type_id: 1,
    uuid: '9643ee1e-5b9e-472b-a6a4-331c10d2e085',
    amount: '321.950000',
    currency_code: 'MXN',
    issuer_name: 'Agricola Escalante de Escamilla S.A. de C.V.',
    is_issuer: false,
    receiver_rfc: 'DLT150819TU4',
    receiver_name: 'Grupo Financiero Méndez de Félix S.C.',
    payment_date: null,
    issuer_rfc: 'IIW131028GV6',
    invoice_date: '2015-09-02T05:50:33+00:00',
    invoice_status: 'VIGENTE'
  }
];
