import { UsersCreator } from '../domain/user-data';
import { setUsersAction, setUsersErrorAction } from '../redux/cards-actions';

const getUsersHandler = ({ getUsers, dispatch }) => async () => {
  const { error, data: usersData } = await getUsers();

  if (error) {
    dispatch(setUsersErrorAction({ error }));
    return;
  }
  const users = UsersCreator({ data: usersData });

  dispatch(setUsersAction({ users }));
};

export { getUsersHandler };
