import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';

// Others
import { service } from '../../service';
import {
  getUserTokenInfo,
  saveAuthToken,
  saveRefreshToken
} from '../../../../Services/authService';
import { saveUserData, setTokenOnRefresh } from '../../../../actionCreators/authAction';

// Paths
import { redirectBasedOnStatus } from '../../../../routing';

//Components
import LoginForm from './Forms/LoginForm';
import TwoFactorAuthPage from '../TwoFactorAuth/TwoFactorAuthPage';

const LoginPage = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState(null);
  const [title, setTitle] = useState('Iniciar sesión');
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [is2FA_Enabled, setIs2FA_Enabled] = useState(null);
  const [userClaims, setUserClaims] = useState(null);
  const dispatch = useDispatch();

  const handleAuth = async (email, password) => {
    const { isSuccess, error, user, accessToken, refreshToken } = await service(
      dispatch
    ).loginUser({
      email,
      password
    });

    if (!user) return;

    const { user_claims } = user;

    const two_factor_authentication_flag =
      user_claims?.two_factor_authentication_flag;

    if (isSuccess) {
      if (two_factor_authentication_flag) {
        saveAuthToken(accessToken);
        saveRefreshToken(refreshToken);
        setUserClaims(user_claims);
        setEmail(email);
        setPassword(password);
        setIs2FA_Enabled(two_factor_authentication_flag);
      } else {
        const { company_id: companyId, user_id: userId, status: userStatus, user_role_id: userRoleId } = user_claims;
        let currentStatus;
        let customerData;
        saveAuthToken(accessToken);
        if(userStatus === "User validated" || userStatus === "Active"){
          
          // Get customer information
          const { isSuccess, error, data } = await service(
            dispatch
          ).customerGet({
            companyId: `${companyId}`,
            userId: `${userId}`
          });

          if(error){
            // Simulate success response in order to avoid UI flickering
            currentStatus = "completed";
            customerData = {lifecycle: "completed"};
          }

          if(isSuccess){
            currentStatus = data.data.lifecycle;
            customerData = data.data;
          }

        }


        saveUserData({ accessToken, refreshToken, customerData }, email, dispatch);
        
        let {
          status,
          company_type_id: userType,
          user_role_id: roleId
        } = getUserTokenInfo();
        status = currentStatus ? currentStatus : status;
        redirectBasedOnStatus(status, history, userType, roleId);
      }
    } else {
      setAuthError(error);
    }
  };

  const handleTwoFactorAuthVerification = async (
    authCode,
    isInitialConfiguration
  ) => {
    if (isInitialConfiguration) {
      const { error } = await service().confirmTwoFactorDeviceConfiguration({
        code: authCode
      });

      if (error) {
        setAuthError(error);
        return;
      }
    }

    const {
      isSuccess,
      error,
      user,
      accessToken,
      refreshToken
    } = await service().twoFactorAuthLogin({ email, password, code: authCode });

    if (isSuccess) {
      let currentStatus;
        let customerData;
        saveAuthToken(accessToken);
        const { user_claims } = user;
        const { company_id: companyId, user_id: userId, status: userStatus, user_role_id: userRoleId } = user_claims;
        if(userStatus === "User validated" || userStatus === "Active"){
          
          // Get customer information
          const { isSuccess, error, data } = await service(
            dispatch
          ).customerGet({
            companyId: `${companyId}`,
            userId: `${userId}`
          });
          
          if(error){
            // Simulate success response in order to avoid UI flickering
            currentStatus = "completed";
            customerData = {lifecycle: "completed"};
          }

          if(isSuccess){
            currentStatus = data.data.lifecycle;
            customerData = data.data;
          }

        }
      saveUserData({ accessToken, refreshToken, customerData }, email, dispatch);

      let {
        status,
        company_type_id: userType,
        user_role_id: roleId
      } = getUserTokenInfo();
      status = currentStatus ? currentStatus : status;
      redirectBasedOnStatus(status, history, userType, roleId);
    } else {
      setAuthError(error);
    }
  };

  const confirmUserEmail = async token => {
    const { isSuccess } = await service().confirmEmail(token);
    if (isSuccess) {
      setTitle('Hemos confirmado tu correo electrónico');
      setEmailConfirmed(true);
    }
  };

  useEffect(() => {
    if (history.location.pathname.includes('/email-confirmation')) {
      const token = history.location.pathname.split('/')[2];
      confirmUserEmail(token);
    }
    // eslint-disable-next-line
  }, []);

  return is2FA_Enabled ? (
    <TwoFactorAuthPage
      handleAuth={handleTwoFactorAuthVerification}
      userClaims={userClaims}
      email={email}
      password={password}
      authError={authError}
    />
  ) : (
    <LoginForm handleAuth={handleAuth} title={title} authError={authError} />
  );
};

export default LoginPage;
