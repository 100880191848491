import { createMachine } from '../../helpers/finite-state-machine';

export const states = {
  LOST_CONFIRMATION: 'LOST_CONFIRMATION',
  CHOOSE_NEXT_ACTION: 'CHOOSE_NEXT_ACTION'
};

export const events = {
  CONFIRM: 'CONFIRM',
  RESET: 'RESET'
};

export const machine = createMachine({
  initialState: states.LOST_CONFIRMATION,
  [states.LOST_CONFIRMATION]: {
    transitions: {
      [events.CONFIRM]: {
        target: states.CHOOSE_NEXT_ACTION
      },
      [events.RESET]: {
        target: states.LOST_CONFIRMATION
      }
    }
  },
  [states.CHOOSE_NEXT_ACTION]: {
    transitions: {
      [events.RESET]: {
        target: states.LOST_CONFIRMATION
      }
    }
  }
});
