import { log } from 'loglevel';

const getAccountStatementHandler = ({ getAccountStatementService }) => async ({
  year,
  month
}) => {
  const { error, data: accountStatement } = await getAccountStatementService({
    year,
    month
  });

  if (error) {
    log.error(error);
    return;
  }

  return accountStatement;
};

export { getAccountStatementHandler };
