import React from 'react';
import PropTypes from 'prop-types';
import {
  CardTypeTitle,
  CardTypeParagraph,
  CardTypeLabelContainer,
  AvatarIconContainer,
  CardTypeInfoParagraph
} from './styles';

const CardTypeLabel = ({ header, primaryInfo, secondaryinfo, icon }) => {
  return (
    <>
      {header && <CardTypeTitle>{header}</CardTypeTitle>}
      <CardTypeLabelContainer>
        <AvatarIconContainer>
          {icon && (
            <img
              src={icon}
              alt="Avatar Icon"
              width={'100%'}
              style={{
                objectFit: 'contain'
              }}
            />
          )}
        </AvatarIconContainer>
        <CardTypeInfoParagraph>
          {primaryInfo && <CardTypeParagraph>{primaryInfo}</CardTypeParagraph>}
          {secondaryinfo && (
            <CardTypeParagraph>{secondaryinfo}</CardTypeParagraph>
          )}
        </CardTypeInfoParagraph>
      </CardTypeLabelContainer>
    </>
  );
};

CardTypeLabel.propTypes = {
  header: PropTypes.string,
  primaryInfo: PropTypes.string,
  secondaryInfo: PropTypes.string,
  icon: PropTypes.string
};

export default CardTypeLabel;
