import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './alertsManager.css';

import error from '../Dashboard4.0/assets/images/error.svg';
import success from '../Dashboard4.0/assets/images/success.svg';
import info from '../Dashboard4.0/assets/images/info.svg';
import warning from '../Dashboard4.0/assets/images/warning.svg';

const buildCss = type => {
  switch (type) {
    case 'success':
      return 'alertSuccess';
    case 'error':
      return 'alertError';
    case 'info':
      return 'alertInfo';
    case 'warning':
      return 'alertWarning';
    default:
      return '';
  }
};

const icon = {
  error,
  success,
  info,
  warning
};

export const alert = (type, message, time) =>
  toast(message, {
    // eslint-disable-next-line react/display-name
    icon: () => <img src={icon[type]} alt="" />,
    type,
    autoClose: time || false,
    draggable: false,
    closeButton: true,
    hideProgressBar: true,
    className: buildCss(type),
    toastId: message
  });
