import React from 'react';
import { useSelector } from 'react-redux';

import Onboarding from '../../micro-frontends/onboarding/Onboarding';
import { PrivateRoute } from '../PrivateRoute';

import OnboardingFreelancerGuard from './rules/onboarding-freelancer-guard';
import OnboardingStatusGuard from './rules/onboarding-status-guard';
import OnboardingTokenGuard from './rules/onboarding-token-guard';
import UserAuthenticatedGuard from '../guards/user-authenticated-guard';

import {
  softwallPath,
  newOnboardingPath
} from '../../micro-frontends/onboarding/constants';
import OnboardingEnterpriseGuard from './rules/onboarding-enterprise-guard';
import OnboardingSoftwallGuard from './rules/onboarding-softwall-guard';
import { companyOnboarding, freelancerOnboarding } from './constants';
import { selectOnboardingState } from '../../micro-frontends/onboarding/redux/onboardingSelectors';
import Softwall from '../../micro-frontends/onboarding/pages/softwall/Softwall';

const OnboardingRoutes = () => {
  const state = useSelector(state => state);
  const onboardingStatus = useSelector(selectOnboardingState);

  return (
    <>
      <PrivateRoute
        exact
        path={newOnboardingPath}
        component={Onboarding}
        validate={
          ![
            UserAuthenticatedGuard(state),
            OnboardingTokenGuard(state)
          ].includes(false)
        }
        redirect={'/'}
      />
      <PrivateRoute
        exact
        path={freelancerOnboarding}
        component={Onboarding}
        validate={
          ![
            UserAuthenticatedGuard(state),
            OnboardingTokenGuard(state),
            OnboardingStatusGuard(onboardingStatus),
            OnboardingFreelancerGuard(state)
          ].includes(false)
        }
        redirect={'/'}
      />
      <PrivateRoute
        exact
        path={companyOnboarding}
        component={Onboarding}
        validate={
          ![
            UserAuthenticatedGuard(state),
            OnboardingTokenGuard(state),
            OnboardingStatusGuard(onboardingStatus || null),
            OnboardingEnterpriseGuard(state)
          ].includes(false)
        }
        redirect={'/'}
      />
      <PrivateRoute
        exact
        path={softwallPath}
        component={Softwall}
        validate={
          ![
            UserAuthenticatedGuard(state),
            OnboardingTokenGuard(state),
            OnboardingStatusGuard(onboardingStatus, true),
            OnboardingSoftwallGuard(state)
          ].includes(false)
        }
        redirect={'/'}
      />
    </>
  );
};

export default OnboardingRoutes;
