/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Auxiliar libraries
import { useFormik } from 'formik';

// Components
import FormOptionsFooter from '../../components/form-options-footer';
import Toggle from '../../components/toggle';
import TextField from '../../../shared/components/TextField/TextField';
import TextFieldAmount from '../../../shared/components/TextFieldAmount/TextFieldAmount';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import TagList from '../../../tags/TagList/TagList';
import { SpanLink, ApprovalLink } from './styles';

// Actions
import { validationSchema } from './validation-schema-card-details';
import {
  isAdminSelector,
  isSuperAdminSelector
} from '../../redux/cards-selectors';
import copy from '../../../../Dashboard4.0/assets/images/copy.svg';
import Button from '../../../shared/components/Button/Button';
import { alert } from '../../../../helpers/alertsManager';
import { userIdSelector } from '../../../users-crud/redux/crud-selectors';

// Helpers
import { ACTIVE } from '../../constants';

const Form = ({
  initialValues,
  isEditingEnabled,
  tags,
  handleSaveTag,
  handleDeleteTag,
  toggleReportCardLostModal,
  toggleBlockCardModal,
  toggleUnblockCardModal,
  toggleCreateCardPinModal,
  handleSafeModalClose,
  handleModalClose,
  isEditedBefore,
  handleSubmit
}) => {
  const [card, setCard] = useState(initialValues);

  const { userId: cardUserId, isCardPinSet } = card;

  const formik = useFormik({
    initialValues,
    validationSchema
  });

  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const userId = useSelector(userIdSelector);

  const handleCopyToClipboard = value => {
    alert('success', 'El número de tarjeta fue copiado exitosamente', 5000, {
      marginLeft: '-10rem',
      width: '40rem'
    });
    navigator.clipboard.writeText(value.replace(/\s/g, ''));
  };

  const manageSubmit = () => {
    handleSubmit(card);
  };

  const isEdited = isEditedBefore || formik.dirty;
  const userSpendingLimitArticle =
    process.env.REACT_APP_USER_SPENDING_LIMITS_ARTICLE;

  const handleChange = e => {
    formik.handleChange(e);
    setCard({ ...card, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <TextField
        data-test="creditCardName"
        className="formField"
        label="Nombre de la tarjeta"
        name="creditCardName"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        onFocus={() => formik.setFieldTouched('creditCardName')}
        error={
          card.creditCardName
            ? formik.errors.creditCardName && formik.touched.creditCardName
            : formik.errors.creditCardName
        }
        helperText={
          card.creditCardName
            ? formik.touched.creditCardName && formik.errors.creditCardName
            : formik.errors.creditCardName
        }
        value={card.creditCardName}
        disabled={!isEditingEnabled || formik.initialValues.isBlocked}
      />
      <TextFieldAmount
        data-test="spendLimit"
        className="formField"
        label="Límite de gasto"
        name="spendLimit"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        onFocus={() => formik.setFieldTouched('spendLimit')}
        error={formik.errors.spendLimit && formik.touched.spendLimit}
        helperText={formik.touched.spendLimit && formik.errors.spendLimit}
        value={card.spendLimit}
        disabled={!isEditingEnabled || formik.initialValues.isBlocked}
        endAdornment={
          <Tooltip
            label="Límites de gasto"
            text={
              <div>
                Para más información, lee este artículo:{' '}
                <a
                  href={userSpendingLimitArticle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ¿Cómo funcionan?
                </a>
              </div>
            }
            className="tooltip"
          />
        }
      />
      <TextField
        data-test="ownerName"
        className="formField"
        label="Usuario de la tarjeta"
        name="ownerName"
        value={card.ownerName}
        disabled
      />
      {card.isVirtual ? (
        <>
          <TextField
            data-test="cardNumber"
            className="formField"
            label="Número"
            name="cardNumber"
            value={card.cardNumber}
            endAdornment={
              <a
                href="#"
                data-testid="clipboardComponent"
                onClick={() => handleCopyToClipboard(card.cardNumber)}
              >
                <img src={copy} alt="copiar" />
              </a>
            }
            disabled
          />
          <TextField
            data-test="cardExpiry"
            className="formField"
            label="Fecha de vencimiento"
            name="cardExpiry"
            value={card.cardExpiry}
            disabled
          />
          <TextField
            data-test="securityCode"
            className="formField"
            label="Código de seguridad"
            name="securityCode"
            value={card.securityCode}
            disabled
          />
        </>
      ) : (
        <TextField
          data-test="cardNumber"
          className="formField"
          label="Número"
          name="cardNumber"
          value={`•••• •••• •••• ${card.lastFourDigits}`}
          disabled
        />
      )}

      <TagList
        handleSaveTag={handleSaveTag}
        handleDeleteTag={handleDeleteTag}
        tags={tags}
        hasPermissions={isSuperAdmin || isAdmin}
      />
      <div
        style={{
          marginTop: '20px'
        }}
      >
        {card.requestStatusDescription === ACTIVE && (
          <FormOptionsFooter>
            {card.isInactive &&
              (!isSuperAdmin && !isCardPinSet && userId === cardUserId ? (
                <div
                  onClick={() => {
                    handleModalClose();
                    toggleCreateCardPinModal(card);
                  }}
                >
                  <ApprovalLink>Crear NIP</ApprovalLink>
                </div>
              ) : (
                <div>
                  <span>{'Por generar'}</span>
                </div>
              ))}

            {card.isRetired && (
              <div>
                <span>Desactivada</span>
              </div>
            )}

            {(card.isActive || card.isBlocked) && (
              <>
                <div>
                  {isEditingEnabled && (
                    <Toggle
                      checked={card.isActive}
                      onClick={() => {
                        card.isActive
                          ? toggleBlockCardModal(card)
                          : toggleUnblockCardModal(card);
                        handleModalClose();
                      }}
                    />
                  )}
                  <span>{card.isActive ? 'Activa' : 'Inactiva'}</span>
                </div>

                <SpanLink
                  onClick={() => {
                    handleModalClose();
                    toggleReportCardLostModal(card);
                  }}
                >
                  Reportar robo o extravío
                </SpanLink>
              </>
            )}
          </FormOptionsFooter>
        )}
      </div>
      {!isEditingEnabled || formik.initialValues.isRetired ? null : (
        <Button
          className="formButton"
          size="lg"
          text={
            formik.isValid
              ? isEdited
                ? 'Guardar cambios'
                : 'Sin cambios'
              : 'Valor inválido'
          }
          onClick={() => manageSubmit()}
          disabled={!isEdited || !formik.isValid}
        />
      )}
      <Button
        className="formButton"
        size="lg"
        text={'Cancelar'}
        onClick={() => {
          return isEdited ? handleSafeModalClose(card) : handleModalClose();
        }}
        variant="overlaySecondary"
      />
    </div>
  );
};

export default Form;
