/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { StyledItem } from './Dropdown.styles';

const Item = ({ text, value, onClick }) => {
  return (
    <StyledItem value={value} onClick={onClick}>
      {text}
    </StyledItem>
  );
};

Item.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default Item;
