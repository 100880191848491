import styled from 'styled-components';

const getColumns = ({ rightElement }) => {
  if (rightElement) {
    return `
      align-items: center;
      grid-template-columns: 73fr auto;`;
  }

  return `grid-template-columns: 100%;`;
};

export const Container = styled.div`
  height: 2.9rem;
  max-width: 94.8rem;
  width: 100%;

  display: grid;
  ${getColumns}

  justify-items: left;
  margin: 2rem 0 2rem 0;

  input {
    width: 100%;
  }
`;
