import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 8px;
  background-color: #dfe4eb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
