import React, { memo, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { ReactComponent as FeedbackIcon } from '../../../../Dashboard4.0/assets/images/feedback.svg';
import { ReactComponent as HelpCenterIcon } from '../../../../Dashboard4.0/assets/images/helpcenter.svg';

import MenuItem from './MenuItem';
import Image from '../../../../components/Image/Image';
import Logo from '../../../../Dashboard4.0/assets/images/fill-18.svg';
import {
  SideNavMenuContainer,
  LogoContainer,
  MarkerLabel,
  BurgerContainer,
  Burger,
  TopBar,
  BottomBar,
  LastAuthContainer,
  BottomContainer
} from './SideNav.styles';
import Logout from './Logout';

import HasPermissions from '../../../../role-based-access/components/HasPermissions';
import { enabledApiDocsSelector } from '../../../../role-based-access/redux/role-permissions-selectors';

import { ReactComponent as HomeIcon } from '../../../../Dashboard4.0/assets/images/home-icon.svg';
import { ReactComponent as CardIcon } from '../../../../Dashboard4.0/assets/images/card-icon.svg';
import { ReactComponent as TeamIcon } from '../../../../Dashboard4.0/assets/images/team-icon.svg';
import { ReactComponent as CreditIcon } from '../../../../Dashboard4.0/assets/images/credit-icon.svg';
import { ReactComponent as InvoiceIcon } from '../../../../Dashboard4.0/assets/images/invoice-icon.svg';
import { ReactComponent as AccountIcon } from '../../../../Dashboard4.0/assets/images/account-icon.svg';
import { ReactComponent as ApiIcon } from '../../../../Dashboard4.0/assets/images/api-icon.svg';

import { service } from '../../../cards/service';

const APPZI_TOKEN = process.env.REACT_APP_APPZI_TOKEN;
const APPZI_WIDGET_TOKEN = process.env.REACT_APP_APPZI_WIDGET_TOKEN;

const Menu = ({ isMenuOpen, toggleMenuOpen }) => {
  useEffect(() => {
    const hasScript = !!document.getElementById('appzi-script');

    if (hasScript) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://w.appzi.io/w.js?token=${APPZI_TOKEN}`;
    script.id = 'appzi-script';
    script.async = true;

    document.head.appendChild(script);
  }, []);

  const setFormatDate = date => {
    const spplittedDate = date.split(' ');
    return `${spplittedDate[0]} ${spplittedDate[1]} de ${spplittedDate[2]}`;
  };

  const userType = useSelector(state => state.rolePermissionsReducer.userType);
  const lastAuthentication = useSelector(
    state => state.userInfoReducer.user.last_authentication
  );
  const time = lastAuthentication
    ? lastAuthentication.split(',')[2].substring(0, 6)
    : 'No disponible';

  const date = lastAuthentication
    ? setFormatDate(lastAuthentication.split(',')[1])
    : '';
  const apiDocsEnabled = useSelector(enabledApiDocsSelector);

  const handleGiveFeedback = e => {
    e.preventDefault();
    if (window.appzi) {
      window.appzi.openWidget(APPZI_WIDGET_TOKEN);
    }
  };

  return (
    <SideNavMenuContainer>
      <LogoContainer>
        <Image alt="deltai" src={Logo} />
        <BurgerContainer>
          <Burger onClick={toggleMenuOpen}>
            <TopBar isMenuOpen={isMenuOpen} />
            <BottomBar isMenuOpen={isMenuOpen} />
          </Burger>
        </BurgerContainer>
      </LogoContainer>

      <HasPermissions
        role={userType}
        perform="transaction:view_own"
        accessAction={() => (
          <MenuItem to="/activity" label="Actividad" icon={<HomeIcon />} />
        )}
        restrictedAction={() => <></>}
      />

      <HasPermissions
        role={userType}
        perform="card:view_own"
        accessAction={() => {
          return <MenuItem to="/cards" label="Tarjetas" icon={<CardIcon />} />;
        }}
        restrictedAction={() => <></>}
      />

      <HasPermissions
        role={userType}
        perform="invoice:view"
        accessAction={() => {
          return process.env.REACT_APP_SHOW_INVOICES === 'true' ? (
            <MenuItem to="/invoices" label="Facturas" icon={<InvoiceIcon />} />
          ) : (
            <MenuItem
              to="/invoices"
              label={
                <>
                  Facturas <MarkerLabel>Próximamente</MarkerLabel>
                </>
              }
              icon={<InvoiceIcon />}
              onClick={e => {
                e.preventDefault();
              }}
            />
          );
        }}
        restrictedAction={() => <></>}
      />
      <HasPermissions
        role={userType}
        perform="user:view_own"
        accessAction={() => (
          <MenuItem to="/team" label="Equipo" icon={<TeamIcon />} />
        )}
        restrictedAction={() => <></>}
      />
      <HasPermissions
        role={userType}
        perform="credit:view"
        accessAction={() => (
          <MenuItem to="/credit" label="Crédito" icon={<CreditIcon />} />
        )}
        restrictedAction={() => <></>}
      />
      <HasPermissions
        role={userType}
        perform="accountStatement:view_tab"
        accessAction={() => (
          <MenuItem to="/account" label="Cuenta" icon={<AccountIcon />} />
        )}
        restrictedAction={() => <></>}
      />
      <HasPermissions
        role={userType}
        perform={'public_api:view_tab'}
        apiDocsEnabled={apiDocsEnabled}
        accessAction={() => (
          <MenuItem to="/apidocs" label={'API'} icon={<ApiIcon />} />
        )}
        restrictedAction={() => {
          return userType === 'Super Admin' ? (
            <MenuItem
              to="/apidocs"
              label={
                <>
                  API <MarkerLabel>Pedir acceso</MarkerLabel>
                </>
              }
              icon={<ApiIcon />}
            />
          ) : (
            <></>
          );
        }}
      />

      <BottomContainer className="bottomContainer">
        <a href="#" data-az-l={APPZI_WIDGET_TOKEN} onClick={handleGiveFeedback}>
          <FeedbackIcon />
          Feedback
        </a>
        <a
          href="https://ayuda.delt.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HelpCenterIcon /> Centro de Ayuda
        </a>
        <Logout />
        {lastAuthentication ? (
          <LastAuthContainer className="lastAuth">
            Último acceso a cuenta: {date} a las {time} hrs.
          </LastAuthContainer>
        ) : null}
      </BottomContainer>
    </SideNavMenuContainer>
  );
};

export default memo(Menu);
