import styled from 'styled-components';

const getBackGroundColor = ({ type }) => {
  const colorSelector = {
    add: {
      normal: 'rgba(23, 144, 207, 0.05)',
      hover: 'rgba(23, 144, 207, 0.15)'
    },
    remove: {
      normal: 'rgba(237, 34, 70, 0.05)',
      hover: 'rgba(237, 34, 70, 0.15)'
    }
  };
  return `
  background-color: ${colorSelector[type].normal};  
  &:hover {
  background-color: ${colorSelector[type].hover};
  }`;
};

export const ButtonContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 14px;
  cursor: pointer;
  ${getBackGroundColor}
`;
