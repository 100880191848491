/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, StatusLabel, Value, Message } from './style';
import spanishTranslation from '../../../../catalogs/spanish.json';

const Status = ({ status }) => (
  <StatusLabel status={status}>{spanishTranslation.status[status]}</StatusLabel>
);

const EntryLabel = ({ name, value }) => (
  <Container data-testid="EntryLabelComponent">
    {value ? <Label>{name}</Label> : null}
    {value && name && name === 'Estatus' ? (
      <>
        <Status status={value.status} />
        {value.errorMessage ? <Message>{value.errorMessage}</Message> : null}
      </>
    ) : value ? (
      <Value>{value}</Value>
    ) : null}
  </Container>
);

EntryLabel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

export default EntryLabel;
