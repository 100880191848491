import { customAxiosInstance } from '../../Services/axiosConfig';
import { validateResponse } from './validateResponse';

const getUserPermissionsPath = '/api/dashboard/permissions';

export const getUserPermissions = async () => {
  const permissions = await customAxiosInstance().get(
    `${getUserPermissionsPath}`
  );
  const validatedResponse = validateResponse(permissions);

  return validatedResponse;
};
