//Yup is a JavaScript object schema validator and object parser check documentation for more details https://www.npmjs.com/package/yup
import * as yup from 'yup';

let requiredFieldMessage = 'Campo requerido';

const validateRFC = (rfc) => {
  if (!rfc || typeof rfc !== 'string') return false;

  const _rfc_pattern_pm =
    "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

  const _rfc_pattern_pf =
    "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

  return (
    new RegExp(_rfc_pattern_pm).test(rfc.toUpperCase()) ||
    new RegExp(_rfc_pattern_pf).test(rfc.toUpperCase())
  );
};

// Esquema de validación ajustado
export const validationSchema = yup.object({
  companyTypeId: yup.number(),
  companyLegalName: yup.string().when('companyTypeId', {
    is: 1,
    then: yup
      .string()
      .min(2, 'Mínimo 2 caracteres')
      .max(60, 'Máximo 60 caracteres')
      .required(requiredFieldMessage),
    otherwise: yup.string(),
  }),
  name: yup
    .string()
    .min(2, 'Mínimo 2 caracteres')
    .max(60, 'Máximo 60 caracteres')
    .required(requiredFieldMessage),
  surname1: yup
    .string()
    .min(2, 'Mínimo 2 caracteres')
    .max(60, 'Máximo 60 caracteres')
    .required(requiredFieldMessage),
  surname2: yup
    .string()
    .min(2, 'Mínimo 2 caracteres')
    .max(60, 'Máximo 60 caracteres'),
  rfc: yup
    .string()
    .required(requiredFieldMessage)
    .test('is-valid-rfc', 'El RFC no tiene un formato válido', (value) => {
      return validateRFC(value); 
    }),
  email: yup
    .string()
    .max(60, 'Máximo 60 caracteres')
    .email('Correo electrónico inválido')
    .required(requiredFieldMessage),
  website: yup.string(),
  phoneNumber: yup
    .string()
    .length(10, 'Ingresa un número de 10 dígitos')
    .required(requiredFieldMessage),
  password: yup
    .string()
    .min(8, 'Mínimo 8 caracteres')
    .required(requiredFieldMessage),
  companyCommercialName: yup.string().when('companyTypeId', {
    is: 1,
    then: yup
      .string()
      .required(requiredFieldMessage)
      .max(60, 'Máximo 60 caracteres'),
    otherwise: yup.string(),
  }),
  legalTerms: yup
    .boolean()
    .required(requiredFieldMessage)
    .oneOf([true], requiredFieldMessage),
});
