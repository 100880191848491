/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Auxiliar libraries
import { v4 as uuidv4 } from 'uuid';

// Components
import InvoicesItem from '../InvoicesItem/InvoicesItem';
import ErrorBoundaryHandler from '../../../../ErrorBoundary';

// Services
import { getShortDate, parseAmount } from '../../../../helpers';

// Constants
import { EXPENSE, INCOME } from '../../constants';

export const Component = ({ invoices, onClick }) => {
  const getValues = (el, ix) => {
    const invoice = {
      invoice_type_id: el.invoice_type_id,
      uuid: el.uuid,
      amount: parseAmount(el.amount),
      currency_code: el.currency_code,
      issuer_name: el.issuer_name,
      issuer_rfc: el.issuer_rfc,
      receiver_name: el.receiver_name,
      receiver_rfc: el.receiver_rfc,
      is_issuer: el.is_issuer,
      is_receiver: el.is_receiver,
      payment_date: el.payment_date,
      invoice_date: el.invoice_date,
      invoice_status: el.invoice_status,
      isDateVisible: ix > 0 ? setDateVisibility(el, invoices[ix - 1]) : true,
      invoice_type: getInvoiceType(
        el.invoice_type_id,
        el.is_issuer,
        el.is_receiver
      ),
      tags: el.tags || [],
      title: getTitle(el.is_issuer, el.receiver_name, el.issuer_name),
      RFC: getRFC(el.is_issuer, el.receiver_rfc, el.issuer_rfc)
    };

    return invoice;
  };

  const getInvoiceType = (invoice_type_id, is_issuer, is_receiver) => {
    // IDS  DESCRIPTION
    // 1    INCOME
    // 2    CREDIT NOTE
    // 3    PAYROLL
    // 4    PAYMENT
    // 5    TRANSFER

    if (invoice_type_id === 3) {
      return EXPENSE;
    }
    if (invoice_type_id === 1 && is_issuer) {
      return INCOME;
    }
    if (invoice_type_id === 1 && is_receiver) {
      return EXPENSE;
    }
    if (invoice_type_id === 2 && is_issuer) {
      return EXPENSE;
    }
    if (invoice_type_id === 2 && is_receiver) {
      return INCOME;
    }
    if (invoice_type_id === 4 && is_issuer) {
      return INCOME;
    }
    if (invoice_type_id === 4 && is_receiver) {
      return EXPENSE;
    }
    if (invoice_type_id === 5 && is_issuer) {
      return EXPENSE;
    }
    if (invoice_type_id === 5 && is_receiver) {
      return INCOME;
    }
  };

  const getTitle = (is_issuer, receiver_name, issuer_name) => {
    return is_issuer ? `${receiver_name}` : `${issuer_name}`;
  };

  const getRFC = (is_issuer, receiver_rfc, issuer_rfc) => {
    return is_issuer ? `${receiver_rfc}` : `${issuer_rfc}`;
  };

  const setDateVisibility = (crnttELement, prvElement) => {
    if (!prvElement) return true;
    const date1 = getShortDate(crnttELement.invoice_date);
    const date2 = getShortDate(prvElement.invoice_date);
    if (date1 === date2) {
      return false;
    }

    return true;
  };

  return (
    <>
      {invoices.length > 0
        ? invoices.map((el, ix) => {
            const details = getValues(el, ix);
            const id = uuidv4();
            return (
              <InvoicesItem
                key={id}
                details={{
                  ...details
                }}
                onClick={onClick}
                isRejected={el.canceledAt}
              />
            );
          })
        : null}
    </>
  );
};

Component.propTypes = {
  invoices: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
};

const InvoicesTable = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="InvoicesTable"
    />
  );
};

export default memo(InvoicesTable);
