/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */ import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip/Tooltip';

import { LabelContainer, LabelText } from './Label.styles';
import Icon from '../Icon/Icon';

const Label = ({
  text,
  helperText,
  helperLabel,
  className,
  icon,
  textLabel
}) => {
  return (
    <span>
      <LabelContainer
        icon={icon}
        data-test="labelComponent"
        className={className}
      >
        {icon ? <Icon icon={icon} /> : null} {text}
        {textLabel ? <LabelText>{textLabel}</LabelText> : null}
      </LabelContainer>
      {helperText && helperLabel && (
        <Tooltip text={helperText} label={helperLabel} />
      )}
    </span>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperLabel: PropTypes.string,
  textLabel: PropTypes.string
};

export default Label;
