import styled, { css } from 'styled-components';

import { TagContainer } from '../../shared/components/Tag/Tag.styles';
import TagComponent from '../../shared/components/Tag';
import HelperTooltip from '../../shared/components/HelperTooltip';

const tagStyles = css`
  width: 10rem;
  margin-right: 1rem;
  padding: 0.4rem 0;
`;

export const Tag = styled(TagComponent)`
  ${tagStyles}
`;

export const TagWrapper = styled(TagContainer)`
  ${tagStyles}
`;

export const Container = styled.div`
  display: flex;

  .buttonsContainer {
    display: flex;
    align-items: center;
    margin-left: 1rem;

    span {
      font-size: 1.4rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  .MuiAutocomplete-root {
    height: 2rem;
  }

  .MuiFormControl-root {
    margin: 0;
    height: 2rem;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  .MuiInputBase-root {
    height: 2rem;
    padding-right: 0;
    width: 100%;
    display: flex;
    color: inherit;
    font-size: 1.4rem;
    justify-content: center;
  }

  .MuiInputBase-input {
    height: 1rem;
    padding: 0;
    min-width: 10rem;
    text-align: center;
    font-family: 'Barlow';
    font-weight: 500;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:hover {
    border-bottom: none;
  }

  input.MuiInputBase-input {
    min-width: 10rem;
    padding: 0;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
  }

  input:hover,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 100%;
  }

  .tooltipText {
    bottom: 0;
    top: 125%;
    right: 0;
    max-width: 3rem;
  }
`;

export const TagOption = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0.4rem 0rem;
  width: 8rem;
  box-sizing: border-box;
  margin-left: -0.8rem;

  span {
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    padding: 0.6rem 0;
    width: 5.5rem;
    text-align: center;
  }

  svg {
    right: 0.6rem;
  }
`;

export const AddTag = styled(TagWrapper)`
  background: none;
  color: #1c2633;
  border: 1px solid #1c2633;
`;

const isUpperCase = string => string === string.toUpperCase();

const getIsTooltipVisible = (string = '') => {
  if (isUpperCase(string)) return string.length > 8;
  return string.length > 11;
};

export const Tooltip = styled(HelperTooltip)`
  .tooltipText {
    width: 7.5rem;
    max-width: 7.5rem;
    word-wrap: break-word;
  }

  &:hover {
    .tooltipText {
      visibility: ${({ tooltipText }) =>
        (getIsTooltipVisible(tooltipText) ? 'visible' : 'hidden')};
    }
  }
`;
