import React, { memo } from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// Components
import Button from '../Button/Button';
import Image from '../../../../components/Image/Image';
import Title from '../Title';

import {
  Container,
  Description,
  EmptyContainer,
  Grid
} from './EntryEmptyState.styles';

const EntryEmptyState = ({
  img,
  svg,
  imgWidth,
  title,
  description,
  actionText,
  action,
  disableAction
}) => (
  <Container data-test="EntryEmptyState">
    {img ? (
      <Image
        data-test="image"
        src={img}
        imgWidth={imgWidth || 100}
        width={160}
      />
    ) : null}
    {svg || null}

    <EmptyContainer>
      <Title data-test="title" text={title} />
      <Grid action={action ? 'true' : null}>
        <Description data-test="description">{description}</Description>
        {action ? (
          <Button
            data-test="action"
            text={actionText}
            onClick={action}
            disabled={disableAction || false}
          />
        ) : null}
      </Grid>
    </EmptyContainer>
  </Container>
);

EntryEmptyState.propTypes = {
  img: PropTypes.string,
  imgWidth: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  action: PropTypes.func,
  disableAction: PropTypes.bool
};

EntryEmptyState.defaultProps = {
  img: null,
  imgWidth: 100,
  actionText: '',
  action: null,
  disableAction: false
};

export default memo(EntryEmptyState);
