/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {
  CustomLabel,
  CustomModal,
  CustomTitle
} from '../../../shared/components/LogoutModal/LogoutModal.styles';
import { ButtonWrapper } from './BatchTransfer.styles';
import Button from '../../../shared/components/Button';

const DeleteBatch = ({ deleteBatch, open, onClose }) => {
  return (
    <CustomModal open={open} onClose={onClose} data-test="ModalContainer">
      <CustomTitle
        size="large"
        text="¿Deseas eliminar este batch?"
        data-test="Title"
      />

      <ButtonWrapper>
        <Button
          data-test="DeleteButton"
          text="Eliminar"
          size="lg"
          onClick={() => {
            deleteBatch();
            onClose();
          }}
        />
      </ButtonWrapper>
      <div onClick={onClose}>
        <CustomLabel text="Cancelar" />
      </div>
    </CustomModal>
  );
};

DeleteBatch.propTypes = {
  deleteBatch: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default DeleteBatch;
