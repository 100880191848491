//Yup is a JavaScript object schema validator and object parser check documentation for more details https://www.npmjs.com/package/yup
import * as yup from 'yup';
import {
  ENGINEER_ROLE_ID,
} from '../../constants';
const REQUIRED = 'Campo requerido';
const PATTERN_AMOUNT = /^([\d.])((\d{0,3},?(\d{3},?)*\d{0,3}(\d{1,3})?)(.[0-9]{0,2})?)$/;

export const validationSchema = yup.object({
  name: yup.string().required(REQUIRED),
  surname1: yup.string().required(REQUIRED),
  surname2: yup.string(),
  department: yup.string().required(REQUIRED),
  position: yup.string().required(REQUIRED),
  spendingLimit: yup
    .string()
    .when('roleId', {
      is: (value) => value === ENGINEER_ROLE_ID,
      // engineer user will not be able to make transactions
      // so the spending limit value is not required
      then: yup.string(),
      // spending limit is required for all other users
      otherwise: yup
        .string()
        .min(1, '')
        .max(18, 'Monto máximo excedido')
        .matches(new RegExp(PATTERN_AMOUNT), {
          message: 'Ingresa un monto válido',
          excludeEmptyString: true
        })
        .required(REQUIRED)
        .test({
          name: 'validator-custom-amount',
          // eslint-disable-next-line object-shorthand
          test: function (amount) {
            if (Number(amount) < 0.01) {
              return this.createError({
                message: 'Ingresa un monto positivo'
              });
            }
            return true;
          }
        })
    }),
  email: yup
    .string()
    .max(60, 'Máximo 60 caracteres')
    .email('Correo electrónico inválido')
    .required(REQUIRED)
});
