// React
import React, { useState, useRef, useEffect } from 'react';

// Components
import Control from './control';
import NoOptionsMessage from './no-options-message';
import Option from './option';
import OptionNotFound from './option-not-found';

// Helpers
import { machine, states, events } from './state-machine-definition';

//Styles
import { selectStyles, StyledSelect as Select } from './styles';

// Others
import PropTypes from 'prop-types';

const EditableSelect = ({
  label,
  name,
  onChange,
  onCreate = () => {},
  error = null,
  helperText = null,
  value,
  options,
  isCreatable,
  isSearchable,
  className = '',
  onBlur = () => {}
}) => {
  const [state, setState] = useState(machine.value);
  const [typed, setTyped] = useState('');
  const selectRef = useRef();

  const optionsWithCreation = typed
    ? [{ add: true, typed }, ...options]
    : [{ placeholder: true }, ...options];

  useEffect(() => {
    if (value) {
      setState(machine.transition(state, events.SET_INITIAL_VALUE));
    }
    // eslint-disable-next-line
  }, []);

  const handleInputChange = value => {
    if (value && value.length > 40) {
      return;
    }

    setTyped(value);
  };

  const handleValueChange = ({ value, label } = {}) => {
    setTyped(label);
    onChange(value);
    setState(machine.transition(state, events.CHOOSE_VALUE));
  };

  const handleFocus = () => {
    if (state === states.HAS_VALUE) {
      setTyped('');
      onChange('');
    }
    setState(machine.transition(state, events.FOCUS));
    selectRef.current.focus();
  };

  const handleBlur = e => {
    if (state === states.SEARCHING) {
      setTyped('');
    }
    if (typeof onBlur === 'function') onBlur(e);
    setState(machine.transition(state, events.BLUR));
  };

  const handleNonSearchableClick = () => {
    if ([states.NO_VALUE, states.HAS_VALUE].includes(state)) {
      setState(machine.transition(state, events.FOCUS));
      setTyped('');
      onChange('');
    } else if (state === states.SEARCHING) {
      setState(machine.transition(state, events.BLUR));
    }
  };

  const handleCreate = async () => {
    const newValue = await onCreate(typed);
    await onChange(newValue);
    setState(machine.transition(state, events.CHOOSE_VALUE));
  };

  const labelValue = options.find(option => option.value === value)?.label;
  const shownInputValue = () => {
    switch (state) {
      case states.NO_VALUE:
        return '';

      case states.SEARCHING:
        return typed;

      case states.HAS_VALUE:
        return labelValue;
      default:
    }
  };

  return (
    <Select
      classes={className}
      styles={selectStyles}
      options={isCreatable ? optionsWithCreation : options}
      components={{
        Control,
        Option,
        NoOptionsMessage: isCreatable ? NoOptionsMessage : OptionNotFound
      }}
      inputValue={shownInputValue()}
      onInputChange={handleInputChange}
      onChange={handleValueChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onCreate={handleCreate}
      onNonSearchableClick={handleNonSearchableClick}
      menuIsOpen={state === states.SEARCHING}
      typed={typed}
      ref={selectRef}
      label={label}
      name={name}
      error={error}
      helperText={helperText}
      isSearchable={!!isCreatable || !!isSearchable}
    />
  );
};

EditableSelect.defaultProps = {
  error: null,
  helperText: null,
  className: '',
  onCreate: () => {},
  onBlur: () => {}
};

EditableSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onCreate: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default EditableSelect;
