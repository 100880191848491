import React from 'react';

// Auxiliary libraries
import moment from 'moment';
import PropTypes from 'prop-types';

// Components
import EntryEmptyState from '../EntryEmptyState/EntryEmptyState';
import LabelInfo from '../LabelInfo/LabelInfo';

// Assets
import { avatarColors } from '../../../../Dashboard4.0/assets/styles/styles';
import { parseAmount } from '../../../../helpers/helpers';
import EmptyStatePreview from '../EmptyStatePreview';

export const EmptyState = ({ makeTransfers, noAdmin }) => {
  const date = moment().format('YYYY-MM-DD');

  const paymentOrders = [
    {
      tracking_key: 2,
      isRejected: false,
      firstAction: true,
      secondAction: true,
      details: {
        mainText: 'Proveedor de logística',
        secondaryText: 'Banorte 4620',
        dateTime: date,
        description: 'Transferencia por servicios de proveedor',
        amount: parseAmount('2500'),
        type: '',
        colors: avatarColors[0],
        isDateVisible: true,
        status: 'approved'
      }
    },
    {
      tracking_key: 3,
      isRejected: false,
      firstAction: true,
      secondAction: true,
      details: {
        mainText: 'delt.ai',
        secondaryText: '',
        dateTime: date,
        description: 'Abono a esta cuenta',
        amount: parseAmount('800'),
        type: 'success',
        colors: avatarColors[7],
        isDateVisible: false,
        status: 'approved'
      }
    }
  ];

  return (
    <EmptyStatePreview
      data-test="EmptyState"
      EntryEmptyState={
        noAdmin ? (
          <EntryEmptyState
            data-test="EntryEmptyState"
            title="Próximamente verás tus transacciones aquí"
            description="Por el momento solo el usuario del representante legal puede verlas"
          />
        ) : (
          <EntryEmptyState
            data-test="EntryEmptyState"
            title="Tu cuenta sigue como nueva"
            description={
              <span>
                <LabelInfo
                  onClick={makeTransfers}
                  className="cursor"
                  text="Realiza tu primer transferencia"
                />{' '}
                para liberar el potencial de tu crédito{' '}
              </span>
            }
          />
        )
      }
      label={
        noAdmin
          ? 'Cuando puedas visualizar tus transacciones, verás dos tipos de movimientos'
          : 'Cuando ya estés utilizando tu crédito, verás dos tipos de movimientos;'
      }
      labelBold="transferencias"
      labelSuccess="abonos"
      data={paymentOrders}
    />
  );
};

EmptyState.propTypes = {
  makeTransfers: PropTypes.func.isRequired
};

export default EmptyState;
