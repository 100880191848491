/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import SingleInput from '../SingleInput';

import { CodeInputContainer, ErrorMessage } from './CodeInput.styles';

const CodeInput = ({ digits, error, onValid, className }) => {
  const [inputs, setInputs] = useState([]);
  const inputRef = useRef([]);
  const [isValid, setIsValid] = useState(false);
  const [values, setValues] = useState({});

  const handler = (e, ix, step) => {
    const next = inputRef.current[ix + 1];
    let prev = inputRef.current[ix - 1];

    if (ix === inputRef.current.length - 1 && e.target.value)
      prev = inputRef.current[ix];

    if (next && step === 'next') next.focus();
    if (prev && step === 'prev') prev.focus();
  };

  const handleChange = (e, ix) => {
    let value = e.target.value;
    if (value.trim().length === 0) {
      setValues({ ...values, [ix]: '' });
      return '';
    }

    setValues({ ...values, [ix]: value });
    if (value) {
      handler(e, ix, 'next');
    }
  };

  useEffect(() => {
    let elements = [];
    for (let i = 0; i < digits; i += 1) {
      elements.push(i);
    }

    setInputs(elements);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let inputValues = Object.values(values).join('');
    if (inputValues.length === digits) {
      setIsValid(true);
      return;
    }

    setIsValid(false);
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    if (isValid) onValid(Object.values(values).join(''));
    // eslint-disable-next-line
  }, [isValid]);

  return (
    <>
      <CodeInputContainer data-test="CodeInputContainer" className={className}>
        {inputs.length > 0
          ? inputs.map((item, ix) => {
              const isFirstInput = ix === 0;
              return (
                <SingleInput
                  data-test="CodeInput"
                  dataTestId={`SingleInput-${ix}`}
                  ref={el => (inputRef.current[ix] = el)}
                  key={ix}
                  error={error}
                  onChange={e => handleChange(e, ix)}
                  onKeyDown={e => {
                    if (e.key === 'Backspace') handler(e, ix, 'prev');
                  }}
                  autofocus={isFirstInput}
                />
              );
            })
          : null}
      </CodeInputContainer>
      {error ? (
        <ErrorMessage className="codeError">{error}</ErrorMessage>
      ) : null}
    </>
  );
};

CodeInput.propTypes = {
  digits: PropTypes.number.isRequired,
  onValid: PropTypes.func,
  className: PropTypes.string
};

export default CodeInput;
