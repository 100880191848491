/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

import { StyledInput, Wrapper } from './Input.styles.';

const Input = ({ icon, type, placeholder, onChange, ...otherProps }) => {
  return (
    <Wrapper>
      {icon && <Icon icon={icon} />}
      <StyledInput
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        {...otherProps}
      />
    </Wrapper>
  );
};

Input.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

export default Input;
