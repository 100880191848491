import React from 'react';

import Entry from './Entry';
import InputButton from '../../../shared/components/InputButton';
import { ReactComponent as Person } from '../../../../Dashboard4.0/assets/images/person.svg';
import { ReactComponent as Group } from '../../../../Dashboard4.0/assets/images/group.svg';

import { avatarColors } from '../../../../Dashboard4.0/assets/styles/styles';

import {
  AddBeneficiaryEntryContainer,
  HeaderDropdown,
  BodyDropdown,
  OptionDropdown
} from './BeneficiaryEntry.styles';

const AddNewEntry = ({
  addBeneficiary,
  addMultiBeneficiaries,
  showDropdown,
  setShowDropdown
}) => {
  return (
    <AddBeneficiaryEntryContainer
      onClick={() => {
        setShowDropdown(!showDropdown);
      }}
      data-test="addNewEntryComponent"
    >
      <HeaderDropdown>
        <Entry
          colors={avatarColors[6]}
          initials="AB"
          alias="Agregar Beneficiario"
        />
        <InputButton
          type="add"
          data-test="addNewBeneficiaryButton"
          onClick={() => setShowDropdown(!showDropdown)}
        />
      </HeaderDropdown>
      {showDropdown && (
        <BodyDropdown>
          <OptionDropdown onClick={addBeneficiary}>
            <Person /> <span>Agregar 1 beneficiario</span>
          </OptionDropdown>
          <OptionDropdown onClick={addMultiBeneficiaries}>
            <Group /> <span>Agregar múltiples beneficiarios</span>
          </OptionDropdown>
        </BodyDropdown>
      )}
    </AddBeneficiaryEntryContainer>
  );
};
export default AddNewEntry;
