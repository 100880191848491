// React
import React, { Fragment, useState } from 'react';

// Auxiliary libraries
import { Grid, Hidden, Modal } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import Webcam from 'react-webcam';

// Own code
import { PrimaryButton, WhiteButton } from '../Shared/';
import { convertB64ToFile, getBase64, getFileName } from '../../helpers/';

// Assets
import face from './marker.svg';
import IDFront from '../../assets/ine_face.svg';
import IDBack from '../../assets/ine_fingerprint.svg';
import { useSelector } from 'react-redux';
import IdVerificationFile from '../CustomModal/IdVerificationFile';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const videoConstraints = {
  width: 720,
  height: 480,
  facingMode: 'user'
};

const useStyles = makeStyles(theme => ({
  cameraContainer: {
    height: '30rem'
  },
  cameraContainerImg: {
    height: '30rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  edgeBottomLeft: {
    top: '20.4rem',
    borderBottom: '0.6rem solid #2954ff',
    borderLeft: '0.6rem solid #2954ff'
  },
  edgeBottomRight: {
    top: '20.4rem',
    left: '35.5rem',
    borderBottom: '0.6rem solid #2954ff',
    borderRight: '0.6rem solid #2954ff'
  },
  edgeTopLeft: {
    borderTop: '0.6rem solid #2954ff',
    borderLeft: '0.6rem solid #2954ff'
  },
  edgeTopRight: {
    left: '35.5rem',
    borderTop: '0.6rem solid #2954ff',
    borderRight: '0.6rem solid #2954ff'
  },
  paper: {
    position: 'absolute',
    width: '50rem',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  camera: {
    width: '35rem',
    height: '30rem'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  miniImg: {
    height: '4.5rem',
    width: '8rem',
    marginLeft: '0.5rem'
  },
  modalTitle: {
    fontSize: '1.6rem',
    verticalAlign: 'middle',
    width: '30rem',
    textAlign: 'center'
  },
  modalHeader: {
    padding: '1rem'
  },
  buttonsContainer: {
    paddingTop: '2rem'
  },
  faceMask: {
    width: 'inherit',
    height: 'inherit',
    position: 'absolute',
    zIndex: '1000'
  },
  pictureEdges: {
    width: '4.4rem',
    height: '4.4rem',
    position: 'absolute',
    [theme.breakpoints.only('xs')]: {
      width: '2.4rem',
      height: '2.4rem'
    }
  },
  pictureStyles: {
    position: 'relative',
    width: '40rem',
    height: '25rem',
    'z-index': '2',
    bottom: '27.5rem',
    marginLeft: '1rem'
  }
}));

export default function IneModal({
  selfie,
  ineFront,
  ineBack,
  savePhoto,
  onClose,
  open,
  width
}) {
  // Hooks
  const [modalStyle] = useState(getModalStyle);
  const [imgSrc, setImgSrc] = useState('');
  const [mirrored, setMirrored] = useState(true);
  const [file, setFile] = useState(null);
  const [resetInput, setResetInput] = useState(false);

  const companyTypeId = useSelector(
    state => state.userInfoReducer.user.company_type_id
  );
  const selfieDocumentID = companyTypeId === 2 ? 13 : 14;
  const ineFrontID = companyTypeId === 2 ? 1 : 9;
  const ineBackID = companyTypeId === 2 ? 11 : 12;
  const documentId = ineFront ? ineFrontID : ineBackID;

  // Styles
  const classes = useStyles();

  // Other variables
  const webcamRef = React.useRef(null);
  const takePhoto = React.useCallback(() => {
    setMirrored(false);
    setTimeout(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }, process.env.REACT_APP_IMAGE_MIRRORING_TIMEOUT);
  }, [webcamRef]);

  const uploadPhoto = () => {
    setMirrored(true);
    const fileImage = convertB64ToFile(imgSrc, 'fileName.jpg');

    if (selfie) {
      setImgSrc('');
      savePhoto('selfie.jpg', fileImage, selfieDocumentID);
      onClose();
      return;
    }

    if (ineFront) {
      setImgSrc('');
      savePhoto('ine_anverso.jpg', fileImage, ineFrontID);
      onClose();
      return;
    }
    if (ineBack) {
      setImgSrc('');
      savePhoto('ine_reverso.jpg', fileImage, ineBackID);
      onClose();
      return;
    }
  };

  const tryAgain = () => {
    setMirrored(true);
    setImgSrc('');
    setFile(null);
    setResetInput(true);
  };

  const validateFile = async (e, element) => {
    setResetInput(false);
    const file = e.target;
    const fileName = getFileName(file.name, element.keyword);
    const documentId = element.kyc_document_type_id;

    if (!file) {
      setFile(null);
      return;
    }

    const fileToB64 = await getBase64(file.file);
    setImgSrc(file.file ? `data:image/jpeg;base64,${fileToB64}` : '');
    setFile({
      fileName: fileName,
      content: file.file,
      kyc: documentId
    });
  };

  const uploadFile = async () => {
    savePhoto(file.fileName, file.content, file.kyc);

    setFile(null);
    setResetInput(true);
    setImgSrc('');
    onClose();
  };

  const pictureFrame = (
    <div className={classes.pictureStyles}>
      <div className={`${classes.pictureEdges} ${classes.edgeTopRight}`}></div>
      <div
        className={`${classes.pictureEdges} ${classes.edgeBottomRight}`}
      ></div>
      <div
        className={`${classes.pictureEdges} ${classes.edgeBottomLeft}`}
      ></div>
      <div className={`${classes.pictureEdges} ${classes.edgeTopLeft}`}></div>
    </div>
  );

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose}
      >
        <div
          style={modalStyle}
          className={`${classes.paper} ${classes.modalContent}`}
        >
          <Grid className={classes.modalHeader} container justify="center">
            {selfie && (
              <span className={classes.modalTitle}>
                En este paso tomaremos la foto
              </span>
            )}
            {ineFront && (
              <Fragment>
                <span className={classes.modalTitle}>
                  Coloca la parte de enfrente de tu INE para continuar con el
                  registro
                </span>
                <img alt="example" className={classes.miniImg} src={IDFront} />
              </Fragment>
            )}
            {ineBack && (
              <Fragment>
                <span className={classes.modalTitle}>
                  Coloca el reverso de tu INE para continuar con el registro
                </span>
                <img alt="example" className={classes.miniImg} src={IDBack} />
              </Fragment>
            )}
          </Grid>

          <div
            className={
              file?.content
                ? classes.cameraContainerImg
                : classes.cameraContainer
            }
          >
            {imgSrc ? (
              <img
                src={imgSrc}
                alt="Example frame"
                width={file?.content ? '50%' : '100%'}
              />
            ) : (
              <React.Fragment>
                {selfie && (
                  <img
                    src={face}
                    alt="Face mask"
                    className={classes.faceMask}
                  />
                )}
                <Webcam
                  audio={false}
                  height={width === 'xs' ? 296 : 296}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={width === 'xs' ? 250 : 419}
                  videoConstraints={videoConstraints}
                  screenshotQuality={1}
                  mirrored={mirrored}
                />
                <Hidden xsDown>
                  {ineFront || ineBack ? pictureFrame : null}
                </Hidden>
              </React.Fragment>
            )}
          </div>

          <div className={classes.buttonsContainer}>
            <WhiteButton
              copy="Intentar de nuevo"
              onClick={tryAgain}
              disabled={!imgSrc}
            />
            {imgSrc ? (
              <PrimaryButton
                onClick={uploadPhoto}
                text="Subir"
                disabled={file?.content}
              />
            ) : (
              <PrimaryButton onClick={takePhoto} text="Tomar foto" />
            )}
          </div>

          {ineFront || ineBack ? (
            <IdVerificationFile
              selfieDocumentID={documentId}
              file={file}
              resetInput={resetInput}
              onChange={validateFile}
              uploadFile={uploadFile}
            />
          ) : null}
        </div>
      </Modal>
    </div>
  );
}
