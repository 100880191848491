export const blockCardHandler = ({ changeCardStatus, authUser }) => async ({
  cardId,
  userId,
  email,
  password
}) => {
  const { error: authError } = await authUser({
    email,
    password
  });
  if (authError) {
    return { isSuccess: false, error: authError };
  }

  const { error } = await changeCardStatus({
    cardId,
    cardStatusId: 3,
    userId
  });

  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true };
};
