import styled, { css } from 'styled-components';
import { fonts, colors } from '../../../../Dashboard4.0/assets/styles/styles';

const getLabelStyles = ({ type }) => {
  if (type === 'normal') {
    return labelStylesNormal;
  }

  return labelStylesMedium;
};

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsLabel = styled.span`
  color: ${colors.carbon};
  font-size: 1.55rem;
  ${getLabelStyles}
`;

const labelStylesNormal = css`
  ${fonts.barlowRegular}
`;

const labelStylesMedium = css`
  ${fonts.barlowMedium}
`;
