/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { memo, useState } from 'react';

import PropTypes from 'prop-types';

import AddNewEntry from '../BeneficiaryEntry/AddNewEntry';
import BeneficiaryEntryContainer from '../BeneficiaryEntry/BeneficiaryEntryContainer';
import EmptyStateCopy from '../../../shared/components/EmptyStateCopy';

import {
  BeneficiaryListContainer,
  ScrollableListContainer
} from './BeneficiaryList.styles';

const BeneficiaryList = ({
  beneficiaries,
  notFound,
  goToTransferForm,
  goToBeneficiaryForm,
  goToMultiBeneficiariesForm,
  deleteBeneficiary
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <BeneficiaryListContainer>
      {!beneficiaries.length && notFound ? (
        <EmptyStateCopy
          copy="Ningún beneficiario coincide con"
          searchParam={notFound}
        />
      ) : (
        <>
          <div className="addNewEntry">
            <AddNewEntry
              addBeneficiary={goToBeneficiaryForm}
              addMultiBeneficiaries={goToMultiBeneficiariesForm}
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
            />
          </div>
          <ScrollableListContainer sm={showDropdown}>
            <div>
              {beneficiaries.map((item, index) => (
                <BeneficiaryEntryContainer
                  key={item.beneficiary_id}
                  id={item.beneficiary_id}
                  alias={item.beneficiary_alias}
                  index={index}
                  fullName={item.beneficiary_full_name}
                  bankingInstitution={item.beneficiary_banking_institution_name}
                  accountCode={item.beneficiary_account_code}
                  onClick={e => goToTransferForm(item)}
                  deleteBeneficiary={deleteBeneficiary}
                />
              ))}
            </div>
          </ScrollableListContainer>
        </>
      )}
    </BeneficiaryListContainer>
  );
};

BeneficiaryList.propTypes = {
  beneficiaries: PropTypes.array,
  notFound: PropTypes.string,
  goToTransferForm: PropTypes.func,
  deleteBeneficiary: PropTypes.func
};

export default memo(BeneficiaryList);
