import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  flexContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  newDashboardStyles: {
    position: 'absolute',
    zIndex: 1
  }
}));

const CustomAlert = ({
  color,
  children,
  linkText,
  linkTo,
  onClick,
  severity,
  text
}) => {
  const classes = useStyles();
  const routes = ['/activity', '/account', '/credit', '/invoices'];
  const [match, setMatch] = useState(false);

  useEffect(() => {
    setMatch(routes.includes(window.location.pathname));
    //eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <div
      className={`${classes.root} ${match ? classes.newDashboardStyles : ''}`}
    >
      <Alert severity={severity} color={color}>
        <div className={classes.flexContainer}>
          {children}
          <span data-test="text-alert">
            {text}
            {linkTo && (
              <a href={linkTo} target="_blank" rel="noopener noreferrer">
                {linkText ? linkText : linkTo}
              </a>
            )}
          </span>
          {onClick && (
            <span
              data-test="close-alert"
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
              onClick={onClick}
            >
              X
            </span>
          )}
        </div>
      </Alert>
    </div>
  );
};

export default CustomAlert;
