/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import Item from './Item';
import CheckboxItem from './CheckboxItem';
import {
  LabelFor,
  Options,
  StyledDropdown,
  Wrapper,
  Dot,
  IconContainer
} from './Dropdown.styles';

const Dropdown = ({
  icon,
  iconWidth,
  iconHeight,
  label,
  options,
  keys,
  onSelect,
  selectedOption,
  checkbox = false
}) => {
  const [selectedValue, setSelectedValue] = useState(selectedOption || '');

  const handleSelect = (e, label, value) => {
    setSelectedValue(value);
    onSelect({ key: label, value: value });
  };

  const resetSelect = () => {
    setSelectedValue('');
  };

  return (
    <StyledDropdown>
      <Wrapper color={selectedOption ? 'primary' : ''}>
        {selectedOption && <Dot />}
        <IconContainer>
          {icon && (
            <Icon
              icon={icon}
              width={iconWidth}
              height={iconHeight}
              color={selectedOption ? 'primary' : ''}
            />
          )}
        </IconContainer>
        <LabelFor onClick={resetSelect}>{label}</LabelFor>
      </Wrapper>

      <Options>
        {options &&
          options.map((el, ix) => {
            return !checkbox ? (
              <Item
                value={selectedValue === el[keys.value] ? 'true' : 'false'}
                key={ix}
                text={el[keys.label]}
                onClick={e => handleSelect(e, el[keys.label], el[keys.value])}
              />
            ) : (
              <CheckboxItem
                value={selectedValue === el[keys.value] ? true : false}
                key={ix}
                text={el[keys.label]}
                onClick={e => handleSelect(e, el[keys.label], el[keys.value])}
              />
            );
          })}
      </Options>
    </StyledDropdown>
  );
};

Dropdown.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  keys: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Dropdown;
