import React, { useEffect, useState } from 'react';

import TransactionForm from './TransactionForm';
import { useDispatch, useSelector } from 'react-redux';

import ButtonContainer from './ButtonContainer';
import HeadContent from './HeadContent';
import Header from '../../../micro-frontends/shared/components/Modal/Header';
import Title from '../../../micro-frontends/shared/components/Title';
import { TransactionContainer } from './TransactionForm.styles';

import { clearTags } from '../../../micro-frontends/tags/redux/tag-action';
import { tagsSelector } from '../../../micro-frontends/tags/redux/tag-selectors';

const TransacionModal = ({ changePage, resetModal }) => {
  const dispatch = useDispatch();
  const beneficiary = useSelector(state => state.speiReducer.beneficiary);
  const tagsAdded = useSelector(tagsSelector);

  const [confirmView, setConfirmView] = useState(false);
  const [transaction, setTransaction] = useState({});

  const handleSubmit = transaction => {
    setTransaction(transaction);
    setConfirmView(true);
  };

  useEffect(() => {
    dispatch(clearTags());
  }, []);

  return (
    <TransactionContainer data-test="transactionContainer">
      <Header data-test="transactionHeader">
        <Title
          size="large"
          text="Nueva transferencia"
          className="title"
          data-test="transactionTitle"
        />

        {beneficiary && (
          <HeadContent
            data-test="transactionHeadContent"
            beneficiary={beneficiary}
            transaction={transaction}
            confirm={confirmView}
            changePage={changePage}
            tagsAdded={tagsAdded}
          />
        )}
      </Header>

      {!confirmView && (
        <TransactionForm
          transaction={transaction}
          data-test="transactionForm"
          submit={handleSubmit}
        />
      )}

      {confirmView && (
        <ButtonContainer
          beneficiary={beneficiary}
          transaction={transaction}
          onClose={resetModal}
          showConfirmView={setConfirmView}
        />
      )}
    </TransactionContainer>
  );
};

export default TransacionModal;
