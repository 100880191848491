const createBankInformationHandler = ({
  createBankInformation
}) => async bankInformation => {
  const { error } = await createBankInformation(bankInformation);

  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true };
};

export { createBankInformationHandler };
