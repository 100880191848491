/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { DateContainer, DayContainer, YearContainer } from './Date.styles';

const Date = ({ day, month, year, color }) => {
  return (
    <DateContainer color={color} data-test="dateComponent">
      <DayContainer data-test="dayContainer">
        {month} {day}
      </DayContainer>
      {year && <YearContainer data-test="yearContainer">{year}</YearContainer>}
    </DateContainer>
  );
};

Date.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  color: PropTypes.string
};

export default memo(Date);
