import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { personDocumentsPath, companyDocumentsPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';
import { freelancerId } from '../constants';

export const uploadDocumentService = async (document, compantTypeId) => {
  const documentsUrl =
    compantTypeId === freelancerId ? personDocumentsPath : companyDocumentsPath;

  try {
    const { data, status, error } = await customAxiosInstance(
      process.env.REACT_APP_AXIOS_KYC_TIMEOUT
    ).post(documentsUrl, document);
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'upload-document-service'
    });
  } catch (error) {
    log.error('upload-document-service');
    return { error: { message: 'Unable to send request' } };
  }
};
