import React from 'react';

import { useDispatch } from 'react-redux';

import Modal from '../../../shared/components/Modal';
import ModalInformation from '../../../shared/components/ModalInformation';

import { service } from '../../../tags/service';

const RemoveTransactionTagModal = ({ onClose, tag, customDelete }) => {
  const dispatch = useDispatch();

  const handleDeleteBasedOnType = async () =>
    // eslint-disable-next-line no-return-await
    await service({ dispatch }).deleteCardTransactionTag({ tag });

  const handleRemoveTag = async () => {
    if (customDelete) await customDelete(tag);
    else await handleDeleteBasedOnType();
    onClose();
  };

  return (
    <Modal
      open
      onClose={onClose}
      showCrossSign={false}
      disableBackDropClick={false}
      showCloseOnModal
    >
      <ModalInformation
        title="¿Estás seguro que deseas eliminar esta etiqueta?"
        description="Al eliminar esta etiqueta se retirará en todas las transacciones futuras asociadas a esta tarjeta, pero se mantendrá la etiqueta en transacciones existentes."
        primaryButtonText="Sí, eliminar"
        secondaryButtonText="No, conservar"
        onPrimaryButtonClick={handleRemoveTag}
        onSecondaryButtonClick={onClose}
      />
    </Modal>
  );
};

export default RemoveTransactionTagModal;
