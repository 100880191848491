import * as yup from 'yup';
import { isClabeInvalid } from '../../../helpers/clabeValidator.js';

const requiredFieldMessage = 'Campo requerido';

export const validationSchema = yup.object({
  bank: yup
    .string()
    .test('bank validator', 'Banco no operante', function (bank) {
      const { path, createError } = this;

      if (bank === 'No encontrado') {
        return createError({
          path,
          message: 'Institución bancaria no operante'
        });
      }
      return {};
    }),
  clabe: yup
    .string()
    .test('clabe validator', 'Clabe inválida', function (clabe) {
      const { path, createError } = this;
      if (!clabe) {
        return createError({ path, message: requiredFieldMessage });
      }

      return createError({ path, message: isClabeInvalid(clabe) });
    })
});
