// React
import React from 'react';

// Components
import AccountPageMobileTabs from './AccountPageMobileTabs';
import AccountPageSideNav from './AccountPageSideNav';

const AccountPageNav = ({ state, handleTabChange, isMobile }) => {
  return (
    <>
      {isMobile ? (
        <AccountPageMobileTabs
          state={state}
          handleTabChange={handleTabChange}
          isMobile
        />
      ) : (
        <AccountPageSideNav state={state} handleTabChange={handleTabChange} />
      )}
    </>
  );
};

export default AccountPageNav;
