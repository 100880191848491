export const selectAddressPage = state => state.onboardingReducer.addressPage;
export const selectEnterpriseSteps = state =>
  state.onboardingReducer.enterpriseSteps;
export const selectFreelancerBasicInfo = state =>
  state.onboardingReducer.freelancerBasicInfo;
export const selectFreelancerSteps = state =>
  state.onboardingReducer.freelancerSteps;
export const selectPayerName = state =>
  selectUser(state).company_information.payer_name;
export const selectUser = state => state.userInfoReducer.user;
export const selectCompanyTypeId = state => selectUser(state).company_type_id;
export const selectBankInformationPage = state =>
  state.onboardingReducer.bankInformation;
export const selectEnterpriseInfo = state =>
  state.onboardingReducer.enterpriseInfo;
export const selectOnboardingState = state => state.onboardingReducer.status;
