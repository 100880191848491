import React, { Fragment } from 'react';

import { Tag, TagListContainer } from './style';

import TagItem from '../TagItem';
import Tooltip from '../../shared/components/HelperTooltip/';

import { TagCreator } from '../domain/tagData';

const TagList = ({
  tags,
  handleSaveTag,
  handleDeleteTag,
  showAddNewTag = true,
  className,
  lengthValidation,
  isDeleteVisible = true,
  hasPermissions
}) => {
  const handleDelete = tag => {
    handleDeleteTag && handleDeleteTag(tag);
  };

  const showTooltip = tag => {
    const labelLength = tag?.tagLabel?.length;
    const length = lengthValidation || 10;
    return labelLength > length;
  };

  return (
    <TagListContainer className={className}>
      {tags && tags.length > 0
        ? tags.map(el => {
            const tag = el.tag_label ? TagCreator(el) : el;
            return (
              <Fragment key={tag.tagId}>
                {showTooltip(tag) ? (
                  <Tooltip tooltipText={tag.tagLabel} key={tag.tagId}>
                    <Tag
                      text={tag.tagLabel}
                      isDeleteVisible={hasPermissions ? isDeleteVisible : false}
                      color="purple"
                      onDeleteClicked={() => handleDelete(tag)}
                      className="tagItem"
                    />
                  </Tooltip>
                ) : (
                  <Tag
                    key={tag.tagId}
                    text={tag.tagLabel}
                    isDeleteVisible={hasPermissions ? isDeleteVisible : false}
                    color="blue"
                    onDeleteClicked={() => handleDelete(tag)}
                    className="tagItem"
                  />
                )}
              </Fragment>
            );
          })
        : null}
      {showAddNewTag && hasPermissions ? (
        <TagItem handleSaveTag={handleSaveTag} />
      ) : null}
    </TagListContainer>
  );
};

export default TagList;
