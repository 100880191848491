import React, { useState, useEffect } from 'react';

import escapeStringRegexp from 'escape-string-regexp';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../../../actionCreators/changeViewAction';

import {
  deleteRecipient,
  getBeneficiaries,
  setBeneficiary
} from '../../../../actionCreators/speiAction';
import { getBeneficiariesCsv } from '../../../../Services/spei';

import { downloadFile, fileFormat } from '../../../../helpers/fileManager.js';

import MultipleTransfersButton from './MultipleTransfersButton';
import BeneficiaryFilter from './BeneficiaryFilter';
import BeneficiaryList from '../BeneficiaryList/BeneficiaryList';
import Header from '../../../shared/components/Modal/Header';
import Title from '../../../shared/components/Title';

import { SelectBeneficiaryContainer } from './SelectBeneficiary.styles';

const SelectBeneficiary = props => {
  const [filtered, setFiltered] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [regexp, setRegexp] = useState('');

  const beneficiaries = useSelector(
    state => state.speiReducer.beneficiaries.res
  );
  const dispatch = useDispatch();

  const cleanStates = () => {
    setFiltered(beneficiaries);
    setRegexp('');
    setNotFound(null);
  };

  const searchBeneficiaries = toSearch => {
    if (!toSearch) {
      cleanStates();
      return;
    }

    setRegexp(toSearch);
    const regex = new RegExp(escapeStringRegexp(regexp), 'gi');

    let filteredList = beneficiaries.filter(beneficiary => {
      return (
        beneficiary.beneficiary_account_code.match(regex) ||
        beneficiary.beneficiary_full_name.match(regex) ||
        beneficiary.beneficiary_alias.match(regex)
      );
    });
    if (!filteredList.length) {
      setNotFound(regexp);
    }
    setFiltered(filteredList);
  };

  const goToTransferForm = beneficiary => {
    setPage(dispatch, 'transactionFlow');
    setBeneficiary(dispatch, beneficiary);
  };

  const goToBeneficiaryForm = () => {
    setPage(dispatch, 'addBeneficiary');
  };

  const goToMultiBeneficiariesForm = () => {
    setPage(dispatch, 'addMultiBeneficiaries');
  };

  const goToBatchTransfers = () => {
    setPage(dispatch, 'batchTransfer');
  };

  const deleteBeneficiary = async id => {
    await deleteRecipient(dispatch, id);
    await getBeneficiaries(dispatch);
  };

  const handleBeneficiaryCsvDownload = async () => {
    try {
      const { status, data } = await getBeneficiariesCsv();
      if (status === 200) {
        downloadFile(
          fileFormat.csv,
          'base64',
          data[0]?.beneficiary_payment_accounts,
          'beneficiarios',
          'csv'
        );
      }
    } catch (error) {
      console.error('get-beneficiaries-csv-file: ', error);
    }
  };

  useEffect(() => {
    getBeneficiaries(dispatch);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    searchBeneficiaries(regexp);
    //eslint-disable-next-line
  }, [beneficiaries, regexp, notFound]);

  return (
    <SelectBeneficiaryContainer>
      <Header>
        <Title
          size="large"
          text="Transferencias"
          className="selectBeneficiaryTitle"
        />
      </Header>
      <div className="multiTransferOption">
        <MultipleTransfersButton onClick={goToBatchTransfers} />
      </div>
      <BeneficiaryFilter
        searchPlaceholder="Buscar beneficiario"
        searchFn={searchBeneficiaries}
        downloadBeneficiariesCsv={handleBeneficiaryCsvDownload}
      />
      <BeneficiaryList
        beneficiaries={filtered || beneficiaries}
        notFound={notFound}
        goToBeneficiaryForm={goToBeneficiaryForm}
        goToMultiBeneficiariesForm={goToMultiBeneficiariesForm}
        goToTransferForm={goToTransferForm}
        deleteBeneficiary={deleteBeneficiary}
      />
    </SelectBeneficiaryContainer>
  );
};

export default SelectBeneficiary;
