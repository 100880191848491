import React, { useState } from "react";
import HSpacing from "../spacing/horizontal-spacing";
import Heading from "../heading";
import Center from "../center";
import Dropdown from "../dropdown";
import VSpacing from "../spacing/vertical-spacing";
import InputText from "../input-text";
import Brand from "../../assets/brand.svg";
import Button from "../button";

const options = [
    { value: "457", label: "(+52) México", lada: `+52` },
    { value: "522", label: "(+1) USA", lada: `+1` },
    { value: "577", label: "(+1) Canada", lada: `+1` },
];

function PhoneNumber({ lada, phone, onChange }) {
    const [newLada, setNewLada] = useState(lada);
    const [newPhone, setNewPhone] = useState(phone);
    const setPhone = (value) => {
        if (/^\d{0,10}$/.test(value)) {
            setNewPhone(value);
        }
    };
    return (
        <>
            <HSpacing times={4} />
            <Center>
                <img src={Brand} alt="" />
            </Center>
            <HSpacing times={4} />
            <Center>
                <Heading>Modificar número celular</Heading>
            </Center>
            <HSpacing times={4} />
            <div
                style={{
                    display: "flex",
                    width: "432px",
                }}
            >
                <Dropdown
                    styleContainer={{
                        width: "208px",
                    }}
                    placeholder="Lada"
                    options={options}
                    value={newLada.value}
                    onChange={setNewLada}
                />
                <VSpacing times={2} />
                <InputText
                    style={{ width: "190px" }}
                    hintText="Número celular (whatsapp)"
                    value={newPhone}
                    onChange={setPhone}
                />
            </div>
            <HSpacing times={4} />
            <Center>
                <Button
                    style={{ fontWeight: 600 }}
                    type="primary"
                    width={432}
                    label="Continuar"
                    onClick={() => {
                        onChange(options.find(e => e.value === newLada.value).lada + newPhone);
                    }}
                    disabled={!(newLada.value && newPhone.length === 10)}
                />
            </Center>
        </>
    );
}

export default PhoneNumber;
