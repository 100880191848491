import { registerUserHandler } from './eventHandlers/registerUser';
import { resendActivationHandler } from './eventHandlers/resendActivation';
import { checkRFCHandler } from './eventHandlers/checkRFC';

import { registerUserService } from './infrastructure/registerUser';
import { resendActivationService } from './infrastructure/resendActivation';
import { checkRFCService } from './infrastructure/checkRFC';

const service = () => ({
  registerUser: registerUserHandler({
    registerUser: registerUserService
  }),
  resendActivation: resendActivationHandler({
    resendActivation: resendActivationService
  }),
  checkRFC: checkRFCHandler({
    checkRFC: checkRFCService
  })
});

export { service };
