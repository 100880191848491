import React from 'react';
import Amount from '../../../shared/components/Amount/Amount';

import { AmountLabelContainer, AmountText } from './styles';

const AmountLabel = ({ amount, text }) => {
  return (
    <AmountLabelContainer>
      <label className="text">{text}:</label>
      $ <AmountText integer={amount.integer} decimals={amount.decimals} />{' '}
    </AmountLabelContainer>
  );
};

export default AmountLabel;
