import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Paragraph = ({
  color,
  text,
  fontStyle,
  size,
  textAlign,
  lineHeight,
  letterSpacing
}) => {
  return (
    <Container
      color={color}
      fontStyle={fontStyle}
      size={size}
      textAlign={textAlign}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
    >
      {text}
    </Container>
  );
};

Paragraph.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  fontStyle: PropTypes.string,
  size: PropTypes.string,
  textAlign: PropTypes.string,
  lineHeight: PropTypes.string,
  letterSpacing: PropTypes.string
};

export default Paragraph;
