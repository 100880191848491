import React, { useState } from 'react';

import DocumentsManager from '../../components/DocumentsManager';
import OnboardingErrorBoundary from '../OnboardingErrorBoundary/OnboardingErrorBoundary';
import OnboardingLayout from '../../components/OnboardingLayout/OnboardingLayout';

import { documentsCatalogue } from '../../helpers/docs-catalogue';

const Component = ({ steps, activeStep, goBack, goToNextStep, user }) => {
  const type = user.company_type_id === 2 ? 'person' : 'company';
  const documents = documentsCatalogue[type];

  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const handleFileChange = e => {
    setUploadedDocuments(e);
  };

  return (
    <OnboardingLayout
      title={'Documentos'}
      description="Sube los siguientes documentos de la empresa"
      onPrimaryButtonClick={() =>
        goToNextStep({ uploadedDocuments, type: user.company_type_id })
      }
      primaryButtonText="Continuar"
      secondaryButtonText="Regresar"
      isModalVisible={true}
      onSecondaryButtonClick={goBack}
      steps={steps}
      activeStep={activeStep}
    >
      <DocumentsManager
        documents={documents}
        type={type}
        onChangeFile={handleFileChange}
      />
    </OnboardingLayout>
  );
};

const DocumentsPage = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="DocumentsPage"
      props={props}
    />
  );
};

export default DocumentsPage;
