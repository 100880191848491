import React, { useEffect, useState } from 'react';

// Auxiliar libraries
import { useFormik } from 'formik';

// Components
import TextField from '../../../shared/components/TextField/TextField';
import FormRow from '../../components/form-row';
import InformationModal from '../../../shared/components/ModalInformation';
import Select from '../../../shared/components/EditableSelect';
import { Alert } from '@material-ui/lab';

// Others
import { validationSchema } from './validation-schema-delivery';

const ModalDeliveryAddress = ({
  handleSubmit,
  handleSecondaryAction,
  addressStates,
  initialValues = {
    street: '',
    externalNum: '',
    internalNum: '',
    settlement: '',
    postalCode: '',
    city: '',
    stateId: '',
    building_type: '',
    reference: ''
  }
}) => {
  const [form, setForm] = useState(initialValues);
  const formik = useFormik({
    initialValues,
    validationSchema
  });

  useEffect(() => {
    formik.setFieldTouched();
    // eslint-disable-next-line
  }, []);

  const handleChange = e => {
    formik.handleChange(e);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <InformationModal
      title="Confirmar dirección de envío"
      description="Por favor confirma tu dirección de envío:"
      primaryButtonText="Confirmar pedido"
      onPrimaryButtonClick={() => handleSubmit(form)}
      isPrimaryButtonDisabled={!formik.isValid}
      onSecondaryButtonClick={() => handleSecondaryAction(form)}
      secondaryButtonText="Regresar"
    >
      <TextField
        data-test="street"
        className="formField"
        label="Calle"
        name="street"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.street && formik.touched.street}
        helperText={formik.touched.street && formik.errors.street}
        value={form.street}
      />
      {formik?.errors?.street_address_validation && (
        <>
          <Alert severity="error">
            {formik?.errors?.street_address_validation}
          </Alert>
          <br />
        </>
      )}
      <FormRow>
        <TextField
          data-test="externalNum"
          className="formField"
          label="No Ext"
          name="externalNum"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.externalNum && formik.touched.externalNum}
          helperText={formik.touched.externalNum && formik.errors.externalNum}
          value={form.externalNum}
        />
        <TextField
          data-test="internalNum"
          className="formField"
          label="No Int"
          name="internalNum"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.internalNum && formik.touched.internalNum}
          helperText={formik.touched.internalNum && formik.errors.internalNum}
          value={form.internalNum}
        />
      </FormRow>
      <FormRow>
        <TextField
          data-test="settlement"
          className="formField"
          label="Colonia"
          name="settlement"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.settlement && formik.touched.settlement}
          helperText={formik.touched.settlement && formik.errors.settlement}
          value={form.settlement}
        />
        <TextField
          data-test="postalCode"
          className="formField"
          label="Código postal"
          name="postalCode"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.postalCode && formik.touched.postalCode}
          helperText={formik.touched.postalCode && formik.errors.postalCode}
          value={form.postalCode}
        />
      </FormRow>
      {formik?.errors?.settlement_city_validation && (
        <>
          <br />
          <Alert severity="error">
            {formik?.errors?.settlement_city_validation}
          </Alert>
          <br />
        </>
      )}
      {formik?.errors?.postalCode_validation && (
        <>
          <br />
          <Alert severity="error">
            {formik?.errors?.postalCode_validation}
          </Alert>
          <br />
        </>
      )}
      <TextField
        data-test="city"
        className="formField"
        label="Ciudad"
        name="city"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.city && formik.touched.city}
        helperText={formik.touched.city && formik.errors.city}
        value={form.city}
      />
      <Select
        data-test="stateId"
        className="formField"
        label="Estado"
        name="stateId"
        onChange={stateId => {
          formik.setFieldValue('stateId', stateId);
          setForm({ ...form, stateId });
        }}
        error={formik.errors.stateId && formik.touched.stateId}
        helperText={formik.touched.stateId && formik.errors.stateId}
        options={addressStates.map(({ stateId, stateName }) => ({
          label: stateName,
          value: stateId
        }))}
        value={parseInt(form.stateId)}
      />
      <Select
        data-test="building_type"
        className="formField"
        label="Tipo de edificio (si es tu oficina, pon Comercial)"
        name="building_type"
        onChange={building_type => {
          formik.setFieldValue('building_type', building_type);
          setForm({ ...form, building_type });
        }}
        error={formik.errors.building_type && formik.touched.building_type}
        helperText={formik.touched.building_type && formik.errors.building_type}
        options={[
          {
            label: 'Residencial',
            value: 'Residencial'
          },
          {
            label: 'Comercial',
            value: 'Comercial'
          }
        ]}
        value={form.building_type}
      />
      <TextField
        data-test="reference"
        className="formField"
        label="Referencia"
        name="reference"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.reference && formik.touched.reference}
        helperText={formik.touched.reference && formik.errors.reference}
        value={form.reference}
      />
    </InformationModal>
  );
};

export default ModalDeliveryAddress;
