//Yup is a JavaScript object schema validator and object parser check documentation for more details https://www.npmjs.com/package/yup
import * as yup from 'yup';

let requiredFieldMessage = 'Campo requerido';

export const validation = yup.object({
  agreement: yup
    .bool()
    .oneOf([true], 'Debes aceptar el contrato')
    .required(requiredFieldMessage),
  certificate: yup.mixed().test({
    name: 'validator-custom-certified',
    // eslint-disable-next-line object-shorthand
    test: function (value) {
      if (!value) {
        return this.createError({
          message: requiredFieldMessage
        });
      }

      const validateCert =
        ['application/x-x509-ca-cert'].includes(value.type) ||
        ['application/pkix-cert'].includes(value.type);

      return validateCert
        ? true
        : this.createError({
            message: 'Sólo admite archivos tipo .cer'
          });
    }
  }),
  creditReport: yup
    .bool()
    .oneOf([true], 'Debes aceptar el reporte de crédito')
    .required(requiredFieldMessage),
  keyfile: yup.mixed().test({
    name: 'validator-custom-keyfile',
    // eslint-disable-next-line object-shorthand
    test: function (value) {
      if (!value) {
        return this.createError({
          message: requiredFieldMessage
        });
      }

      const type = value.name.split('.').slice(-1).pop();

      const isInvalid = type !== 'key';
      return isInvalid
        ? this.createError({
            message: 'Sólo admite archivos tipo .key'
          })
        : true;
    }
  }),
  password: yup.string().required(requiredFieldMessage)
});
