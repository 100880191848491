import React, { useState, useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import log from 'loglevel';
import { TextField } from '@material-ui/core/';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../Input/Input';
import InputCard from '../../InputCard/InputCard';
import MainTitle from '../../Shared/MainTitle/MainTitle';
import Paragraph from '../../Shared/Paragraph/Paragraph';
import PrimaryButton from '../../Shared/PrimaryButton/PrimaryButton';
import Subtitle from '../../Shared/Subtitle/';
import WhiteButton from '../../Shared/whiteButton/whiteButton';

import { createBankInformation } from '../../../Services/Company';
import { getFileName } from '../../../helpers/helpers';
import { getBankings } from '../../../actionCreators/speiAction';
import { removeBlankSpaces } from '../../../helpers/clabeValidator.js';
import MissingDocumentsContext from '../../../context/missingDocuments/missingDocumentsContext';
import { transformKycMultipart } from '../../../Services/softwallService';
import { validationSchema } from './validationSchema';
import {
  updateStep,
  validateIsRequired,
  setIsUpdated,
  setUpdatedRequired,
  persistFormData
} from '../../../actionCreators/onboardingStepsActions';
import { currentOnboardingStepPersistedData } from '../../../selectors';

const useStyles = makeStyles({
  root: { maxWidth: '55.2rem', width: '100%', margin: '0 auto' },
  formInput: { maxWidth: '36rem', margin: '0 auto' },
  buttonContainer: { display: 'flex', justifyContent: 'center' },
  subtitleContainer: { marginTop: '4rem' },
  inputCard: { padding: '4rem 0' },
  bankInput: { width: '100%' }
});

const BankingInfoForm = ({ isFreelancer }) => {
  const classes = useStyles();

  const missingDocumentsContext = useContext(MissingDocumentsContext);
  const { postDocuments } = missingDocumentsContext;

  const persistedFormData = useSelector(currentOnboardingStepPersistedData);

  const [document, setDocument] = useState(
    persistedFormData?.document ? persistedFormData.document : ''
  );
  const [fileName, setFileName] = useState(
    persistedFormData?.fileName ? persistedFormData.fileName : ''
  );
  const [formValues, setFormValues] = useState(
    persistedFormData
      ? persistedFormData
      : {
          bank: '',
          clabe: ''
        }
  );
  const [isValid, setIsValid] = useState(false);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    validateOnMount: true
  });

  const dispatch = useDispatch();

  const banks = useSelector(state => state.speiReducer.bankings.res);
  const onboardingState = useSelector(state => state.onboardingStepsReducer);
  const userInfoState = useSelector(
    state => state.userInfoReducer.user.company_information
  );

  const COMPANY_TYPE_ID = useSelector(
    state => state.userInfoReducer.user.company_type_id
  );

  const postDocument = () => {
    postDocuments([document], COMPANY_TYPE_ID);
  };

  const setValues = (e, name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formik.handleChange(e);
    setUpdatedRequired(dispatch, true);
  };

  const handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    const isNumber = new RegExp('^[0-9]{0,18}$');
    if (name === 'clabe' && value.match(isNumber)) setValues(e, name, value);
    if (name === 'bank') setValues(e, name, value);
  };

  const getBank = prefix => {
    return banks.filter(bank => {
      return bank.prefix === prefix;
    });
  };

  const getPrefix = clabe => {
    return clabe.slice(0, 3);
  };

  useEffect(() => {
    const event = {
      target: {
        value: '',
        name: 'bank'
      }
    };

    if (formValues.clabe.length >= 3) {
      const clabe = getPrefix(formValues.clabe);
      const bank = getBank(clabe);
      event.target.value = bank[0] ? bank[0].name : 'No encontrado';
      handleChange(event);
      return;
    }
    handleChange(event);
    //eslint-disable-next-line
  }, [formValues.clabe]);

  const handleDocumentChange = e => {
    if (e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    setFileName(file.name);
    const fileName = getFileName(file.name, 'caratula');

    let multipartFile = transformKycMultipart(fileName, file, 15);

    setDocument(multipartFile);
    setUpdatedRequired(dispatch, true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!onboardingState.isUpdateRequired) {
      updateStep(dispatch, onboardingState, 'nextStep');
      return;
    }

    postDocument();
    const { clabe, bank } = formValues;
    const form = { bank, clabe: removeBlankSpaces(clabe) };
    createBankInformation(form)
      .then(res => {
        if (res.status === 201) {
          updateStep(dispatch, onboardingState, 'nextStep');
        }
      })
      .catch(err => log.error(err));

    const { currentStep } = onboardingState;
    const valuesToPersist = { bank, clabe, fileName, document };
    persistFormData(dispatch, currentStep, valuesToPersist);
  };

  const handleReturn = () => {
    updateStep(dispatch, onboardingState, 'prevStep');
  };

  useEffect(() => {
    if (formik.isValid && document) {
      setIsValid(true);
      setIsUpdated(dispatch, true);
      return;
    }
    if (onboardingState.currentStep === onboardingState.lastStep) {
      setIsValid(false);
      return;
    }
    if (!onboardingState.isUpdateRequired) {
      setIsValid(true);
      return;
    }
    setIsUpdated(dispatch, false);
    setIsValid(false);
    //eslint-disable-next-line
  }, [formik.isValid, document, onboardingState.isUpdateRequired]);

  useEffect(() => {
    validateIsRequired(dispatch, onboardingState);
    getBankings(dispatch);
    //eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <MainTitle text={`Datos de ${userInfoState.payer_name}`} />

      <Paragraph text="Completa la información para continuar" />

      <div className={classes.subtitleContainer}>
        <Subtitle text="Cuenta Bancaria" />
      </div>

      <Paragraph
        text={`Ingresa los datos de una cuenta bancaria ${
          isFreelancer ? 'en México que utilices' : 'de la compañía'
        }`}
      />

      <form onSubmit={handleSubmit}>
        <div className={classes.formInput}>
          <Input
            label="CLABE"
            value={formValues.clabe}
            name="clabe"
            id="clabe"
            onBlur={formik.handleBlur}
            onChange={handleChange}
            error={
              (formik.errors.clabe && formik.touched.clabe) ||
              formik.errors.bank
            }
            helperText={formik.errors.clabe || formik.errors.bank}
          />
          <TextField
            disabled
            label="Banco"
            name="bank"
            value={formValues.bank}
            className={classes.bankInput}
          />
        </div>
        <Paragraph
          text={`Sube la carátula del estado de la cuenta con CLABE ${formValues.clabe}`}
        />
        <div className={`${classes.formInput} ${classes.inputCard}`}>
          <InputCard
            cardText="Carátula del estado de cuenta bancario"
            tooltip="Máximo dos meses de antigüedad"
            value="statement"
            name="statement"
            onChange={handleDocumentChange}
            isUploaded={document}
            file={fileName}
          />
        </div>
        <div className={classes.buttonContainer}>
          <WhiteButton copy="Regresar" onClick={handleReturn} />
          <PrimaryButton type="submit" text="Continuar" disabled={!isValid} />
        </div>
      </form>
    </div>
  );
};

export default BankingInfoForm;
