import styled, { css } from 'styled-components';

import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

import Icon from '../../../shared/components/Icon/Icon';
import Title from '../../../shared/components/Title';

export const AmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-area: amount;
`;

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0 11px 22px -8px rgba(198, 204, 213, 0.4);
  border: solid 1px ${colors.carbon3};
  background-color: white;
  padding: 3.2rem 3.2rem 3.2rem 2.4rem;
  margin-left: -1rem;

  @media (max-width: 769px) {
    padding: 2.4rem 1.2rem;
  }
`;

export const Concept = styled(Title)`
  font-weight: 500;
  font-size: 1.55rem;
  font-family: Barlow;
  color: ${colors.bodyGs};
  grid-area: concept;
`;

export const Digits = styled(Concept)`
  font-weight: normal;
`;

export const InterestRate = styled(Concept)`
  font-weight: 400;
  color: ${colors.carbon};
  grid-area: rate;
`;

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 2fr;
  margin: 0.8rem 0;
  grid-template-areas: 'concept rate amount';
  @media (max-width: 769px) {
    grid-template-areas:
      'concept amount'
      'rate amount';
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 2fr 1fr;
  }
`;

export const Sign = styled(Digits)`
  padding-right: 1.6rem;
`;

const visibleStyles = css`
  height: 28rem;
`;

const isVisible = ({ isVisible }) => {
  return isVisible ? visibleStyles : '';
};

export const SectionContainer = styled.div`
  height: 0;
  display: grid;
  grid-template-columns: 2fr 12fr;
  justify-items: center;
  margin: 0 auto;
  padding-left: 1rem;
  transition: height 1s;
  overflow: hidden;
  ${isVisible}
`;

export const EntryBalanceContainer = styled.div`
  cursor: pointer;
  .entryDetailsContainer {
    display: flex;
  }
`;

const showDetailsStyles = ({ showDetails }) => {
  return showDetails
    ? `transform: rotate(-90deg); 
    path {
    fill: ${colors.primary};
  }`
    : '';
};

export const CustomIcon = styled(Icon)`
  transform: rotate(90deg);
  margin-left: 1.6rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  ${showDetailsStyles};
`;

export const LinkContainer = styled.div`
  display: flex;
  margin-top: 4rem;
`;

export const Arrow = styled(Icon)`
  margin-left: 0.8rem;

  path {
    fill: ${colors.primary};
  }
`;
