import { setTagOptionsAction } from '../redux/tag-action';
import { TagsCreator } from '../domain/tagData';

const getTagOptionsHandler = ({ dispatch, getTagOptions }) => async ({
  options
}) => {
  const { error, data } = await getTagOptions({ options });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  const tags = TagsCreator({ data });

  dispatch(setTagOptionsAction({ tags }));

  return { isSuccess: true };
};

export { getTagOptionsHandler };
