import { UserLimitsCreator } from '../domain/userLimits';
import { getSpendLimitService } from '../infrastructure/get-spending-limit-service';

const getSpendingLimitHandler = async () => {
  const { data, error } = await getSpendLimitService();
  if (data) return { data: UserLimitsCreator({ data }), error };

  return { data, error };
};

export { getSpendingLimitHandler };
