import { customAxiosInstance } from '../../../Services/axiosConfig';
import log from 'loglevel';
import { PHYSICAL } from '../constants';
import { issueCardPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const issueCardService = async ({
  creditCardId,
  cardType,
  cardLimit,
  creditCardName,
  cardOwnerId,
  password,
  email,
  cardPin,
  deliveryAddress
}) => {
  try {
    const payload = {
      password,
      email,
      user_id: cardOwnerId,
      card_type: cardType,
      spend_limit: cardLimit,
      credit_card_name: creditCardName,
      pin: cardPin,
      credit_card_id: creditCardId
    };

    if (deliveryAddress) {
      const {
        street,
        externalNum,
        internalNum,
        settlement,
        postalCode,
        city,
        stateId,
        building_type,
        reference
      } = deliveryAddress;

      const address = [
        postalCode,
        settlement,
        '',
        stateId,
        city,
        street,
        externalNum,
        internalNum
      ].join('|');

      payload.address = address;
      payload.building_type = building_type;
      payload.reference = reference;
    }

    const {
      data,
      status,
      error,
      internal_error_code
    } = await customAxiosInstance().post(issueCardPath, {
      ...payload
    });

    if (status === 200 || status === 201) {
      return extractContentFromResponse({
        data,
        status,
        error,
        moduleName: 'approve-card-creation-service',
        isSingleElement: true
      });
    }
    log.error('issue-card-service', cardType, status, error, data);

    // TODO is this special error needed here?
    return {
      error: {
        internal_error_code: internal_error_code || `Error code: ${status}`
      }
    };
  } catch (error) {
    log.error('issue-card-service', cardType, error);

    return { error: { message: 'Not able to send request!' } };
  }
};
