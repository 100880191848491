import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosAuxConfig';
import { customerGetPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const customerGetService = async ({ companyId, userId }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      customerGetPath,
      {
        op: 'customer-get',
        data: {
          company_id: companyId,
          user_id: userId
        }
      }
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: '2FA-login-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('2FA-login-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
