import React, { useEffect, useState } from 'react';

import TextField from '../TextField';

import {
  CustomTitle,
  ContentWrapper,
  Text,
  CustomButton,
  ContentButtons
} from './CardActivation.styles';

const Step3 = ({ activateCard, setStep }) => {
  const [isValid, setIsValid] = useState(false);
  const [password, setPassword] = useState('');

  const validateFormat = value => {
    if (value.trim().length === 0) return '';

    return value;
  };

  const setValue = e => {
    let value = e.target.value;
    setPassword(validateFormat(value));
  };

  const updateStep = () => {
    activateCard(password);
  };

  useEffect(() => {
    if (password && password.length >= 8) setIsValid(true);
    else setIsValid(false);
  }, [password]);

  return (
    <ContentWrapper>
      <CustomTitle text="Activar tarjeta" />
      <Text text="Ingresa la contraseña que usas para tu cuenta delt.ai" />
      <TextField
        className="cardActivationPswd"
        type="password"
        onChange={setValue}
        value={password}
        name="cardActivation"
        autocomplete={false}
      />
      <ContentButtons>
        <CustomButton
          isGrey
          text="Regresar"
          size="lg"
          onClick={() => setStep(2)}
        />

        <CustomButton
          text="Continuar"
          size="lg"
          onClick={updateStep}
          disabled={!isValid}
        />
      </ContentButtons>
    </ContentWrapper>
  );
};

export default Step3;
