import React, { useRef, useCallback } from 'react';

import Webcam from 'react-webcam';

import {
  Container,
  StyledButton as Button,
  VideoContainer
} from './Selfie.styles.js';

const videoConstraints = {
  width: 480,
  height: 480,
  facingMode: 'user'
};

const Selfie = ({ onChange, imageSrc = null, closeCamera }) => {
  const webcamRef = useRef(null);
  const takePhoto = useCallback(() => {
    let image = webcamRef.current.getScreenshot();
    if (onChange) onChange(image);
    // eslint-disable-next-line
  }, [webcamRef]);

  const handleClick = () => {
    return imageSrc ? resetImage() : takePhoto();
  };

  const resetImage = () => {
    onChange(null);
  };

  return (
    <Container>
      <VideoContainer>
        {closeCamera ? null : imageSrc ? (
          <img src={imageSrc} alt="selfie" />
        ) : (
          <Webcam
            audio={false}
            height={150}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={150}
            videoConstraints={videoConstraints}
            screenshotQuality={1}
            mirrored={false}
          />
        )}
      </VideoContainer>
      <Button
        text={imageSrc ? 'Reintentar' : 'Tomar selfie'}
        variant="outlined"
        onClick={handleClick}
      />
    </Container>
  );
};

export default Selfie;
