import { combineReducers } from 'redux';

import apidocsReducer from '../micro-frontends/api-docs/redux/apidocs-reducer';
import apiStatusReducer from './apiStatusReducer';
import companyReducer from './companyReducer';
import crudReducer from '../micro-frontends/users-crud/redux/crud-reducer';
import failedBatchesReducer from './failedBatchesReducer';
import loadingReducer from './loadingReducer';
import onboardingStepsReducer from './onboardingStepsReducer';
import rolePermissionsReducer from '../role-based-access/redux/role-permissions-reducer';
import speiReducer from './speiReducer';
import softwallReducer from './softwallReducer';
import statementsReducer from './statementsReducer';
import transactionReducer from './transactionReducer';
import invoicesReducer from './invoicesReducer';
import userInfoReducer from './userInfoReducer';
import usersReducer from './usersReducer';
import viewReducer from './viewReducer';
import cardsReducer from '../micro-frontends/cards/redux/cards-reducer';
import onboardingReducer from '../micro-frontends/onboarding/redux/onboardingReducer';
import tagsReducer from '../micro-frontends/tags/redux/tag-reducer';

export default combineReducers({
  apidocsReducer,
  apiStatusReducer,
  crudReducer,
  companyReducer,
  failedBatchesReducer,
  loadingReducer,
  onboardingStepsReducer,
  softwallReducer,
  speiReducer,
  statementsReducer,
  rolePermissionsReducer,
  transactionReducer,
  invoicesReducer,
  userInfoReducer,
  usersReducer,
  viewReducer,
  cardsReducer,
  onboardingReducer,
  tagsReducer
});
