// React
import React from 'react';
import PropTypes from 'prop-types';

// Shared Components
import Icon from '../Icon/Icon';
import Label from '../Label/Label';

// ToolTip Styles
import {
  Container,
  HelperContainer,
  HelperIcon,
  HelperText
} from './Tooltip.styles';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';

// Helpers
import useViewportWidth from '../../../../hooks/useViewportWidth';

const Component = ({ label, text, className, IconColor, classContainer }) => {
  const [open, setOpen] = React.useState(false);
  const viewportWidth = useViewportWidth();
  const isMobile = viewportWidth < 769;

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const ToolTipContent = () => (
    <HelperContainer data-test="helper" className={className}>
      <HelperIcon>
        <Icon icon="question" />
      </HelperIcon>
      <HelperText>
        <Label text={label} className="tooltipLabel" />
        <div>{text}</div>
      </HelperText>
    </HelperContainer>
  );

  return (
    <>
      <Container className={classContainer} data-testid="tooltipComponent">
        {isMobile && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              title={<ToolTipContent />}
              PopperProps={{
                disablePortal: true
              }}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={handleTooltipClose}
            >
              <div onClick={handleTooltipOpen}>
                <Icon icon="question" color={IconColor} />
              </div>
            </Tooltip>
          </ClickAwayListener>
        )}
        {!isMobile && (
          <Tooltip
            title={<ToolTipContent />}
            PopperProps={{
              disablePortal: true
            }}
          >
            <div>
              <Icon icon="question" color={IconColor} />
            </div>
          </Tooltip>
        )}
      </Container>
    </>
  );
};

Component.propTypes = {
  IconColor: PropTypes.string,
  classContainer: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

Component.defaultProps = {
  className: '',
  IconColor: '',
  classContainer: ''
};

export default Component;
