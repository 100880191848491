import React from 'react';

// Auxiliary libraries
import { useFormik } from 'formik';

// Components
import OnboardingErrorBoundary from '../OnboardingErrorBoundary/OnboardingErrorBoundary';
import OnboardingLayout from '../../components/OnboardingLayout/OnboardingLayout';
import Select from '../../../shared/components/EditableSelect';
import TextField from '../../../shared/components/TextField';

// Others
import mexicanStates from '../../../../catalogs/catalog_mexican_states';
import { validationSchema } from './validationSchema';
import setInputProps from '../../helpers/set-input-props';

const Component = ({
  steps,
  activeStep,
  goBack,
  goToNextStep,
  userName,
  storedData
}) => {
  const initialValues = storedData || {
    zipCode: '',
    street: '',
    externalNum: '',
    municipality: '',
    settlement: '',
    city: '',
    state: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema
  });

  const handleNextStep = () => {
    goToNextStep(formik.values);
  };

  return (
    <OnboardingLayout
      title={`Domicilio fiscal de ${userName}`}
      description="Complete la información para continuar."
      onPrimaryButtonClick={handleNextStep}
      primaryButtonText="Continuar"
      secondaryButtonText="Regresar"
      isPrimaryButtonDisabled={!formik.isValid || !formik.dirty}
      isModalVisible
      onSecondaryButtonClick={goBack}
      steps={steps}
      activeStep={activeStep}
    >
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Código postal',
          'zipCode',
          'text'
        )}
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Calle',
          'street',
          'text'
        )}
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Número exterior',
          'externalNum',
          'text'
        )}
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Número interior',
          'internalNum',
          'text'
        )}
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Colonia',
          'settlement',
          'text'
        )}
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Municipio o delegación',
          'municipality',
          'text'
        )}
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Ciudad',
          'city',
          'text'
        )}
      />

      <Select
        {...setInputProps(
          formik,
          stateId => {
            formik.setFieldValue('state', stateId);
          },
          'Estado',
          'state',
          'text'
        )}
        options={mexicanStates.states.map(({ name }) => ({
          label: name,
          value: name
        }))}
        value={formik.values['state']}
      />
    </OnboardingLayout>
  );
};

const AddressPage = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="AddressPage"
      props={props}
    />
  );
};

export default AddressPage;
