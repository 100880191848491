// React
import React, { Fragment, useEffect, useState } from 'react';

// Auxiliary libraries
import { createCompanyAddress } from '../../../Services/Company';
import { getSpacing } from '../../../helpers';
import { Grid, TextField, withWidth } from '@material-ui/core/';
import log from 'loglevel';
import statesCatalog from '../../../catalogs/catalog_mexican_states.json';
import { useStyles } from './FormStyles';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { PrimaryButton, WhiteButton } from '../../Shared';
import {
  setUpdatedRequired,
  setIsUpdated,
  updateStep,
  validateIsRequired,
  persistFormData
} from '../../../actionCreators/onboardingStepsActions';

const Form = props => {
  const { width } = props;

  const {
    values,
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    setTouched
  } = props;

  const {
    postal_code,
    settlement,
    municipality,
    state,
    city,
    street,
    external_num,
    internal_num
  } = values;

  // Redux
  const dispatch = useDispatch();
  const onboardingState = useSelector(state => state.onboardingStepsReducer);

  // Hooks
  const [address, setAddress] = useState(
    values
      ? values
      : {
          postal_code: '',
          settlement: '',
          municipality: '',
          state: '',
          city: '',
          street: '',
          external_num: ' ',
          internal_num: ''
        }
  );

  // Styles
  const classes = useStyles();

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
    setData(e);
    setUpdatedRequired(dispatch, true);
  };

  const setData = e => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value
    });
  };

  const handleReturn = () => {
    updateStep(dispatch, onboardingState, 'prevStep');
  };

  const postData = () => {
    let doc = {
      address: `${address.postal_code}|${address.settlement}|${address.municipality}|${address.state}|${address.city}|${address.street}|${address.external_num}|${address.internal_num}`
    };
    createCompanyAddress(doc)
      .then(res => {
        if (res.status === 201) {
          updateStep(dispatch, onboardingState, 'nextStep');
        }
      })
      .catch(e => log.error(e));
  };

  const setTouchFields = () => {
    setTouched({
      postal_code: true,
      street: true,
      external_num: true,
      internal_num: true,
      settlement: true,
      municipality: true,
      city: true,
      state: true
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!onboardingState.isUpdateRequired) {
      updateStep(dispatch, onboardingState, 'nextStep');
      return;
    }

    if (!isValid) {
      setTouchFields();
      return;
    }

    const { currentStep } = onboardingState;
    persistFormData(dispatch, currentStep, values);
    postData();
  };

  useEffect(() => {
    if (isValid) {
      setIsUpdated(dispatch, true);
    } else {
      setIsUpdated(dispatch, false);
    }
    //eslint-disable-next-line
  }, [isValid, onboardingState.isUpdateRequired]);

  useEffect(() => {
    validateIsRequired(dispatch, onboardingState);
    //eslint-disable-next-line
  }, []);

  //TO DO: Refactor inputs with formik properties
  return (
    <Fragment>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={getSpacing(width)}>
          <Grid item xs={12} md>
            <TextField
              label="Código postal"
              className={classes.textField}
              id="postal_code"
              name="postal_code"
              onChange={change.bind(null, 'postal_code')}
              margin="normal"
              helperText={touched.postal_code ? errors.postal_code : ''}
              error={touched.postal_code && Boolean(errors.postal_code)}
              value={postal_code}
            />
          </Grid>
          <Grid item xs={12} md>
            <TextField
              label="Calle"
              className={classes.textField}
              id="street"
              name="street"
              onChange={change.bind(null, 'street')}
              margin="normal"
              helperText={touched.street ? errors.street : ''}
              error={touched.street && Boolean(errors.street)}
              value={street}
            />
          </Grid>
        </Grid>
        <Grid container spacing={getSpacing(width)}>
          <Grid item xs={12} md>
            <TextField
              label="Número exterior"
              id="external_num"
              name="external_num"
              onChange={change.bind(null, 'external_num')}
              helperText={touched.external_num ? errors.external_num : ''}
              error={touched.external_num && Boolean(errors.external_num)}
              value={external_num}
              className={classes.textField}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md>
            <TextField
              label="Número interior"
              id="internal_num"
              name="internal_num"
              onChange={change.bind(null, 'internal_num')}
              helperText={touched.internal_num ? errors.internal_num : ''}
              error={touched.internal_num && Boolean(errors.internal_num)}
              value={internal_num}
              className={classes.textField}
              margin="normal"
            />
          </Grid>
        </Grid>
        <TextField
          label="Colonia"
          className={classes.textField}
          id="settlement"
          name="settlement"
          onChange={change.bind(null, 'settlement')}
          margin="normal"
          helperText={touched.settlement ? errors.settlement : ''}
          error={touched.settlement && Boolean(errors.settlement)}
          value={settlement}
        />
        <TextField
          label="Municipio o Delegación"
          className={classes.textField}
          id="municipality"
          name="municipality"
          onChange={change.bind(null, 'municipality')}
          helperText={touched.municipality ? errors.municipality : ''}
          margin="normal"
          error={touched.municipality && Boolean(errors.municipality)}
          value={municipality}
        />
        <Grid container spacing={getSpacing(width)}>
          <Grid item xs={12} md>
            <TextField
              label="Ciudad"
              className={classes.textField}
              id="city"
              name="city"
              onChange={change.bind(null, 'city')}
              margin="normal"
              helperText={touched.city ? errors.city : ''}
              error={touched.city && Boolean(errors.city)}
              value={city}
            />
          </Grid>
          <Grid item xs={12} md>
            <TextField
              select
              label="Estado"
              id="state"
              name="state"
              onChange={change.bind(null, 'state')}
              className={classes.textField}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              helperText={touched.state ? errors.state : ''}
              error={touched.state && Boolean(errors.state)}
              value={state}
            >
              <option value=" ">Selecciona un estado</option>
              {statesCatalog.states.map(option => (
                <option key={option.state_id} value={option.state_id}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <div className={classes.button}>
          <WhiteButton copy="Regresar" onClick={handleReturn} />
          <PrimaryButton type="submit" text="Continuar" />
        </div>
      </form>
    </Fragment>
  );
};

export default withWidth()(Form);
