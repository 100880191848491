import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  overflow: hidden;
`;
export const Buttons = styled.div`
  float: right;

  & a:not(:first-child) {
    margin-left: 20px;
  }
`;
