import React from 'react';

import PropTypes from 'prop-types';
import { ToggleButton, Dot } from './styles';

const Toggle = ({ checked, onClick }) => {
  return (
    <ToggleButton
      data-testid="toggle"
      checked={checked}
      onClick={onClick}
      type="button"
    >
      <Dot checked={checked} />
    </ToggleButton>
  );
};

Toggle.propTypes = { onClick: PropTypes.func, checked: PropTypes.bool };

export default Toggle;
