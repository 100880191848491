import styled from 'styled-components';

import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

import Modal from '../../../shared/components/Modal';
import Title from '../../../shared/components/Title';
import Tag from '../../../shared/components/Tag';
import Button from '../../../shared/components/Button';

export const StyledModal = styled(Modal)`
  width: 787px;
  max-width: 787px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: scroll;

  .flexContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.4rem;
  }

  .formContainer {
    width: 60%;
    height: 100%;
    padding: 32px 24px 24px 24px;
  }

  .formTitle {
    font-size: 24px;
    color: #939ca9;
  }

  .cardTypeInfoContainer {
    width: 40%;
    height: 100%;
    background-color: #f4fbff;
    padding: 50px 18px;
  }

  .cardTypeInfoParagraph {
    width: 100%;
  }

  .spendingLimitsContainer {
    margin-top: 20px;
  }

  .moblieContainer {
    display: none;
  }

  .optionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 16px 0;
  }

  .radioButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 10px 0 10px 0;
  }

  .radioOptionsContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .radioButtonsLabel {
    font-size: 17px;
  }

  .formButton {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
  }

  .tagsContainer {
    display: flex;
    flex: 1;
    justify-content: space-evenly;

    &.stretched {
      justify-content: flex-end;
      padding-right: 1em;
    }
  }

  .tooltip {
    top: -3rem;
    right: -2rem;
    left: auto;
    z-index: 10;
    width: 30rem;

    a {
      color: inherit;
    }
  }

  .tagActive {
    background: #1790cf;
    color: #fff;
  }

  .deleteContainer {
    margin: 2rem 0 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 1.3rem;
    }
  }

  .button-spacing {
    height: 7.16rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    overflow: auto;

    form {
      width: 100%;
    }

    .formContainer {
      width: 100%;
    }

    .flexContainer {
      display: block;
    }

    .cardTypeInfoContainer {
      display: none;
    }

    .moblieContainer {
      display: block;
    }

    .radioButtonsContainer {
      flex-direction: column;
      align-items: flex-start;
    }

    .radioButtonsLabel {
      width: fit-content;
    }
  }

  @media (max-width: 580px) {
    .optionsContainer {
      flex-direction: column;
      align-items: flex-start;

      label {
        margin-bottom: 1.8rem;
      }
    }
  }
`;

export const StyledTitle = styled(Title)`
  color: ${colors.carbon};
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  font-size: 1.55rem;
  line-height: 20px;
  letter-spacing: 0.218182px;
  color: ${colors.body};
`;

export const StyledTag = styled(Tag)`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 15.5px;
  background-color: rgba(23, 144, 207, 0.05);
  letter-spacing: 0.15px;
  ${fonts.quickSandMedium};
  font-size: 1.2rem;
  height: 3rem;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 40px;
`;

export const DeleteBtn = styled.span`
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.218182px;
  text-decoration-line: underline;
  color: ${colors.deltai};
  cursor: pointer;
`;

export const Subtitle = styled.label`
  ${fonts.barlowRegular};
  font-size: 1.4rem;
  line-height: 19, 38px;
  letter-spacing: 0.22px;
  color: #000000;
  text-align: center;
  margin-bottom: 1.4rem;
`;

export const Header = styled.label`
  ${fonts.quickSandSemiBold};
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.22px;
  color: #000000;
  text-align: center;
  margin-bottom: 1.4rem;
`;

export const SubtleButton = styled.button`
  ${fonts.quickSandSemiBold}
  font-size: 1.7rem;
  color: ${colors.carbon1};
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  &:hover {
    color: ${colors.carbon};
  }
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
