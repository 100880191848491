export const colors = {
  body: '#1c2633',
  bodyGs: '#465c78',
  body3: '#374b61',
  blue2faced: '#2faced',
  carbon: '#939ca9',
  carbon1: '#afb6c2',
  carbon2: '#c6ccd5',
  carbon3: '#ebeef2',
  carbon4: '#f7f8fa',
  carbon5: '#FBFBFB',
  completed: '#168fce',
  gray1: '#eeeeee',
  deltai: '#ed2246',
  deltai2: '#f7477c',
  detailsBlack: '#1b232d',
  pallete0Start: '#f5f7f9',
  pallete0End: '#e7ebf0',
  pallet0Text: '#9aa3af',
  palette1: '#e7e2f3',
  palette100: '#cac1e3',
  palette1Text: '#6a647b',
  primary: '#1790cf',
  primaryGs: '#33c3e9',
  primary4: '#e3f2fb',
  sense1: '#be33f4',
  subtle: '#9aa3af',
  subtleShade: '#f7f8fa',
  success: '#45bd62',
  success4: '#ecf8ef',
  success4Transaparent: 'rgba(236, 248, 239, 0.3)',
  warning: '#ed6b22',
  white: '#fff',
  almostBlack: '#1C2633',
  blueBackground: '#1790cf'
};

export const gradients = {
  deltaiGradient: `linear-gradient(
    79deg,
    ${colors.deltai2} 0%,
    ${colors.deltai}
  )`,
  palette0Gradient: `linear-gradient(69deg, ${colors.pallete0Start} 17%, ${colors.pallete0End} 88%);`,
  successGradient: `linear-gradient(70deg, #7be09b, ${colors.success} 100%);`,
  NavBarBackgroundGradient: `linear-gradient(270deg, #F5F7F9 0%, rgba(231, 235, 240, 0) 96.5%);`,
  DeltaiCardBackgroundGradiant:
    'linear-gradient(20.64deg, #000000 -0.02%, #585858 99.99%)'
};

export const fonts = {
  barlowRegular: `
  font-family: Barlow;
  font-weight: 400;
    `,
  barlowMedium: `
    font-family: Barlow;
    font-weight: 500;
  `,
  barlowSemiBold: `
  font-family: Barlow;
  font-weight: 600;
`,
  quickSandMedium: `
  font-family: Quicksand;
  font-weight: 500;
`,
  quickSandSemiBold: `
  font-family: Quicksand;
  font-weight: 600;
`,
  quicksandBold: `
  font-family: Quicksand;
  font-weight: 700;
  `
};

export const avatarColors = {
  0: {
    linearGradient: 'linear-gradient(44deg, #e7e2f3 15%, #cac1e3 86%)',
    fontColor: '#6a647b'
  },
  1: {
    linearGradient: 'linear-gradient(45deg, #c1f6eb 15%, #8ee9d2 85%)',
    fontColor: '#5a7871'
  },
  2: {
    linearGradient: 'linear-gradient(45deg, #d8faff 15%, #a5e7f6 85%)',
    fontColor: '#548792'
  },

  3: {
    linearGradient: 'linear-gradient(45deg, #f3f9e7 15%, #e2f0cb 85%)',
    fontColor: '#6e785c'
  },
  4: {
    linearGradient: 'linear-gradient(45deg, #ffdac1 15%, #ffefe2 85%)',
    fontColor: '#8d6d58'
  },
  5: {
    linearGradient: 'linear-gradient(45deg, #ffdcd9 15%, #ffb7b2 85%)',
    fontColor: '#89625f'
  },
  6: {
    linearGradient: 'linear-gradient(44deg, #f5f7f9 16%, #e7ebf0 87%)',
    fontColor: '#9aa3af'
  },
  7: {
    linearGradient: `linear-gradient(45deg, ${colors.deltai2} 16%, ${colors.deltai} 87%)`,
    fontColor: `${colors.white}`
  },
  8: {
    linearGradient:
      'linear-gradient(45deg, rgba(69, 189, 98, 0.2),  rgba(69, 189, 98, 0.2) )',
    fontColor: '#45BD62'
  }
};
