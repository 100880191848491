import React from 'react';

import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

const EmailSent = () => {
  return (
    <RegistrationPageLayout
      title="Revisa tu correo"
      description="Enviamos una liga para que restablezcas tu contraseña"
      isPrimaryButtonHidden={true}
      isModalVisible={true}
      showLogout={true}
    />
  );
};

export default EmailSent;
