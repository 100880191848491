export const reportLostCardHandler = ({ changeCardStatus }) => async ({
  cardId,
  userId
}) => {
  const { error } = await changeCardStatus({
    cardId,
    userId,
    cardStatusId: 4
  });

  if (error) {
    return { isSuccess: false, error };
  }
  // const card = CardCreator({ data: cardData });

  // dispatch(doCardNaiveUpdate({ card }));
  return { isSuccess: true };
};
