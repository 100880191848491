import styled from 'styled-components';

export const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(28, 38, 51, 0.33);
  z-index: ${props => (props.zIndex ? props.zIndex : '999')};
  width: 100vw;
  height: 100vh;
  padding: 1.8rem;

  .crossSign {
    position: absolute;
    top: 2rem;
    right: 3rem;
    color: white;
    font-size: 2rem;
    cursor: pointer;

    svg {
      width: 2rem;
      height: 2rem;

      path {
        fill: white;
      }
    }
  }

  .overlayArea {
    overflow: auto;
    scrollbar-width: none;
  }

  @media (max-width: 768px) {
    padding: 0;

    .overlayArea {
      height: 100%;
      width: 100%;
    }
  }
`;
