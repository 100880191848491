/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { AmountContainer, DecimalsContainer } from './Amount.styles';

const Amount = ({ color, integer, decimals, className }) => {
  return (
    <>
      {integer ? (
        <AmountContainer
          data-test="amountContainer"
          color={color}
          className={className}
        >
          {integer}
          <DecimalsContainer data-test="decimalsContainer">
            {decimals}
          </DecimalsContainer>
        </AmountContainer>
      ) : null}
    </>
  );
};

Amount.propTypes = {
  color: PropTypes.string,
  integer: PropTypes.string.isRequired,
  decimals: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default memo(Amount);
