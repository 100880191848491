const createLegalPersonHandler = ({
  createLegalPerson,
  updateLegalPerson
}) => async data => {
  if (data.complementaryData.isValid) {
    const { error } = await createLegalPerson(data.complementaryData.values);
    return error ? { isSuccess: false, error } : { isSuccess: true };
  }

  const { error } = await updateLegalPerson(data.attorneyForm);

  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true };
};

export { createLegalPersonHandler };
