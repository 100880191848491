/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import BatchContent from './BatchContent';
import Button from '../../../shared/components/Button';
import MultipartFileInput from '../../../shared/components/MultipartFileInput';
import LabelInfo from '../../../shared/components/LabelInfo';

import {
  ButtonContainer,
  DetailsContainer,
  Subtitle
} from './BatchTransfer.styles';

const SelectFile = ({
  downloadTemplate,
  onChange,
  template,
  submit,
  isValid,
  failedBatches,
  showFailedBatches
}) => {
  return (
    <BatchContent
      title="Transferencia Múltiple"
      subtitle={
        <>
          <Subtitle>
            Sube un archivo
            <Subtitle color="info">{` ${'.xls, .xlsx'} `} </Subtitle>o
            <Subtitle color="info"> {` ${'.csv'} `}</Subtitle>
            con la lista de <br></br> transferencias a realizar.
          </Subtitle>
        </>
      }
      content={
        <>
          <DetailsContainer>
            <MultipartFileInput
              type="file"
              label="Adjuntar archivo"
              onChange={onChange}
              accept=".csv, .xls, .xlsx"
            />
            {template ? (
              <LabelInfo
                data-test="LabelInfo"
                text="Descarga nuestra plantilla para crear tu orden"
                className="link"
                onClick={downloadTemplate}
              />
            ) : null}
            {failedBatches ? (
              <LabelInfo
                data-test="showFailedTransfersLabel"
                text="Mostrar transferencias fallidas"
                className="link"
                onClick={showFailedBatches}
              />
            ) : null}
            <ButtonContainer>
              <Button
                data-test="Button"
                text="Continuar"
                onClick={() => submit(2)}
                disabled={!isValid}
              />
            </ButtonContainer>
          </DetailsContainer>
        </>
      }
    />
  );
};

SelectFile.propTypes = {
  isValid: PropTypes.bool,
  downloadTemplate: PropTypes.func,
  onChange: PropTypes.func,
  template: PropTypes.any,
  submit: PropTypes.func,
  failedBatches: PropTypes.number,
  showFailedBatches: PropTypes.func
};

export default SelectFile;
