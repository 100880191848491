const CreateLegalPerson = ({
  name,
  surname,
  surname2,
  associationTypeId,
  title,
  percentage
}) =>
  Object.freeze({
    name,
    surname_1: surname,
    surname_2: surname2,
    company_association_type_id: Number(associationTypeId),
    ...(percentage && { controlling_stake: percentage }),
    ...(title && { occupation_job: title })
  });

export default CreateLegalPerson;
