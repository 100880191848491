import { getFormProcessHandler } from './eventHandlers/form-process-handler';
import { getComponentHandler } from './eventHandlers/get-component-handler';
import { getSelectorHandler } from './eventHandlers/selector-handler';
import freelancerActionsManager from './freelancer-actions-manager';

const service = state => ({
  getFormProcess: getFormProcessHandler({
    getFormProcess: freelancerActionsManager[state].service
  }),
  getPath: freelancerActionsManager[state].path,
  getSelector: getSelectorHandler({
    getSelector: freelancerActionsManager[state].selector
  }),
  getComponent: getComponentHandler({
    getComponent: freelancerActionsManager[state].component
  })
});

export { service };
