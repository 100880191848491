import React from 'react';

const CardSvg = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <defs>
        <filter id="3ddobxzjna">
          <feColorMatrix in="SourceGraphic" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g filter="url(#3ddobxzjna)" transform="translate(-11 -11)">
          <g fill="#1C2633">
            <path
              d="M0 3H20V5.88H0z"
              opacity=".85"
              transform="rotate(-45 37.52 -1.4)"
            />
            <path
              fillRule="nonzero"
              d="M17.56 0H2.44C1.092 0 0 1.092 0 2.44v9.12C0 12.908 1.092 14 2.44 14h15.12c1.348 0 2.44-1.092 2.44-2.44V2.44C20 1.092 18.908 0 17.56 0zm0 1.44c.552 0 1 .448 1 1v9.12c0 .552-.448 1-1 1H2.44c-.552 0-1-.448-1-1V2.44c0-.552.448-1 1-1h15.12z"
              transform="rotate(-45 37.52 -1.4)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CardSvg;
