const authUserHandler = ({ authUser }) => async ({ email, password }) => {
  const { error } = await authUser({
    email,
    password
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  return { isSuccess: true };
};

export { authUserHandler };
