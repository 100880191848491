const setFreelancerInfoHandler = ({ setFreelancerBasicInfo }) => async data => {
  const { error } = await setFreelancerBasicInfo(data);

  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true };
};

export { setFreelancerInfoHandler };
