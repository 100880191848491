import styled from 'styled-components';

import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const CustomLink = styled.a`
  display: flex;
  align-items: center;
  ${fonts.quickSandSemiBold}
  font-size: 1.2rem;
  color: ${colors.primary};
  text-decoration: none;
`;
