import React from 'react';

// Shared Components
import CircularCheckbox from '../../../shared/components/CircularCheckbox/CircularCheckbox';

// Custom Components
import { LegalLinks } from './styles';

const LegalTerms = ({ onChange, ...otherProps }) => {
  return (
    <CircularCheckbox
      text={
        <>
          Acepto el{' '}
          <LegalLinks
            href={process.env.REACT_APP_PRIVACY_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Aviso de privacidad
          </LegalLinks>{' '}
          y los{' '}
          <LegalLinks
            href={process.env.REACT_APP_TERMS_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Términos y condiciones
          </LegalLinks>
        </>
      }
      {...otherProps}
      onChange={onChange}
    />
  );
};

export default LegalTerms;
