import { log } from 'loglevel';
import { SET_DATES } from '../../../reducers/types';

const getDatesHandler = ({ getBillingCycles, dispatch }) => async () => {
  const { error, data: dates } = await getBillingCycles();

  if (error) {
    log.error(error);
    dispatch({ type: SET_DATES, payload: [] });
    return;
  }

  dispatch({ type: SET_DATES, payload: dates[0] });
};

export { getDatesHandler };
