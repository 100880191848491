import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const HeaderTypes = ({ type }) => {
  return type === 'deposit'
    ? 'linear-gradient(86.66deg, #45BD62 1.95%, #4ADA6C 100%)'
    : 'linear-gradient(86.66deg, #1C2633 1.95%, #3C4D62 100%)';
};

export const VoucherContainer = styled.div`
  width: 100%;
  padding-bottom: ${({ isHighQuality }) => (isHighQuality ? '2rem' : '')};
  display: flex;
  flex-direction: column;
  align-content: center;
  height: auto;
  max-height: 98vh;
  position: relative;

  .xml {
    color: ${colors.success};
    path {
      fill: ${colors.success};
    }
  }
  .pdf {
    color: ${colors.sense1};
    path {
      fill: ${colors.sense1};
    }
  }

  .paid {
    color: ${colors.primary};
    path {
      fill: ${colors.primary};
    }
  }

  @media (min-width: 769px) {
    width: 65.9rem;
  }

  @media print {
    button {
      display: none;
    }
  }
`;

export const Logo = styled.img`
  padding-top: 2.1rem;
  width: 10.2rem;
  height: 5.2rem;
`;

export const BodyContainer = styled.div`
  margin: 0 3rem 3rem;
  overflow-y: scroll;
  padding-bottom: ${({ pb }) => pb || ''};
`;

export const IconContainer = styled.span`
  cursor: pointer;
  height: 3.2rem;
  line-height: 2;
`;

export const IconText = styled.span`
  font-size: 1.35rem;
  font-family: ${fonts.barlowSemiBold};
  margin-left: 0.8rem;
  line-height: 1.35rem;
  letter-spacing: 0.12px;
`;
