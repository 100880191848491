import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../redux/onboardingSelectors';

import MissingDocuments from '../missing-documents/MissingDocuments';
import RejectedDocuments from '../rejected-documents/RejectedDocuments';
import SuccessPage from '../success-page/SuccessPage';

import { service } from '../../service';
import { ONBOARDING_COMPLETE, rejectedStatusId } from '../../constants';
import { updateOnboardingProgressService } from '../../../service/infrastructure/updateOnboardingProgress';

const Softwall = () => {
  const user = useSelector(selectUser);
  const type = user.company_type_id === 2 ? 'person' : 'company';

  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [missingDocuments, setMissingDocuments] = useState([]);
  const [rejectedDocuments, setRejectedDocuments] = useState([]);

  const getDocuments = async () => {
    const missingDocs = await service().getMissingDocuments(type);
    const rejectedDocs = await service().getRejectedDocuments(type);
    const rejectedDocsValidated = validateRejectedDocuments(rejectedDocs.data);
    setMissingDocuments(missingDocs.data);
    setRejectedDocuments(rejectedDocsValidated);
    setUploadedDocuments([]);
    updateToOnboardingComplete(missingDocs.data, rejectedDocsValidated);
  };

  const updateToOnboardingComplete = async (missingDocs, rejectedDocs) => {
    if (!missingDocs?.length && !rejectedDocs?.length)
      await updateOnboardingProgressService({ newStatus: ONBOARDING_COMPLETE });
  };

  const handleFileChange = e => {
    if (Object.keys(e).length > 0)
      setUploadedDocuments([...uploadedDocuments, e]);
  };

  const uploadDocuments = () => {
    service()
      .fileUploadManager({ uploadedDocuments, type: user.company_type_id })
      .then(() => {
        getDocuments();
      });
  };

  const filterRejectedDocuments = documents => {
    return documents.filter(el => {
      return el.kyc_document_status_id === rejectedStatusId;
    });
  };

  const validateRejectedDocuments = rejectedDocuments => {
    if (!rejectedDocuments) return;

    if (!rejectedDocuments.person?.length && !rejectedDocuments.company?.length)
      return [];

    const documents = [
      ...(rejectedDocuments.person || []),
      ...(rejectedDocuments.company || [])
    ];

    return filterRejectedDocuments(documents);
  };

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {missingDocuments?.length > 0 ? (
        <MissingDocuments
          companyTypeId={type}
          handleFileChange={handleFileChange}
          documents={missingDocuments}
          uploadFiles={uploadDocuments}
          uploadedDocuments={uploadedDocuments}
          isPrimaryButtonDisabled={!uploadedDocuments.length > 0}
        />
      ) : rejectedDocuments?.length > 0 ? (
        <RejectedDocuments
          companyTypeId={type}
          handleFileChange={handleFileChange}
          documents={rejectedDocuments}
          uploadFiles={uploadDocuments}
          uploadedDocuments={uploadedDocuments}
          isPrimaryButtonDisabled={!uploadedDocuments.length > 0}
        />
      ) : (
        <SuccessPage />
      )}
    </>
  );
};

export default Softwall;
