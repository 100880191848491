const basePath = '/api/dashboard';
export const addressPath = `${basePath}/person/address`;
export const freelancerBasicInfo = `${basePath}/person`;
export const bankInformationPath = `${basePath}/bankinformation`;
export const bankingInstitutionsPath = `${basePath}/spei/banking-institution`;
export const personDocumentsPath = `${basePath}/person/kyc`;
export const companyDocumentsPath = `${basePath}/company/kyc`;
export const enterpriseBasicInfo = `${basePath}/company`;
export const missingDocuments = type => `${basePath}/${type}/kyc`;
export const rejectedDocuments = (type = ':type') =>
  `${basePath}/${type}/kyc-validated-files`;
export const contactCommunicationCompanyPath = `${basePath}/company/contact-communication`;
export const contactCommunicationPersonPath = `${basePath}/person/communication_contact`;
