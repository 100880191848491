/* eslint-disable no-confusing-arrow */
const addSeparatorEveryFourthLetter = (result, number, i) =>
  i % 4 === 3 ? `${result}${number} ` : `${result}${number}`;

export const CardCreator = ({
  data: {
    credit_card_id,
    card_status_id,
    credit_card_name,
    is_virtual,
    last_four_digits,
    spend_limit,
    card_number,
    cvv,
    expiry_date,
    owner_name,
    owner_spending_limit,
    created_by,
    user_id,
    request_status_description,
    card_admin_request_id,
    remaining_spend_limit,
    is_pin_set
  }
}) =>
  Object.freeze({
    creditCardId: credit_card_id,
    cardAdminRequestId: card_admin_request_id,
    creditCardName: credit_card_name || '',
    isVirtual: is_virtual,
    lastFourDigits: last_four_digits,
    spendLimit: spend_limit,
    isActive: card_status_id === 1,
    isInactive: card_status_id === 2,
    isBlocked: card_status_id === 3,
    isRetired: card_status_id === 4,
    isCardPinSet: is_pin_set === 1,
    cardStatusId: card_status_id,
    cardExpiry: expiry_date,
    securityCode: cvv,
    cardNumber: card_number
      ?.split('')
      .reduce(addSeparatorEveryFourthLetter, ''),
    ownerName: owner_name,
    ownerSpendLimit: owner_spending_limit || '0',
    createdById: created_by,
    userId: user_id,
    requestStatusDescription: request_status_description,
    remainingSpendLimit: remaining_spend_limit
  });

export const CardsCreator = ({ data: cardsData }) =>
  cardsData.map(cardData => CardCreator({ data: cardData }));
