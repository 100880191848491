import styled from 'styled-components';

import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const EmptyState = styled.div`
  font-size: 1.55rem;
  color: ${colors.body3};
  padding-top: 8rem;
  text-align: center;

  .bold {
    font-weight: 500;
  }
`;

export const Icon = styled.span`
  width: 2.3rem;
  height: 2.3rem;
  padding: 0 1.15rem;
  border-radius: 6px;
  margin: 1.4rem;
  background-color: ${colors.carbon4};
`;
