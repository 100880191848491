// React imports
import React, { useState, useEffect } from 'react';

// Auxiliary librarys imports
import { Box } from '@material-ui/core';
import { captureEvent, Severity } from '@sentry/browser';
import { ErrorBoundary } from 'react-error-boundary';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

// Own Components
import IneModal from '../IneModal';
import { MainTitle, PrimaryButton, WhiteButton } from '../Shared';
import Success from '../../Views/Success/Success';
import { startLoading, stopLoading } from '../../actionCreators/loadingActions';
import {
  getCompanyMissingDocs,
  manageModalDocs,
  updateDocuments,
  getPersonMissingDocs,
  postDocuments,
  getPersonRejectedDocs,
  getCompanyRejectedDocs,
  updateCount,
  resetPostedDocuments
} from '../../actionCreators/softwallAction';
import DocumentComponent from './DocumentComponent';
import { updateStep } from '../../actionCreators/onboardingStepsActions';

const useStyles = makeStyles({
  cardContent: {
    padding: '0',
    paddingBottom: '0'
  },
  formContainer: {
    margin: '0 auto'
  },
  cards: {
    margin: '0 auto'
  },
  height: {
    height: '10rem'
  },
  button: {
    textAlign: 'center',
    margin: '2.5rem 0'
  },
  input: {
    display: 'none'
  },
  progressReport: {
    color: '#666566',
    fontSize: '1.4rem'
  },
  subtitle: {
    color: '#323132',
    fontSize: '2.4rem',
    fontWeight: '600'
  },
  helpMessage: {
    color: '#666566',
    fontSize: '1.4rem',
    textAlign: 'center'
  }
});

const Component = props => {
  // Redux
  const dispatch = useDispatch();
  const modal = useSelector(state => state.softwallReducer.modal);
  const missingDocsState = useSelector(
    state => state.softwallReducer.missingDocuments
  );
  const rejectedDocuments = useSelector(
    state => state.softwallReducer.rejectedDocuments
  );
  const countMissingDocs = useSelector(
    state => state.softwallReducer.countMissingDocs
  );
  const countRejectedDocs = useSelector(
    state => state.softwallReducer.countRejectedDocs
  );
  const postedDocuments = useSelector(
    state => state.softwallReducer.postedDocuments
  );
  const userInfoState = useSelector(state => state.userInfoReducer.user);
  const onboardingState = useSelector(state => state.onboardingStepsReducer);
  const companyTypeId = userInfoState.company_type_id;
  const rejectedDocs = [];

  // Hooks
  const [documentType, setDocumentType] = useState('missing');
  const [hasAttachedFiles, setHasAttachedFiles] = useState(false);
  const [successView, setSuccessView] = useState(false);
  const [docsSubmitted, setDocsSubmitted] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState();
  const [initialStep] = useState(companyTypeId === 1 ? 7 : 6);

  // Classes
  const classes = useStyles();

  useEffect(() => {
    getMissingDocs();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      'count' in countMissingDocs &&
      countMissingDocs.uploaded === countMissingDocs.count
    ) {
      setDocumentType('rejected');
      getRejectedDocs();
      getMissingDocs();
      return;
    }

    setDocumentType('missing');
    //eslint-disable-next-line
  }, [countMissingDocs.count, countMissingDocs.uploaded]);

  useEffect(() => {
    if (
      countRejectedDocs.count &&
      countRejectedDocs.uploaded === countRejectedDocs.count
    ) {
      getRejectedDocs();
    }
    //eslint-disable-next-line
  }, [countRejectedDocs.uploaded]);

  useEffect(() => {
    let attachedFiles = [];
    let uploaded = [];
    Object.keys(missingDocsState).forEach(el => {
      if (missingDocsState[el].charged) {
        attachedFiles.push(el);
      }
      if (missingDocsState[el].success) {
        uploaded.push(el);
      }
    });

    updateCount(
      dispatch,
      {
        ...countMissingDocs,
        attachedFiles: attachedFiles.length,
        uploaded: uploaded.length
      },
      'missing'
    );
    //eslint-disable-next-line
  }, [missingDocsState]);

  useEffect(() => {
    let attachedFiles = [];
    let uploaded = [];
    Object.keys(rejectedDocuments).forEach(el => {
      if (rejectedDocuments[el].charged) {
        attachedFiles.push(el);
      }
      if (rejectedDocuments[el].success) {
        uploaded.push(el);
      }
    });

    updateCount(
      dispatch,
      {
        ...countRejectedDocs,
        attachedFiles: attachedFiles.length,
        uploaded: uploaded.length
      },
      'rejected'
    );
    //eslint-disable-next-line
  }, [rejectedDocuments]);

  useEffect(() => {
    if (
      'attachedFiles' in countMissingDocs ||
      'attachedFiles' in countRejectedDocs
    ) {
      if (
        countMissingDocs.attachedFiles > 0 ||
        countRejectedDocs.attachedFiles > 0
      ) {
        setHasAttachedFiles(true);
      } else {
        setHasAttachedFiles(false);
      }
    }
    //eslint-disable-next-line
  }, [countMissingDocs.attachedFiles, countRejectedDocs.attachedFiles]);

  const getMissingDocs = () => {
    if (companyTypeId === 1) {
      getCompanyMissingDocs(dispatch);
    } else {
      getPersonMissingDocs(dispatch);
    }
  };

  const getRejectedDocs = () => {
    if (companyTypeId === 1) {
      getCompanyRejectedDocs(dispatch);
    } else {
      getPersonRejectedDocs(dispatch);
    }
  };

  const goToNextStep = () => {
    resetPostedDocuments(dispatch);
    let state =
      documentType === 'missing' ? missingDocsState : rejectedDocuments;

    let count =
      documentType === 'missing' ? countMissingDocs : countRejectedDocs;

    setAttachedFiles(count.attachedFiles);
    postDocuments(
      dispatch,
      state,
      documentType,
      companyTypeId,
      userInfoState.user_id,
      count
    );

    setDocsSubmitted(true);
  };

  const handleCloseModal = () => {
    manageModalDocs(dispatch, { ...modal, open: false });
  };

  const savePhoto = (fileName, content, documentId) => {
    let photo = {
      fileName: fileName,
      file: content,
      kyc: documentId
    };

    let state =
      documentType === 'missing' ? missingDocsState : rejectedDocuments;

    updateDocuments(
      dispatch,
      {
        ...state,
        [documentId]: {
          ...state[documentId],
          data: photo,
          charged: true,
          fileName: fileName,
          person: true
        }
      },
      documentType
    );
  };

  useEffect(() => {
    if (docsSubmitted) {
      startLoading(dispatch);
    }
    if (postedDocuments === attachedFiles) {
      stopLoading(dispatch);
      setDocsSubmitted(false);
    }
    //eslint-disable-next-line
  }, [postedDocuments]);

  useEffect(() => {
    if (
      Object.keys(missingDocsState).length === 0 &&
      Object.keys(rejectedDocuments).length === 0
    ) {
      setSuccessView(true);
      updateStep(dispatch, onboardingState, 'nextStep');
    } else {
      setSuccessView(false);
      if (onboardingState.currentStep > initialStep)
        updateStep(dispatch, onboardingState, 'prevStep');
    }
    //eslint-disable-next-line
  }, [missingDocsState, rejectedDocuments]);

  return (
    <Box>
      {missingDocsState ? (
        <div>
          {Object.keys(missingDocsState).length > 0 ? (
            <Box>
              <MainTitle text="Aún tienes pendiente subir los siguientes documentos:" />
              <DocumentComponent
                array={missingDocsState}
                documentsType="missing"
                gridMdSpacing={6}
              />
            </Box>
          ) : null}
          {Object.keys(missingDocsState).length === 0 &&
          Object.keys(rejectedDocuments).length > 0 ? (
            <Box>
              <MainTitle text="Tu informe de progreso" />
              <p className={classes.subtitle}>
                Desafortunadamente estos documentos han tenido un problema
              </p>
              <p className={classes.progressReport}>
                Te recomendamos verifiques que los documentos pertenezcan a la
                sección correspondiente y los datos del documento sean claros,
                si subes el mismo documento es posible que tenga el mismo
                problema.
              </p>
              <DocumentComponent
                array={rejectedDocuments}
                documentsType="rejected"
                gridMdSpacing={6}
              />
            </Box>
          ) : null}
          {successView && <Success companyTypeId={companyTypeId} />}
          <IneModal
            selfie={modal.selfie}
            open={modal.open}
            onClose={handleCloseModal}
            ineFront={modal.ineFront}
            ineBack={modal.ineBack}
            savePhoto={savePhoto}
            userdocumentsType={userInfoState.company_documentsType_id}
          />
          <div className={classes.button}>
            {!rejectedDocs.length === 0 &&
              !Object.keys(missingDocsState).length === 0 && (
                <WhiteButton
                  copy="Regresar"
                  onClick={props.handleReturn}
                  customClass={classes.buttonMargin}
                />
              )}

            {!successView ? (
              <PrimaryButton
                text={'Continuar'}
                disabled={!hasAttachedFiles}
                onClick={goToNextStep}
              />
            ) : null}
          </div>
          <div>
            <p className={classes.helpMessage}>
              Si necesitas ayuda o tienes alguna pregunta acerca de tus
              documentos, escríbenos a{' '}
              <a href="mailto:ayuda@delt.ai">ayuda@delt.ai</a>
            </p>
          </div>
        </div>
      ) : null}
    </Box>
  );
};

const SoftWall = props => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        captureEvent({
          level: Severity.Warning,
          message: `errorBoundary: ${error.message}`,
          extra: {
            component: 'FreelancerStep2',
            errorBoundary: true,
            errorName: error.name,
            props: props
          }
        });
        return <></>;
      }}
    >
      <Component {...props} />
    </ErrorBoundary>
  );
};

export default SoftWall;
