import { GET_COMPANY_SUMMARY, SET_ERROR } from '../reducers/types';
import { getCompanySummary } from '../Services/Company';
import { setAction } from '.';

export const getCompanySum = async dispatch => {
  try {
    let res = await getCompanySummary();
    setAction(res, GET_COMPANY_SUMMARY, dispatch);
    if (res.status === 200) {
      dispatch({
        type: GET_COMPANY_SUMMARY,
        payload: res.data[0]
      });
    }
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: error
    });
  }
};
