import { AddressCreator } from '../domain/address-data';

const getPersonAddressHandler = ({ getPersonAddress }) => async () => {
  const { error, data: personAddressData } = await getPersonAddress();

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  const personAddress = AddressCreator({ data: personAddressData });

  return {
    isSuccess: true,
    personAddress
  };
};

export { getPersonAddressHandler };
