// React imports
import React, { useState, useContext, useEffect } from 'react';

// Auxiliary librarys imports
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

// Own Components
import { Context as userInfoContext } from '../../../context/userInfoContext';
import { closeSession } from '../../../Services/authService';
import { PURGE } from '../../../reducers/types';
import { RedBorderButton } from '../';
import { SideBanner } from '../';
import Signup from '../../Signup/Signup';
import { Welcome } from '../../../Views';

const useStyles = makeStyles(theme => ({
  welcomeContainer: {
    width: '100%',
    paddingBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem'
    }
  },
  containerHeight: {
    height: '100vh'
  },
  borderRad: {
    borderRadius: '2.4rem',
    marginLeft: '2rem'
  },
  topBar: {
    padding: '2rem'
  }
}));

const BasicInfoTemplate = props => {
  // Styles
  const classes = useStyles();

  // Context
  const { saveUserToken } = useContext(userInfoContext);

  // Hooks
  let [renderText, setRenderText] = useState(true);
  let [renderWelcome, setRenderWelcome] = useState(true);
  let [typeOfUser, setTypeOfUser] = useState('');

  // Redux
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.userInfoReducer.token);

  const setForms = type => {
    setRenderText(true);
    setRenderWelcome(false);
    setTypeOfUser(type);
  };

  const goToLogin = () => {
    setRenderText(false);
    props.history.push('/login');
  };

  const goToOnboarding = () => {
    props.history.push('/onboarding');
  };

  const goToHome = () => {
    setRenderWelcome(true);
  };

  const logOut = () => {
    try {
      closeSession();
    } finally {
      dispatch({ type: PURGE });
      saveUserToken({});
    }
  };

  const loggedInComponent = () => {
    return (
      <div>
        <RedBorderButton copy="Mi cuenta" onClick={goToOnboarding} />
        <RedBorderButton copy="Salir" onClick={logOut} />
      </div>
    );
  };

  const notLoggedInComponent = () => {
    return (
      <div>
        <RedBorderButton copy="Iniciar sesión" onClick={goToLogin} />
      </div>
    );
  };

  useEffect(() => {
    if (props.history.location.pathname === '/pyme') {
      setForms('pyme');
    } else if (props.history.location.pathname === '/freelancer') {
      setForms('freelancer');
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Grid
      className={`${classes.containerHeight}`}
      justify="center"
      container
      spacing={0}
    >
      <Hidden smDown>
        <Grid item sm={0} md={4}>
          <SideBanner image={props.image} renderText={renderText} />
        </Grid>
      </Hidden>
      <Grid container justify="center" item sm={10} md={8}>
        <Grid container alignItems="flex-start" item xs={10} md={8}>
          <Container className={classes.welcomeContainer}>
            <Hidden smDown>
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.topBar}
              >
                {!renderWelcome && (
                  <RedBorderButton copy="Regresar" onClick={goToHome} />
                )}

                {!isLoggedIn ? notLoggedInComponent() : null}
                {isLoggedIn ? loggedInComponent() : null}
              </Grid>
            </Hidden>

            {renderWelcome ? (
              <Welcome setForms={setForms} />
            ) : (
              <Signup type={typeOfUser} />
            )}
            <Hidden mdUp>
              <Grid container justify="center">
                {!isLoggedIn ? notLoggedInComponent() : null}
                {isLoggedIn ? loggedInComponent() : null}
              </Grid>
            </Hidden>
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicInfoTemplate;
