import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '1.8rem',
    fontWeight: '600',
    color: '#323132',
    margin: '0',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.4rem'
    }
  }
}));

const Subtitle = props => {
  const classes = useStyles();
  return (
    <div>
      <h2 className={classes.root}>{props.text}</h2>
    </div>
  );
};

export default Subtitle;
