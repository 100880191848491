/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';

import Button from '../../../../shared/components/Button/Button';
import CreditVoucher from '../CreditVoucher/CreditVoucher';
import DepositVoucher from '../DepositVoucher/DepositVoucher';
import Header from '../Header/Header';
import LabelGroup from '../LabelGroup';
import TagList from '../../../../tags/TagList/TagList';
// import TransactionDetails from '../TransactionDetails';
import TransactionVoucher from '../TransactionVoucher/TransactionVoucher';

import { transactionTypeIds } from '../../Transactions/constants';
import {
  getShortDate,
  parseAmount,
  splitAmount
} from '../../../../../helpers/helpers';

import { BodyContainer, Logo, VoucherContainer, TagsSection } from './styles';
import LogoImg from '../../../../../Dashboard4.0/assets/images/deltai-logo.svg';

import { service as tagService } from '../../../../tags/service';
import {
  tagsSelector,
  transactionsSelector
} from '../../../../tags/redux/tag-selectors';
import {
  isAdminSelector,
  isSuperAdminSelector
} from '../../../../cards/redux/cards-selectors';
import {
  ADD_TAG_TO_TRANSACTION,
  REMOVE_TAG_FROM_TRANSACTION
} from '../../../../../reducers/types';

const Voucher = ({
  details,
  reversals = [],
  onClick,
  isHighQuality,
  onOpenRemoveModal
}) => {
  const {
    amount,
    transaction_datetime = '',
    transaction_type_id,
    tracking_key,
    credit_card_id,
    credit_transaction_id
  } = details;

  const dispatch = useDispatch();
  const tagsAdded = useSelector(tagsSelector);
  const transactions = useSelector(transactionsSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const [transactionId, setTransactionId] = useState(null);
  const componentRef = useRef();

  const voucherTypes = {
    [transactionTypeIds.order]: {
      component: <TransactionVoucher details={details} />,
      text: 'Transferencia',
      type: 'transfer'
    },
    [transactionTypeIds.deposit]: {
      component: <DepositVoucher details={details} />,
      text: 'Depósito',
      type: 'deposit'
    },
    [transactionTypeIds.purchase]: {
      component: <CreditVoucher details={details} />,
      text: 'Cargo con tarjeta',
      type: 'credit'
    },
    [transactionTypeIds.reversal]: {
      component: (
        <CreditVoucher details={{}} reversals={reversals} type="reversal" />
      ),
      text: 'Reverso',
      type: 'reversal'
    }
  };

  const handleDeleteTag = async tag => {
    const tagData =
      transaction_type_id === transactionTypeIds.order
        ? { ...tag, transactionId }
        : {
            ...tag,
            transactionId: credit_transaction_id,
            cardId: credit_card_id
          };
    onOpenRemoveModal(tagData);
  };

  const handleSaveTag = async tag => {
    if (transaction_type_id === transactionTypeIds.order) {
      const { isSuccess } = await tagService({ dispatch }).createSpeiTag({
        ...tag,
        transactionId
      });

      if (isSuccess) {
        dispatch({
          type: ADD_TAG_TO_TRANSACTION,
          payload: {
            transactionId,
            tag: { tag_id: tag.tagId, tag_label: tag.tagLabel }
          }
        });
      }
    } else {
      await tagService({ dispatch }).createCardTransactionCard({
        ...tag,
        transactionId: credit_transaction_id,
        cardId: credit_card_id
      });
    }
  };

  const shouldDisplayTags = transaction_type_id => {
    const enableTagsFeature = process.env.REACT_APP_ENABLE_TRANSACTION_TAGS;
    const enableOrderTags = process.env.REACT_APP_ENABLE_SPEI_TRANSACTION_TAGS;
    const enableCreditCardTags =
      process.env.REACT_APP_ENABLE_CARD_TRANSACTION_TAGS;

    if (isHighQuality) {
      return false;
    }

    if (enableTagsFeature) {
      if (
        enableOrderTags === 'true' &&
        transaction_type_id === transactionTypeIds.order
      ) {
        return true; // Display tags for payment orders (1)
      }

      if (
        enableCreditCardTags === 'true' &&
        transaction_type_id === transactionTypeIds.purchase
      ) {
        return true; // Display tags for credit card transactions (5)
      }
    }

    return false;
  };

  useEffect(() => {
    const currentTransaction = transactions.find(
      transaction => transaction.tracking_key === tracking_key
    );
    if (currentTransaction) {
      setTransactionId(currentTransaction.transaction_id);
    }
  }, []);

  if (transaction_type_id) {
    return (
      <VoucherContainer ref={componentRef}>
        <Header
          amount={splitAmount(parseAmount(amount))}
          className="amount"
          type={voucherTypes[transaction_type_id].type}
          text={voucherTypes[transaction_type_id].text}
        />
        <BodyContainer>
          <Logo src={LogoImg} />
          <LabelGroup
            mainText={getShortDate(transaction_datetime)?.toLowerCase()}
            secondaryText={getShortDate(transaction_datetime, 'hh:mm A')}
            align="right"
          />

          {voucherTypes[transaction_type_id].component}

          {shouldDisplayTags(transaction_type_id) && (
            <TagsSection>
              <TagList
                tags={tagsAdded}
                lengthValidation={7}
                hasPermissions={isSuperAdmin || isAdmin}
                handleSaveTag={handleSaveTag}
                handleDeleteTag={handleDeleteTag}
              />
            </TagsSection>
          )}

          {onClick ? (
            <div className="btnContainer">
              <ReactToPrint
                trigger={() => <Button text="Descargar" />}
                content={() => componentRef.current}
                documentTitle={'comprobante'}
              />
            </div>
          ) : null}
        </BodyContainer>
      </VoucherContainer>
    );
  }
  return null;
};

Voucher.propTypes = {
  details: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isHighQuality: PropTypes.bool
};

export default Voucher;
