import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../Modal/Modal';
import FirstStep from '../FirstStep/FirstStep';
import RegistrationComplete from '../RegistrationComplete/';
import SecondStep from '../SecondStep/SecondStep';

import { closeSession } from '../../../../Services/authService';
import { ProfileCompletionService } from '../../services/sub-account-service';
import { ProfileCompletionObject } from '../../sub-account-object-creators/sub-account-object-creator';
import { updateUserStatus } from '../../../../actionCreators/authAction';

const Registration = () => {
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    birthdate: '',
    gender: '',
    selfie: '',
    curp: '',
    rfc: '',
    phone: ''
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const userStatus = useSelector(state => state.userInfoReducer.user.status);

  const uploadData = async form => {
    const parseObject = ProfileCompletionObject(form);
    const { profileCompletionResponse, error } = await ProfileCompletionService(
      parseObject
    );

    if (error) return;

    const createdBySuperAdmin =
      profileCompletionResponse[0].created_by_user_role === 'Super Admin';

    if (createdBySuperAdmin) {
      updateUserStatus('User validated', dispatch);
      history.push({
        pathname: '/activity',
        state: { prevlocation: history.location.pathname }
      });
    }
    setStep(3);
  };

  useEffect(() => {
    if (form.birthdate && form.birthdate.includes('/')) {
      const arrDate = form.birthdate.split('/');
      const formattingDate = `${arrDate[0]}-${arrDate[1]}-${arrDate[2]}`;

      setForm({ ...form, birthdate: formattingDate });
    }

    const formArr = Object.values(form);

    if (!formArr.includes('')) uploadData(form);
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (userStatus === 'Pending approval') setStep(3);
    // eslint-disable-next-line
  }, [userStatus]);

  const StepSelector = ({ step }) => {
    switch (step) {
      case 1:
        return <FirstStep setForm={setForm} changeStep={setStep} />;
      case 2:
        return (
          <SecondStep form={form} setForm={setForm} changeStep={setStep} />
        );
      case 3:
        return <RegistrationComplete logOut={logOut} />;
      default:
        return null;
    }
  };

  const logOut = async () => {
    try {
      await closeSession();
    } finally {
      dispatch({ type: 'PURGE' });
      history.push('/');
    }
  };

  return (
    <Modal>
      <StepSelector step={step} />
    </Modal>
  );
};

export default Registration;
