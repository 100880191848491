const sendResetPasswordHandler = ({ sendResetPasswordEmail }) => async ({
  email,
}) => {
  const { error } = await sendResetPasswordEmail({
    email,
  });

  if (error) {
    return {
      isSuccess: false,
      error,
    };
  }

  return { isSuccess: true };
};

export { sendResetPasswordHandler };
