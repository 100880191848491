import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { loginPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const loginUserService = async ({ email, password }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      loginPath,
      {
        email,
        password,
      },
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'login-service',
    });
  } catch (error) {
    log.error('login-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
