/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { memo } from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// Components
import Input from '../Input/Input';
import { Container } from './FilterBar.styles';

const FilterBar = ({
  searchIcon,
  searchPlaceholder,
  searchFn,
  rightElement: Component
}) => {
  const handleEvent = e => {
    searchFn(e.target.value);
  };

  return (
    <Container rightElement={Component}>
      <Input
        icon={searchIcon}
        type="text"
        placeholder={searchPlaceholder}
        onChange={handleEvent}
        maxLength="40"
        widthFor40chars
      />

      {Component ? <Component /> : null}
    </Container>
  );
};

FilterBar.propTypes = {
  searchIcon: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  searchFn: PropTypes.func.isRequired,
  rightElement: PropTypes.any,
  query: PropTypes.string
};

export default memo(FilterBar);
