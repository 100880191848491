import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '0',
    paddingBottom: '0'
  },
  formContainer: {
    margin: '0 auto'
  },
  cards: {
    margin: '0 auto'
  },
  height: {
    height: '10rem'
  },
  button: {
    textAlign: 'center',
    marginTop: '2.5rem'
  },
  input: {
    display: 'none'
  },
  instructions: {
    fontSize: '1.8rem',
    color: '#989898'
  },
  header: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    margin: '0 auto'
  }
}));
