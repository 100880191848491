/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../TextField/TextField';

const Control = ({ selectProps }) => {
  const {
    label,
    name,
    onFocus,
    onBlur,
    onNonSearchableClick,
    onInputChange,
    inputValue,
    error,
    helperText,
    textFieldProps,
    isSearchable
  } = selectProps;

  return (
    <TextField
      label={label}
      name={name}
      value={inputValue}
      hasValue={!!inputValue}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
      onClick={onFocus}
      onBlur={onBlur}
      onDisabledFieldClick={onNonSearchableClick} // react is throwing warnings due to unknown event handler
      onChange={e => onInputChange(e.target.value)}
      fullWidth
      autoComplete="off"
      readOnly={!isSearchable}
      {...textFieldProps}
    />
  );
};

Control.propTypes = {
  selectProps: PropTypes.object.isRequired
};

export default Control;
