export const checkPermissions = (
  allPermissions,
  rules,
  perform,
  apiDocsEnabled
) => {
  // api must be enabled
  if (perform.startsWith('public_api') && !apiDocsEnabled)
    return false;

  // super admin user
  if (allPermissions)
    return true;

  // validate rules
  if (!rules || !Array.isArray(rules))
    return false;

  // normal check
  return rules.includes(perform);
};
