import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const ModalContainer = styled.div`
  border-radius: 1.2rem;
  overflow: hidden;
  box-shadow: 0 11px 22px -8px rgba(28, 38, 51, 0.3);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 5rem;
  min-height: 5rem;
  position: relative;

  .modalClose {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 3;
    font-size: 1.4rem;
    cursor: pointer;
    color: ${colors.carbon1};
  }

  @media (max-width: 768px) {
    height: 100%;
    border-radius: revert;
  }
`;

export const HeaderContainer = styled.div`
  background-image: linear-gradient(
    75deg,
    ${colors.pallete0Start} 18%,
    ${colors.pallete0End} 88%
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(14px);
  padding-top: 5.6rem;

  @media (max-width: 768px) {
    padding-top: 4.6rem;
  }
`;
