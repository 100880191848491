export const getCustomerDataHandler = ({
    getCustomerData
  }) => async ({customerUuid}) => {
    const { error, data } = await getCustomerData(customerUuid);
  
    if (error) {
      return {
        isSuccess: false,
        error
      };
    }
  
    return {
      isSuccess: true,
      data
    };
  };
  