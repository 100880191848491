import React from 'react';

import Button from '../../../shared/components/Button/Button';
import CustomModal from '../../../../components/CustomModal/CustomModal';
import OnboardingErrorBoundary from '../OnboardingErrorBoundary/OnboardingErrorBoundary';
import OnboardingLayout from '../../components/OnboardingLayout/OnboardingLayout';

import ID from '../../../../assets/visual_information.svg';
import Paragraph from '../../../shared/components/Paragraph/Paragraph';

const Description = () => (
  <>
    <img src={ID} alt="selfie" />{' '}
    <div>
      En este paso tómate una selfie, retira cualquier objeto de tu rostro. No
      te preocupes, puedes intentarlo varias veces.
    </div>
    <Paragraph
      size="0.9rem"
      textAlign="center"
      text="Para continuar permite el uso de la cámara"
    />
  </>
);

const Component = ({ steps, activeStep, goBack, goToNextStep }) => {
  return (
    <OnboardingLayout
      title={`Por seguridad necesitamos verificar tu identidad`}
      description={<Description />}
      isPrimaryButtonHidden
      secondaryButtonText="Regresar"
      isPrimaryButtonDisabled
      isModalVisible
      onSecondaryButtonClick={goBack}
      steps={steps}
      activeStep={activeStep}
    >
      <CustomModal
        goToNext={goToNextStep}
        isRedesign
        styledButton={props => (
          <Button
            className="formButton"
            text="Verificar"
            size="lg"
            {...props}
          />
        )}
      />
    </OnboardingLayout>
  );
};

const IdentityPage = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="IdentityPage"
      props={props}
    />
  );
};

export default IdentityPage;
