import styled, { css } from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

import { TextFieldContainer, Label } from '../TextField/TextField.styles';

const inputHasValue = css`
  top: 1.4rem;
`;

const hasValueStyles = ({ hasValue }) => {
  return hasValue ? inputHasValue : '';
};

const validateLengthSize = ({ label }) => {
  const length = label.length;
  if (length > 43) {
    return `
      font-size: 1.3rem;
      line-height: 2rem;
    `;
  }
};

export const FileInputContainer = styled(TextFieldContainer)`
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  max-width: 100%;
  cursor: pointer;
  input {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  @media (max-width: 360px) {
    grid-template-columns: 10fr 1fr;
  }
`;

export const FileInputLabel = styled(Label)`
  font-size: 1.7rem;
  ${hasValueStyles};
  ${validateLengthSize};
  position: initial;
  cursor: pointer;
`;

export const FileName = styled.div`
  font-size: 1.7rem;
  color: ${colors.body};
`;

export const Icon = styled.img`
  margin-right: 1.8rem;

  @media (max-width: 360px) {
    display: none;
  }
`;

export const InputContainer = styled.div`
  max-width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: hidden;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const InputButtonContainer = styled.div`
  display: flex;
  align-items: center;

  .fileInputEndAdorment {
    margin-left: 0.8rem;
  }

  .endAdornmentTooltip {
    left: -9rem;
  }
`;
