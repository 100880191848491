import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, validate, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        (validate ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: rest.redirect
            }}
          />
        ))
      }
    />
  );
};
