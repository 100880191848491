import { GET_COMPANY_SUMMARY, SET_ERROR, PURGE } from './types';

const initialState = {
  companySummary: {},
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_SUMMARY:
      return {
        ...state,
        companySummary: action.payload
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
