import log from 'loglevel';
import { extractContentFromResponse } from '../../../../../../helpers/extractContentFromResponse';
import { customAxiosInstance } from '../../../../../../Services/axiosConfig';
import { getRejectedBatchPath } from './apiPaths';

export const getFailedBatches = async ({ batchId }) => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      getRejectedBatchPath(batchId)
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'get-failed-batches'
    });
  } catch (error) {
    log.error('get-failed-batches', error);

    return { error: { message: 'Not able to get response' } };
  }
};
