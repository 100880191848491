import React from 'react';
import { Route } from 'react-router-dom';

// Pages
import ChooseSignMethod from './pages/ChooseSignMethod/ChooseSignMethod';
import DigitalSignContract from './pages/digital-sign-contract/DigitalSignContract';
import SignatureSchedule from './pages/SignatureSchedule/SignatureSchedule';

// Constants
import {
  digitalSignContractPath,
  signatureSchedulePath,
  signContractPath
} from './constants';

const UsersCRUDModule = () => {
  return (
    <>
      <Route exact path={signContractPath} component={ChooseSignMethod} />
      <Route
        exact
        path={digitalSignContractPath}
        component={DigitalSignContract}
      />
      <Route exact path={signatureSchedulePath} component={SignatureSchedule} />
    </>
  );
};

export default UsersCRUDModule;
