import { CardsCreator } from '../domain/card-data';
import { setCardsAction, setCardsErrorAction } from '../redux/cards-actions';

const getCardsHandler = ({ getCards, dispatch }) => async () => {
  const { error, data: cardsData } = await getCards();

  if (error) {
    dispatch(setCardsErrorAction({ error }));
    return;
  }
  const cards = CardsCreator({ data: cardsData });

  dispatch(setCardsAction({ cards, success: true }));
};

export { getCardsHandler };
