// React
import React, { useState } from 'react';

// Others
import PropTypes from 'prop-types';

// Components
import BatchContent from '../BatchTransfer/BatchContent';
import FileLoader from './FileLoader';
import HandleButtons from '../BatchTransfer/HandleButtons';

// Styles
import {
  DetailsContainer,
  Error,
  FileContainer,
  FileDetailsContainer,
  Subtitle
} from '../BatchTransfer/BatchTransfer.styles';

const FileDetails = ({
  file,
  onClose,
  resetFile,
  handleBack,
  changePage,
  done
}) => {
  const [parsedFile, setParsedFile] = useState();
  const [fileError, setFileError] = useState(null);
  const [dataFile, setDataFile] = useState(null);

  return (
    <FileDetailsContainer>
      <BatchContent
        data-test="FileDetails"
        title="Revisar múltiples beneficiarios"
        className="titleBlack"
        subtitle={
          <>
            <Subtitle>
              {dataFile && (
                <>
                  Encontramos {dataFile.beneficiaries}{' '}
                  {`${
                    dataFile?.beneficiaries === 1
                      ? 'beneficiario'
                      : 'beneficiarios'
                  }`}{' '}
                  en el archivo <span>{dataFile.nameFile}</span>
                </>
              )}
            </Subtitle>
            {fileError ? <Error>{fileError}</Error> : null}
            <FileContainer>
              {file && file.file ? (
                <FileLoader
                  file={file.file}
                  setParsedFile={setParsedFile}
                  setFileError={setFileError}
                  dataParsed={setDataFile}
                />
              ) : null}
            </FileContainer>
          </>
        }
        content={
          <>
            <DetailsContainer>
              <HandleButtons
                file={parsedFile}
                onClose={onClose}
                resetFile={resetFile}
                disabled={fileError}
                type="beneficiaries"
                done={done}
                changePage={changePage}
                beneficiaries={dataFile ? dataFile.beneficiaries : ''}
              />
            </DetailsContainer>
          </>
        }
      />
    </FileDetailsContainer>
  );
};

FileDetails.propTypes = {
  file: PropTypes.shape({
    file: PropTypes.shape({
      lastModified: PropTypes.number,
      lastModifiedDate: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      webkitRelativePath: PropTypes.string
    })
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  resetFile: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired
};

export default FileDetails;
