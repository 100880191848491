import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  table: {
    position: 'absolute',
    border: '1px solid #b0cbef',
    borderWidth: '1px 0px 0px 1px',
    fontSize: '11pt',
    fontFamily: 'Calibri',
    fontWeight: '100',
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    '& th': {
      fontWeight: 'bold',
      border: '1px solid #9eb6ce',
      borderWidth: '0px 1px 1px 0px',
      height: '17px',
      minWidth: '50px',
      maxWidth: '200px',
      whiteSpace: 'nowrap'
    },
    '& td': {
      padding: '0px 4px 0px 2px',
      border: '1px solid #d0d7e5',
      borderWidth: '0px 1px 1px 0px',
      whiteSpace: 'nowrap',
      '& b': {
        border: '0px',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    }
  },
  head: {
    backgroundColor: '#e4ecf7',
    textAlign: 'center',
    border: '1px solid #9eb6ce',
    borderWidth: '0px 1px 1px 0px',
    fontSize: '14px',
    fontWeight: 'bold'
  }
}));

const ExcelRenderer = ({ rows, cols }) => {
  const classes = useStyles();

  const getRows = (el, i, arr) => {
    const td = arr[i].slice(',');
    return (
      <tr key={i}>
        <td className={classes.head}>
          <b>{i}</b>
        </td>
        {td.map((el, i) => (
          <td key={i}>{el}</td>
        ))}
      </tr>
    );
  };

  return (
    <table className={classes.table}>
      <tbody>
        <tr>
          {cols.map((el, i) => (
            <th key={i}>{el.name}</th>
          ))}
        </tr>
        {rows.map((el, i, arr) => getRows(el, i, arr))}
      </tbody>
    </table>
  );
};

export default ExcelRenderer;
