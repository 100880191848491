import { PHYSICAL } from '../constants';

export const issuePhysicalCardHandler = ({ issueCard }) => async ({
  creditCardId,
  cardLimit,
  creditCardName,
  cardOwnerId,
  deliveryAddress,
  password,
  email,
  cardPin
}) => {
  const { error, data } = await issueCard({
    creditCardId,
    cardType: PHYSICAL,
    cardLimit,
    creditCardName,
    cardOwnerId,
    deliveryAddress,
    password,
    email,
    cardPin
  });

  if (error) {
    return { isSuccess: false, error };
  }
  // const card = CardCreator({ data: cardData });

  // dispatch(doCardNaiveUpdate({ card }));
  return { isSuccess: true, data: data || {} };
};
