import { PURGE, SET_FAILED_BATCHES } from './types';

const INITIAL_STATE = {
  failedBatches: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FAILED_BATCHES:
      return {
        ...state,
        failedBatches: action.payload
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
