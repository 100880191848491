import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from '../../components/Button';

import { closeSession } from '../../../../Services/authService';

import deltaiLogo from '../../assets/deltai.png';

const HeadLayout = ({ showLogout = false, handleLogOut }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logOut = () => {
    try {
      closeSession();
    } finally {
      dispatch({ type: 'PURGE' });
      history.push('/');
    }
  };

  return (
    <div className="header">
      <img alt="delt.ai Logo" src={deltaiLogo} className="deltaiLogo" />
      {showLogout ? (
        <Button
          variant="outlined"
          text="Salir"
          className="logoutBtn"
          onClick={handleLogOut ? handleLogOut : logOut}
        />
      ) : null}
    </div>
  );
};

export default HeadLayout;
