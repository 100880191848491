// Validations messages
export const INVALID_DATE_MESSAGE = 'Fecha de nacimiento inválida';
export const INVALID_CONSTITUTION_MESSAGE = 'Fecha de constitución inválida';
export const REQUIRED_MESSAGE = 'Campo requerido';
export const LENGTH_MESSAGE = 'Debe contener 13 caracteres';
export const ENTERPRISE_LENGTH_MESSAGE = 'Debe contener 12 caracteres';
export const INSTITUTION_NOT_FOUND_MESSAGE = 'Institución bancaria no operante';
export const ONLY_NUMBERS_MESSAGE = 'Sólo se permiten números';

// Onboarding steps
export const EMAIL_CONFIRMED = 'EMAIL_CONFIRMED';
export const INITIAL_ONBOARDING = 'INITIAL_ONBOARDING';
export const ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE';

// Paths
export const freelancerAddressPath = '/onboarding/freelancer/address';
export const freelancerInfoPath = '/onboarding/freelancer/basic-info';
export const freelancerBankPath = '/onboarding/freelancer/bank-info';
export const freelancerIdentity = '/onboarding/freelancer/identity';
export const freelancerDocuments = '/onboarding/freelancer/documents';

export const enterpriseAddressPath = '/onboarding/enterprise/address';
export const enterpriseAttorneyPath = '/onboarding/enterprise/attorney-in-law';
export const enterpriseInfoPath = '/onboarding/enterprise/basic-info';
export const enterpriseBankPath = '/onboarding/enterprise/bank-info';
export const enterpriseIdentity = '/onboarding/enterprise/identity';
export const enterpriseDocuments = '/onboarding/enterprise/documents';
export const softwallTpaPath = `/tpa/onboarding/softwall`;

export const onboardinsPaths = [
  softwallTpaPath
]

export const newOnboardingPath = '/new-onbsoftwallTpaPathsoftwallTpaPathsoftwallTpaPathoarding';

// Document's Ids
export const proofOfPowersId = 8;
export const rejectedStatusId = 3;

// Document's names
export const proofOfPowersName = 'constancia_poderes';

//Regexp
export const isCLABE = new RegExp('^[0-9]{0,18}$');

// User type variables
export const freelancerId = 2;
export const companyId = 1;
export const userTypeName = userTypeId =>
  (userTypeId === freelancerId ? 'person' : 'company');

// Company Association Type IDs
export const attorneyInFactAssociationId = 4;
export const directorAssociationId = 5;
export const ownerAssociationId = 3;

export const onboardingPaths = [
  freelancerAddressPath,
  freelancerInfoPath,
  freelancerBankPath,
  freelancerIdentity,
  freelancerDocuments,
  enterpriseAddressPath,
  enterpriseAttorneyPath,
  enterpriseInfoPath,
  enterpriseBankPath,
  enterpriseIdentity,
  enterpriseDocuments,
  softwallTpaPath,
  newOnboardingPath
];
