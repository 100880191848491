import React, { useState } from 'react';

import { AccountSecurityPage, Header, RadioButton } from '../../Account.styles';
import { Concept } from '../../../credit/components/EntryBalance/EntryBalance.styles';
import { useDispatch, useSelector } from 'react-redux';
import { service } from '../../service';

const AccountSecurity = ({ isMobile = false }) => {
  const dispatch = useDispatch();
  const two_factor_authentication_flag = useSelector(
    state => state.userInfoReducer.user.two_factor_authentication_flag
  );

  return (
    <AccountSecurityPage data-test="accountSecurity">
      <Header>
        <Concept text={'Seguridad'} />
      </Header>
      <p className="info-text bold">
        Doble Factor de Autenticación obligatoria para todas las sesiones:
      </p>

      {isMobile ? (
        <p className="info-text">
          Sólo configurable desde un navegador desde Desktop.
        </p>
      ) : (
        <>
          <div className="toggle-options-Container">
            <div className="toggle-button-Container">
              <RadioButton
                data-testid="2FA-radio-option-enable"
                checked={two_factor_authentication_flag}
                onClick={async () => {
                  await service({ dispatch }).enable2FA();
                }}
              />
              <label>Sí</label>
            </div>
            <div className="toggle-button-Container">
              <RadioButton
                data-testid="2FA-radio-option-disable"
                checked={!two_factor_authentication_flag}
                onClick={async () => {
                  await service({ dispatch }).disable2FA();
                }}
              />
              <label>No</label>
            </div>
          </div>
          <p className="info-text">
            Siempre que alguien de tu organización inicia sesión a delt.ai,
            vamos a requerir que se autentique con doble factor de
            autenticación.
          </p>
        </>
      )}
    </AccountSecurityPage>
  );
};

export default AccountSecurity;
