/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import BatchContent from './BatchContent';
import Button from '../../../shared/components/Button';
import {
  ButtonContainer,
  Error,
  FileContainer,
  FileDetailsContainer,
  Subtitle
} from './BatchTransfer.styles';
import FileLoader from './FileLoader';

const FileDetails = ({
  file,
  setParsedFile,
  onSetDataFile,
  submit,
  resetFile,
  isValid
}) => {
  const [fileError, setFileError] = useState(null);
  const [dataFile, setDataFile] = useState(null);

  const handleSetDataFile = dataFile => {
    setDataFile(dataFile);
    onSetDataFile(dataFile);
  };

  return (
    <FileDetailsContainer>
      <BatchContent
        data-test="FileDetails"
        title="Revisar las transferencias"
        className="titleBlack"
        subtitle={
          <>
            <Subtitle>
              {dataFile ? (
                <>
                  Envía <span>{dataFile.amount}</span> en total a
                  <span> {dataFile.beneficiaries}</span>{' '}
                  {dataFile.beneficiaries > 1
                    ? 'beneficiarios'
                    : 'beneficiario'}
                </>
              ) : null}
            </Subtitle>
            {fileError ? <Error>{fileError}</Error> : null}
            <FileContainer>
              {file && file.file ? (
                <FileLoader
                  file={file.file}
                  setParsedFile={setParsedFile}
                  setFileError={setFileError}
                  dataParsed={handleSetDataFile}
                />
              ) : null}
            </FileContainer>
          </>
        }
        content={
          <>
            <ButtonContainer top="2.8rem">
              <Button
                data-test="Button"
                text="Continuar"
                onClick={() => submit(3)}
                disabled={!isValid}
              />
              <Button
                onClick={() => resetFile()}
                text="Subir un nuevo archivo"
                variant="outlinedBlack"
              />
            </ButtonContainer>
          </>
        }
      />
    </FileDetailsContainer>
  );
};

FileDetails.propTypes = {
  isValid: PropTypes.bool,
  submit: PropTypes.func,
  file: PropTypes.any,
  resetFile: PropTypes.func,
  onSetDataFile: PropTypes.func
};

export default FileDetails;
