/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PageItem from './PageItem';

import { PagesContainer, PaginatorContainer } from './Paginator.styles';

import FirstSvg from '../../../../Dashboard4.0/assets/images/FirstSvg';
import LastSvg from '../../../../Dashboard4.0/assets/images/LastSvg';
import NextSvg from '../../../../Dashboard4.0/assets/images/NextSvg';
import PrevSvg from '../../../../Dashboard4.0/assets/images/PrevSvg';

const setArrRange = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const Paginator = ({
  page,
  displayedResults,
  label,
  onChange,
  totalPages,
  totalResults
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [pagesArr, setPageArr] = useState(setArrRange(1, 10, 1));
  const [resultsFrom, setResultsFrom] = useState(0);
  const [resultsUpTo, setResultsUpTo] = useState(0);

  const goToFirstPage = () => {
    handleChange(1);
  };

  const goToPreviousPage = () => {
    handleChange(currentPage - 1);
  };

  const goToNextPage = () => {
    handleChange(currentPage + 1);
  };

  const goToLastPage = () => {
    handleChange(totalPages);
  };

  const handleChange = el => {
    setCurrentPage(el);
    if (onChange) onChange(el);
  };

  useEffect(() => {
    if (currentPage !== 1) {
      const stop = currentPage + 5 <= totalPages ? currentPage + 5 : totalPages;
      const start = stop - 10 > 0 ? stop - 10 : 1;
      setPageArr(setArrRange(start, stop, 1));
    } else setPageArr(setArrRange(1, 10, 1));
    //eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(page);
    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    const resultsPerPage = 10 * (page - 1) + displayedResults;
    setResultsFrom(resultsPerPage - displayedResults + 1);
    setResultsUpTo(resultsPerPage);
    //eslint-disable-next-line
  }, [displayedResults, page]);

  return (
    <PaginatorContainer data-test="PaginatorContainer">
      {totalResults ? (
        <>
          <div data-test="Label">
            Mostrando{' '}
            {totalResults <= 10
              ? ` ${totalResults} de ${totalResults} `
              : `del ${resultsFrom} al ${resultsUpTo} de 
        ${totalResults || displayedResults} 
        ${label || ''}`}
            resultados
          </div>
          <PagesContainer data-test="PageContainer">
            <PageItem
              data-test="FirstSvg"
              className={currentPage === 1 ? 'inactive' : 'svg'}
              label={<FirstSvg />}
              onClick={goToFirstPage}
            />
            <PageItem
              data-test="PrevSvg"
              className={currentPage === 1 ? 'inactive' : 'svg'}
              label={<PrevSvg />}
              onClick={goToPreviousPage}
            />

            {totalPages <= 10
              ? [...Array(totalPages).keys()].map(el => {
                  const index = el + 1;
                  return (
                    <PageItem
                      data-test="PageItems"
                      key={el}
                      isActive={index === currentPage}
                      onClick={e => handleChange(index)}
                      label={index}
                    />
                  );
                })
              : pagesArr.map(el => {
                  return (
                    <PageItem
                      data-test="PageItems"
                      key={el}
                      isActive={el === currentPage}
                      onClick={e => handleChange(el)}
                      label={el}
                    />
                  );
                })}

            <PageItem
              className={currentPage === totalPages ? 'inactive' : 'svg'}
              label={<NextSvg />}
              onClick={goToNextPage}
            />
            <PageItem
              className={currentPage === totalPages ? 'inactive' : 'svg'}
              label={<LastSvg />}
              onClick={goToLastPage}
            />
          </PagesContainer>
        </>
      ) : null}
    </PaginatorContainer>
  );
};

Paginator.propTypes = {
  displayedResults: PropTypes.number.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  page: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired
};

export default Paginator;
