import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { cardDetailsPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const getCardDetailsService = async ({ email, password, cardId }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      `${cardDetailsPath}${cardId}`,
      {
        email,
        password
      }
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      isSingleElement: true,
      moduleName: 'card-details-service'
    });
  } catch (error) {
    log.error('card-details-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
