const resetPasswordHandler = ({ resetPassword }) => async ({
  password,
  token,
}) => {
  const { error } = await resetPassword({
    password,
    token,
  });

  if (error) return { isSuccess: false, error };

  return { isSuccess: true };
};

export { resetPasswordHandler };
