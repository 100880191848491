import { createMachine } from '../../helpers/finite-state-machine';

export const states = {
  AUTHENTICATION: 'AUTHENTICATION',
  VIEW_CARD_DETAILS: 'VIEW_CARD_DETAILS',
  EDITING_CARD_DETAILS: 'EDITING_CARD_DETAILS',
  WARNING_BEFORE_LOOSING_CHANGES: 'WARNING_BEFORE_LOOSING_CHANGES'
};

export const events = {
  AUTHENTICATED: 'AUTHENTICATED',
  CLOSING_DURING_EDIT: 'CLOSING_DURING_EDIT',
  COME_BACK_STEP: 'COME_BACK_STEP',
  RESET: 'RESET'
};

export const machine = createMachine({
  initialState: states.AUTHENTICATION,
  [states.AUTHENTICATION]: {
    transitions: {
      [events.AUTHENTICATED]: {
        target: states.VIEW_CARD_DETAILS
      },
      [events.RESET]: {
        target: states.AUTHENTICATION
      }
    }
  },
  [states.VIEW_CARD_DETAILS]: {
    transitions: {
      [events.CLOSING_DURING_EDIT]: {
        target: states.WARNING_BEFORE_LOOSING_CHANGES
      },
      [events.RESET]: {
        target: states.AUTHENTICATION
      }
    }
  },
  [states.WARNING_BEFORE_LOOSING_CHANGES]: {
    transitions: {
      [events.COME_BACK_STEP]: {
        target: states.EDITING_CARD_DETAILS
      },
      [events.RESET]: {
        target: states.AUTHENTICATION
      }
    }
  },
  [states.EDITING_CARD_DETAILS]: {
    transitions: {
      [events.CLOSING_DURING_EDIT]: {
        target: states.WARNING_BEFORE_LOOSING_CHANGES
      },
      [events.RESET]: {
        target: states.AUTHENTICATION
      }
    }
  }
});
