/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import Entry from '../BeneficiaryEntry/Entry';
import Header from '../../../shared/components/Modal/Header';
import SavedBeneficiary from './SavedBeneficiary';

import { avatarColors } from '../../../../Dashboard4.0/assets/styles/styles';
import { EntryWrapper, CustomTitle } from './AddBeneficiaryHeader.styles';

const AddBeneficiaryHeader = ({
  initials,
  alias,
  fullName,
  saved,
  email,
  isInEditMode
}) => (
    <Header>
      <CustomTitle
        data-test="headerTitle"
        size="large"
        text={
          saved
            ? 'Beneficiario'
            : isInEditMode
              ? 'Editar beneficiario'
              : 'Nuevo beneficiario'
        }
      />
      {!saved ? (
        <EntryWrapper>
          <Entry
            colors={avatarColors[0]}
            initials={initials}
            alias={alias}
            fullName={fullName}
          />
        </EntryWrapper>
      ) : (
        <SavedBeneficiary
          colors={avatarColors[0]}
          alias={alias}
          fullName={fullName}
          initials={initials}
          email={email}
        />
      )}
    </Header>
);

AddBeneficiaryHeader.propTypes = {
  alias: PropTypes.string,
  initials: PropTypes.string,
  email: PropTypes.string,
  fullName: PropTypes.string,
  saved: PropTypes.bool
};

export default AddBeneficiaryHeader;
