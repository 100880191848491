import {
  SET_CARDS,
  SET_CARDS_ERROR,
  SET_USERS,
  SET_USERS_ERROR,
  PURGE,
  SET_CARDS_TYPE_FILTER,
  SET_AVAILABLE_CARDS
} from './cards-action-types';

const initialState = {
  cards: [],
  cardsError: null,
  users: [],
  usersError: null,
  success: false,
  filterByType: '',
  availablePhysicalCards: 0,
  availableVirtualCards: 0
};

const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CARDS_ERROR:
      return {
        ...state,
        cardsError: action.payload
      };
    case SET_CARDS: {
      const { cards, success } = action.payload;
      return {
        ...state,
        cards,
        success,
        cardsError: null
      };
    }
    case SET_USERS_ERROR:
      return {
        ...state,
        usersError: action.payload
      };
    case SET_USERS: {
      const { users } = action.payload;
      return {
        ...state,
        users,
        usersError: null
      };
    }
    case SET_CARDS_TYPE_FILTER: {
      return {
        ...state,
        filterByType: action.payload
      };
    }
    case SET_AVAILABLE_CARDS: {
      const { physicalCards, virtualCards } = action.payload;
      return {
        ...state,
        availablePhysicalCards: physicalCards,
        availableVirtualCards: virtualCards
      };
    }
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default cardsReducer;
