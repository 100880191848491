import React, { useEffect, useState } from 'react';

// Auxiliar libraries
import { useHistory } from 'react-router-dom';

// Components
import Amount from '../../../shared/components/Amount/Amount';
import AmountLabel from '../AmountLabel';
import ErrorBoundaryHandler from '../../../../ErrorBoundary';
import InfoBarItem from '../../../shared/components/InfoBar/InfoBarItem';
import Label from '../../../shared/components/Label';
import ProgressBar from '../../../shared/components/ProgressBar';

// Assets
import {
  InfoBarWrapper,
  InfoBarItemContainer,
  InfoBarLink
} from '../UserDetails/styles';
import {
  AmountBar,
  Container,
  LabelCentered,
  AmountIndicator,
  AmountLabelContainer,
  StyledLink
} from './styles';
import LabelName from '../LabelName';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

// Helpers
import { getSpendingLimitHandler } from '../../../../Dashboard4.0/components/Transaction/eventHandlers/get-spending-limit-handler';
import { parseAmount, splitAmount } from '../../../../helpers';
import HasPermissions from '../../../../role-based-access/components/HasPermissions';
import { useSelector } from 'react-redux';
import InfoBar from '../../../shared/components/InfoBar/InfoBar';
import { userIdSelector } from '../../../users-crud/redux/crud-selectors';
import { alert } from '../../../../helpers/alertsManager';

const initialState = {
  sign: '$',
  integer: '0',
  decimals: '.00'
};

const Component = ({ isSuperAdmin }) => {
  const history = useHistory();
  const [userLimitsData, setUserLimitsData] = useState({});
  const [progress, setProgressBar] = useState(0);
  const userId = useSelector(userIdSelector);

  const spendingLimitArticle = process.env.REACT_APP_SPENDING_LIMITS_ARTICLE;

  const setAmountFormat = value => {
    return value ? splitAmount(parseAmount(value)) : initialState;
  };

  const getSpendingLimit = async () => {
    const { data, error } = await getSpendingLimitHandler();

    if (!error) setUserLimitsData(data);
    return data;
  };

  const getProgressBar = (userSpendLimit, totalSpend) => {
    let progress = (Number(totalSpend) * 100) / Number(userSpendLimit);
    setProgressBar(progress);
  };

  useEffect(() => {
    getSpendingLimit();
  }, []);

  useEffect(() => {
    if (userLimitsData.userSpendLimit) {
      getProgressBar(userLimitsData.userSpendLimit, userLimitsData.totalSpend);
    }
  }, [userLimitsData.totalSpend, userLimitsData.userSpendLimit]);

  return (
    <InfoBarWrapper>
      {isSuperAdmin ? <LabelName text="Tu gasto como usuario" /> : null}
      <InfoBar
        columnGap={'true'}
        isSuperAdmin={isSuperAdmin}
        start={
          <InfoBarItem>
            <Label text="TU LÍMITE" />
            <InfoBarItemContainer>
              <Amount
                integer={`$ ${
                  setAmountFormat(userLimitsData.userSpendLimit).integer
                }`}
                decimals={
                  setAmountFormat(userLimitsData.userSpendLimit).decimals
                }
              />
              <HasPermissions
                perform="user:create"
                accessAction={() => (
                  <InfoBarLink
                    onClick={() => {
                      history.push({
                        pathname: '/team',
                        state: {
                          userId,
                          trigger: 'Subir'
                        }
                      });
                    }}
                  >
                    Subir
                  </InfoBarLink>
                )}
                restrictedAction={() => (
                  <InfoBarLink
                    onClick={() => {
                      alert(
                        'info',
                        () => (
                          <p>
                            Para incrementar el límite, contacta a un
                            administrador de tu cuenta.{' '}
                            <StyledLink
                              href={spendingLimitArticle}
                              target={'_blank'}
                            >
                              Haz click aquí
                            </StyledLink>{' '}
                            para aprender más acerca de límites de gasto y cómo
                            cambiarlos.
                          </p>
                        ),

                        5000,
                        {
                          marginLeft: '-10rem',
                          width: '40rem'
                        }
                      );
                    }}
                  >
                    Subir
                  </InfoBarLink>
                )}
              />
            </InfoBarItemContainer>
          </InfoBarItem>
        }
        middle={
          <Container>
            <InfoBarItem
              data-test="contentStart"
              style={{ paddingRight: 0 }}
              className="creditDetailsSmall fullWidth"
            >
              {' '}
              <LabelCentered>
                <Label
                  helperLabel={progress >= 75 ? 'TU GASTO' : ''}
                  helperText={
                    <>
                      Si tu total gastado es más <br></br> alto que tu límite,
                      es <br></br>probable que te hayan <br></br>reducido el
                      límite.
                    </>
                  }
                  text="TU GASTO"
                  className="inline-helper"
                />
              </LabelCentered>
              <AmountBar>
                <AmountLabelContainer>
                  <AmountIndicator
                    color={progress >= 75 ? colors.deltai : colors.primary}
                  />
                  <AmountLabel
                    amount={setAmountFormat(userLimitsData.totalSpend)}
                    text="gastaste"
                  />
                </AmountLabelContainer>
                <AmountLabelContainer>
                  <AmountIndicator color={colors.primary4} />
                  <AmountLabel
                    amount={setAmountFormat(userLimitsData.totalAvailable)}
                    text="disponible"
                  />
                </AmountLabelContainer>
              </AmountBar>
              <InfoBarItem
                data-test="contentMiddle"
                style={{ paddingRight: 0 }}
              >
                <ProgressBar
                  progress={progress}
                  amount={''}
                  height="0.9rem"
                  progressClass={progress >= 75 ? 'progress-color' : ''}
                />
              </InfoBarItem>
            </InfoBarItem>
          </Container>
        }
        button={null}
      />
    </InfoBarWrapper>
  );
};

Component.propTypes = {};

const LimitBar = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="LimitBar"
    />
  );
};

export default LimitBar;
