import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const RowContainer = styled.div`
  width: 100%;
  height: 6rem;
  border-radius: 1.2rem;
  background-color: ${({ index }) =>
    (index % 2 !== 0 ? colors.carbon5 : 'white')};
  font-family: ${fonts.barlowMedium};
  font-weight: 400;
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  grid-template-columns: ${({ columns }) => columns};
  font-size: 16px;
  position: relative;
  text-align: center;

  &:hover {
    border-radius: 1.2rem;
    cursor: pointer;
    border: 1px solid;
    border-color: ${colors.blueBackground};
  }

  .redColor {
    color: ${colors.deltai};
  }

  @media screen and (max-width: 769px) {
    font-size: 12px;
  }
`;

export const Container = styled.div`
  min-height: 25rem;
  height: fit-content;
`;
