/* eslint-disable no-return-await */
import { customAxiosInstance } from './axiosConfig';

const companyMissingFiles = `/api/dashboard/company/kyc`;
const companyRejectedFiles = `/api/dashboard/company/kyc-validated-files`;
const personMissingFiles = `/api/dashboard/person/kyc`;
const personRejectedFiles = `/api/dashboard/person/kyc-validated-files`;

const timeout = process.env.REACT_APP_AXIOS_KYC_TIMEOUT;

export const getCompanyMissingFiles = async () => {
  return await customAxiosInstance().get(companyMissingFiles);
};

export const getCompanyRejectedFiles = async () => {
  return await customAxiosInstance().get(companyRejectedFiles);
};

export const getPersonMissingFiles = async () => {
  return await customAxiosInstance().get(personMissingFiles);
};

export const getPersonRejectedFiles = async () => {
  return await customAxiosInstance().get(personRejectedFiles);
};

export const postCompanyDocs = async body => {
  return await customAxiosInstance(timeout).post(companyMissingFiles, body);
};

export const postPersonDocs = async body => {
  return await customAxiosInstance(timeout).post(personMissingFiles, body);
};

export const transformKycMultipart = (name, file, kycDocId) => {
  const formData = new FormData();
  formData.append('file_name', name);
  formData.append('content', file);
  formData.append('kyc_document_type_id', kycDocId);
  return formData;
};
