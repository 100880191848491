import "./index.scss";

import React, {Component} from "react"

class Index extends Component{
    constructor({size = "default", style = {}, children}) {
        super(arguments[0]);
        this.size = size;
        this.style = style;
        this.children = children;
    }
    render(){
        const className = "body_text " + this.size;
        return(
            <>
                <span className={className} style={{...this.style}}>{this.children}</span>
            </>
        );
    }
}
export default Index;