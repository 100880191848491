import React, { useEffect, useState } from 'react';

import Entry from '../../../micro-frontends/activity/components/BeneficiaryEntry/Entry';
import LabelInfo from '../../../micro-frontends/shared/components/LabelInfo';

import {
  BeneficiaryContainer,
  ConfirmLabel,
  HeadContainer,
  TagsContainer
} from './TransactionForm.styles';

import { avatarColors } from '../../assets/styles/styles';
import { getInitials, parseAmount } from '../../../helpers/helpers';
import Icon from '../../../micro-frontends/shared/components/Icon/Icon';
import IconButton from '../../../micro-frontends/shared/components/IconButton/IconButton';
import TagList from '../../../micro-frontends/tags/TagList/TagList';

const HeadContent = ({
  beneficiary,
  transaction,
  confirm,
  changePage,
  tagsAdded
}) => {
  const [initials, setInitials] = useState('');

  useEffect(() => {
    if (beneficiary.beneficiary_full_name)
      setInitials(getInitials(beneficiary.beneficiary_full_name));
  }, [beneficiary]);

  return (
    <>
      <HeadContainer
        data-test="transactionHeadContent"
        data-testid="transactionHeadContent"
      >
        <BeneficiaryContainer>
          <div className="headerContentContainer">
            <Entry
              initials={initials}
              colors={avatarColors[0]}
              alias={beneficiary.beneficiary_alias}
              fullName={beneficiary.beneficiary_full_name}
              data-test="transactionEntryBeneficiary"
            />
            {!confirm && (
              <IconButton
                icon="pencil"
                onClick={e => changePage('editBeneficiary')}
              />
            )}
          </div>
          {confirm && (
            <ConfirmLabel className="labelsContainer">
              <span>{beneficiary.beneficiary_account_code}</span>
              <LabelInfo
                text={beneficiary.beneficiary_email}
                className="label"
              />
            </ConfirmLabel>
          )}
        </BeneficiaryContainer>

        {confirm ? (
          <Entry
            initials={
              <Icon
                className="avatarIconColor"
                icon="issued"
                width="1.2rem"
                height="1.2rem"
              />
            }
            colors={avatarColors[3]}
            alias={
              transaction && transaction.concept
                ? transaction.concept
                : 'No disponible'
            }
            fullName={
              <ConfirmLabel>
                <label>{parseAmount(transaction.amount)}</label>
                {transaction.reference_number ? (
                  <span className="alignEnd">
                    Ref. {transaction.reference_number}
                  </span>
                ) : null}
              </ConfirmLabel>
            }
            data-test="transactionEntryBeneficiary"
          />
        ) : null}
        {confirm ? (
          <TagsContainer>
            <TagList
              tags={tagsAdded}
              showAddNewTag={false}
              isDeleteVisible={false}
            />
          </TagsContainer>
        ) : null}
      </HeadContainer>
    </>
  );
};

export default HeadContent;
