import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as routes from './routes'
import CreditPage from './pages/Credit';

const Credit = () => {
  return (
    <Switch>
      <Route path={routes.creditPath} component={CreditPage} />
    </Switch>
  )
};

export default Credit;

