import { patterns_metadata } from '../../../catalogs/patterns';

//Yup is a JavaScript object schema validator and object parser check documentation for more details https://www.npmjs.com/package/yup
let yup = require('yup');

let fieldRequiredMessage = 'Campo requerido';
let minMaxCharactersMessage = 'Debe contener 13 caracteres';

export const validationSchema = yup
  .object({
    gender: yup.string('Selecciona un género').required(fieldRequiredMessage),
    birthDate: yup
      .date()
      .nullable()
      .required(fieldRequiredMessage)
      .min(new Date(1900, 0, 1), 'Fecha inválida'),
    federalEntity: yup.string().required(fieldRequiredMessage),
    countryOfBirth: yup
      .string('País de nacimiento')
      .required(fieldRequiredMessage),
    phoneNumber: yup
      .string()
      .length(10, 'Ingresa un número de 10 dígitos')
      .required(fieldRequiredMessage),
    curp: yup
      .string('')
      .length(18, 'Debes ingresar 18 dígitos')
      .matches(patterns_metadata.patternCurp, 'Ingresa un CURP válido')
      .required(fieldRequiredMessage),
    rfc: yup
      .string()
      .min(13, minMaxCharactersMessage)
      .max(13, minMaxCharactersMessage)
      .matches(patterns_metadata.patternRFC, 'Ingresa un RFC válido')
      .required(fieldRequiredMessage),
    occupation: yup.string().required(fieldRequiredMessage),
    nationality: yup.bool().oneOf([true], 'Field must be checked'),
    serialNumberFiel: yup.string().required(fieldRequiredMessage)
  })
  .shape();
