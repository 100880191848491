import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { AddOption } from './styles';

const Option = ({ data, innerRef, innerProps, selectProps, ...props }) => {
  return data.placeholder ? (
    <AddOption ref={innerRef} {...innerProps}>
      <i>Escriba para agregar...</i>
    </AddOption>
  ) : data.add ? (
    <AddOption ref={innerRef} {...innerProps}>
      + {data.typed} <span onClick={selectProps.onCreate}>Agregar</span>
    </AddOption>
  ) : (
    <components.Option
      data={data}
      innerRef={innerRef}
      innerProps={innerProps}
      selectProps={selectProps}
      {...props}
    />
  );
};

Option.propTypes = {
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
};

export default Option;
