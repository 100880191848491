import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  button: {
    textAlign: 'center',
    marginTop: '2.5rem'
  },
  form: {
    width: '36rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    margin: '0 auto'
  },
  formContainer: {
    margin: '0 auto'
  },
  instructions: {
    fontSize: '1.8rem',
    color: '#989898'
  },
  subtitle: {
    fontSize: '2.4rem',
    fontWeight: '600',
    color: '#323132'
  },
  textField: {
    width: '100%',
    marginBottom: '0'
  },
  header: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    margin: '0 auto'
  }
}));
