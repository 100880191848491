import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import i18next from '../../../i18n';

import { Alert } from '@material-ui/lab';
import {
  Grid,
  Container as MUIContainer,
  InputLabel,
  Select,
  FormHelperText,
  FormControl,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import Button from '../../shared/components/Button/Button';
import { downloadUrlFile } from '../../../helpers/fileManager';
import {
  Container,
  Code,
  InlineCode,
  Paragraph,
  Title,
  SectionTitle,
  ParagraphTitle
} from '../styles';
import TableComponent from './TableComponent';
import { Td, Tr } from '../styles';
import { getId } from '../helpers';
import { copyToClipboard } from '../../../helpers/helpers';
import { environmentValidationSchema } from '../validations';
import ApiKeysFormComponent from './ApiKeysFormComponent';
import {
  createNewApiKeys,
  deleteApiKeys,
  getApiKeys,
  updateApiKeys
} from '../service';

const useStyles = makeStyles({
  container: {
    marginBottom: '1rem'
  },
  configName: {
    paddingRight: 20,
    fontWeight: 'bold',
    textAlign: 'right'
  },
  configTable: {
    marginTop: 20,
    marginLeft: 20,
    borderSpacing: '8px'
  },
  alertContainer: {
    display: 'flex',
    marginTop: '30px',
    marginBottom: '20px'
  }
});

const Docs = React.forwardRef(
  ({ headers, documentation, python_sdk_url }, ref) => {
    const [APIKeys, setAPIKeys] = useState([]);
    const [tokens, setTokens] = useState(null);
    const [buttonText, setButtonText] = useState('Copy keys');
    const classes = useStyles();

    const copyData = tokens => {
      const data =
        `Access Token: ${tokens.api_key_access_token}\n` +
        `Token Secret: ${tokens.api_key_access_token_secret}`;
      copyToClipboard(data);
      handleCopyTokens();
    };

    const handleCopyTokens = () => {
      setButtonText('Copied');
      setTimeout(() => {
        setTokens(null);
      }, 1500);
      setTimeout(() => {
        setButtonText('Copy keys');
      }, 2000);
    };

    const getUrlFileName = fileUrl => {
      const domainParts = fileUrl.split('?');
      const nameParts = domainParts[0].split('/');
      return nameParts[nameParts.length - 1];
    };

    useEffect(() => {
      getApiKeys(setAPIKeys);
    }, []);

    return (
      <Container ref={ref}>
        <div>
          <Title id={'getting-started'}>
            <Translation>
              {t => t('public-api-docs-getting-started')}
            </Translation>
          </Title>
          <div>
            <SectionTitle id={'overview'}>
              <Translation>{t => t('public-api-docs-overview')}</Translation>
            </SectionTitle>

            <Paragraph>
              <Translation>{t => t('public-api-docs-overview-p1')}</Translation>
            </Paragraph>

            <SectionTitle id={'recommended-environment'}>
              <Translation>
                {t => t('public-api-docs-recommended-environment')}
              </Translation>
            </SectionTitle>

            <Paragraph>
              <Translation>
                {t => t('public-api-docs-recommended-environment-p-1')}
              </Translation>
            </Paragraph>

            <div className={classes.alertContainer}>
              <Alert variant="outlined" severity="info">
                <>
                  <Translation>
                    {t => t('public-api-docs-recommended-environment-info-1')}
                  </Translation>
                  <br />
                  <Translation>
                    {t => t('public-api-docs-recommended-environment-info-2')}
                  </Translation>
                </>
              </Alert>
            </div>

            <SectionTitle id={'install-prerequisites'}>
              <Translation>
                {t => t('public-api-docs-install-prerequisites')}
              </Translation>
            </SectionTitle>

            <Paragraph>
              <Translation>
                {t => t('public-api-docs-install-prerequisites-p-1')}
              </Translation>
            </Paragraph>

            <ParagraphTitle>1. Python 3.8+</ParagraphTitle>

            <Code>
              {'sudo apt-get update\n' +
                'sudo apt-get install python3.8\n' +
                'python3.8 --version'}
            </Code>

            <ParagraphTitle>2. gpg (GnuPG) 2.2+</ParagraphTitle>

            <Code>
              {'sudo apt-get update\n' +
                'sudo apt-get instal gpg\n' +
                'gpg --version\n'}
            </Code>
            <br />

            <SectionTitle id={'download-deltai-python-sdk'}>
              <Translation>
                {t => t('public-api-docs-download-python-sdk')}
              </Translation>
            </SectionTitle>

            <Paragraph>
              <Translation>
                {t => t('public-api-docs-download-python-sdk-p-1')}
              </Translation>
            </Paragraph>

            <br />
            <Button
              text={'Download Python SDK'}
              onClick={() =>
                downloadUrlFile(python_sdk_url, getUrlFileName(python_sdk_url))
              }
            />
            <br />
            <br />
            <br />

            <SectionTitle id={'install-deltai-python-sdk'}>
              <Translation>
                {t => t('public-api-docs-install-python-sdk')}
              </Translation>
            </SectionTitle>

            <Paragraph>
              <Translation>
                {t => t('public-api-docs-install-python-sdk-p-1')}
              </Translation>
            </Paragraph>

            <ParagraphTitle>
              <Translation>
                {t => t('public-api-docs-create-virtual-env')}
              </Translation>
            </ParagraphTitle>

            <Code>
              {'python3.8 -m venv ./venvironment\n' +
                'source venvironment/bin/activate\n'}
            </Code>

            <ParagraphTitle>
              <Translation>
                {t => t('public-api-docs-install-using-pip')}
              </Translation>
            </ParagraphTitle>

            <Code>{'pip install deltai_sdk-X.X.X.tar.gz'}</Code>

            <ParagraphTitle>
              <Translation>
                {t => t('public-api-docs-check-cli-version')}
              </Translation>
            </ParagraphTitle>

            <Code>{'deltai -h'}</Code>
          </div>

          <Title id={'make-requests'}>
            <Translation>{t => t('public-api-docs-make-requests')}</Translation>
          </Title>

          <div>
            <SectionTitle id={'create-api-keys'}>
              <Translation>
                {t => t('public-api-docs-create-api-keys')}
              </Translation>
            </SectionTitle>

            <ParagraphTitle>
              <Translation>{t => t('public-api-docs-init-sdk')}</Translation>
            </ParagraphTitle>

            <Paragraph>
              <Translation>
                {t => t('public-api-docs-init-sdk-p-1')}
              </Translation>
            </Paragraph>

            <Code>{'deltai --gpg-home  ~/deltai-sandbox --init'}</Code>

            <Paragraph>
              <Translation>
                {t => t('public-api-docs-init-sdk-p-2')}
              </Translation>
            </Paragraph>

            <Code>
              {'[INSTRUCTIONS]\n' +
                '...\n\n' +
                '[CUSTOMER_FINGERPRINT]\n' +
                '...\n\n' +
                '[CUSTOMER_PRIVATE_KEY_PASSPHRASE]\n' +
                '...\n\n' +
                '[CUSTOMER_PUBLIC_KEY]\n' +
                '-----BEGIN PGP PUBLIC KEY BLOCK-----\n' +
                '...\n' +
                '-----END PGP PUBLIC KEY BLOCK-----\n'}
            </Code>

            <div className={classes.alertContainer}>
              <Alert variant="outlined" severity="info">
                <>
                  <div>
                    <Translation>
                      {t => t('public-api-docs-init-sdk-info-1')}
                    </Translation>
                  </div>

                  <ul>
                    <li>CUSTOMER_PRIVATE_KEY_PASSPHRASE</li>
                    <li>CUSTOMER_FINGERPRINT</li>
                    <li>CUSTOMER_PUBLIC_KEY</li>
                  </ul>

                  <div>
                    <Translation>
                      {t => t('public-api-docs-init-sdk-info-2')}
                    </Translation>
                  </div>
                </>
              </Alert>
            </div>

            <ParagraphTitle>
              <Translation>
                {t => t('public-api-docs-create-api-key-dashboard')}
              </Translation>
            </ParagraphTitle>

            <Paragraph>
              <Translation>
                {t => t('public-api-docs-create-sandbox-key')}
              </Translation>
            </Paragraph>

            <div className={classes.alertContainer}>
              <Alert variant="outlined" severity="info">
                <>
                  <div>
                    <Translation>
                      {t => t('public-api-docs-create-sandbox-key-info-1')}
                    </Translation>
                  </div>

                  <ul>
                    <li>Access Token</li>
                    <li>Token Secret</li>
                  </ul>

                  <div>
                    <Translation>
                      {t => t('public-api-docs-init-sdk-info-2')}
                    </Translation>
                  </div>
                </>
              </Alert>
            </div>

            <SectionTitle id={'manage-api-keys'}>
              <Translation>{t => t('public-api-docs-manage-keys')}</Translation>
            </SectionTitle>

            <Formik
              initialValues={{
                environment: 'sandbox'
              }}
              validationSchema={environmentValidationSchema}
              onSubmit={({ environment }) =>
                createNewApiKeys(environment, setAPIKeys, setTokens)
              }
            >
              {({ handleChange, handleSubmit, errors, touched, values }) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    style={{ minWidth: '25rem' }}
                    text={'Generate API Key'}
                    onClick={handleSubmit}
                    size="medium"
                  />
                  <FormControl
                    style={{ flex: 1, height: '100%', marginLeft: '20px' }}
                    variant="outlined"
                    size="medium"
                    fullWidth
                  >
                    <InputLabel id="environment-outlined-label">
                      Environment
                    </InputLabel>
                    <Select
                      style={{ height: '60px' }}
                      size="md"
                      labelId="environment-outlined-label"
                      id="environment-outlined"
                      value={values.environment}
                      onChange={handleChange('environment')}
                      label="Environment"
                      variant="outlined"
                      error={errors.environment && touched.environment}
                    >
                      <MenuItem value={'sandbox'}>Sandbox</MenuItem>
                      <MenuItem value={'live'}>Live</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors.environment && touched.environment
                        ? errors.environment
                        : ''}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
            </Formik>

            {tokens && (
              <div className={classes.alertContainer}>
                <Alert variant="outlined" severity="info">
                  <>
                    <br />
                    <div>
                      Save the values below, as this is the <b>ONLY</b> time you
                      <br />
                      will have access to the <b>Access Token</b> and{' '}
                      <b>Token Secret</b>
                    </div>

                    <br />

                    <div>
                      <b>Access Token</b>
                      <br />
                      {tokens.api_key_access_token} <br />
                      <br />
                      <b>Token Secret</b>
                      <br />
                      {tokens.api_key_access_token_secret} <br />
                      <br />
                    </div>

                    <div
                      style={{
                        width: '100%'
                      }}
                    >
                      <Button
                        success={buttonText === 'Copied'}
                        text={buttonText}
                        data-test="copyButton"
                        onClick={() => copyData(tokens)}
                      />
                    </div>
                    <br />
                  </>
                </Alert>
              </div>
            )}

            {APIKeys && APIKeys.length > 0 && (
              <ApiKeysFormComponent
                headers={[
                  'Environment',
                  'IP Whitelist',
                  'Fingerprint',
                  'Public Key',
                  'Action'
                ]}
                APIKeys={APIKeys}
                handleDelete={body => deleteApiKeys(body, setAPIKeys)}
                handleSubmit={body => updateApiKeys(body, setAPIKeys)}
              />
            )}

            <ParagraphTitle>
              <Translation>{t => t('public-api-docs-update-keys')}</Translation>
            </ParagraphTitle>

            <Paragraph>
              <Translation>
                {t => t('public-api-docs-update-keys-p-1')}
              </Translation>
            </Paragraph>

            <div className={classes.alertContainer}>
              <Alert variant="outlined" severity="info">
                <>
                  <div>
                    <Translation>
                      {t => t('public-api-docs-config-vars-p-1')}
                    </Translation>
                  </div>

                  <table className={classes.configTable}>
                    <tbody>
                      <tr>
                        <td className={classes.configName}>Public Key</td>
                        <td>
                          <Translation>
                            {t => t('public-api-docs-config-description-1')}
                          </Translation>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.configName}>Fingerprint</td>
                        <td>
                          <Translation>
                            {t => t('public-api-docs-config-description-2')}
                          </Translation>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.configName}>
                          IP&nbsp;Whitelist
                        </td>
                        <td>
                          <Translation>
                            {t => t('public-api-docs-config-description-3')}
                          </Translation>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              </Alert>
            </div>

            <SectionTitle id={'environment-variables'}>
              <Translation>{t => t('public-api-docs-env-vars')}</Translation>
            </SectionTitle>

            <ParagraphTitle>
              <Translation>
                {t => t('public-api-docs-env-vars-conf')}
              </Translation>
            </ParagraphTitle>

            <Paragraph>
              <Translation>
                {t => t('public-api-docs-env-vars-p-1')}
              </Translation>
            </Paragraph>

            <TableComponent>
              <Tr>
                <Td>DELTAI_ENV</Td>
                <Td>
                  <Translation>
                    {t => t('public-api-docs-env-vars-desc-1')}
                  </Translation>
                </Td>
              </Tr>
              <Tr>
                <Td>DELTAI_ACCESS_TOKEN</Td>
                <Td>
                  <Translation>
                    {t => t('public-api-docs-env-vars-desc-2')}
                  </Translation>
                </Td>
              </Tr>
              <Tr>
                <Td>DELTAI_TOKEN_SECRET</Td>
                <Td>
                  <Translation>
                    {t => t('public-api-docs-env-vars-desc-3')}
                  </Translation>
                </Td>
              </Tr>
              <Tr>
                <Td>DELTAI_GPG_HOME</Td>
                <Td>
                  <Translation>
                    {t => t('public-api-docs-env-vars-desc-4')}
                  </Translation>
                </Td>
              </Tr>
              <Tr>
                <Td>DELTAI_PRIVATE_KEY_PASSPHRASE</Td>
                <Td>
                  <Translation>
                    {t => t('public-api-docs-env-vars-desc-5')}
                  </Translation>
                </Td>
              </Tr>
              <Tr>
                <Td>DELTAI_PRIVATE_KEY_FINGERPRINT</Td>
                <Td>
                  <Translation>
                    {t => t('public-api-docs-env-vars-desc-6')}
                  </Translation>
                </Td>
              </Tr>
              <Tr>
                <Td>DELTAI_API_HOST</Td>
                <Td>
                  <Translation>
                    {t => t('public-api-docs-env-vars-desc-7')}
                  </Translation>
                </Td>
              </Tr>
              <Tr>
                <Td>DELTAI_X_API_KEY</Td>
                <Td>
                  <Translation>
                    {t => t('public-api-docs-env-vars-desc-8')}
                  </Translation>
                </Td>
              </Tr>
            </TableComponent>
          </div>
        </div>

        {documentation ? (
          headers.map((head_el, head_ix) => (
            <div key={`head-${getId(head_el)}`}>
              <Title id={getId(head_el)}>{head_el}</Title>

              {documentation[head_el].map((el, ix) => (
                <div key={`section-${getId(el.request_title)}`}>
                  <SectionTitle id={getId(el.request_title)}>
                    <Translation>{t => t(el.request_title)}</Translation>
                  </SectionTitle>

                  <Paragraph>
                    <Translation>{t => t(el.request_description)}</Translation>
                  </Paragraph>

                  <ParagraphTitle>
                    <Translation>
                      {t => t('public-api-docs-request-data-title')}
                    </Translation>
                  </ParagraphTitle>

                  <TableComponent array={el.request_body} />

                  <ParagraphTitle>
                    <Translation>
                      {t => t('public-api-docs-sample-request-title')}
                    </Translation>
                  </ParagraphTitle>

                  <Code>{el.python_request}</Code>

                  <ParagraphTitle>
                    <Translation>
                      {t => t('public-api-docs-response-format-title')}
                    </Translation>
                  </ParagraphTitle>

                  <TableComponent array={el.response_format} />

                  <ParagraphTitle>
                    <Translation>
                      {t => t('public-api-docs-sample-response-title')}
                    </Translation>
                  </ParagraphTitle>

                  <Code>{JSON.stringify(el.response_json, null, 2)}</Code>
                </div>
              ))}
            </div>
          ))
        ) : (
          <></>
        )}
      </Container>
    );
  }
);

Docs.displayName = 'Docs';

export default Docs;
