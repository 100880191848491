import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { cardChangeRequestPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const getCardChangeRequestService = async ({ cardId }) => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      `${cardChangeRequestPath}${cardId}`
    );

    if (status === 200 || status === 201) {
      log.debug('card-change-request-service', status, data);
      // TODO Unorthodox behaviour. Probably should be error from BE instead
      return data[0] ? { data: data[0] } : { error: data };
    }
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'card-change-request-service'
    });
  } catch (error) {
    log.error('card-change-request-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
