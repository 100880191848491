import React from 'react';

import CreatePasswordPage from '../../../sub-account-onboarding/pages/RegistrationPage/CreatePasswordPage';

import { service } from '../../service';

const ResetPasswordPage = () => {
  return <CreatePasswordPage resetPasswordService={service().resetPassword} />;
};

export default ResetPasswordPage;
