/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// styled
import { Container, DateLabel, DateLabelContainer } from './SideBar.styles';

// Components
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';
import Label from '../../../shared/components/Label';

export const LabelInfoDate = ({ date: { day, month }, className }) => {
  return (
    <DateLabelContainer className={className}>
      <DateLabel text={`${day} de ${month}`} />
    </DateLabelContainer>
  );
};

export const Component = ({ statementClosingDate, paymentDeadLine }) => {
  return (
    <Container>
      <Label text="PRÓXIMA FECHA DE CORTE" />
      <LabelInfoDate
        date={statementClosingDate}
        className="label_info-margin"
      />
      <Label text="FECHA LÍMITE DE PAGO" className="label-topMargin" />
      <LabelInfoDate date={paymentDeadLine} className="label_info-margin" />
      <Label
        text="Para no incurrir en cargos adicionales"
        className="label-content"
      />
      <br />
      <Label
        text="DÍAS INHÁBILES"
        helperText="Cuando la fecha de pago coincide con un día inhábil, esta se recorre automáticamente  al siguiente día hábil"
        helperLabel="DÍAS INHÁBILES"
        className="label-topMargin"
      />
    </Container>
  );
};

Component.propTypes = {
  statementClosingDate: PropTypes.object.isRequired,
  paymentDeadLine: PropTypes.object.isRequired
};

const SideBar = props => {
  return (
    <ErrorBoundaryHandler component={<Component {...props} />} name="SideBar" />
  );
};

export default SideBar;
