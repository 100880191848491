import { setTagsCreditCardTransactionAction } from '../redux/tag-action';
import { TagsCreator } from '../domain/tagData';

const getCardTransactionTagsHandler = ({
  dispatch,
  getCardTransactionTags
}) => async ({ cardId, transactionId }) => {
  const { error, data } = await getCardTransactionTags({
    cardId,
    transactionId
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  const tags = TagsCreator({ data });

  dispatch(setTagsCreditCardTransactionAction({ tags }));

  return { isSuccess: true };
};

export { getCardTransactionTagsHandler };
