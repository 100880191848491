/* eslint-disable no-dupe-keys */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// see https://react.i18next.com/guides/multiple-translation-files
const resources = {
  en: {
    translation: {
      'public-api-docs-check-cli-version': '3. Check delt.ai CLI version',
      'public-api-docs-common-response-parameter-description-body':
        'Request details if successful (the contents are listed below)',
      'public-api-docs-common-response-parameter-description-message':
        'Text description of the request status',
      'public-api-docs-common-response-parameter-description-tracking-id':
        'API request tracking Identifier',
      'public-api-docs-config-description-1':
        'Cryptographic key created in previous step',
      'public-api-docs-config-description-2':
        'Identifier for cryptographic key pair',
      'public-api-docs-config-description-3': `Approved Origin IPs that can use this API Key.  You can use 0.0.0.0 to allow requests from anywhere when configuring Sandbox keys, but this is not allowed for keys to the Live environment`,
      'public-api-docs-config-vars-p-1': `The following values are used as required configuration data when making requests to the delt.ai API.`,
      'public-api-docs-create-api-key-dashboard': `2. Create an API Key`,
      'public-api-docs-create-api-keys': 'Create API Keys',
      'public-api-docs-create-payment-beneficiary-request-description':
        'Create a Payment Beneficiary',
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-account-code':
        'Beneficiary clabe account number',
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-account-type':
        "Beneficiary's account type",
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-alias':
        "Beneficiary's alias",
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-email':
        "Beneficiary's email address",
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-full-name':
        'The full name of the beneficiary',
      'public-api-docs-create-payment-beneficiary-request-title':
        'Create Payment Beneficiary',
      'public-api-docs-create-payment-beneficiary-response-parameter-description-beneficiary-id':
        'The indentifier of the payment account beneficiary',
      'public-api-docs-create-payment-order-request-description':
        'Create a single Payment Order',
      'public-api-docs-create-payment-order-request-parameter-amount':
        'Payment amount',
      'public-api-docs-create-payment-order-request-parameter-beneficiary-id':
        'Beneficiary payment account indentifier',
      'public-api-docs-create-payment-order-request-parameter-payment-description':
        'Description text for payment',
      'public-api-docs-create-payment-order-request-title':
        'Create Payment Order',
      'public-api-docs-create-payment-order-response-parameter-reference-number':
        'Payment order reference number',
      'public-api-docs-create-payment-order-response-parameter-timestamp':
        'Time payment order was created (Central Time)',
      'public-api-docs-create-payment-order-response-parameter-tracking-key':
        'Payment order tracking key',
      'public-api-docs-create-payment-order-response-parameter-transaction-id':
        'Internal delt.ai transaction identifier',
      'public-api-docs-create-sandbox-key': `Create a Sandbox API Key in the section below`,
      'public-api-docs-create-sandbox-key-info-1': `The following values are used as required configuration data when making requests to the delt.ai API.`,
      'public-api-docs-create-virtual-env': '1. Create a Virtual Environment',
      'public-api-docs-delete-payment-beneficiary-request-description':
        'Delete a Payment Beneficiary',
      'public-api-docs-delete-payment-beneficiary-request-title':
        'Delete Payment Beneficiary',
      'public-api-docs-delete-payment-beneficiary-response-parameter-beneficiary-id':
        'Identifier associated with the beneficiary',
      'public-api-docs-download-deltai-python-sdk':
        'Download delt.ai Python SDK',
      'public-api-docs-download-python-sdk': 'Download delt.ai Python SDK',
      'public-api-docs-download-python-sdk-p-1':
        'Use the link below to download the delt.ai Python SDK',
      'public-api-docs-env-vars': 'Environment Variables',
      'public-api-docs-env-vars-conf': `1. Configure delt.ai SDK Environment Variables`,
      'public-api-docs-env-vars-desc-1': 'Either sandbox or live',
      'public-api-docs-env-vars-desc-2':
        'Access Token created in previous step',
      'public-api-docs-env-vars-desc-3':
        'Token Secret created in previous step',
      'public-api-docs-env-vars-desc-4':
        'Directory used for --gpg-home in the --init step',
      'public-api-docs-env-vars-desc-5':
        'Passphrase created in the --init step',
      'public-api-docs-env-vars-desc-6':
        'Fingerprint created in the --init step',
      'public-api-docs-env-vars-desc-7': 'https://sandbox.delt.ai',
      'public-api-docs-env-vars-desc-8': '1CCtPnjYeMZcUcZoKKgBBrcpIyUZMHw5',
      'public-api-docs-env-vars-p-1': `You must configure the following environment variable when using the delt.ai SDK to make API requests`,
      'public-api-docs-environment-variables': 'Environment Variables',
      'public-api-docs-get-all-payment-beneficiaries-request-description':
        'Get all Payment Beneficiaries',
      'public-api-docs-get-all-payment-beneficiaries-request-title':
        'Get All Payment Beneficiaries',
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-banking-institution-id':
        'Banking Institution indentifier',
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-account-code':
        'Banking account code for the beneficiary',
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-account-type':
        'Banking account type for the beneficiary',
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-alias':
        'Alias of the beneficary',
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-banking-institution-name':
        'Banking Institution name',
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-email':
        'Email of the beneficary',
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-full-name':
        'Full name of the beneficiary',
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-id':
        'Beneficiary payment account indentifier',
      'public-api-docs-get-banking-institution-details-request-description':
        'Get the Banking Institution details',
      'public-api-docs-get-banking-institution-details-request-title':
        'Get Banking Institution Details',
      'public-api-docs-get-banking-institution-details-response-parameter-description-id':
        'Banking Institution indentifier',
      'public-api-docs-get-banking-institution-details-response-parameter-description-name':
        'Banking Institution name',
      'public-api-docs-get-banking-institution-details-response-parameter-description-prefix':
        '3 digit prefix as string',
      'public-api-docs-get-banking-institution-details-response-parameter-description-prefix':
        'Banking account number prefix',
      'public-api-docs-get-banking-institutions-request-description':
        'Get a list of all supported Banking Institutions',
      'public-api-docs-get-banking-institutions-request-title':
        'Get Banking Institutions',
      'public-api-docs-get-banking-institutions-response-parameter-description-id':
        'Banking Institution indentifier',
      'public-api-docs-get-banking-institutions-response-parameter-description-name':
        'Banking Institution name',
      'public-api-docs-get-banking-institutions-response-parameter-description-prefix':
        'Banking account number prefix',
      'public-api-docs-get-payment-order-by-tracking-key-request-description':
        'Get a Payment Order using its tracking key',
      'public-api-docs-get-payment-order-by-tracking-key-request-parameter-tracking-key':
        'Tracking Key associated with the payment order',
      'public-api-docs-get-payment-order-by-tracking-key-request-title':
        'Get Payment Order By Tracking Key',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-amount':
        'Transaction amount',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-account-type-id':
        'Identifier for beneficiary account type',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-alias':
        'Alias of the beneficiary',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-banking-institution':
        'Banking institution for the beneficiary',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-card-number':
        'Card number of beneficiary, typically null',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-clabe-code':
        'Clabe code of the Payment Beneficiary',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-email':
        'Email address of the beneficiary',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-full-name':
        'Name of the beneficiary',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-description':
        'Text description of transaction',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-reference-number':
        'Transaction reference number',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-sender-banking-institution':
        'Banking institution for the sender',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-sender-clabe-code':
        'Clabe code of payment sender',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-sender-name':
        'Name of the sender',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-status':
        'Payment status (One of pending, approved or rejected). The default status is pending, and this is first sent in the response until the payment process is completed and notified by the webhook to approved or rejected',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-tracking-key':
        'Payment order tracking key',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-transaction-datetime':
        'Time payment order was created',
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-transaction-type-id':
        'Identifier for the transaction type',
      'public-api-docs-getting-started': 'Getting Started',
      'public-api-docs-init-sdk': '1. Initialize the delt.ai SDK',
      'public-api-docs-init-sdk-info-1': `The following values are used as required configuration data when making requests to the delt.ai API.`,
      'public-api-docs-init-sdk-info-2': `You must save these values for later use.`,
      'public-api-docs-init-sdk-p-1': `Use the delt.ai CLI to initialize a home directory for the delt.ai SDK.  This directory will contain the cryptographic keys required for communicating with the delt.ai API. You can choose any directory path, so long as the directory does not already exist.`,
      'public-api-docs-init-sdk-p-2': `This command will output the Passphrase and Public Key associated with your integration, see the example below. Save the output values, as they will be used in the configuration sections below.`,
      'public-api-docs-install-deltai-python-sdk': 'Install delt.ai Python SDK',
      'public-api-docs-install-prerequisites': 'Install Prerequisites',
      'public-api-docs-install-prerequisites-p-1': `In order to use the delt.ai SDK, you will first need to install the following prerequisites:`,
      'public-api-docs-install-python-sdk': 'Install delt.ai Python SDK',
      'public-api-docs-install-python-sdk-p-1':
        "Now we're ready to install the delt.ai Python SDK",
      'public-api-docs-install-using-pip': '2. Install using pip',
      'public-api-docs-make-requests': 'Make Requests',
      'public-api-docs-manage-api-keys': 'Manage API Keys',
      'public-api-docs-manage-keys': 'Manage API Keys',
      'public-api-docs-overview': 'Overview',
      'public-api-docs-overview-p1': `The delt.ai Python SDK contains the delt.ai CLI which can be used to automate the configuration of your integration with the delt.ai API.  The delt.ai CLI will initialize your environment and create the cryptographic keys required to communicate with the delt.ai API.  Even if you decide to integrate using a different language, such as NodeJS, you should still use the delt.ai CLI to initialize your environment.`,
      'public-api-docs-payload-description': 'Description',
      'public-api-docs-payload-parameter': 'Parameter',
      'public-api-docs-recommended-environment': 'Recommended Environment',
      'public-api-docs-recommended-environment-info-1': `We can provide you with a Docker script for initializing a supported runtime.`,
      'public-api-docs-recommended-environment-info-2': `Please reach out to Customer Support if you're interested in this option.`,
      'public-api-docs-recommended-environment-p-1': `The delt.ai Python SDK is tested using Debian 10+ and Ubuntu 20+. Other Unix-like systems such as macOS may also work, but are not officially supported.  If you require compatibility with a different system please reach out to Customer Support for assistance.`,
      'public-api-docs-request-data-title': 'Request Data',
      'public-api-docs-response-format-title': 'Response Format',
      'public-api-docs-sample-request-title': 'Sample Request',
      'public-api-docs-sample-response-title': 'Sample Response',
      'public-api-docs-update-keys': '3. Update the API Key',
      'public-api-docs-update-keys-p-1': `Update the API Key configuration with the output from previous steps`,
      'public-api-docs-update-payment-beneficiary-request-description':
        'Update a Payment Beneficiary',
      'public-api-docs-update-payment-beneficiary-request-parameter-banking-institution-id':
        'Banking Institution identifier',
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-account-code':
        'Beneficiary clabe account number',
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-account-type':
        "Beneficiary's account type",
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-alias':
        "Beneficiary's alias",
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-email':
        "Beneficiary's email address",
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-full-name':
        'The full name of the beneficiary',
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-id':
        'Beneficiary identifier',
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-rfc':
        'Beneficiary RFC',
      'public-api-docs-update-payment-beneficiary-request-title':
        'Update Payment Beneficiary',
      'public-api-docs-update-payment-beneficiary-response-parameter-description-beneficiary-id':
        'The indentifier of the payment account beneficiary'
    }
  },
  es: {
    translation: {
      'public-api-docs-check-cli-version': `3. Verifique la versión CLI de delt.ai`,
      'public-api-docs-common-response-parameter-description-body': `Solicitar detalles si tiene éxito (los contenidos se enumeran a continuación)`,
      'public-api-docs-common-response-parameter-description-message': `Texto descriptivo del estado de la solicitud`,
      'public-api-docs-common-response-parameter-description-tracking-id': `Identificador de seguimiento de solicitud de API`,
      'public-api-docs-config-description-1': `Clave criptográfica creada en el paso anterior`,
      'public-api-docs-config-description-2': `Identificador para par de claves criptográficas`,
      'public-api-docs-config-description-3': `IP de origen aprobadas que pueden usar esta clave de API. Puedes usar 0.0.0.0 para permitir solicitudes desde cualquier lugar al configurar Claves de sandbox, pero esto no está permitido para las claves del entorno Live`,
      'public-api-docs-config-vars-p-1': `Los siguientes valores se utilizan como datos de configuración necesarios cuando realizar solicitudes a la API delt.ai.`,
      'public-api-docs-create-api-key-dashboard': `2. Crea una clave API`,
      'public-api-docs-create-api-keys': `Crear claves API`,
      'public-api-docs-create-payment-beneficiary-request-description': `Crear un Beneficiario de Pago`,
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-account-code': `Número de cuenta de clabe beneficiario`,
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-account-type': `Tipo de cuenta del beneficiario`,
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-alias': `Alias del beneficiario`,
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-email': `Dirección de correo electrónico del beneficiario`,
      'public-api-docs-create-payment-beneficiary-request-parameter-description-beneficiary-full-name': `El nombre completo del beneficiario`,
      'public-api-docs-create-payment-beneficiary-request-title': `Crear beneficiario de pago`,
      'public-api-docs-create-payment-beneficiary-response-parameter-description-beneficiary-id': `El identificador del beneficiario de la cuenta de pago`,
      'public-api-docs-create-payment-order-request-description': `Crear una orden de pago única`,
      'public-api-docs-create-payment-order-request-parameter-amount': `Monto del pago`,
      'public-api-docs-create-payment-order-request-parameter-beneficiary-id': `Identificador de cuenta de pago del beneficiario`,
      'public-api-docs-create-payment-order-request-parameter-payment-description': `Texto de descripción para el pago`,
      'public-api-docs-create-payment-order-request-title': `Crear orden de pago`,
      'public-api-docs-create-payment-order-response-parameter-reference-number': `Número de referencia de la orden de pago`,
      'public-api-docs-create-payment-order-response-parameter-timestamp': `Hora en que se creó la orden de pago (hora central)`,
      'public-api-docs-create-payment-order-response-parameter-tracking-key': `Clave de seguimiento de orden de pago`,
      'public-api-docs-create-payment-order-response-parameter-transaction-id': `Identificador interno de transacción delt.ai`,
      'public-api-docs-create-sandbox-key': `Cree una clave API de Sandbox en la sección a continuación`,
      'public-api-docs-create-sandbox-key-info-1': `Los siguientes valores se utilizan como datos de configuración necesarios al realizar solicitudes a la API delt.ai.`,
      'public-api-docs-create-virtual-env': `1. Crear un entorno virtual`,
      'public-api-docs-delete-payment-beneficiary-request-description': `Eliminar un beneficiario de pago`,
      'public-api-docs-delete-payment-beneficiary-request-title': `Eliminar beneficiario de pago`,
      'public-api-docs-delete-payment-beneficiary-response-parameter-beneficiary-id': `Identificador asociado al beneficiario`,
      'public-api-docs-download-deltai-python-sdk': `Descargar delt.ai Python SDK`,
      'public-api-docs-download-python-sdk': `Descargar delt.ai Python SDK`,
      'public-api-docs-download-python-sdk-p-1': `Use el siguiente enlace para descargar el SDK de Python delt.ai`,
      'public-api-docs-env-vars': `Variables de entorno`,
      'public-api-docs-env-vars-conf': `1. Configure las variables de entorno del SDK delt.ai`,
      'public-api-docs-env-vars-desc-1': `Ya sea sandbox o en vivo`,
      'public-api-docs-env-vars-desc-2': `Token de acceso creado en el paso anterior`,
      'public-api-docs-env-vars-desc-3': `Token Secret creado en el paso anterior`,
      'public-api-docs-env-vars-desc-4': `Directorio utilizado para --gpg-home en el paso --init`,
      'public-api-docs-env-vars-desc-5': `Frase de contraseña creada en el paso --init`,
      'public-api-docs-env-vars-desc-6': `Huella digital creada en el paso --init`,
      'public-api-docs-env-vars-desc-7': `https://sandbox.delt.ai`,
      'public-api-docs-env-vars-desc-8': `1CCtPnjYeMZcUcZoKKgBBrcpIyUZMHw5`,
      'public-api-docs-env-vars-p-1': `Debe configurar la siguiente variable de entorno cuando usando el SDK delt.ai para hacer solicitudes de API`,
      'public-api-docs-environment-variables': `Variables de entorno`,
      'public-api-docs-get-all-payment-beneficiaries-request-description': `Obtener todos los Beneficiarios de Pago`,
      'public-api-docs-get-all-payment-beneficiaries-request-title': `Obtener todos los beneficiarios de pago`,
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-banking-institution-id': `Identificador de Institución Bancaria`,
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-account-code': `Código de cuenta bancaria del beneficiario`,
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-account-type': `Tipo de cuenta bancaria del beneficiario`,
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-alias': `Alias del beneficiario`,
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-banking-institution-name': `Nombre de la institución bancaria`,
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-email': `Email del beneficiario`,
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-full-name': `Nombre completo del beneficiario`,
      'public-api-docs-get-all-payment-beneficiaries-response-parameter-beneficiary-id': `Identificador de cuenta de pago del beneficiario`,
      'public-api-docs-get-banking-institution-details-request-description': `Obtener los datos de la Institución Bancaria`,
      'public-api-docs-get-banking-institution-details-request-title': `Obtener detalles de la institución bancaria`,
      'public-api-docs-get-banking-institution-details-response-parameter-description-id': `Identificador de Institución Bancaria`,
      'public-api-docs-get-banking-institution-details-response-parameter-description-name': `Nombre de la institución bancaria`,
      'public-api-docs-get-banking-institution-details-response-parameter-description-prefix': `Prefijo de número de cuenta bancaria`,
      'public-api-docs-get-banking-institutions-request-description': `Obtenga una lista de todas las instituciones bancarias admitidas`,
      'public-api-docs-get-banking-institutions-request-title': `Obtener Instituciones Bancarias`,
      'public-api-docs-get-banking-institutions-response-parameter-description-id': `Identificador de Institución Bancaria`,
      'public-api-docs-get-banking-institutions-response-parameter-description-name': `Nombre de la institución bancaria`,
      'public-api-docs-get-banking-institutions-response-parameter-description-prefix': `Prefijo de número de cuenta bancaria`,
      'public-api-docs-get-payment-order-by-tracking-key-request-description': `Obtener una orden de pago utilizando su clave de seguimiento`,
      'public-api-docs-get-payment-order-by-tracking-key-request-parameter-tracking-key': `Clave de seguimiento asociada a la orden de pago`,
      'public-api-docs-get-payment-order-by-tracking-key-request-title': `Obtener orden de pago mediante clave de seguimiento`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-amount': `Cantidad de transacción`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-account-type-id': `Identificador del tipo de cuenta del beneficiario`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-alias': `Alias del beneficiario`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-banking-institution': `Institución bancaria del beneficiario`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-card-number': `Número de tarjeta del beneficiario, normalmente nulo`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-clabe-code': `Clabe código del Beneficiario del Pago`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-email': `Dirección de correo electrónico del beneficiario`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-beneficiary-full-name': `Nombre del beneficiario`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-description': `Descripción de texto de la transacción`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-reference-number': `Número de Referencia de la Transacción`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-sender-banking-institution': `Entidad bancaria del remitente`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-sender-clabe-code': `Clabe código del remitente del pago`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-sender-name': `Nombre del remitente`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-status': `Estado del pago (Uno de pendiente, aprobado o rechazado). El estado predeterminado es pendiente, y esto se envía primero en la respuesta hasta que el proceso de pago se completa y el webhook lo notifica como aprobado o rechazado.`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-tracking-key': `Clave de seguimiento de orden de pago`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-transaction-datetime': `Hora de creación de la orden de pago`,
      'public-api-docs-get-payment-order-by-tracking-key-response-parameter-transaction-type-id': `Identificador del tipo de transacción`,
      'public-api-docs-getting-started': `Empezando`,
      'public-api-docs-init-sdk': `1. Inicializar el SDK delt.ai`,
      'public-api-docs-init-sdk-info-1': `Los siguientes valores se utilizan como datos de configuración necesarios al realizar solicitudes a la API delt.ai.`,
      'public-api-docs-init-sdk-info-2': `Debe guardar estos valores para su uso posterior.`,
      'public-api-docs-init-sdk-p-1': `Use la CLI delt.ai para inicializar un directorio de inicio para el SDK delt.ai. Este directorio contendrá el claves criptográficas requeridas para comunicarse con la API delt.ai. Puede elegir cualquier ruta de directorio, siempre que el directorio no ya existe.`,
      'public-api-docs-init-sdk-p-2': `Este comando generará la frase de contraseña y el público Clave asociada con su integración, vea el ejemplo a continuación. Guarde los valores de salida, ya que se utilizarán en la configuración secciones a continuación.`,
      'public-api-docs-install-deltai-python-sdk': `Instale el SDK de Python delt.ai`,
      'public-api-docs-install-prerequisites': `Requisitos previos de instalación`,
      'public-api-docs-install-prerequisites-p-1': `Para utilizar el SDK de delt.ai, primero deberá instalar los siguientes requisitos previos:`,
      'public-api-docs-install-python-sdk': `Instale el SDK de Python delt.ai`,
      'public-api-docs-install-python-sdk-p-1': `Ahora estamos listos para instalar el SDK de Python delt.ai`,
      'public-api-docs-install-using-pip': `2. Instalar usando pip`,
      'public-api-docs-make-requests': `Hacer peticiones`,
      'public-api-docs-manage-api-keys': `Administrar claves API`,
      'public-api-docs-manage-keys': `Administrar claves API`,
      'public-api-docs-overview': `Visión general`,
      'public-api-docs-overview-p1': `El SDK de Python delt.ai contiene la CLI delt.ai que se puede utilizar para automatizar la configuración de su integración con la API delt.ai. La CLI delt.ai inicialice su entorno y cree las claves criptográficas necesario para comunicarse con la API delt.ai. Incluso si tú decide integrarse usando un lenguaje diferente, como NodeJS, aún debe usar la CLI delt.ai para inicialice su entorno.`,
      'public-api-docs-payload-description': `Descripción`,
      'public-api-docs-payload-parameter': `Parámetro`,
      'public-api-docs-recommended-environment': `Entorno recomendado`,
      'public-api-docs-recommended-environment-info-1': `Podemos proporcionarle un script de Docker para inicializar un tiempo de ejecución compatible.`,
      'public-api-docs-recommended-environment-info-2': `Comuníquese con Atención al cliente si está interesado en esta opción.`,
      'public-api-docs-recommended-environment-p-1': `El SDK de Python delt.ai se prueba con Debian 10+ y Ubuntu 20+. Otros sistemas similares a Unix, como macOS, también pueden funcionar, pero son no soportado oficialmente. Si necesita compatibilidad con otro comuníquese con Atención al cliente para obtener ayuda.`,
      'public-api-docs-request-data-title': `Solicitar datos`,
      'public-api-docs-response-format-title': `Formato de respuesta`,
      'public-api-docs-sample-request-title': `Solicitud de muestra`,
      'public-api-docs-sample-response-title': `Respuesta de muestra`,
      'public-api-docs-update-keys': `3. Actualice la clave API`,
      'public-api-docs-update-keys-p-1': `Actualice la configuración de la clave API con el resultado de los pasos anteriores`,
      'public-api-docs-update-payment-beneficiary-request-description': `Actualizar un beneficiario de pago`,
      'public-api-docs-update-payment-beneficiary-request-parameter-banking-institution-id': `Identificador de la Institución Bancaria`,
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-account-code': `Número de cuenta de clabe beneficiario`,
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-account-type': `Tipo de cuenta del beneficiario`,
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-alias': `Alias del beneficiario`,
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-email': `Dirección de correo electrónico del beneficiario`,
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-full-name': `El nombre completo del beneficiario`,
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-id': `Identificador de beneficiario`,
      'public-api-docs-update-payment-beneficiary-request-parameter-beneficiary-rfc': `Beneficiario RFC`,
      'public-api-docs-update-payment-beneficiary-request-title': `Actualizar beneficiario de pago`,
      'public-api-docs-update-payment-beneficiary-response-parameter-description-beneficiary-id': `El identificador del beneficiario de la cuenta de pago`
    }
  }
};

// https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // change with i18n.changeLanguage
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
