// TODO: Validate or replace it with a more "readable" expression
const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateBeneficiaries = rows => {
  const headers = rows[0];
  const parsedRows = rows.map(row => row.map(el => el.trim()));
  const beneficiariesDuplicates = [headers];
  const beneficiariesErrors = [headers];

  const beneficiariesSuccess = parsedRows.filter((row, index, self) => {
    if (index === 0) return row;

    // TODO: Set this validations as a subset of the 7-columns CSV file ones;
    // they're the same fields
    if (
      index > 0 &&
      !row.includes('') &&

      // 'clabe' (CLABE code)
      row[0].length === 18 &&
      self.map(el => el[0]).indexOf(row[0]) === index &&

      // 'correo' (email)
      validateEmail(row[3])
    ) {
      return row;
    }
    
    if (
      index > 0 &&

      // The condition for a "duplicate" is if the CLABE code is defined twice
      // or more in the same CSV File
      self.map(el => el[0]).indexOf(row[0]) !== index
    ) {
      beneficiariesDuplicates.push(row) 
    } else {
      beneficiariesErrors.push(row);
    } 
  });

  return {
    success: beneficiariesSuccess,
    duplicates: beneficiariesDuplicates,
    errors: beneficiariesErrors
  };

};
