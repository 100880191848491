import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { freelancerBasicInfo } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';
import CreateLegalPerson from '../domain/create-legal-person';

const createLegalPersonService = async data => {
  try {
    const { status, error } = await customAxiosInstance().post(
      freelancerBasicInfo,
      CreateLegalPerson(data)
    );
    return extractContentFromResponse({
      status,
      error,
      moduleName: 'create-legal-person'
    });
  } catch (error) {
    log.error('create-legal-person', error);

    return { error: { message: 'Not able to send request!' } };
  }
};

export default createLegalPersonService;
