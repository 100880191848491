import React from 'react';

import { Route } from 'react-router';

import OnboardingRequirementsPage from './pages/OnboardingRequirementsPage';
import PrequalificationPage from './pages/PrequalificationPage';
import ScheduleInterviewPage from './pages/ScheduleInterviewPage';
import StartLaterPage from './pages/StartLaterPage';
import InReviewPage from './pages/InReviewPage/InReviewPage';

import {
  prequalificationFormPath,
  interviewSchedulePath,
  onboardingRequirementsPath,
  startLaterPath,
  inReviewPath
} from './constants';

const Prequalification = () => {
  return (
    <>
      <Route
        exact
        path={prequalificationFormPath}
        component={PrequalificationPage}
      />
      <Route
        exact
        path={interviewSchedulePath}
        component={ScheduleInterviewPage}
      />
      <Route
        exact
        path={onboardingRequirementsPath}
        component={OnboardingRequirementsPage}
      />
      <Route exact path={startLaterPath} component={StartLaterPage} />
      <Route path={inReviewPath} component={InReviewPage} />
    </>
  );
};

export default Prequalification;
