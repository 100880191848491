import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosAuxConfig';
import { customerUpdatePhone } from '../utils/constants';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const customerUpdatePhoneService = async ({ uuid, phone }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      customerUpdatePhone,
      {
        data:{
          customer:{
            uuid,
            phone
          }
        }
      },
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'kyc-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('kyc-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
