import { removeTagAction } from '../redux/tag-action';

const deleteCardTagHandler = ({ dispatch, deleteCardTag }) => async ({
  tag
}) => {
  const { error } = await deleteCardTag({ tag });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  dispatch(removeTagAction({ tag }));

  return { isSuccess: true, data: tag };
};

export { deleteCardTagHandler };
