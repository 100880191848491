import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const getFocusedStyles = ({ hasValue }) => {
  return hasValue ? labelFocused : '';
};

const labelFocused = css`
  font-size: 1.2rem;
  top: 0.8rem;
  transition: 300ms ease all;
  ${fonts.quickSandSemiBold};
`;

export const Options = styled.div`
  background-color: ${colors.white};
  border: 0.1rem solid ${colors.carbon3};
  border-radius: 0.4rem;
  box-shadow: 0px 4px 4px rgba(198, 204, 213, 0.4);
  box-sizing: border-box;
  color: #1c2633;
  display: none;
  font-size: 1.4rem;
  width: 100%;
  padding: 2rem;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 7.1rem;
`;

export const StyledDropdwon = styled.ul`
  color: ${colors.primary};
  display: inline-block;
  float: left;
  font-family: ${fonts.quickSandSemiBold};
  font-size: 1.7rem;
  padding-inline-start: 0;
  width: 100%;
  height: 7.1rem;
  border-radius: 0.8rem;
  border: solid 1px #ebeef2;
  background-color: white;
  margin: 1.6rem 0;
  position: relative;
  padding: 0 2.4rem;
  ${getFocusedStyles};

  &:hover {
    cursor: pointer;
    background-color: ${colors.carbon4};
    ${Options} {
      display: block;
    }
  }
`;

export const StyledItem = styled.li`
  display: block;
  font-size: 1.4rem;
  min-width: 8rem;
  padding: 0.5rem 0;

  &:hover {
    cursor: pointer;
    color: ${colors.primary};
  }
  &:active {
    color: ${colors.primary};
  }
`;

export const LabelFor = styled.div`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  height: 1.2rem;
`;

export const HelperText = styled.div`
  ${fonts.quickSandMedium}
  font-size: 1.2rem;
  color: #000;
`;

export const SelectedLabel = styled.div`
  ${fonts.barlowRegular}
  font-size: 2rem;
  color: #1c2633;
`;

export const LabelWrapper = styled.div`
  color: ${props => (props.hasValue ? colors.primary : colors.carbon)};
  display: flex;
  align-items: self-start;
  flex-direction: column;
  line-height: ${props => (props.lineHeight ? props.lineHeight : '1')};
`;

export const Wrapper = styled.div`
  color: ${colors.carbon};

  display: flex;
  align-items: self-start;

  &:hover {
    cursor: pointer;
  }

  svg path {
    fill: ${colors.carbon};
  }
`;
