import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const styles = makeStyles(theme => ({
  button: {
    fontFamily: 'Barlow',
    textTransform: 'none',
    color: '#ed2246',
    backgroundColor: '#f7f8f8',
    borderColor: '#f7f8f8',
    width: '16.8rem',
    height: '4.8rem',
    marginRight: '2.4rem',
    [theme.breakpoints.down('sm')]: {
      width: '13rem',
      marginRight: '1.2rem'
    },
    borderRadius: '2.4rem',
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '1.31',
    letterSpacing: '0.2px',
    textAlign: 'center'
  },
  img: {
    paddingLeft: '0.4rem'
  }
}));

const whiteButton = ({ copy, icon, onClick, disabled, type, customClass }) => {
  const classes = styles();
  return (
    <Button
      type={type}
      variant="outlined"
      disabled={disabled}
      className={`${classes.button} ${customClass}`}
      onClick={onClick}
    >
      {copy}
      <img src={icon} alt="" className={classes.img} />
    </Button>
  );
};

export default whiteButton;
