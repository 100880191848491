import React from 'react';

import { Tooltip, TooltipText } from './styles.js';

const HelperTooltip = ({ children, className, tooltipText }) => {
  return (
    <Tooltip className={className}>
      {children}
      <TooltipText className="tooltipText">{tooltipText}</TooltipText>
    </Tooltip>
  );
};

export default HelperTooltip;
