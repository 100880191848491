import { companyId } from '../../../micro-frontends/onboarding/constants';

const OnboardingEnterpriseGuard = async state => {
  const userType = state?.userInfoReducer?.user?.company_type_id;
  if (!userType) return false;

  return userType === companyId;
};

export default OnboardingEnterpriseGuard;
