/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const getColor = ({ color }) => {
  const textColors = {
    info: colors.primary,
    body: colors.body,
  };

  return textColors[color] || textColors.body;
};

const flexContent = () => {
  return `display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;`;
};

export const BatchTransferContainer = styled.div`
  width: 61.4rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BatchContainer = styled.div`
  width: 61.4rem;
  height: auto;
  .title {
    font-size: 2.4rem;
    font-weight: 600;

    letter-spacing: 0.22px;
    color: ${colors.carbon};
  }

  .titleBlack {
    color: ${colors.carbon};
  }

  .link {
    margin-top: 3.2rem;
    cursor: pointer;
  }
  .progressBar {
    width: 44.4rem;
    height: 6.1rem;
    border-radius: 35.5px;
    & > div {
      height: 6.1rem;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  ${flexContent}
`;

export const SubtitleContainer = styled.div`
  margin: 4rem 0;
  text-align: center;
`;

export const Subtitle = styled.label`
  ${fonts.barlowMedium}
  font-size: 1.55rem;
  line-height: 1.48;
  letter-spacing: 0.19px;
  color: ${getColor};
  span {
    color: #1790cf;
  }
`;

export const SubtitleNameStep = styled(Subtitle)`
  display: block;
  margin-bottom: 4rem;
`;

export const DetailsContainer = styled.div`
  width: 44.4rem;
  ${flexContent};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${props => (props.top ? props.top : '22.7rem')};
  margin-bottom: 2rem;
  button {
    border-radius: 35.5px;
    width: 44.4rem;
    height: 6.1rem;
  }

  @media (max-width: 768px) {
    margin-top: 6rem;
  }
`;

export const FileContainer = styled.div`
  margin-top: 4rem;
  overflow-x: scroll;
  position: relative;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
`;

export const FailedBatchesContainer = styled(FileContainer)`
  height: 40rem;
  .gridContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 4rem;
    width: 56rem;
    max-width: 100%;
  }
`;

export const DownloadContainer = styled.div`
  cursor: pointer;

  svg path {
    fill: ${colors.sense1};
  }
`;
export const Image = styled.img`
  cursor: pointer;
`;

export const FileDetailsContainer = styled.div`
  .subtitleContainer {
    margin: 4rem 0 0 0;
  }
`;

export const TransfersConfirmContainer = styled.div`
  width: 61.4rem;
  .subtitleContainer {
    margin: 4rem 0;
    min-width: 55.1rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 4.8rem 0 3.6rem;
  .buttonClass {
    width: 37rem;
    height: 5rem;
  }
`;

export const Error = styled.div`
  ${fonts.barlowMedium}
  font-size: 1.55rem;
  line-height: 1.48;
  letter-spacing: 0.19px;
  color: ${colors.deltai};
`;

export const AccordionContainer = styled.div`
  height: 34.6rem;
  background: white;
  padding: 2rem 0;
  max-width: 75.3rem;
  width: 56rem;
  overflow-y: scroll;
`;

export const AccordionItem = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`;

export const TooltipContainer = styled.div`
  position: absolute;
  right: 4.5rem;
  top: 0.7rem;
  cursor: pointer;
`;

export const AccordionHeader = styled.div`
  font-size: 1.8rem;
  line-height: 2.2rem;
  cursor: pointer;
  background: white;
  padding-bottom: 1rem;
  width: 100%;
  position: relative;
  svg {
    position: absolute;
    left: 2.9rem;
    top: 0.5rem;
  }
  .warning {
    left: 3.5rem;
  }
  .plus {
    left: 3.3rem;
  }
  span {
    font-weight: 600;
    color: ${props => (props.type === 'success'
        ? colors.success
      : props.type === 'warning'
        ? colors.warning
      : colors.deltai)};
  }
`;

export const AccordionTitle = styled.div`
  width: 70%;
  margin: auto;
`;

export const AccordionBody = styled.div`
  background: white;
`;

export const IconExpand = styled.span`
  position: absolute;
  right: ${props => (props.active ? '4rem' : '10.5rem')};
  top: ${props => (props.active ? '2rem' : '0.5rem')};
  transform: ${props => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const BackButton = styled.div`
  position: absolute;
  z-index: 10;
  top: 6.2rem;
  left: 2rem;
  color: ${colors.carbon};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.218182px;
  cursor: pointer;
  ${fonts.quickSandSemiBold};
`;

export const DotsContainer = styled.div`
  display: flex;
  width: 5.1rem;
  margin: auto;
  padding-bottom: 2rem;
  justify-content: space-between;
`;

export const Dot = styled.div`
  background: ${props => (props.active ? colors.primary : colors.white)};
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid ${props => (props.active ? 'transparent' : colors.body)};
`;

export const TransferName = styled.div`
  svg {
    margin-right: 3rem;
    width: 5.8rem;
  }
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.2125px;
  padding: 2rem 0;
  ${fonts.quickSandSemiBold};
`;

export const TransferAmount = styled(TransferName)``;

export const TransferBeneficiaries = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  span {
    ${fonts.barlowMedium};
    font-size: 15.5px;
    line-height: 19px;
    letter-spacing: 0.19375px;
    color: ${colors.carbon};
    margin: 0.5rem 0;
  }
`;
