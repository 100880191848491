const customerGetHandler = ({
  customerGet,
}) => async ({ companyId, userId }) => {
  const { error, data } = await customerGet({ companyId, userId });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  return { isSuccess: true, data };
};

export { customerGetHandler };
