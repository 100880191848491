import * as yup from 'yup';
import { TransferAmountValidation } from './helpers/transferAmountValidation';

const requiredFieldMessage = 'Campo requerido';
const patternAmount = /^([\d.])((\d{0,3},?(\d{3},?)*\d{0,3}(\d{1,3})?)([.]?[0-9]{0,2})?)$/;

export const transferValidations = yup.object({
  amount: yup
    .string()
    .min(1, '')
    .max(18, 'Monto máximo excedido')
    .matches(new RegExp(patternAmount), {
      message: 'Ingresa un monto válido',
      excludeEmptyString: true
    })
    .test('Validate Amount', function (amount) {
      if (!amount) {
        return this.createError({
          message: `Monto - campo requerido`
        });
      }

      if (Number(amount) < 0.01) {
        return this.createError({
          message: 'Ingresa un monto válido'
        });
      }
      const userRoleId = this.parent.userRoleId;
      const balance = this.parent.balance;
      const creditLine = this.parent.creditLine;
      const spendingLimit = this.parent.spendingLimit;
      const parsedBalance = Number(balance.replace(/([$]|[,])/g, ''));
      const parsedCreditLine = Number(creditLine.replace(/([$]|[,])/g, ''));
      const parsedLimit = Number(spendingLimit.replace(/([$]|[,])/g, ''));
      const parsedAmount = Number(amount.replace(/[,]/gi, ''));

      const validation = TransferAmountValidation(
        parsedCreditLine,
        parsedBalance,
        parsedLimit,
        parsedAmount,
        userRoleId
      );

      if (!validation.isValid) {
        return this.createError({
          path: this.path,
          message: validation.errorMessage
        });
      } 
        return true;
      
    })

    .required(requiredFieldMessage),
  concept: yup
    .string()
    .required(requiredFieldMessage)
    .max(40, 'Máximo 40 caracteres'),
  reference_number: yup
    .string()
    .test({
      name: 'reference_validator',
      test: function (ref) {
        if (!ref) return true;

        if (ref.startsWith('0')) {
          return this.createError({
            message: 'No debe empezar con cero'
          });
        }
        return true;
      }
    })
    .matches(/^[0-9]+$/, 'Solo se permiten números')
    .max(7, 'Máximo 7 caracteres')
});
