import React, { memo } from 'react';

import log from 'loglevel';
import moment from 'moment';

import { downloadFile } from '../../../../helpers/fileManager';

import EntryStatement from '../EntryStatement/EntryStatement';
import HeaderTable from '../../../shared/components/HeaderTable/HeaderTable';
import Title from '../../../shared/components/Title/Title';
import Label from '../../../shared/components/Label/Label';
import { Header } from '../../Account.styles';
import { Concept } from '../../../credit/components/EntryBalance/EntryBalance.styles';
import { service } from '../../service';
import AccountStatementYearSelector from '../AccountStatementYearSelector/AccountStatementYearSelector';

const AccountStatements = ({
  statementDates,
  menuOptions = [],
  selectedYear,
  setSelectedYear
}) => {
  const getStatement = async date => {
    let newDate = moment(date);
    let year = newDate.year();
    let month = newDate.month() + 1;

    try {
      const accountStatement = await service().getAccountStatement({
        year,
        month
      });

      downloadFile(
        'pdf',
        'base64',
        accountStatement[0].account_statement,
        'Estado de cuenta',
        'pdf'
      );
    } catch (error) {
      log.error(error);
    }
  };

  const Statements = () => {
    if (!statementDates || !statementDates.length) {
      return (
        <div style={{ paddingLeft: '3.2rem' }}>
          <Title
            data-test="noStatementsTitle"
            text="Aún no tienes estados de cuenta"
          />
        </div>
      );
    }

    return statementDates.map(date => (
      <EntryStatement
        key={date}
        title={getMonthString(date)}
        onClick={() => getStatement(date)}
      />
    ));
  };

  const getMonthString = date => {
    let month = moment(date).locale('es').format('MMMM');

    return month;
  };

  return (
    <div data-test="accountStatements">
      <Header>
        <Concept text={'Estados de cuenta'} />
        <Label
          text={
            menuOptions.length ? (
              <AccountStatementYearSelector
                year={selectedYear}
                onSelect={setSelectedYear}
                options={menuOptions}
              />
            ) : (
              ''
            )
          }
        />
      </Header>
      <HeaderTable text1="FECHA" className="accountStatementsHeader" />
      <div className="accountStatementsContainer">{<Statements />}</div>
    </div>
  );
};

export default memo(AccountStatements);
