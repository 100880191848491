import createDataContext from "./createDataContext";

const formContext = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ATTORNEY_IN_FACT_STATE':
      return { ...state, formState: payload };
    case 'SET_DIRECTOR_MEMBER_STATE':
      return { ...state, formState: payload };
    case 'SET_NAME_STATE':
      return { ...state, formState: payload };
    case 'SET_OWNER_STATE':
      return { ...state, formState: payload };
    case 'UPDATE_OWNER_PERSON':
      return { ...state, formState: payload };
    case 'SET_PERSON_STATE':
      return { ...state, formState: payload };
    case 'SET_FORM_ERRORS':
      return { ...state, formState: payload };
    default:
      return state;
  }
};

let formStateValue = {
  attorneyInFactValue: {},
  directorMemberValue: {},
  nameValue: {},
  ownerValue: {},
  ownerPersons: {
    total: 0,
    owners: []
  },
  kindPersonValue: false,
  errors: {}
};

const setFormAttorneyInFact = dispatch => formState => {
  formStateValue.attorneyInFactValue = formState;
  dispatch({
    type: 'SET_ATTORNEY_IN_FACT_STATE',
    payload: { formStateValue: formStateValue }
  });
};

const setFormDirectorMember = dispatch => formState => {
  formStateValue.directorMemberValue = formState;
  dispatch({
    type: 'SET_DIRECTOR_MEMBER_STATE',
    payload: { formStateValue: formStateValue }
  });
};

const setFormName = dispatch => formState => {
  formStateValue.nameValue = formState;
  dispatch({
    type: 'SET_NAME_STATE',
    payload: { formStateValue: formStateValue }
  });
};

const setFormOwner = dispatch => formState => {
  formStateValue.ownerValue = formState;
  dispatch({
    type: 'SET_OWNER_STATE',
    payload: { formStateValue: formStateValue }
  });
};

const addOwnerPerson = dispatch => formState => {
  formStateValue.ownerPersons.owners.push(formState.owners);
  formStateValue.ownerPersons.total = formState.total;
  dispatch({
    type: 'UPDATE_OWNER_PERSON',
    payload: { formStateValue: formStateValue }
  });
};

const removeOwnerPerson = dispatch => formState => {
  formStateValue.ownerPersons = formState;
  dispatch({
    type: 'UPDATE_OWNER_PERSON',
    payload: { formStateValue: formStateValue }
  });
};

const setPersons = dispatch => formState => {
  formStateValue.kindPersonValue = formState;
  dispatch({
    type: 'SET_PERSON_STATE',
    payload: { formStateValue: formStateValue }
  });
};

const setFormErrors = dispatch => formState => {
  formStateValue.errors = formState;
  dispatch({
    type: 'SET_FORM_ERRORS',
    payload: { formStateValue: formStateValue }
  });
};

export const { Provider, Context } = createDataContext(
  formContext,
  {
    setFormAttorneyInFact,
    setFormDirectorMember,
    setFormName,
    setFormOwner,
    addOwnerPerson,
    removeOwnerPerson,
    setPersons,
    setFormErrors
  },
  { formStateValue: formStateValue }
);
