import "./index.scss";
import { v4 as uuid } from "uuid";

import React, { useState } from "react";

import eye from "../../assets/eye.svg";
import eyeHide from "../../assets/eyeHide.svg";

export default function InputText({
    type = "text",
    value = "",
    errorMessage = null,
    hintText,
    name,
    onChange,
    isOptional,
    filter,
    validator,
    style={},
    disabled = false
}) {
    const [isPasswordVisible, setPasswordVisible] = useState(false);

    let inputClassName = "input";
    let labelClassName = "label";
    let errorClassName = "error_hidden";

    const isPassword = type === "password";

    if (errorMessage !== null) {
        inputClassName += " error";
        labelClassName += " error";
        errorClassName = "error_show";
    }

    const componentId = `input-text-${uuid()}`;

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className="container">
            <input
                disabled={disabled}
                type={
                    isPassword
                        ? isPasswordVisible
                            ? "text"
                            : "password"
                        : type
                }
                id={componentId}
                className={inputClassName}
                placeholder={hintText}
                value={value}
                onChange={(e) => {
                    if(name){
                        onChange([{name, value: filter? filter(e.target.value) : e.target.value, validator}], isOptional)
                    }else{
                        onChange(e.target.value)
                    }
                }}
                style={{ paddingRight: "30px", ...style }} // Adjust padding to accommodate icon
            />
            {isPassword && (
                <img
                    src={isPasswordVisible ? eyeHide : eye}
                    alt={isPasswordVisible ? "ocultar" : "mostrar"}
                    className="icon"
                    onClick={togglePasswordVisibility}
                />
            )}
            <label htmlFor={componentId} className={labelClassName}>
                {hintText}
            </label>
            <div className={errorClassName}>{errorMessage}</div>
        </div>
    );
}
