import React, { useEffect, useState } from 'react';

import CodeInput from '../CodeInput';

import { CustomTitle, ContentWrapper, Text } from './CardActivation.styles';

const Step1 = ({ requestActivationCode, setStep, setForm }) => {
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState('');
  const cardValidation = new RegExp('^[0-9]{4}$');

  const onValid = () => {
    setForm(value);
    setStep(2);

    requestActivationCode();
  };

  const validateFormat = value => {
    if (!value.match(cardValidation)) {
      setError('Código Inválido');
      setIsValid(false);
      return;
    }

    setValue(value);
    setIsValid(true);
  };

  useEffect(() => {
    if (isValid) onValid();
    //eslint-disable-next-line
  }, [isValid]);

  return (
    <ContentWrapper>
      <CustomTitle text="Activar tarjeta" />
      <Text
        marginBottom={'2rem'}
        text="Ingresa los últimos 4 dígitos de tu tarjeta. Encuéntralos en el reverso de la misma."
      />
      <Text
        marginBottom={'2rem'}
        bold
        text="Recuerda: Solo el dueño de la tarjeta puede activar su tarjeta."
      />
      <CodeInput
        error={error}
        digits={4}
        onValid={e => validateFormat(e)}
        className="cardLastFour"
      />
    </ContentWrapper>
  );
};

export default Step1;
