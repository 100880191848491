import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Amount from '../../../shared/components/Amount/Amount';
import Avatar from '../../../shared/components/Avatar/Avatar';
import Date from '../../../shared/components/Date';
import Details from '../../../shared/components/Details';
import Icon from '../../../shared/components/Icon/Icon';
import StatusIcon from '../../../shared/components/StatusIcon/StatusIcon';
import Title from '../../../shared/components/Title';
import useViewportWidth from '../../../../hooks/useViewportWidth';
import TagList from '../../../tags/TagList/TagList';
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';

// Assets
import { splitDate, splitAmount } from '../../../../helpers/helpers';

// Constants
import { INCOME, STATUS } from '../../constants';

// Styled Components
import {
  InvoiceItemContainer,
  InvoiceItemDetailsContainer,
  ActionButton
} from './styles';
import { avatarColors } from '../../../../Dashboard4.0/assets/styles/styles';

export const Component = ({ className = '', details, onClick }) => {
  const {
    invoice_type_id,
    uuid,
    amount,
    currency_code,
    issuer_name,
    issuer_rfc,
    receiver_name,
    receiver_rfc,
    is_issuer,
    payment_date,
    invoice_date,
    invoice_status,
    isDateVisible,
    invoice_type,
    tags,
    title,
    RFC
  } = details;

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [date, setDate] = useState('');
  const [digits] = useState(() => splitAmount(amount));

  const width = useViewportWidth();

  const handleMouseOver = isMouseOver => {
    if (width < 769) return;
    setIsMouseOver(isMouseOver);
  };

  useEffect(() => {
    setDate(splitDate(invoice_date, 'DD MMM YYYY'));
    //eslint-disable-next-line
  }, []);

  return (
    <InvoiceItemContainer
      className={(className = '')}
      onClick={() => onClick(details)}
      data-testid="InvoiceItemComponent"
      onMouseEnter={() => handleMouseOver(true)}
      onMouseLeave={() => handleMouseOver(false)}
    >
      {isDateVisible && date ? (
        <Date day={date.day} month={date.month} year={date.year} />
      ) : (
        <span></span>
      )}
      <Avatar
        colors={invoice_type === INCOME ? avatarColors[8] : avatarColors[6]}
        className="InvoiceItemAvatar"
      >
        <Icon
          className="avatarIconColor"
          icon={invoice_type === INCOME ? 'issued' : 'reverseArrow'}
        />
      </Avatar>
      <InvoiceItemDetailsContainer>
        <div>
          <Title
            text={title}
            onClick={onClick}
            className={` ${isMouseOver ? 'titleHover' : ''} `}
          />
        </div>
        {RFC ? <Details mainText={`RFC-${RFC}`} /> : null}
        {tags?.length ? (
          <TagList
            className="cardTransactionTags"
            tags={tags}
            showAddNewTag={false}
            lengthValidation={7}
            isDeleteVisible={false}
          />
        ) : null}
      </InvoiceItemDetailsContainer>

      {invoice_status === STATUS.VIGENTE && payment_date === null && (
        <>
          <Amount
            color={invoice_type === INCOME ? 'success' : null}
            integer={`${digits.sign}${digits.integer}`}
            decimals={digits.decimals}
          />

          <ActionButton onClick={onClick}>
            {invoice_type_id === 1 ? 'Por Cobrar' : 'Por Pagar'}
          </ActionButton>
        </>
      )}
      {invoice_status === STATUS.VIGENTE && payment_date && (
        <>
          <Amount
            color={invoice_type === INCOME ? 'success' : null}
            integer={`${digits.sign}${digits.integer}`}
            decimals={digits.decimals}
            className={` sucessAmount`}
          />
          {invoice_status ? <StatusIcon status={'success'} /> : null}
        </>
      )}
      {invoice_status === STATUS.CANCELADO && (
        <>
          <Amount
            integer={`${digits.sign}${digits.integer}`}
            decimals={digits.decimals}
          />
          {invoice_status ? <StatusIcon status={'rejected'} /> : null}
        </>
      )}
    </InvoiceItemContainer>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

const InvoicesItem = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="Invoices Item"
    />
  );
};

export default memo(InvoicesItem);
