import { customAxiosInstance } from '../Services/axiosConfig';
import createDataContext from './createDataContext';
import { transformKycMultipart } from '../Services/softwallService';

const BiometricContext = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'KYC_Person_Document_Register':
      return { ...state, kyc_res: payload };
    case 'add_error':
      return { ...state, errorMessage: false };
    case 'changeKycRes':
      return { ...state, kyc_res: payload };
    default:
      return state;
  }
};

const KYCPersonDocumentRegister = dispatch => async doc => {
  let body = transformKycMultipart(doc.fileName, doc.file, doc.kyc);

  await customAxiosInstance(process.env.REACT_APP_AXIOS_KYC_TIMEOUT)
    .post(`/api/dashboard/person/kyc`, body)
    .then(success => {
      dispatch({
        type: 'KYC_Person_Document_Register',
        payload: success.status
      });
    })
    .catch(error => {
      dispatch({ type: 'add_error', payload: true });
    });
};

const changeKycRes = dispatch => status => {
  dispatch({
    type: 'changeKycRes',
    payload: status
  });
};

export const { Provider, Context } = createDataContext(
  BiometricContext,
  { KYCPersonDocumentRegister, changeKycRes },
  { res: null, errorMessage: null, kyc_res: null }
);
