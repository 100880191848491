import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const getAlign = ({ align }) => {
  if (align)
    return `
      text-align: ${align};
  `;
};

const getStatusColor = ({ status }) => {
  const colorStatus = {
    approved: colors.primary,
    default: colors.body,
    pending: '#b4bac3',
    rejected: '#ed2246'
  };

  let color = colorStatus[status] ? colorStatus[status] : colorStatus.default;

  return color;
};

const classes = css`
  .amount {
    font-family: ${fonts.quickSandSemiBold};
    font-size: 4rem;
    letter-spacing: 1.71px;
    color: ${colors.palette1Text};
    span {
      font-size: 2.6rem;
    }
  }
  .status {
    padding: 2.8rem 0 2.8rem;
    align-items: center;
    margin-top: -0.8rem;
    label > img {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .hQAmount {
    font-size: 5rem;
    letter-spacing: 2.35px;
    span {
      font-size: 3.6rem;
    }
  }
  .hQStatus {
    padding: 6.8rem 0 4rem;
  }
  .hQTrackingKey {
    font-size: 2.35rem;
    letter-spacing: 0.71px;
  }

  .btnContainer {
    margin: 0 auto 3rem;
    text-align: center;
  }

  .tags label {
    display: flex;
    align-items: center;
  }

  .tags label:nth-child(1) {
    display: none;
  }

  .tagItem {
    width: 8rem;
    min-width: 8rem;
    height: 2rem;

    span {
      padding: 0;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 1rem;

  ${classes}
`;

export const DetailsContainer = styled.div`
  border-radius: 18px;
  max-width: 65.9rem;
  width: 100vw;
  height: auto;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
  overflow: hidden;

  @media screen and (max-width: 443px) {
    border-radius: 0;
  }
`;

export const AmountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10.62rem;
  box-shadow: 0 11px 22px -8px rgba(198, 204, 213, 0.4);
  background-image: linear-gradient(
    69deg,
    ${colors.palette1} 16%,
    ${colors.palette100} 87%
  );
  margin-top: 0rem;
  position: relative;

  .modalClose {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  @media screen and (max-width: 1400px) {
    height: 8rem;
  }

  @media screen and (max-width: 850px) {
    height: 6rem;
  }
`;

export const Logo = styled.img`
  padding-top: 2.1rem;
  width: 10.2rem;
  height: 5.2rem;
  float: left;
`;

export const LabelContainer = styled.div`
  line-height: 0.5rem;
  margin-top: 1.8rem;
  ${getAlign}
`;

export const MainTitle = styled.p`
  font-family: ${fonts.barlowSemiBold};
  font-size: 1.55rem;
  letter-spacing: 0.91px;
  line-height: 1.86rem;
  color: ${colors.body};
  margin: 0.5rem;
`;

export const SecondaryText = styled(MainTitle)`
  ${fonts.barlowRegular};
  color: ${colors.carbon};
  letter-spacing: 0.91px;
`;

export const BaseDescription = styled(MainTitle)`
  color: ${colors.carbon};
  margin: 2.5rem 0 3.5rem;
  text-align: center;
  @media screen and (max-width: 850px) {
    margin: 1rem 0 2rem;
  }
`;

export const Label = styled.span`
  margin-bottom: 1rem;
  font-family: 'Quicksand';
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 0.19px;
`;

export const Section = styled.div`
  border-bottom: 0.5px solid #939ca9;
  padding: 2rem 0;

  ${Section}:nth-child(5) {
    border-bottom: none;
  }
`;

export const TagsSection = styled.div`
  margin-top: -75px;
`;

export const SectionBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  div {
    margin: 2rem 3rem 2rem 0;
  }
`;

export const Description = styled(BaseDescription)`
  color: ${colors.body};
  margin: 1rem 0 0;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 1400px) {
    margin: 2rem 0 0;
  }

  @media screen and (max-width: 850px) {
    padding: 1rem 0 0;
  }
`;

export const Content = styled.div`
  padding: 2rem 5rem 0;
  max-width: 100%;
  width: 100vw;

  @media screen and (max-width: 850px) {
    padding: 0.5rem 5rem 0;
  }

  @media screen and (max-width: 443px) {
    padding: 2rem 2rem 0;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
`;

export const DetailsLabel = styled.label`
  font-family: ${fonts.quickSandSemiBold};
  font-weight: 600;
  font-size: 1.35rem;
  line-height: 1.9rem;
  letter-spacing: 0.17px;
  color: ${colors.carbon};
`;

export const DetailsText = styled.label`
  font-family: ${fonts.barlowRegular};
  font-size: 1.55rem;
  letter-spacing: 0.49px;
  color: ${getStatusColor};
`;

export const TrackingKeyContainer = styled.div`
  padding: 0 0 2.8rem;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 3px;
  }

  span {
    color: #1c2633;
    font-weight: 400;
  }
`;

export const ModalContainer = styled.div`
  max-width: 45rem;
  width: 100%;
  height: 80%;
  padding: 1rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 100%;
    margin-top: 6.6rem;
  }
`;
