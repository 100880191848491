import log from 'loglevel';

import { customAxiosInstance } from '../../../Services/axiosConfig';
import { onboardingProgressPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const getOnboardingProgressService = async () => {
  try {
    const { data, error, status } = await customAxiosInstance().get(
      onboardingProgressPath
    );

    return extractContentFromResponse({
      data,
      error,
      status,
      moduleName: 'get-onboarding-progress-service'
    });
  } catch (error) {
    log.debug('get-onboarding-progress-service', error);
    return { error: { message: 'Unable to send request' } };
  }
};
