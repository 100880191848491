import styled from 'styled-components';

import MultipartFileInput from '../../../shared/components/MultipartFileInput';

export const FileInput = styled(MultipartFileInput)`
  width: 36rem;
  margin: 1rem auto 2rem;

  .tooltip {
    right: 0;
  }
`;
