/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

const CheckboxItem = ({ text, value, onClick }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={onClick}
          name="dropdown_checkbox"
          color="primary"
          style={{
            color: colors.primary
          }}
        />
      }
      label={text}
    />
  );
};

CheckboxItem.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default CheckboxItem;
