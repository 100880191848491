// React
import React from 'react';

// Material UI
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Shared styles
import { colors } from '../../../Dashboard4.0/assets/styles/styles';

// Helpers
import { states } from '../pages/Account';
import { useSelector } from 'react-redux';
import {
  allPermissionsSelector,
  rulesSelector
} from '../../../role-based-access/redux/role-permissions-selectors';

const AccountPageMobileTabs = ({ state, handleTabChange }) => {
  const isSuperAdmin = useSelector(allPermissionsSelector);
  const rolePermissions = useSelector(rulesSelector);

  const viewAccountStatePermissions = rolePermissions.includes(
    'accountStatement:view'
  );

  const isSelected = tab => {
    if (tab === state)
      return { backgroundColor: colors.primary4, color: colors.primary };

    return {};
  };

  return (
    <div
      style={{
        marginTop: '20px',
        marginBottom: '32px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          width: '100%'
        }}
      >
        <Tabs
          value={state}
          onChange={(event, newTabState) => handleTabChange(newTabState)}
        >
          {(isSuperAdmin || viewAccountStatePermissions) && (
            <Tab
              disableRipple
              value={states.ACCOUNT_STATEMENTS}
              label={states.ACCOUNT_STATEMENTS}
              style={isSelected(states.ACCOUNT_STATEMENTS)}
            />
          )}
          {isSuperAdmin && (
            <Tab
              disableRipple
              value={states.ACCOUNT_SECURITY}
              label={states.ACCOUNT_SECURITY}
              style={isSelected(states.ACCOUNT_SECURITY)}
            />
          )}
        </Tabs>
        <br />
        <hr />
      </div>
    </div>
  );
};

export default AccountPageMobileTabs;
