import React from 'react';

import { Grid, InputAdornment, IconButton } from '@material-ui/core/';
import { getSpacing } from '../../helpers';
import { Visibility, VisibilityOff } from '@material-ui/icons/';

import Input from '../Input/';

const PersonSignUpForm = ({ props }) => {
  const {
    classes,
    formik,
    setData,
    showPassword,
    width,
    changeTermState,
    handleClickShowPassword,
    changeVoiceAndImgState,
    AcceptUseMyVoiceAndImage,
    AcceptTerms
  } = props;
  return (
    <>
      <Input
        type="text"
        name="name"
        label="Nombre(s)"
        onChange={setData}
        id="name"
        onBlur={formik.handleBlur}
        error={formik.errors.name && formik.touched.name}
        helperText={formik.errors.name}
      />
      <Grid container spacing={getSpacing(width)}>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            type="text"
            id="surname_1"
            label="Apellido paterno"
            name="surname_1"
            onChange={setData}
            onBlur={formik.handleBlur}
            error={formik.errors.surname_1 && formik.touched.surname_1}
            helperText={formik.errors.surname_1}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            type="text"
            label="Apellido materno"
            name="surname_2"
            id="surname_2"
            onChange={setData}
            onBlur={formik.handleBlur}
            error={formik.errors.surname_2 && formik.touched.surname_2}
            helperText={formik.errors.surname_2}
          />
        </Grid>
      </Grid>
      <Input
        type="email"
        label="Correo electrónico"
        name="email"
        id="email"
        onChange={setData}
        onBlur={formik.handleBlur}
        error={formik.errors.email && formik.touched.email}
        helperText={formik.errors.email}
      />
      <Input
        type={showPassword ? 'text' : 'password'}
        label="Crea tu contraseña"
        name="password"
        id="password"
        onChange={setData}
        onBlur={formik.handleBlur}
        error={formik.errors.password && formik.touched.password}
        helperText={formik.errors.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <div className={classes.checkbox}>
        <input
          id="terms_and_conditions"
          type="checkbox"
          className="checkbox-round"
          onChange={() => {
            changeTermState(AcceptTerms);
          }}
        />
        <label htmlFor="">
          Acepto el{' '}
          <a
            href={process.env.REACT_APP_PRIVACY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.redText}
          >
            Aviso de Privacidad
          </a>{' '}
          y{' '}
          <a
            href={process.env.REACT_APP_TERMS_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.redText}
          >
            Términos y Condiciones
          </a>
        </label>
      </div>
      <div className={classes.checkbox}>
        <input
          id="voice_and_image"
          type="checkbox"
          className="checkbox-round"
          onChange={() => {
            changeVoiceAndImgState(AcceptUseMyVoiceAndImage);
          }}
        />
        <label htmlFor="">
          Autorizo el uso de mi voz e imagen para el expediente de mi cuenta (no
          los compartiremos con nadie)
        </label>
      </div>
    </>
  );
};

export default PersonSignUpForm;
