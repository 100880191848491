import log from 'loglevel';

import { service } from '../../../../service/index';

const updateStatusHandler = async currentStatus => {
  if (currentStatus) {
    const { data, error } = await service().updateOnboardingProgress({
      newStatus: currentStatus
    });
    if (!data) log.error(error);
    else return data;
  }
};

export { updateStatusHandler };
