import React from 'react';

import Layout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';
import Calendly from '../../../shared/components/Calendly/Calendly';
import { calendlyURL } from '../../constants';

import { ModalDescription, Wrapper } from './styles.js';

import { service } from '../../../service';
import HubSpotScheduler from '../../components/HubSpotScheduler';

const ScheduleInterviewPage = ({ history }) => {
  const goToNextStep = async () => {
    await service().updateOnboardingProgress({
      newStatus: 'INTERVIEW_SCHEDULED'
    });
    history.push('/requirements');
  };

  return (
    <Wrapper>
      <Layout
        showLogout
        title="¡Sí somos para tí! 🎉"
        description={
          <ModalDescription>
            Accede a tu crédito a las 48hs. de tu entrevista. ¡Agéndala!
            Recuerda que es necesario que asista el representante legal
          </ModalDescription>
        }
        isPrimaryButtonHidden
        isModalVisible
      >
        <Calendly
          calendlyUrl={calendlyURL}
          className="calendly"
          onScheduled={goToNextStep}
        />
      </Layout>
    </Wrapper>
  );
};

export default ScheduleInterviewPage;
