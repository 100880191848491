import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  margin-bottom: 50px;
  width: 100%;

  & > *:first-child {
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      margin-left: 20px;
      display: flex;
      align-items: center;
      font-family: Barlow;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 18px;
      color: #000000;
    }
  }
  & > *:nth-child(2) {
    margin-left: auto;
    font-family: Barlow;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #000000;
  }
`;

export default Row;
