"use client";
import React from "react";

import Header from '../components/header';
import Panel from '../components/panel';
import HSpacing from '../components/spacing/horizontal-spacing';
import Heading from '../components/heading';
import Center from '../components/center';
import Button from '../components/button';
import Footer from '../components/footer';
import BodyText from '../components/body-text';

import Lock from "../assets/lock.svg";
import EyeHide from "../assets/eyeHide.svg";

import {tpaCompliancePath} from '../utils/constants';

const fontTextStyle = {
    fontWeight: 400,
    fontSize: "16px",
    letterSpacing: "0.22px",
    fontFamily: "barlow",
};

function View({history}) {

    const handleGoAhead = () => {
        history.push(tpaCompliancePath);
    }

    return (
        <div>
            <Header />
            <HSpacing times={3} />
            <Panel>
                <HSpacing times={4} />
                <Center>
                    <Heading>delt.ai utiliza Syntage</Heading>
                </Center>
                <HSpacing times={2} />
                <Center>
                    <div
                        style={{
                            width: "432px",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <BodyText style={fontTextStyle}>
                            Utilizamos al proveedor Syntage para conectar con tu
                            información fiscal de forma:
                        </BodyText>
                    </div>
                </Center>
                <HSpacing times={3} />
                <div
                    style={{
                        display: "flex",
                        marginLeft: 40,
                    }}
                >
                    <div>
                        <img src={Lock} alt="" />
                    </div>
                    <div style={{ marginLeft: 16 }}>
                        <div>
                            <BodyText
                                style={{
                                    color: "#1C2633",
                                    fontSize: 16,
                                    fontWeight: 600,
                                }}
                            >
                                Segura
                            </BodyText>
                        </div>
                        <HSpacing times={1} />
                        <div>
                            <BodyText
                                style={{
                                    color: "#1C2633",
                                    fontSize: 14,
                                }}
                            >
                                La información está encriptada. Esto protege tu
                                información fiscal.
                            </BodyText>
                        </div>
                    </div>
                </div>
                <HSpacing times={2} />
                <div
                    style={{
                        display: "flex",
                        marginLeft: 40,
                    }}
                >
                    <div>
                        <img src={EyeHide} alt="" />
                    </div>
                    <div style={{ marginLeft: 16 }}>
                        <div>
                            <BodyText
                                style={{
                                    color: "#1C2633",
                                    fontSize: 16,
                                    fontWeight: 600,
                                }}
                            >
                                Privada
                            </BodyText>
                        </div>
                        <HSpacing times={1} />
                        <div>
                            <BodyText
                                style={{
                                    color: "#1C2633",
                                    fontSize: 14,
                                }}
                            >
                                <a
                                    className="body_text default"
                                    style={{
                                        color: "#939CA9",
                                        fontWeight: "600",
                                    }}
                                    href="https://delt.ai"
                                >
                                    delt.ai
                                </a>{" "}
                                no tendrá acceso a tus credenciales
                            </BodyText>
                        </div>
                    </div>
                </div>
                <HSpacing times={3} />
                <Center>
                    <div style={{ width: 432 }}>
                        <span className="info-text">
                            <BodyText
                                style={{
                                    color: "#939CA9",
                                    fontSize: 14,
                                }}
                            >
                                Haciendo clic en “Continuar” aceptas la{" "}
                            </BodyText>
                            <a
                                style={{
                                    color: "#939CA9",
                                    textDecoration: "underline",
                                }}
                                className="info-text text-bold"
                                href="https://syntage.com/en/legal/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <BodyText
                                    style={{
                                        color: "#939CA9",
                                        fontWeight: 500,
                                        fontSize: 14,
                                    }}
                                >
                                    politica de privacidad
                                </BodyText>
                            </a>
                            <BodyText
                                style={{
                                    color: "#939CA9",
                                    fontSize: 14,
                                }}
                            >
                                {" "}
                                de usario de Syntage.
                            </BodyText>
                        </span>
                    </div>
                </Center>

                <HSpacing times={5} />
                <Center>
                    <Button
                        style={{ fontWeight: 600 }}
                        type="primary"
                        width={432}
                        label="Aceptar y continuar"
                        onClick={handleGoAhead}
                    />
                </Center>
            </Panel>
            <Footer />
        </div>
    );
}

export default function Page({history}) {
    return (
        <>
            <View history={history}/>
        </>
    );
}
