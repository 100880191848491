import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { getCardTagsPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const getCardTagsService = async ({ cardId }) => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      `${getCardTagsPath}${cardId}`
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'get-card-tags-service'
    });
  } catch (error) {
    log.error('get-card-tags-service', error);

    return { error: { message: 'Unable to send request!' } };
  }
};
