/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import TextField from '../../../shared/components/TextField/TextField';

const Datepicker = ({ label, hasValue, onChange, ...otherProps }) => {
  const [date, setDate] = useState(hasValue);

  // To improve
  const formatDate = date => {
    if (!date) return;
    const arr = date.split('');
    const day = arr[0] ? arr[0] : '_';
    const day2 = arr[1] ? arr[1] : '_';
    const month = arr[2] ? arr[2] : '_';
    const month1 = arr[3] ? arr[3] : '_';
    const year1 = arr[4] ? arr[4] : '_';
    const year2 = arr[5] ? arr[5] : '_';
    const year3 = arr[6] ? arr[6] : '_';
    const year4 = arr[7] ? arr[7] : '_';

    const final = [
      day,
      day2,
      '/',
      month,
      month1,
      '/',
      year1,
      year2,
      year3,
      year4
    ].join('');

    return final;
  };

  const handleChange = e => {
    const value = e.target.value;
    setDate(value ? validateFormat(value) : '');
    e.target.value = validateFormat(value);

    if (onChange) onChange(e);
  };

  const deleteChars = (e, date) => {
    if (!date) return;
    if (e.keyCode === 8) {
      const value = date.replace(/[^0-9]+/gi, '');
      const arr = value.split('');

      if (arr.length === 1) {
        setDate('');
        return;
      }

      arr.pop();
      const finalValue = arr.join('');

      setDate(formatDate(finalValue));
    }
  };

  const validateFormat = value => {
    const removeSpecialChars = value.replace(/[^0-9]+/gi, '');
    const regexNumbers = /^\d+$/;
    if (!regexNumbers.test(removeSpecialChars)) return '';
    return formatDate(value.replace(/[^0-9]+/gi, ''));
  };

  useEffect(() => {
    if (hasValue) {
      const e = {
        target: {
          value: validateFormat(hasValue)
        }
      };
      handleChange(e);
    }
    //eslint-disable-next-line
  }, [hasValue]);

  return (
    <TextField
      data-test="textField"
      label={label}
      onChange={handleChange}
      onKeyDown={e => deleteChars(e, date)}
      value={date}
      {...otherProps}
    />
  );
};

Datepicker.propTypes = {
  label: PropTypes.string,
  hasValue: PropTypes.string,
  handleChange: PropTypes.func
};

export default Datepicker;
