import * as yup from 'yup';

import { directorAssociationId, REQUIRED_MESSAGE } from '../../constants';

const PATTERN_PERCENTAGE = /^((2[5-9]|[3-9][0-9]|100\d?)(\.\d{1,2})?|100(\.00?)?)$/;
const PATTERN_PERCENTAGE_MESSAGE = 'Mínimo 25, máximo 100. Máximo 2 decimales';
const CONTROLLING_STAKE_MESSAGE = 'Ingresa una cifra numérica etre 25 y 100';

const validatePercentage = (createError, percentage) => {
  const controllingStake = Number(percentage);

  if (!controllingStake) {
    return createError({
      message: CONTROLLING_STAKE_MESSAGE
    });
  }

  if (controllingStake > 100 || controllingStake < 25)
    return createError({
      message: CONTROLLING_STAKE_MESSAGE
    });

  return true;
};

export const attorneyInLawValidation = yup.object({
  shareHolder: yup.string().test(function (shareHolder) {
    if (shareHolder === '' && shareHolder === null)
      return this.createError({
        message: REQUIRED_MESSAGE
      });
    return true;
  }),
  isYou: yup.boolean(),
  percentage: yup
    .string()
    .test(function (percentage) {
      if (this.parent.isYou)
        return validatePercentage(this.createError, percentage);
      return true;
    })
    .matches(PATTERN_PERCENTAGE, PATTERN_PERCENTAGE_MESSAGE)
});

export const complementaryFormValidation = yup.object({
  associationTypeId: yup.string().required(REQUIRED_MESSAGE),
  name: yup.string().required(REQUIRED_MESSAGE),
  surname: yup.string().required(REQUIRED_MESSAGE),
  surname2: yup.string().required(REQUIRED_MESSAGE),
  title: yup.string().required(REQUIRED_MESSAGE),
  percentage: yup
    .string()
    .test(function (percentage) {
      if (this.parent.hasShareHolder)
        return validatePercentage(this.createError, percentage);
      return true;
    })
    .matches(PATTERN_PERCENTAGE, PATTERN_PERCENTAGE_MESSAGE)
});
