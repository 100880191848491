import React from 'react';
import PropTypes from 'prop-types';

import { AddOption } from './styles';

const NoOptionsMessage = ({ selectProps }) => {
  const { typed, onCreate } = selectProps;
  return (
    <AddOption>
      + {typed}
      <span onClick={onCreate}>Agregar</span>
    </AddOption>
  );
};

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object.isRequired
};

export default NoOptionsMessage;
