import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Layout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';
import { List, ListItem } from './style.js';

import { companyTypeIDSelector } from '../../redux/prequalification-selectos';

import { service } from '../../../service';
import {
  freelancerInfoPath,
  enterpriseInfoPath
} from '../../../onboarding/constants';
import { setOnboardingStatus } from '../../../onboarding/redux/onboardingActions';

const freelancerDocs = [
  'Cedula de Identificación Fiscal',
  'Constancia de la Firma Electrónica Avanzada',
  'Comprobante de domicilio no mayor a 3 meses de antigüedad',
  'Tu INE'
];

const pymeDocuments = [
  'Acta Constitutiva',
  ...freelancerDocs.slice(0, -1),
  'Tu INE como apoderado legal de la empresa',
  'Acta donde te nombre como apoderado legal de la empresa'
];

const DocumentsList = ({ requiredDocs }) => {
  return (
    <List>
      {requiredDocs.map(document => {
        return <ListItem key={document}>{document}</ListItem>;
      })}
    </List>
  );
};

const OnboardingRequirementsPage = ({ history }) => {
  const dispatch = useDispatch();

  const userTypeId = useSelector(companyTypeIDSelector);

  const basicInfoPath =
    userTypeId === 2 ? freelancerInfoPath : enterpriseInfoPath;

  const status = 'INITIAL_ONBOARDING';

  const submit = async () => {
    const { isSuccess } = await service().updateOnboardingProgress({
      newStatus: status
    });

    if (isSuccess) {
      dispatch(setOnboardingStatus(status));
    }

    history.push(basicInfoPath);
  };

  return (
    <Layout
      showLogout={true}
      title="¡Qué ganas de conocerte!"
      description={
        <div>
          <p>Esto es lo que necesitas tener listo:</p>
        </div>
      }
      primaryButtonText="Continuar"
      isModalVisible={true}
      onPrimaryButtonClick={submit}
    >
      <DocumentsList
        requiredDocs={userTypeId === 2 ? freelancerDocs : pymeDocuments}
      />
    </Layout>
  );
};

export default OnboardingRequirementsPage;
