import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { addressPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const setAddressService = async (
  {
    zipCode,
    settlement,
    state,
    city,
    street,
    externalNum,
    internalNum,
    municipality
  },
  userId
) => {
  try {
    const address = [
      zipCode,
      settlement,
      municipality,
      state,
      city,
      street,
      externalNum,
      internalNum
    ].join('|');

    const { status, error } = await customAxiosInstance().post(addressPath, {
      address,
      user_id: userId
    });
    return extractContentFromResponse({
      status,
      error,
      moduleName: 'set-address-service'
    });
  } catch (error) {
    log.error('set-address-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
