/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import log from 'loglevel';
import { useDispatch } from 'react-redux';

import Button from '../../../shared/components/Button';
import BatchContent from './BatchContent';
import ExcelRenderer from '../ExcelRenderer';
import {
  ButtonWrapper,
  DownloadContainer,
  FailedBatchesContainer,
  FileDetailsContainer,
  Image,
  Subtitle,
} from './BatchTransfer.styles';
import Icon from '../../../shared/components/Icon/Icon';

import { deleteBatch } from '../../../../actionCreators/failedBatchesAction';

import { downloadFile, fileFormat } from '../../../../helpers/fileManager';
import { getFailedBatchById } from '../../../../Services/spei';
import remove from '../../../../Dashboard4.0/assets/images/icons-remove.svg';
import DeleteBatch from './DeleteBatch';

const FailedBatches = ({ failedBatches, goBack }) => {
  const [headers] = useState(['ID', 'Fecha', 'Descargar', 'Eliminar']);
  const [content, setContent] = useState([]);
  const [batchId, setBatchId] = useState(null);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const deleteBatchByID = (id) => {
    deleteBatch(dispatch, id);
  };

  const convertData = (data, date) => {
    const b64 = data[0]?.transactions;
    downloadFile(fileFormat.csv, 'base64', b64, date, 'csv');
  };

  const downloadBatch = (id, date) => {
    getFailedBatchById(id)
      .then((data) => {
        if (data.status === 200) convertData(data.data, date);
      })
      .catch((e) => log.error(e));
  };

  const shortenId = (id) => {
    if (!id) return;
    return id.split('-')[4];
  };

  const transformToArray = (batches) => {
    const array = [];
    batches.forEach((batch) => {
      const { timestamp, batch_id, batch_process_id } = batch;
      const date = moment(timestamp).format('YYYY-MM-DD_HH:mm:ss');
      const id = `...${shortenId(batch_id)}`;
      array.push([
        id,
        date,
        <Download key={`download-${id}`} id={batch_process_id} date={date} />,
        <Delete key={`delete-${id}`} id={batch_id} />,
      ]);
    });
    setContent([headers, ...array]);
  };

  useEffect(() => {
    transformToArray(failedBatches);
    // eslint-disable-next-line
  }, [failedBatches]);

  const Delete = ({ id }) => {
    return (
      <Image
        src={remove}
        alt="delete"
        onClick={() => {
          setOpen(true);
          setBatchId(id);
        }}
      />
    );
  };

  const Download = ({ id, date }) => {
    return (
      <DownloadContainer onClick={() => downloadBatch(id, date)}>
        <Icon icon="download" />
      </DownloadContainer>
    );
  };

  return (
    <FileDetailsContainer>
      <DeleteBatch
        deleteBatch={(e) => deleteBatchByID(batchId)}
        open={open}
        onClose={(e) => {
          setOpen(false);
          setBatchId(null);
        }}
      />
      <BatchContent
        title="Transferencia Múltiple"
        subtitle={(
          <>
            <Subtitle>Transferencias fallidas</Subtitle>
            <FailedBatchesContainer>
              <ExcelRenderer rows={content} />
            </FailedBatchesContainer>
          </>
        )}
        content={(
          <ButtonWrapper>
            <Button
              text="Regresar"
              size="lg"
              onClick={goBack}
              className="buttonClass"
            />
          </ButtonWrapper>
        )}
      />
    </FileDetailsContainer>
  );
};

FailedBatches.propTypes = {
  failedBatches: PropTypes.array,
};

export default FailedBatches;
