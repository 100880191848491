import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles.js';

export const FormContainer = styled.form`
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 1.6rem 4.8rem;

  .formField {
    margin: 4rem 0;
  }

  .formButton {
    width: 100%;
    margin-top: 1.6rem;
  }

  .backBtn {
    width: 100%;
    color: ${colors.carbon1};
    font-size: 1.7rem;
    display: flex;
    justify-content: center;
    margin: 2.4rem 0;

    span {
      cursor: pointer;
    }
  }

  .customHelperMessage {
    margin-top: -0.8rem;
  }

  @media (max-width: 768px) {
    padding: 1.6rem 2rem;
    .formField {
      label {
        font-size: 1.4rem;
      }
      .endAdornment {
        margin-top: -1.2rem;
        font-size: 1.3rem;
      }
    }
  }

  @media (max-width: 410px) {
    .formField {
      padding: 0 1.6rem;
      label {
        font-size: 1.2rem;
      }
    }
  }
`;
