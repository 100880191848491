import React, { useEffect, useState, useCallback } from 'react';

// Auxiliar libraries
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Modal from '../../../shared/components/Modal';
import ModalAuth from '../../components/modal-auth';
import CardDetails from './modal-card-details';
import DeliveryAddress from './modal-delivery-address';
import InformationModal from '../../../shared/components/ModalInformation';
import CardSuccessModal from '../../components/modal-card-creation-success/index';

// Service
import { service } from '../../service';
import { service as tagService } from '../../../tags/service';

// Others
import {
  isAdminSelector,
  isSuperAdminSelector,
  selectAvailablePhysicalCards,
  selectAvailableVirtualCards,
  selectEmail
} from '../../redux/cards-selectors';
import { machine, states, events } from './state-machine-definition';
import { PHYSICAL, VIRTUAL, removeTagLink } from '../../constants';
import {
  getTitleOfErrorMessage,
  getBodyOfErrorMessage,
  getTitleOfSuccessMessage,
  getBodyOfSuccessMessage,
  VirtualCardSuccessMessage,
  PhysicalCardSuccessMessage,
  getTitleOfInformationMessage,
  getBodyOfInformationMessage
} from './response-handler';
import { useMixPanel } from '../../../shared/mixpanel';
import { addTagAction, clearTags } from '../../../tags/redux/tag-action';
import { tagsSelector } from '../../../tags/redux/tag-selectors';

const NewCardModal = ({
  openModal,
  setNewCardModal,
  setSelectedCard,
  toggleCardDetailsModal
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cardBasicInfo, setCardBasicInfo] = useState();
  const [deliveryInfo, setDeliveryInfo] = useState();
  const [addressStates, setAddressStates] = useState([]);
  const [authError, setAuthError] = useState(null);
  const [passwordLocal, setPasswordLocal] = useState(null);
  const [cardDetails, setCardDetails] = useState({});
  const [issueError, setIssueError] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const email = useSelector(selectEmail);
  const [sendMixPanelEvent] = useMixPanel();
  const tags = useSelector(tagsSelector);
  const availablePhysicalCards = useSelector(selectAvailablePhysicalCards);
  const availableVirtualCards = useSelector(selectAvailableVirtualCards);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);

  const [state, setState] = useState();
  // Initial State is set here
  useEffect(() => {
    if (Number(availablePhysicalCards) + Number(availableVirtualCards) === 0) {
      return setState(states.AVAILABLE_CARD_LIMIT_REACHED);
    }
    setState(machine.value);
  }, [availablePhysicalCards, availableVirtualCards]);

  const handleModalClose = useCallback(() => {
    setNewCardModal(false);
    dispatch(clearTags());
    setCardDetails({});
    // eslint-disable-next-line
  }, [history]);

  const handleStateMachineReset = () => {
    dispatch(clearTags());
    setCardDetails({});
    setState(machine.transition(state, events.RESET));
  };

  const handleComeBackStep = () =>
    setState(machine.transition(state, events.COME_BACK_STEP));

  const handleEditNewCard = () => {
    dispatch(clearTags());
    setState(machine.transition(state, events.RESET));
    setNewCardModal(false);
    setSelectedCard(cardDetails);
    toggleCardDetailsModal();
  };

  const handleSubmitNewCard = async newCardInfo => {
    if (newCardInfo.cardType === VIRTUAL) {
      const { isSuccess, error, data } = await service({
        dispatch
      }).issueVirtualCard({ newCardInfo, password: passwordLocal, email });

      if (isSuccess) {
        const { credit_card_id } = data;
        setSuccessMessage(isSuccess);
        sendMixPanelEvent('Created a card', {
          'Card Type': 'Virtual'
        });
        tags.map(async tag => {
          await tagService({ dispatch }).createCardTag(
            { ...tag, credit_card_id },
            false
          );
        });
        await tagService({ dispatch }).getCardTags({ cardId: credit_card_id });
        setCardDetails(data);
        isSuperAdmin
          ? setState(machine.transition(state, events.SUCEED_WITH_VIRTUAL_CARD))
          : setState(
              machine.transition(state, events.REQUEST_SUPER_ADMIN_APPROVAL)
            );
      } else {
        setCardBasicInfo(newCardInfo);
        setIssueError(error);
        setState(machine.transition(state, events.FAILED_WITH_VIRTUAL_CARD));
      }
      await service({ dispatch }).getCards();
    } else if (newCardInfo.cardType === PHYSICAL) {
      setCardBasicInfo(newCardInfo);
      /*
      SUPER ADMIN or ADMIN requesting a card for another user 
      OR 
      ADMIN requesting card for themself 
      */
      if (!newCardInfo?.requestingCardForSelf || isAdmin) {
        const { isSuccess, error, data } = await service({
          dispatch
        }).issuePhysicalCard({
          ...newCardInfo,
          password: passwordLocal,
          email
        });

        if (isSuccess) {
          setCardDetails(data);
          isSuperAdmin
            ? setState(
                machine.transition(state, events.REQUEST_PIN_FROM_CARD_OWNER)
              )
            : setState(
                machine.transition(state, events.REQUEST_SUPER_ADMIN_APPROVAL)
              );
        } else {
          setIssueError(error);
          setState(machine.transition(state, events.FAILED_WITH_PHYSCIAL_CARD));
        }
        await service({ dispatch }).getCards();
      } else {
        //Super Admin requesting a card themselves
        const { isSuccess, personAddress } = await service().getPersonAddress();
        const {
          isSuccess: isStatesSuccess,
          addressStates
        } = await service().getAddressStates();
        if (isStatesSuccess) {
          setAddressStates(addressStates);
        }
        if (isSuccess) {
          setDeliveryInfo(personAddress);
          sendMixPanelEvent('Created a card', {
            'Card Type': 'Physical'
          });
        }

        setState(machine.transition(state, events.NEEDED_ADDRESS));
      }
    }
  };

  const handleSubmitDeliveryAddress = async deliveryAddress => {
    //SUPER ADMIN setting their card delivery address
    const { isSuccess, error, data } = await service({
      dispatch
    }).issuePhysicalCard({
      ...cardBasicInfo,
      deliveryAddress,
      password: passwordLocal,
      email
    });

    if (isSuccess) {
      setCardDetails(data);
      setState(machine.transition(state, events.SUCEED_WITH_PHYSCIAL_CARD));
    } else {
      setDeliveryInfo(deliveryAddress);
      setIssueError(error);
      setState(machine.transition(state, events.FAILED_WITH_PHYSCIAL_CARD));
    }
    await service({ dispatch }).getCards();
  };

  const handleAuth = async ({ password }) => {
    const { isSuccess, error } = await service().authUser({
      email,
      password
    });

    if (isSuccess) {
      setPasswordLocal(password);
      setState(machine.transition(state, events.AUTHENTICATED));

      await service({ dispatch }).getUsers();
    } else {
      setAuthError(error);
    }
  };

  const saveCardTag = async tag => {
    const existingTag = tags.find(t => t.tagLabel === tag.tagLabel);
    if (!existingTag) {
      dispatch(addTagAction({ tag }));
    } else return;
  };

  useEffect(() => {
    if (location?.state?.authenticated)
      setState(machine.transition(state, events.AUTHENTICATED));
    // eslint-disable-next-line
  }, []);

  const getModalBasedOnState = () => {
    switch (state) {
      case states.AVAILABLE_CARD_LIMIT_REACHED:
        return (
          <InformationModal
            primaryButtonText="Entendido"
            onPrimaryButtonClick={handleModalClose}
            title={getTitleOfErrorMessage({ internal_error_code: '409-11' })}
            description={getBodyOfErrorMessage({
              internal_error_code: states.AVAILABLE_CARD_LIMIT_REACHED
            })}
          />
        );
      case states.AUTHENTICATION:
        return (
          <ModalAuth
            handleSubmit={handleAuth}
            handleModalClose={handleModalClose}
            authError={authError}
          />
        );
      case states.PROVIDE_BASIC_INFO:
        return (
          <CardDetails
            handleModalClose={handleModalClose}
            handleSubmit={handleSubmitNewCard}
            initialValues={cardBasicInfo}
            handleSaveTag={saveCardTag}
            tags={tags}
          />
        );
      case states.VIRTUAL_CARD_SUCCESS:
        return (
          <CardSuccessModal
            details={cardDetails}
            handleModalClose={handleModalClose}
            handleCreateNewCard={handleStateMachineReset}
            handleEditNewCard={handleEditNewCard}
            title={VirtualCardSuccessMessage}
          />
        );
      case states.VIRTUAL_CARD_FAILURE:
        return (
          <InformationModal
            primaryButtonText="Entendido"
            onPrimaryButtonClick={handleModalClose}
            secondaryButtonText="Regresar"
            onSecondaryButtonClick={handleComeBackStep}
            title={getTitleOfErrorMessage(issueError)}
            description={getBodyOfErrorMessage(issueError)}
          />
        );
      case states.PHYSCIAL_CARD_ADDRESS:
        return (
          <DeliveryAddress
            handleSubmit={handleSubmitDeliveryAddress}
            handleSecondaryAction={deliveryAddress => {
              setDeliveryInfo(deliveryAddress);
              handleComeBackStep();
            }}
            initialValues={deliveryInfo}
            addressStates={addressStates}
          />
        );
      case states.PHYSCIAL_CARD_SUCCESS:
        return (
          <CardSuccessModal
            details={cardDetails}
            handleModalClose={handleModalClose}
            handleCreateNewCard={handleStateMachineReset}
            handleEditNewCard={handleEditNewCard}
            title={PhysicalCardSuccessMessage}
          />
        );
      case states.PHYSCIAL_CARD_FAILURE:
        return (
          <InformationModal
            primaryButtonText="Entendido"
            onPrimaryButtonClick={handleModalClose}
            secondaryButtonText="Regresar"
            onSecondaryButtonClick={handleComeBackStep}
            title={getTitleOfErrorMessage(issueError)}
            description={getBodyOfErrorMessage(issueError)}
          />
        );
      case states.PENDING_SUPER_ADMIN_APPROVAL:
        return (
          <InformationModal
            primaryButtonText="Continuar"
            onPrimaryButtonClick={handleModalClose}
            title={'La tarjeta debe ser autorizada por el Super Admin'}
            description={
              'Se ha enviado un correo al Super Admin para que autorice esta tarjeta. Una vez que la autorice, podrá ser utilizada por el usuario.'
            }
          />
        );
      case states.PENDING_CARD_PIN_CONFIRMATION:
        return (
          <InformationModal
            primaryButtonText="Continuar"
            onPrimaryButtonClick={handleModalClose}
            title={getTitleOfInformationMessage(isSuperAdmin)}
            description={getBodyOfInformationMessage(isSuperAdmin)}
          />
        );
      default:
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      showCrossSign={false}
      disableBackDropClick={false}
      showCloseOnModal
    >
      {getModalBasedOnState()}
    </Modal>
  );
};

export default NewCardModal;
