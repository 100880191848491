import { createMachine } from '../../helpers/finite-state-machine';

export const states = {
  AUTHENTICATION: 'AUTHENTICATION',
  PROVIDE_CARD_PIN_INFO: 'PROVIDE_CARD_PIN_INFO',
  PHYSCIAL_CARD_ADDRESS: 'PHYSCIAL_CARD_ADDRESS',
  PHYSCIAL_CARD_SUCCESS: 'PHYSCIAL_CARD_SUCCESS',
  PHYSCIAL_CARD_FAILURE: 'PHYSCIAL_CARD_FAILURE'
};

export const events = {
  AUTHENTICATED: 'AUTHENTICATED',
  SUCEED_WITH_CARD_PIN_INFO: 'SUCEED_WITH_PHYSCIAL_CARD',
  SUCEED_WITH_PHYSCIAL_CARD: 'SUCEED_WITH_PHYSCIAL_CARD',
  FAILED_WITH_PHYSCIAL_CARD: 'FAILED_WITH_PHYSCIAL_CARD',
  COME_BACK_STEP: 'COME_BACK_STEP',
  RESET: 'RESET'
};

export const machine = createMachine({
  initialState: states.AUTHENTICATION,
  [states.AUTHENTICATION]: {
    transitions: {
      [events.AUTHENTICATED]: {
        target: states.PROVIDE_CARD_PIN_INFO
      },
      [events.RESET]: {
        target: states.AUTHENTICATION
      }
    }
  },
  [states.PROVIDE_CARD_PIN_INFO]: {
    transitions: {
      [events.SUCEED_WITH_CARD_PIN_INFO]: {
        target: states.PHYSCIAL_CARD_ADDRESS
      },
      [events.RESET]: {
        target: states.PROVIDE_CARD_PIN_INFO
      }
    }
  },

  [states.PHYSCIAL_CARD_ADDRESS]: {
    transitions: {
      [events.SUCEED_WITH_PHYSCIAL_CARD]: {
        target: states.PHYSCIAL_CARD_SUCCESS
      },
      [events.FAILED_WITH_PHYSCIAL_CARD]: {
        target: states.PHYSCIAL_CARD_FAILURE
      },
      [events.COME_BACK_STEP]: {
        target: states.PROVIDE_CARD_PIN_INFO
      },
      [events.RESET]: {
        target: states.PROVIDE_CARD_PIN_INFO
      }
    }
  },
  [states.PHYSCIAL_CARD_SUCCESS]: {
    transitions: {
      [events.RESET]: {
        target: states.AUTHENTICATION
      }
    }
  },
  [states.PHYSCIAL_CARD_FAILURE]: {
    transitions: {
      [events.RESET]: {
        target: states.AUTHENTICATION
      },
      [events.COME_BACK_STEP]: {
        target: states.PHYSCIAL_CARD_ADDRESS
      }
    }
  }
});
