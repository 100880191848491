import { VIRTUAL } from '../constants';

export const issueVirtualCardHandler = ({ issueCard }) => async ({
  newCardInfo: { cardLimit, creditCardName, cardOwnerId },
  password,
  email
}) => {
  const { data, error } = await issueCard({
    cardType: VIRTUAL,
    cardLimit,
    creditCardName,
    cardOwnerId,
    password,
    email
  });

  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true, data: data || {} };
};
