// React
import React, { memo, useState, useEffect } from 'react';

// Styles
import {
  AccountPageContainer,
  AccountPageMenu,
  AccountPageMenuContainer
} from '../Account.styles';

// Shared Components
import Layout from '../../shared/components/Layout/Layout';
import HasPermissions from '../../../role-based-access/components/HasPermissions';

// Account Components
import AccountStatements from '../components/AccountStatements/AccountStatements';
import AccountSecurity from '../components/AccountSecurity/AccountSecurity';
import AccountDetails from '../components/AccountDetails/AccountDetails';
import AccountPageNav from '../components/AccountPageNav';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { selectUserRoleId } from '../../../selectors';

// Helpers
import useViewportWidth from '../../../hooks/useViewportWidth';
import { service } from '../service';

export const states = {
  ACCOUNT_STATEMENTS: 'ESTADOS DE CUENTA',
  ACCOUNT_SECURITY: 'SEGURIDAD'
};

const AccountPage = () => {
  const dispatch = useDispatch();
  const statementDates = useSelector(state => state.statementsReducer.dates);
  const userRoleId = useSelector(selectUserRoleId);
  const [selectedYear, setSelectedYear] = useState('');
  const [menuOptions, setMenuOptions] = useState([]);
  const [state, setState] = useState(states.ACCOUNT_STATEMENTS);
  const basicUserRoleId = 3;
  const viewportWidth = useViewportWidth();
  const isMobile = viewportWidth < 769;

  useEffect(() => {
    //if statementDates is undefined or null it gets keys from an empty object
    let statementsList = Object.keys(statementDates || {}).reverse();
    if (statementsList.length) {
      setMenuOptions(
        statementsList.map(year => {
          return {
            name: year,
            id: year
          };
        })
      );
      //sets the default selected menu option to the most recent year
      setSelectedYear(statementsList[0]);
    }
  }, [statementDates]);

  const getDates = async dispatch => {
    await service({ dispatch }).getDates();
  };

  useEffect(() => {
    if (!statementDates && userRoleId !== basicUserRoleId) {
      getDates(dispatch);
    }
    // eslint-disable-next-line
  }, []);

  const handleTabChange = state => {
    setState(state);
  };

  const getMenuBasedOnState = () => {
    switch (state) {
      case states.ACCOUNT_STATEMENTS:
        return (
          <HasPermissions
            perform={'accountStatement:view'}
            accessAction={() => (
              <AccountStatements
                menuOptions={menuOptions}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                statementDates={
                  statementDates ? statementDates[selectedYear] : []
                }
              />
            )}
            restrictedAction={() => <></>}
          />
        );
      case states.ACCOUNT_SECURITY:
        return (
          <HasPermissions
            perform={''}
            accessAction={() => <AccountSecurity isMobile={isMobile} />}
            restrictedAction={() => <></>}
          />
        );
      default:
    }
  };

  return (
    <AccountPageContainer>
      <Layout
        title="Cuenta"
        midleComponent={
          <HasPermissions
            perform={'accountStatement:view_header'}
            accessAction={() => <AccountDetails />}
            restrictedAction={() => <></>}
          />
        }
      >
        <AccountPageMenuContainer isMobile={isMobile}>
          <AccountPageNav
            isMobile={isMobile}
            state={state}
            states={states}
            handleTabChange={handleTabChange}
          />
          <AccountPageMenu>{getMenuBasedOnState()}</AccountPageMenu>
        </AccountPageMenuContainer>
      </Layout>
    </AccountPageContainer>
  );
};

export default memo(AccountPage);
