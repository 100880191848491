import React from 'react';
import PropTypes from 'prop-types';

import Row from './styles';

const FormOptionsFooter = ({ children }) => (
  <Row data-test="form-row">{children}</Row>
);

FormOptionsFooter.propTypes = {
  children: PropTypes.array.isRequired
};

export default FormOptionsFooter;
