import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

//Components
import { Button, withStyles } from '@material-ui/core';

const getHeaderAttr = ({ isHeader }) => {
  if (isHeader)
    return `
    column-gap: 8rem;
    padding: 0 0 0 2.5rem;
    max-width: 94.8rem;
    grid-template-columns: 16.4rem 68.4rem;
    grid-template-columns: 2.5fr 7.5fr;
  `;
};

const hasBorder = ({ isBorder }) => {
  if (isBorder)
    return `
    border-radius: 0 0 0.5px 0;
    border-bottom: 0.1rem solid;
    border-color: ${colors.carbon3};
  `;
};

export const Container = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  flex: 1 1 0%;
  padding: 0 1.2rem;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  @media (max-width: 768px) {
    padding-top: 4rem;

    & > h2 {
      text-align: center;
    }
  }
  @media (min-width: 769px) {
    max-width: 94.8rem;
    padding-top: 8rem;
  }
`;

export const FullScreenContentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullScreenWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MiddleComponent = styled.div`
  margin: 4rem 0 5.6rem;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 21.4rem 69.4rem;
  grid-template-columns: 2.5fr 7.5fr;
  column-gap: 4rem;
  ${getHeaderAttr}
`;

export const PeriodContainer = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
  padding: 0 0 2.5rem 2.5rem;
  ${hasBorder}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 10rem;
  }
`;

export const TitleButton = withStyles({
  root: {
    minWidth: '9rem',
    height: '3.9rem',
    borderRadius: '1.95rem',
    backgroundImage:
      'linear-gradient(79deg, rgb(247, 71, 124) 0%, rgb(237, 34, 70))',
    padding: '0px 2.6rem',
    border: 'none',
    color: 'white',
    fontFamily: 'Quicksand',
    letterSpacing: '0.18px',
    fontWeight: '600',
    cursor: 'pointer',
    outline: 'none',
    transition: 'all 300ms ease 0s',
    fontSize: '17px',
    textTransform: 'none',

    '&:hover': {
      backgroundColor:
        'linear-gradient(79deg, rgb(247, 71, 124) 0%, rgb(237, 34, 70))',

      borderColor:
        'linear-gradient(79deg, rgb(247, 71, 124) 0%, rgb(237, 34, 70))',

      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor:
        'linear-gradient(79deg, rgb(247, 71, 124) 0%, rgb(237, 34, 70))',

      borderColor:
        'linear-gradient(79deg, rgb(247, 71, 124) 0%, rgb(237, 34, 70))'
    },
    '&:focus': {
      boxShadow:
        'linear-gradient(79deg, rgb(247, 71, 124) 0%, rgb(237, 34, 70))'
    }
  }
})(Button);
