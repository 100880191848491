'use client';
import Header from '../components/header';
import Panel from '../components/panel';
import HSpacing from '../components/spacing/horizontal-spacing';
import Heading from '../components/heading';
import Center from '../components/center';
import Button from '../components/button';
import BodyText from '../components/body-text';
import Footer from '../components/footer';
import InputText from '../components/input-text';

import REGEXP from '../utils/regexp';
import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { tpaSuccessPath } from '../utils/constants';

import { service } from '../service';
import { CUSTOMER_INFO } from '../../../reducers/types';

const fontTextStyle = {
  fontWeight: 400,
  fontSize: '16px',
  letterSpacing: '0.22px',
  fontFamily: 'barlow'
};

function View({ history }) {
  const dispatch = useDispatch();
  const customerData = useSelector(state => state.userInfoReducer.customer);
  const uuid = customerData.customer.uuid;
  const { taxid } = customerData.customer.general;

  const [password, setPassword] = useState('');

  const validateTaxid = taxid => {
    const re = REGEXP.TAXID;
    return re.test(String(taxid).toUpperCase());
  };

  const validatePassword = password => {
    if (!password) return true;
    const re = REGEXP.PASSWORD.AT_LEAST_EIGHT_CHARACTERS;
    return re.test(String(password).toUpperCase());
  };

  const handleSendCredentials = async () => {
    const response = await service().complianceCredentials({
      uuid,
      taxid,
      password
    });
    if (response.isSuccess) {
      history.replace(tpaSuccessPath);
      dispatch({
        type: CUSTOMER_INFO,
        payload: {
          ...customerData,
          lifecycle: 'completed'
        }
      });
    }
  };

  return (
    <div>
      <Header />
      <HSpacing times={3} />
      <Panel>
        <HSpacing times={4} />
        <Center>
          <Heading>Ingresa tus credenciales</Heading>
        </Center>
        <HSpacing times={2} />
        <Center>
          <div
            style={{
              width: '432px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <BodyText style={fontTextStyle}>
              Al proporcionar tus credenciales del SAT, permites que Syntage
              recupere tus datos fiscales.
            </BodyText>
          </div>
        </Center>
        <HSpacing times={3} />
        <InputText
          style={{
            color: '#6B727A'
          }}
          hintText="RFC"
          value={taxid}
          onChange={() => {}}
          disabled={true}
        />
        <HSpacing times={3} />
        <InputText
          type="password"
          hintText="Contraseña"
          value={password}
          onChange={setPassword}
          errorMessage={
            validatePassword(password) ? null : 'El valor no es válido'
          }
        />
        <HSpacing times={5} />
        <Center>
          <Button
            style={{ fontWeight: 600 }}
            type="primary"
            width={432}
            label="Continuar"
            onClick={handleSendCredentials}
            disabled={
              !validateTaxid(taxid) || !validatePassword(password) || !password
            }
          />
        </Center>
      </Panel>
      <Footer />
    </div>
  );
}

export default function Page({ history }) {
  return (
    <>
      <View history={history} />
    </>
  );
}
