import React, { useState } from 'react';
import './styles.css'; // Import the CSS file for styling

const CustomCheckbox = ({onChange, text}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onChange();
  };

  return (
    <div>
      <label className="custom-checkbox-label">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className="custom-checkbox-checkmark"></span>
      </label>
      {text}
    </div>
  );
};

export default CustomCheckbox;
