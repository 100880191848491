'use client';
import Header from '../components/header';
import Panel from '../components/panel';
import HSpacing from '../components/spacing/horizontal-spacing';
import Heading from '../components/heading';
import Center from '../components/center';
import Button from '../components/button';
import Digits from '../components/digits';
import Footer from '../components/footer';
import BodyText from '../components/body-text';
import CustomCheckbox from '../components/checkbox';
// import WaitingRedLines from "@/_components/loader/waitingRedLines";
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { tpaCreditHistoryPath, tpaSyntagePath } from '../utils/constants';

import { service } from '../service';

import Geo from '../utils/Geo';

const fontTextStyle = {
  fontWeight: 400,
  fontSize: '16px',
  letterSpacing: '0.17px',
  fontFamily: 'barlow'
};

function View({ history }) {
  const [accept, setAccept] = useState(false);
  const [nip, setNip] = useState('');
  // const [buroGranted, setBuroGranted] = useState(false);
  const customerData = useSelector(state => state.userInfoReducer.customer);
  const { phone } = customerData.account.general;
  const { uuid } = customerData.customer;

  const handlerGoBack = () => {
    history.push(tpaCreditHistoryPath);
  };

  const handleSubmit = async () => {
    const agreements = await service().agreementList({
      uuid,
      type: 'unsigned'
    });
    if (agreements.isSuccess) {
      
      const agreementList = agreements.data.data.agreements;
      const buro = agreementList.find(element => element.title === 'smes_buro');
      let granted = true;
      if (buro) {
        granted = false;
        const ip = '0.0.0.0';
        const geoData = Geo.getData();
        const response = await service().agreementUpdate({
          uuid,
          agreementUuid: buro.uuid,
          lat: geoData.lat,
          lng: geoData.lng,
          ip
        });
        if (response.isSuccess) {
          granted = true;
        }
      }
      const response = await service().buroSubmit({ uuid, nip });
      if (response.isSuccess && granted) {
        history.push(tpaSyntagePath);
      }
    }
  };

  useEffect(() => {
    Geo.init();
  }, []);

  return (
    <div>
      <Header />
      <HSpacing times={3} />
      <Panel>
        <HSpacing times={4} />
        <Center>
          <div
            style={{
              width: '416px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <Heading>Autorización de consulta de historial crediticio</Heading>
          </div>
        </Center>
        <HSpacing times={2} />
        <Center>
          <div
            style={{
              width: '432px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <BodyText style={fontTextStyle}>
              Por este conducto autorizo expresamente a{' '}
              <strong>Happy Days Tech, S.A.P.I. de C.V. (“delt.ai”)</strong>{' '}
              para que por conducto de sus funcionarios facultados lleve a cabo
              Investigaciones, sobre mi comportamiento crediticio o el de la
              Empresa que represento en Trans Union de México, S. A. SIC y/o Dun
              & Bradstreet, S.A. SIC
            </BodyText>
          </div>
        </Center>
        <HSpacing times={2} />
        <Center>
          <div
            style={{
              width: '432px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <BodyText style={fontTextStyle}>
              Así mismo, declaro que conozco la naturaleza y alcance de la
              información que se solicitará, del uso que Happy Days Tech,
              S.A.P.I. de C.V. (“delt.ai”) hará de tal información y de que ésta
              podrá realizar consultas periódicas sobre mi historial o el de la
              empresa que represento, consintiendo que esta autorización se
              encuentre vigente por un período de 3 años contados a partir de su
              expedición y en todo caso durante el tiempo que se mantenga la
              relación jurídica.
            </BodyText>
          </div>
        </Center>
        <HSpacing times={2} />
        <Center>
          <div
            style={{
              width: '432px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <BodyText style={fontTextStyle}>
              En caso de que la solicitante sea una Persona Moral, declaro bajo
              protesta de decir verdad Ser Representante Legal de la empresa
              mencionada en esta autorización; manifestando que a la fecha de
              firma de la presente autorización los poderes no me han sido
              revocados, limitados, ni modificados en forma alguna.
            </BodyText>
          </div>
        </Center>
        <HSpacing times={4} />
        <Center>
          <CustomCheckbox
            text={
              <BodyText
                style={{
                  color: '#939CA9',
                  fontSize: 14
                }}
              >
                Acepto la consulta de mi buró de crédito
              </BodyText>
            }
            value={accept}
            onChange={() => setAccept(!accept)}
          />
        </Center>
        <HSpacing times={4} />
        <Center>
          <Digits
            header={
              <div>
                <div>Escribe el NIP de 4 dígitos que te enviamos a</div>
                <HSpacing times={1} />
                <div>
                  <strong>{phone}</strong>
                </div>
                <HSpacing times={2} />
              </div>
            }
            numberOfDigits={4}
            onFullfilled={setNip}
          />
        </Center>
        <Center>
          <BodyText
            style={{
              color: '#939CA9',
              fontSize: 14
            }}
          >
            <p style={{ padding: '10px' }}>
              Es el mismo NIP que usaste en el paso anterior
            </p>
          </BodyText>
        </Center>
        <HSpacing times={2} />
        <Center>
          <Button
            style={{ fontWeight: 600 }}
            type="primary"
            width={432}
            label="Acepto realizar consulta"
            onClick={handleSubmit}
            disabled={
              nip.length !== 4 || !accept || Geo.getIsEnabled() === false
            }
          />
        </Center>
        <Center>
          <Button
            style={{ fontWeight: 600 }}
            type="transparent"
            width={432}
            label="Regresar"
            onClick={handlerGoBack}
          />
        </Center>
      </Panel>
      <Footer />
    </div>
  );
}

export default function Page({ history }) {
  return (
    <>
      <View history={history} />
    </>
  );
}
