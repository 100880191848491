import React from 'react';

// Auxiliar libraries
import { useFormik } from 'formik';

// Shared Components
import Button from '../../../../shared/components/Button/Button';
import RegistrationPageLayout from '../../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';
import TextField from '../../../../shared/components/TextField/TextField';

// Paths
import { forgotPasswordPath } from '../../../constants';
import {
  registerUserTypeLink,
  registerPymeLink
} from '../../../../register/constants';

// Styles
import { StyledLink, ModalContent } from '../styles';

// Others
import { validationSchema } from '../validationSchema';

const LoginForm = ({ title, handleAuth, authError }) => {
  const initialValues = { email: '', password: '' };
  const formik = useFormik({
    initialValues,
    validationSchema
  });

  return (
    <RegistrationPageLayout
      title={title}
      description={'Ingresa a tu cuenta delt.ai'}
      isPrimaryButtonHidden
      isModalVisible
      secondaryActionComponent={
        <p>
          ¿No tienes una cuenta?{' '}
          <StyledLink
            fontSize="1.7rem"
            to={
              process.env.REACT_APP_ACTIVATE_FREELANCER_ONBOARDING === 'true'
                ? registerUserTypeLink
                : registerPymeLink
            }
          >
            Crear una cuenta
          </StyledLink>
        </p>
      }
    >
      <ModalContent>
        <TextField
          data-test="email"
          className="formField"
          label="Correo electrónico"
          name="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={authError || (formik.errors.email && formik.touched.email)}
          helperText={formik.touched.email && formik.errors.email}
          value={formik.values.email}
        />
        <TextField
          data-test="password"
          className="formField"
          label="Contraseña"
          name="password"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            authError || (formik.errors.password && formik.touched.password)
          }
          helperText={formik.touched.password && formik.errors.password}
          value={formik.values.password}
        />

        <div className="flexAlign">
          <StyledLink fontSize="1.4rem" to={forgotPasswordPath}>
            ¿Olvidaste tu contraseña?
          </StyledLink>
        </div>

        <Button
          className="formButton"
          size="lg"
          text="Continuar"
          onClick={e => {
            e.preventDefault();
            handleAuth(formik.values.email, formik.values.password);
          }}
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
        />
      </ModalContent>
    </RegistrationPageLayout>
  );
};

export default LoginForm;
