export const CompanyRegister = ({
  companyTypeId,
  name,
  surname1,
  surname2,
  rfc,
  companyCommercialName,
  companyLegalName,
  website,
  phoneNumber,
  email,
  password
}) =>
  Object.freeze({
    name: name,
    surname_1: surname1,
    surname_2: surname2,
    rfc: rfc,
    website_url: website,
    phone_number: phoneNumber,
    email: email,
    password: password,
    company_legal_name: companyLegalName,
    company_commercial_name: companyCommercialName,
    company_type_id: companyTypeId
  });

export const FreelancerRegister = ({
  companyTypeId,
  name,
  surname1,
  surname2,
  rfc,
  website,
  phoneNumber,
  email,
  password
}) =>
  Object.freeze({
    name: name,
    surname_1: surname1,
    surname_2: surname2,
    rfc: rfc,
    website_url: website,
    phone_number: phoneNumber,
    email: email,
    password: password,
    company_type_id: companyTypeId
  });
