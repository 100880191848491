import { loginUserHandler } from './eventHandlers/loginUser';
import { sendResetPasswordHandler } from './eventHandlers/sendResetPasswordEmail';
import { resetPasswordHandler } from './eventHandlers/resetPassword';
import { request2FA_uri_handler } from './eventHandlers/requestTwoFactorAuthURI';
import { confirmTwoFactorDeviceHandler } from './eventHandlers/confirmTwoFactorDeviceConfiguration';
import { twoFactorAuthLoginHandler } from './eventHandlers/twoFactorAuthLogin';
import { customerGetHandler } from './eventHandlers/customerGet';

import { loginUserService } from './infrastructure/loginUser';
import { resetPasswordService } from './infrastructure/resetPassword';
import { sendResetPasswordEmailService } from './infrastructure/sendResetPasswordEmail';
import { confirmEmailHandler } from './eventHandlers/confirmEmail';
import { confirmEmailService } from './infrastructure/confirmEmail';
import { requestTwoFactorAuthURI_service } from './infrastructure/requestTwoFactorAuthURI-service';
import { confirmTwoFactorDeviceService } from './infrastructure/confirmTwoFactorDeviceConfiguration-service';
import { twoFactorLoginService } from './infrastructure/twoFactorAuthLoginService';
import { customerGetService } from './infrastructure/customerGet';

const service = dispatch => ({
  loginUser: loginUserHandler({
    loginUser: loginUserService
  }),

  sendResetPswdEmail: sendResetPasswordHandler({
    sendResetPasswordEmail: sendResetPasswordEmailService
  }),

  resetPassword: resetPasswordHandler({
    resetPassword: resetPasswordService
  }),

  confirmEmail: confirmEmailHandler({
    confirmEmail: confirmEmailService
  }),
  requestTwoFactorAuthURI: request2FA_uri_handler({
    requestTwoFactorAuthURI: requestTwoFactorAuthURI_service
  }),
  confirmTwoFactorDeviceConfiguration: confirmTwoFactorDeviceHandler({
    confirm2FA_device_service: confirmTwoFactorDeviceService
  }),
  twoFactorAuthLogin: twoFactorAuthLoginHandler({
    twoFactorAuthLoginService: twoFactorLoginService
  }),
  customerGet: customerGetHandler({
    customerGet: customerGetService,
  })
});

export { service };
