import React, { useEffect, useState } from 'react';

import { Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import EmptyState from './pages/OnboardingErrorBoundary/EmptyState';
import EnterpriseRoutesController from './enterprise-module/enterprise-routing-controller/EnterpriseRoutesController';
import FreelancerRoutesController from './freelancer-module/freelancer-routing-controller/FreelancerRoutesController';
import OnboardingErrorBoundary from './pages/OnboardingErrorBoundary/OnboardingErrorBoundary';
import Softwall from './pages/softwall/Softwall';

import {
  selectCompanyTypeId,
  selectEnterpriseSteps,
  selectFreelancerSteps,
  selectOnboardingState
} from './redux/onboardingSelectors.js';
import {
  setEnterpriseSteps,
  setFreelancerSteps
} from './redux/onboardingActions';
import {
  freelancerId,
  softwallPath,
  enterpriseAddressPath,
  enterpriseAttorneyPath,
  enterpriseBankPath,
  enterpriseInfoPath,
  enterpriseDocuments,
  enterpriseIdentity,
  freelancerAddressPath,
  freelancerBankPath,
  freelancerDocuments,
  freelancerInfoPath,
  freelancerIdentity,
  newOnboardingPath,
  INITIAL_ONBOARDING,
  ONBOARDING_COMPLETE
} from './constants';

import {
  handleMachine as enterpriseMachine,
  events as enterpriseEvents
} from './enterprise-module/state-machine/state-machine-definition';
import {
  handleMachine as freelancerManchine,
  events as freelancerEvents
} from './freelancer-module/state-machine/state-machine-definition';
import { service } from '../service';
import { setOnboardingStatus } from './redux/onboardingActions';

const prequalificationRoutes = {
  IN_REVIEW: '/in-review',
  QUALIFIED: '/interview',
  INTERVIEW_SCHEDULED: '/requirements',
  NOT_INITIALIZED: '/register/new-confirm-account',
  EMAIL_CONFIRMED: '/prequalification',
  UNQUALIFIED: '/in-review'
};

const freelancerOnboarding = {
  ADDRESS_STEP: freelancerAddressPath,
  BANK_DATA_STEP: freelancerBankPath,
  PERSONAL_DATA_STEP: freelancerInfoPath,
  IDENTITY_STEP: freelancerIdentity,
  DOCUMENTS_STEP: freelancerDocuments
};

const companyOnboarding = {
  ADDRESS_STEP: enterpriseAddressPath,
  BANK_DATA_STEP: enterpriseBankPath,
  COMPANY_DATA_STEP: enterpriseInfoPath,
  REAL_OWNERSHIP: enterpriseAttorneyPath,
  IDENTITY_STEP: enterpriseIdentity,
  DOCUMENTS_STEP: enterpriseDocuments
};

const getCompletedSteps = steps => {
  if (!steps) return [];

  const completedSteps = Object.values(steps).filter(el => {
    return el.isComplete;
  });
  return completedSteps;
};

const Component = ({ history }) => {
  const dispatch = useDispatch();

  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [steps, setSteps] = useState([]);

  const userTypeId = useSelector(selectCompanyTypeId);
  const isFreelancer = userTypeId === freelancerId;

  const latestStatus = useSelector(selectOnboardingState);
  const onboardingRoutes = isFreelancer
    ? freelancerOnboarding
    : companyOnboarding;
  const onboardingPaths = useSelector(
    isFreelancer ? selectFreelancerSteps : selectEnterpriseSteps
  );

  const events = isFreelancer ? freelancerEvents : enterpriseEvents;
  const handleMachine = isFreelancer ? freelancerManchine : enterpriseMachine;
  const updateSteps = isFreelancer ? setFreelancerSteps : setEnterpriseSteps;

  const updateProgressBar = (history, latestStatus) => {
    if (latestStatus === INITIAL_ONBOARDING) return;

    const updatedSteps = onboardingPaths;
    history.forEach(el => {
      const path = onboardingRoutes[el];
      if (path) {
        updatedSteps[path] = {
          ...onboardingPaths[path],
          isComplete: true
        };
      }

      updateSteps(updatedSteps);
    });
  };

  const getNextStatus = currentStatus => {
    let newStatus = '';
    if (currentStatus === INITIAL_ONBOARDING)
      newStatus = handleMachine(currentStatus).createMachine.value;
    else
      newStatus = handleMachine(currentStatus).createMachine.transition(
        currentStatus,
        events.GO_FORWARD_STEP
      );

    return newStatus;
  };

  const getStatus = async () => {
    const { data: status, isSuccess } = await service().getOnboardingProgress();
    let onboarding_progress;
    try {
      onboarding_progress = (status.body[0] ? status.body[0] : {}).onboarding_progress;
    } catch (error) {
      onboarding_progress = (status?.[0] ? status[0] : {}).onboarding_progress;
    }

    const latestStatus = onboarding_progress?.latest;
    const history = onboarding_progress?.history;

    if (isSuccess) {
      dispatch(setOnboardingStatus(latestStatus));
      updateProgressBar(history, latestStatus);
    }
  };

  const validateOnboardingStatus = async (
    latestStatus,
    steps,
    onboardingPaths
  ) => {
    if (latestStatus === null) {
      return;
    }
    const showSoftwall = steps.length === Object.values(onboardingPaths).length;

    if (showSoftwall || latestStatus === ONBOARDING_COMPLETE) {
      history.push(softwallPath);
      return;
    }

    const pathname = history.location.pathname;
        const path = prequalificationRoutes[latestStatus]
      ? prequalificationRoutes[latestStatus]
      : onboardingRoutes[getNextStatus(latestStatus)];

    if (
      !path &&
      latestStatus !== 'DOCUMENTS_STEP' &&
      latestStatus !== 'ONBOARDING_COMPLETE'
    ) {
      setShowErrorComponent(true);
      return;
    }

    if (pathname.includes(newOnboardingPath)) {
      if(prequalificationRoutes.EMAIL_CONFIRMED === path){
        history.push(`${prequalificationRoutes.IN_REVIEW}/5`);
      }else{
        history.push(path);
      }
    }

    setShowErrorComponent(false);
  };

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    return setSteps(getCompletedSteps(onboardingPaths));
  }, [latestStatus, onboardingPaths]);

  useEffect(() => {
    validateOnboardingStatus(latestStatus, steps, onboardingPaths);
  }, [latestStatus, steps, onboardingPaths]);

  return (
    <>
      {latestStatus && onboardingPaths ? (
        isFreelancer ? (
          <FreelancerRoutesController />
        ) : (
          <EnterpriseRoutesController />
        )
      ) : (
        <></>
      )}

      {showErrorComponent ? (
        <EmptyState onPrimaryButtonClick={getStatus} />
      ) : null}
    </>
  );
};

const Onboarding = props => (
  <OnboardingErrorBoundary
    component={<Component {...props} />}
    name="Onboarding"
    isModalVisible
  />
);

export default Onboarding;
