import styled from 'styled-components';

import { fonts, colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const Button = styled.button`
  ${fonts.quickSandSemiBold}
  font-size: 1.7rem;
  color: ${colors.carbon1};
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  &:hover {
    color: ${colors.carbon};
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
`;
