/* eslint-disable no-case-declarations */
import {
  SET_CURRENT_STEP,
  SET_IS_UPDATED,
  SET_IS_UPDATE_REQUIRED,
  SET_LAST_STEP,
  SET_USER_TYPE,
  PERSIST_FORM_DATA,
  PURGE,
} from './types';

const initialState = {
  currentStep: 0,
  lastStep: 0,
  userType: null,
  isUpdateRequired: false,
  isUpdated: false,
  stepsDescriptionFreelancer: {
    'Incomplete Profile': 0,
    'Address Form': 1,
    Bankings: 2,
    'Documents Form': 3,
    'Id Verification': 4,
    Calendly: 5,
    softwall: 6,
    uploadedDocs: 7,
  },
  stepsDescriptionPyme: {
    'Incomplete Profile': 0,
    'Address Form': 1,
    Bankings: 2,
    'Documents Form': 3,
    'Person Types Form': 4,
    'Id Verification': 5,
    Calendly: 6,
    softwall: 7,
    uploadedDocs: 8,
  },
  steps: {
    0: {
      isActive: true,
      formData: null,
      transitions: {
        freelancer: {
          nextStep: 1,
        },
        PyME: {
          nextStep: 1,
        },
      },
    },
    1: {
      isActive: false,
      formData: null,
      transitions: {
        freelancer: {
          nextStep: 2,
          prevStep: 0,
        },
        PyME: {
          nextStep: 2,
          prevStep: 0,
        },
      },
    },
    2: {
      isActive: false,
      formData: null,
      transitions: {
        freelancer: {
          nextStep: 3,
          prevStep: 1,
        },
        PyME: {
          nextStep: 3,
          prevStep: 1,
        },
      },
    },
    3: {
      isActive: false,
      formData: null,
      transitions: {
        freelancer: {
          nextStep: 4,
          prevStep: 2,
        },
        PyME: {
          nextStep: 4,
          prevStep: 2,
        },
      },
    },
    4: {
      isActive: false,
      formData: null,
      transitions: {
        freelancer: {
          nextStep: 5,
          prevStep: 3,
        },
        PyME: {
          nextStep: 5,
          prevStep: 3,
        },
      },
    },
    5: {
      isActive: false,
      formData: null,
      transitions: {
        freelancer: {
          prevStep: 4,
          nextStep: 6,
        },
        PyME: {
          nextStep: 6,
          prevStep: 4,
        },
      },
    },
    6: {
      isActive: false,
      formData: null,
      transitions: {
        freelancer: {
          nextStep: 7,
          prevStep: 5,
        },
        PyME: {
          nextStep: 7,
          prevStep: 5,
        },
      },
    },
    7: {
      isActive: false,
      formData: null,
      transitions: {
        freelancer: {
          prevStep: 6,
        },
        PyME: {
          nextStep: 8,
        },
      },
    },
    8: {
      isActive: false,
      formData: null,
      transitions: {
        PyME: {
          prevStep: 7,
        },
      },
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case SET_LAST_STEP:
      return {
        ...state,
        lastStep: action.payload,
      };
    case SET_IS_UPDATED:
      return {
        ...state,
        isUpdated: action.payload,
      };
    case SET_IS_UPDATE_REQUIRED:
      return {
        ...state,
        isUpdateRequired: action.payload,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    case PERSIST_FORM_DATA:
      const { currentStep, formData } = action.payload;
      const { steps } = state;

      return {
        ...state,
        steps: replaceStepFormData({ steps, currentStep, formData }),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

const replaceStepFormData = ({ steps, currentStep, formData }) => ({
  ...steps,
  [currentStep]: {
    ...steps[currentStep],
    formData,
  },
});
