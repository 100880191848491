/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

import React, { useEffect, useState, useCallback } from 'react';

// Auxiliar libraries
import log from 'loglevel';
import PropTypes from 'prop-types';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Modal from '../../../shared/components/Modal';
import ModalAuth from '../../components/modal-auth';
import ModalCardDetails from './modal-card-details';
import InformationModal from '../../../shared/components/ModalInformation';

// Others
import {
  selectEmail,
  isValidCardIdSelector,
  userTypeSelector,
  validCardIdsSelector
} from '../../redux/cards-selectors';
import { service } from '../../service';
import { machine, states, events } from './state-machine-definition';
import HasPermissions from '../../../../role-based-access/components/HasPermissions';
import { cardsLink, removeTagLink } from '../../constants';
import { service as tagService } from '../../../tags/service';
import { clearTags } from '../../../tags/redux/tag-action';
import { tagsSelector } from '../../../tags/redux/tag-selectors';

const CardDetailsModal = ({
  cardId,
  cardDetailsModal,
  setCardDetailsModal,
  toggleReportCardLostModal,
  toggleRemoveTagModal,
  toggleBlockCardModal,
  toggleUnblockCardModal,
  toggleCreateCardPinModal,
  setTag
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cardDetails, setCardDetails] = useState();
  const [authError, setAuthError] = useState(null);
  const [passwordLocal, setPasswordLocal] = useState(null);
  const [state, setState] = useState(machine.value);
  const email = useSelector(selectEmail);
  const validCardIds = useSelector(validCardIdsSelector);
  const isValidCardId = useSelector(isValidCardIdSelector(cardId));
  const userType = useSelector(userTypeSelector);
  const tags = useSelector(tagsSelector);

  useEffect(() => {
    const resetModalState = () =>
      setState(machine.transition(state, events.RESET));

    return resetModalState;
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleModalClose = useCallback(() => {
    setCardDetailsModal(false);
    dispatch(clearTags());
    // eslint-disable-next-line
  }, [history]);

  if (!isValidCardId) {
    log.warn(
      `Invalid card id ${cardId} passed as url parameter! Valid ones: ${validCardIds.join(
        ', '
      )}`
    );
    return <Redirect to={cardsLink} />;
  }

  const handleComeBackStep = () =>
    setState(machine.transition(state, events.COME_BACK_STEP));

  const handleSafeModalClose = editedDetails => {
    setCardDetails(editedDetails);
    setState(machine.transition(state, events.CLOSING_DURING_EDIT));
  };

  const handleEditCardDetails = async updatedCard => {
    const { isSuccess } = await service().updateCard({
      updatedCard,
      password: passwordLocal,
      email
    });

    if (isSuccess) {
      await service({ dispatch }).getCards();
      handleModalClose();
    }
  };

  const handleAuth = async ({ password }) => {
    const { isSuccess, card, error } = await service().getCardDetails({
      email,
      password,
      cardId
    });

    if (isSuccess) {
      await tagService({ dispatch }).getCardTags({ cardId });
      setCardDetails(card);
      setPasswordLocal(password);
      setState(machine.transition(state, events.AUTHENTICATED));
    } else {
      setAuthError(error);
    }
  };

  const saveCardTag = async tag => {
    await tagService({ dispatch }).createCardTag({
      ...tag,
      credit_card_id: cardId
    });
  };

  const openDeleteTagModal = tag => {
    setTag({
      isNewCard: false,
      tag: tag
    });
    toggleRemoveTagModal(cardDetails);
  };

  const getModalBasedOnState = () => {
    switch (state) {
      case states.AUTHENTICATION:
        return (
          <ModalAuth
            handleSubmit={handleAuth}
            handleModalClose={handleModalClose}
            authError={authError}
          />
        );
      case states.VIEW_CARD_DETAILS:
      case states.EDITING_CARD_DETAILS:
        return (
          // If user does not have "card:edit" permission, disable edit ability - isEditingEnabled={false}
          <HasPermissions
            role={userType}
            perform="card:edit"
            accessAction={() => (
              <ModalCardDetails
                handleSubmit={handleEditCardDetails}
                handleSafeModalClose={handleSafeModalClose}
                handleModalClose={handleModalClose}
                initialValues={cardDetails}
                isEditedBefore={state === states.EDITING_CARD_DETAILS}
                isEditingEnabled
                handleSaveTag={saveCardTag}
                handleDeleteTag={openDeleteTagModal}
                tags={tags}
                toggleCreateCardPinModal={toggleCreateCardPinModal}
                toggleReportCardLostModal={toggleReportCardLostModal}
                toggleBlockCardModal={toggleBlockCardModal}
                toggleUnblockCardModal={toggleUnblockCardModal}
              />
            )}
            restrictedAction={() => (
              <ModalCardDetails
                handleSubmit={() => {}}
                handleSafeModalClose={handleModalClose}
                handleModalClose={handleModalClose}
                initialValues={cardDetails}
                isEditingEnabled={false}
                tags={tags}
                toggleCreateCardPinModal={toggleCreateCardPinModal}
                toggleReportCardLostModal={toggleReportCardLostModal}
                toggleBlockCardModal={toggleBlockCardModal}
                toggleUnblockCardModal={toggleUnblockCardModal}
              />
            )}
          />
        );
      case states.WARNING_BEFORE_LOOSING_CHANGES:
        return (
          <InformationModal
            primaryButtonText="Sí, salir sin guardar cambios"
            onPrimaryButtonClick={handleModalClose}
            secondaryButtonText="Regresar"
            onSecondaryButtonClick={handleComeBackStep}
            title="Tienes cambios sin guardar"
            description="¿Deseas salir sin guardar tus cambios?"
          />
        );
      default:
    }
  };

  return (
    <Modal
      open={cardDetailsModal}
      onClose={handleModalClose}
      showCrossSign={false}
      disableBackDropClick={false}
      showCloseOnModal
    >
      {getModalBasedOnState()}
    </Modal>
  );
};

CardDetailsModal.propTypes = {
  history: PropTypes.object,
  toggleReportCardLostModal: PropTypes.func.isRequired
};

export default CardDetailsModal;
