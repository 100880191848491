import styled, { css } from 'styled-components';
import { fonts, colors } from '../../../../Dashboard4.0/assets/styles/styles';

const getFontSize = ({ size }) => {
  if (size === 'large') return fontSizeLarge;

  return fontSizeMedium;
};

const fontSizeLarge = css`
  font-size: 2.4rem;
`;
const fontSizeMedium = css`
  font-size: 1.7rem;
`;

const getFontColor = ({ type }) => {
  if (type === 'success') return colors.success;
  if (type === 'primary') return colors.primary;

  return colors.detailsBlack;
};

export const TitleContainer = styled.h2`
  ${fonts.quickSandSemiBold}
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  margin-top: 0;
  margin-bottom: 0;
  color: ${getFontColor};
  ${getFontSize}

  &.cardsTitle {
    margin: 0;
    position: absolute;
  }
`;
