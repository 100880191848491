/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Shared components
import Overlay from '../Overlay';

// Card Activation Components
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import CardActivationInfo from './CardActivationInfo';

// Styled Components
import { CardActivationWrapper } from './CardActivation.styles';

// Services
import {
  activateCard,
  requestActivationCode
} from '../../../../Services/CreditServices';

const CardActivation = ({ open, userEmail, handleOnClose }) => {
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const [formCard, setFormCard] = useState({ email: userEmail });

  const onClose = () => {
    handleOnClose();
  };

  const updateForm = (key, value) => {
    formCard[key] = value;
  };

  const activateCreditCard = password => {
    let form = formCard;
    form.password = password;

    activateCard(form).then(res => {
      if (res.status === 201) setStep(4);
      else if (res.status === 409) setError(true);
      else setStep(1);
    });
  };

  const requestCode = () => {
    let form = formCard;

    requestActivationCode(form).then(res => {
      if (res.status === 200) setStep(2);
      else {
        setError(true);
        setStep(1);
      }
    });
  };

  return (
    <CardActivationWrapper>
      <Overlay
        open={open}
        className={error ? 'overlay overlay-center' : 'overlay'}
        disableBackDropClick
        showCrossSign
        onClose={handleOnClose || onClose}
      >
        <>
          {step === 1 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Step1
                setStep={setStep}
                setForm={el => updateForm('last_four_digits', el)}
                requestActivationCode={el => requestCode(el)}
              />
              <CardActivationInfo />
            </div>
          )}

          {step === 2 && (
            <Step2
              setStep={setStep}
              setForm={el => updateForm('activation_code', el)}
            />
          )}

          {step === 3 && (
            <Step3
              setStep={setStep}
              activateCard={el => activateCreditCard(el)}
            />
          )}

          {step === 4 && <Step4 close={onClose} />}
        </>
      </Overlay>
    </CardActivationWrapper>
  );
};

CardActivation.propTypes = {
  open: PropTypes.bool
};

export default CardActivation;
