import axios from 'axios';
import { getAuthToken } from './authService';

export const defaultTimeOut = process.env.REACT_APP_AXIOS_TIMEOUT;

const headers = {
  'x-api-key': process.env.REACT_APP_API_KEY,
  Accept: `application/json; version=${process.env.REACT_APP_API_VERSION}`
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
  headers: headers
});

export const customAxiosInstance = (timeout, contentType) => {
  axiosInstance.defaults.timeout = timeout || defaultTimeOut;
  axiosInstance.defaults.headers['Content-Type'] = contentType || 'application/json';

  return axiosInstance;
};

axiosInstance.interceptors.request.use(
  (config) => {
    let token = `Bearer ${getAuthToken()}`;
    if (getAuthToken()) {
      config.headers = { ...config.headers, Authorization: token };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
