/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import FilterBar from '../../../shared/components/FilterBar/FilterBar';
import { FilterBarContainer } from './SelectBeneficiary.styles';
import Icon from '../../../shared/components/Icon/Icon';

const BeneficiaryFilter = ({
  searchFn,
  searchPlaceholder,
  downloadBeneficiariesCsv
}) => {
  return (
    <FilterBarContainer>
      <FilterBar
        searchIcon="search"
        searchPlaceholder={searchPlaceholder}
        searchFn={searchFn}
        data-test="searchBarComponent"
      />
      <div
        className="downloadCsv filterBarHideItem"
        onClick={downloadBeneficiariesCsv}
      >
        <Icon icon="download" />
        <span>Descargar beneficiarios</span>
      </div>
    </FilterBarContainer>
  );
};

BeneficiaryFilter.propTypes = {
  goToBatchTransfers: PropTypes.func,
  searchFn: PropTypes.func,
  searchPlaceholder: PropTypes.string
};

export default BeneficiaryFilter;
