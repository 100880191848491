import React, { useEffect, useState } from 'react';

// Auxiliary libraries
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

// Components
import HasPermissions from '../../../../role-based-access/components/HasPermissions';
import {
  StyledButton as Button,
  StyledTag as Tag,
  Label,
  DeleteBtn
} from './styles';
import TextField from '../../../shared/components/TextField';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import TextFieldAmount from '../../../shared/components/TextFieldAmount/TextFieldAmount';
import CreatableSelect from '../../../shared/components/EditableSelect';

// Helpers
import {
  SUPER_ADMIN_ROLE_ID,
  ADMIN_ROLE_ID,
  BASIC_ROLE_ID,
  ENGINEER_ROLE_ID
} from '../../constants';
import { validationSchema } from './validation';
import {
  getDepartmentsService,
  createDepartmentsService
} from '../../services/department-service';

// Selectors
import {
  creditSelector,
  userSelector,
  userIdSelector,
  userRoleIDSelector
} from '../../redux/crud-selectors';

import svg from '../../images/warn.svg';

const getEditPermissions = (currentUserStatus = [], accountHolderRoleID) => {
  return (
    accountHolderRoleID === SUPER_ADMIN_ROLE_ID ||
    (accountHolderRoleID === ADMIN_ROLE_ID &&
      !currentUserStatus.includes('Pending approval'))
  );
};

const Form = ({ disabled, handleDelete, type, onSubmit, submitText }) => {
  const [newRoleId, setNewRoleId] = useState(-1);
  const [initialFlag, setInitialFlag] = useState(true);
  const [departments, setDepartments] = useState([]);

  const creditLine = useSelector(creditSelector);
  const user = useSelector(userSelector);
  const accountHolderRoleID = useSelector(userRoleIDSelector);
  const isEditable = getEditPermissions(user.statuses, accountHolderRoleID);
  const isSuperAdmin = user.roleId === SUPER_ADMIN_ROLE_ID;

  const currentUserId = useSelector(userIdSelector);

  const formik = useFormik({
    initialValues: {
      creditLine,
      name: user.name || '',
      surname1: user.surname_1 || '',
      surname2: user.surname_2 || '',
      department: user.departmentId || '',
      departmentName: user.departmentName || '',
      position: user.position || '',
      spendingLimit: user.spendingLimit || '',
      email: user.email || '',
      adminFlag: user.adminFlag || -1,
      roleId: user.roleId || -1,
      phone: user.phone || ''
    },
    validationSchema,
    validateOnMount: false
  });

  const getDepartments = async () => {
    const { departments } = await getDepartmentsService();
    setDepartments(departments);
  };

  const createDeprtment = async departmentName => {
    const { department } = await createDepartmentsService(departmentName);
    setDepartments([...departments, department]);

    return department.departmentId;
  };

  useEffect(() => {
    getDepartments();

    if (formik.values.roleId > 0) {
      setNewRoleId(formik.values.roleId);
    }

    // eslint-disable-next-line
  }, []);

  const handleUserType = roleId => {
    if (disabled || !isEditable) return;

    setInitialFlag(false);
    setNewRoleId(roleId);

    const adminFlag = roleId === ADMIN_ROLE_ID;
    formik.setFieldValue('adminFlag', adminFlag);
    formik.setFieldValue('roleId', roleId);
  };

  useEffect(() => {
    if (currentUserId === user.userId && !!user.roleId) {
      setInitialFlag(false);
      formik.setFieldValue('adminFlag', user.adminFlag);
      formik.setFieldValue('roleId', user.roleId);
    }
  }, []);

  useEffect(() => {
    if (type === 'update') {
      setInitialFlag(false);
    }
  }, []);

  const handleDeleteConfirmation = () => {
    handleDelete(user.userId, user.userName);
  };

  const manageSubmit = e => {
    e.preventDefault();
    onSubmit(e, formik);
  };

  const handleChangeWithStripping = ({ target: { value, name } }) => {
    if (value && value.length > 40) {
      return;
    }

    formik.setFieldValue(name, value);
  };

  return (
    <form onSubmit={manageSubmit}>
      <TextField
        label="Email"
        name="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.errors.email && formik.touched.email}
        helperText={formik.touched.email && formik.errors.email}
        value={formik.values.email}
        disabled={type !== 'create'}
      />
      <TextField
        label="Nombre(s)"
        name="name"
        onBlur={formik.handleBlur}
        onChange={handleChangeWithStripping}
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        value={formik.values.name}
        disabled={disabled || !isEditable}
      />
      <TextField
        label="Apellido paterno"
        name="surname1"
        onBlur={formik.handleBlur}
        onChange={handleChangeWithStripping}
        error={formik.errors.surname1 && formik.touched.surname1}
        helperText={formik.touched.surname1 && formik.errors.surname1}
        value={formik.values.surname1}
        disabled={disabled || !isEditable}
      />
      <TextField
        label="Apellido materno"
        name="surname2"
        onBlur={formik.handleBlur}
        onChange={handleChangeWithStripping}
        error={formik.errors.surname2 && formik.touched.surname2}
        helperText={formik.touched.surname2 && formik.errors.surname2}
        value={formik.values.surname2}
        disabled={disabled || !isEditable}
      />
      <div className="flexContainer">
        {(type === 'create' || type === 'update') && isEditable ? (
          <CreatableSelect
            label="Departamento"
            name="department"
            onChange={departmentId =>
              formik.setFieldValue('department', departmentId)
            }
            onCreate={createDeprtment}
            onBlur={formik.handleBlur}
            error={formik.errors.department && formik.touched.department}
            helperText={formik.touched.department && formik.errors.department}
            value={formik.values.department}
            options={departments.map(({ departmentId, departmentName }) => ({
              label: departmentName,
              value: departmentId
            }))}
            isCreatable
          />
        ) : (
          <TextField
            label="Departamento"
            name="departmentName"
            value={formik.values.departmentName}
            disabled={disabled || !isEditable}
          />
        )}
        <TextField
          label="Posición"
          name="position"
          onBlur={formik.handleBlur}
          onChange={handleChangeWithStripping}
          error={formik.errors.position && formik.touched.position}
          helperText={formik.touched.position && formik.errors.position}
          value={formik.values.position}
          disabled={disabled || !isEditable}
        />
      </div>
      {newRoleId !== ENGINEER_ROLE_ID && (
        <TextFieldAmount
          label={<div>Límite de gasto ($) </div>}
          name="spendingLimit"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.errors.spendingLimit && formik.touched.spendingLimit}
          helperText={
            formik.touched.spendingLimit && formik.errors.spendingLimit
          }
          value={formik.values.spendingLimit}
          disabled={disabled || !isEditable}
          endAdornment={
            <Tooltip
              className="tooltip"
              label="Límites de gasto"
              text={
                <div>
                  Para más información, lee este artículo:{' '}
                  <a
                    href="https://ayuda.delt.ai/es/articles/4988036-limites-de-gasto-como-funcionan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ¿Cómo funcionan?
                  </a>
                </div>
              }
            />
          }
        />
      )}

      {type === 'update' ? (
        <TextField
          label="Número de teléfono"
          name="phone"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.errors.phone && formik.touched.phone}
          helperText={formik.touched.phone && formik.errors.phone}
          value={formik.values.phone}
          disabled={disabled || !isEditable}
        />
      ) : null}
      <div className="optionsContainer">
        {user.roleId === SUPER_ADMIN_ROLE_ID ? (
          <>
            <Label>Tipo de usuario: </Label>
            <div className="tagsContainer stretched">
              <Tag text="Super Admin" color="blue" className="tagActive" />
            </div>
          </>
        ) : (
          <>
            <Label>Tipo de usuario: </Label>
            <div className="tagsContainer">
              <Tag
                text="Admin"
                color="blue"
                className={newRoleId === ADMIN_ROLE_ID ? 'tagActive' : ''}
                onClick={() => handleUserType(ADMIN_ROLE_ID)}
              />
              <Tag
                text="Básico"
                color="blue"
                className={newRoleId === BASIC_ROLE_ID ? 'tagActive' : ''}
                onClick={() => handleUserType(BASIC_ROLE_ID)}
              />
              <Tag
                text="Ingeniero"
                color="blue"
                className={newRoleId === ENGINEER_ROLE_ID ? 'tagActive' : ''}
                onClick={() => handleUserType(ENGINEER_ROLE_ID)}
              />
            </div>
          </>
        )}
        <Tooltip
          label="Permisos"
          text={
            <div>
              Para más información, lee este artículo:{' '}
              <a
                href={process.env.REACT_APP_PERMISSIONS_ARTICLE}
                target="_blank"
                rel="noopener noreferrer"
              >
                ¿Qué permisos tiene cada tipo de usuario?{' '}
              </a>
            </div>
          }
          className="tooltip"
        />
      </div>
      <HasPermissions
        perform="user:edit"
        accessAction={() => (
          <>
            {!isSuperAdmin &&
            type === 'update' &&
            currentUserId !== user.userId ? (
              <div className="deleteContainer">
                <img src={svg} alt="delete" />
                <DeleteBtn onClick={handleDeleteConfirmation}>
                  Eliminar usuario
                </DeleteBtn>
              </div>
            ) : null}
            <Button
              size="lg"
              text={submitText}
              type="submit"
              disabled={
                type === 'update' || type === 'create'
                  ? !formik.isValid || initialFlag || !formik.dirty
                  : !disabled
              }
            />
          </>
        )}
        restrictedAction={() => <></>}
      />
    </form>
  );
};

export default Form;
