import React from 'react';
import PropTypes from 'prop-types';

import { Button, BtnContainer } from './styles';

const ButtonSubtle = ({ children, onClick, ...otherProps }) => {
  return (
    <BtnContainer>
      <Button onClick={onClick} {...otherProps}>
        {children}
      </Button>
    </BtnContainer>
  );
};

ButtonSubtle.propTypes = {
  children: PropTypes.any,
  onclick: PropTypes.func
};

export default ButtonSubtle;
