// Handlers
import { createBankInformationHandler } from './eventHandlers/createBankInformation';
import { createLegalPersonHandler } from './eventHandlers/create-legal-person';
import { fileUploadHandler } from './eventHandlers/file-upload-handler';
import { getBankingInstitutionsHandler } from './eventHandlers/getBankingInstitutions';
import { getMissingDocumentsHandler } from './eventHandlers/get-missing-documents';
import { getRejectedDocumentsHandler } from './eventHandlers/get-rejected-documents';
import { setAddressHandler } from './eventHandlers/setAddress';
import { setEnterpriseBasicInfoHandler } from './eventHandlers/enterprise-basic-info';
import { setFreelancerInfoHandler } from './eventHandlers/set-freelancer-basic-info';
import { uploadDocumentHandler } from './eventHandlers/upload-document';

// Services
import contactCommunicationCompany from './infrastructure/contact-communication-company';
import contactCommunicationPerson from './infrastructure/contact-communication-person';
import { createBankInformationService } from './infrastructure/createBankInformation';
import createLegalPersonService from './infrastructure/create-legal-person';
import { getBankingInstitutionsService } from './infrastructure/getBankingInstitutions';
import { getMissingDocumentsService } from './infrastructure/get-missing-documents';
import { getRejectedDocumentsService } from './infrastructure/get-rejected-document';
import { setAddressService } from './infrastructure/setAddress';
import setAttorneyInLowService from './infrastructure/set-attorney-in-low';
import setEnterpriseInfoService from './infrastructure/set-enterprise-basic-info';
import setFreelancerInfoService from './infrastructure/set-freelancer-basic-info';
import updateLegalPersonService from './infrastructure/update-legal-person';
import { uploadDocumentService } from './infrastructure/upload-document';

const service = () => ({
  setAddress: setAddressHandler({
    setAddress: setAddressService
  }),
  setFreelancerBasicInfo: setFreelancerInfoHandler({
    setFreelancerBasicInfo: setFreelancerInfoService
  }),
  createBankInformation: createBankInformationHandler({
    createBankInformation: createBankInformationService
  }),
  getBankingInstitutions: getBankingInstitutionsHandler({
    getBankingInstitutions: getBankingInstitutionsService
  }),
  uploadDocument: uploadDocumentHandler({
    uploadDocument: uploadDocumentService
  }),
  setEnterpriseBasicInfo: setEnterpriseBasicInfoHandler({
    setEnterpriseBasicInfo: setEnterpriseInfoService,
    setAttorneyInLow: setAttorneyInLowService,
    uploadDocument: uploadDocumentService
  }),
  createLegalPerson: createLegalPersonHandler({
    createLegalPerson: createLegalPersonService,
    updateLegalPerson: updateLegalPersonService
  }),
  getMissingDocuments: getMissingDocumentsHandler({
    getMissingDocuments: getMissingDocumentsService
  }),
  getRejectedDocuments: getRejectedDocumentsHandler({
    getRejectedDocuments: getRejectedDocumentsService
  }),
  fileUploadManager: fileUploadHandler({
    uploadDocument: uploadDocumentService
  })
});

export { service };
