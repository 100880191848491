import React from 'react';

import Button from '../../../shared/components/Button';
import Title from '../../../shared/components/Title';

import { Container, Text } from './RegistrationComplete.styles';

const RegistrationComplete = ({ logOut }) => {
  return (
    <Container>
      <Title size="large" text="Ya falta poco" />
      <Text>
        Solo falta que el representante legal de la cuenta apruebe tu registro.
        Una vez aprobado, tendrás acceso a los poderes de delt.ai.
      </Text>
      <Button text="¡Okay!" size="lg" onClick={logOut} />
    </Container>
  );
};

export default RegistrationComplete;
