import { CardCreator } from '../domain/card-data';

const getCardCreationRequestHandler = ({ getCardsCreationRequests }) => async ({
  cardRequestId
}) => {
  const { error, data: requestsData } = await getCardsCreationRequests();

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  const cardData = requestsData.find(
    request => request.card_admin_request_id === parseInt(cardRequestId)
  );

  if (!cardData) {
    return {
      isSuccess: false
    };
  }

  const card = CardCreator({ data: cardData });

  return {
    isSuccess: true,
    card
  };
};

export { getCardCreationRequestHandler };
