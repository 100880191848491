/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import Header from '../../../shared/components/Modal/Header';
import Title from '../../../shared/components/Title';

import {
  BatchContainer,
  Content,
  SubtitleContainer
} from './BatchTransfer.styles';

const BatchContent = ({ title, subtitle, content, className }) => {
  return (
    <BatchContainer>
      <Header>
        <Title size="large" text={title} className={`title ${className}`} />
        <SubtitleContainer className="subtitleContainer">
          {subtitle}
        </SubtitleContainer>
      </Header>
      <Content>{content}</Content>
    </BatchContainer>
  );
};

BatchContent.propTypes = {
  subtitle: PropTypes.any,
  content: PropTypes.any
};

export default BatchContent;
