import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const getAmountColor = ({ color }) => {
  const fontColors = {
    success: colors.success,
    primary: colors.primary,
    default: colors.body,
    red: colors.deltai,
    white: colors.white
  };

  return !color ? fontColors['default'] : fontColors[color];
};

export const AmountContainer = styled.span`
  ${fonts.quickSandSemiBold}
  font-size: 1.7rem;
  letter-spacing: 1px;
  color: ${getAmountColor};
`;

export const DecimalsContainer = styled.span`
  ${fonts.quickSandSemiBold}
  font-size: 1.1rem;
  line-height: 1.45;
  letter-spacing: 0.65px;
`;
