import { tagActions } from './tag-action-types';

export const addTagAction = ({ tag }) => ({
  type: tagActions.ADD_TAG,
  payload: tag
});

export const addTagCreditTransactionAction = ({ tag }) => ({
  type: tagActions.ADD_TAG_CREDIT_TRANSACTION,
  payload: tag
});

export const addSpeiTagAction = ({ tag }) => ({
  type: tagActions.ADD_SPEI_TAG,
  payload: tag
});

export const removeTagAction = ({ tag }) => ({
  type: tagActions.REMOVE_TAG,
  payload: tag
});

export const setTagsAction = ({ tags }) => ({
  type: tagActions.SET_TAGS,
  payload: tags
});

export const setTagOptionsAction = ({ tags }) => ({
  type: tagActions.SET_TAG_OPTIONS,
  payload: tags
});

export const setTagsSpeiAction = ({ tags }) => ({
  type: tagActions.SET_TAGS_SPEI,
  payload: tags
});

export const setTagsCreditCardTransactionAction = ({ tags }) => ({
  type: tagActions.SET_TAGS_CARD_TRANSACTION,
  payload: tags
});

export const addTagOptionAction = ({ tag }) => ({
  type: tagActions.ADD_TAG_OPTION,
  payload: tag
});

export const clearTags = () => ({
  type: tagActions.CLEAR_TAGS
});

export const removeTagOptionAction = ({ tag }) => ({
  type: tagActions.REMOVE_TAG_OPTION,
  payload: tag.tagId
});
