import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';
import Select from 'react-select';

export const AddOption = styled.div`
  padding: 8px 12px;
  ${fonts.barlowMedium}
  font-style: normal;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.175px;
  color: ${colors.almostBlack};

  span {
    ${fonts.barlowMedium}
    font-style: normal;
    font-size: 11px;
    line-height: 27px;
    letter-spacing: 0.175px;
    text-decoration-line: underline;
    color: ${colors.almostBlack};
    float: right;
  }
`;

export const selectStyles = {
  menu: css => ({
    ...css,
    marginTop: '-10px'
  }),
  option: css => ({
    ...css,
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '27px',
    letterSpacing: 0.175,
    color: colors.almostBlack
  })
};

export const StyledSelect = styled(Select)`
  input {
    font-size: ${props => {
      return props.classes === 'formField' ? '1.7rem' : '1.2rem';
    }};
  }
`;
