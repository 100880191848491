const setColumns = (string, el) => {
  string += el;
  return string;
};

export const getGridColumns = ({ columns }) => {
  if (!columns) return;

  let string = '';
  for (let i = 0; i < columns; i += 1) {
    string = setColumns(string, '1fr ');
  }

  return `display: grid;
    grid-template-columns: ${string};
    grid-column-gap: 10%; 
  `;
};
