import React, { useState } from 'react';

import { FileInput } from '../../components/DocumentsManager/styles';
import SelfieModal from './SelfieModal';

import { getFileName } from '../../../../helpers/helpers';
import { transformKycMultipart } from '../../../../Services/softwallService';

const Selfie = ({ kycDocumentTypeId, name, keyword, onChange }) => {
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleChange = file => {
    // To match with MultiPartFileInput component format
    // src\Dashboard4.0\components\MultipartFileInput\MultipartFileInput.jsx
    const fileName = getFileName(file.name, name);
    const multiPartFile = transformKycMultipart(name, file, kycDocumentTypeId);
    const formatFile = { target: { name, value: multiPartFile, file } };
    onChange(formatFile);
    setOpen(false);
    setFileName(fileName);
  };

  return (
    <>
      <SelfieModal open={open} setOpen={setOpen} onChange={handleChange} />
      <FileInput
        key={kycDocumentTypeId}
        name={keyword}
        id={kycDocumentTypeId}
        label={name}
        type="file"
        onClick={() => setOpen(true)}
        onChange={onChange}
        documentName={keyword}
        documentId={kycDocumentTypeId}
        fileName={fileName}
      />
    </>
  );
};

export default Selfie;
