import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2.5rem 3fr;
  line-height: 1.3;
`;

export const Checkbox = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border: 1px solid
    ${({ helperText }) => (helperText ? `${colors.deltai}` : '#1c2633')};

  &:checked {
    background: ${colors.deltai};
  }
`;

export const CheckboxLabel = styled.span`
  ${fonts.quickSandMedium};
  font-size: 1.4rem;
  color: #1c2633;
`;

export const ErrorMessage = styled.div`
  ${fonts.quickSandMedium};
  font-size: 1.2rem;
  color: ${colors.deltai2};
  margin-left: 2.5rem;
`;
