import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';

import { StyledButton as Button } from '../RegistrationModal/RegistrationModal.styles';
import {
  ErrorMessage,
  FormContainer,
  Paragraph
} from './CreatePassword.styles';
import TextField from '../../../shared/components/TextField';
import Title from '../../../shared/components/Title';

import { validationSchema } from './validationSchema';

const CreatePassword = ({ createPassword, token }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [showError, setShowError] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    validateOnMount: false
  });

  const checkFormCompletion = formik => {
    return (
      formik.values.password &&
      formik.values.confirmPassword &&
      !Object.keys(formik.errors).length
    );
  };

  const validatePasswordsMatch = () => {
    return formik.values.password !== formik.values.confirmPassword;
  };

  useEffect(() => {
    if (checkFormCompletion(formik)) {
      if (validatePasswordsMatch()) {
        setIsDisabled(true);
        setShowError(true);
      } else {
        setIsDisabled(false);
        setShowError(false);
      }
      return;
    }
    setIsDisabled(true);
    // eslint-disable-next-line
  }, [formik.values, formik.errors]);

  const handleSubmit = e => {
    e.preventDefault();
    createPassword({
      password: formik.values.password,
      token: token
    });
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <div className="header">
        <Title text="Crea tu contraseña" className="formTitle" />
        <Paragraph>Recuerda mantanerla en un lugar seguro</Paragraph>
      </div>
      <TextField
        label="Contraseña"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.errors.password && formik.touched.password}
        helperText={formik.errors.password}
        type="password"
      />
      <TextField
        label="Confirmar contraseña"
        name="confirmPassword"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.errors.confirmPassword && formik.touched.confirmPassword}
        helperText={formik.errors.confirmPassword}
        type="password"
      />
      {showError ? (
        <ErrorMessage>Las contraseñas deben coincidir</ErrorMessage>
      ) : null}
      <Button size="lg" text="Continuar" disabled={isDisabled} type="submit" />
    </FormContainer>
  );
};

export default CreatePassword;
