/* eslint-disable react/require-default-props */
import React, { memo } from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// Styled
import {
  LabelContainer,
  MainTitle,
  SecondaryText
} from './ProofOfPayments.styles';
import { CustomMain, CustomSecondary } from './HighQualityVoucher.styles';

const LabelGroup = ({ align, mainText, secondaryText, hightQuality }) => {
  return hightQuality ? (
    <LabelContainer align={align}>
      <CustomMain>{mainText}</CustomMain>
      <CustomSecondary>{secondaryText}</CustomSecondary>
    </LabelContainer>
  ) : (
    <LabelContainer align={align}>
      <MainTitle>{mainText}</MainTitle>
      <SecondaryText>{secondaryText}</SecondaryText>
    </LabelContainer>
  );
};

LabelGroup.propTypes = {
  align: PropTypes.string,
  mainText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired
};

export default memo(LabelGroup);
