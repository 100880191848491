import { STATUS, EXPENSE, INCOME } from '../constants';

export const getStatus = (invoiceStatus, paymentDate) => {
  if (invoiceStatus === STATUS.VIGENTE && paymentDate === null) {
    return STATUS.pending;
  }

  if (invoiceStatus === STATUS.VIGENTE && paymentDate) {
    return STATUS.success;
  }

  if (invoiceStatus === STATUS.CANCELADO) {
    return STATUS.cancelled;
  }
};

export const getActions = (invoiceStatus, paymentDate, invoice_type) => {
  // TODO : Connect backend endpoints for invoice interactions
  return [
    {
      icon: 'download',
      class: 'download xml',
      helperTooltip: 'Descargar comprobante',
      text: 'XML',
      method: () => {
        console.log('Download XML');
      }
    },
    {
      icon: 'download',
      class: 'download pdf',
      text: 'PDF',
      method: () => {
        console.log('Download PDF');
      }
    },
    {
      icon: 'cancel',
      text: 'MARK AS UNPAID',
      disabled: getStatus(invoiceStatus, paymentDate) === STATUS.pending,
      method: () => {
        console.log('Mark as unpaid');
      }
    },
    {
      icon: 'doubleTicks',
      text: 'MARK AS PAID',
      class: 'paid',
      disabled: getStatus(invoiceStatus, paymentDate) === STATUS.success,
      method: () => {
        console.log('Mark as paid');
      }
    },
    {
      icon: 'money',
      text: 'PAY NOW',
      disabled:
        getStatus(invoiceStatus, paymentDate) === STATUS.success ||
        invoice_type === INCOME,
      method: () => {
        console.log('Pay now');
      }
    }
  ];
};
