"use client";
import React from "react";

import Header from "../components/header";
import Panel from "../components/panel";
import HSpacing from "../components/spacing/horizontal-spacing";
import BodyText from "../components/body-text";
import Heading from "../components/heading";
import Center from "../components/center";
import Button from "../components/button";
import Footer from '../components/footer';

import { useSelector } from 'react-redux';

import {tpaAuthorizationPath} from '../utils/constants';

const fontTextStyle = {
    fontWeight: 400,
    fontSize: "16px",
    letterSpacing: "0.22px",
    fontFamily: "barlow",
};

function View({history}) {
    const customerData = useSelector(state => state.userInfoReducer.customer);
    const {phone} = customerData.account.general;

    const handlerGoAhead = () => {
        history.replace(tpaAuthorizationPath)  ;
    }

    return (
        <div>
            <Header />
            <HSpacing times={3} />
            <Panel>
                <HSpacing times={4} />
                <Center>
                    <Heading>Historial crediticio</Heading>
                </Center>
                <HSpacing times={2} />
                <Center>
                    <div
                        style={{
                            width: "432px",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <BodyText style={fontTextStyle}>
                            Necesitamos tener acceso a la información crediticia
                            de tu Empresa. Esta consulta nos ayuda a evaluar y
                            diseñar la oferta que mejor se adecua al perfil de
                            crédito de tu Empresa.
                        </BodyText>
                    </div>
                </Center>
                <HSpacing times={3} />
                <Center>
                    <div
                        style={{
                            width: "432px",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <BodyText style={fontTextStyle}>
                            Al continuar, acepto la relación jurídica como
                            prospecto de{" "}
                            <strong>
                                Happy Days Tech, S.A.P.I. de C.V. (“delt.ai”)
                            </strong>{" "}
                            y autorizo a realizar, consultar y evaluar
                            información por mecanismos digitales tales como NIP.
                        </BodyText>
                    </div>
                </Center>
                <HSpacing times={3} />
                <Center>
                    <div
                        style={{
                            width: "432px",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <BodyText style={fontTextStyle}>
                            Te enviaremos un NIP de 4 dígitos al{" "}
                            <strong>{phone}</strong>, introdúcelo en la
                            siguiente pantalla para continuar.
                        </BodyText>
                    </div>
                </Center>
                <HSpacing times={5} />
                <Center>
                    <Button
                        style={{ fontWeight: 600 }}
                        type="primary"
                        width={432}
                        label="Continuar"
                        onClick={handlerGoAhead}
                        disabled={false}
                    />
                </Center>
            </Panel>
            <Footer/>
        </div>
    );
}

export default function Page({history}) {
    return (
        <>
            <View history={history}/>
        </>
    );
}
