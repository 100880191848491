import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import Button from '../../../shared/components/Button/Button';
import { service } from '../../service';
import { DEVICE_CONFIGURED } from './constants';
import Icon from '../../../shared/components/Icon/Icon';
import { copyToClipboard } from '../../../../helpers/helpers';
import { alert } from '../../../../helpers/alertsManager';

const TwoFactorAuthQR_CodePage = ({
  setIs2FA_Device_Configured,
  setIsInitialConfiguration
}) => {
  const [error, setError] = useState(null);
  const [imgSrc, setImgSrc] = useState('');
  const [secret, setSecret] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const fetchTwoFactorAuthURI = async () => {
    try {
      setError(null);

      const {
        isSuccess,
        error,
        data
      } = await service().requestTwoFactorAuthURI();

      if (isSuccess && data) {
        const uri = data[0].provisioning_uri;
        // Extracting the query string from the URI
        const queryString = uri.split('?')[1];

        // Parsing the query string
        const params = new URLSearchParams(queryString);

        // Extracting the value of the 'secret' parameter
        const secret = params.get('secret');

        setSecret(secret);
        await QRCode.toDataURL(
          uri,
          {
            errorCorrectionLevel: 'H',
            type: 'image/webp',
            quality: 1,
            margin: 4
          },
          (err, url) => {
            setImgSrc(url);
          }
        );
      } else {
        console.error(error);
        setError(error);
      }
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  useEffect(() => {
    fetchTwoFactorAuthURI();
  }, []);

  if (error) {
    return (
      <div className="two-factor-auth-QRcode-container">
        <Button
          className="primaryButton"
          size="lg"
          text="¿No te funciona el QR? Carga uno nuevo"
          onClick={() => {
            fetchTwoFactorAuthURI();
          }}
        />
      </div>
    );
  }

  return (
    <div className="two-factor-auth-QRcode-container">
      {imgSrc && (
        <img
          src={imgSrc}
          alt="Example frame"
          width={'80%'}
          height="inherit"
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageLoaded(false)}
          style={{ display: imageLoaded ? 'block' : 'none' }}
        />
      )}
      <Button
        variant="overlaySecondary"
        className="secondaryButton"
        size="lg"
        text="¿No te funciona el QR? Copia el siguiente código a tu aplicación de autenticación:"
        onClick={() => {
          fetchTwoFactorAuthURI();
        }}
      />
      {secret && (
        <div className="two-factor-secret-container">
          <p className="two-factor-secret">{secret}</p>
          <div
            className="copy-secret-icon-container"
            onClick={() => {
              copyToClipboard(secret);
              alert('success', 'Copiados', 1500);
            }}
          >
            <Icon icon="copyIcon" />
          </div>
        </div>
      )}
      <Button
        className="primaryButton"
        size="lg"
        text="Continuar"
        onClick={() => {
          setIs2FA_Device_Configured(DEVICE_CONFIGURED);
          setIsInitialConfiguration(true);
        }}
        type="submit"
      />
    </div>
  );
};

export default TwoFactorAuthQR_CodePage;
