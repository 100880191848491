import React, { useEffect, useState } from 'react';

// Auxiliary Libraries
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Button from '../../../micro-frontends/shared/components/Button/Button';
import TextField from '../../../micro-frontends/shared/components/TextField/TextField';
import TextFieldAmount from '../../../micro-frontends/shared/components/TextFieldAmount/TextFieldAmount';
import TagList from '../../../micro-frontends/tags/TagList/TagList';
import RemoveTransactionTagModal from '../../../micro-frontends/activity/components/RemoveTransactionTagModal';
import { Alert } from '@material-ui/lab';

// Styles
import {
  Container,
  SubContainer,
  TransferLimit
} from './TransactionForm.styles';

// Redux
import { tagsSelector } from '../../../micro-frontends/tags/redux/tag-selectors';
import {
  removeTagAction,
  setTagsSpeiAction
} from '../../../micro-frontends/tags/redux/tag-action';

// Services
import { getSpendingLimitHandler } from './eventHandlers/get-spending-limit-handler';

// Helpers
import { transferValidations } from './validation';
import { parseAmount } from '../../../helpers/helpers';

const TransactionForm = ({ submit, transaction }) => {
  const [initialState, setInitialState] = useState(true);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [remainingSpendLimit, setRemainingSpendLimit] = useState();
  const [tagRemove, setTagRemove] = useState(null);

  const tagsAdded = useSelector(tagsSelector);
  const dispatch = useDispatch();

  const balance = useSelector(
    state => state.companyReducer.companySummary.available_credit
  );

  const creditLine = useSelector(
    state => state.companyReducer.companySummary.credit_line
  );

  const userRoleId = useSelector(
    state => state.userInfoReducer.user.user_role_id
  );

  const userId = useSelector(state => state.userInfoReducer.user.user_id);

  const [formData, setFormData] = useState({
    concept: transaction.concept ? transaction.concept : '',
    amount: transaction.amount ? transaction.amount : '',
    balance: balance,
    creditLine: creditLine,
    userRoleId: userRoleId,
    reference_number: transaction.reference_number
      ? transaction.reference_number
      : ''
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: transferValidations
  });

  const getSpendingLimit = async () => {
    const { data, error } = await getSpendingLimitHandler();

    if (!error) {
      const { totalAvailable } = data;
      formik.setFieldValue('spendingLimit', totalAvailable);
      setRemainingSpendLimit(getTransferLimit(totalAvailable));
    }
  };

  const getTransferLimit = value => {
    let re = /([$]|[,])/g;
    const avaliableUserSpendLimit = Number(value.replace(re, ''));
    const avaliableAccountBalance = Number(balance.replace(re, ''));

    if (avaliableUserSpendLimit === avaliableAccountBalance) {
      return balance;
    }

    if (avaliableUserSpendLimit > avaliableAccountBalance) {
      return balance;
    }

    if (avaliableUserSpendLimit < avaliableAccountBalance) {
      return parseAmount(avaliableUserSpendLimit);
    }
  };

  const handleSaveTag = async tag => {
    dispatch(setTagsSpeiAction({ tags: [...tagsAdded, tag] }));
  };

  const handleOpenRemoveModal = tag => {
    setTagRemove(tag);
    setOpenRemoveModal(true);
  };

  const closeRemoveTag = () => {
    setOpenRemoveModal(false);
  };

  const deleteTag = tag => {
    dispatch(removeTagAction({ tag }));
  };

  useEffect(() => {
    getSpendingLimit();
    // eslint-disable-next-line
  }, [userId]);

  const handleChange = e => {
    if (
      checkRefMaxLength(e) ||
      checkEmptySpaces(e) ||
      checkIfHasCurrencySign(e.target)
    )
      return;
    formik.handleChange(e);
    setInitialState(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const checkEmptySpaces = e => e.target.value.startsWith(' ');

  const checkRefMaxLength = e => {
    return e.target.name === 'reference_number' && e.target.value.length > 7;
  };

  const checkIfHasCurrencySign = ({ name, value } = { name: '', value: '' }) =>
    name === 'amount' && value.includes('$');

  return (
    <Container data-test="transactionForm" data-testid="transactionForm">
      {openRemoveModal && tagRemove && (
        <RemoveTransactionTagModal
          onClose={closeRemoveTag}
          tag={tagRemove}
          customDelete={deleteTag}
        />
      )}

      <TextField
        name="concept"
        label="Concepto"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formData.concept}
        error={formik.errors.concept && formik.touched.concept}
        helperText={formik.errors.concept}
      />
      <SubContainer className="transferForm">
        <TextFieldAmount
          name="amount"
          label="Monto"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formData.amount}
          error={formik.errors.amount && formik.touched.amount}
        />

        <TextField
          name="reference_number"
          label="Referencia"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formData.reference_number}
          error={
            formik.errors.reference_number && formik.touched.reference_number
          }
          helperText={formik.errors.reference_number}
        />
      </SubContainer>
      {remainingSpendLimit && (
        <>
          <TransferLimit>
            Puedes transferir:{' '}
            <span className="transferLimit">{`${remainingSpendLimit}`}</span>
          </TransferLimit>
        </>
      )}
      {formik?.touched?.amount && formik?.errors?.amount && (
        <div style={{ marginBottom: '20px' }}>
          <Alert severity="error">{formik.errors.amount}</Alert>
        </div>
      )}
      <TagList
        tags={tagsAdded}
        lengthValidation={7}
        hasPermissions
        handleSaveTag={handleSaveTag}
        handleDeleteTag={handleOpenRemoveModal}
      />
      <Button
        text="Continuar"
        disabled={!formik.isValid || !formik.dirty}
        onClick={e => submit(formData)}
        className="button"
      />
    </Container>
  );
};

export default TransactionForm;
