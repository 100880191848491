
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */import React from 'react';
import PropTypes from 'prop-types';

import { PageItemContainer } from './Paginator.styles';

const PageItem = ({ className, isActive, label, onClick }) => {
  return (
    <>
      <PageItemContainer
        isActive={isActive}
        onClick={onClick}
        className={className}
        data-test="PageItem"
      >
        {label}
      </PageItemContainer>
    </>
  );
};

PageItem.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  label: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired
};

export default PageItem;
