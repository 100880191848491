import log from 'loglevel';
import { formatAmount } from '../../../../helpers';

// preapring value representations
const getUnformattedValueWithoutDecimals = value =>
  value.split('.').shift().split(',').join('');

// adding characters path
const isCharacterChange = e => !!e.nativeEvent.data;
const isNumericChange = e =>
  isCharacterChange(e) &&
  (!Number.isNaN(e.nativeEvent.data) || e.nativeEvent.data === '.');
const hasNumberOfCommasIncreased = e => {
  const unformattedValueWithoutDecimals = getUnformattedValueWithoutDecimals(
    e.target.value
  );

  return (
    isNumericChange(e) &&
    unformattedValueWithoutDecimals.length > 1 &&
    unformattedValueWithoutDecimals.length % 3 === 1
  );
};

// removing characters path
const isBackspaceRemoval = e =>
  e.nativeEvent.inputType === 'deleteContentBackward';
const isPaste = e => e.nativeEvent.inputType === 'insertFromPaste';
const isForwardRemoval = e =>
  e.nativeEvent.inputType === 'deleteContentForward';
const isCommaRemovedByUserEvent = e =>
  e.target.value
    .split('.')
    .shift()
    .split(',')
    .some(substring => substring.length > 3);
const hasNumberOfCommasDecreased = e => {
  const unformattedValueWithoutDecimals = getUnformattedValueWithoutDecimals(
    e.target.value
  );
  return isCommaRemovedByUserEvent(e)
    ? unformattedValueWithoutDecimals.length % 3 === 1
    : unformattedValueWithoutDecimals.length % 3 === 0;
};
const removeNumberOnTheLeftOfComma = e => {
  const { value, selectionEnd } = e.target;
  const formattedAmount = formatAmount(value) || '';

  return (
    formattedAmount.slice(0, selectionEnd - 1) +
    formattedAmount.slice(selectionEnd)
  );
};
const removeNumberOnTheRightOfComma = e => {
  const { value, selectionEnd } = e.target;
  const formattedAmount = formatAmount(value) || '';

  return (
    formattedAmount.slice(0, selectionEnd + 1) +
    formattedAmount.slice(selectionEnd + 2)
  );
};

export const getCursorPositionForAmountField = e => {
  const { selectionEnd } = e.target;

  const newPosition = (() => {
    if (isCharacterChange(e)) {
      if (!isNumericChange(e)) {
        return selectionEnd - 1; //if not valid input than don't move the cursor
      }

      return hasNumberOfCommasIncreased(e) ? selectionEnd + 1 : selectionEnd; // if extra comma than move cursor by its place
    } 
      // it is other type of input than character

    if (isBackspaceRemoval(e)) {
      if (selectionEnd === 0) {
        return 0;
      }

      if (isCommaRemovedByUserEvent(e)) {
        e.target.value = removeNumberOnTheLeftOfComma(e);

        return hasNumberOfCommasDecreased(e)
          ? selectionEnd - 2 // move left through comma and by removed comma sign
          : selectionEnd - 1; // move left through comma
      }

      return hasNumberOfCommasDecreased(e) ? selectionEnd - 1 : selectionEnd;
    }

    if (isForwardRemoval(e)) {
      if (isCommaRemovedByUserEvent(e)) {
        e.target.value = removeNumberOnTheRightOfComma(e);

        return hasNumberOfCommasDecreased(e)
          ? selectionEnd
          : selectionEnd + 1;
      }
      return hasNumberOfCommasDecreased(e) ? selectionEnd - 1 : selectionEnd;
    }
    if (isPaste(e)) {
      return -1; // it is copy paste
    }
    return selectionEnd;
  })();

  log.info(
    'text-field-amount calculated position',
    newPosition,
    ' based on event ',
    e
  );

  return { modifiedEvent: e, newPosition };
};
