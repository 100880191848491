import { UserCreator } from '../domain/userData';
import { saveUserData } from '../../../actionCreators/authAction';
import { parseToken } from '../../../Services/authService';

const twoFactorAuthLoginHandler = ({ twoFactorAuthLoginService }) => async ({
  email,
  password,
  code
}) => {
  const { error, data: userData } = await twoFactorAuthLoginService({
    email,
    password,
    code
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  const { accessToken, refreshToken } = UserCreator({ data: userData[0] });
  let user = parseToken(accessToken);

  return { isSuccess: true, user, accessToken, refreshToken };
};

export { twoFactorAuthLoginHandler };
