import React, { useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import * as typeformEmbed from '@typeform/embed';

import '@typeform/embed/build/css/widget.css';
import { FormContainer } from './styles';

import { userEmailSelector } from '../redux/prequalification-selectos';

const Typeform = () => {
  const email = useSelector(userEmailSelector);
  const ref = useRef(null);

  useEffect(() => {
    typeformEmbed.createWidget(process.env.REACT_APP_TYPEFORM_ID, {
      container: ref.current,
      medium: 'embed-sdk',
      hidden: {
        useremail: email
      }
    });
    // eslint-disable-next-line
  }, []);

  return <FormContainer ref={ref} id="target-dom-node" />;
};

export default Typeform;
