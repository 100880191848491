/* eslint-disable guard-for-in */
import errorMessages from './errorMessages.json';

export const getErrors = err => {
  /*
   *The errors should be returned in an array
   */

  if (err.response) {
    return validateHttpErrorMessage(err.response);
  }

  return validateErrorType(err);
};

export const validateHttpErrorMessage = res => {
  let message;
  let internalErrors = [];

  if (res.status && res.data.message) {
    const responseMessage = parseErrorMessage(res.data.message);

    const { errorCodeMessage } = validateErrorCode(res);
    internalErrors = getInternalErrors(res);

    message = errorCodeMessage || errorMessages[res.status][responseMessage];
  }

  return message
    ? [...internalErrors, message]
    : [...internalErrors, errorMessages.default];
};

const validateErrorType = err => {
  let message = errorMessages.default;
  const error = err.name;
  const errMessage = err.message ? err.message : null;

  if (errMessage && errMessage.includes('timeout'))
    message = errorMessages.timeout;

  if (errMessage && errMessage.includes('Network Error'))
    message = errorMessages.networkError;

  if (errMessage && errMessage.includes('Permissions error'))
    message = errorMessages.permissionsError;

  if (error === 'TypeError') message = null;

  return [message];
};

const parseErrorMessage = message => {
  for (const errMessage in errorMessages.messagesToParse) {
    return message.includes(errMessage) ? errMessage : message;
  }
};

const sessionValidation = (validation, errorCode, status) => {
  if (status !== 401 || !errorMessages[status][errorCode]) return validation;

  validation.errorCodeMessage = errorMessages[status][errorCode];
  validation.key = 'invalid_sesion';
  return validation;
};

export const validateErrorCode = res => {
  const validation = { errorCodeMessage: null, key: null };

  const status = res?.status;
  const errorCode = res?.data?.body[0]?.internal_error_code;

  if (status && errorMessages[status][errorCode])
  {validation.errorCodeMessage = errorMessages[status][errorCode];}

  return !status && !errorCode
    ? validation
    : sessionValidation(validation, errorCode, status);
};

const getInternalErrors = res => {
  const errorsHandled = [];
  const errors = res?.data?.body[0]?.errors;

  if (errors && errors.length > 0) {
    errors.forEach(el => {
      if (errorMessages.internalErrors[el])
        {errorsHandled.push(errorMessages.internalErrors[el]);}
    });
  }

  return errorsHandled;
};
