import { log } from 'loglevel';
import { PURGE } from '../../../reducers/types';
import { cleanLocalStorage } from '../../../Services/authService';
import { alert } from '../../../helpers/alertsManager';

const disable2FA_Handler = ({ dispatch, disable2FA_service }) => async () => {
  const { error } = await disable2FA_service();
  if (error) {
    log.error(error);
    console.log('Error occurred');
    return;
  }

  alert(
    'success',
    'La autenticación de dos factores se ha desactivado correctamente'
  );
  cleanLocalStorage();
  dispatch({ type: PURGE });
  return;
};

export { disable2FA_Handler };
