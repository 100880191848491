import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { tagOptionsPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const createTransactionTagService = async ({ tagLabel }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      tagOptionsPath,
      {
        tag_label: tagLabel
      }
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'tag-options-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('tag-options-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
