import * as XLSX from 'xlsx';

import log from 'loglevel';


const utf8 = require('utf8')

export const convertToJSON = csv => {

  const lines = csv.split('\n');
  const rows = [];

  lines.map(line => {
    if (line !== ',,,' && line !== '') {
      rows.push(line.split(','));
    }
  });

  const cols = lines[0].split(',');

  return { rows, cols };

};

export const processCSVFile = evt => {      

  /* Parse file */
  const file = evt.target.result;
  let workbook

  try {
    workbook = XLSX.read(utf8.decode(file), { type: 'string' });

  } catch (error) {
    log.info('result of parsing spreadsheet', error);

    workbook = XLSX.read(file, { type: 'binary' });
  }

  /* Get first worksheet */
  const workSheetName = workbook.SheetNames[0];
  const workSheet = workbook.Sheets[workSheetName];

  /* Convert to CSV file */
  const data = XLSX.utils.sheet_to_csv(workSheet, { header: 1 });
  log.info('result of parsing spreadsheet', data);

  return data

}
