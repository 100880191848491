import React from 'react';

import CodeInput from '../CodeInput';

import {
  CustomTitle,
  ContentWrapper,
  Text,
  ContentButtons,
  CustomButton
} from './CardActivation.styles';

const Step2 = ({ setStep, setForm }) => {
  const updateStep = value => {
    setForm(value);
    setStep(3);
  };

  return (
    <ContentWrapper>
      <CustomTitle text="Activar tarjeta" />
      <Text
        marginBottom={'2rem'}
        text="Ingresa el código de activación de 7 caracteres que acaba de ser enviado a tu correo."
      />
      <Text
        marginBottom={'2rem'}
        bold
        text="Recomendamos copiar y pegarlo desde el correo para evitar errores"
      />
      <CodeInput digits={7} onValid={e => updateStep(e)} />
      <ContentButtons>
        <CustomButton
          isGrey
          text="Regresar"
          size="lg"
          onClick={() => setStep(1)}
        />
      </ContentButtons>
    </ContentWrapper>
  );
};

export default Step2;
