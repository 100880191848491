import React from 'react';

export const getTitleOfErrorMessage = error => {
  const errorCode = error?.internal_error_code;

  switch (errorCode) {
    case '409-6':
      return 'Límite de usuario superado';
    case '409-8':
    case '409-9':
    case '409-10':
    case '409-11':
      return 'Llegaste a tu límite de tarjetas';
    default:
      return 'Algo no funcionó ...';
  }
};

export const openIntercom = (cardType = '') => {
  const message = cardType
    ? `Me gustaría solicitar más tarjetas ${cardType}`
    : 'Hola, tuve un problema al crear mi tarjeta ¿me pueden ayudar, por favor?';
  window.Intercom('showNewMessage', message);
};

export const getBodyOfErrorMessage = error => {
  const VIRTUAL_LABEL = 'virtuales';
  const PHYSICAL_LABEL = 'físicas';
  const errorCode = error?.internal_error_code;

  switch (errorCode) {
    case '409-6':
      return 'El límite de gasto de la tarjeta supera el límite de gasto del usuario. Incrementa el límite del usuario primero.';
    case '409-8':
      return (
        <>
          Llegaste al límite de tarjetas virtuales para una cuenta Empresarial.
          ¿Necesitas más?{' '}
          <u onClick={() => openIntercom(VIRTUAL_LABEL)}>Búscanos aquí</u>.
        </>
      );
    case '409-9':
      return (
        <>
          Llegaste al límite de tarjetas virtuales para una cuenta Freelancer.
          ¿Necesitas ayuda?{' '}
          <u onClick={() => openIntercom(VIRTUAL_LABEL)}>Búscanos aquí</u>.
        </>
      );
    case '409-10':
      return (
        <>
          Llegaste a tu límite de tarjetas físicas para una cuenta Empresarial.
          ¿Necesitas más?{' '}
          <u onClick={() => openIntercom(PHYSICAL_LABEL)}>Búscanos aquí</u>.
        </>
      );
    case '409-11':
      return (
        <>
          Llegaste a tu límite de tarjetas físicas para una cuenta Freelancer.
          ¿Necesitas ayuda?{' '}
          <u onClick={() => openIntercom(PHYSICAL_LABEL)}>Búscanos aquí</u>.
        </>
      );
    case 'AVAILABLE_CARD_LIMIT_REACHED':
      return (
        <>
          Llegaste al límite de tarjetas virtuales y físicas para una cuenta
          Empresarial. ¿Necesitas ayuda?{' '}
          <u onClick={() => openIntercom()}>Búscanos aquí</u>.
        </>
      );
    default:
      return (
        <>
          Algo pasó de nuestro lado y no pudimos crear tu nueva tarjeta. Por
          favor reinténtalo o{' '}
          <u onClick={() => openIntercom()}>
            contáctanos y con gusto te ayudaremos
          </u>
          .
        </>
      );
  }
};

export const getTitleOfSuccessMessage = ({ message }) => {
  if (message === 'Virtual card request created')
    return 'Tu solicitud fue exitosa';
  return '¡Ya puedes hacer tu primera compra! 🎉';
};

export const getBodyOfSuccessMessage = ({ message }) => {
  if (message === 'Virtual card request created')
    return 'Tu tarjeta nueva se reflejará en tu sección de Tarjetas una vez que el Super Admin de la cuenta la apruebe.';
  return '¡Excelente! Ya puedes visualizar tu nueva tarjeta virtual en tu sección de Tarjetas.';
};

export const VirtualCardSuccessMessage =
  '¡Ya puedes hacer tu primera compra! 🎉';
export const PhysicalCardSuccessMessage = 'Tu tarjeta está en camino 📨';

export const getTitleOfInformationMessage = isSuperAdmin => {
  return isSuperAdmin
    ? 'El usuario necesita configurar su NIP'
    : 'El usuario necesita configurar su NIP y la tarjeta debe ser autorizada';
};

export const getBodyOfInformationMessage = isSuperAdmin => {
  return isSuperAdmin
    ? 'Se le ha enviado un correo con una liga para terminar de configurar su NIP. Una vez completado este paso, la tarjeta será creada exitosamente.'
    : 'Se le ha enviado un correo con una liga para terminar de configurar su NIP. Una vez completado este paso, la tarjeta será creada exitosamente.';
};
