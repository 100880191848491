/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// This module components
import Toggle from '../toggle';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';

// Styled
import { Row, ApprovalLink } from './styles';

// Helpers
import {
  ACTIVE,
  ADMIN_CREATE_REQUEST,
  ADMIN_UPDATE_REQUEST
} from '../../constants';
import {
  isSuperAdminSelector,
  isAdminSelector,
  isBasicUserSelector
} from '../../redux/cards-selectors';
import { formatAmount } from '../../../../helpers/helpers';
import useViewportWidth from '../../../../hooks/useViewportWidth';
import { userIdSelector } from '../../../users-crud/redux/crud-selectors';

const TableRowCard = ({
  card,
  columns,
  toggleCardDetailsModal,
  toggleCreateCardPinModal,
  toggleApprovalModal,
  toggleBlockCardModal,
  toggleUnblockCardModal,
  index
}) => {
  const {
    requestStatusDescription,
    userId: cardUserId,
    remainingSpendLimit,
    creditCardName,
    lastFourDigits,
    isCardPinSet,
    spendLimit,
    isInactive,
    ownerName,
    isBlocked,
    isVirtual,
    isRetired,
    isActive
  } = card;
  const cardSpendLimit = useMemo(() => formatAmount(spendLimit), [spendLimit]);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isBasicUser = useSelector(isBasicUserSelector);
  const userId = useSelector(userIdSelector);
  const viewportWidth = useViewportWidth();
  const isMobile = viewportWidth < 769;

  const showLastFourDigits = () => {
    if (!lastFourDigits) {
      return 'Por generar';
    }
    return lastFourDigits;
  };

  const isGreaterThan25Percent = () => {
    if (!remainingSpendLimit) return true;

    const twentyFivePercent =
      spendLimit.split(',').join('').split('.')[0] * 0.25;

    if (getNumberWOCommas(remainingSpendLimit) > twentyFivePercent) return true;
    return false;
  };

  const getNumberWOCommas = string => Number(string.replace(/,/g, ''));

  const convertToDecimal = number => (number / 1).toFixed(2);

  const formatNumber = num =>
    convertToDecimal(typeof num === 'string' ? getNumberWOCommas(num) : num);

  const getRemainingSpendLimit = num => {
    if (!num) return;
    const number = formatNumber(num);
    if (number > 0) return num;
    return number;
  };

  const remainingCardLimit = getRemainingSpendLimit(remainingSpendLimit);

  return (
    <>
      <Row data-test="card-row" columns={columns} index={index}>
        <div
          onClick={() => {
            toggleCardDetailsModal(card);
          }}
        >
          <span>
            {creditCardName || <ApprovalLink>¡Ponle nombre!</ApprovalLink>}
          </span>
        </div>

        <div
          onClick={() => {
            toggleCardDetailsModal(card);
          }}
        >
          <span className={!isGreaterThan25Percent() ? 'redColor' : ''}>
            {remainingCardLimit ? `$${remainingCardLimit}` : 'No disponible'}
          </span>
        </div>

        <div
          onClick={() => {
            toggleCardDetailsModal(card);
          }}
        >
          <span>${cardSpendLimit}</span>
        </div>

        <div
          className="ownerName"
          onClick={() => {
            toggleCardDetailsModal(card);
          }}
        >
          <span className="ownerNameLabel">{ownerName}</span>
        </div>

        <div
          onClick={() => {
            toggleCardDetailsModal(card);
          }}
        >
          <span>{showLastFourDigits()}</span>
        </div>

        {!isMobile ? (
          <div
            onClick={() => {
              toggleCardDetailsModal(card);
            }}
          >
            <span>{isVirtual ? 'Virtual' : 'Física'}</span>
          </div>
        ) : null}

        {(() => {
          if (requestStatusDescription === ACTIVE) {
            if (isInactive) {
              /*
              1.) If you are a SUPER ADMIN, you do not need to access the CREATE PIN MODAL, SUPER ADMINS set their own pin when creating a card
              2.) You can only access the CREATE PIN MODAL if you are an Admin or Basic User and the card belongs to you i.e userId === card.userId
              */
              return !isSuperAdmin && !isCardPinSet && userId === cardUserId ? (
                <div
                  onClick={() => {
                    toggleCreateCardPinModal(card);
                  }}
                >
                  <ApprovalLink>Crear NIP</ApprovalLink>
                </div>
              ) : (
                <div
                  onClick={() => {
                    toggleCardDetailsModal(card);
                  }}
                >
                  <span>
                    {'Por generar'}
                    <span className="tooltipContainer">
                      <Tooltip
                        className="statusTooltip"
                        text={'La tarjeta se está generando.'}
                      />
                    </span>
                  </span>
                </div>
              );
            }
            if (isActive || isBlocked) {
              return (
                <span
                  onClick={() => {
                    if (card.isActive) {
                      toggleBlockCardModal(card);
                    } else {
                      toggleUnblockCardModal(card);
                    }
                  }}
                >
                  <Toggle checked={card.isActive} />
                </span>
              );
            }
            if (isRetired) {
              return (
                <div
                  onClick={() => {
                    toggleCardDetailsModal(card);
                  }}
                >
                  <span>{'Desactivada'}</span>
                </div>
              );
            }
          }
          if (isAdmin || isBasicUser) {
            return (
              <div
                onClick={() => {
                  toggleCardDetailsModal(card);
                }}
              >
                Enviado
                <span className="tooltipContainer">
                  <Tooltip
                    className="statusTooltip"
                    text={
                      isAdmin
                        ? 'Hemos enviado esta tarjeta a tu Super Admin para aprobar'
                        : 'Se ha enviado un correo al Super Admin para que autorice esta tarjeta. Una vez que la autorice, podrá ser utilizada por el usuario.'
                    }
                  />
                </span>
              </div>
            );
          }
          if (
            isSuperAdmin &&
            (ADMIN_UPDATE_REQUEST === requestStatusDescription ||
              ADMIN_CREATE_REQUEST === requestStatusDescription)
          ) {
            return (
              <div
                onClick={() => {
                  toggleApprovalModal(card);
                }}
              >
                <ApprovalLink>Revisar</ApprovalLink>
              </div>
            );
          }
        })()}
      </Row>
    </>
  );
};

TableRowCard.propTypes = {
  columns: PropTypes.string,
  card: PropTypes.object.isRequired
};

export default TableRowCard;
