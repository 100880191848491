import styled from 'styled-components';

import Modal from '../../../shared/components/Modal';
import Title from '../../../shared/components/Title';

import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const CustomTitle = styled(Title)`
  color: ${colors.carbon};
`;

export const FormContainer = styled.div`
  display: grid;
  width: 54rem;
  max-width: 54rem;
  padding: 3.2rem 4.8rem 2.6rem;
`;

export const Disclaimer = styled.div`
  ${fonts.quickSandMedium};
  font-size: 1.6rem;
  color: ${colors.carbon};
  margin: 1.6rem 1.6rem 3.2rem;
  text-align: center;
`;

export const CustomModal = styled(Modal)`
  max-width: 54rem;
`;

export const Subtitle = styled.div`
  ${fonts.barlowRegular}
  font-size: 1.55rem;
  color: ${colors.body};
  margin: 4rem 7.2rem 2.4rem;
  text-align: center;
`;
