import log from 'loglevel';

import { customAxiosInstance } from '../../../../Services/axiosConfig';
import { afbCustomerDataPath } from './apiPaths';
import { extractContentFromResponse } from '../../../../helpers/extractContentFromResponse';

export const getCustomerData = async ( customerUuid ) => {
  try {
    const { data, error, status } = await customAxiosInstance().post(
        afbCustomerDataPath,
        { customer_uuid: customerUuid}
    )

    return extractContentFromResponse({
      data,
      error,
      status,
      moduleName: 'get-customer-data-service'
    });
  } catch (error) {
    log.debug('get-customer-data-service', error);
    return { error: { message: 'Unable to send request' } };
  }
};
