import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core/';
import LogoImage from '../../../assets/DLT-horizontal-red.jpg';
import RedBorderButton from '../RedBorderButton/RedBorderButton';
import { closeSession } from '../../../Services/authService';
import { useHistory } from 'react-router-dom';
import { Context as userInfoContext } from '../../../context/userInfoContext';
import { useDispatch } from 'react-redux';
import { PURGE } from '../../../reducers/types';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: ' 1em 7em',
    [theme.breakpoints.only('sm')]: {
      padding: '1rem 2.5rem'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 1.2rem'
    },
    fontFamily: 'Barlow',
    backgroundColor: 'white'
  },
  sizes: {
    width: 'auto',
    height: '4rem'
  },
  logout: {
    fontSize: '1.6rem',
    color: '#323132'
  }
}));

export default function CenteredGrid() {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { saveUserToken } = useContext(userInfoContext);
  const userInfoState = useSelector(state => state.userInfoReducer.token);

  const logout = () => {
    try {
      closeSession();
    } finally {
      dispatch({ type: PURGE });
      history.push('/');
      saveUserToken({});
    }
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Grid container item xs alignItems="center">
          <Hidden smDown>
            <img src={LogoImage} alt="Logo Delt.ai" className={classes.sizes} />
          </Hidden>
        </Grid>
        {userInfoState ? (
          <React.Fragment>
            <Hidden smDown>
              <Grid>
                <RedBorderButton copy="Salir" onClick={logout} />
              </Grid>
            </Hidden>

            <Hidden mdUp>
              <span className={classes.logout} onClick={logout}>
                Guardar y salir
              </span>
            </Hidden>
          </React.Fragment>
        ) : null}
      </Grid>
    </div>
  );
}
