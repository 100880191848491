import * as yup from 'yup';
import { patterns_metadata as patterns } from '../../../../catalogs/patterns';

const requiredFieldMessage = 'Campo requerido';

export const validationSchema = yup.object({
  street: yup.string().required(requiredFieldMessage),
  externalNum: yup.string().required(requiredFieldMessage),
  settlement: yup.string().required(requiredFieldMessage),
  zipCode: yup
    .string()
    .matches(patterns.patternPostalCode, 'Ingresa un código postal válido')
    .required(requiredFieldMessage),
  city: yup.string().required(requiredFieldMessage),
  state: yup.string().required(requiredFieldMessage)
});
