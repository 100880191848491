import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const labelFocused = css`
  font-size: 1.2rem;
  top: 0.8rem;
  color: ${colors.primary};
`;

const inputFocused = css`
  margin-bottom: 0.2rem;
`;

const startAdornmentFocused = css`
  display: block;
`;

const hasValueStyles = css`
  font-size: 1.2rem;
  top: 0.8rem;
`;

const getFocusedStyles = ({ focused }) => {
  return focused ? labelFocused : '';
};

const getHasValueStyles = ({ hasValue }) => {
  return hasValue ? hasValueStyles : '';
};

const getFocusedInputStyles = ({ focused, hasValue }) => {
  return focused || hasValue ? inputFocused : '';
};

const getFocusedStartAdornment = ({ focused, hasValue }) => {
  return focused || hasValue ? startAdornmentFocused : '';
};

const getStartAdornmentStyles = ({ startAdornment }) => {
  if (startAdornment) return `text-align: right;`;
};

const getErrorStyles = ({ error }) => {
  return error
    ? `
    border: solid 1px ${colors.deltai};
    label, span {
      color: ${colors.deltai};  
    };
    `
    : '';
};

const validateLengthSize = ({ length }) => {
  if (length < 36) return 'font-size: 1.7rem';

  return `
    font-size: 1.4rem;
    top:2.5rem;
  `;
};

export const TextFieldContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 7.1rem;
  border-radius: 0.8rem;
  border: solid 1px
    ${({ focused }) => (!focused ? colors.carbon3 : colors.primary)};
  background-color: white;
  margin: 1.6rem 0;
  position: relative;
  padding: 0 2.4rem;
  ${getErrorStyles}

  .endAdornment {
    padding-left: 1.2rem;
  }

  .endAdornmentTooltip {
    left: -13rem;
  }

  &:nth-child(8),
  &:nth-child(4) {
    @media screen and (max-width: 1024px) {
      label {
        width: 80%;
        margin-top: -10px;
      }
    }
  }
`;

export const Label = styled.label`
  ${fonts.quickSandSemiBold};
  position: absolute;
  top: 2rem;
  color: ${colors.carbon};
  transition: 300ms ease all;
  ${validateLengthSize}
  ${getFocusedStyles}
  ${getHasValueStyles}
`;

export const Input = styled.input`
  background: none;
  background-color: white;
  color: ${({ disabled }) => (disabled ? '#3a3a3a' : 'black')};
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  font-size: 1.7rem;
  padding: 0;
  margin-bottom: 0.6rem;
  transition: 300ms ease all;
  ${getStartAdornmentStyles}
  ${getFocusedInputStyles}

  &:focus {
    outline: none;
  }
`;

export const StartAdornment = styled.label`
  font-size: 1.7rem;
  padding: 0;
  transition: 300ms ease all;
  display: none;
  ${getFocusedStartAdornment}
`;

export const Helper = styled.span`
  font-family: Quicksand;
  font-size: 1.2rem;
  position: absolute;
`;

export const InputContainer = styled.div`
  width: 100%;
`;
