import {
  GET_DOCUMENTS,
  GET_REJECTED_DOCS,
  DOCUMENTS_UPLOADED,
  DOCUMENTS_TO_UPLOAD,
  SET_DOCS_AWAITING_REPLENISHMENT
} from './types';

export default (state, action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        missingDocuments: action.payload
      };
    case GET_REJECTED_DOCS:
      return {
        ...state,
        rejectedDocs: action.payload
      };
    case DOCUMENTS_UPLOADED:
      return {
        ...state,
        documentsUploaded: action.payload
      };
    case DOCUMENTS_TO_UPLOAD:
      return {
        ...state,
        documentsToUpload: action.payload
      };
    case SET_DOCS_AWAITING_REPLENISHMENT:
      return {
        ...state,
        awaitingReplenishmentDocs: action.payload
      };
    default:
      return state;
  }
};
