import styled from 'styled-components';
import { Description } from '../../../shared/components/ModalInformation/ModalInformation.styles';

export const Wrapper = styled.div`
  .modalContainer {
    width: fit-content;
    height: fit-content;
    padding: 0 1rem;
  }

  .calendly {
    max-width: 100vw;
    width: 100rem;
    height: 75rem;
  }

  .hubSpotScheduler {
    max-width: 100vw;
    width: 100rem;
    height: 75rem;
  }
`;

export const ModalDescription = styled(Description)`
  font-size: 1.7rem;
`;
