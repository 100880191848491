// eslint-disable-next-line no-irregular-whitespace
// The weighting values ​​come from the SPEI documentation to validate the CLABE code. Consult the documentation for more details
const weightingArr = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];

const convertToArr = clabe => {
  return clabe.split('');
};

const validateClabeLength = clabeArr => {
  if (clabeArr.length !== 18) {
    return false;
  }

  return true;
};

const getWeightingModuleResult = clabeArr => {
  return clabeArr.map((el, index) => {
    return (weightingArr[index] * Number(el)) % 10;
  });
};

const getModuleSum = clabeAr => {
  return getWeightingModuleResult(clabeAr).reduce((x, y) => {
    return x + y;
  });
};

export const removeBlankSpaces = text => {
  return text.replace(/\s+/g, '');
};

const getVerifierDigit = clabeArr => {
  const value = getModuleSum(clabeArr) % 10;
  return (10 - value) % 10;
};

export const isClabeInvalid = clabe => {
  const clabeVaue = removeBlankSpaces(clabe);
  const clabeArr = convertToArr(clabeVaue);
  const newClabe = clabeVaue.slice(0, -1);
  const newClabeArr = convertToArr(newClabe);

  if (!validateClabeLength(clabeVaue)) {
    return 'La CLABE debe contener 18 dígitos';
  }

  if (isASPclabe(clabe) && process.env.REACT_APP_ENABLE_ASP_BANK !== 'true')
    return 'Por el momento no podemos mandar transferencias a este banco. Por favor ingresa otra opción.';

  const digitVerifier = getVerifierDigit(newClabeArr);
  const clabeDigitVerifier = Number(clabeArr.pop());

  if (digitVerifier === clabeDigitVerifier) {
    return false;
  }

  return 'La CLABE ingresada no es válida';
};

const isASPclabe = clabe => {
  const ASPcode = '659';
  const bankCode = clabe.slice(0, 3);
  if (ASPcode === bankCode) return true;

  return false;
};
