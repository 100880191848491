/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Auxiliar libraries
import { useFormik } from 'formik';

// Components
import TextField from '../../../shared/components/TextField/TextField';
import InformationModal from '../../../shared/components/ModalInformation';

// Others
import { validationSchema } from './validation-schema';

const ModalAuth = ({ handleSubmit, handleModalClose, authError }) => {
  const initialValues = { password: '' };
  const [form, setForm] = useState(initialValues);

  const formik = useFormik({
    initialValues,
    validationSchema
  });

  useEffect(() => {
    formik.setFieldTouched();
    // eslint-disable-next-line
  }, []);

  const handleChange = e => {
    formik.handleChange(e);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <InformationModal
      title="Contraseña"
      description="Introduce tu contraseña."
      primaryButtonText="Continuar"
      onPrimaryButtonClick={() => handleSubmit(form)}
      secondaryButtonText="Cancelar"
      onSecondaryButtonClick={handleModalClose}
    >
      <input
        name="block-autocomplete"
        style={{ opacity: 0, width: 1, height: 1, position: 'absolute' }}
        tabIndex="-1"
      />
      <TextField
        data-test="password"
        data-testid="AuthModalPasswordInput"
        className="formField"
        label="Contraseña"
        name="password"
        type="password"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        error={authError || (formik.errors.password && formik.touched.password)}
        helperText={formik.touched.password && formik.errors.password}
        value={form.password}
      />
    </InformationModal>
  );
};

ModalAuth.propTypes = {
  history: PropTypes.object
};

export default ModalAuth;
