export default {
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PHONE: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    TAXID: /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/,
    TAXID_PM:
        /^[A-Z&Ñ]{3}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/,
    POPULATIONID:
        /^[A-Z][AEIOUX][A-Z]{2}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[MH]([ABCMTZ]S|[BCJMOT]C|[CNPST]L|[GNQ]T|[GQS]R|C[MH]|[MY]N|[DH]G|NE|VZ|DF|SP)[BCDFGHJ-NP-TV-Z]{3}[0-9A-Z][0-9]$/,
    PASSWORD: {
        SPECIAL_CHARACTERS: /[^A-Za-z0-9\n\s]/,
        AT_LEAST_ONE_NUMBER: /[0-9]/,
        AT_LEAST_EIGHT_CHARACTERS: /.{8,}/,
    },
    FIEL: /^[0-9]+$/,
    NUMBERS:/^(?:\d+|)$/,
    INTNUMBER:/^[0-9A-z\s]+$/,
    ALPHANUMERIC:/[^a-zA-Z0-9]/g,
    NAME:/^[A-Za-záéíóúÁÉÍÓÚñÑ\s]+$/,
};
