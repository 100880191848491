/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { LabelContainer, Label } from './AccountInput.styles';

const AccountInputLabel = ({ type }) => {
  return (
    <LabelContainer>
      <Label selected={type === 'clabe_code'} data-test="clabeLabel">
        CLABE
      </Label>{' '}
      o{' '}
      <Label data-test="debitCardLabel" selected={type === 'debit_card_number'}>
        Número de tarjeta de débito
      </Label>
    </LabelContainer>
  );
};

AccountInputLabel.propTypes = {
  selectAccountType: PropTypes.func
};

export default AccountInputLabel;
