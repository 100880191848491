import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

import Avatar from '../Avatar/Avatar';
import Button from '../Button';
import Modal from '../Modal';
import Title from '../Title';

export const CustomButton = styled(Button)`
  width: 38.4rem;
`;

export const CustomAvatar = styled(Avatar)`
  color: #414a57;
  margin: 4rem auto;
`;

export const CustomTitle = styled(Title)`
  color: ${colors.carbon};
`;

export const CustomLabel = styled(Title)`
  color: ${colors.carbon1};
  cursor: pointer;
  margin-top: 3.2rem;
`;

export const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  padding: 4.6rem 4.8rem;
`;

export const Helper = styled.div`
  ${fonts.barlowRegular};
  font-size: 1.55rem;
  color: ${colors.body};
  margin-top: 1.6rem;
`;
