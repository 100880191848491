const FreelancerCreator = ({
  userId,
  birthDate,
  rfc,
  curp,
  gender,
  countryOfBirth,
  occupation,
  nationality,
  serialNumberFiel,
  phoneNumber
}) =>
  Object.freeze({
    user_id: userId,
    rfc: rfc,
    curp: curp,
    gender: gender,
    birth_date: birthDate,
    country_of_birth: countryOfBirth,
    nationality_id: nationality,
    serial_number_fiel: serialNumberFiel,
    occupation_job: occupation,
    phone_number: phoneNumber
  });

export default FreelancerCreator;
