import styled from 'styled-components';

import {
  colors,
  fonts,
  gradients
} from '../../../../Dashboard4.0/assets/styles/styles';

import Modal from '../../../shared/components/Modal/Modal';

export const StyledModal = styled(Modal)`
  max-width: 540px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: scroll;
  box-sizing: border-box;

  .Container {
    width: 100%;
    height: 100%;
    padding: 32px 24px 24px 24px;
  }

  .Title {
    font-size: 24px;
    color: #939ca9;
    text-align: center;
  }

  .detailsContainer {
    padding: 12px 30px;
    .detailsGroup {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 0 0 24px 0;
      justify-content: space-between;
      align-items: flex-start;

      .label {
        font-family: ${fonts.quickSandMedium};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #939ca9;
        width: 40%;
      }

      .info {
        font-family: ${fonts.barlowMedium};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #1c2633;
        width: 60%;
      }
    }
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }

  @media (max-width: 768px) {
    width: 100%;
    overflow: auto;

    form {
      width: 100%;
    }

    .formContainer {
      width: 100%;
    }
  }

  @media (max-width: 580px) {
    .optionsContainer {
      flex-direction: column;
      align-items: flex-start;

      label {
        margin-bottom: 1.8rem;
      }
    }
  }
`;

const getStatusColor = ({ status }) => {
  const colorStatus = {
    1: colors.success,
    2: colors.warning,
    default: '#b4bac3',
    rejected: '#ed2246'
  };

  let color = colorStatus[status] ? colorStatus[status] : colorStatus.default;

  return color;
};

export const Dot = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${getStatusColor};
  border-radius: 50%;
  display: inline-flex;
  align-self: flex-start;
  margin-right: 5px;
  margin-top: 3px;
`;

export const Label = styled.p`
  font-family: ${fonts.barlowMedium};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
`;

export const CardContainer = styled.div`
  width: 250px;
  height: 392px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 24px auto 24px auto;
  background: ${gradients.DeltaiCardBackgroundGradiant};
  box-shadow: 0px 0px 20px 10px rgba(28, 38, 51, 0.2);
  border-radius: 12px;
  box-sizing: border-box;

  .start {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 24px;

    .statusContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      box-sizing: content-box;
    }

    .activeLink {
      font-size: 12px;
      text-decoration-line: underline;
      color: #85d5ff;
      margin-top: 3px;
    }

    .activeText {
      font-size: 12px;
      font-weight: 400;
      color: ${colors.white};
      align-self: flex-start;
    }

    .chipContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 52px;
      background: linear-gradient(
        135.81deg,
        #b3aeb6 1.37%,
        #c2bfb5 20.06%,
        #d7d3c7 49.29%,
        #bbb7ab 77.56%,
        #d0d3c1 100%,
        #d8d8d8 100%
      );
      box-shadow: inset 0px 0.5px 0.1px rgba(0, 0, 0, 0.24);
      border-radius: 12px;
    }
  }

  .middle {
    font-family: ${fonts.barlowMedium};
    font-weight: 400;
    font-size: 16px;
    color: ${colors.white};
    padding: 0 24px;
    width: 100%;
    text-align: left;

    .blurDots {
      font-size: 8px;
    }

    .marginLeft {
      margin-left: 10px;
    }

    .ownersName {
      text-transform: uppercase;
    }
  }

  .end {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    img {
      width: 80px;
    }

    .logo {
      width: 110px;
    }
  }
`;
