const setInputProps = (formik, handleChange, label, name, type) => {
  return {
    label,
    name,
    id: name,
    onChange: handleChange,
    onBlur: formik.handleBlur,
    error: formik.errors[name] && formik.touched[name],
    helperText: formik.errors[name],
    type: type
  };
};

export default setInputProps;
