import React from 'react';
import PropTypes from 'prop-types';

import { EmptyState } from './styles';

const NoResultPlaceholder = ({ searchPhrase, text }) => {
  return (
    <EmptyState>
      {text} <span className="bold">{searchPhrase}</span>
    </EmptyState>
  );
};

NoResultPlaceholder.propTypes = {
  searchPhrase: PropTypes.string,
  text: PropTypes.string
};

export default NoResultPlaceholder;
