import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const HeaderContainer = styled.div`
  height: 4.7rem;
  width: 100%;

  display: grid;
  align-items: center;
  justify-items: center;

  border-radius: 1.2rem;
  box-shadow: 0 11px 22px -8px #e5e8ed;
  border: solid 1px ${colors.carbon3};
  background-color: ${colors.carbon4};
  color: ${colors.carbon};
  font-family: ${fonts.barlowSemiBold};
  font-size: 1.2rem;
  line-height: 1.08;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
  grid-template-columns: ${({ columns }) => columns};
  column-gap: 0.8rem;

  @media (max-width: 360px) {
    font-size: 0.9rem;
  }
`;
