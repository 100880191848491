import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  activeButton: {
    background: '#ed2246',
    color: '#fff',
    borderRadius: '2rem',
    borderColor: 'red',
    '&:hover': {
      background: '#ed2246',
      color: '#fff'
    }
  },
  title: {
    color: '#323132',
    fontSize: '4.8rem'
  },
  description: {
    color: '#989898',
    fontSize: '1.4rem'
  },
  card: {
    width: 'fit-content',
    margin: '4rem 0'
  },
  sectionTitle: {
    fontSize: '2rem',
    fontWeight: '600',
    marginTop: '4rem',
    lineHeight: '1.5'
  },
  formContainer: {
    margin: '0 auto'
  },
  form: {
    width: '36rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    margin: '0 auto'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2.5rem 0'
  },
  smallButtons: {
    borderRadius: '2rem',
    borderColor: 'red'
  },
  input: {
    display: 'none'
  },
  labelToggle: {
    fontSize: '1.6rem',
    color: '#323132'
  },
  checkbox: {
    marginTop: '2rem'
  },
  instructions: {
    fontSize: '1.8rem',
    color: '#989898'
  },
  header: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    margin: '0 auto'
  },
  infoContainer: {
    textAlign: 'center'
  },
  infoLabel: {
    color: '#1790cf'
  },
  shareholderToggle: {
    marginBottom: '1rem'
  }
}));
