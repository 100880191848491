import React from 'react';
import { Translation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Table, Td, Th, Tr } from '../styles';

const TableComponent = ({ children, array }) => (
  <Table>
    <thead>
      <Tr>
        <Th>
          <Translation>{t => t('public-api-docs-payload-parameter')}</Translation>
        </Th>
        <Th>
          <Translation>{t => t('public-api-docs-payload-description')}</Translation>
        </Th>
      </Tr>
    </thead>
    <tbody>
      {array && array.map(el => (
        <Tr key={uuidv4()}>
          <Td>{el.parameter}</Td>
          <Td>
            <Translation>{t => t(el.description)}</Translation>
          </Td>
        </Tr>
      ))}
      { children }
    </tbody>
  </Table>
);
export default TableComponent;
