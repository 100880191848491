import styled from 'styled-components';
import { fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ completeRow, hasReversals }) =>
    completeRow ? '1fr' : hasReversals ? 'repeat(5, 1fr)' : 'repeat(3, 1fr)'};
  grid-gap: 2.5rem;
  grid-auto-rows: minmax(2.7rem, auto);
  border-bottom: ${props =>
    props.hasSeparator ? '0.5px solid #939ca9a8;' : ''};

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }

  @media (max-width: 280px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.5rem;
  }
`;

export const Title = styled.p`
  ${fonts.quickSandSemiBold};
  font-size: 1.8rem;
  line-height: 0px;
  letter-spacing: 0.19px;

  @media (max-width: 768px) {
    line-height: 7px;
  }
`;
