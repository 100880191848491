import styled from 'styled-components';
import { colors } from '../../../Dashboard4.0/assets/styles/styles';

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 2rem;

  .infoBarContentStart {
    width: max-content;
  }

  .infoBarContentEnd {
    width: 100%;
    & > div {
      width: 100%;
    }
  }

  .creditDetailsSmall {
    display: none;
  }

  .creditHeader {
    border-top: 1px solid ${colors.carbon3};
    padding-top: 2.5rem;
    & > h2:nth-child(2) {
      margin: 4rem 3rem;
    }
  }

  @media (max-width: 780px) {
    .creditHeader {
      grid-template-columns: 1fr;
      & > h2:first-child {
        display: none;
      }
    }

    .creditPageGrid {
      grid-template-columns: 1fr;

      & > div:first-child {
        order: 2;
      }

      & > div:nth-child(2) {
        order: 1;
      }
    }

    .creditSideContent {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 4rem;
      column-gap: 2rem;

      & > div:nth-child(2) {
        margin-top: 0;
      }
    }

    .infoBarContentEnd {
      flex-direction: column;
      height: fit-content;
      padding: 1.5rem 2.4rem;

      & > div,
      button {
        margin: 1.5rem 0;
      }
    }

    .infoBarContentStart,
    .infoBarBtnContainer {
      display: none;
    }

    .creditDetailsSmall {
      display: block;
    }

    .entryDetailsContainer h2,
    span {
      font-size: 1.4rem;
    }
  }
`;
