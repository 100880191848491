/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import {
  ButtonContainer,
  InfoBarContainer,
  CreditDetailsContainer
} from './InfoBar.styles';
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';

export const Component = ({
  start,
  middle,
  button,
  columnGap,
  redirect,
  ...otherProps
}) => {
  return (
    <InfoBarContainer data-test="infoBar" {...otherProps}>
      {start && (
        <CreditDetailsContainer
          data-test="creditDetailsStart"
          columnGap={columnGap}
          start={start}
          className="infoBarContentStart"
          onClick={redirect}
          redirect={redirect}
        >
          {start}
        </CreditDetailsContainer>
      )}
      {middle && (
        <CreditDetailsContainer
          noBorders={start && !columnGap}
          data-test="creditDetailsMiddle"
          onClick={redirect}
          redirect={redirect}
          className="infoBarContentEnd"
        >
          {middle}
        </CreditDetailsContainer>
      )}
      {button && (
        <ButtonContainer
          onClick={e => e.stopPropagation()}
          className="infoBarBtnContainer"
        >
          {button}
        </ButtonContainer>
      )}
    </InfoBarContainer>
  );
};

Component.propTypes = {
  start: PropTypes.object,
  middle: PropTypes.object,
  button: PropTypes.object,
  columnGap: PropTypes.string,
  redirect: PropTypes.func
};

const InfoBar = props => {
  return (
    <ErrorBoundaryHandler component={<Component {...props} />} name="InfoBar" />
  );
};

export default InfoBar;
