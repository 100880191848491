import styled from 'styled-components';

import Modal from '../../../shared/components/Modal';

export const ModalContainer = styled.div`
  .overlay-container {
    background: none;
    width: fit-content;
    height: fit-content;
    position: inherit;
    margin: 1rem 0 10rem;
  }

  .overlayArea {
    box-shadow: 0px 11px 22px -8px rgba(28, 38, 51, 0.3);
    border-radius: 1.2rem;
  }
`;

export const StyledModal = styled(Modal)`
  width: 50.7rem;
  padding: 8.2rem 3.2rem 0;
`;
