/* 

Id's established according to the types of transactions processed by BE

'1', 'Payment order - payment';
'2', 'Internal transaction - charge';
'3', 'Payment order - deposit';
'4', 'Internal transaction - reinbursement';
'5', 'Credit card transaction - purchase';
'6', 'Credit card transaction - reversal transaction';
'7', 'Credit card transaction - adjustment';

*/

export const transactionTypeIds = {
  order: 1,
  charge: 2,
  deposit: 3,
  reinbursement: 4,
  purchase: 5,
  reversal: 6,
  adjustment: 7,
  multitransfer: 8
};

const paymentOrderText = 'Orden de pago';
const creditOrderText = 'Tarjeta de crédito';
const internalOrderText = 'Transacciones internas';

export const transactionTypesLabels = {
  [transactionTypeIds.deposit]: 'Depósito',
  [transactionTypeIds.reinbursement]: 'Reembolso',
  [transactionTypeIds.reversal]: 'Reverso',
  [transactionTypeIds.charge]: 'Cargo',
  [transactionTypeIds.adjustment]: 'Ajuste'
};

export const transactionIcons = {
  [transactionTypeIds.order]: 'issued',
  [transactionTypeIds.deposit]: 'reverseArrow',
  [transactionTypeIds.reinbursement]: 'bank',
  [transactionTypeIds.reversal]: 'card',
  [transactionTypeIds.charge]: 'bank',
  [transactionTypeIds.adjustment]: 'card',
  [transactionTypeIds.purchase]: 'card',
  [transactionTypeIds.multitransfer]: 'multitransfer'
};

export const transactionTypes = {
  internalTransactions: [
    transactionTypeIds.charge,
    transactionTypeIds.reinbursement
  ],
  paymentOrders: [transactionTypeIds.deposit, transactionTypeIds.order],
  creditCardTransactions: [
    transactionTypeIds.purchase,
    transactionTypeIds.reversal,
    transactionTypeIds.adjustment
  ],
  deltaiColor: [
    transactionTypeIds.deposit,
    transactionTypeIds.reinbursement,
    transactionTypeIds.reversal
  ],
  noDetailsTransaction: [
    transactionTypeIds.charge,
    transactionTypeIds.reinbursement
  ]
};

export const transactionDescriptions = {
  [transactionTypeIds.deposit]: paymentOrderText,
  [transactionTypeIds.order]: paymentOrderText,
  [transactionTypeIds.charge]: internalOrderText,
  [transactionTypeIds.reinbursement]: internalOrderText,
  [transactionTypeIds.purchase]: creditOrderText,
  [transactionTypeIds.reversal]: creditOrderText,
  [transactionTypeIds.adjustment]: creditOrderText
};
