import React from 'react';

// Components
import { StyledModal, Label, CardContainer, Dot } from './styles';

// Others
import Title from '../../../shared/components/Title/Title';
import Icon from '../../../shared/components/Icon/Icon';
import logo from '../../../../assets/logo-white.png';
import Button from '../../../shared/components/Button/Button';
import { formatCardNumberInput } from '../../../../helpers/helpers';
import { useSelector } from 'react-redux';
import { tagsSelector } from '../../../tags/redux/tag-selectors';
import {
  isAdminSelector,
  isSuperAdminSelector
} from '../../redux/cards-selectors';
import TagList from '../../../tags/TagList/TagList';

const CardSuccessModal = ({
  isEditingEnable = true,
  handleCreateNewCard,
  handleEditNewCard,
  handleModalClose,
  details,
  title
}) => {
  const {
    spend_limit,
    card_status_id,
    credit_card_name,
    last_four_digits,
    card_number,
    expiry_date,
    is_virtual,
    owner_name,
    address,
    cvv,
    credit_card_service_id,
    user_id,
    credit_card_id,
    owner_spending_limit
  } = details;

  const tags = useSelector(tagsSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);

  const isVirtualCard = is_virtual === 1;

  return (
    <StyledModal
      open
      onClose={handleModalClose}
      showCloseOnModal
      disableBackDropClick={false}
    >
      <div className="Container">
        <Title className="Title" size="large" text={credit_card_name} />
        <Label>{title}</Label>
        <CardContainer>
          <div className="start">
            <div className="statusContainer">
              <Dot status={!isVirtualCard ? 2 : card_status_id} />
              <div>
                {isVirtualCard ? (
                  <span className="activeText">Active</span>
                ) : (
                  <span className="activeText">Activación pendiente</span>
                )}
                <br />
                {card_status_id !== 1 && (
                  <a className="activeLink">Así se activa</a>
                )}
              </div>
            </div>
          </div>
          <div className="middle">
            <p className="ownersName">{owner_name}</p>

            {isVirtualCard && card_number ? (
              <p>{formatCardNumberInput(card_number).value}</p>
            ) : (
              <>
                <span className="blurDots">{`⚪⚪⚪⚪`}</span>
                <span className="blurDots marginLeft">{`⚪⚪⚪⚪`}</span>
                <span className="blurDots marginLeft">{`⚪⚪⚪⚪`}</span>
                <span
                  className={`${!last_four_digits && 'blurDots'} marginLeft`}
                >
                  {last_four_digits ? `${last_four_digits}` : '⚪⚪⚪⚪'}
                </span>
              </>
            )}

            {isVirtualCard && expiry_date ? (
              <p className="exp">{`EXP ${expiry_date}`}</p>
            ) : (
              <p className="exp">
                <span>EXP</span>
                <span className="blurDots marginLeft">⚪⚪/⚪⚪</span>
              </p>
            )}

            {isVirtualCard && cvv ? (
              <p className="cvc">{`CVC ${cvv}`}</p>
            ) : (
              <p className="cvc">
                <span>CVC</span>
                <span className="blurDots marginLeft">⚪⚪⚪</span>
              </p>
            )}
          </div>
          <div className="end">
            <img className="logo" src={logo} />
            <Icon icon={'mastercardLogo'} />
          </div>
        </CardContainer>
        <hr />
        <div className="detailsContainer">
          {!isVirtualCard && address && (
            <div className="detailsGroup">
              <span className="label">Domicilio</span>
              <span className="info">{address}</span>
            </div>
          )}
          <div className="detailsGroup">
            <span className="label">Limite de gasto</span>
            <span className="info">{`${spend_limit}`}</span>
          </div>
          {tags.length > 0 && (
            <div className="detailsGroup">
              <span className="label">Tags</span>

              <TagList
                tags={tags}
                hasPermissions={isSuperAdmin || isAdmin}
                showAddNewTag={false}
                isDeleteVisible={false}
              />
            </div>
          )}
        </div>
        <hr />
        <div className="buttonContainer">
          {isEditingEnable && (
            <Button
              data-test="editCardDetaislButton"
              text="Cambiar configuración de tarjeta"
              variant="secondary"
              onClick={() => {
                handleEditNewCard();
              }}
            />
          )}
          <Button
            data-test="createNewCardButton"
            text="Crear otra tarjeta"
            variant="overlaySecondary"
            onClick={() => {
              handleCreateNewCard();
            }}
          />
        </div>
      </div>
    </StyledModal>
  );
};
export default CardSuccessModal;
