/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { MenuItemContainer } from './SideNav.styles';

const MenuItem = ({ label, to, onClick, icon }) => {
  return (
    <MenuItemContainer
      data-testid="MenuItemNavLink"
      to={to}
      activeClassName="selected"
      onClick={onClick}
    >
      {icon} {label}
    </MenuItemContainer>
  );
};

MenuItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func
};

export default memo(MenuItem);
