import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { checkRFCPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const checkRFCService = async rfc => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
        checkRFCPath,
        {rfc: rfc}
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'check-RFC-service'
    });
  } catch (error) {
    log.error('check-RFC-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};