import styled from 'styled-components';

import { getGridColumns } from '../../../../Dashboard4.0/assets/styles/grid';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const Header = styled.div`
  font-size: 16px;
  line-height: 19px;
  p {
    margin: 0;
  }
`;

const colorValues = {
  blue: {
    fontColor: colors.primary,
    background: colors.primary4
  }
};

const setColor = ({ color }) => {
  let background;
  let defaultColor = colors.carbon;

  if (color) {
    defaultColor = colorValues[color].fontColor;
    background = colorValues[color].background;
  }

  return `
    background: ${background};
    color: ${defaultColor};
`;
};

export const Container = styled.div`
  font-size: 1.2rem;
  position: sticky;
  top: 0;

  a {
    color: ${colors.carbon};
    text-decoration: none;
    &:hover {
      color: ${colors.primary};
    }
    width: 100%;
    padding: 0.8rem 1.8rem;
    display: block;
  }
`;

export const SectionContainer = styled.div`
  width: 19.2rem;
`;

export const Section = styled.div`
  font-family: ${fonts.quickSandSemiBold};
  font-weight: 600;
  line-height: 3.5;
  letter-spacing: 0.5px;
  width: 100%;
  height: 4rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${setColor};
  ${getGridColumns};

  &:hover {
    cursor: pointer;
  }
`;

export const SubSection = styled.p`
  font-family: ${fonts.barlowSemiBold};
  width: 100%;
  margin: 0;
  padding: 0;
  ${setColor};
  ${getGridColumns};
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  border: solid 1px ${colors.carbon3};
  border-top: 0;
  border-bottom: 0;
  &:last-child {
    border-bottom: solid 1px ${colors.carbon3};
  }
`;
