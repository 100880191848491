/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
import * as currencyFormatter from 'currency-formatter';
import moment from 'moment';

import spanish from '../catalogs/spanish.json';

export const getBase64 = file => {
  if (!file) return;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = err => reject(err);
    reader.readAsDataURL(file);
  }).then(res => {
    const trimmedBase64 = res.split('base64,')[1];
    return trimmedBase64;
  });
};

export const getFileName = (file, fileName) => {
  const fileExtension = file.slice(file.lastIndexOf('.'), file.length);
  return fileName + fileExtension;
};

export const trimBase64 = base64 => {
  return base64.split('base64,')[1];
};

export const transformDate = date => {
  let d = new Date(date).toLocaleDateString().split('/');
  if (!d[0] || !d[1] || !d[2]) {
    return;
  }
  let dd = d[1].padStart(2, 0);
  let mm = d[0].padStart(2, 0);
  let yyyy = d[2];
  return `${dd}-${mm}-${yyyy}`;
};

export const getSpacing = width => {
  if (width === 'sm' || width === 'xs') {
    return 0;
  }
  return 2;
};

export const sortCountries = (a, b) => a.name.localeCompare(b.name);

export const getMaxDate = () => {
  let date = new Date().toLocaleDateString().split('/');
  date[2] = String(parseInt(date[2]) - 18);

  return `${date[2]}-${date[1]}-${date[0]}`;
};

export const getProgress = (totalSteps, currentStep) => {
  if (currentStep === 0) {
    return 0;
  }
  return (100 / totalSteps) * currentStep;
};

export const validateMinAliasLength = email => {
  let alias = email.split('@')[0];
  let aliasLength = alias.length;
  if (aliasLength < 6) {
    return false;
  }
  return true;
};

export const validateMaxAliasLength = email => {
  if (email) {
    let alias = email.split('@')[0];
    let aliasLength = alias.length;
    if (aliasLength > 30) {
      return false;
    }
    return true;
  }
};

const removeLettersAndSpecialCharacters = amount =>
  amount.replace(/[a-zA-Z¡¿!@#%^&*()_+\-=[\]{};':"\\|<>/?]/gi, '');

export const formatAmount = amount => {
  if (!amount) return;
  amount = removeLettersAndSpecialCharacters(amount);
  if (amount.includes(',')) amount = amount.replace(/,/g, '');
  let decimals = '';
  let charArray = [];
  let formatedAmount = '';

  if (amount.includes('.')) {
    charArray = amount.split('.')[0].split('');
    decimals = '.' + amount.split('.')[1].slice(0, 2);
  } else {
    charArray = amount.split('');
  }

  if (charArray.length >= 4) {
    for (let i = charArray.length; i > 0; i--) {
      if ((charArray.length - i) % 3 === 0 && charArray.length - i !== 0) {
        formatedAmount = ',' + formatedAmount;
      }
      formatedAmount === undefined
        ? (formatedAmount = charArray[i - 1])
        : (formatedAmount = charArray[i - 1] + formatedAmount);
    }
    return formatedAmount + decimals;
  }
  return charArray.join('') + decimals;
};

export const formatDate = date => {
  if (!date) return;

  let today = moment();

  let currentDate = moment(date);

  let month = currentDate.month();
  let day = '';

  if (
    currentDate.date() === today.date() &&
    currentDate.month() === today.month() &&
    currentDate.year() === today.year()
  ) {
    day = 'Hoy - ' + currentDate.date();
  } else if (
    currentDate.date() === today.date() - 1 &&
    month === today.month() &&
    currentDate.year() === today.year()
  ) {
    day = 'Ayer - ' + currentDate.date();
  } else {
    day = currentDate.date();
  }

  switch (month) {
    case 0:
      return `${day} de Enero`;
    case 1:
      return `${day} de Febrero`;
    case 2:
      return `${day} de Marzo`;
    case 3:
      return `${day} de Abril`;
    case 4:
      return `${day} de Mayo`;
    case 5:
      return `${day} de Junio`;
    case 6:
      return `${day} de Julio`;
    case 7:
      return `${day} de Agosto`;
    case 8:
      return `${day} de Septiembre`;
    case 9:
      return `${day} de Octubre`;
    case 10:
      return `${day} de Noviembre`;
    case 11:
      return `${day} de Diciembre`;
    default:
      return 'No disponible';
  }
};

export const removeTime = date => {
  // Parse time to UTC+6
  // eslint-disable-next-line no-underscore-dangle
  let newDate = moment(date.transaction_datetime).parseZone().utcOffset('GMT')
    ._d;
  //Return date without the time
  return moment(newDate).format('YYYY-MM-DD');
};

export const groupByDate = arr => {
  if (!arr) return;
  let dates = {};

  function groupTransactions(arr) {
    if (arr.length === 0) return;

    let item = arr[arr.length - 1];
    let date = removeTime(arr[arr.length - 1]);
    dates[date] ? dates[date].push(item) : (dates[date] = [item]);
    return groupTransactions(arr.slice(0, arr.length - 1));
  }

  groupTransactions(arr);

  return Object.entries(dates).sort((a, b) => {
    let date1 = moment(a[0]);
    let date2 = moment(b[0]);

    if (date1 < date2 || !date1.isValid()) return 1;
    if (date2 < date1 || !date2.isValid()) return -1;

    return 0;
  });
};

export const getTransactionDate = transaction => {
  const date = transaction.transaction_datetime.split(' ')[0];
  return date;
};

export const parseAmount = amount => {
  return currencyFormatter.format(amount, {
    code: 'MXN'
  });
};

const translateRow = (arr, type) => {
  /**
   * arr|array
   * type|string|key from spanish json
   */

  return arr.map(el => {
    const translated = type ? spanish[type][el] : spanish[el];

    if (!translated) return el;
    return translated;
  });
};

export const parseTransactions = (arr, type) => {
  /**
   * arr|array
   * type|string|key from spanish json
   */

  const rows = [];
  let keys;

  keys = Object.keys(arr[0]);
  rows.push(translateRow(keys, type));

  arr.forEach(el => {
    const values = Object.values(el);
    rows.push(translateRow(values, type));
  });

  return rows;
};

export const convertToCsv = async data => {
  const csv = data
    .map(d => {
      return d.join();
    })
    .join('\n');

  // eslint-disable-next-line no-return-await
  return await csv;
};

export const makeCsvFile = csv => {
  const file = new File([csv], 'some.csv', {
    type: 'text/csv;charset=utf-8;'
  });

  return file;
};

export const getUuid = pathname => {
  return pathname.split('/')[2];
};

export const convertB64ToFile = (b64, filename) => {
  const arr = b64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-const-assign
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const transformToDate = string => {
  const newDate = moment(string);
  return newDate;
};

const getMonths = isFullMonth => {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Sept',
    'Octubre',
    'Nov',
    'Dic'
  ];

  if (isFullMonth) {
    months[8] = 'Septiembre';
    months[10] = 'Noviembre';
    months[11] = 'Diciembre';
  }

  return months;
};

export const removeTimezone = date => {
  // TODO I hope this is only temporary function until we remove Time zones from UTC or move to ISO
  const parsedUTCDate = date.match(/^(.*)\s+(\w+)$/);

  if (!parsedUTCDate) {
    return date;
  }
  return parsedUTCDate[1];
};

// This method will cut month strings that are too long
export const getShortDate = (string, format, isFullMonth) => {
  if (!string) return;

  let date = moment(string);

  if (!date._isValid) {
    // BUG this is trap and when code comes here it will fail if it have timezone in UTC. HEL-300 - the solution just covered the problem. Need to change to ISO dates if we want to use timezones.
    date = transformToDate(removeTimezone(string));
  }

  moment.updateLocale('es', {
    months: getMonths(isFullMonth),
    monthsShort: [
      'ENE',
      'FEB',
      'MAR',
      'ABR',
      'MAY',
      'JUN',
      'JUL',
      'AGO',
      'SEP',
      'OCT',
      'NOV',
      'DIC'
    ]
  });

  return date.locale('es').format(format || 'DD [de] MMMM [de] YYYY');
};

export const splitDate = (string, format) => {
  const shortDate = getShortDate(string, format);
  const longMonth = getShortDate(string, 'MMMM', true).toLowerCase();
  let dateArray = shortDate.split(' ');

  return {
    day: dateArray[0],
    month: dateArray[1],
    longMonth: longMonth,
    year: dateArray[2]
  };
};

export const splitAmount = amount => {
  let digits = amount.split('.');
  let integer = digits[0].split('$');
  let sign = integer[0] ? '-$' : '$';
  return { sign: sign, integer: integer[1], decimals: `.${digits[1]}` };
};

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const convertToLocalTime = transactionTime => {
  const date = moment.utc(removeTimezone(transactionTime));

  const time = date.parseZone().utcOffset('GMT')._d;

  return time;
};

export const getInitials = name => {
  if (!name) return '';
  const firstInitial = getFirstIntial(name);
  const lastInitial = getLasInital(name);
  return `${firstInitial}${lastInitial}`.toUpperCase();
};

const getFirstIntial = name => {
  if (!name) return '';

  return name[0] || '';
};

const getLasInital = name => {
  let indexOfSpace = name.search(' ');
  if (!name || indexOfSpace === -1) return '';

  const lastInitial = name[indexOfSpace + 1] || '';

  return lastInitial;
};

export const checkEmptySpaces = value => value.startsWith(' ');

export const formatAccountNumber = number => {
  if (!number) return;
  let chars = number.split('');
  let formatedAccountNum = '';

  for (let i = 0; i < chars.length; i++) {
    formatedAccountNum += chars[i];
    if (number.length === 16 && (i + 1) % 4 === 0) {
      formatedAccountNum += ' ';
    } else if (number.length === 18 && (i + 1) % 6 === 0)
      formatedAccountNum += ' ';
  }

  return formatedAccountNum;
};

export const formatClabeCodeInput = value => {
  let number = value.trim();

  return {
    value: number
      .replace(/\s/g, '')
      .replace(/^(\d{3})/, '$1 ')
      .replace(/(\d{4})/, '$1 ')
      .replace(/(\d{3} ?\d{4} ?\d{3})/, '$1 ')
      .replace(/(\d{3} ?\d{4} ?\d{3} ?\d{3})/, '$1 '),
    type: 'clabe_code'
  };
};

export const formatCardNumberInput = value => {
  return {
    value: value.replace(/\s/g, '').replace(/(\d{4})/g, '$1 '),
    type: 'debit_card_number'
  };
};

export const formatAccountInputValue = value => {
  const valueNoSpace = value.replace(/\s/g, '');
  const numericValue = valueNoSpace.replace(/\D/g, '');

  return valueNoSpace.length > 16
    ? formatClabeCodeInput(numericValue)
    : formatCardNumberInput(numericValue);
};
