// React imports
import React, { useState } from 'react';

// Auxiliary librarys imports
import { Grid, Modal } from '@material-ui/core';
import Webcam from 'react-webcam';

// Own Components
import { PrimaryButton, WhiteButton } from '../../../../components/Shared';

// Assets
import { CustomModalStyles } from '../../../../components/CustomModal/customModalStyles';
import { convertB64ToFile } from '../../../../helpers/helpers';
import face from '../../../../components/CustomModal/marker.svg';

const videoConstraints = {
  width: 720,
  height: 480,
  facingMode: 'user'
};

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
};

const SelfieModal = ({ onChange, width, open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle);
  const [imgSrc, setImgSrc] = useState('');
  const [mirrored, setMirrored] = useState(true);

  // Classes
  const classes = CustomModalStyles();

  const webcamRef = React.useRef(null);
  const takePhoto = React.useCallback(() => {
    setMirrored(false);
    setTimeout(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }, process.env.REACT_APP_IMAGE_MIRRORING_TIMEOUT);
  }, [webcamRef]);

  const uploadPhoto = async () => {
    setMirrored(true);
    let image = convertB64ToFile(imgSrc, 'selfie.jpg');
    onChange(image);
  };

  const tryAgain = () => {
    setMirrored(true);
    setImgSrc('');
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={() => setOpen(false)}
    >
      <div
        style={modalStyle}
        className={`${classes.paper} ${classes.modalContent} ${classes.paperRedesign}`}
      >
        <Grid className={classes.modalHeader} container justify="center">
          <span
            className={`${classes.modalTitle} ${classes.modalTitleRedesign}`}
          >
            Ahora tómate una selfie, retira cualquier objeto de tu rostro y
            colócalo dentro de óvalo
          </span>
        </Grid>
        <div className={classes.cameraRedesign}>
          {imgSrc ? (
            <>
              <img
                src={imgSrc}
                alt="Example frame"
                width={'100%'}
                height="inherit"
              />
            </>
          ) : (
            <>
              <img src={face} alt="Face mask" className={classes.faceMask} />

              <Webcam
                audio={false}
                height={width === 'xs' ? 296 : 296}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={width === 'xs' ? 250 : 419}
                videoConstraints={videoConstraints}
                screenshotQuality={1}
                mirrored={mirrored}
              />
            </>
          )}
        </div>
        <Grid
          className={classes.buttonsCenter}
          container
          justify="center"
          spacing={2}
        >
          <Grid item sm={6}>
            <WhiteButton
              copy="Reintentar"
              onClick={tryAgain}
              customClass={classes.marginZero}
              disabled={!imgSrc}
            />
          </Grid>
          <Grid item sm={6}>
            <PrimaryButton
              onClick={imgSrc ? uploadPhoto : takePhoto}
              text={imgSrc ? 'Subir' : 'Tomar foto'}
              disabled={imgSrc?.content}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default SelfieModal;
