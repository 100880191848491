import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const SelectBeneficiaryContainer = styled.div`
  @media (min-width: 768px) {
    max-width: 80rem;
    max-height: 65rem;
    width: 65.5rem;
  }

  width: 100%;
  max-height: 100vh;

  .selectBeneficiaryTitle {
    color: ${colors.carbon};
    margin-bottom: 20px;
  }

  .multiTransferOption {
    padding: 2rem 0rem 1rem;
  }
`;

const buttonText = css`
  ${fonts.barlowMedium}
  color: ${colors.carbon};
  font-size: 1.4rem;
  margin-left: 0.8rem;
  min-width: max-content;
`;

export const FilterBarContainer = styled.div`
  width: 100%;
  padding: 0 4.8rem;
  display: flex;
  align-items: baseline;

  div:first-child {
    width: 100%;
    display: flex;
  }

  input {
    width: 100%;
    ${fonts.barlowMedium};
    color: ${colors.carbon};
  }

  .downloadCsv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    ${buttonText}

    svg path {
      fill: ${colors.carbon};
    }

    span {
      margin-left: 0.8rem;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 1.6rem;

    .filterBarHideItem {
      display: none;
    }
  }
`;

export const MultipleTransfersButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: ${colors.deltai2};
  width: 26rem;
  height: 3.8rem;
  margin: auto;
  border-radius: 20px;
  position: relative;
  ${fonts.quickSandSemiBold}

  img {
    right: 3rem;
    top: 1.4rem;
    position: absolute;
  }

  .tooltip {
    position: absolute;
    left: 23rem;
    top: 1.4rem;
  }
`;

export const MultipleTransfersText = styled.div`
  ${buttonText}
  color: ${colors.white};
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.175px;
`;
