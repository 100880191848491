import styled, { css } from 'styled-components';

import {
  colors,
  gradients
} from '../../../../Dashboard4.0/assets/styles/styles';

const outlinedStyles = css`
  border: solid 1px ${colors.carbon1};
  color: ${colors.carbon1};
  background-image: none;

  &:hover {
    border: solid 1px ${colors.primary};
    color: ${colors.primary};
  }
`;

const outlinedBlackStyles = css`
  border: 1px solid ${colors.almostBlack};
  color: ${({ hasEmptyFile }) =>
    hasEmptyFile ? colors.primary : colors.almostBlack};
  background: none;
  margin-top: 0.8rem;
`;
const secondaryStyles = css`
  border: solid 1px ${colors.almostBlack};
  color: ${colors.almostBlack};
  background-image: none;
  background: none;
`;

const successStyles = css`
  background-image: ${gradients.successGradient};
`;

const sizeLarge = css`
  font-size: 1.7rem;
  padding: 0 6.4rem;
  height: 6.1rem;
  letter-spacing: 0.21px;
  border-radius: 35.5px;
`;

const sizeSmall = css`
  height: 3rem;
  padding: 0 1.6rem;
  border-radius: 15.5px;
  font-size: 1.2rem;
  letter-spacing: 0.15px;
  line-height: 2.42;
`;

const sizeMedium = css`
  height: 5rem;
  padding: 0 1.6rem;
  border-radius: 35.5px;
  font-size: 1.7rem;
  letter-spacing: 0.21;
`;

const getVariantStyles = ({ variant }) => {
  const selector = {
    outlined: outlinedStyles,
    outlinedBlack: outlinedBlackStyles,
    success: successStyles,
    secondary: secondaryStyles,
    overlaySecondary: overlayButtonSecondaryStyles,
    overlayPrimary: overlayButtonPrimaryStyles
  };

  return selector[variant];
};

const getSize = ({ size }) => {
  const sizeSelector = {
    lg: sizeLarge,
    md: sizeMedium,
    sm: sizeSmall
  };

  return sizeSelector[size];
};

export const ButtonContainer = styled.button`
  min-width: 9rem;
  height: 3.9rem;
  border-radius: 1.95rem;
  background-image: ${gradients.deltaiGradient};
  padding: 0 2.6rem;
  border: none;
  color: white;
  font-family: Quicksand;
  letter-spacing: 0.18px;
  font-size: 1.7rem !important;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  transition: 300ms ease all;
  @media (min-width: 470px) {
    font-size: 1.7rem !important;
  }

  &:hover {
    box-shadow: 1px 2px 4px 0 #cad0d6, -1px -2px 4px 0 white;
  }

  ${getVariantStyles};
  ${getSize};

  &:disabled {
    background: rgba(28, 38, 51, 0.5);
    color: white;
    cursor: default;
    box-shadow: none;
  }

  .checkmarkIcon {
    margin-left: 0.8rem;
  }
`;

const OverlayButton = css`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  background: none;
`;

const overlayButtonSecondaryStyles = css`
  ${OverlayButton}
  color: ${colors.carbon};

  &:hover {
    box-shadow: none;
  }
`;

const overlayButtonPrimaryStyles = css`
  ${OverlayButton};
  background-color: ${colors.carbon4};
  color: ${colors.deltai};
  &:hover {
    box-shadow: 1px 2px 4px 0 rgba(93, 100, 108, 0.5),
      -1px -2px 4px 0 rgba(93, 100, 108, 0.5);
    background-color: ${colors.carbon4};
  }
`;
