import styled from 'styled-components';

export const AlertLink = styled.a`
  color: #000000;
`;

export const AlertSpan = styled.span`
  color: #000000;
`;

export const AlertButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #000000;
  cursor: pointer;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
