import BankIcon from './bank-icon.svg';
import CardIcon from './card.svg';
import MultiTransfer from './multitransfer.svg';
import MultiReversal from './multiReversal.svg';
import ReverseArrow from './reverse-arrow.svg';
import CardChip from './cardChip.svg';
import MastercardLogo from './Mastercard-logo.svg';
import CardCirusLogo from './Card-cirus-logo.svg';
import pdfIcon from './pdf-icon.svg';
import copyIcon from './copy.svg';

export const icons = {
  search: {
    viewBox: '0 0 14 14',
    fill: '#1B232C',
    d:
      'M6 10.5c-2.485 0-4.5-2.015-4.5-4.5S3.515 1.5 6 1.5s4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5m7.78 2.22l-3.045-3.045C11.525 8.659 12 7.387 12 6c0-3.313-2.687-6-6-6S0 2.687 0 6s2.687 6 6 6c1.387 0 2.659-.475 3.675-1.265l3.045 3.045c.293.293.767.293 1.06 0 .293-.293.293-.767 0-1.06'
  },
  date: {
    viewBox: '0 0 14 14',
    fill: '#939ca9',
    d:
      'M12.5 1.5c.828 0 1.5.672 1.5 1.5v9.5c0 .828-.672 1.5-1.5 1.5h-11C.672 14 0 13.328 0 12.5V3c0-.828.672-1.5 1.5-1.5H3V.75c0-.414.336-.75.75-.75s.75.336.75.75v.75h5V.75c0-.414.336-.75.75-.75s.75.336.75.75v.75h1.5zm0 4h-11v7h11v-7z'
  },
  download: {
    viewBox: '0 0 10 14',
    fill: '#1B232D',
    d:
      'M9.25 12.5c.414 0 .75.336.75.75s-.336.75-.75.75H.75c-.414 0-.75-.336-.75-.75s.336-.75.75-.75zM6.5 0c.276 0 .5.224.5.5v4.78h1.916c.427 0 .657.501.38.825l-4.106 4.81c-.1.117-.28.117-.38 0L.704 6.105c-.277-.324-.047-.825.38-.825H3V.5c0-.276.224-.5.5-.5z'
  },
  mail: {
    viewBox: '0 0 18 14',
    fill: '#1B232D',
    d:
      'M17.921 1.04c.047.145.079.298.079.46v11c0 .828-.672 1.5-1.5 1.5h-15C.672 14 0 13.328 0 12.5v-11c0-.162.033-.315.079-.46l8.615 6.699c.18.14.432.14.613 0zM16.501 0l.05.149-7.397 5.754c-.09.07-.217.07-.307 0L1.45.149 1.5 0z'
  },
  deltai: {
    viewBox: '0 0 30 30',
    fill: '#ED2246',
    d:
      'M24.452 24.14L15 12.453 5.549 24.14c-1.295-1.338-2.308-2.948-2.944-4.736L15 4.035l12.418 15.307c-.636 1.812-1.658 3.445-2.966 4.798m-17.53 1.236L15 15.387l8.077 9.989c-2.23 1.74-5.035 2.779-8.077 2.779-3.043 0-5.847-1.039-8.078-2.78M14.386 1.86L2.022 17.15c-.116-.7-.177-1.419-.177-2.15 0-7.048 5.571-12.818 12.54-13.14M28.156 15c0 .731-.062 1.45-.177 2.15L15.614 1.86c6.97.322 12.54 6.092 12.54 13.14M15 0C6.73 0 0 6.729 0 15s6.73 15 15 15c8.271 0 15-6.729 15-15S23.271 0 15 0'
  },
  question: {
    viewBox: '0 0 10 10',
    fill: '#ED2246',
    d:
      'M5 0c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm0 1C2.79 1 1 2.79 1 5s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z M5.202 5.982c.023 0 .043-.008.06-.025.016-.016.024-.036.024-.059V5.66c0-.126.04-.232.119-.319.08-.086.2-.187.364-.304.163-.117.293-.222.388-.315.096-.093.18-.215.253-.364.072-.15.108-.327.108-.532 0-.397-.135-.715-.406-.956-.27-.24-.632-.36-1.085-.36-.303 0-.572.057-.805.172-.233.114-.415.275-.546.482-.13.208-.196.445-.196.711v.105c0 .023.008.044.025.063.016.019.036.028.059.028l.644.028c.056 0 .084-.026.084-.077v-.147c0-.182.065-.331.196-.448.13-.117.303-.175.518-.175.205 0 .371.055.497.165.126.11.189.255.189.437 0 .163-.047.3-.14.41-.093.11-.233.232-.42.367-.215.154-.378.3-.49.438-.112.137-.168.318-.168.542v.287c0 .023.008.043.024.06.017.016.037.024.06.024h.644zm-.343 1.505c.14 0 .257-.047.35-.14.093-.093.14-.212.14-.357s-.046-.263-.136-.354c-.091-.09-.21-.136-.354-.136-.145 0-.264.045-.357.136-.093.091-.14.21-.14.354 0 .145.047.264.14.357.093.093.212.14.357.14z'
  },
  issued: {
    viewBox: '0 0 12 12',
    fill: '#ED2246',
    d:
      'M3.71935 0C3.32236 0 3.00038 0.322007 3.00038 0.720015C3.00038 1.11802 3.32236 1.44003 3.71935 1.44003H9.5421L0.211491 10.7712C-0.0704971 11.0522 -0.0704971 11.5082 0.211491 11.7892C0.49248 12.0703 0.947461 12.0703 1.22945 11.7892L10.5601 2.45805V8.28017C10.5601 8.67818 10.882 9.00019 11.28 9.00019C11.677 9.00019 12 8.67818 12 8.28017V0.720015C12 0.322007 11.677 0 11.28 0H3.71935Z'
  },
  arrow: {
    viewBox: '0 0 10 17',
    fill: '#D8DCE2',
    d:
      'M7.486 4L6.488 5.092 9.576 7.768 -2.664 7.768 -2.664 9.232 9.576 9.232 6.488 11.902 7.486 13 12.664 8.5z'
  },
  attachment: {
    viewBox: '0 0 13 14',
    fill: '#1B232D',
    d:
      'M4.593 14c-1.004 0-1.948-.391-2.66-1.102-1.465-1.465-1.465-3.851 0-5.316l2.978-2.978c.883-.882 2.32-.882 3.203 0 .884.883.884 2.32 0 3.203L6.153 9.769c-.293.293-.768.293-1.06 0-.293-.293-.293-.768 0-1.061l1.96-1.961c.299-.299.299-.784 0-1.082-.298-.298-.782-.298-1.081 0L2.995 8.642c-.881.881-.881 2.315 0 3.196.855.853 2.343.853 3.196 0l4.062-4.062c1.433-1.433 1.433-3.766 0-5.199-1.433-1.434-3.765-1.434-5.2 0L1.452 6.18c-.293.293-.768.293-1.061 0-.293-.293-.293-.768 0-1.061l3.603-3.603C4.97.539 6.27 0 7.653 0c1.383 0 2.683.539 3.66 1.516 2.019 2.018 2.019 5.302 0 7.321l-4.061 4.061C6.542 13.609 5.597 14 4.592 14'
  },
  checkmark: {
    viewBox: '0 0 13 11',
    fill: 'white',
    d:
      'M11.47.504c.322-.354.869-.38 1.223-.06.324.295.373.78.132 1.13l-.073.092-8.021 8.835c-.315.346-.84.375-1.19.086l-.084-.079-3.2-3.438C-.07 6.72-.05 6.173.3 5.847c.32-.298.807-.306 1.136-.036l.086.08L4.081 8.64l7.39-8.136z'
  },
  pencil: {
    viewBox: '0 0 14 14',
    fill: '#afb6c2',
    d:
      'M13.3 2.15L11.9.75c-.8-.8-2-.8-2.8 0l-9 8.9v4.2h4.2l8.9-8.9c.9-.8.9-2 .1-2.8z'
  },
  next: {
    viewBox: '0 0 5 8',
    fill: '#1C2633',
    d:
      'M.75 8C.547 8 .347 7.92.2 7.76c-.282-.303-.264-.778.04-1.06L3.147 4 .24 1.3C-.064 1.018-.08.543.2.24.481-.065.956-.083 1.26.2l3.5 3.25c.153.142.24.342.24.55 0 .208-.087.408-.24.55L1.26 7.8c-.145.134-.328.2-.51.2'
  },
  filter: {
    viewBox: '0 0 24 24',
    fill: '#000',
    d:
      'M1,4.75H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2ZM7.333,2a1.75,1.75,0,1,1-1.75,1.75A1.752,1.752,0,0,1,7.333,2Z M23,11H20.264a3.727,3.727,0,0,0-7.194,0H1a1,1,0,0,0,0,2H13.07a3.727,3.727,0,0,0,7.194,0H23a1,1,0,0,0,0-2Zm-6.333,2.75A1.75,1.75,0,1,1,18.417,12,1.752,1.752,0,0,1,16.667,13.75Z M23,19.25H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2ZM7.333,22a1.75,1.75,0,1,1,1.75-1.75A1.753,1.753,0,0,1,7.333,22Z'
  },
  cancel: {
    viewBox: '0 0 10 10',
    fill: '#1B232D',
    d:
      'M9.24264 0.757358C9.57068 1.0854 9.57068 1.61726 9.24264 1.9453L6.18794 4.9983L9.24264 8.0547C9.57068 8.38274 9.57068 8.9146 9.24264 9.24264C8.9146 9.57068 8.38274 9.57068 8.0547 9.24264L5 6.18624L1.9453 9.24264C1.61726 9.57068 1.0854 9.57068 0.757359 9.24264C0.429319 8.9146 0.429319 8.38274 0.757359 8.0547L3.81291 4.99915L0.757359 1.9453C0.429319 1.61726 0.429319 1.0854 0.757359 0.757358C1.0854 0.429318 1.61726 0.429318 1.9453 0.757358L5.00085 3.81121L8.0547 0.757358C8.38274 0.429318 8.9146 0.429318 9.24264 0.757358Z'
  },
  doubleTicks: {
    viewBox: '0 0 20 10',
    fill: '#1B232D',
    d:
      'M18.6887 0.252689L11.3677 8.49969L8.61574 5.41069L12.3107 1.24869C12.5857 0.938689 12.5577 0.464689 12.2477 0.189689C11.9367 -0.0853109 11.4637 -0.0573109 11.1887 0.252689L3.86774 8.50069L1.30974 5.62669C1.03574 5.31769 0.560742 5.28969 0.250742 5.56569C-0.0582579 5.84069 -0.0852579 6.31469 0.189742 6.62469L2.74774 9.49769C3.03174 9.81669 3.43974 10.0007 3.86774 10.0007C4.29674 9.99969 4.70574 9.81569 4.98974 9.49569L7.61274 6.54069L10.2477 9.49769C10.5327 9.81669 10.9407 10.0007 11.3677 10.0007C11.7967 9.99969 12.2057 9.81569 12.4897 9.49569L19.8107 1.24869C20.0857 0.938689 20.0577 0.464689 19.7477 0.189689C19.4367 -0.0853109 18.9627 -0.0573109 18.6887 0.252689Z'
  },
  money: {
    viewBox: '0 0 9 14',
    fill: '#1B232D',
    d:
      'M0 9.251C0 9.113 0.112 9.001 0.25 9.001H2.771C2.901 9.001 2.998 9.102 3.017 9.23C3.127 9.949 3.749 10.5 4.499 10.5H5C5.552 10.5 6 10.052 6 9.5C6 9.198 5.817 8.927 5.538 8.814L2.066 7.677C0.155 7.051 -0.797 4.63 0.831 2.65C1.326 2.047 2.042 1.66 2.812 1.531L3 1.5V0.5C3 0.224 3.224 0 3.5 0H5.5C5.776 0 6 0.224 6 0.5V1.5C7.657 1.5 9 2.843 9 4.5V4.749C9 4.887 8.888 4.999 8.75 4.999H6.229C6.099 4.999 6.002 4.898 5.982 4.771C5.873 4.051 5.251 3.5 4.501 3.5H4C3.448 3.5 3 3.948 3 4.5C3 4.802 3.183 5.073 3.462 5.186L6.934 6.323C8.845 6.949 9.796 9.37 8.17 11.35C7.675 11.952 6.959 12.34 6.189 12.468L6 12.5V13.5C6 13.776 5.776 14 5.5 14H3.5C3.224 14 3 13.776 3 13.5V12.5C1.343 12.5 0 11.157 0 9.5V9.251Z'
  },
  bank: {
    isImage: true,
    src: BankIcon
  },
  card: {
    isImage: true,
    src: CardIcon
  },
  reverseArrow: {
    isImage: true,
    src: ReverseArrow
  },
  multitransfer: {
    isImage: true,
    src: MultiTransfer
  },
  multiReversal: {
    isImage: true,
    src: MultiReversal
  },
  cardChip: {
    isImage: true,
    src: CardChip
  },
  mastercardLogo: {
    isImage: true,
    src: MastercardLogo
  },
  CardCirusLogo: {
    isImage: true,
    src: CardCirusLogo
  },
  pdfIcon: {
    isImage: true,
    src: pdfIcon
  },
  copyIcon: {
    isImage: true,
    src: copyIcon
  }
};
