import styled from 'styled-components';

import MultipartFileInput from '../../../shared/components/MultipartFileInput';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 4rem;
  }

  .stepTwoButton {
    margin-top: 6rem;
    width: 100%;
  }
`;

export const FileInput = styled(MultipartFileInput)`
  width: 350px;
  margin: 0 auto;
`;

export const Divider = styled.div`
  width: 21.9rem;
  height: 0px;
  margin: 5rem auto;
  border: 0.5px solid #afb6c2;
`;
