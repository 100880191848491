import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const LegalLinks = styled.a`
  ${fonts.quickSandMedium};
  font-size: 1.4rem;
  text-decoration: underline;
  color: #1c2633;

  :hover {
    cursor: pointer;
    color: ${colors.primary};
  }
`;
