import React from 'react';
import PropTypes from 'prop-types';

// FIXME: Update after moving shared components
import Header from '../../../shared/components/Modal/Header';
import TextField from '../../../shared/components/TextField';
import Button from '../../../shared/components/Button';

import { CustomModal, CustomTitle, FormContainer, Subtitle } from './styles';

const SatwsCredentialsModal = ({ open, onClose }) => {
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      showCrossSign
      showCloseOnModal
      disableBackDropClick={false}
    >
      <Header>
        <CustomTitle size="large" text="Activa tu buzón de facturas" />
        <Subtitle>
          Ingresa tus credenciales para poder acceder a tu buzón tributario del
          Servicio de Administración Tributaria (SAT)
        </Subtitle>
      </Header>
      <FormContainer>
        <TextField label="RFC" type="rfc" />
        <TextField label="Contraseña" type="password" />
        <Button text="Continuar" size="lg" disabled />
      </FormContainer>
    </CustomModal>
  );
};

SatwsCredentialsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

SatwsCredentialsModal.defaultProps = {
  open: false,
  onClose: () => {}
};

export default SatwsCredentialsModal;
