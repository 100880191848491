import React from 'react';

import OnboardingStepper from '../OnboardingStepper/OnboardingStepper';

import { RegistrationContainer } from '../../../shared/layouts/RegistrationPageLayout/styles';

import HeadLayout from '../../../shared/layouts/RegistrationPageLayout/HeadLayout';
import FooterLayout from '../../../shared/layouts/RegistrationPageLayout/FooterLayout';
import Modal from '../../../shared/components/Modal';
import ModalInformation from '../../../shared/components/ModalInformation';

const OnboardingLayout = ({
  children,
  isModalVisible = false,
  title,
  description,
  onPrimaryButtonClick,
  primaryButtonText,
  isPrimaryButtonDisabled,
  isPrimaryButtonHidden,
  secondaryButtonText,
  onSecondaryButtonClick,
  secondaryActionComponent,
  steps,
  activeStep
}) => {
  return (
    <RegistrationContainer>
      <HeadLayout showLogout />
      {isModalVisible ? (
        <Modal open disableBackDropClick={false}>
          {steps ? (
            <OnboardingStepper steps={steps} activeStep={activeStep} />
          ) : null}
          <ModalInformation
            paddingHeader={'3rem'}
            title={title}
            description={description}
            isPrimaryButtonDisabled={isPrimaryButtonDisabled}
            isPrimaryButtonHidden={isPrimaryButtonHidden}
            secondaryButtonText={secondaryButtonText}
            primaryButtonText={primaryButtonText}
            onPrimaryButtonClick={onPrimaryButtonClick}
            onSecondaryButtonClick={onSecondaryButtonClick}
          >
            {children}
          </ModalInformation>
          <div className="modalSecondaryAction">{secondaryActionComponent}</div>
        </Modal>
      ) : (
        <>{children}</>
      )}
      <FooterLayout />
    </RegistrationContainer>
  );
};

export default OnboardingLayout;
