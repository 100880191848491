import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const LinkBaseStyles = css`
  ${fonts.quickSandSemiBold}
  line-height: 60px;
  text-align: center;
  color: ${colors.carbon};
`;

export const StyledLink = styled(Link)`
  ${LinkBaseStyles}
  font-size: 1.7rem;
`;

export const LegalLinks = styled.a`
  ${fonts.quickSandMedium};
  font-size: 1.4rem;
  text-decoration: underline;
  color: #1c2633;

  :hover {
    cursor: pointer;
    color: ${colors.primary};
  }
`;
