const getMissingDocumentsHandler = ({ getMissingDocuments }) => async type => {
  const { data, error } = await getMissingDocuments(type);

  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true, data };
};

export { getMissingDocumentsHandler };
