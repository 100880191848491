import React, { useState } from 'react';

import WaitingRedLines from '../../../../components/WaitingRedLines/WaitingRedLines';

import ExcelRenderer from '../../../../components/ExcelRenderer';

import Icon from '../../../shared/components/Icon/Icon';
import { ReactComponent as Success } from '../../../../Dashboard4.0/assets/images/transfer-check.svg';
import { ReactComponent as Error } from '../../../../Dashboard4.0/assets/images/transfer-remove.svg';
import { ReactComponent as Warning } from '../../../../Dashboard4.0/assets/images/transfer-warning.svg';
import { ReactComponent as Expand } from '../../../../Dashboard4.0/assets/images/expand.svg';

import {
  AccordionContainer,
  AccordionItem,
  AccordionHeader,
  AccordionTitle,
  AccordionBody,
  IconExpand,
  TooltipContainer
} from '../BatchTransfer/BatchTransfer.styles';

const Accordion = ({ beneficiaries, rows, ready }) => {
  const initialValues = {
    accordionSuccess: false,
    accordionDuplicates: false,
    accordionErrors: false
  };
  const [accordionItem, setAccordionItem] = useState(initialValues);

  const expandTable = index => {
    setAccordionItem({ [index]: !accordionItem[index] });
  };

  return (
    <AccordionContainer>
      {beneficiaries.success.length > 1 && (
        <AccordionItem>
          <AccordionHeader
            type="success"
            onClick={() => expandTable('accordionSuccess')}
          >
            <Success />
            <AccordionTitle>
              <span>
                {beneficiaries.success.length - 1} de {rows.length - 1}{' '}
                beneficiarios{' '}
              </span>
              fueron detectados del archivo y serán agregados
            </AccordionTitle>
            <IconExpand active={accordionItem.accordionSuccess}>
              <Expand />
            </IconExpand>
          </AccordionHeader>
          {accordionItem.accordionSuccess && (
            <AccordionBody data-test="FileLoader">
              {ready ? (
                <ExcelRenderer rows={beneficiaries.success} />
              ) : (
                <WaitingRedLines />
              )}
            </AccordionBody>
          )}
        </AccordionItem>
      )}
      {beneficiaries.duplicates.length > 1 && (
        <AccordionItem>
          <AccordionHeader
            type="warning"
            onClick={() => expandTable('accordionDuplicates')}
          >
            <Warning className="warning" />
            <AccordionTitle>
              <span>
                {beneficiaries.duplicates.length - 1} de {rows.length - 1}{' '}
                beneficiarios
              </span>{' '}
              no serán agregados porque la CLABE está repetida en el mismo
              archivo.
            </AccordionTitle>
            <IconExpand active={accordionItem.accordionDuplicates}>
              <Expand />
            </IconExpand>
          </AccordionHeader>
          {accordionItem.accordionDuplicates && (
            <AccordionBody data-test="FileLoader">
              {ready ? (
                <ExcelRenderer rows={beneficiaries.duplicates} />
              ) : (
                <WaitingRedLines />
              )}
            </AccordionBody>
          )}
        </AccordionItem>
      )}
      {beneficiaries.errors.length > 1 && (
        <AccordionItem>
          <AccordionHeader
            type="error"
            onClick={() => expandTable('accordionErrors')}
          >
            <Error />
            <AccordionTitle>
              <span>
                {beneficiaries.errors.length - 1} de {rows.length - 1}{' '}
                beneficiarios
              </span>{' '}
              no serán agregados por errores en el archivo.
            </AccordionTitle>
            <IconExpand active={accordionItem.accordionErrors}>
              <Expand />
            </IconExpand>
          </AccordionHeader>
          {accordionItem.accordionErrors && (
            <AccordionBody data-test="FileLoader">
              {ready ? (
                <ExcelRenderer rows={beneficiaries.errors} />
              ) : (
                <WaitingRedLines />
              )}
            </AccordionBody>
          )}
          <TooltipContainer
            onClick={() =>
              window
                .open(process.env.REACT_APP_MULTITRANSFERS_HELP_LINK, '_blank')
                .focus()
            }
          >
            <Icon icon="question" />
          </TooltipContainer>
        </AccordionItem>
      )}
    </AccordionContainer>
  );
};

export default Accordion;
