import { disable2FA_Handler } from './event-handlers/disable2FA';
import { enable2FA_Handler } from './event-handlers/enable2FA';
import { getAccountStatementHandler } from './event-handlers/getAccountStatement';
import { getDatesHandler } from './event-handlers/getDates';

import { disable2FA_service } from './infrastructure/disable2FA-service';
import { enable2FA_service } from './infrastructure/enable2FA-service';
import { getAccountStatementService } from './infrastructure/getAccountStatement-service';
import { getBillingCyclesService } from './infrastructure/getBillingCycles-service';

const service = ({ dispatch } = {}) => ({
  getAccountStatement: getAccountStatementHandler({
    getAccountStatementService: getAccountStatementService
  }),
  getDates: getDatesHandler({
    dispatch,
    getBillingCycles: getBillingCyclesService
  }),
  enable2FA: enable2FA_Handler({
    dispatch,
    enable2FA_service: enable2FA_service
  }),
  disable2FA: disable2FA_Handler({
    dispatch,
    disable2FA_service: disable2FA_service
  })
});

export { service };
