import React from 'react';

import Layout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

const StartLater = () => {
  return (
    <Layout
      showLogout={true}
      title="Como gustes, nos vemos al rato"
      description="Puedes volver a terminar tu registro iniciando sesión a tu cuenta."
      isModalVisible={true}
      isPrimaryButtonHidden={true}
    ></Layout>
  );
};

export default StartLater;
