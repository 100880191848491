/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '../../../shared/components/Button';
import ProgressBar from '../../../shared/components/ProgressBar';
import WaitingRedLines from '../../../../components/WaitingRedLines/WaitingRedLines';
import { alert } from '../../../../helpers/alertsManager';

import { ButtonContainer } from './BatchTransfer.styles';
import { SweetButton } from '../../../../Dashboard4.0/components/Transaction/TransactionForm.styles';

import { sendCsvFile } from '../../../../Services/spei';
import { makeCsvFile } from '../../../../helpers';
import { useMixPanel } from '../../../shared/mixpanel';

const successText = 'Orden enviada';
const successBeneficiaries = 'Nuevos beneficiarios añadidos';
const confirmText = 'Confirmar y continuar';
const cancelText = 'Detener transferencia';

const HandleButtons = ({
  file,
  onClose,
  resetFile,
  disabled,
  type,
  done,
  submit,
  changePage,
  beneficiaries,
  longHeader
}) => {
  const [confirm, setConfirm] = useState(false);
  const [mainText, setMainText] = useState(confirmText);
  const [secondaryText, setSecondaryTest] = useState(
    type ? 'Subir un nuevo archivo' : 'Editar transferencias'
  );
  const [progress, setProgress] = useState(0);
  const [sendMixPanelEvent] = useMixPanel();

  const sendFile = () => {
    setMainText('');
    const csvFile = makeCsvFile(file.parsedFile ? file.parsedFile : file);

    sendCsvFile('file.csv', csvFile, type, file.transferName).then(resp => {
      if (resp?.status && resp?.status === 200) {
        setMainText(type ? successBeneficiaries : successText);
        setConfirm(false);
        sendMixPanelEvent(
          type ? 'Multiple beneficiaries order' : 'Multiple transfer order'
        );
        if (done) done(true);
        if (type) {
          changePage('batchTransfer');
          alert(
            'success',
            beneficiaries > 1
              ? `${beneficiaries} beneficiarios agregados`
              : `${beneficiaries} beneficiario agregado`,
            5000,
            {
              marginLeft: '-29rem',
              width: '60.5rem'
            }
          );
        }
        setSecondaryTest(
          type ? 'Subir un nuevo archivo' : 'Enviar más transferencias'
        );
        return;
      }

      setMainText(confirmText);
      setConfirm(false);
      setProgress(0);
    });
  };

  const calculateProgress = () => {
    setTimeout(() => {
      let value = progress;
      setProgress(value + 1);
    }, 30);
  };

  const handleConfirm = value => {
    setConfirm(true);
    if (done) done(true);
  };

  useEffect(() => {
    if (progress < 101 && confirm) {
      calculateProgress();
    }
    //eslint-disable-next-line
  }, [progress, confirm]);

  useEffect(() => {
    if (progress === 101) {
      sendFile();
      //setConfirm(false);
    }
    //eslint-disable-next-line
  }, [progress]);

  return (
    <ButtonContainer
      top={type || longHeader ? '3rem' : '26.5rem'}
      data-test="HandleButtons"
    >
      {!confirm && mainText ? (
        <Button
          data-test="confirmButton"
          success={
            mainText === successText || mainText === successBeneficiaries
          }
          variant={
            mainText === successText || mainText === successBeneficiaries
              ? 'success'
              : ''
          }
          text={mainText}
          onClick={
            mainText === confirmText ? e => handleConfirm(true) : e => onClose()
          }
          disabled={disabled}
        />
      ) : null}

      {confirm && progress <= 100 ? (
        <>
          <ProgressBar
            data-test="progressBar"
            progress={progress}
            textContent={
              type ? 'Procesando archivo' : 'Procesando transferencias'
            }
            className="progressBar"
          />
          {!type ? (
            <SweetButton
              data-test="sweetButton"
              onClick={() => {
                setProgress(0);
                setConfirm(false);
                if (done) done(false);
              }}
            >
              {cancelText}
            </SweetButton>
          ) : null}
        </>
      ) : (
        <Button
          text={secondaryText}
          variant="outlinedBlack"
          hasEmptyFile={disabled}
          onClick={() => {
            setProgress(0);
            resetFile();
            if (done) done(false);
          }}
        />
      )}

      {!mainText ? <WaitingRedLines /> : null}
    </ButtonContainer>
  );
};

HandleButtons.propTypes = {
  file: PropTypes.any,
  onClose: PropTypes.func,
  resetFile: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  done: PropTypes.func,
  submit: PropTypes.func,
  changePage: PropTypes.func,
  longHeader: PropTypes.bool,
  beneficiaries: PropTypes.string
};

export default HandleButtons;
