/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';

// Auxiliary libraries
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Shared Components
import EntryEmptyState from '../../../shared/components/EntryEmptyState/EntryEmptyState';
import EmptyStateLabel from '../../../shared/components/EmptyStateLabel/EmptyStateLabel';
import LabelInfo from '../../../shared/components/LabelInfo';

// This module components
import TableRowCard from '../table-row-card';

// Styled
import { Container } from './styles';

// Others
import card from '../../../../Dashboard4.0/assets/images/card-b-2.png';

import { ACTIVE } from '../../constants';

export const NoCardsPlaceholder = ({ newCardLink, noAdmin }) => {
  const history = useHistory();
  const handleCreateNewCard = useCallback(() => history.push(newCardLink), [
    history,
    newCardLink
  ]);

  const placeholderCards = [
    {
      creditCardId: 1,
      creditCardName: 'Facebook Ads',
      isVirtual: true,
      lastFourDigits: 4567,
      spendLimit: '100,000',
      ownerName: 'Fernanda González',
      requestStatusDescription: ACTIVE,
      isActive: true
    },
    {
      creditCardId: 2,
      creditCardName: 'Ventas',
      isVirtual: false,
      lastFourDigits: 4455,
      spendLimit: '100,000',
      ownerName: 'Miguel Hernández',
      requestStatusDescription: ACTIVE,
      isActive: false
    }
  ];

  return (
    <Container data-test="empty-state-container">
      <EntryEmptyState
        data-test="EntryEmptyState"
        title={
          noAdmin
            ? 'Próximamente verás tus tarjetas aquí'
            : 'Todavía no tienes tarjetas'
        }
        img={card}
        description={
          noAdmin ? (
            'Por el momento solo el usuario del representante legal puede verlas'
          ) : (
            <span>
              <LabelInfo
                onClick={handleCreateNewCard}
                className="cursor"
                text="Haz clic aquí "
              />
              para agregar una tarjeta de crédito.
            </span>
          )
        }
      />

      <EmptyStateLabel
        data-test="EmptyStateLabel"
        label={
          noAdmin
            ? 'Cuando puedas visualizar tus tarjetas, podrás encontrarlas aquí.'
            : 'Una vez que tengas tarjetas agregadas, podrás encontrarlas aquí.'
        }
      />

      {placeholderCards.map(card => (
        <TableRowCard
          key={card.creditCardId}
          card={card}
          columns={'3fr 2fr 2fr 1fr 1fr 1fr 0.5fr'}
          isPlaceholder
        />
      ))}
    </Container>
  );
};

NoCardsPlaceholder.propTypes = {
  newCardLink: PropTypes.string
};

export default NoCardsPlaceholder;
