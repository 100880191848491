/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import BatchContent from './BatchContent';
import Button from '../../../shared/components/Button';
import {
  ButtonContainer,
  DetailsContainer,
  FileDetailsContainer,
  SubtitleNameStep
} from './BatchTransfer.styles';
import TextField from '../../../shared/components/TextField';

const MultiTransferName = ({ dataFile, submit, onChange }) => {
  const [name, setName] = useState('');

  const handleChange = e => {
    onChange(e);
    setName(e.target.value);
  };

  return (
    <FileDetailsContainer>
      <BatchContent
        data-test="MultiTransferName"
        title="Dar nombre a tu multiTransfer"
        className="titleBlack"
        subtitle={
          <>
            <SubtitleNameStep>
              {dataFile ? (
                <>
                  Envía <span>{dataFile.amount}</span> en total a
                  <span> {dataFile.beneficiaries}</span>{' '}
                  {dataFile.beneficiaries > 1
                    ? 'beneficiarios'
                    : 'beneficiario'}
                </>
              ) : null}
            </SubtitleNameStep>
          </>
        }
        content={
          <>
            <DetailsContainer>
              <TextField
                data-test="nameField"
                className="formField"
                label="Nombre de la orden (¿para qué es esta orden?)"
                name="name"
                onChange={handleChange}
                value={name}
              />
              <ButtonContainer top="33.7rem">
                <Button
                  data-test="Button"
                  text="Continuar"
                  onClick={() => submit(4)}
                  disabled={name === ''}
                />
              </ButtonContainer>
            </DetailsContainer>
          </>
        }
      />
    </FileDetailsContainer>
  );
};

MultiTransferName.propTypes = {
  dataFile: PropTypes.object,
  submit: PropTypes.func,
  onChange: PropTypes.func
};

export default MultiTransferName;
