import {
  enterpriseAddressPath,
  enterpriseAttorneyPath,
  enterpriseBankPath,
  enterpriseInfoPath,
  enterpriseDocuments,
  enterpriseIdentity,
  freelancerAddressPath,
  freelancerBankPath,
  freelancerDocuments,
  freelancerInfoPath,
  freelancerIdentity
} from '../../micro-frontends/onboarding/constants';

export const freelancerOnboarding = [
  freelancerAddressPath,
  freelancerBankPath,
  freelancerInfoPath,
  freelancerIdentity,
  freelancerDocuments
];

export const companyOnboarding = [
  enterpriseAddressPath,
  enterpriseBankPath,
  enterpriseInfoPath,
  enterpriseAttorneyPath,
  enterpriseIdentity,
  enterpriseDocuments
];
