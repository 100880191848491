/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Delete } from '../../../../Dashboard4.0/assets/images/Delete.svg';

import { OverlayContainer } from './Overlay.styles';

const Overlay = ({
  children,
  open,
  onClose,
  disableBackDropClick = true,
  ...otherProps
}) => {
  return (
    <>
      {open && (
        <OverlayContainer
          {...otherProps}
          data-test="overlayComponent"
          onClick={e => {
            const data_test = e.target.getAttribute('data-test');
            if (data_test === 'overlayComponent' && !disableBackDropClick) {
              return onClose();
            }
          }}
        >
          {otherProps.showCrossSign ? (
            <span data-test="crossSign" className="crossSign" onClick={onClose}>
              <Delete />
            </span>
          ) : null}
          <div data-test="overlayArea" className="overlayArea">
            {children}
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

Overlay.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  showCrossSign: PropTypes.bool
};

export default Overlay;
