/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

import PropTypes from 'prop-types';

import { Container, Grid, HeadItem, Item } from './ExcelRenderer.styles';

const ExcelRenderer = ({ rows, sm = false }) => {
  return (
    <Container sm={sm}>
      <Grid className="gridContainer" rules="none">
        <thead>
          {rows.slice(0, 1).map(row => (
            <tr key="header-row">
              {row.map((el, i) => (
                <HeadItem key={`header-cell-${i}`}>{el}</HeadItem>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.slice(1).map((row, j) => (
            <tr key={`body-row-${j}`}>
              {row.map((el, i) => (
                <Item key={`body-row-${j}-cell-${i}`}>{el}</Item>
              ))}
            </tr>
          ))}
        </tbody>
      </Grid>
    </Container>
  );
};

ExcelRenderer.propTypes = {
  rows: PropTypes.array,
  sm: PropTypes.bool
};

export default ExcelRenderer;
