import React from 'react';

import PropTypes from 'prop-types';

import { Concept } from '../../../credit/components/EntryBalance/EntryBalance.styles';
import { PeriodContainer } from '../Layout/Layout.styles';
import Label from '../Label/Label';

const Header = ({ firstValue, secondValue, label, border, className }) => (
  <div data-test="Header" className={className}>
    <PeriodContainer isBorder={border}>
      <Concept text={secondValue} />
      <Label text={label} />
    </PeriodContainer>
    <Concept text={firstValue} />
  </div>
);

Header.propTypes = {
  firstValue: PropTypes.string.isRequired,
  secondValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default Header;
