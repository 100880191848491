import { getFormProcessHandler } from './eventHandlers/form-process-handler';
import { getComponentHandler } from './eventHandlers/get-component-handler';
import { getSelectorHandler } from './eventHandlers/selector-handler';
import enterpriseActionsManager from './enterprise-actions-manager';

const service = state => ({
  getFormProcess: getFormProcessHandler({
    getFormProcess: enterpriseActionsManager[state].service
  }),
  getPath: enterpriseActionsManager[state].path,
  getSelector: getSelectorHandler({
    getSelector: enterpriseActionsManager[state].selector
  }),
  getComponent: getComponentHandler({
    getComponent: enterpriseActionsManager[state].component
  })
});

export { service };
