import { addTagOptionAction } from '../redux/tag-action';
import { TagCreator } from '../domain/tagData';

const createTransactionTagHandler = ({
  dispatch,
  createTransactionTag
}) => async ({ tagLabel }) => {
  const { error, data } = await createTransactionTag({ tagLabel });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  const tag = TagCreator(data);

  dispatch(addTagOptionAction({ tag }));

  return { isSuccess: true, data: tag };
};

export { createTransactionTagHandler };
