import successfulMessages from './successfulMessages.json';

const validateSuccessfullyDeleted = message => {
  return message.includes('successfully deleted')
    ? successfulMessages['successfully deleted']
    : '';
};

export const getMessageResponse = res => {
  let message = '';

  if (res.data && res.data.message) {
    message =
      validateSuccessfullyDeleted(res.data.message) ||
      successfulMessages[res.data.message];
  }

  return message;
};
