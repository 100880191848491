export const updateCardHandler = ({ updateCard }) => async ({
  updatedCard: {
    creditCardId,
    spendLimit,
    isActive,
    isBlocked,
    creditCardName,
    userId
  },
  password,
  email
}) => {
  const { error } = await updateCard({
    creditCardId,
    spendLimit,
    isActive,
    isBlocked,
    creditCardName,
    userId,
    password,
    email
  });

  if (error) {
    return { isSuccess: false, error };
  }
  // const card = CardCreator({ data: cardData });

  // dispatch(doCardNaiveUpdate({ card }));
  return { isSuccess: true };
};
