/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import BankItem from './BankItem';

import {
  CustomDropdown,
  CustomOptions,
  CustomIcon,
  Label
} from './BankMenu.styles';

const BankMenu = ({ banks, height, selectedBank, accountType, onChange }) => {
  const handleClick = bank => {
    if (onChange) onChange({ target: { name: 'bank', value: bank } });
  };

  return (
    <CustomDropdown>
      {accountType === 'clabe_code' ? (
        <BankItem bank={selectedBank} />
      ) : (
        <>
          {selectedBank ? (
            <BankItem bank={selectedBank} />
          ) : (
            <Label>
              {' '}
              Banco <CustomIcon icon="next" />{' '}
            </Label>
          )}
          <CustomOptions height={height}>
            {banks &&
              banks.map(bank => {
                return (
                  <BankItem
                    data-test="bankMenuItem"
                    onClick={() => handleClick(bank)}
                    key={bank.id}
                    bank={bank}
                    onChange={onChange}
                  />
                );
              })}
          </CustomOptions>
        </>
      )}
    </CustomDropdown>
  );
};

BankMenu.propTypes = {
  banks: PropTypes.array,
  height: PropTypes.string,
  selectedBank: PropTypes.object,
  accountType: PropTypes.string,
  onChange: PropTypes.func
};

export default BankMenu;
