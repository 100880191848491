import { createMachine } from '../../../cards/helpers/finite-state-machine';

export const states = {
  NO_VALUE: 'NO_VALUE',
  SEARCHING: 'SEARCHING',
  HAS_VALUE: 'HAS_VALUE'
};

export const events = {
  FOCUS: 'FOCUS',
  CHOOSE_VALUE: 'CHOOSE_VALUE',
  BLUR: 'BLUR',
  SET_INITIAL_VALUE: 'SET_INITIAL_VALUE'
};

export const machine = createMachine({
  initialState: states.NO_VALUE,
  [states.NO_VALUE]: {
    transitions: {
      [events.FOCUS]: {
        target: states.SEARCHING
      },
      [events.SET_INITIAL_VALUE]: {
        target: states.HAS_VALUE
      }
    }
  },
  [states.SEARCHING]: {
    transitions: {
      [events.CHOOSE_VALUE]: {
        target: states.HAS_VALUE
      },
      [events.BLUR]: {
        target: states.NO_VALUE
      }
    }
  },
  [states.HAS_VALUE]: {
    transitions: {
      [events.FOCUS]: {
        target: states.SEARCHING
      },
      [events.BLUR]: {
        target: states.HAS_VALUE
      }
    }
  }
});
