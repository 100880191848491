import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const classes = css`
  .inactive {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    svg path {
      fill: ${colors.carbon3};
    }
  }
  .svg > svg:hover path {
    fill: ${colors.primary};
  }
  .svg > svg path {
    fill: ${colors.carbon};
  }
`;

export const PaginatorContainer = styled.div`
  width: 100%;
  height: 11.6rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-self: center;
  ${fonts.barlowMedium};
  font-size: 1.4rem;
  color: ${colors.carbon};
  letter-spacing: 0.18px;
  line-height: 0.86;
  justify-content: center;
  align-content: center;
  ${classes}

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: center;
  }
`;

export const PagesContainer = styled.div`
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const PageItemContainer = styled.label`
  width: 2.1rem;
  text-align: center;
  padding: 0.7rem 0.7rem 0.7rem 0.8rem;
  line-height: 1rem;
  color: ${props => (props.isActive ? colors.primary : '')};

  :hover {
    cursor: pointer;
    color: ${colors.primary};
    text-decoration: underline;
  }
`;
