import {
  SET_AVAILABLE_CARDS,
  SET_CARDS,
  SET_CARDS_ERROR,
  SET_CARDS_TYPE_FILTER,
  SET_USERS,
  SET_USERS_ERROR
} from './cards-action-types';

export const setCardsAction = ({ cards, success }) => ({
  type: SET_CARDS,
  payload: { cards, success }
});

export const setCardsErrorAction = ({ error }) => ({
  type: SET_CARDS_ERROR,
  payload: { error }
});

export const setUsersAction = ({ users }) => ({
  type: SET_USERS,
  payload: { users }
});

export const setUsersErrorAction = ({ error }) => ({
  type: SET_USERS_ERROR,
  payload: { error }
});

export const setFilterByType = fliter => ({
  type: SET_CARDS_TYPE_FILTER,
  payload: fliter
});

export const setAvailableCards = ({ availableCards }) => ({
  type: SET_AVAILABLE_CARDS,
  payload: {
    physicalCards: availableCards.physicalCreditCards,
    virtualCards: availableCards.virtualCreditCards
  }
});
