/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { ContractContainer, LinkContainer } from './CreditContract.styles';
import Icon from '../../../shared/components/Icon/Icon';
import Label from '../../../shared/components/Label';
import LabelInfo from '../../../shared/components/LabelInfo';

const CreditContract = ({ className, onClick, contractId }) => {
  return (
    <ContractContainer data-test="creditContract" className={className}>
      <Label text="CONTRATO DEL CRÉDITO" />

      {contractId && (
        <LinkContainer data-test="fileDownload" onClick={onClick}>
          <Icon
            width="1rem"
            height="1rem"
            icon="attachment"
            className="attachment"
          />
          <LabelInfo text={contractId} />
        </LinkContainer>
      )}
    </ContractContainer>
  );
};

CreditContract.propTypes = {
  className: PropTypes.string,
  contractId: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default CreditContract;
