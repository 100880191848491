import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const InfoBarCredit = styled.div`
  div {
    border: none;
    box-shadow: none;
  }
  .infoBarBtnContainer {
    background: ${colors.white};
  }
  button {
    width: 80%;
    margin: auto;
    font-size: 1.4rem !important;
    @media (min-width: 780px) {
      width: auto;
    }
  }
  button:nth-child(2),
  button:nth-child(4) {
    background: ${colors.white};
    color: ${colors.body};
    border: 1px solid ${colors.body};
    margin-left: 1rem;
    @media (max-width: 780px) {
      margin-left: 0;
    }
  }
  .paymentDeadline {
    ${fonts.quickSandSemiBold}
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 1px;
  }
  .smallContainter {
    display: flex;
    flex-direction: row;
    .creditDetailsSmall {
      margin: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 780px) {
    display: none;
  }
`;
