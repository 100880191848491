import { freelancerId } from '../../micro-frontends/onboarding/constants';

export const documents = {
  1: {
    kyc_document_type_id: 1,
    name: 'Foto de frente de tu INE',
    tooltip: true,
    type: freelancerId,
    takePhoto: true,
    keyword: 'ine_anverso'
  },
  9: {
    kyc_document_type_id: 9,
    name: 'Foto de frente de tu INE',
    tooltip: true,
    type: freelancerId,
    takePhoto: true,
    keyword: 'ine_anverso'
  },
  11: {
    kyc_document_type_id: 11,
    name: 'INE reverso',
    tooltip: true,
    type: freelancerId,
    takePhoto: true,
    keyword: 'ine_reverso'
  },
  12: {
    kyc_document_type_id: 12,
    name: 'INE reverso',
    tooltip: true,
    type: freelancerId,
    takePhoto: true,
    keyword: 'ine_reverso'
  }
};
