import { createTransactionTagHandler } from './event-handlers/create-transaction-tag';
import { createCardTagHandler } from './event-handlers/create-card-tag';
import { createCardTransactionTagHandler } from './event-handlers/create-card-transaction-tag';
import { createSpeiTagHandler } from './event-handlers/create-spei-tag';
import { getCardTagsHandler } from './event-handlers/get-card-tags';
import { getTagOptionsHandler } from './event-handlers/get-tag-options';
import { getSpeiTagsHandler } from './event-handlers/get-tags-spei';
import { getCardTransactionTagsHandler } from './event-handlers/get-card-transaction-tags';
import { deleteCardTagHandler } from './event-handlers/delete-card-tag';
import { deleteCardTransactionTagHandler } from './event-handlers/delete-card-transaction-tag';
import { deleteTransactionTagHandler } from './event-handlers/delete-transaction-tag';

import { createTransactionTagService } from './infrastructure/create-transaction-tag';
import { createCardTagService } from './infrastructure/create-card-tag';
import { createCardTransactionTagService } from './infrastructure/create-card-transaction-tag';
import { createSpeiTagService } from './infrastructure/create-spei-tag';
import { getCardTagsService } from './infrastructure/get-card-tags';
import { getTagOptionsService } from './infrastructure/get-tag-options';
import { getTagsSpeiService } from './infrastructure/get-tags-spei';
import { getCardTransactionTagsService } from './infrastructure/get-card-transaction-tags';
import { deleteCardTagService } from './infrastructure/delete-card-tag';
import { deleteCardTransactionTagService } from './infrastructure/delete-card-transaction-tag';
import { deleteTransactionTagService } from './infrastructure/delete-transaction-tag';

const service = ({ dispatch } = {}) => ({
  createTransactionTag: createTransactionTagHandler({
    dispatch,
    createTransactionTag: createTransactionTagService
  }),
  createCardTag: createCardTagHandler({
    dispatch,
    createCardTag: createCardTagService
  }),
  createCardTransactionCard: createCardTransactionTagHandler({
    dispatch,
    createCardTransactionTag: createCardTransactionTagService
  }),
  createSpeiTag: createSpeiTagHandler({
    dispatch,
    createSpeiTag: createSpeiTagService
  }),
  deleteCardTag: deleteCardTagHandler({
    dispatch,
    deleteCardTag: deleteCardTagService
  }),
  deleteCardTransactionTag: deleteCardTransactionTagHandler({
    dispatch,
    deleteCardTransactionTag: deleteCardTransactionTagService
  }),
  deleteTransactionTag: deleteTransactionTagHandler({
    dispatch,
    deleteTransactionTag: deleteTransactionTagService
  }),
  getTagOptions: getTagOptionsHandler({
    dispatch,
    getTagOptions: getTagOptionsService
  }),
  getCardTags: getCardTagsHandler({
    dispatch,
    getCardTags: getCardTagsService
  }),
  getCardTransactionTags: getCardTransactionTagsHandler({
    dispatch,
    getCardTransactionTags: getCardTransactionTagsService
  }),
  getSpeiTags: getSpeiTagsHandler({
    dispatch,
    getSpeiTags: getTagsSpeiService
  })
});

export { service };
