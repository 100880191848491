/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';

import ErrorBoundaryHandler from '../../../../ErrorBoundary';
import EntryComponent from '../EntryComponent';

export const Component = ({ orders, getValues, setActions, type }) => {
  return (
    <>
      {orders.length > 0
        ? orders.map((el, ix) => {
            const details = getValues(el, ix);
            const id = uuidv4();
            return (
              <EntryComponent
                key={id}
                details={{
                  ...details,
                  batch_process_name: el.batch_process_name
                }}
                firstAction={setActions(el, 'firstAction')}
                secondAction={setActions(el, 'secondAction')}
                thirdAction={setActions(el, 'thirdAction')}
                multiTransferAction={setActions(el, 'multiTransferAction')}
                reversalsAction={setActions(el, 'reversalsAction')}
                isRejected={el.status === 'rejected'}
                typeEntry={type}
              />
            );
          })
        : null}
    </>
  );
};

Component.propTypes = {
  orders: PropTypes.array.isRequired,
  getValues: PropTypes.func.isRequired,
  setActions: PropTypes.func.isRequired,
  type: PropTypes.string
};

const Movements = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="Movements"
    />
  );
};

export default memo(Movements);
