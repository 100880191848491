'use client';
import Header from '../components/header';
import Panel from '../components/panel';
import HSpacing from '../components/spacing/horizontal-spacing';
import Heading from '../components/heading';
import Center from '../components/center';
import Button from '../components/button';
import BodyText from '../components/body-text';
import Footer from '../components/footer';


import { CenterText} from '../utils/styles';


import React from 'react';

function View({history}) {
  
  const handleGoAhead = () => {
    history.push("/");
  } 
  
  return (
    <div>
      <Header />
      <HSpacing times={3} />
      <Panel>
        <Center>
          <BodyText style={{ fontSize: '40px' }}>🎉</BodyText>
        </Center>
        <HSpacing times={4} />
        <Center>
          <div
            style={{
              width: '416px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <Heading>Información actualizado con éxito</Heading>
          </div>
        </Center>
        <HSpacing times={4} />

        <Center>
          <CenterText>
            ¡Te agradecemos por actualizar tu información! 
          </CenterText>
        </Center>
        <HSpacing times={4} />
        <Center>
          <CenterText>
            Esta acción nos permite continuar mejorando tu experiencia día tras día. Gracias por tu interés y confianza en nuestros servicios.
          </CenterText>
        </Center>
        
        <HSpacing times={8} />
        <Center>
          <Button
            style={{ fontWeight: 600 }}
            width={432}
            label="Continuar"
            onClick={handleGoAhead}
          />
        </Center>
      </Panel>
      <Footer/>
    </div>
  );
}

export default function Page({history}) {
  return (
    <>
      <View history={history}/>
    </>
  );
}
