import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

// Components
import Balance from '../components/Balance';
import CreditDetails from '../components/CreditDetails';
import CreditContract from '../components/CreditContract/CreditContract';
import Header from '../../shared/components/Header/Header';
import Layout from '../../shared/components/Layout/Layout';
import SideBar from '../components/SideBar';
import CreditPayModal from '../components/CreditPayModal';
import { PageContainer } from './Credit.styles';

import { downloadFile, fileFormat } from '../../../helpers/fileManager';
import { getSignedContract } from '../../../Services/fea';

import { GridContainer } from '../../shared/components/Layout/Layout.styles';

const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const splitDateString = dateString => {
  const spplitedDate = dateString.toLowerCase().split(' de ');
  return {
    day: spplitedDate[0],
    month: capitalize(spplitedDate[1]),
    year: spplitedDate[2]
  };
};

const CreditPage = () => {
  // Hooks
  const [closingDate, setClosingDate] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [openingDate, setOpeningDate] = useState('');

  // Redux
  const companySummary = useSelector(
    state => state.companyReducer.companySummary
  );
  const daysLeft = companySummary.days_to_cut_off;
  const statementClosingDate = companySummary.statement_closing_date;
  const paymentDeadLine = companySummary.payment_deadline;
  const openingBalanceDate = companySummary.opening_balance_date;

  const year = moment().format('YYYY');
  const [isOpenPayModal, setOpenPayModal] = useState(false);

  const getSplitDate = (date, setDate) => {
    if (!date) return;

    setDate(splitDateString(date));
  };

  useEffect(() => {
    getSplitDate(paymentDeadLine, setPaymentDate);
    getSplitDate(statementClosingDate, setClosingDate);
    getSplitDate(openingBalanceDate, setOpeningDate);
    // eslint-disable-next-line
  }, [paymentDeadLine, statementClosingDate, openingBalanceDate]);

  const handleClick = () => {
    getSignedContract()
      .then(res => {
        downloadFile(
          fileFormat.pdf,
          'base64',
          res.data[0].signed_contract,
          'contrato',
          'pdf'
        );
      })
      .catch(err => console.error(err));
  };

  return (
    <PageContainer>
      <Layout
        title="Crédito"
        midleComponent={
          <CreditDetails openModal={() => setOpenPayModal(true)} />
        }
      >
        <Header
          firstValue="Detalles"
          secondValue="Resumen de periodo"
          border
          className="creditHeader"
        />
        <GridContainer className="creditPageGrid">
          <div className="creditSideContent">
            {closingDate && paymentDate && (
              <SideBar
                statementClosingDate={closingDate}
                paymentDeadLine={paymentDate}
              />
            )}
            <CreditContract contractId="Contrato" onClick={handleClick} />
          </div>
          {closingDate && openingDate ? (
            <Balance
              companySummary={companySummary}
              daysLeft={daysLeft}
              statementClosingDate={closingDate}
              openingBalanceDate={openingDate}
            />
          ) : null}
        </GridContainer>
      </Layout>
      <CreditPayModal
        open={isOpenPayModal}
        onClose={() => setOpenPayModal(false)}
      />
    </PageContainer>
  );
};

export default CreditPage;
