/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { AvatarContainer } from './Avatar.styles';

//children can either be a string or an image (object)
//the colors prop is an object containing the background and font colors
const Avatar = ({ children, colors, size, ...otherProps }) => {
  return (
    <AvatarContainer size={size} colors={colors} {...otherProps}>
      {children}
    </AvatarContainer>
  );
};

Avatar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  colors: PropTypes.object,
  size: PropTypes.string
};

export default Avatar;
