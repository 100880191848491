/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '../TextField';

import { Container } from './SingleInput.styles';

// eslint-disable-next-line react/display-name
const SingleInput = forwardRef(
  ({ error, onChange, onKeyDown, autofocus, dataTestId }, ref) => {
    const [digit, setDigit] = useState('');
    const handleChange = e => {
      let value = e.target.value;
      setDigit(value ? validateValue(value) : '');

      if (onChange) onChange(e);
    };

    const validateValue = value => {
      const length = value.length;
      if (value.trim().length === 0) return '';

      if (length > 0 && length < 2) {
        return value;
      }

      if (length > 1) {
        return value[0];
      }
    };

    return (
      <Container data-test="SingleInputContainer">
        <TextField
          data-testid={dataTestId}
          ref={ref}
          className="singleInput"
          onChange={handleChange}
          value={digit}
          onKeyDown={onKeyDown}
          error={error}
          autofocus={autofocus}
        />
      </Container>
    );
  }
);

SingleInput.propTypes = {
  error: PropTypes.any,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func
};

export default SingleInput;
