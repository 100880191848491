import React from 'react';
import { useHistory } from 'react-router-dom';

import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';
import {
  digitalSignContractPath,
  signatureSchedulePath
} from '../../constants';

const ChooseSignMethod = () => {
  const history = useHistory();
  const goToDigitalSign = () => history.push(digitalSignContractPath);
  const goToScheduleSign = () => history.push(signatureSchedulePath);

  return (
    <RegistrationPageLayout
      isModalVisible={true}
      showLogout={true}
      title="Por favor firma tu solicitud"
      description="¿Cómo prefieres firmar?"
      primaryButtonText="Digital (recomendado)"
      onPrimaryButtonClick={goToDigitalSign}
      secondaryButtonText="En sucursal"
      onSecondaryButtonClick={goToScheduleSign}
    />
  );
};

export default ChooseSignMethod;
