import * as yup from 'yup';

import { patterns_metadata as patterns } from '../../../catalogs/patterns';

let fieldRequiredMessage = 'Campo requerido';
let minMaxCharactersMessage = 'Debe contener 13 caracteres';
const patternPercentage = /^((2[5-9]|[3-9][0-9]|100\d?)(\.\d{1,2})?|100(\.00?)?)$/;

export const validateOwnerPercentage = (owners, controllingStake) => {
  let percentage =
    owners
      .map(item => {
        return Number(item.controlling_stake);
      })
      .reduce((a, b) => {
        return a + b;
      }, 0) + controllingStake;

  if (percentage > 100) {
    return false;
  }

  return percentage.toFixed(2);
};

export const validateAttorneyInLaw = yup
  .object({
    curp: yup
      .string('CURP')
      .required(fieldRequiredMessage)
      .matches(patterns.patternCurp, 'Ingresa un CURP válido'),
    rfc: yup
      .string('RFC')
      .min(13, minMaxCharactersMessage)
      .max(13, minMaxCharactersMessage)
      .required(fieldRequiredMessage)
      .matches(patterns.patternRFC, 'Ingresa un RFC válido')
  })
  .shape();

export const validateSchemaOwner = yup
  .object({
    controlling_stake: yup
      .string('Porcentaje')
      .test(
        'controlling_stake validation',
        'porcentaje inválido',
        function (controlling_stake) {
          const controllingStake = Number(controlling_stake);

          if (!controllingStake) {
            return this.createError({
              message: 'Ingresa una cifra numérica etre 25 y 100'
            });
          }

          const owners = this.parent.owners;

          return validateOwnerPercentage(owners, controllingStake)
            ? true
            : this.createError({
                message:
                  'No puedes añadir más del 100% en el total de accionistas'
              });
        }
      )
      .matches(patternPercentage, 'Mínimo 25, máximo 100. Máximo 2 décimales')
      .required(fieldRequiredMessage)
  })
  .shape();

export const validateName = yup
  .object({
    name: yup.string('Nombre').required(fieldRequiredMessage),
    surname_1: yup.string('Apellido paterno').required(fieldRequiredMessage)
  })
  .shape();

export const validateDirectorMember = yup
  .object({
    charge: yup.string('Cargo').required(fieldRequiredMessage)
  })
  .shape();
