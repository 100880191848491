const users = [
  {
    userId: 1,
    name: 'Juanito Pérez',
    spendingLimit: '$ 3,000.00',
    userType: 'Admin',
    email: 'juanitoperez@gmail.com',
    departmentName: 'Operaciones'
  },
  {
    userId: 2,
    name: 'Rosa Villanoba Martínez',
    spendingLimit: '$ 1,500.00',
    userType: 'Basic User',
    email: 'rosav@ventas.com',
    departmentName: 'Ventas'
  }
];

export default users;
