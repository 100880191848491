import { makeStyles } from '@material-ui/core/styles';

export const idVerificationStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2.5rem 0'
  },
  center: {
    width: '32rem',
    margin: '0 auto'
  },
  checkbox: {
    [theme.breakpoints.only('xs')]: {
      marginRight: '1rem'
    }
  },
  checkboxText: {
    color: '#666566',
    fontSize: '1.6rem'
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem 0'
  },
  root: {
    margin: '0 auto'
  },
  secondaryText: {
    fontSize: '1.4rem',
    color: '#989898',
    marginBottom: '3rem'
  },
  steps: {
    fontSize: '2.4rem',
    color: '#323132',
    fontWeight: 600
  },
  stepsContainer: {
    marginTop: '2.4rem'
  }
}));
