import { resendEmailObject } from '../../object-creators/object-creators';
import {
  approveProfileService,
  approveUserService,
  approveUserUpdateRequestService,
  declineUserUpdateRequestService,
  createUserService,
  deleteUserService,
  updateUserService,
  resendEmailInvitation,
  declineUserDeleteRequestService
} from '../../services/crud-service';

export const approveUser = async (user, onClose) => {
  const { error } = await approveUserService(user.userId);
  if (!error) return onClose();
};

export const approveProfile = async (user, onClose) => {
  const { error } = await approveProfileService(user.userId);
  if (!error) return onClose();
};

export const createUser = async (data, onClose, successAction = () => {}) => {
  const { error } = await createUserService(data);
  if (!error) {
    successAction();
    onClose();
  }
};

export const deleteUser = async (user, onClose) => {
  const { error } = await deleteUserService(user.userId);
  if (!error) onClose();
};

export const updateUser = async (data, userId, onClose) => {
  const { error } = await updateUserService({
    ...data,
    user_id: userId
  });
  if (!error) onClose();
};

export const updateAdminRequest = async (requestId, onClose) => {
  const { error } = await approveUserUpdateRequestService(requestId);
  if (!error) onClose();
};

export const resendEmailActivation = async (email, onClose) => {
  const { error } = await resendEmailInvitation(resendEmailObject(email));
  if (!error) onClose();
};

// Decline requests
export const declineRequestedUpdate = async (requestId, onClose) => {
  const { error } = await declineUserUpdateRequestService(requestId);
  if (!error) onClose();
};

export const declineUserDeleteRequest = async (requestId, onClose) => {
  const { error } = await declineUserDeleteRequestService(requestId);
  if (!error) onClose();
};
