/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import {
  colors,
  fonts,
  gradients
} from '../../../../Dashboard4.0/assets/styles/styles';

export const BurgerContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  @media (min-width: 1150px) {
    display: none;
  }
`;

export const Burger = styled.div`
  width: 18px;
  height: 12px;
  position: relative;
  display: block;
  margin: -4px auto 0;
  top: 50%;
`;
export const Bar = styled.div`
  border-radius: 25px;
  width: 100%;
  height: 4px;
  display: block;
  position: relative;
  background: #c4c4c4;
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.2s;
`;

export const TopBar = styled(Bar)`
  transform: ${({ isMenuOpen }) =>
    isMenuOpen
      ? 'translateY(6px) rotate(45deg)'
      : 'translateY(0px) rotate(0deg)'};
`;
export const BottomBar = styled(Bar)`
  transform: ${({ isMenuOpen }) =>
    isMenuOpen
      ? 'translateY(2px) rotate(-45deg)'
      : 'translateY(6px) rotate(0deg)'};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 20px;
  @media (max-width: 1150px) {
    min-height: 76px;
  }

  img {
    height: 35px;
    width: 35px;
  }

  @media (min-width: 1150px) {
    justify-content: center;
    height: 11.9rem;

    img {
      height: 48px;
      width: 48px;
    }
  }
`;

export const MenuItemContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 4.4rem;
  flex-shrink: 0;

  height: 3.6rem;
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: 0.019rem;
  color: ${colors.almostBlack};
  text-decoration: none;
  position: relative;
  margin-bottom: 1.5rem;

  svg {
    position: absolute;
    left: 1.7rem;
    @media (max-width: 1150px) {
      left: 2.5rem;
    }
  }

  &:hover {
    background-color: ${colors.carbon3};
  }

  &.selected {
    color: ${colors.deltai};
    position: relative;
    svg {
      filter: invert(36%) sepia(100%) saturate(6272%) hue-rotate(339deg)
        brightness(96%) contrast(93%);
    }
    &::before {
      content: '';
      width: 0.6rem;
      height: 3.4rem;
      background: ${colors.deltai};
      position: absolute;
      left: 0;
      border-radius: 0px 5px 5px 0px;
    }
  }

  @media (max-width: 1150px) {
    justify-content: flex-start;
    padding-left: 5rem;
    margin-bottom: 3.5rem;
  }
`;

export const SideNavContainer = styled.div`
  background: ${gradients.NavBarBackgroundGradient};
  display: ${({ isMobileMenu }) => (isMobileMenu ? 'flex' : 'none')};
  transition: all 0.3s ease-in, background 0.5s ease-in;
  transition-delay: 0.25s;
  @media (max-width: 1150px) {
    height: ${({ isMenuOpen }) => (isMenuOpen ? '100vh' : '76px')};
    padding: ${({ isMenuOpen }) => (isMenuOpen ? '50px 0px' : '0px')};

    .bottomContainer {
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: center;
    }
    .lastAuth {
      width: 13rem;
      line-height: 1rem;
      margin: 2rem auto;
    }
  }
  @media (min-width: 1150px) {
    flex-shrink: 0;
    display: ${({ isMobileMenu }) => (isMobileMenu ? 'none' : 'flex')};
  }
`;

export const SideNavMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: scroll;

  @media (min-width: 1150px) {
    width: 19.2rem;
  }
`;

export const LastAuthContainer = styled.div`
  margin-top: 2rem;
  color: ${colors.carbon};
  font-size: 0.9rem;
  ${fonts.quickSandMedium};
  text-align: center;
  line-height: 10px;
  letter-spacing: 0.125926px;
`;

export const BottomContainer = styled.div`
  ${fonts.barlowRegular};
  font-size: 1.35rem;
  line-height: 1.8rem;
  letter-spacing: 0.1px;
  color: ${colors.almostBlack};
  /* margin: 0 auto; */
  padding: 4rem 0;
  bottom: 2rem;

  a,
  div {
    text-decoration: none;
    color: ${colors.almostBlack};
    display: block;
    margin: 2rem 3rem 0;
    svg,
    img {
      margin: 0 1rem 0 -1rem;
    }
  }

  @media (min-width: 1150px) {
    margin-top: auto;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const MarkerLabel = styled.span`
  border-radius: 0.4rem;
  padding: 0 0.5rem;
  background: #263343;
  color: #b4bac3;
  text-align: center;
  font-size: 0.9rem;
  ${fonts.barlowRegular};
  position: absolute;
  right: 1.5rem;
`;
