/* eslint-disable react/require-default-props */
import React from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// Components
import Dropdown from '../../shared/components/Dropdown/Dropdown';
import FilterBar from '../../shared/components/FilterBar';

import { FilterBarContainer } from './FilterTransactions.styles';

const FilterTransactions = ({
  searchFn,
  selectFilter,
  selectDownload,
  query,
  type
}) => {
  const dropdown = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Dropdown
          icon="filter"
          label="Filtrar"
          options={[
            { name: 'Todos los movimientos', id: 'all' },
            { name: 'Ordenes de pago', id: 'payment_order' },
            { name: 'Transferencias múltiples', id: 'multi_transfer' },
            { name: 'Tarjeta', id: 'credit_card' },
            { name: 'Internos', id: 'internal' }
          ]}
          keys={{ value: 'id', label: 'name' }}
          onSelect={e => selectFilter(e.value)}
        />
        <Dropdown
          icon="download"
          label="Descargar"
          options={[{ name: 'Todos los movimientos', id: 'all' }]}
          keys={{ value: 'id', label: 'name' }}
          onSelect={selectDownload}
        />
      </div>
    );
  };

  return (
    <FilterBarContainer>
      <FilterBar
        className="filterBar"
        searchIcon="search"
        searchPlaceholder="Buscar movimiento"
        searchFn={searchFn}
        rightElement={type !== 'multitransfer' ? dropdown : null}
        query={query}
      />
    </FilterBarContainer>
  );
};

FilterTransactions.propTypes = {
  searchFn: PropTypes.func,
  selectFilter: PropTypes.func,
  selectDownload: PropTypes.func,
  query: PropTypes.string
};

export default FilterTransactions;
