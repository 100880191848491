/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../Button/Button';
import { CustomTitle } from '../../../activity/components/AddBeneficiaryHeader/AddBeneficiaryHeader.styles';
import {
  Container as ModalContainer,
  Description as ModalDescription,
  Header as ModalHeader
} from './ModalInformation.styles';

const Activity = ({
  primaryButtonText,
  onPrimaryButtonClick,
  isPrimaryButtonDisabled,
  isPrimaryButtonHidden,
  secondaryButtonText,
  onSecondaryButtonClick,
  title,
  description,
  children,
  paddingHeader
}) => {
  return (
    <ModalContainer className="modalContainer">
      <ModalHeader paddingHeader={paddingHeader}>
        <CustomTitle className="headerTitle" size="large" text={title} />
      </ModalHeader>
      {description && <ModalDescription>{description}</ModalDescription>}
      {children}
      {isPrimaryButtonHidden ? null : (
        <Button
          className={`formButton ${!secondaryButtonText && 'singleButton'}`}
          size="lg"
          text={primaryButtonText}
          onClick={onPrimaryButtonClick}
          disabled={isPrimaryButtonDisabled}
        />
      )}
      {secondaryButtonText ? (
        <Button
          onClick={onSecondaryButtonClick}
          className="formButton"
          size="lg"
          text={secondaryButtonText}
          variant="overlaySecondary"
        />
      ) : null}
    </ModalContainer>
  );
};

Activity.propTypes = {
  handleModalClose: PropTypes.object
};

export default Activity;
