import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { bankInformationPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const createBankInformationService = async ({ bank, clabe }) => {
  try {
    const { status, error } = await customAxiosInstance().post(
      bankInformationPath,
      {
        bank,
        clabe
      }
    );
    return extractContentFromResponse({
      status,
      error,
      moduleName: 'create-bank-information-service'
    });
  } catch (error) {
    log.error('create-bank-information-service', error);

    return { error: { message: 'Unable to send request!' } };
  }
};
