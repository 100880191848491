import styled from 'styled-components';
import { colors, fonts } from '../../../../../Dashboard4.0/assets/styles/styles';

const HeaderTypes = ({ type }) => {
  return type === 'deposit'
    ? 'linear-gradient(86.66deg, #45BD62 1.95%, #4ADA6C 100%)'
    : 'linear-gradient(86.66deg, #1C2633 1.95%, #3C4D62 100%)';
};

export const VoucherContainer = styled.div`
  width: 100%;
  padding-bottom: ${({ isHighQuality }) => (isHighQuality ? '2rem' : '')};
  display: flex;
  flex-direction: column;
  align-content: center;
  height: auto;
  max-height: 98vh;
  position: relative;

  .title {
    color: ${colors.carbon};
    margin-bottom: 4.2rem;
  }

  .btnContainer {
    margin: 1rem auto 3rem;
    text-align: center;
    padding-bottom: 2rem;
  }

  .multitransferVoucher {
    max-height: 58rem;
  }

  .entryDetails {
    grid-gap: 2.5rem;
  }

  @media (min-width: 769px) {
    width: 65.9rem;
  }

  @media print {
    button {
      display: none;
    }
  }
`;

export const Header = styled.div`
  background-image: ${HeaderTypes};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(14px);
  height: 10.6rem;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const Logo = styled.img`
  padding-top: 2.1rem;
  width: 10.2rem;
  height: 5.2rem;
  float: left;
`;

export const BodyContainer = styled.div`
  margin: 0 3rem 3rem;
  overflow-y: scroll;
  padding-bottom: ${({ pb }) => (pb || '')};

  .headerTable {
    display: grid;
    align-items: center;
    justify-items: left;
  }
`;

export const TagsSection = styled.div`
  margin: 2.7rem 0;
`;

export const LinksContainer = styled.div`
  font-size: 1.6rem;
  color: #939ca9;
  text-align: center;
  max-width: 65.9rem;
  overflow: hidden;
  position: absolute;
  bottom: 2rem;
  height: 7rem;
  background: #fff;
  width: 90%;
  line-height: 4;
  ${fonts.barlowRegular};
  font-size: 1.6rem;
  color: #939ca9;
  text-align: center;
  margin: 0;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 0 0 20px 20px;
`;

export const ClickableActions = styled.span`
  ${fonts.barlowSemiBold};
  cursor: pointer;
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-right: 1.5rem;
  color: ${colors.almostBlack};
  &:first-of-type + span {
    color: ${colors.blueBackground};
  }
  &:first-of-type + span + span {
    color: #db0011;
  }
  svg {
    margin-right: 0.5rem;
  }
`;
