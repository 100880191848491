import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AddBeneficiary from '../AddBeneficiary/AddBeneficiary';
import AddMultiBeneficiaries from '../AddBeneficiary/AddMultiBeneficiaries';
import BatchTransfer from '../BatchTransfer/BatchTransfer';
import Modal from '../../../shared/components/Modal/Modal';
import SelectBeneficiary from '../SelectBeneficiary/SelectBeneficiary';
import TransacionModal from '../../../../Dashboard4.0/components/Transaction/TransactionModal';
import { clearCreaters } from '../../../../actionCreators/speiAction';
import { getTransactions } from '../../../../actionCreators/transactionAction';
import { setPage } from '../../../../actionCreators/changeViewAction';

const TransfersModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(state => state.viewReducer.currentPage);

  const handleClose = () => {
    clearCreaters(dispatch);
    getTransactions(dispatch, 1);
    onClose();
  };

  const clearData = () => {
    clearCreaters(dispatch);
    changePage('selectBeneficiary');
  };

  const changePage = page => {
    setPage(dispatch, page);
  };

  useEffect(() => {
    clearCreaters(dispatch);
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableBackDropClick={false}
      showCloseOnModal
    >
      {currentPage === 'selectBeneficiary' ? <SelectBeneficiary /> : null}
      {currentPage === 'editBeneficiary' ? (
        <AddBeneficiary isInEditMode />
      ) : null}
      {currentPage === 'addBeneficiary' ? <AddBeneficiary /> : null}
      {currentPage === 'addMultiBeneficiaries' ? (
        <AddMultiBeneficiaries onClose={handleClose} changePage={changePage} />
      ) : null}
      {currentPage === 'transactionFlow' ? (
        <TransacionModal changePage={changePage} resetModal={clearData} />
      ) : null}
      {currentPage === 'batchTransfer' ? (
        <BatchTransfer
          onClose={handleClose}
          onBack={() => changePage('selectBeneficiary')}
        />
      ) : null}
    </Modal>
  );
};

export default TransfersModal;
