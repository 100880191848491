import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

import Button from '../Button';
import Title from '../Title';

export const CardActivationWrapper = styled.div`
  .overlay {
    align-items: center;
    padding-top: 7.8rem;
    background-color: rgba(28, 38, 51, 0.66);
    overflow: scroll;
    backdrop-filter: blur(9px);
    width: 100%;
    height: 100%;
  }
  .overlay-center {
    align-items: center;
  }
  .link {
    display: inline-block;
    span {
      font-size: 2.4rem;
    }
  }
  .blockedMessage {
    width: 57.5rem;
  }

  .cardActivationPswd {
    max-width: 44rem;
  }

  @media (max-width: 600px) {
    .overlayArea {
      padding: 0 1.6rem;
    }

    .blockedMessage {
      width: 100%;
    }

    .cardLastFour {
      .singleInput {
        width: 5rem;
        height: 5.2rem;

        input {
          font-size: 1.6rem;
          margin-left: 0;
          width: 3.5rem;
          height: 2.5rem;
        }
      }
    }
  }
`;

export const CustomTitle = styled(Title)`
  color: white;
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 2.6rem;
`;

export const Text = styled(Title)`
  font-family: Barlow;
  font-size: 1.55rem;
  color: white;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  text-align: center;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom : '7.1rem'};
  width: 35rem;
  line-height: 23px;
`;

export const SuccessMessage = styled(CustomTitle)`
  width: 40.7rem;

  @media (max-width: 600px) {
    width: 100%;
    font-size: 1.8rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-bottom: 0.8rem;

  .codeError {
    color: #cd0324;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 4rem 0;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

const getGreyStyles = ({ isGrey }) => {
  if (isGrey)
    return `background: ${colors.carbon2};
    color: ${colors.body}
  `;
};

export const CustomButton = styled(Button)`
  max-width: 44.4rem;
  display: block;
  margin-top: 1.8rem;
  ${getGreyStyles};
  width: 100%;
  margin: 1.8rem auto;

  @media (max-width: 600px) {
    height: 4rem;
  }
`;

export const Image = styled.img`
  width: 22.5rem;
  height: 35.6rem;
  object-fit: contain;
`;

export const ContentButtons = styled.div`
  margin-top: 9.1rem;
  width: 100%;

  @media (max-wdith: 600px) {
    flex-direction: column;
  }
`;
