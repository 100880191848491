import log from 'loglevel';

import { customAxiosInstance } from '../../../../Services/axiosConfig';
import { afbSignUpPath } from './apiPaths';
import { extractContentFromResponse } from '../../../../helpers/extractContentFromResponse';

export const getSignUp = async ( {base64Encoding} ) => {
  try {
    const { data, error, status } = await customAxiosInstance().get(
      `${afbSignUpPath}?k=${base64Encoding}`
    )

    return extractContentFromResponse({
      data,
      error,
      status,
      moduleName: 'get-sign-up-service'
    });
  } catch (error) {
    log.debug('get-sign-up-service', error);
    return { error: { message: 'Unable to send request' } };
  }
};
