import styled from 'styled-components';
import { fonts } from '../../../../Dashboard4.0/assets/styles/styles';
import Amount from '../../../shared/components/Amount/Amount';

export const AmountLabelContainer = styled.span`
  ${fonts.barlowSemiBold};
  font-size: 1.3rem;
  text-transform: uppercase;
`;

export const AmountText = styled(Amount)`
  ${fonts.barlowSemiBold}
  font-size: 1.3rem;
`;
