import * as yup from 'yup';
import { isClabeInvalid } from '../../../../helpers/clabeValidator.js';

import {
  REQUIRED_MESSAGE,
  INSTITUTION_NOT_FOUND_MESSAGE,
  ONLY_NUMBERS_MESSAGE,
  isCLABE,
} from '../../constants';

export const validationSchema = yup.object({
  bank: yup
    .string()
    .test('bank validator', 'Banco no operante', function (bank) {
      const { path, createError } = this;

      if (bank === 'No encontrado') {
        return createError({
          path,
          message: INSTITUTION_NOT_FOUND_MESSAGE,
        });
      }
      return {};
    }),
  clabe: yup
    .string()
    .max(18, 'Clabe inválida')
    .test('clabe validator', 'Clabe inválida', function (clabe) {
      const { path, createError } = this;
      return true;
    }),
});
