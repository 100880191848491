import React from 'react';
import PropTypes from 'prop-types';

import { Container, Checkbox, CheckboxLabel, ErrorMessage } from './styles';

const CircularCheckbox = ({ text, helperText, onChange, onBlur }) => {
  return (
    <>
      <Container onBlur={onBlur}>
        <Checkbox
          type="checkbox"
          helperText={helperText}
          onChange={e => onChange(e.target.checked)}
        />
        <CheckboxLabel>{text}</CheckboxLabel>
      </Container>
      <ErrorMessage fontSize="1.2rem">{helperText}</ErrorMessage>
    </>
  );
};

CircularCheckbox.propTypes = {
  text: PropTypes.any,
  onChange: PropTypes.func
};

export default CircularCheckbox;
