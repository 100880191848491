export const ProfileCompletionObject = ({
  birthdate,
  gender,
  selfie,
  curp,
  rfc,
  phoneNumber
}) => {
  return {
    birth_date: birthdate,
    gender_id: gender,
    selfie_url: selfie,
    curp,
    rfc,
    phone_number: phoneNumber
  };
};
