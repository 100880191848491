import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';
import Title from '../../../shared/components/Title';

const getColor = ({ color }) => {
  const fontColors = {
    grey: colors.carbon,
    success: colors.success,
    primary: colors.primary,
    default: colors.body
  };

  return !color ? fontColors['default'] : fontColors[color];
};

const getType = ({ type }) => {
  if (type === 'primary') {
    return `
        border-radius: 8px;
        border: solid 0.8px rgba(23, 144, 207, 0.5);
        background-color: rgba(23, 144, 207, 0.05);
    `;
  }
};

const getHeight = ({ type }) => {
  if (type === 'primary' || type === 'grey') {
    return `
    height: 7.7rem;
    `;
  }
  return 'height: 5.4rem;';
};

const classes = css`
  .arrow {
    transform: rotate(90deg);
    margin-bottom: -2rem;
  }
`;

export const Container = styled.div`
  border-radius: 1.2rem;
  background-color: white;
  display: grid;
  grid-template-columns: 2fr 9fr 3fr;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  padding: 0;
  ${getHeight}
  font-size: 1.4rem;
`;

export const Concept = styled(Title)`
  font-size: 1.7rem;
  ${fonts.quickSandSemiBold}
  font-weight: 600;
  letter-spacing: 0.21px;
  color: ${getColor};
`;

export const CustomAmount = styled.div`
  display: grid;
  grid-template-columns: 8fr 2fr;
  align-items: left;
  justify-items: left;
  padding: 0.8rem 3.2rem 0.9rem 2.5rem;
  ${getType}

  @media (max-width: 768px) {
    padding: 0.8rem 1.2rem;
  }
`;

export const Point = styled.div`
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  background-image: linear-gradient(to top, #d8dce2 99%, #eef0f3 1%);
`;

export const LineContainer = styled.div`
  height: 5.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: space-around;
  ${classes}
`;

export const FocusedPoint = styled.div`
  width: 1.8rem;
  height: 1.8rem;
`;
