import React from 'react';
import SuccessPage from '../success-page/SuccessPage';

const Softwall = () => {

  return (
    <>
      <SuccessPage />
    </>
  );
};

export default Softwall;
