/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import divider from '../../../../Dashboard4.0/assets/images/divider.svg';
import { DetailsContainer, DetailsLabel } from './Details.styles';

const Details = ({
  mainText,
  secondaryText,
  secondaryTextDetails,
  className,
  className2
}) => {
  return (
    <DetailsContainer data-test="detailsComponent" className="detailsComponent">
      {mainText && (
        <DetailsLabel className={className} type="medium" data-test="mainText">
          {mainText}
        </DetailsLabel>
      )}

      {mainText && secondaryText && (
        <img data-test="divider" src={divider} alt="divider" />
      )}

      {secondaryText && (
        <DetailsLabel
          className={className2}
          type="normal"
          data-test="secondaryText"
        >
          {secondaryText}
          {secondaryTextDetails && (
            <>
              <br />
              {secondaryTextDetails}
            </>
          )}
        </DetailsLabel>
      )}
    </DetailsContainer>
  );
};

Details.propTypes = {
  mainText: PropTypes.string,
  secondaryText: PropTypes.string
};

export default Details;
