import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    margin: theme.spacing(1),
    width: '16.8rem',
    height: '4rem',
    borderRadius: '2.4rem',
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '1.31',
    letterSpacing: '0.2px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '14rem',
      fontSize: '1.3rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '13rem',
      height: '4.8rem',
      marginTop: '1.5rem'
    }
  }
}));

const RedBorderButton = ({ copy, onClick, disabled, dataTest }) => {
  const classes = styles();

  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={onClick}
      className={classes.button}
      disabled={disabled}
      data-test={dataTest || 'RedBorderButton'}
    >
      {copy}
    </Button>
  );
};

export default RedBorderButton;
