const agreementListHandler = ({ agreementList }) => async ({ uuid, type }) => {

  const { error, data } = await agreementList({
    uuid, type
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  let isSuccess = true;
  if(data?.response === "BAD"){
    isSuccess = false;
  }

  return { isSuccess, data };
};

export { agreementListHandler };
