import React from 'react';

// Auxiliar libraries
import { useFormik } from 'formik';

// Shared Components
import Button from '../../../shared/components/Button/Button';
import AuthCode from 'react-auth-code-input';

// Others
import { TwoFactorAuthValidation } from '../loginPage/validationSchema';

const TwoFactorAuthCodeForm = ({
  handleAuth,
  isInitialConfiguration,
  authError
}) => {
  const formik = useFormik({
    initialValues: { authCode: '' },
    validationSchema: TwoFactorAuthValidation
  });

  return (
    <>
      <AuthCode
        length={6}
        allowedCharacters="numeric"
        containerClassName="two-factor-auth-container"
        inputClassName={`two-factor-auth-input ${
          authError && 'two-factor-auth-input-error'
        }`}
        onChange={value => {
          formik.setFieldValue('authCode', value);
        }}
      />

      {authError && (
        <p className="two-factor-auth-otp-error">
          El código no es correcto. Por favor intenta otra vez.
        </p>
      )}

      <Button
        className="primaryButton"
        size="lg"
        text="Continuar"
        onClick={e => {
          e.preventDefault();
          handleAuth(formik.values.authCode, isInitialConfiguration);
        }}
        type="submit"
        disabled={!formik.isValid || !formik.dirty}
      />
    </>
  );
};

export default TwoFactorAuthCodeForm;
