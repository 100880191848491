// React imports
import React from 'react';

// Auxiliary librarys imports
import { Grid } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

// Components
import Button from '../../micro-frontends/shared/components/Button/Button';
import MultipartFileInput from '../../micro-frontends/shared/components/MultipartFileInput/MultipartFileInput';
import Tooltip from '../../micro-frontends/shared/components/Tooltip/Tooltip';

// Assets
import { CustomModalStyles } from './customModalStyles';
import { documents } from './documents';

const IdVerificationFile = ({
  selfieDocumentID,
  file,
  resetInput,
  onChange,
  uploadFile
}) => {
  const classes = CustomModalStyles();

  return (
    <div>
      <>
        <div className={classes.separator}></div>
        <Grid
          className={classes.buttonsCenter}
          container
          justify="center"
          spacing={2}
        >
          <p className={classes.text}>O, puedes subir una foto</p>
          <MultipartFileInput
            resetInput={resetInput}
            className={classes.fileInput}
            label={documents[selfieDocumentID].name}
            type="file"
            onChange={e => onChange(e, documents[selfieDocumentID])}
            documentName={documents[selfieDocumentID].keyword}
            documentId={selfieDocumentID}
            accept={'.jpg, .jpeg, .png, .pdf'}
            endAdornment={
              <Tooltip
                className="tooltip"
                label="INE"
                text="Recuerda solo subir archivos JPG, PNG o PDF. Recomendamos que el archivo no sea más grande que 50 MB."
              />
            }
          />
        </Grid>
        <Button
          text="Continuar"
          size="lg"
          className={classes.buttonLg}
          disabled={!file || !file.content}
          onClick={uploadFile}
        />
      </>
    </div>
  );
};

export default withWidth()(IdVerificationFile);
