const invalidSoftwallStatuses = [
  'NO_STATUS',
  'IN_REVIEW',
  'QUALIFIED',
  'INTERVIEW_SCHEDULED',
  'NOT_INITIALIZED',
  'EMAIL_CONFIRMED',
  'UNQUALIFIED'
];

// ONBOARDING_COMPLETE is a 'reversible' status for softwall path but not for the rest of softwall paths
const invalidOnboardingStatuses = [
  ...invalidSoftwallStatuses,
  ' ONBOARDING_COMPLETE'
];

const OnboardingStatusGuard = (onboardingStatus, isSoftwall) => {
  const status = onboardingStatus || 'NO_STATUS';
  const invalidStatuses = isSoftwall
    ? invalidSoftwallStatuses
    : invalidOnboardingStatuses;

  return !invalidStatuses.includes(status);
};

export default OnboardingStatusGuard;
