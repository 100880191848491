/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Details from '../../../shared/components/Details';

import {
  SavedBeneficiaryContainer,
  CustomAvatar,
  Alias,
  DetailsContainer,
  Email
} from './AddBeneficiaryHeader.styles';

const SavedBeneficiary = ({ alias, fullName, colors, initials, email }) => {
  return (
    <SavedBeneficiaryContainer data-test="savedBeneficiaryHeader">
      <CustomAvatar colors={colors}>{initials}</CustomAvatar>
      <DetailsContainer>
        <Alias text={alias} size="large" />
        <Details mainText={fullName} />
      </DetailsContainer>
      <Email variant="outlined" text={email} />
    </SavedBeneficiaryContainer>
  );
};

SavedBeneficiary.propTypes = {
  alias: PropTypes.string,
  colors: PropTypes.object,
  fullName: PropTypes.string,
  initials: PropTypes.string,
  email: PropTypes.string
};

export default SavedBeneficiary;
