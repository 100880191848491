import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const EmptyState = styled.div`
  font-size: 1.55rem;
  color: ${colors.body3};
  padding-top: 8rem;
  text-align: center;

  .bold {
    font-weight: 500;
  }
`;
