/* eslint-disable react/require-default-props */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import FileDetails from './FileDetails';
import FailedBatches from './FailedBatches';
import SelectFile from './SelectFile';
import MultiTransferName from './MultiTransferName';
import TransfersConfirm from './TransfersConfirm';
import Arrow from '../../../../Dashboard4.0/assets/images/arrow.svg';
import {
  BackButton,
  BatchTransferContainer,
  DotsContainer,
  Dot
} from './BatchTransfer.styles';

import { downloadFile, fileFormat } from '../../../../helpers/fileManager';
import { getTemplateTransfer } from '../../../../Services/spei';

import { getFailedBatches } from '../../../../actionCreators/failedBatchesAction';

const BatchTransfer = ({ onClose, onBack }) => {
  const [step, setStep] = useState(1);
  const [multiTransfer, setMultiTransfer] = useState({
    file: null,
    name: null
  });
  const [done, setDone] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [template, setTemplate] = useState();
  const [dataFile, setDataFile] = useState(null);
  const [parsedFile, setParsedFile] = useState();

  const dispatch = useDispatch();
  const failedBatches = useSelector(
    state => state.failedBatchesReducer.failedBatches
  );

  const onChange = e => {
    if (e.target && e.target.hasOwnProperty('file'))
      setMultiTransfer({ ...multiTransfer, file: e.target });
    else setMultiTransfer({ ...multiTransfer, name: e.target.value });
  };

  const resetFile = () => {
    setStep(1);
    setMultiTransfer({ file: null });
  };

  const downloadTemplate = () => {
    downloadFile(
      fileFormat.csv,
      'base64',
      template,
      'plantilla_multiples_transferencias',
      'csv'
    );
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      onBack();
    }
  };

  const goToFailedTransactions = () => {
    setStep(5);
  };

  useEffect(() => {
    if (multiTransfer.file && multiTransfer.file.value) setIsValid(true);
    else setIsValid(false);
  }, [multiTransfer.file]);

  useEffect(() => {
    getTemplateTransfer().then(data => {
      if (data.status === 200) {
        setTemplate(data.data[0].batch_template);
      }
    });

    getFailedBatches(dispatch);
    // eslint-disable-next-line
  }, []);

  return (
    <BatchTransferContainer data-test="BatchTransfer">
      {step !== 5 && !done ? (
        <BackButton onClick={() => handleBack()}>
          <img src={Arrow} alt="arrow" /> Regresar
        </BackButton>
      ) : null}
      {step === 1 ? (
        <SelectFile
          onChange={onChange}
          template={template}
          downloadTemplate={downloadTemplate}
          submit={setStep}
          isValid={isValid}
          failedBatches={failedBatches.length}
          showFailedBatches={goToFailedTransactions}
        />
      ) : null}
      {step === 2 ? (
        <FileDetails
          file={multiTransfer.file}
          submit={setStep}
          isValid={isValid}
          onSetDataFile={setDataFile}
          resetFile={resetFile}
          setParsedFile={setParsedFile}
        />
      ) : null}
      {step === 3 ? (
        <MultiTransferName
          dataFile={dataFile}
          onChange={onChange}
          submit={setStep}
        />
      ) : null}
      {step === 4 ? (
        <TransfersConfirm
          dataFile={{ ...dataFile, multiTransferName: multiTransfer.name }}
          parsedFile={parsedFile}
          name={multiTransfer.name}
          resetFile={resetFile}
          done={setDone}
          submit={setStep}
          onChange={onChange}
          onClose={onClose}
        />
      ) : null}
      {step === 5 ? (
        <FailedBatches
          failedBatches={failedBatches}
          goBack={() => setStep(1)}
        />
      ) : null}
      {step < 4 ? (
        <DotsContainer>
          <Dot active={step >= 1} />
          <Dot active={step >= 2} />
          <Dot active={step === 3} />
        </DotsContainer>
      ) : null}
    </BatchTransferContainer>
  );
};

BatchTransfer.propTypes = {
  onClose: PropTypes.func
};

export default BatchTransfer;
