import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import {
  getCardTransactionTagsCardIdPath,
  getCardTransactionTagsTransactionIdPath
} from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const getCardTransactionTagsService = async ({
  transactionId,
  cardId
}) => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      `${getCardTransactionTagsCardIdPath}${cardId}${getCardTransactionTagsTransactionIdPath}${transactionId}`
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'card-transaction-tag-service'
    });
  } catch (error) {
    log.error('card-transaction-tag-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
