import { setAvailableCards, setCardsErrorAction } from '../redux/cards-actions';

const getAvailableCardsHandler = ({
  getAvailableCards,
  dispatch
}) => async () => {
  const { error, data: availableCardsData } = await getAvailableCards();

  if (error) {
    dispatch(setCardsErrorAction({ error }));
    return;
  }
  const availableCards = {
    physicalCreditCards: availableCardsData.physical_credit_cards,
    virtualCreditCards: availableCardsData.virtual_credit_cards
  };

  dispatch(setAvailableCards({ availableCards }));

  return {
    isSuccess: true,
    availableCards
  };
};

export { getAvailableCardsHandler };
