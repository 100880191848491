import { setTagsSpeiAction } from '../redux/tag-action';
import { TagsCreator } from '../domain/tagData';

const getSpeiTagsHandler = ({ dispatch, getSpeiTags }) => async ({
  transactionId
}) => {
  const { error, data } = await getSpeiTags({ transactionId });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  const tags = TagsCreator({ data });

  dispatch(setTagsSpeiAction({ tags }));

  return { isSuccess: true };
};

export { getSpeiTagsHandler };
