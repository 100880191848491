import styled from 'styled-components';
import Button from '../../../shared/components/Button';

import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  max-width: 44rem;
`;

export const VideoContainer = styled.div`
  border-radius: 50%;
  background-color: #e5e8ed;
  overflow: hidden;

  img {
    width: 15rem;
    height: 15rem;
  }

  video {
    border-radius: 50%;
  }
`;

export const StyledButton = styled(Button)`
  border-color: ${colors.primary};
  color: ${colors.primary};
  background: none;
  min-width: 14.3rem;
`;
