// TODO: Order imports

import { getCardsHandler } from './event-handlers/get-cards';
import { authUserHandler } from './event-handlers/auth-user';
import { getCardDetailsHandler } from './event-handlers/get-card-details';
import { getPersonAddressHandler } from './event-handlers/get-person-address';
import { getAddressStatesHandler } from './event-handlers/get-address-states';
import { issueVirtualCardHandler } from './event-handlers/issue-virtual-card';
import { issuePhysicalCardHandler } from './event-handlers/issue-physical-card';
import { updateCardHandler } from './event-handlers/update-card';
import { blockCardHandler } from './event-handlers/block-card';
import { unblockCardHandler } from './event-handlers/unblock-card';
import { reportLostCardHandler } from './event-handlers/report-lost-card';
import { getUsersHandler } from './event-handlers/get-users';
import { getCardChangeRequestHandler } from './event-handlers/get-card-change-request';
import { getCardCreationRequestHandler } from './event-handlers/get-card-creation-request';
import { decideCardRequestHandler } from './event-handlers/decide-card-request';
import { getAvailableCardsHandler } from './event-handlers/get-available-cards';

import { getCardsService } from './infrastructure/get-cards';
import { authUserService } from './infrastructure/auth-user';
import { getCardDetailsService } from './infrastructure/get-card-details';
import { getPersonAddressService } from './infrastructure/get-person-address';
import { getAddressStatesService } from './infrastructure/get-address-states';
import { issueCardService } from './infrastructure/issue-card';
import { updateCardService } from './infrastructure/update-card';
import { changeCardStatusService } from './infrastructure/change-card-status';
import { getUsersService } from './infrastructure/get-users';
import { getCardsCreationRequestsService } from './infrastructure/get-cards-creation-requests';
import { getCardChangeRequestService } from './infrastructure/get-card-change-request';
import { approveCardChangeRequestService } from './infrastructure/approve-card-change-request';
import { declineCardChangeRequestService } from './infrastructure/decline-card-change-request';
import { approveCardCreationRequestService } from './infrastructure/approve-card-creation-request';
import { declineCardCreationRequestService } from './infrastructure/decline-card-creation-request';
import { getAvailableCardsService } from './infrastructure/get-available-cards';

const service = ({ dispatch } = {}) => ({
  getCards: getCardsHandler({
    dispatch,
    getCards: getCardsService
  }),

  getCardDetails: getCardDetailsHandler({
    getCardDetails: getCardDetailsService
  }),

  getAddressStates: getAddressStatesHandler({
    getAddressStates: getAddressStatesService
  }),

  getPersonAddress: getPersonAddressHandler({
    getPersonAddress: getPersonAddressService
  }),

  authUser: authUserHandler({
    authUser: authUserService
  }),

  issueVirtualCard: issueVirtualCardHandler({
    dispatch,
    issueCard: issueCardService
  }),

  issuePhysicalCard: issuePhysicalCardHandler({
    dispatch,
    issueCard: issueCardService
  }),

  updateCard: updateCardHandler({
    updateCard: updateCardService
  }),

  blockCard: blockCardHandler({
    changeCardStatus: changeCardStatusService,
    authUser: authUserService
  }),

  unblockCard: unblockCardHandler({
    changeCardStatus: changeCardStatusService,
    authUser: authUserService
  }),

  reportLostCard: reportLostCardHandler({
    changeCardStatus: changeCardStatusService
  }),

  getUsers: getUsersHandler({
    dispatch,
    getUsers: getUsersService
  }),

  getCardChangeRequest: getCardChangeRequestHandler({
    getCardChangeRequest: getCardChangeRequestService
  }),

  getCardCreationRequest: getCardCreationRequestHandler({
    getCardsCreationRequests: getCardsCreationRequestsService
  }),

  approveCardCreationRequest: decideCardRequestHandler({
    decideCardRequest: approveCardCreationRequestService
  }),

  declineCardCreationRequest: decideCardRequestHandler({
    decideCardRequest: declineCardCreationRequestService
  }),

  approveCardChangeRequest: decideCardRequestHandler({
    decideCardRequest: approveCardChangeRequestService
  }),

  declineCardChangeRequest: decideCardRequestHandler({
    decideCardRequest: declineCardChangeRequestService
  }),

  getAvailableCardsRequest: getAvailableCardsHandler({
    dispatch,
    getAvailableCards: getAvailableCardsService
  })
});

export { service };
