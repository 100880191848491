/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

// Auxiliar libraries
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

// Components

import { StyledModal } from './styles';
import Title from '../../../shared/components/Title/Title';
import Form from './Form';

// Actions
import { validationSchema } from './validation-schema-card-details';
import MobileCardTypeInfoDisplay from '../../components/card-type-info/MobileCardTypeInfoDisplay';
import CardTypeInfo from '../../components/card-type-info/CardTypeInfo';

const ModalCardDetails = ({
  handleSubmit,
  handleSafeModalClose,
  handleModalClose,
  isEditedBefore,
  initialValues,
  isEditingEnabled,
  handleSaveTag,
  handleDeleteTag,
  tags,
  toggleReportCardLostModal,
  toggleBlockCardModal,
  toggleUnblockCardModal,
  toggleCreateCardPinModal
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema
  });

  useEffect(() => {
    if (isEditedBefore) {
      formik.setFieldTouched('creditCardName');
      formik.setFieldTouched('spendLimit');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <StyledModal
      open
      showCloseOnModal
      onClose={handleModalClose}
      disableBackDropClick={false}
    >
      <div className="formContainer">
        <Title
          className="formTitle"
          size="large"
          text={
            initialValues?.creditCardName
              ? `${initialValues.creditCardName} (${initialValues.lastFourDigits})`
              : `(${initialValues.lastFourDigits})`
          }
        />
        <div className="moblieContainer">
          <MobileCardTypeInfoDisplay />
        </div>
        <Form
          initialValues={initialValues}
          isEditingEnabled={isEditingEnabled}
          handleSaveTag={handleSaveTag}
          handleDeleteTag={handleDeleteTag}
          tags={tags}
          toggleReportCardLostModal={toggleReportCardLostModal}
          toggleBlockCardModal={toggleBlockCardModal}
          toggleUnblockCardModal={toggleUnblockCardModal}
          handleModalClose={handleModalClose}
          handleSafeModalClose={handleSafeModalClose}
          toggleCreateCardPinModal={toggleCreateCardPinModal}
          isEditedBefore={isEditedBefore}
          handleSubmit={handleSubmit}
        />
      </div>
      <div className="cardTypeInfoContainer">
        <CardTypeInfo />
      </div>
    </StyledModal>
  );
};

ModalCardDetails.propTypes = {
  history: PropTypes.object,
  toggleReportCardLostModal: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired
};

export default ModalCardDetails;
