import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';
import InfoBar from '../../../shared/components/InfoBar/InfoBar';

export const Container = styled.div`
  width: 100%;
  .progress-color {
    background: ${colors.deltai};
  }

  .fullWidth {
    width: 100%;
    margin: 0;
  }

  @media (min-width: 760px) {
    .fullWidth {
      width: 100%;
    }
  }
`;

export const LabelCentered = styled.div`
  margin: auto;
  display: table;
  .inline-helper {
    display: inline-block;
    margin-right: 0.5rem;
  }
`;

export const CustomInfoBar = styled(InfoBar)`
  max-width: 100%;
  margin: 1rem 0 0 0;
  .infoBarContentEnd {
    border-radius: ${props => (props.isSuperAdmin ? '0 1.2rem 1.2rem 0' : '0')};
  }

  @media screen and (min-width: 990px) {
    margin-top: 0;
    /* max-width: ${props => (props.isSuperAdmin ? '68.8rem' : '100%')}; */
  }
`;

export const AmountBar = styled.div`
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: auto auto;
  ${fonts.quickSandSemiBold};
  font-size: 1.7rem;

  span:nth-child(2) {
    text-align: right;
  }
`;

export const AmountLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AmountIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.color || colors.primary};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

export const StyledLink = styled.a`
  color: var(--toastify-text-color-light);
`;
