import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Typeform from '../../components/Typeform';

import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

import { selectOnboardingState } from '../../../onboarding/redux/onboardingSelectors';
import { EMAIL_CONFIRMED } from '../../../onboarding/constants';

const PrequalificationPage = ({ history }) => {
  const status = useSelector(selectOnboardingState);

  return (
    <RegistrationPageLayout
      title="Ahora a conocernos mejor 😃"
      description="Esto nos ayuda para recomendarte la mejor solución para tus finanzas."
      isPrimaryButtonHidden
      isModalVisible
      showLogout
    >
      <Typeform />
    </RegistrationPageLayout>
  );
};

export default PrequalificationPage;
