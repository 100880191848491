import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Shared Components
import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

//Styles
import TwoFactorAuthCodeForm from './TwoFactorAuthCodeForm';
import TwoFactorAuthQR_CodePage from './TwoFactorAuthQR_CodePage';
import { ModalContent } from './styles';
import { cleanLocalStorage } from '../../../../Services/authService';

// Helpers
import { DEVICE_CONFIGURED, DEVICE_NOT_CONFIGURED } from './constants';

const TwoFactorAuthPage = ({ handleAuth, userClaims, authError }) => {
  const [is2FA_Device_Configured, setIs2FA_Device_Configured] = useState('');
  const [isInitialConfiguration, setIsInitialConfiguration] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const two_factor_authentication_device_flag =
      userClaims?.two_factor_authentication_device_flag;

    if (two_factor_authentication_device_flag) {
      setIs2FA_Device_Configured(DEVICE_CONFIGURED);
    } else {
      setIs2FA_Device_Configured(DEVICE_NOT_CONFIGURED);
    }
  }, []);

  return (
    <div>
      {userClaims && (
        <RegistrationPageLayout
          title={
            is2FA_Device_Configured === DEVICE_CONFIGURED
              ? 'Ingresa el código de doble factor de autenticación'
              : 'Configura el doble factor de autenticación escaneando el código QR'
          }
          isPrimaryButtonHidden
          isModalVisible
          showLogout
          handleLogOut={() => {
            cleanLocalStorage();
            history.push('/');
          }}
        >
          <ModalContent>
            {is2FA_Device_Configured === DEVICE_CONFIGURED && (
              <TwoFactorAuthCodeForm
                handleAuth={handleAuth}
                isInitialConfiguration={isInitialConfiguration}
                authError={authError}
              />
            )}
            {is2FA_Device_Configured === DEVICE_NOT_CONFIGURED && (
              <TwoFactorAuthQR_CodePage
                setIs2FA_Device_Configured={setIs2FA_Device_Configured}
                setIsInitialConfiguration={setIsInitialConfiguration}
              />
            )}
          </ModalContent>
        </RegistrationPageLayout>
      )}
    </div>
  );
};

export default TwoFactorAuthPage;
