import React, { memo } from 'react';

// Auxiliar libraries
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Services
import { closeSession } from '../../../../Services/authService';
import { PURGE } from '../../../../reducers/types';

import logout from '../../../../Dashboard4.0/assets/images/logout.svg';

const Logout = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const logOut = () => {
    try {
      closeSession();
    } finally {
      dispatch({ type: PURGE });
      history.push('/');
    }
  };

  return (
    <div onClick={logOut}>
      <img src={logout} alt="Cerrar sesión" /> Cerrar sesión
    </div>
  );
};

export default memo(Logout);
