/* eslint-disable no-return-await */
import { transactionTypes } from '../constants';
import { getCreditCardMovement } from '../infrastructure/get-credit-card-movement';
import {
  getPaymentOrder,
  getPaymentOrders
} from '../infrastructure/get-payment-order';

export const getTransactionDetails = async (trackingKey, transactionTypeId) => {
  if (transactionTypes.creditCardTransactions.includes(transactionTypeId))
    return await getCreditCardMovement(trackingKey);

  return await getPaymentOrder(trackingKey);
};

export const getMultiTransactionDetails = async batchProcessId => {
  return await getPaymentOrders(batchProcessId);
};
