/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AccountInputLabel from './AccountInputLabel.jsx';
import TextField from '../../../shared/components/TextField';
import BankMenu from '../BankMenu';
import { formatAccountInputValue } from '../../../../helpers';

const AccountInput = ({ onChange, values, ...otherProps }) => {
  const setDefaultValue = () => {
    if (values.account_code) {
      const valueFormated = formatAccountInputValue(values.account_code);
      return valueFormated.value.substring(0, 22);
    }
    return '';
  };

  const [value, setValue] = useState(setDefaultValue());
  const [type, setType] = useState(values.type);

  const handleChange = e => {
    const inputValue = e?.target?.value;

    let handledStringToDelete = handleDelete(inputValue);
    const valueToFormat = handledStringToDelete || inputValue;
    const formattedValues = formatAccountInputValue(valueToFormat);

    onChange({
      target: { value: formattedValues.value, name: 'account_code' },
      nativeEvent: { inputType: 'insertText' }
    });
    setType(formattedValues.type);
    setValue(formattedValues.value.substring(0, 22));
  };

  const handleDelete = inputValue => {
    const valueWithoutSpaces = value.replace(/\s/g, '');

    if (inputValue.length < value.length)
      return valueWithoutSpaces.slice(0, -1);
  };

  return (
    <TextField
      label={<AccountInputLabel type={values.type} />}
      name="account_code"
      value={value}
      onChange={handleChange}
      onBlur={otherProps.onBlur}
      error={otherProps.error}
      helperText={otherProps.helperText}
      {...otherProps}
      endAdornment={
        <BankMenu
          height={otherProps.height}
          banks={otherProps.banks}
          selectedBank={values.bank}
          accountType={type || values.type}
          onChange={onChange}
        />
      }
    />
  );
};

AccountInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  helperText: PropTypes.string
};

export default AccountInput;
