import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Modal from '../../../shared/components/Modal';
import ModalInformation from '../../../shared/components/ModalInformation';

import { service } from '../../../tags/service';
import { removeTagAction } from '../../../tags/redux/tag-action';

const RemoveTagModal = ({ removeTagModal, setRemoveTagModal, tag, cardId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setRemoveTagModal(false);
  };

  const handleDeleteBasedOnType = async ({ isNewCard, tag }) => {
    if (isNewCard) return dispatch(removeTagAction({ tag }));
    // eslint-disable-next-line no-return-await
    return await service({ dispatch }).deleteCardTag({
      tag: {
        tagLabel: tag.tagLabel,
        creditCardId: cardId,
        tagId: tag.tagId
      }
    });
  };

  const handleRemoveTag = async () => {
    await handleDeleteBasedOnType(tag);
    handleModalClose();
  };

  return (
    <Modal
      open={removeTagModal}
      onClose={handleModalClose}
      showCrossSign={false}
      disableBackDropClick={false}
      showCloseOnModal
    >
      <ModalInformation
        title="¿Estás seguro que deseas eliminar esta etiqueta?"
        description="Al eliminar esta etiqueta se retirará en todas las transacciones futuras asociadas a esta tarjeta, pero se mantendrá la etiqueta en transacciones existentes."
        primaryButtonText="Sí, eliminar"
        secondaryButtonText="No, conservar"
        onPrimaryButtonClick={handleRemoveTag}
        onSecondaryButtonClick={handleModalClose}
      />
    </Modal>
  );
};

export default RemoveTagModal;
