import log from 'loglevel';

export const createMachine = stateMachineDefinition => {
  const machine = {
    value: stateMachineDefinition.initialState,
    transition(currentState, event) {
      const currentStateDefinition = stateMachineDefinition[currentState];
      const destinationTransition = currentStateDefinition?.transitions[event];
      if (!destinationTransition) {
        log.error('State transition not found!', currentState, event);
        return currentState;
      }
      const destinationState = destinationTransition.target;

      return destinationState;
    }
  };

  return machine;
};
