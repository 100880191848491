import { TextField } from '@material-ui/core';
import React, { memo } from 'react';

import { useStyles } from './FormStyles';

const CustomTextField = ({
  label,
  name,
  error,
  value,
  onChange,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <TextField
      {...otherProps}
      label={label}
      className={classes.textField}
      name={name}
      onChange={onChange}
      margin="normal"
      helperText={error}
      error={error}
      value={value}
      fullWidth
    />
  );
};

export default memo(CustomTextField);
