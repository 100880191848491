/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import Add from '../../../../Dashboard4.0/assets/images/icons-add.svg';
import Remove from '../../../../Dashboard4.0/assets/images/icons-remove.svg';

import { ButtonContainer } from './InputButton.styles';

const InputButton = ({ type, onClick, ...props }) => {
  return (
    <ButtonContainer
      data-test="inputButtonComponent"
      {...props}
      type={type}
      onClick={onClick}
    >
      <img src={type === 'add' ? Add : Remove} alt="button icon" />
    </ButtonContainer>
  );
};

InputButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func
};

export default InputButton;
