import React from 'react';
import waitingRedLines from '../../assets/waiting-red-lines.gif';

const WaitingRedLines = ({ backgroundRGBA }) => {
  const bg_rgba = backgroundRGBA || 'rgba(255,255,255,0.9)';
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: bg_rgba,
        position: 'fixed',
        left: '0',
        top: '0',
        zIndex: '9999'
      }}
    >
      <img
        style={{ width: '10rem', margin: 'auto', display: 'block' }}
        src={waitingRedLines}
        alt="Cargando..."
      />
    </div>
  );
};

export default WaitingRedLines;
