export const calendlyURL = `${process.env.REACT_APP_CALENDLY_URL_INTERVIEW}`;
export const interviewSchedulePath = '/interview';
export const prequalificationFormPath = '/prequalification';
export const onboardingRequirementsPath = '/requirements';
export const startLaterPath = '/start-later';
export const inReviewPath = ['/in-review', '/in-review/:score'];

export const prequalificationPaths = [
  prequalificationFormPath,
  interviewSchedulePath,
  onboardingRequirementsPath,
  startLaterPath,
  inReviewPath
];
