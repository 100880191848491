import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Auxiliar libraries
import { debounce } from 'lodash';
import log from 'loglevel';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CardActivation from '../../shared/components/CardActivation';
import EntryEmptyState from '../../shared/components/EntryEmptyState/EntryEmptyState';
import EmptyState from '../../shared/components/EmptyState/EmptyState';
import FilterTransactions from './FilterTransactions';
import HasPermissions from '../../../role-based-access/components/HasPermissions';
import HeaderTable from '../../shared/components/HeaderTable';
import Layout from '../../shared/components/Layout/Layout';
import Paginator from '../../shared/components/Paginator';
import Transactions from '../components/Transactions/Transactions';
import TransfersModal from '../components/TransfersModal/TransfersModal';
import UserDetails from '../components/UserDetails';
import LimitBar from '../components/LimitBar';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { MobileButtonContainer } from './Activity.styles';

// Actions
import {
  filterTransactionsByType,
  getTransactions,
  getTransactionsByText,
  setFilterByType
} from '../../../actionCreators/transactionAction';
import { getCompanySum } from '../../../actionCreators/companyActions';
import { setPage } from '../../../actionCreators/changeViewAction';

import { alert } from '../../../helpers/alertsManager';
import loading from '../../../assets/waiting-red-lines.gif';
import { getTransactionsFile } from '../../../Services/transaction';
import {
  downloadFile,
  fileFormat,
  removeExtension
} from '../../../helpers/fileManager';
import spanish from '../../../catalogs/spanish.json';
import MultiTransferById from '../components/ProofOfPayments/MultiTransferModal/MultitransfersById';
import Button from '../../shared/components/Button';
import { useHistory } from 'react-router-dom';

const ActivityPage = () => {
  // Redux
  const dispatch = useDispatch();
  const history = useHistory();
  const name = useSelector(state => state.userInfoReducer.user.name);
  const surname = useSelector(state => state.userInfoReducer.user.surname_1);
  const paymentOrdersRes = useSelector(
    state => state.transactionReducer.transactions
  );
  const paymentOrders = paymentOrdersRes.res.transactions;
  const page = paymentOrdersRes.res.current_page;
  const totalPages = paymentOrdersRes.res.total_pages;
  const totalResults = paymentOrdersRes.res.total_results;
  const { success } = paymentOrdersRes;
  const { error } = paymentOrdersRes;
  const userEmail = useSelector(state => state.userInfoReducer.user.email);
  const isSuperAdmin =
    useSelector(state => state.rolePermissionsReducer.userType) !==
    'Basic User';
  const savedFilterByType = useSelector(
    state => state.transactionReducer.filterByType
  );

  const [currentPage, setCurrentPage] = useState(page || 1);
  const [filteredByQuery, setFilteredByQuery] = useState('');
  const [filteredByType, setFilteredByType] = useState(savedFilterByType);
  const [open, setOpen] = useState(false);
  const [notFound, setNotFound] = useState('');

  const downloadTransactionsZip = () => {
    getTransactionsFile()
      .then(res => {
        if (res.status === 200) {
          const fileName = removeExtension(res.data[0].filename);
          downloadFile(
            fileFormat.csv,
            'base64',
            res.data[0].transactions,
            fileName,
            'zip'
          );
        }
      })
      .catch(err => {
        log.error(err);
      });
  };

  const filterTransactions = debounce(query => {
    setFilteredByType('');

    if (!query) {
      setFilteredByQuery('');
      return;
    }

    setFilteredByQuery(query);
    setCurrentPage(1);
  }, 1000);

  const getTransactionsByType = type => {
    setFilteredByQuery('');

    if (!type || type === 'all') {
      setFilteredByType('');
      setFilterByType(dispatch, '');
      return;
    }

    setFilteredByType(type);
    setFilterByType(dispatch, type);
    setCurrentPage(1);
  };

  const onModalClose = () => {
    setOpen(false);
    getCompanySum(dispatch);
  };

  const handleOpen = () => {
    setOpen(true);
    setPage(dispatch, 'selectBeneficiary');
  };

  useEffect(() => {
    if (filteredByQuery) {
      getTransactionsByText(dispatch, currentPage, filteredByQuery);
      return;
    }

    if (filteredByType) {
      filterTransactionsByType(dispatch, currentPage, filteredByType);
      return;
    }
    if (!filteredByQuery && !filteredByType) {
      getTransactions(dispatch, currentPage);
    }

    // eslint-disable-next-line
  }, [currentPage, filteredByQuery, filteredByType]);

  useEffect(() => {
    if (filteredByQuery && paymentOrders.length === 0) {
      setNotFound(filteredByQuery);
    } else if (filteredByType && paymentOrders.length === 0) {
      setNotFound(`movimientos de tipo ${spanish.orderTypes[filteredByType]}`);
    } else setNotFound('');

    // eslint-disable-next-line
  }, [filteredByQuery, paymentOrders]);

  useEffect(() => {
    if (history.location?.state?.prevlocation === '/new-user-registration') {
      alert(
        'success',
        'Has completado tu registro exitosamente. Ya puedes ingresar a tu cuenta.',
        5000,
        {
          marginLeft: '-29rem',
          width: '60.5rem'
        }
      );
    }
  }, [history]);

  return (
    <Layout
      title={`¡Buen día, ${name || ''}!`}
      titleButton={{
        text: 'Transferir dinero',
        onClick: handleOpen,
        disabled: process.env.REACT_APP_TRANSFERS_DISABLED === 'true',
        startIcon: <CallMadeIcon />
      }}
      midleComponent={
        <>
          <UserDetails setOpen={handleOpen} />{' '}
          <HasPermissions
            perform="transaction:create"
            accessAction={() => (
              <LimitBar
                setOpen={handleOpen}
                isSuperAdmin={isSuperAdmin}
                userName={`${name} ${surname}`}
              />
            )}
            restrictedAction={() => <></>}
          />
        </>
      }
    >
      <FilterTransactions
        query={filteredByQuery}
        searchFn={filterTransactions}
        selectFilter={getTransactionsByType}
        selectDownload={downloadTransactionsZip}
      />

      <HeaderTable
        texts={['FECHA', 'MOVIMIENTOS', 'MONTO', 'ESTATUS']}
        columns="1.5fr 8fr 3fr 3fr"
      />

      {!error && !success ? (
        <EntryEmptyState
          img={loading}
          imgWidth={80}
          data-test="EntryEmptyState"
          title="Sincronizando transacciones"
          description="Tus transacciones se están sincronizando, esto podría tomar algunos segundos."
        />
      ) : null}

      {error ? (
        <EntryEmptyState
          data-test="EntryEmptyState"
          title="¡Algo pasó!"
          description="Surgió un problema sincronizando tus movimientos. Por favor inténtalo de nuevo."
          action={() => getTransactions(dispatch, 1)}
          actionText="Sincronizar"
        />
      ) : null}

      {(success && paymentOrders && paymentOrders.length > 0) || notFound ? (
        <>
          <Transactions notFound={notFound} paymentOrders={paymentOrders} />
          <Paginator
            page={page}
            displayedResults={paymentOrders.length}
            totalResults={totalResults}
            totalPages={totalPages}
            onChange={el => setCurrentPage(el)}
          />
        </>
      ) : null}

      <HasPermissions
        perform="only:admin"
        accessAction={() => (
          <>
            {success && !notFound && !paymentOrders.length ? (
              <EmptyState makeTransfers={handleOpen} />
            ) : null}
          </>
        )}
        restrictedAction={() => (
          <>
            {success && !notFound && !paymentOrders.length ? (
              <EmptyState makeTransfers={null} noAdmin />
            ) : null}
          </>
        )}
      />
      <TransfersModal open={open} onClose={onModalClose} />
      <CardActivation
        userEmail={userEmail}
        open={history.location.pathname === '/activar'}
      />
      {history.location.pathname.includes('multitransfers') ? (
        <MultiTransferById />
      ) : null}
    </Layout>
  );
};

export default ActivityPage;
