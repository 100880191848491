import React from 'react';
import './styles.css'; // Import the CSS file for styling

const AButton = ({onClick, text, disabled}) => {
  return (
    <button className='a-general-button' onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

export default AButton;
