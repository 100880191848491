import { log } from 'loglevel';
import { alert } from '../../helpers/alertsManager';
import { customAxiosInstance } from '../../Services/axiosConfig';
import {
  createAPIKeysRequest,
  deleteAPIKeysRequest,
  getAPIKeysRequest,
  updateAPIKeysRequest
} from '../../Services/publicApiSettings';

const getApiDocsPaths = '/api/dashboard/apidocs';

export const getApiDocs = async () => {
  const docs_response = await customAxiosInstance().get(getApiDocsPaths);
  let docs_data = docs_response.data[0];

  const json_docs = await import('../../assets/api-docs.json');
  docs_data.api_docs = json_docs.api_docs;

  return {documentation: docs_data};
};

export const getApiKeys = async setAPIKeys => {
  try {
    const { data, status } = await getAPIKeysRequest();

    if (status === 200) {
      setAPIKeys(data);
    }

    return;
  } catch (error) {
    log.error('Error fetching API Keys', error);
  }
};

export const createNewApiKeys = async (environment, setAPIKeys, setTokens) => {
  try {
    const { data, status, request } = await createAPIKeysRequest({
      environment
    });

    if (status === 200) {
      let { message: successMessage } = JSON.parse(request.responseText);
      alert('success', `${successMessage}`, 5000, {
        marginLeft: '-10rem',
        width: '40rem'
      });

      setTokens(data[0]);

      await getApiKeys(setAPIKeys);
    }

    return;
  } catch (error) {
    log.error('Error generating API Keys', error);
  }
};

export const deleteApiKeys = async (body, setAPIKeys) => {
  try {
    const { status, request } = await deleteAPIKeysRequest(body);

    if (status === 200) {
      let { message: successMessage } = JSON.parse(request.responseText);
      alert('success', `${successMessage}`, 5000, {
        marginLeft: '-10rem',
        width: '40rem'
      });

      await getApiKeys(setAPIKeys);
    }

    return;
  } catch (error) {
    log.error('Error deleting API Key', error);
  }
};

export const updateApiKeys = async (body, setAPIKeys) => {
  try {
    const { status, request } = await updateAPIKeysRequest(body);

    if (status === 200) {
      let { message: successMessage } = JSON.parse(request.responseText);
      alert('success', `${successMessage}`, 5000, {
        marginLeft: '-10rem',
        width: '40rem'
      });

      await getApiKeys(setAPIKeys);
    }

    return;
  } catch (error) {
    log.error('Error updating API Key', error);
  }
};
