import styled from 'styled-components';

import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const CardTypeTitle = styled.label`
  ${fonts.quickSandSemiBold};
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.22px;
  color: #000000;
  text-align: center;
  margin-bottom: 16px;
`;

export const CardTypeParagraph = styled.p`
  ${fonts.barlowRegular};
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.22px;
  color: #00214e;
  text-align: left;
  margin: 0;
  margin-bottom: 12px;
`;

export const AvatarsContainer = styled.div`
  padding: 30px 0px 0px 0px;
`;

export const CardTypeLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const AvatarIconContainer = styled.div`
  min-width: 50px;
  height: 50px;
  margin-right: 20px;
`;

export const CardTypeInfoParagraph = styled.div`
  width: 100%;
`;
