import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

import Modal from '../../../shared/components/Modal';

export const StyledModal = styled(Modal)`
  padding: 4rem 2.4rem 0;
  max-width: 54rem;

  .deleteTitle {
    text-align: center;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    margin: 2.4rem 0;
  }

  .label {
    ${fonts.barlowRegular};
    font-size: 1.5rem;
    line-height: 2.3rem;
    text-align: center;
    max-width: 44.4rem;
    margin: 4rem 0 1.6rem;
    color: ${colors.body};
  }
`;
