/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { avatarColors } from '../../../../Dashboard4.0/assets/styles/styles';

import {
  CustomAvatar,
  CustomButton,
  CustomLabel,
  CustomModal,
  CustomTitle,
  Helper
} from './LogoutModal.styles';

const LogoutModal = ({
  secondaryText,
  secondsLeft,
  logoutAction,
  stayLoggedInAction,
  open,
  onClose,
  title,
  ...otherProps
}) => {
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      disableBackDropClick={otherProps.disableBackDropClick}
      zIndex={otherProps.zIndex}
    >
      <CustomTitle size="large" text={title} />
      {secondaryText ? <Helper>{secondaryText}</Helper> : null}
      <CustomAvatar size="large" colors={avatarColors[6]}>
        {secondsLeft}
      </CustomAvatar>
      <CustomButton text="Cerrar ahora" size="lg" onClick={logoutAction} />
      <CustomButton
        text="Mantener sesión abierta"
        size="lg"
        variant="overlaySecondary"
        onClick={stayLoggedInAction}
      />
    </CustomModal>
  );
};

LogoutModal.propTypes = {
  logoutAction: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  secondaryText: PropTypes.string,
  secondsLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stayLoggedInAction: PropTypes.func,
  title: PropTypes.string
};

export default LogoutModal;
