import React, { useEffect, useState } from 'react';

import { FileInput } from './styles';
import Link from '../../../shared/components/Link';
import Selfie from '../../pages/Selfie/Selfie';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';

import { documentsCatalogue } from '../../helpers/docs-catalogue';

const Documents = ({ documents, onChangeFile, isSoftwall = false }) => {
  const [uploadedDocs, setUploadedDocuments] = useState([]);
  const [file, setFile] = useState({});

  const handleFileChange = (e, fileData) => {
    const file = e.target;
    file.type = fileData.type;
    file.isSoftwall = isSoftwall;
    setUploadedDocuments([...uploadedDocs, file]);
    setFile(file);
  };

  useEffect(() => {
    if (onChangeFile && isSoftwall) onChangeFile(file);
    else if (onChangeFile) onChangeFile(uploadedDocs);
    // eslint-disable-next-line
  }, [uploadedDocs]);

  const getEndAdornment = element => {
    if (element.tooltip)
      return (
        <Tooltip
          className="tooltip"
          label={element.name}
          text={
            <>
              {element.description}
              {element.customTooltip}
              <Link to={element.link} target="_blank">
                {element.textLink}
              </Link>
            </>
          }
        />
      );
    return <></>;
  };

  return (
    <>
      {documents?.length > 0 ? (
        documents.map((el, ix) => {
          const element = documentsCatalogue.documents[el.kyc_document_type_id];

          if (element.keyword === 'selfie') {
            return (
              <Selfie
                key={`${element.kyc_document_type_id}-${ix}`}
                element={element}
                kycDocumentTypeId={element.kyc_document_type_id}
                keyword={element.keyword}
                name={element.name}
                onChange={e => handleFileChange(e, element)}
              />
            );
          }

          return (
            <FileInput
              key={element.kyc_document_type_id}
              name={element.keyword}
              id={element.kyc_document_type_id}
              label={element.name}
              type="file"
              onChange={e => handleFileChange(e, element)}
              endAdornment={getEndAdornment(element)}
              documentName={element.keyword}
              documentId={element.kyc_document_type_id}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default Documents;
