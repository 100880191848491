import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosAuxConfig';
import { buroNip } from '../utils/constants';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const buroNipService = async ({ uuid, nip }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(buroNip, {
      data: {
        customer: {
          uuid,
          nip
        }
      }
    });
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'kyc-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('kyc-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
