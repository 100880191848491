/* eslint-disable react/require-default-props */
import React, { memo } from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// Styled
import { LabelContainer, MainTitle } from './styles';

const LabelGroup = ({ align, issuedDate, paidDate }) => {
  return (
    <LabelContainer align={align}>
      <MainTitle>{`Enviado : ${issuedDate}`}</MainTitle>
      {paidDate && <MainTitle>{`Pagado : ${paidDate}`}</MainTitle>}
    </LabelContainer>
  );
};

LabelGroup.propTypes = {
  align: PropTypes.string,
  issuedDate: PropTypes.string.isRequired,
  paidDate: PropTypes.string
};

export default memo(LabelGroup);
