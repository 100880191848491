import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { freelancerBasicInfo } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';
import FreelancerCreator from '../domain/freelancer-basic-info-creator';

const setFreelancerInfoService = async data => {
  try {
    const { status, error } = await customAxiosInstance().put(
      freelancerBasicInfo,
      FreelancerCreator(data)
    );
    return extractContentFromResponse({
      status,
      error,
      moduleName: 'set-freelancer-basic-info'
    });
  } catch (error) {
    log.error('set-freelancer-basic-info', error);

    return { error: { message: 'Not able to send request!' } };
  }
};

export default setFreelancerInfoService;
