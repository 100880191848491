/* eslint-disable no-return-await */
import jwtDecode from 'jwt-decode';
import createDataContext from './createDataContext';
import { getAuthToken } from '../Services/authService';
import { customAxiosInstance } from '../Services/axiosConfig';

const userInfoReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'sign_user':
      return { ...state, res: payload, errorMessage: false };
    case 'add_error':
      return { ...state, errorMessage: payload, response: null };
    case 'kind_of_user':
      return { ...state, company_type_id: payload };
    case 'user_validate':
      return { ...state, response: payload };
    case 'userInfo':
      return { ...state, userInfo: payload };
    case 'clear_error':
      return { ...state, errorMessage: null };
    default:
      return state;
  }
};

const SignUp = (dispatch) => async (doc) => await customAxiosInstance()
  .post('/api/dashboard/signup', doc)
  .then((success) => {
    if (success.status && success.status === 201) {
      dispatch({ type: 'sign_user', payload: success.status });
    }
  })
  .catch(() => {
    dispatch({ type: 'add_error', payload: true });
  });

const kindOfUser = (dispatch) => () => {
  const token = getAuthToken();
  const userInfo = jwtDecode(token);
  const { company_type_id } = userInfo.user_claims;

  dispatch({ type: 'kind_of_user', payload: company_type_id });
};

const validateUser = (dispatch) => async (token) => await customAxiosInstance()
  .get(`/api/dashboard/user/validation/${token}`)
  .then((data) => {
    if (data.status && data.status === 200) {
      dispatch({ type: 'user_validate', payload: data });
    }
  })
  .catch((error) => {
    dispatch({ type: 'add_error', payload: true });
  });

const saveUserToken = (dispatch) => (user) => {
  dispatch({ type: 'userInfo', payload: user });
};

const clearErrorMessage = (dispatch) => () => {
  dispatch({ type: 'clear_error' });
};

export const { Provider, Context } = createDataContext(
  userInfoReducer,
  {
    SignUp, validateUser, kindOfUser, saveUserToken, clearErrorMessage,
  },
  {
    res: null,
    errorMessage: null,
    response: null,
    company_type_id: null,
    userInfo: null,
    responseLogin: null,
  },
);
