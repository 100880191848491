import log from 'loglevel';

import { customAxiosInstance } from '../../../Services/axiosConfig';
import { onboardingProgressPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const updateOnboardingProgressService = async ({ newStatus }) => {
  if (!newStatus) {
    log.debug('No status found: update-onboarding-status-service');
    return { error: { message: 'Unable to send request' } };
  }

  try {
    const { data, error, status } = await customAxiosInstance().post(
      onboardingProgressPath,
      {
        onboarding_status: newStatus
      }
    );

    return extractContentFromResponse({
      data,
      error,
      status,
      moduleName: 'update-onboarding-status-service'
    });
  } catch (error) {
    log.debug('update-onboarding-status-service', error);
    return { error: { message: 'Unable to send request' } };
  }
};
