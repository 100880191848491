/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';

import log from 'loglevel';
import PropTypes from 'prop-types';
import { validateBeneficiaries } from './helpers';

import { convertToJSON, processCSVFile } from '../../../../Dashboard4.0/components/CSVFile/helpers';

import Accordion from './Accordion';

const FileLoader = ({ file, setParsedFile, setFileError, dataParsed }) => {
  const [fileFormat, setFileFormat] = useState({ rows: [], cols: [] });
  const [ready, setReady] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState(null);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = evt => {

      const data = processCSVFile(evt)

      const { rows, cols } = convertToJSON(data)

      dataParsed({
        nameFile: file.name,
        beneficiaries: rows.length - 1
      });

      const validatedBeneficiaries = validateBeneficiaries(rows);

      setBeneficiaries(validatedBeneficiaries);

      setFileFormat({ rows: rows.map(row => row.map(el => el.trim())), cols });

      if (validateIsEmptyFile(rows)) {
        setFileError(
          'El documento está vacío. Por favor selecciona otro archivo.'
        );
      }

      if (!getIsColumnsNumberValid(rows)) {
        setFileError(
          'Por favor verifica el formato del documento.'
        );
      }
      setReady(true);
      setParsedFile(data);
    };

    reader.readAsBinaryString(file);

  }, [file, setParsedFile, setFileError]);

  const validateIsEmptyFile = (rows = []) => {
    if (!rows[0] || !rows[1]) return true;
  };

  const getIsColumnsNumberValid = rows => {
    let isColumnsNumberValid = true;

    rows.map(row => {
      if (row.length > 4) isColumnsNumberValid = false;
    });

    return isColumnsNumberValid;
  };

  return (
    <>
      {beneficiaries ? (
        <Accordion
          beneficiaries={beneficiaries}
          rows={fileFormat.rows}
          ready={ready}
        />
      ) : null}
    </>
  );

};

File.propTypes = {
  file: PropTypes.any,
  setParsedFile: PropTypes.func,
  setFileError: PropTypes.func,
  dataParsed: PropTypes.func
};

export default FileLoader;
