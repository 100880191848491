import styled from 'styled-components';

export const Wrapper = styled.div`
  .modalContainer {
    width: fit-content;
    height: fit-content;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .calendly {
    max-width: 100vw;
    width: 100rem;
    height: 75rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
