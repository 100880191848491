import styled from 'styled-components';

const setColors = ({ colors }) => {
  if (!colors) return;
  return `
  background-image: ${colors.linearGradient};
  color: ${colors.fontColor};
  .avatarIconColor path {
    fill: ${colors.fontColor};
  }
  `;
};

const getSize = ({ size }) => {
  const sizeSelector = {
    normal: `
    min-width: 4.8rem;
    width: 4.8rem;
    height: 4.8rem;
    font-size: 1.5rem;
    `,
    large: `
    width: 12rem;
    height: 12rem;
    font-size: 3.7rem;
    `
  };
  return sizeSelector[size || 'normal'];
};

export const AvatarContainer = styled.div`
  border-radius: 50%;
  line-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  ${setColors}
  ${getSize}
`;
