const onboardingActionTypes = {
  SET_ADDRESS: 'ONBOARDING/SET_ADDRESS',
  SET_FREELANCER_STEPS: 'ONBOARDING/SET_FREELANCER_STEPS',
  SET_ENTERPRISE_STEPS: 'ONBOARDING/SET_ENTERPRISE_STEPS',
  SET_FREELANCER_INFO: 'ONBOARDING/SET_FREELANCER_INFO',
  SET_BANK_INFORMATION: 'ONBOARDING/SET_BANK_INFORMATION',
  SET_ENTERPRISE_INFO: 'ONBOARDING/SET_ENTERPRISE_INFO',
  SET_REAL_OWNERSHIP: 'ONBOARDING/SET_REAL_OWNERSHIP',
  SET_STATUS: 'ONBOARDING/SET_STATUS'
};

export { onboardingActionTypes };
