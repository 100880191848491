import { Container, Grid, makeStyles, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import HasPermissions from '../../../role-based-access/components/HasPermissions';
import Button from '../../shared/components/Button/Button';
import { ParagraphTitle } from '../styles';
import * as Yup from 'yup';

const useStyles = makeStyles({
  container: {
    marginBottom: '1rem'
  }
});

const FormComponent = ({ data, handleSubmit, setOpen, open }) => {
  const {
    environment,
    api_key_user_id,
    fingerprint,
    ip_whitelist,
    public_key
  } = data;

  const classes = useStyles();
  const PublicKey = `${environment} public key`;
  const FingerPrint = `${environment} fingerprint`;
  const IPwhiteList = `${environment} IP whitelist`;
  const apiKeyUserId = api_key_user_id;

  const Validation = Yup.object({
    [PublicKey]: Yup.string().required('field required'),
    [FingerPrint]: Yup.string().required('field required'),
    [IPwhiteList]: Yup.string().required('field required')
  });

  return (
    <div>
      <HasPermissions
        perform=""
        restrictedAction={() => <></>}
        accessAction={() => (
          <>
            <Formik
              initialValues={{
                [PublicKey]: public_key,
                [FingerPrint]: fingerprint,
                [IPwhiteList]: ip_whitelist
              }}
              validationSchema={Validation}
              onSubmit={values => {
                handleSubmit({
                  environment: environment,
                  ip_whitelist: values[`${IPwhiteList}`],
                  fingerprint: values[`${FingerPrint}`],
                  public_key: values[`${PublicKey}`],
                  api_key_user_id: apiKeyUserId
                });

                return setOpen(!open);
              }}
            >
              {({ handleChange, handleSubmit, errors, touched, values }) => (
                <>
                  <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={0} direction="column">
                      <ParagraphTitle>{`${environment.toUpperCase()} KEYS`}</ParagraphTitle>
                      <Grid item xs>
                        <TextField
                          label={PublicKey}
                          placeholder={PublicKey}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange(PublicKey)}
                          error={
                            errors[`${PublicKey}`] && touched[`${PublicKey}`]
                          }
                          helperText={errors[`${PublicKey}`]}
                          value={values[`${PublicKey}`]}
                          multiline
                          rows={12}
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          label={FingerPrint}
                          placeholder={FingerPrint}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange(FingerPrint)}
                          error={
                            errors[`${FingerPrint}`] &&
                            touched[`${FingerPrint}`]
                          }
                          helperText={errors[`${FingerPrint}`]}
                          value={values[`${FingerPrint}`]}
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          label={IPwhiteList}
                          placeholder={IPwhiteList}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange(IPwhiteList)}
                          error={
                            errors[`${IPwhiteList}`] &&
                            touched[`${IPwhiteList}`]
                          }
                          helperText={errors[`${IPwhiteList}`]}
                          value={values[`${IPwhiteList}`]}
                        />
                      </Grid>
                      <Grid item xs>
                        <Button
                          style={{ marginTop: 10, marginBottom: 20}}
                          text={`Update API Key`}
                          onClick={handleSubmit}
                          size="md"
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}
            </Formik>
          </>
        )}
      />
    </div>
  );
};

export default FormComponent;
