import React, { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Shared Components
import Modal from '../../../shared/components/Modal/Modal';
import Header from '../../../shared/components/Modal/Header';
import Title from '../../../shared/components/Title';
import Button from '../../../shared/components/Button';
import InfoBar from '../../../shared/components/InfoBar/InfoBar';
import InfoBarItem from '../../../shared/components/InfoBar/InfoBarItem';
import Amount from '../../../shared/components/Amount/Amount';
import Label from '../../../shared/components/Label';

// Styled Components
import {
  CreditPayContainer,
  InfoBarWrapper,
  CreditPayBody,
  StyledSpan,
  ClientTitle
} from './styles';
import { Concept } from '../EntryBalance/EntryBalance.styles';

// Helpers
import { copyToClipboard, parseAmount, splitAmount } from '../../../../helpers';

const initialState = {
  sign: '$',
  integer: '0',
  decimals: '00'
};

const CreditPayModal = ({ open, onClose }) => {
  const [creditLimit, setCreditLimit] = useState({});
  const [amountToPay_, setAmountToPay_] = useState(initialState);

  const creditLine = useSelector(
    state => state.companyReducer.companySummary.credit_line
  );

  const companySummary = useSelector(
    state => state.companyReducer.companySummary
  );
  const companyInformation = useSelector(
    state => state.userInfoReducer.user.company_information
  );

  const amountToPay = companySummary.amount_to_pay;

  const clabe = companySummary?.clabe_code
    ? companySummary.clabe_code
    : 'No disponible';

  const payerName = companyInformation?.payer_name
    ? companyInformation.payer_name
    : 'No disponible';

  const [buttonText, setButtonText] = useState('Copiar datos');

  const changeButtonText = () => {
    setButtonText('Copiados');
    setTimeout(() => {
      setButtonText('Copiar datos');
    }, 1500);
  };

  const copyData = () => {
    let data = `
  Cuenta: ${payerName}
  CLABE: ${clabe}
  Banco: STP
  `;
    copyToClipboard(data);
    changeButtonText();
  };

  const updateState = (value, method) => {
    if (!value) return;
    method(splitAmount(parseAmount(value)));
  };

  useEffect(() => {
    setCreditLimit(splitAmount(parseAmount(creditLine)));
    if (amountToPay) updateState(amountToPay, setAmountToPay_);
  }, [creditLine, amountToPay]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableBackDropClick={false}
      showCloseOnModal
    >
      <CreditPayContainer>
        <Header>
          <Title
            size="large"
            text="Paga tu crédito"
            className="creditPayTitle"
          />
        </Header>
        <CreditPayBody>
          <InfoBarWrapper>
            <InfoBar
              middle={
                <div className="smallContainer">
                  <InfoBarItem
                    data-test="contentStart"
                    className="payInfoBarItem"
                    tooltipContent={payerName}
                  >
                    <Label text="CUENTA" />

                    <ClientTitle>{payerName}</ClientTitle>
                  </InfoBarItem>
                  <InfoBarItem
                    data-test="contentStart"
                    className="payInfoBarItem"
                  >
                    <Label text="MI CLABE" />
                    <Concept text={clabe} />
                  </InfoBarItem>
                  <InfoBarItem
                    data-test="contentStart"
                    className="payInfoBarItem"
                  >
                    <Label text="MI BANCO" />
                    <Concept text="STP" />
                  </InfoBarItem>
                  <Button
                    success={buttonText === 'Copiados'}
                    text={buttonText}
                    onClick={copyData}
                    date-test="copyButton"
                    className="payButtonSmall"
                  />
                </div>
              }
              button={
                <Button
                  success={buttonText === 'Copiados'}
                  text={buttonText}
                  onClick={copyData}
                  date-test="copyButton"
                />
              }
            />
          </InfoBarWrapper>
          <div className="list">
            <div className="listItem">
              <div className="number">1</div>
              <p>
                {`Utilizar la información de arriba para enviar una transferencia
                desde otra cuenta. Recuerda que como banco debes seleccionar
                "STP".`}
              </p>
            </div>
            <div className="listItem">
              <div className="number">2</div>
              <p>
                Enviar la transferencia por el monto de{' '}
                <span>
                  {creditLimit.integer && (
                    <Amount
                      integer={`${amountToPay_.sign}  ${amountToPay_.integer}`}
                      decimals={amountToPay_.decimals}
                    />
                  )}
                </span>
              </p>
            </div>
            <div className="listItem">
              <div className="number">3</div>
              <p>
                Una vez que se reciba el pago, verás reflejada la transacción en
                tu dashboard y en el disponible de tu crédito inmediatamente. Si
                necesitas ayuda, haz{' '}
                <StyledSpan
                  onClick={() => {
                    window.Intercom(
                      'showNewMessage',
                      'Necesito ayuda pagando mi crédito'
                    );
                  }}
                >
                  click aquí.
                </StyledSpan>
              </p>
            </div>
          </div>
          <div className="footer">
            Recuerda que pagar tu crédito en tiempo y forma te ayuda a que en el
            futuro te incrementemos la línea 🚀
          </div>
        </CreditPayBody>
      </CreditPayContainer>
    </Modal>
  );
};

export default CreditPayModal;
