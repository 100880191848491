import { addTagCreditTransactionAction } from '../redux/tag-action';

const createCardTransactionTagHandler = ({
  dispatch,
  createCardTransactionTag
}) => async (cardTransactionTag = {}, saveOnRedux = true) => {
  const { error } = await createCardTransactionTag(cardTransactionTag);

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  if (saveOnRedux)
    dispatch(addTagCreditTransactionAction({ tag: cardTransactionTag }));

  return { isSuccess: true };
};

export { createCardTransactionTagHandler };
