/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import Overlay from '../Overlay';
import CloseIcon from '../../../../Dashboard4.0/assets/images/Close.svg';
import CloseIconWhite from '../../../../Dashboard4.0/assets/images/CloseWhite.svg';

import { ModalContainer } from './Modal.styles';

const Modal = ({
  open,
  onClose,
  children,
  disableBackDropClick,
  ...otherProps
}) => {
  return (
    <>
      {children ? (
        <Overlay
          data-test="overlayComponent"
          open={open}
          className="overlay-container"
          onClose={onClose}
          disableBackDropClick={disableBackDropClick}
          showCrossSign={otherProps.showCrossSign}
          zIndex={otherProps.zIndex}
        >
          <ModalContainer
            data-test="modalContainer"
            className="modalContainer"
            {...otherProps}
          >
            {otherProps.showCloseOnModal ? (
              <span
                data-test="modalClose"
                className="modalClose"
                onClick={onClose}
              >
                <img
                  src={otherProps.closeIconWhite ? CloseIconWhite : CloseIcon}
                  alt="close icon"
                />
              </span>
            ) : null}
            {children}
          </ModalContainer>
        </Overlay>
      ) : null}
    </>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.any
};

export default Modal;
