import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const Container = styled.div`
  margin-top: -6rem;

  .divider {
    position: relative;
    &::before {
      content: '';
      width: 85%;
      height: 1px;
      position: absolute;
      background: ${colors.carbon3};
      bottom: 0;
      right: 0;
    }
  }
`;
