import {
  SET_CURRENT_STEP,
  SET_LAST_STEP,
  SET_IS_UPDATED,
  SET_IS_UPDATE_REQUIRED,
  SET_USER_TYPE,
  PERSIST_FORM_DATA
} from '../reducers/types';

export const cleanUpdatedValues = dispatch => {
  /*
   * dispatch from Redux
   */

  dispatch({ type: SET_IS_UPDATED, payload: false });
  dispatch({ type: SET_IS_UPDATE_REQUIRED, payload: false });
};

export const setCurrentStep = (dispatch, step) => {
  /*
   * dispatch from Redux
   * step|number
   */

  dispatch({ type: SET_CURRENT_STEP, payload: step });
};

export const setLastStep = (dispatch, step) => {
  /*
   * dispatch from Redux
   * step|number
   */

  dispatch({ type: SET_LAST_STEP, payload: step });
};

export const setUserType = (dispatch, type) => {
  /*
   * dispatch from Redux
   * type|string|freelancer|PyME
   */

  dispatch({ type: SET_USER_TYPE, payload: type });
};

export const setUpdatedRequired = (dispatch, isRequired) => {
  /*
   * dispatch from Redux
   * isRequired|boolean
   */

  dispatch({ type: SET_IS_UPDATE_REQUIRED, payload: isRequired });
};

export const setIsUpdated = (dispatch, isUpdated) => {
  /*
   * dispatch from Redux
   * isUpdated|boolean
   */

  dispatch({ type: SET_IS_UPDATED, payload: isUpdated });
};

export const persistFormData = (dispatch, currentStep, formData) => {
  /*
   * dispatch from Redux
   * doc|object
   * currentStep|number
   */

  dispatch({ type: PERSIST_FORM_DATA, payload: { currentStep, formData } });
};

export const updateStep = (dispatch, state, transitionType) => {
  /*
   * dispatch from Redux
   * state|obj
   * transitionType|string|nextStep|prevStep
   */

  const {
    isUpdated,
    isUpdateRequired,
    lastStep,
    currentStep,
    steps,
    userType
  } = state;

  if (
    !steps[currentStep] ||
    !steps[currentStep].transitions ||
    !steps[currentStep].transitions[userType] ||
    steps[currentStep].transitions[userType][transitionType] === null ||
    steps[currentStep].transitions[userType][transitionType] === undefined
  )
    return;

  const userTypeTransitions = steps[currentStep].transitions[userType];
  const stepTransition = userTypeTransitions[transitionType];

  const { isValid, updateLastStep } = validateGoToAnotherStep(
    currentStep,
    dispatch,
    stepTransition,
    isUpdated,
    isUpdateRequired,
    lastStep,
    transitionType
  );

  if (isValid) {
    setCurrentStep(dispatch, stepTransition);
  }

  if (updateLastStep) {
    setLastStep(dispatch, stepTransition);
  }

  cleanUpdatedValues(dispatch);
};

export const validateIsRequired = (dispatch, state) => {
  /*
   * dispatch from redux
   * currentStep|number
   * lastStep|number
   */

  const { currentStep, lastStep } = state;

  if (currentStep === lastStep) {
    setUpdatedRequired(dispatch, true);
    return true;
  }

  setUpdatedRequired(dispatch, false);
  return false;
};

export const validateGoToAnotherStep = (
  currentStep,
  dispatch,
  stepTransition,
  isUpdated,
  isUpdatedRequired,
  lastStep,
  transitionType
) => {
  /*
   * currentStep|number
   * dispatch from redux
   * stepTransition|number
   * lastStep|number
   * isUpdated|boolean
   * isUpdatedRequired|boolean
   * transitionType|string|nextStep|prevStep
   */
  if (transitionType === 'prevStep') {
    return {
      isValid: true,
      updateLastStep: false
    };
  }

  const isValid = validateIsUpdated(isUpdated, isUpdatedRequired);
  const updateLastStep = validateUpdateLastStep(
    stepTransition,
    lastStep,
    transitionType
  );

  return {
    isValid: isValid,
    updateLastStep: updateLastStep
  };
};

const validateIsUpdated = (isUpdated, isUpdatedRequired) => {
  /*
   * isUpdated|boolean
   * isUpdatedRequired|boolean
   */

  // Check if is updated required
  if (!isUpdatedRequired) {
    return true;
  }

  if (isUpdatedRequired && isUpdated) {
    return true;
  }

  return false;
};

const validateUpdateLastStep = (stepTransition, lastStep, transitionType) => {
  /*
   * stepTransition|number
   * lastStep|number
   * transitionType|string|nextStep|prevStep
   */

  // Check if it is necessary to update the last step
  let updateLastStep = false;

  if (transitionType === 'nextStep') {
    if (stepTransition > lastStep) {
      updateLastStep = true;
    }
  }

  return updateLastStep;
};
