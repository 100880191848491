import React from 'react';

import DocumentsManager from '../../components/DocumentsManager';
import OnboardingErrorBoundary from '../OnboardingErrorBoundary/OnboardingErrorBoundary';
import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

const Component = ({
  documents,
  handleFileChange,
  uploadFiles,
  isPrimaryButtonDisabled
}) => {
  return (
    <RegistrationPageLayout
      title="Aún tienes pendiente subir los siguientes documentos:"
      primaryButtonText="Continuar"
      onPrimaryButtonClick={uploadFiles}
      isModalVisible={true}
      showLogout={true}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
    >
      {documents?.length > 0 ? (
        <DocumentsManager
          documents={documents}
          onChangeFile={handleFileChange}
          isSoftwall={true}
        />
      ) : null}
    </RegistrationPageLayout>
  );
};

const MissingDocuments = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="MissingDocuments"
      props={props}
    />
  );
};

export default MissingDocuments;
