//Yup is a JavaScript object schema validator and object parser check documentation for more details https://www.npmjs.com/package/yup
import * as yup from 'yup';
import { patterns_metadata } from '../../../catalogs/patterns';

let fieldRequiredMessage = 'Campo requerido';
const invalidDate = 'Fecha inválida';

export const validationSchema = yup
  .object({
    mercantil_business: yup
      .string('Selecciona un giro mercantil')
      .required(fieldRequiredMessage),
    incorporationDate: yup
      .date()
      .nullable()
      .required(fieldRequiredMessage)
      .min(new Date(1800, 0, 1), invalidDate)
      .max(new Date(), invalidDate),
    phoneNumber: yup
      .string('')
      .length(10, 'Ingresa un número de 10 dígitos')
      .required(fieldRequiredMessage),
    rfc: yup
      .string()
      .length(12, 'Debes ingresar 12 dígitos')
      .matches(patterns_metadata.patternEntityRFC, 'Ingresa un RFC válido')
      .required(fieldRequiredMessage),
    nationality: yup.bool().oneOf([true], 'Field must be checked'),
    serial_number_fiel: yup.string('').required(fieldRequiredMessage)
  })
  .shape();
