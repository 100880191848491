import React from 'react';

import { Document, pdfjs, Page } from 'react-pdf';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import { ReactComponent as Download } from '../../assets/dowload-normal.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 'fit-content',
    height: '90%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto'
  },
  page: {
    overflow: 'scroll',
    margin: '0 auto'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '1.2rem'
  },
  link: {
    textDecoration: 'none',
    color: '#f50057',
    fontSize: '1.4rem',
    fontWeight: 500,
    marginLeft: '0.8rem'
  },
  downloadIcon: {
    '& path': {
      fill: '#f50057'
    }
  },
  linkContainer: {
    padding: '1.2rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  documentContainer: {
    height: '85%',
    overflowY: 'scroll'
  }
}));

const Contract = ({ contract, open, handleClose }) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [numPages, setNumPages] = React.useState(9);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = contract => {
    const { numPages } = contract;
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = offset => setPageNumber(pageNumber + offset);

  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.center}>
            <p>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </p>
            <div className={classes.buttons}>
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Atrás
              </button>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Siguiente
              </button>
            </div>
          </div>
          <div className={classes.documentContainer}>
            <Document file={contract} onLoadSuccess={onDocumentLoadSuccess}>
              <Page className={classes.page} pageNumber={pageNumber} />
            </Document>
          </div>
          <div className={classes.linkContainer}>
            <Download className={classes.downloadIcon} />
            <a href={contract} download="contrato.pdf" className={classes.link}>
              Descargar contrato
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Contract;
