import React from 'react';

const LastSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 10 8"
    >
      <defs>
        <filter id="g2qgpycd4a">
          <feColorMatrix in="SourceGraphic" />
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#g2qgpycd4a)"
        transform="translate(-7 -8)"
      >
        <g fill="#939ca9">
          <path
            d="M.75 8C.547 8 .347 7.92.2 7.76c-.282-.303-.264-.778.04-1.06L3.147 4 .24 1.3C-.064 1.018-.08.543.2.24.481-.065.956-.083 1.26.2l3.5 3.25c.153.142.24.342.24.55 0 .208-.087.408-.24.55L1.26 7.8c-.145.134-.328.2-.51.2"
            transform="matrix(-1 0 0 1 17 8)"
          />
          <path
            d="M5.75 8c-.202 0-.402-.08-.55-.24-.282-.303-.264-.778.04-1.06L8.147 4 5.24 1.3C4.936 1.018 4.92.543 5.2.24c.281-.304.756-.322 1.06-.04l3.5 3.25c.153.142.24.342.24.55 0 .208-.087.408-.24.55L6.26 7.8c-.145.134-.328.2-.51.2"
            transform="matrix(-1 0 0 1 17 8)"
          />
        </g>
      </g>
    </svg>
  );
};

export default LastSvg;
