import {
  CREATE_PASSWORD_PATH,
  PROFILE_COMPLETION_PATH,
  UPLOAD_DOCS_PATH
} from '../constants';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { validateStatus } from '../../users-crud/services/validateStatus';

export const createPasswordService = async body => {
  const response = await customAxiosInstance().put(CREATE_PASSWORD_PATH, body);
  const validatedAnswer = validateStatus(response, 'passwordCreated');
  return validatedAnswer;
};

export const ProfileCompletionService = async body => {
  const response = await customAxiosInstance().put(
    PROFILE_COMPLETION_PATH,
    body
  );
  const validatedAnswer = validateStatus(response, 'profileCompletionResponse');
  return validatedAnswer;
};

export const PostSelfieService = async body => {
  const response = await customAxiosInstance().post(UPLOAD_DOCS_PATH, body);
  const validatedAnswer = validateStatus(response, 'selfieResponse');
  return validatedAnswer;
};

export const PostINEService = async body => {
  const response = await customAxiosInstance().post(UPLOAD_DOCS_PATH, body);
  const validatedAnswer = validateStatus(response, 'ineResponse');
  return validatedAnswer;
};
