import { getInvoicesService } from '../Services/Invoices';

import { GET_INVOICES, SET_INVOICES_ERRORS } from '../reducers/types';

import { dummyInvoices } from '../micro-frontends/invoices/mocks/dummyInvoices';

import { setAction } from '.';

export const getInvoicesSchema = (
  dispatch,
  service,
  pageNumber,
  query,
  type
) => {
  const page = pageNumber || 1;
  let params = page;
  if (query) params = { page, query };
  if (type) params = { page, type };

  // - // { THIS IS THE APPROPRIATE CODEBLOCK, COMMENTED OUT FOR DEMONSTRATION PURPOSES}
  // service(params)
  //   .then(res => {
  //     if (res && res.status === 200) {
  //       let payloadRes = {
  //         res: { ...res.data[0], invoices: res.data[0].invoices },
  //         success: true,
  //         error: false
  //       };
  //       return setAction(res, GET_INVOICES, dispatch, payloadRes);
  //     }
  //     return dispatch({ type: SET_INVOICES_ERRORS, payload: true });
  //   })
  //   .catch(e => dispatch({ type: SET_INVOICES_ERRORS, payload: true }));

  //   {DEMO CODE BLOCK}
  let payloadRes = {
    res: {
      current_page: 1,
      total_results: 66,
      total_pages: 7,
      invoices: dummyInvoices
    },
    success: true,
    error: false
  };
  setAction({ data: [0], status: 200 }, GET_INVOICES, dispatch, payloadRes);
};

export const getInvoices = (dispatch, pageNumber) => {
  getInvoicesSchema(dispatch, getInvoicesService, pageNumber);
};
