/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { LabelContainer } from './LabelInfo.style';

const LabelInfo = ({ text, border, className, onClick }) => {
  return (
    <LabelContainer
      border={border}
      className={className}
      onClick={onClick}
      data-test="labelInfo"
    >
      {text}
    </LabelContainer>
  );
};

LabelInfo.propTypes = {
  text: PropTypes.string.isRequired,
  border: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default LabelInfo;
