import { customAxiosInstance } from './axiosConfig';

const publicApiSettingsPath = `/api/dashboard/public-api-settings`;

export const updateAPIKeysRequest = async body => {
  return customAxiosInstance().put(publicApiSettingsPath, body);
};

export const getAPIKeysRequest = async () => {
  return customAxiosInstance().get(publicApiSettingsPath);
};

export const createAPIKeysRequest = async body => {
  return customAxiosInstance().post(publicApiSettingsPath, body);
};

export const deleteAPIKeysRequest = async body => {
  return customAxiosInstance().delete(publicApiSettingsPath, {
    data: {
      api_key_user_id: body.api_key_user_id,
      environment: body.environment
    }
  });
};
