import React from 'react';
import PropTypes from 'prop-types';
import { Point, LineContainer, FocusedPoint } from './EntryBalance.styles';
import focusedPoint from '../../../../Dashboard4.0/assets/images/line-dot-focused.svg';
import Icon from '../../../shared/components/Icon/Icon';

const Line = () => {
  return (
    <LineContainer>
      <Point />
      <Point />
      <Point />
    </LineContainer>
  );
};

const Focused = () => {
  return (
    <LineContainer>
      <Point />
      <FocusedPoint>
        <img src={focusedPoint} alt="focused" />
      </FocusedPoint>
      <Point />
      <Icon icon="arrow" className="arrow" />
    </LineContainer>
  );
};

const types = {
  linear: <Line />,
  focused: <Focused />
};

const LineComplements = ({ type }) => {
  return types[type];
};

LineComplements.propTypes = {
  type: PropTypes.string.isRequired
};

export default LineComplements;
