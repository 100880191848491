export const UserCreator = ({ data: { user_id, email, name, spend_limit } }) =>
  Object.freeze({
    userId: user_id,
    email: email,
    name: name,
    spendLimit: parseFloat(spend_limit).toFixed(2),
    optionLabel: `${name} (${email})`
  });

export const UsersCreator = ({ data: usersData }) =>
  usersData.map(userData => UserCreator({ data: userData }));
