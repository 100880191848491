import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  text-align: center;
  margin: 2rem 0 3rem;
`;

export const ListItem = styled.li`
  font-size: 14px;
  margin-bottom: 16px;
`;
