import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { StatusText } from './Styles';

// TODO: Validate status 'src/catalogs/spanish.json'
const StatusIcon = ({ status }) => {
  const statusSelector = {
    approved: 'Aprobada',
    rejected: 'Rechazada',
    pending: 'Procesando',
    success: 'Completo'
  };

  return (
    status && (
      <StatusText data-test="statusIcon" status={status}>
        {statusSelector[status]}
      </StatusText>
    )
  );
};

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired
};

export default memo(StatusIcon);
