/* eslint-disable no-unneeded-ternary */

import React, { useEffect } from 'react';

// Auxiliar libraries
import { useFormik } from 'formik';

// Components
import TextField from '../../../shared/components/TextField';
import TextFieldAmount from '../../../shared/components/TextFieldAmount';
import Select from '../../../shared/components/EditableSelect';
import Button from '../../../shared/components/Button/Button';
import RadioButton from '../../components/radio-button';
import { Description as ModalDescription } from '../../../shared/components/ModalInformation/ModalInformation.styles';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import TagList from '../../../tags/TagList/TagList';

// Others
import { validationSchema } from './validation-schema-details';
import { useSelector } from 'react-redux';
import { VIRTUAL, PHYSICAL } from '../../constants';
import { openIntercom } from './response-handler';
import {
  isAdminSelector,
  isSuperAdminSelector,
  selectAvailablePhysicalCards,
  selectAvailableVirtualCards,
  selectGetOwnerSpendLimit,
  selectUsers
} from '../../redux/cards-selectors';
import {
  userIdSelector,
  userRoleIDSelector
} from '../../../users-crud/redux/crud-selectors';

const Form = ({
  handleSaveTag,
  handleDeleteTag,
  tags,
  handleSubmit,
  handleModalClose
}) => {
  const userId = useSelector(userIdSelector);
  const userRoleId = useSelector(userRoleIDSelector);
  const usersOptions = useSelector(selectUsers);
  const virtualCreditCards = useSelector(selectAvailableVirtualCards);
  const physicalCreditCards = useSelector(selectAvailablePhysicalCards);
  const getCardOwnerSpendLimit = useSelector(selectGetOwnerSpendLimit);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);

  const formik = useFormik({
    initialValues: {
      creditCardName: '',
      cardLimit: '',
      cardType: '',
      cardPin: '',
      confirmCardPin: '',
      userRoleId,
      requestingCardForSelf: false,
      isSuperAdmin: isSuperAdmin
    },
    validationSchema: validationSchema
  });

  const handleChange = e => {
    formik.handleChange(e);
  };

  const userSpendingLimitArticle =
    process.env.REACT_APP_USER_SPENDING_LIMITS_ARTICLE;

  const manageSubmit = () => {
    handleSubmit(formik.values);
  };

  const hasNoMoreVirtualCards =
    Number(virtualCreditCards) === 0 && formik.values.cardType === VIRTUAL;
  const hasNoMorePhysicalCards =
    Number(physicalCreditCards) === 0 && formik.values.cardType === PHYSICAL;

  useEffect(() => {
    formik.setFieldTouched();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <RadioButton
        name="cardType"
        className="radioButtonsContainer"
        className2="radioButtonsLabel"
        className3="radioOptionsContainer"
        label="Elige el tipo de tarjeta:"
        onChange={handleChange}
        optionA={{ label: 'Virtual', value: VIRTUAL }}
        optionB={{ label: 'Física', value: PHYSICAL }}
        disabledOA={
          process.env.REACT_APP_DEACTIVATE_REQUEST_DIGITAL_CARDS === 'true'
        }
        tooltipOA="Si deseas otra tarjeta, por favor pídenos a través del chat o a ayuda@delt.ai."
        error={formik.errors.cardType && formik.touched.cardType}
        helperText={formik.touched.cardType && formik.errors.cardType}
        value={formik.values.cardType}
      />
      <TextField
        data-test="creditCardName"
        className="formField"
        label="Nombre de la tarjeta"
        name="creditCardName"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        onFocus={() => formik.setFieldTouched('creditCardName')}
        error={formik.errors.creditCardName && formik.touched.creditCardName}
        helperText={
          formik.touched.creditCardName && formik.errors.creditCardName
        }
        value={formik.values.creditCardName}
      />

      <Select
        label="Usuario de la tarjeta"
        name="userId"
        className="formField"
        onChange={cardOwnerId => {
          const cardOwnerSpendLimit = getCardOwnerSpendLimit(cardOwnerId);
          formik.setFieldValue('cardOwnerId', cardOwnerId, true);
          formik.setFieldValue(
            'cardOwnerSpendLimit',
            cardOwnerSpendLimit,
            true
          );
          formik.setFieldValue(
            'requestingCardForSelf',
            Number(cardOwnerId) === Number(userId) ? true : false
          );
        }}
        error={formik.errors.cardOwnerId && formik.touched.cardOwnerId}
        helperText={formik.touched.cardOwnerId && formik.errors.cardOwnerId}
        options={usersOptions.map(({ userId, optionLabel }) => ({
          label: optionLabel,
          value: userId
        }))}
        value={formik.values.cardOwnerId}
      />

      <TextFieldAmount
        name="cardLimit"
        className="formField"
        label="Límite de gasto"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        onFocus={() => formik.setFieldTouched('cardLimit')}
        error={formik.errors.cardLimit && formik.touched.cardLimit}
        helperText={formik.touched.cardLimit && formik.errors.cardLimit}
        value={formik.values.cardLimit}
        endAdornment={
          <Tooltip
            label="Límites de gasto"
            text={
              <div>
                Para más información, lee este artículo:{' '}
                <a
                  href={userSpendingLimitArticle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ¿Cómo funcionan?
                </a>
              </div>
            }
            className="tooltip"
          />
        }
      />

      {formik.values.cardType === PHYSICAL &&
        formik.values.requestingCardForSelf === true &&
        isSuperAdmin && (
          <>
            <p
              style={{
                fontSize: '12px',
                fontWeight: '600'
              }}
            >
              No olvides guardar tu NIP. Ya no se podrá cambiar después de
              asignarlo.
            </p>
            <TextField
              data-test="cardPin"
              className="formField"
              label="NIP de tarjeta"
              name="cardPin"
              type="text"
              maxLength="4"
              onChange={handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => formik.setFieldTouched('cardPin')}
              error={formik.errors.cardPin && formik.touched.cardPin}
              helperText={formik.touched.cardPin && formik.errors.cardPin}
              value={formik.values.cardPin}
            />
            <TextField
              data-test="confirmCardPin"
              className="formField"
              label="Confirmar NIP de tarjeta"
              name="confirmCardPin"
              type="text"
              maxLength="4"
              onChange={handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => formik.setFieldTouched('confirmCardPin')}
              error={
                formik.errors.confirmCardPin && formik.touched.confirmCardPin
              }
              helperText={
                formik.touched.confirmCardPin && formik.errors.confirmCardPin
              }
              value={formik.values.confirmCardPin}
            />
          </>
        )}

      <TagList
        handleSaveTag={handleSaveTag}
        handleDeleteTag={handleDeleteTag}
        tags={tags}
        hasPermissions={isSuperAdmin || isAdmin}
      />
      <br />

      {hasNoMoreVirtualCards || hasNoMorePhysicalCards ? (
        <ModalDescription>
          Llegaste al límite de tarjetas{' '}
          {hasNoMoreVirtualCards ? 'virtuales' : 'físicas'} para una cuenta.
          ¿Necesitas más?{' '}
          <u
            onClick={() =>
              openIntercom(
                formik.values.cardType === 'physical' ? 'físicas' : 'virtuales'
              )
            }
          >
            Búscanos aquí.
          </u>
        </ModalDescription>
      ) : (
        <>
          <Button
            className="formButton"
            size="lg"
            text={'Continuar'}
            onClick={() => manageSubmit()}
            disabled={!formik.isValid}
          />
          <Button
            className="formButton"
            size="lg"
            text={'Cancelar'}
            onClick={() => handleModalClose()}
            variant="overlaySecondary"
          />
        </>
      )}
    </div>
  );
};

export default Form;
