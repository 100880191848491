import styled, { css } from 'styled-components';
import { colors, fonts } from '../../assets/styles/styles';

const classes = css`
  .avatarIconColor {
    path {
      fill: ${colors.success};
    }
  }
  .button {
    border-radius: 35.5px;
    width: 100%;
    height: 6.1rem;
    margin-top: 4rem;

    @media (min-width: 769px) {
      margin-top: 9rem;
    }
  }
  .label {
    font-size: 1.55rem;
    letter-spacing: 0.19px;
    margin-top: 0.5rem;
  }
  .labelsContainer {
    display: flex;
    flex-direction: column;
    margin-left: 7rem;
    @media (min-width: 960px) {
      margin-left: 0;
      align-items: flex-end;
    }
  }
  .confirmButton {
    width: 38.5rem;
    height: 6.1rem;
    border-radius: 35.5px;
  }
  .alignRight {
    margin-right: 3.5rem;
  }
  .progressBar {
    width: 44.4rem;
    height: 6.1rem;
    border-radius: 35.5px;
  }
  .progressBar > div {
    height: 6.1rem;
  }

  .alignEnd {
    text-align: end;
  }

  .entryDetails {
    width: 100%;
    label {
      margin-top: -0.5rem;
    }
  }
`;

export const Container = styled.form`
  margin: 2.4rem 2rem;
  ${classes}

  @media (min-width: 769px) {
    margin: 4.8rem 4.8rem 4rem;
    max-width: 54rem;

    .transferForm {
      grid-template-columns: 7fr 5fr;
      grid-template-rows: 1fr;
      column-gap: 1.6rem;
    }
  }

  @media (max-width: 769px) {
    padding-bottom: 6rem;
    overflow: scroll;
  }
`;

export const SubContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  grid-template-rows: 1fr 1fr;
`;

export const TransactionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow: auto;

  @media (min-width: 769px) {
    width: 61.4rem;
  }

  .title {
    color: ${colors.carbon};
    margin-bottom: 4.2rem;
  }
`;

export const HeadContainer = styled.div`
  width: 100%;
  padding: 0 7.2rem 2.6rem 7.2rem;
  ${classes}

  @media (max-width: 768px) {
    padding: 0 1.9rem 1.4rem 1.9rem;
  }
`;

export const BeneficiaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 4rem;
  @media (min-width: 960px) {
    flex-direction: row;
  }

  .headerContentContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ConfirmLabel = styled.div`
  display: grid;
  grid-template-columns: 4.1fr 1fr;
  font-size: 1.55rem;
  color: ${colors.carbon};
  margin-top: 1rem;
  font-weight: normal;
  letter-spacing: 0.91px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ConfirmButtonContainer = styled.div`
  margin: 4.8rem 0 1.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${classes}

  @media (max-width: 768px) {
    padding: 0 1.6rem;

    .confirmButton {
      width: 100%;
    }
  }
`;

export const SweetButton = styled.button`
  background: none;
  cursor: pointer;
  height: 6rem;
  ${fonts.quickSandSemiBold};
  border: none;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 3.53;
  letter-spacing: 0.21px;
  text-align: center;
  color: ${colors.carbon};
  margin-top: 0.8rem;
  outline: none;
`;

const getProgressBarColor = ({ progress }) => {
  let color = '#374b61';

  if (progress > 27 && progress < 75) {
    return `
    color: #374b61;
    `;
  }

  if (progress > 75) color = 'white';

  return `color: ${color}`;
};

export const ProgressText = styled.span`
  font-family: Quicksand;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 3.53;
  letter-spacing: 0.21px;
  ${getProgressBarColor}
`;

export const TagsContainer = styled.div`
  margin-left: 7.8rem;
`;

export const TransferLimit = styled.p`
  ${fonts.barlowRegular}
  font-size: 1.7rem;
  margin-top: 0px;

  .transferLimit {
    color: ${colors.success};
    margin-right: 1.2rem;
  }

  .tooltip {
    top: 7rem;
    right: -3rem;
    left: auto;
    z-index: 10;
  }
`;
