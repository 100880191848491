import React, { useEffect } from 'react';

import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Styled components
import { DashboardContainer } from './Dashboard.styles';

// Components
import Account from '../micro-frontends/account/pages/Account';
import Activity from '../micro-frontends/activity/pages/Activity';
import ApiDocs from '../micro-frontends/api-docs/page/ApiDocs';
import Cards from '../micro-frontends/cards/index';
import Invoices from '../micro-frontends/invoices/index';
import Credit from '../micro-frontends/credit';
import SideNav from '../micro-frontends/shared/components/SideNav/SideNav';
import UsersCrud from '../micro-frontends/users-crud/pages/UsersCrud';

import { checkAPIStatus } from '../actionCreators/apiStatusAction';
import { detailsLink } from '../micro-frontends/activity/components/ProofOfPayments/MultiTransferModal/constants';

// TODO should be switch here for routing. This should be web container for microfrontends
const DashboardView = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    checkAPIStatus(dispatch);
    //eslint-disable-next-line
  }, [match.path]);

  return (
    <DashboardContainer>
      <SideNav isMobileMenu={false} />
      <Route path="/activar" exact component={Activity} />
      <Route path="/activity" exact component={Activity} />
      <Route path={detailsLink()} component={Activity} />
      <Route path="/cards" exact component={Cards} />
      <Route path="/invoices" exact component={Invoices} />
      <Route path="/credit" exact component={Credit} />
      <Route path="/account" exact component={Account} />
      <Route path="/team" exact component={UsersCrud} />
      <Route path="/apidocs" exact component={ApiDocs} />
    </DashboardContainer>
  );
};

export default DashboardView;
