import styled from 'styled-components';

import { fonts, colors } from '../../../../Dashboard4.0/assets/styles/styles';

const setBorder = ({ border }) => {
  if (border) {
    return `
        border-radius: 0.2rem;
        border: solid 0.8px rgba(23, 144, 207, 0.5);
        background-color: rgba(23, 144, 207, 0.05);
        padding: 0.1rem 0.5rem;
        margin: 0 0.4rem
    `;
  }
};

export const LabelContainer = styled.span`
  ${fonts.quickSandSemiBold}
  height: 0.9rem;
  font-size: 1.35rem;
  font-weight: 600;
  letter-spacing: 0.17px;
  color: ${colors.carbon};
  ${setBorder}
`;
