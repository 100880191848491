import { customAxiosInstance } from '../../../Services/axiosConfig';
import {
  CreateUserRequest,
  UpdateUserRequest
} from '../object-creators/object-creators';
import { validateStatus } from './validateStatus';

const basePath = '/api/dashboard/team';
const usersBasePath = '/api/dashboard/user';
const getUsers = `${basePath}/company/user`;
const getUserByIdPath = `${usersBasePath}/?user_id=`;
const getUserStatusPath = `${usersBasePath}/status?user_id=`;
const searchUserByQueryPath = `${usersBasePath}/search?query=`;
const updateUserDocsPath = `${usersBasePath}/documents?user_id=`;
const resendInvitationPath = `/api/dashboard/resend-invitation `;

export const approveUserService = async userId => {
  const path = `${basePath}/user/${userId}/approve`;
  const response = await customAxiosInstance().get(path);
  const validatedAnswer = validateStatus(response, 'approvedUser');
  return validatedAnswer;
};

export const approveProfileService = async userId => {
  const path = `${basePath}/user/${userId}/profile-completion/approve`;
  const response = await customAxiosInstance().get(path);
  const validatedAnswer = validateStatus(response, 'approvedProfile');
  return validatedAnswer;
};

export const approveUserUpdateRequestService = async requestId => {
  const path = `${basePath}/update-requests/${requestId}/approve`;
  const response = await customAxiosInstance().get(path);
  const validatedAnswer = validateStatus(response, 'approvedUpdatedRequested');
  return validatedAnswer;
};

export const getUsersService = async page => {
  const path = `${getUsers}?page=${page || 1}`;
  const response = await customAxiosInstance().get(path);
  const validatedAnswer = validateStatus(response, 'users');
  return validatedAnswer;
};

export const getUserByIdService = async id => {
  const path = `${getUserByIdPath}${id}`;
  const response = await customAxiosInstance().get(path);
  const validatedAnswer = validateStatus(response, 'user');
  return validatedAnswer;
};

export const getUserStatusService = async id => {
  const path = `${getUserStatusPath}${id}`;
  const response = await customAxiosInstance().get(path);
  const validatedAnswer = validateStatus(response, 'userStatus');
  return validatedAnswer;
};

export const getUsersByQueryService = async (page, query) => {
  const path = `${searchUserByQueryPath}${query}?page=${page || 1}`;
  const response = await customAxiosInstance().get(path);
  const validatedAnswer = validateStatus(response, 'users');
  return validatedAnswer;
};

export const createUserService = async body => {
  const requestObject = CreateUserRequest(body);
  const response = await customAxiosInstance().post(
    usersBasePath,
    requestObject
  );
  const validatedAnswer = validateStatus(response, 'userCreated');
  return validatedAnswer;
};

export const deleteUserService = async userId => {
  const path = `${basePath}/user/${userId}/delete`;
  const response = await customAxiosInstance().delete(path);
  const validatedAnswer = validateStatus(response, 'deletedUser');
  return validatedAnswer;
};

export const updateUserService = async body => {
  const data = UpdateUserRequest(body);
  const path = `${basePath}/user`;
  const response = await customAxiosInstance().put(path, data);
  const validatedAnswer = validateStatus(response, 'updatedUser');
  return validatedAnswer;
};

export const postUserDocumentsService = async (document, userId) => {
  const path = `${updateUserDocsPath}${userId}`;
  const response = await customAxiosInstance().put(path, document);
  const validatedAnswer = validateStatus(response, 'document');
  return validatedAnswer;
};

export const resendEmailInvitation = async body => {
  const response = await customAxiosInstance().post(resendInvitationPath, body);
  const validatedAnswer = validateStatus(response, 'resendedEmailActivation');
  return validatedAnswer;
};

// Decline requests

export const declineUserUpdateRequestService = async requestId => {
  const path = `${basePath}/update-requests/${requestId}/decline`;
  const response = await customAxiosInstance().get(path);
  const validatedAnswer = validateStatus(response, 'declinedUpdatedRequested');
  return validatedAnswer;
};

export const declineUserDeleteRequestService = async requestId => {
  const path = `${basePath}/delete-requests/${requestId}/decline`;
  const response = await customAxiosInstance().get(path);
  const validatedAnswer = validateStatus(
    response,
    'declineUserDeleteRequestService'
  );
  return validatedAnswer;
};
