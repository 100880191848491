import { CardCreator } from '../domain/card-data';

const getCardDetailsHandler = ({ getCardDetails }) => async ({
  email,
  password,
  cardId
}) => {
  const { error, data: cardData } = await getCardDetails({
    email,
    password,
    cardId
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  const card = CardCreator({ data: cardData });

  return {
    isSuccess: true,
    card
  };
};

export { getCardDetailsHandler };
