import * as yup from 'yup';

import {
  isClabeInvalid,
  removeBlankSpaces
} from '../../../../helpers/clabeValidator';

const invalidCardMessage = 'Número de tarjeta inválido';
const requiredFieldMessage = 'Campo requerido';
const VISA = /^4\d{3} ?\d{4} ?\d{4} ?\d{4}$/;
const MASTERCARD = /^5[1-5]\d{2} ?\d{4} ?\d{4} ?\d{4}$/;

export const validationSchema = yup.object({
  bank: yup
    .mixed()
    .test('bank validator', 'Banco no operante', function (bank) {
      const { path, createError } = this;

      if (!bank)
        return createError({
          path,
          message: `Banco: ${requiredFieldMessage}`
        });

      if (bank === 'No encontrado') {
        return createError({
          path,
          message: 'Institución bancaria no operante'
        });
      }
      return true;
    }),
  name: yup
    .string()
    .max(40, 'Máximo 40 caracteres')
    .required(requiredFieldMessage),
  alias: yup
    .string()
    .max(36, 'Máximo 36 caracteres')
    .required(requiredFieldMessage),
  rfc: yup
      .string()
      .max(13, 'Máximo 13 caracteres'),
  email: yup
    .string()
    .max(60, 'Máximo 60 caracteres')
    .email('Correo electrónico inválido')
    .required(requiredFieldMessage),
  type: yup.string(),
  account_code: yup.string().when('type', {
    is: 'clabe_code',
    then: yup
      .string()
      .test('clabe validator', 'Clabe inválida', function (accountCode) {
        const { path, createError } = this;
        if (!accountCode) {
          return createError({ path, message: requiredFieldMessage });
        }
        let noSpaceClabe = removeBlankSpaces(accountCode);
        const isInvalid = isClabeInvalid(noSpaceClabe);
        if (isInvalid) return createError({ path, message: isInvalid });
        return true;
      }),
    otherwise: yup
      .string()
      .test(
        'debit card number validator',
        invalidCardMessage,
        function (accountCode) {
          const { path, createError } = this;
          if (!accountCode) {
            return createError({ path, message: requiredFieldMessage });
          }
          let codeNoSpaces = removeBlankSpaces(accountCode);

          if (codeNoSpaces.length < 16) {
            return createError({
              path,
              message: 'El número de tarjeta debe ser de 16 dígitos'
            });
          } if (
            !codeNoSpaces.match(VISA) &&
            !codeNoSpaces.match(MASTERCARD)
          ) {
            return createError({ path, message: invalidCardMessage });
          }
          return {};
        }
      )
  })
});
