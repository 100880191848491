import React from 'react';
import waiting from './waiting.gif';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

function WaitingLogo() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src={waiting}
        style={{ width: '120px', margin: 'auto', display: 'block' }}
        alt="Loading..."
      />
    </div>
  );
}

export default WaitingLogo;
