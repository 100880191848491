import React, { useContext, useEffect } from 'react';


import { useFormik } from 'formik';

import OnboardingLayout from '../../components/OnboardingLayout/OnboardingLayout';
import Select from '../../../shared/components/EditableSelect';

import Datepicker from '../../../sub-account-onboarding/components/Datepicker/Datepicker';
import { FileInput } from '../documents/styles';
import Paragraph from '../../../shared/components/Paragraph/Paragraph';
import OnboardingErrorBoundary from '../OnboardingErrorBoundary/OnboardingErrorBoundary';
import SearchableSelect from '../../components/SearchableSelect/SearchableSelect';
import TextField from '../../../shared/components/TextField/TextField';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';

import catalogue_mercantile_business from '../../../../catalogs/catalog_mercantile_business.json';
import { fonts } from '../../../../Dashboard4.0/assets/styles/styles';
import nationalities from '../../../../catalogs/nationalities.json';
import setInputProps from '../../helpers/set-input-props';
import { validationSchema } from './validation-schema';
import { proofOfPowersId, proofOfPowersName } from '../../constants';
import { convertDate } from '../../helpers/datepicker-helpers';

const Component = ({
  activeStep,
  goToNextStep,
  steps,
  storedData,
  user,
  userId,
  userName
}) => {
  const attorneyInLaw = `${user.name} ${user.surname_1}`;
  const initialValues = storedData;
  initialValues.userId = userId;
  initialValues.rfcCompany = user.company_information.payer_rfc_code;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema
  });

  const handleFileChange = async e => {
    if (!e.target.value) return;

    formik.setFieldValue('proofOfPowers', e.target.value);
  };

  const handleGoNext = () => {
    const data = formik.values;
    data.incorporationDate = convertDate(
      formik.values.incorporationDate,
      '/',
      '-'
    );
    goToNextStep(data);
  };

  const toInputUppercase = e => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };


  return (
    <OnboardingLayout
      title={`Datos de ${userName}`}
      description="Completa la información para continuar."
      onPrimaryButtonClick={handleGoNext}
      primaryButtonText="Continuar"
      isPrimaryButtonDisabled={!formik.isValid || !formik.dirty}
      isModalVisible
      steps={steps}
      activeStep={activeStep}
    >
      <Select
        {...setInputProps(
          formik,
          keyword => {
            formik.setFieldValue('mercantilBusiness', keyword);
          },
          'Giro mercantil',
          'mercantilBusiness'
        )}
        options={catalogue_mercantile_business.map(({ id, keyword }) => {
          return {
            label: keyword,
            value: keyword
          };
        })}
        value={formik.values['mercantilBusiness']}
      />

      <Datepicker
        name="incorporationDate"
        label="Fecha de constitución (DD/MM/AAAA e.g. 21/12/1980)"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        hasValue={formik.values.incorporationDate}
        error={
          formik.errors.incorporationDate && formik.touched.incorporationDate
        }
        helperText={formik.errors.incorporationDate}
      />
     <TextField
        {...setInputProps(formik, formik.handleChange, 'RFC', 'rfcCompany', 'text')}
        onInput={toInputUppercase}
        value={formik.values.rfcCompany}
        disabled={true}
      />
      <SearchableSelect
        name="companyNationality"
        label="Nacionalidad"
        options={nationalities.nationalities}
        formik={formik}
      />

      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'Número de serie de Firma Electrónica Avanzada',
          'companySerialNumberFiel',
          'text'
        )}
        endAdornment={
          <Tooltip
            className="endAdornmentTooltip"
            label="Número de serie de la Firma Electrónica Avanzada"
            text="Son 20 dígitos que puedes encontrar en tu Constancia de Firma Electrónica Avanzada ó en el comprobante de Certificado de Sello Digital."
          />
        }
      />
      <Paragraph
        text={`Datos de ${attorneyInLaw}`}
        fontStyle={fonts.quickSandSemiBold}
        textAlign="center"
        size="2.4rem"
        lineHeight="3rem"
      />
      <Paragraph
        text="Completa la información de apoderado para continuar."
        fontStyle={fonts.barlowRegular}
        textAlign="center"
        size="1.55rem"
        lineHeight="3rem"
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'CURP',
          'personalCurp',
          'text'
        )}
        onInput={toInputUppercase}
      />
      <TextField
        {...setInputProps(
          formik,
          formik.handleChange,
          'RFC',
          'personalRfc',
          'text'
        )}
        onInput={toInputUppercase}
      />
      <Paragraph
        text={
          <>
            Si el nombre que estás registrando en la cuenta no está en <br></br>{' '}
            el Acta Constitutiva como apoderado, sube el instrumento <br></br>{' '}
            público donde conste el nombramiento del apoderado.
          </>
        }
        fontStyle={fonts.barlowRegular}
        textAlign="center"
        size="1.55rem"
        lineHeight="1.75rem"
      />

      <FileInput
        label="Subir instrumento público que contiene el nombramiento"
        type="file"
        name="proofOfPowers"
        documentName={proofOfPowersName}
        documentId={proofOfPowersId}
        onChange={handleFileChange}
        endAdornment={
          <Tooltip
            className="endAdornmentTooltip"
            label="Constancia de poderes"
            text="La constancia de poderes es el instrumento público que te dieron en la notaría o un corredor público en el que fuiste nombrado apoderado o administrador único de la PYME, o te otorgaron facultades como el presidente del Consejo de Administración."
          />
        }
      />
    </OnboardingLayout>
  );
};

const EnterpriseBasicInfo = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="EnterpriseBasicInfo"
      props={props}
    />
  );
};

export default EnterpriseBasicInfo;
