import styled from 'styled-components';
import { fonts, colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const Form = styled.div`
  font-size: 1.4rem;
  ${fonts.quickSandMedium}
  color: ${colors.body};
  min-height: 5rem;

  input[type='radio'] {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid black;
    outline: none;
    cursor: pointer;
    &:checked {
      background: ${colors.deltai};
      border-color: ${colors.deltai};
    }
  }

  h2 {
    font-size: 2.4rem;
    text-align: center;
  }

  .formQuestion {
    display: flex;
    align-items: center;

    input {
      margin: 0;
    }

    label {
      margin: 0 1.2rem;
    }
  }

  .formQuestionVertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      margin-bottom: 1.2rem;
    }

    input {
      margin-right: 1.2rem;
    }
  }
`;
