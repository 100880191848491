import { customAxiosInstance } from '../../../Services/axiosConfig';
import log from 'loglevel';
import { updateCardPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const updateCardService = async ({
  creditCardId,
  cardAdminRequestId,
  spendLimit,
  isActive,
  isBlocked,
  creditCardName,
  userId,
  password,
  email
}) => {
  try {
    const { data, status, error } = await customAxiosInstance().put(
      `${updateCardPath}${cardAdminRequestId || creditCardId}`,
      {
        credit_card_id: creditCardId,
        spend_limit: spendLimit,
        // eslint-disable-next-line no-nested-ternary
        card_status_id: isActive ? 1 : 3,
        credit_card_name: creditCardName,
        user_id: userId,
        password,
        email
      }
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'update-card-service'
    });
  } catch (error) {
    log.error('update-card-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
