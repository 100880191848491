import React from 'react';
import PropTypes from 'prop-types';

import { AddOption } from './styles';

const OptionNotFound = ({ selectProps }) => {
  const { typed } = selectProps;
  return <AddOption>{typed} no encontrado</AddOption>;
};

OptionNotFound.propTypes = {
  selectProps: PropTypes.object.isRequired
};

export default OptionNotFound;
