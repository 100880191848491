import React, { memo } from 'react';

import AccountInput from '../AccountInput';
import TextField from '../../../shared/components/TextField';
import Button from '../../../shared/components/Button';

import { FormContainer } from './AddBeneficiaryForm.styles';

const AddBeneficiaryForm = ({
  form,
  formik,
  formAction,
  handleChange,
  handleReturn,
  saved,
  initialState,
  banks
}) => {
  return (
    <FormContainer onSubmit={formAction} data-test="addBeneficiaryForm">
      {!saved ? (
        <>
          <TextField
            data-test="aliasField"
            className="formField"
            label="Alias"
            name="alias"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.alias && formik.touched.alias}
            helperText={formik.errors.alias}
            helperTextClass={'customHelperMessage'}
            value={form.alias}
          />
          <TextField
            name="name"
            className="formField"
            label="Titular de la cuenta"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.name && formik.touched.name}
            helperText={formik.errors.name}
            helperTextClass={'customHelperMessage'}
            value={form.name}
          />{' '}
        </>
      ) : null}
      <AccountInput
        className="formField"
        name="account_code"
        data-test="accountInputComponent"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.account_code && formik.touched.account_code}
        helperText={formik.errors.account_code || formik.errors.bank}
        helperTextClass={'customHelperMessage'}
        values={{
          type: form.type,
          account_code: form.account_code,
          bank: form.bank
        }}
        disabled={saved}
        banks={banks}
        height={saved ? '15rem' : null}
      />
      {!saved ? (
          <>
              <TextField
                  name="email"
                  className="formField"
                  label="Correo de confirmación"
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.errors.email}
                  helperTextClass={'customHelperMessage'}
                  error={formik.errors.email && formik.touched.email}
                  value={form.email}
              />
              <TextField
                  data-test="rfcField"
                  className="formField"
                  label="RFC (opcional)"
                  name="rfc"
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.rfc && formik.touched.rfc}
                  helperText={formik.errors.rfc}
                  helperTextClass={'customHelperMessage'}
                  value={form.rfc}
              />
          </>
      ) : null}
      <Button
        className="formButton"
        size="lg"
        type="submit"
        text={!saved ? 'Guardar beneficiario' : 'Transferir dinero'}
        disabled={initialState || !formik.isValid}
      />
      {saved ? (
        <div className="backBtn">
          <span onClick={handleReturn}>Regresar</span>
        </div>
      ) : null}
    </FormContainer>
  );
};

export default memo(AddBeneficiaryForm);
