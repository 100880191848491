import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    width: '35rem',
    paddingTop: '2.5rem'
  },
  inputField: {
    width: '100%',
    fontSize: '1.6rem'
  },
  checkboxLabel: {
    verticalAlign: 'middle',
    height: '100%'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '3rem'
  },
  genderLegend: {
    marginBottom: '1.5rem',
    color: '#323132',
    fontSize: '1.6rem'
  },
  description: {
    color: '#989898',
    fontSize: '1.8rem'
  },
  checkbox: {
    marginTop: '2rem'
  },
  checkboxes: {
    fontSize: '1.8rem',
    color: '#d6d6d6',
    paddingRight: '1rem'
  },
  nationalityCheckbox: {
    fontSize: '1.6rem',
    color: '#666566'
  },
  header: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    margin: '0 auto'
  },
  genderSelected: {
    color: '#666566'
  }
}));
