import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 7.1rem;
  border-radius: 0.8rem;
  background-color: white;
  margin: 1.6rem 0;
  position: relative;
  padding: 0 2.4rem;
  margin-bottom: 50px;
`;

export const Label = styled.div`
  width: 100%;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.218182px;
  display: block;
  color: ${colors.body};
`;

export const Option = styled.button`
  background: ${props =>
    props.disabled
      ? 'lightgray'
      : props.checked
      ? '#1790CF'
      : 'rgba(23, 144, 207, 0.05)'};
  border-radius: 50px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 29px;
  border: 0px;
  padding: 5px 30px;
  text-align: center;
  letter-spacing: 0.15px;
  margin: 30px 15px;
  cursor: pointer;

  color: ${props =>
    props.disabled ? 'gray' : props.checked ? '#FFFFFF' : '#1790cf'};

  &:hover {
    background: ${props =>
      props.disabled
        ? 'lightgray'
        : props.checked
        ? '#1790CF'
        : 'rgba(23, 144, 207, 0.15)'};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  &:focus {
    outline: none;
  }
`;
