import {
  CREATE_USER,
  DELETE_USER,
  GET_USER_BY_ID,
  GET_USER_STATUS,
  GET_USERS,
  SET_USER,
  UPDATE_USER,
  PURGE
} from './crud-types';

const INITIAL_STATE = {
  createUser: {},
  deleteUser: {},
  getUserById: {},
  updateUser: {},
  users: {},
  user: {},
  userStatus: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_USER:
      return { ...state, createUser: action.payload };
    case DELETE_USER:
      return { ...state, deleteUser: action.payload };
    case GET_USER_BY_ID:
      return { ...state, getUserById: action.payload };
    case GET_USER_STATUS:
      return { ...state, userStatus: action.payload };
    case GET_USERS:
      return { ...state, users: action.payload };
    case SET_USER:
      return { ...state, user: action.payload };
    case UPDATE_USER:
      return { ...state, updateUser: action.payload };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
