import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import HasPermissions from '../../../../role-based-access/components/HasPermissions';
import { StatusContainer } from './styles';
import StatusLabel from './StatusLabel';

import { dictionary, labels } from './statuses';

const getStatus = userDetails => {
  const statuses = userDetails.statuses;

  if (!statuses) return '';

  const requestedByAdmin = validateAdminRequest(
    userDetails.adminUpdateRequest,
    userDetails.adminDeleteRequest
  );

  if (requestedByAdmin) return requestedByAdmin;

  if (!statuses.length) return dictionary.active;
  return dictionary[statuses[0]] || dictionary.active;
};

const validateAdminRequest = (adminUpdateRequest, adminDeleteRequest) => {
  if (!adminUpdateRequest && !adminDeleteRequest) return false;

  if (adminUpdateRequest) return 'admin_update_requested';

  if (adminDeleteRequest) return 'admin_delete_requested';

  return false;
};

const getInfo = (status, userDetails, admin) => {
  const info = labels[status] || labels.default;
  info.userName = userDetails.userName || 'este usuario';
  info.admin = admin ? admin : 'Un administrador';
  info.isEmailTokenExpired = userDetails.isEmailTokenExpired;

  const userData = { ...info };

  return userData;
};

const Status = ({ getUser, userDetails, admin }) => {
  const [statusInfo, setInfo] = useState(labels.default);
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (userDetails.statuses) setStatus(getStatus(userDetails));
  }, [userDetails]);

  useEffect(() => {
    if (status) setInfo(getInfo(status, userDetails, admin));
  }, [status, userDetails, admin]);

  return (
    <HasPermissions
      perform="user:approve"
      accessAction={() => (
        <StatusContainer
          onClick={
            statusInfo.isCTAactive
              ? e => {
                  e.stopPropagation();
                  getUser(userDetails, status);
                }
              : null
          }
        >
          <StatusLabel
            status={statusInfo.adminStatus || statusInfo.status}
            tooltip={statusInfo.tooltip}
            color={statusInfo.color}
            userName={statusInfo.userName}
          />
        </StatusContainer>
      )}
      restrictedAction={() => (
        <>
          {statusInfo.adminStatus ? (
            <StatusContainer
              onClick={
                statusInfo.isCTAactive
                  ? () => getUser(userDetails, status)
                  : null
              }
            >
              <StatusLabel
                status={statusInfo.adminStatus}
                tooltip={statusInfo.tooltip}
                color={statusInfo.color}
                userName={statusInfo.userName}
              />
            </StatusContainer>
          ) : (
            <StatusContainer>
              <StatusLabel status={statusInfo.status} />
            </StatusContainer>
          )}
        </>
      )}
    />
  );
};

Status.propTypes = {
  children: PropTypes.any,
  onclick: PropTypes.func
};

export default Status;
