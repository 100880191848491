import React from 'react';
import PropTypes from 'prop-types';

import { EntryStatementContainer } from './EntryStatement.styles';
import Icon from '../../../shared/components/Icon/Icon';
import Title from '../../../shared/components/Title';

const EntryStatement = ({ title, onClick }) => {
  return (
    <EntryStatementContainer data-test="entryStatement">
      <Title text={title} data-test="titleComponent" />
      <span onClick={onClick}>
        <Icon icon="download" className="download" data-test="iconComponent" />
      </span>
    </EntryStatementContainer>
  );
};

EntryStatement.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default EntryStatement;
