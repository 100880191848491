import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { getCardTransactionTagsCardIdPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const createCardTransactionTagService = async ({
  tagId,
  tagLabel,
  cardId,
  transactionId
} = {}) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      getCardTransactionTagsCardIdPath,
      {
        tag_id: tagId,
        tag_label: tagLabel,
        credit_card_id: cardId,
        credit_card_transaction_id: transactionId
      }
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'create-card-transaction-tag-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('create-card-transaction-tag-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
