import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const Options = styled.div`
  background-color: ${colors.white};
  border: 0.1rem solid ${colors.carbon3};
  border-radius: 0.4rem;
  box-shadow: 0 0.8rem 1.6rem -0.6rem #e5e8ed;
  box-sizing: border-box;
  display: none;
  min-width: 12.5rem;
  padding: 2rem;
  position: absolute;
  z-index: 1;
`;

export const StyledDropdown = styled.div`
  color: ${colors.carbon};
  display: block;
  font-family: ${fonts.barlowMedium}
  font-size: 1.4rem;
  padding-inline-start: 0;
  

  &:hover {
    cursor: pointer;
    background-color: ${colors.carbon4};
    ${Options} {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const StyledItem = styled.li`
  color: ${props =>
    (props.value === 'true' ? colors.primary : colors.pallet0Text)};
  display: block;
  min-width: 8rem;
  padding: 0.5rem 0;
  text-align: center;

  &:hover {
    cursor: pointer;
    color: ${colors.primary};
  }
  &:active {
    color: ${colors.primary};
  }
`;

export const LabelFor = styled.div`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin: 0 0.8rem;
`;

export const Wrapper = styled.div`
  color: ${props => (props.color ? colors[props.color] : colors.carbon)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const Dot = styled.circle`
  height: 6px;
  width: 6px;
  color: black;
  background-color: ${colors.primary};
  border-radius: 50%;
  margin: 0 0.8rem;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
