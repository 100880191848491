/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

import Header from '../Header/Header';
import LabelGroup from '../LabelGroup';
import TransactionVoucher from '../TransactionVoucher/TransactionVoucher';
import FilterTransactions from '../../../pages/FilterTransactions';
import Transactions from '../../Transactions/Transactions';
import HeaderTable from '../../../../shared/components/HeaderTable/HeaderTable';
import { ReactComponent as DownloadIcon } from '../../../../../Dashboard4.0/assets/images/download-icon.svg';

import { transactionTypeIds } from '../../Transactions/constants';
import {
  getShortDate,
  parseAmount,
  splitAmount
} from '../../../../../helpers';
import { transactionsSelector } from '../../../../tags/redux/tag-selectors';

import {
  BodyContainer,
  ClickableActions,
  LinksContainer,
  Logo,
  VoucherContainer
} from '../Voucher/styles';
import LogoImg from '../../../../../Dashboard4.0/assets/images/deltai-logo.svg';

import spanish from '../../../../../catalogs/spanish.json';
import { transactionStatus } from '../constants';

const MultiTransferModal = ({
  details,
  reference,
  isHighQuality,
  downloadFailed,
  downloadSuccess,
  downloadZip
}) => {
  const {
    total_amount,
    batch_date = '',
    transaction_type_id,
    tracking_key
  } = details;

  details.description = details.batch_description;

  const [paymentOrders, setPaymentOrders] = useState(
    details.batch_transactions
  );
  const transactions = useSelector(transactionsSelector);
  const [transactionId, setTransactionId] = useState(null);
  const [filteredByQuery, setFilteredByQuery] = useState('');
  const [filteredByType, setFilteredByType] = useState('');
  const [notFound, setNotFound] = useState('');

  const filterTransactions = debounce(query => {
    setFilteredByType('');

    if (!query) {
      setFilteredByQuery('');
      return;
    }

    setFilteredByQuery(query);
  }, 1000);

  useEffect(() => {
    if (filteredByQuery) {
      setPaymentOrders(
        details.batch_transactions.filter(
          el => el.description.indexOf(filteredByQuery) !== -1
        )
      );
    }
    if (!filteredByQuery) setPaymentOrders(details.batch_transactions);
  }, [filteredByQuery]);

  useEffect(() => {
    if (filteredByQuery && paymentOrders.length === 0)
      setNotFound(filteredByQuery);
    else if (filteredByType && paymentOrders.length === 0)
      setNotFound(`movimientos de tipo ${spanish.orderTypes[filteredByType]}`);
    else setNotFound('');
  }, [filteredByQuery, paymentOrders]);

  const voucherTypes = {
    [transactionTypeIds.order]: {
      component: <TransactionVoucher details={details} />,
      text: 'multiTransfer',
      type: 'transfer'
    }
  };

  useEffect(() => {
    const currentTransaction = transactions.find(
      transaction => transaction.tracking_key === tracking_key
    );
    if (currentTransaction) {
      setTransactionId(currentTransaction.transaction_id);
    }
  }, []);

  if (transaction_type_id) {
    return (
      <VoucherContainer ref={reference} isHighQuality={isHighQuality}>
        <Header
          amount={splitAmount(parseAmount(total_amount))}
          className="amount"
          type={voucherTypes[transaction_type_id].type}
          text={voucherTypes[transaction_type_id].text}
        />
        <BodyContainer pb="7rem" className="multitransferVoucher">
          <Logo src={LogoImg} />
          <LabelGroup
            mainText={getShortDate(batch_date)?.toLowerCase()}
            secondaryText={getShortDate(batch_date, 'hh:mm A')}
            align="right"
          />

          {voucherTypes[transaction_type_id].component}

          <FilterTransactions
            query={filteredByQuery}
            searchFn={filterTransactions}
            type="multitransfer"
          />

          <HeaderTable
            texts={['MOVIMIENTOS', 'MONTO', 'ESTATUS']}
            columns={'9.5fr 3fr 3fr'}
            className="headerTable"
          />

          {(paymentOrders && paymentOrders.length > 0) || notFound ? (
            <>
              <Transactions
                notFound={notFound}
                paymentOrders={paymentOrders}
                type="multitransfer"
              />
            </>
          ) : null}

          {downloadFailed || downloadSuccess || downloadZip ? (
            <LinksContainer>
              <ClickableActions onClick={downloadZip}>
                <DownloadIcon /> Zip de todos
              </ClickableActions>{' '}
              {details.batch_transactions.some(
                transaction => transaction.status !== transactionStatus.rejected
              ) ? (
                <>
                  <ClickableActions onClick={downloadSuccess}>
                    <DownloadIcon /> Transferencias Exitosas
                  </ClickableActions>{' '}
                </>
              ) : null}
              {details.batch_transactions.some(
                transaction => transaction.status === transactionStatus.rejected
              ) ? (
                <ClickableActions onClick={downloadFailed}>
                  <DownloadIcon /> Transferencias Fallidas
                </ClickableActions>
              ) : null}
            </LinksContainer>
          ) : null}
        </BodyContainer>
      </VoucherContainer>
    );
  }
  return null;
};

MultiTransferModal.propTypes = {
  details: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  reference: PropTypes.any,
  downloadZip: PropTypes.func,
  downloadSuccess: PropTypes.func,
  downloadFailed: PropTypes.func,
  isHighQuality: PropTypes.bool
};

export default MultiTransferModal;
