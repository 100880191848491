/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { memo, useState } from 'react';

// Auxiliary libraries
import PropTypes from 'prop-types';

// Components
import { Container } from './styles';
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';
import TableRow from './TableRow';
import Paginator from '../../../shared/components/Paginator';

const getSubarray = (users = [], page = 1) => {
  const start = (page - 1) * 10;
  const end = start + 10;
  return users.slice(start, end);
};

const getTotalPages = users => {
  const totalPages = Math.ceil(users.length / 10);
  return totalPages;
};

export const Component = ({
  users = [],
  userId,
  deleteUser,
  getUser,
  columns
}) => {
  const [page, setPage] = useState(1);

  const currentUsers = getSubarray(users, page);
  const totalPages = getTotalPages(users);
  const totalResults = users.length;
  const displayedResults = currentUsers.length;

  const changePage = page => {
    setPage(page);
  };

  return (
    <>
      <Container>
        {currentUsers.map((el, index) => {
          return (
            <TableRow
              key={el.user_id}
              details={el}
              columns={columns}
              index={index}
              deleteUser={deleteUser}
              getUser={getUser}
              userId={userId}
            />
          );
        })}
      </Container>
      <Paginator
        page={page}
        displayedResults={displayedResults}
        totalResults={totalResults}
        totalPages={totalPages}
        onChange={changePage}
      />
    </>
  );
};

Component.propTypes = {
  users: PropTypes.array.isRequired,
  userId: PropTypes.number,
  deleteUser: PropTypes.func,
  getUser: PropTypes.func
};

const UsersTable = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="UsersTable from users CRUD"
    />
  );
};

export default memo(UsersTable);
