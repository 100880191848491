import {
  setDocumentationAction,
  setDocumentationErrorAction,
  setHeaders,
  setPythonSdkUrl,
} from './redux/apidocs-actions';
import { getApiDocs } from './service';

export const getDocumentation = async dispatch => {
  const { documentation, error } = await getApiDocs();
  dispatch(setDocumentationAction(getDocumentationOrdered(documentation.api_docs)));
  dispatch(setDocumentationErrorAction(error));
  dispatch(setHeaders(getHeaders(documentation.api_docs)));
  dispatch(setPythonSdkUrl(documentation.python_sdk_url));
};

export const getHeaders = api_docs => {
  const filtered = [
    ...new Map(
      api_docs.map(item => [item.request_category, item.request_category])
    ).values()
  ];
  return filtered;
};

export const getDocumentationOrdered = api_docs => {
  return Array.isArray(api_docs)
    ? api_docs?.reduce((groups, item) => {
        let val = item.request_category;
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {})
    : [];
};
