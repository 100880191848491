/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';

// Auxiliary libraries
import PropTypes from 'prop-types';

// Components
import Button from '../../shared/components/Button/Button';
import DeleteUser from '../components/DeleteUser/DeleteUser';
import EmptyState from '../components/EmptyStates/EmptyState';
import EmptyStateCopy from '../../shared/components/EmptyStateCopy/EmptyStateCopy';
import FilterBar from '../../shared/components/FilterBar/FilterBar';
import HasPermissions from '../../../role-based-access/components/HasPermissions';
import Layout from '../../shared/components/Layout/Layout';
import PageButtonsHeader from '../../cards/components/page-buttons-header';
import TableHeader from '../components/HeaderTable/TableHeader';
import UsersTable from '../components/UsersTable/UsersTable';
import UserModal from '../components/UserModal/UserModal';

// Reducers, actions, helpers
import { getUsersAction, setUserAction } from '../redux/crud-actions';
import { getUsersService, deleteUserService } from '../services/crud-service';
import { useDispatch, useSelector } from 'react-redux';
import {
  userIdSelector,
  usersSelector,
  userSelector
} from '../redux/crud-selectors';
import { useLocation, useHistory } from 'react-router-dom';
import { statuses } from '../components/Status/statuses';
import { UserRowEntry } from '../object-creators/object-creators';
import useViewportWidth from '../../../hooks/useViewportWidth';

const getCurrentView = (users, error, filteredUsers) => {
  if (!users || users.length === 0 || error) return 'empty';

  if (filteredUsers.list.length) return 'filteredUsers';

  if (!filteredUsers.list.length && filteredUsers.query) return 'notFound';

  return 'users';
};

const UsersCrud = () => {
  const [deleteState, setDeleteState] = useState({
    show: false,
    name: '',
    id: ''
  });
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: ''
  });

  const userId = useSelector(userIdSelector);
  const user = useSelector(userSelector);
  const { users, error } = useSelector(usersSelector);

  const [filteredUsers, setFilteredUsers] = useState({
    list: [],
    query: ''
  });

  const currentView = getCurrentView(users, error, filteredUsers);
  const viewportWidth = useViewportWidth();
  const isMobile = viewportWidth < 769;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const columnNames = [
    'Nombre',
    'Disponible',
    `${isMobile ? 'Límite' : 'Límite de gasto'}`,
    'Departamento',
    'Tipo',
    'Estatus'
  ];

  const columns = '1.4fr 1.2fr 1.3fr 1.7fr 1.3fr 1.3fr';

  const createUser = () => console.log('create user');
  const searchFn = searchValue => {
    if (!searchValue) {
      setFilteredUsers({ list: [] });
      return;
    }
    const filteredUsers = filterUsers(searchValue);
    if (filteredUsers.length) {
      setFilteredUsers({ list: filteredUsers });
      return;
    }
    setFilteredUsers({ list: [], query: searchValue });
  };

  const filterUsers = searchValue => {
    const regex = new RegExp(searchValue, 'i');
    const filteredUsers = users.filter(
      user =>
        (user.full_name && user.full_name.match(regex)) ||
        (user.name && user.name.match(regex))
    );

    return filteredUsers;
  };

  const { isOpen, type } = modalState;
  const { show, name, id } = deleteState;

  const handleModal = (isOpen, type) => {
    dispatch(setUserAction({}));
    setModalState({ isOpen, type });
  };

  const getUsersList = async () => {
    const users = await getUsersService();
    dispatch(getUsersAction(users));
  };

  const showUser = async (userData, type) => {
    if (type !== statuses.admin_update_requested)
      dispatch(setUserAction(userData));

    if (type === statuses.admin_update_requested) {
      const data = UserRowEntry(userData.adminUpdateRequest);
      data.roleId = userData.roleId;
      data.userId = userData.userId;
      data.updateRequestId = userData.adminUpdateRequest.update_request_id;
      dispatch(setUserAction(data));
    }

    setModalState({ isOpen: true, type: type || 'update' });
  };

  const handleDelete = async () => {
    const { error } = await deleteUserService(id);
    if (!error) {
      setDeleteState({ show: false });
      getUsersList();
      handleClose();
    }
  };

  const handleDeleteModal = (id, name) => {
    setDeleteState({ show: !show, name, id });
  };

  const handleClose = () => {
    dispatch(setUserAction({}));
    handleModal(false, '');
    getUsersList();
  };

  const checkIdinParams = () => {
    let currentUser = [];

    if (
      location?.state?.trigger === 'Subir' &&
      location?.state?.userId &&
      users?.length > 0
    ) {
      currentUser = users.filter(user => {
        return user.user_id === location.state.userId;
      });
    }

    if (currentUser?.length > 0) {
      const userDetails = UserRowEntry(currentUser[0]);
      history.replace({ state: {} });
      showUser(userDetails, 'update');
    }
  };

  useEffect(() => {
    getUsersList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkIdinParams();
  }, [users]);

  return (
    <>
      <Layout title="Equipo">
        <HasPermissions
          perform="user:create"
          accessAction={() => (
            <PageButtonsHeader>
              <Button
                data-test="AddUserButton"
                text="Agregar usuario"
                onClick={() => handleModal(true, 'create')}
              />
            </PageButtonsHeader>
          )}
          restrictedAction={() => <></>}
        />

        <HasPermissions
          perform="user:view"
          accessAction={() => (
            <FilterBar
              className="filterBar"
              searchIcon="search"
              searchPlaceholder="Buscar usuario"
              searchFn={searchFn}
            />
          )}
          restrictedAction={() => <></>}
        />

        <TableHeader columnNames={columnNames} columns={columns} />

        {currentView === 'users' && users ? (
          <UsersTable
            users={users}
            userId={userId}
            columns={columns}
            getUser={showUser}
            deleteUser={handleDeleteModal}
          />
        ) : null}

        {currentView === 'filteredUsers' ? (
          <UsersTable
            users={filteredUsers.list}
            userId={userId}
            columns={columns}
            getUser={showUser}
            deleteUser={handleDeleteModal}
          />
        ) : null}

        {currentView === 'empty' ? (
          <EmptyState
            createUser={createUser}
            reloadUsers={getUsersList}
            error={error}
            users={users}
          />
        ) : null}

        {currentView === 'notFound' ? (
          <EmptyStateCopy
            copy="Ningún usuario coincide con"
            searchParam={filteredUsers.query}
          />
        ) : null}
      </Layout>
      <UserModal
        open={isOpen}
        onClose={handleClose}
        type={type}
        handleDelete={handleDeleteModal}
      />

      <DeleteUser
        open={show}
        handleClose={handleDeleteModal}
        handleDelete={handleDelete}
        name={name}
      />
    </>
  );
};

UsersCrud.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  history: PropTypes.object
};

export default UsersCrud;
