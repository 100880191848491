export const fileUploadHandler = ({ uploadDocument }) => async ({
  uploadedDocuments,
  type
}) => {
  const sentDocuments = [];

  for (let index = 0; index < uploadedDocuments.length; index += 1) {
    const element = uploadedDocuments[index];
    const formData = element.value;
    const documentType = element.type;
    const requestType = documentType === 'both' ? type : documentType;

    if (formData) {
      // eslint-disable-next-line no-await-in-loop
      const res = await uploadDocument(formData, requestType);
      sentDocuments.push(res);
    }
  }

  return { isSuccess: true, sentDocuments };
};
