export const tagActions = {
  ADD_TAG: 'ADD_TAG',
  ADD_TAG_CREDIT_TRANSACTION: 'ADD_TAG_CREDIT_TRANSACTION',
  ADD_SPEI_TAG: 'ADD_SPEI_TAG',
  SET_TAGS_CARD_TRANSACTION: 'SET_TAGS_CARD_TRANSACTION',
  PURGE: 'PURGE',
  REMOVE_TAG: 'REMOVE_TAG',
  SET_TAGS: 'SET_TAGS',
  SET_TAG_OPTIONS: 'SET_TAG_OPTIONS',
  SET_TAGS_SPEI: 'SET_TAGS_SPEI',
  ADD_TAG_OPTION: 'ADD_TAG_OPTION',
  CLEAR_TAGS: 'CLEAR_TAGS',
  REMOVE_TAG_OPTION: 'REMOVE_TAG_OPTION'
};
