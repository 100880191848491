/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { TagContainer } from './Tag.styles';
import { ReactComponent as Delete } from '../../../../Dashboard4.0/assets/images/Delete.svg';

const Tag = ({
  text,
  color,
  isDeleteVisible,
  onDeleteClicked,
  onClick,
  ...otherProps
}) => {
  return (
    text && (
      <TagContainer
        data-test="Tag"
        color={color}
        {...otherProps}
        isDeleteVisible={isDeleteVisible}
        onClick={onClick}
      >
        <span>{text}</span>
        {isDeleteVisible ? <Delete onClick={onDeleteClicked} /> : null}
      </TagContainer>
    )
  );
};

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default memo(Tag);
