import styled from 'styled-components';

export const ContractContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1.1rem;
  justify-content: center;
  margin-top: 2.4rem;
  height: max-content;
`;

export const LinkContainer = styled.div`
  cursor: pointer;

  .attachment {
    margin-right: 1rem;
  }

  .attachment path {
    fill: #1790cf;
  }
`;
