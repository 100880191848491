// Paths
export const customerInfoPath = '/customer-information';
export const commonCustomerInfoPath = '/team-information';
export const tpaNipVerificationPath = '/tpa-nip-verification';
export const tpaCreditHistoryPath = '/tpa-credit-history';
export const tpaAuthorizationPath = '/tpa-authorization';
export const tpaSyntagePath = '/tpa-syntage';
export const tpaCompliancePath = '/tpa-compliance';
export const tpaSuccessPath = '/tpa-success';
// Api
const basePath = 'api/dashboard';
export const buroGenerate = `${basePath}/tpa/customer-buro-generate`;
export const buroNip = `${basePath}/tpa/customer-buro-nip`;
export const buroSubmit = `${basePath}/tpa/customer-buro-submit`;
export const buroResendNip = `${basePath}/tpa/customer-buro-resend-code`;
export const customerUpdatePhone = `${basePath}/tpa/customer-update-phone`;
export const complianceCredentials = `${basePath}/tpa/customer-compliance-credentials`;
export const agreementList = `${basePath}/tpa/customer-agreement-list`;
export const agreementUpdate = `${basePath}/tpa/customer-agreement-update`;

export const buroResendNipMessage = "Se ha enviado el código de verificación"

export const errorMessages = {
    "0028.AUX-SERVICES.CUSTOMER.REPORT-BUREAU-NIP.004": "El NIP no es válido",
    "0028.AUX-SERVICES.CUSTOMER.REPORT-BUREAU-SUBMIT.004": "El NIP no es válido",
    "0001.MESSAGE-ERROR_RFC.001":  "El RFC ya está registrado. Intente nuevamente."
}

export const kycPaths = [
    customerInfoPath,
    tpaNipVerificationPath,
    tpaCompliancePath,
    tpaCreditHistoryPath,
    tpaAuthorizationPath,
    tpaSyntagePath,
    tpaCompliancePath,
    tpaSuccessPath,
    commonCustomerInfoPath
]