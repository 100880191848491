export const registerUserTypeLink = `/register/user-type`;
export const registerFreelancerLink = `/register/freelancer`;
export const registerPymeLink = `/register/pyme`;
export const confirmAccountPath = `/register/new-confirm-account`;

export const registerPaths = [
  registerUserTypeLink,
  registerFreelancerLink,
  registerPymeLink,
  confirmAccountPath
];
