const AttorneyInLawCreator = ({
  userId,
  personalRfc,
  personalCurp,
  percentage
}) =>
  Object.freeze({
    user_id: userId,
    rfc: personalRfc,
    curp: personalCurp,
    company_association_type_id: 4, //According with company association type catalog
    controlling_stake: percentage
  });

export default AttorneyInLawCreator;
