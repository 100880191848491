import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  stepper: {
    paddingTop: '4rem',
    paddingBottom: '0',
    position: 'inherit'
  }
});

export const stepperTheme = createTheme({
  overrides: {
    MuiStepConnector: {
      line: {
        display: 'none'
      }
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          background: '#fff',
          color: '#fff',
          fontWeight: '700'
        },
        '&$completed': {
          background: '#fff',
          color: '#22c70c',
          borderRadius: '50%'
        },
        color: '#fff'
      },
      text: {
        fill: '#000',
        fontSize: '1.5rem',
        fontFamily: 'Barlow'
      },
      alternativeLabel: {
        position: 'inherit'
      }
    },
    MuiStep: {
      alternativeLabel: {
        padding: '0px'
      }
    },
    MuiStepper: {
      alternativeLabel: {
        alignItems: 'flex-start',
        padding: '0px'
      }
    },
    MuiStepLabel: {
      root: {
        alignItems: 'flex-start',
        paddingBottom: '.35rem'
      },
      labelContainer: {
        display: 'none'
      }
    }
  }
});
