import { removeTagOptionAction } from '../redux/tag-action';

const deleteTransactionTagHandler = ({
  dispatch,
  deleteTransactionTag
}) => async ({ tag }) => {
  const { error } = await deleteTransactionTag({ tag });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  dispatch(removeTagOptionAction({ tag }));

  return { isSuccess: true, data: tag };
};

export { deleteTransactionTagHandler };
