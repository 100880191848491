export const getBankingInstitutionsHandler = ({
  getBankingInstitutions
}) => async () => {
  const res = await getBankingInstitutions();
  const { error, data } = res;
  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true, data };
};
