import "./index.scss"

import React from "react";

export default function Index({children}) {
    return (
        <div className="center">
            <div>
                {children}
            </div>
        </div>
    )
}
