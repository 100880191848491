import * as yup from 'yup';

const requiredFieldMessage = 'Campo requerido';
const street_address_error_message =
  'El límite de caracteres para la Calle, No Ext y No Int es de 20 caracteres';
const settlement_city_error_message =
  'El límite de caracteres para la Colonia y la Ciudad es de 25 caracteres';
const postalCode_error_message =
  'El límite de caracteres para el Código postal es de 27 caracteres';

const street_address_max_characters = 20;
const settlement_city_max_characters = 25;
const postalCode_max_characters = 27;

export const validationSchema = yup.object({
  street: yup.string().required(requiredFieldMessage),
  externalNum: yup.string().required(requiredFieldMessage),
  internalNum: yup.string(),
  street_address_validation: yup.string().when('street', {
    is: value => value !== undefined,
    then: yup.string().test({
      name: 'street_address_validation',
      message: street_address_error_message,
      test: function () {
        const fields = [
          this.parent.street ? this.parent.street : '',
          this.parent.externalNum ? this.parent.externalNum : '',
          this.parent.internalNum ? this.parent.internalNum : ''
        ];
        const combinedLength = fields.reduce(
          (acc, field) => acc + field.length,
          0
        );
        return combinedLength <= street_address_max_characters;
      }
    })
  }),
  settlement: yup.string().required(requiredFieldMessage),
  city: yup.string().required(requiredFieldMessage),
  settlement_city_validation: yup.string().when('settlement', {
    is: value => value !== undefined,
    then: yup.string().test({
      name: 'settlement_city_validation',
      message: settlement_city_error_message,
      test: function () {
        const fields = [
          this.parent.settlement ? this.parent.settlement : '',
          this.parent.city ? this.parent.city : ''
        ];
        const combinedLength = fields.reduce(
          (acc, field) => acc + field.length,
          0
        );
        return combinedLength <= settlement_city_max_characters;
      }
    })
  }),
  postalCode: yup.string().required(requiredFieldMessage),
  postalCode_validation: yup.string().when('postalCode', {
    is: value => value !== undefined,
    then: yup.string().test({
      name: 'postalCode_validation',
      message: postalCode_error_message,
      test: function () {
        const fields = [this.parent.postalCode ? this.parent.postalCode : ''];
        const combinedLength = fields.reduce(
          (acc, field) => acc + field.length,
          0
        );
        return combinedLength <= postalCode_max_characters;
      }
    })
  }),
  stateId: yup
    .string()
    .max(40, 'Máximo 40 caracteres')
    .required(requiredFieldMessage),
  building_type: yup
    .string()
    .oneOf(['Residencial', 'Comercial'])
    .required(requiredFieldMessage),
  reference: yup
    .string()
    .max(35, 'Máximo 35 caracteres')
    .required(requiredFieldMessage)
});
