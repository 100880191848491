import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const classes = css`
  .rejected {
    color: ${colors.pallet0Text};
  }
  .flex-container {
    display: flex;
  }
  .card {
    width: 22px;
    opacity: 0.9;
    transform: rotate(-19deg);
    filter: contrast(0.5);
  }

  .titleHover {
    color: ${colors.primary};
    cursor: pointer;
  }

  .cardTransactionTags {
    margin: 0.5rem 0;

    .tagItem {
      width: 6rem;
      height: 2.17rem;
      min-width: 6rem;
      margin-right: 0.8rem;

      span {
        min-width: 5rem;
        width: 5rem;
        padding: 0;
      }
    }

    .tooltipText {
      min-width: min-content;
    }
  }
`;

const successStyles = css`
  background-color: ${colors.success4Transaparent};
`;

const getSuccessStyles = ({ type }) => {
  return type && successStyles;
};

const getColumns = ({ columns }) => {
  if (!columns) return `grid-template-columns: 2fr 2fr 8fr 3fr 3fr;`;
  if (columns === 4) return `grid-template-columns: 0fr 7fr 3fr 3fr;`;
};

const getHoverColumns = ({ columns }) => {
  if (!columns) return;
  if (columns === 4) return `grid-template-columns: 0fr 7fr 3fr 3fr;`;
  return `grid-template-columns: 1fr 1fr 5fr 3fr 3fr;`;
};

export const Container = styled.div`
  max-width: 94.8rem;
  width: 100%;
  border-radius: 1.2rem;
  background-color: white;
  padding: 0;

  &:hover {
    border-radius: 1.2rem;
    box-shadow: 0 1.1rem 2.2rem -0.8rem #e5e8ed;
    border: solid 1px ${colors.primary};
    ${getHoverColumns}
  }

  @media (max-width: 768px) {
    grid-template-columns: 2fr 8fr 3fr 3fr;
    margin: 0;
    padding: 0;

    h2,
    & > span {
      font-size: 1.4rem;
      text-overflow: ellipsis;
      margin: 0;
    }
    div > span {
      font-size: 1.2rem;
      text-overflow: ellipsis;
      margin: 0;
    }

    & > div:nth-child(2) {
    }
  }
`;

export const EntryContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  background-color: white;
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  padding: 25px 1.2rem;

  ${classes}
  ${getSuccessStyles}
  ${getColumns};
`;

export const EntryDetailsContainer = styled.div`
  justify-self: self-start;
  padding: 0 1rem;
`;

export const IconContainer = styled.span`
  cursor: pointer;
  height: 3.2rem;
  line-height: 2;
`;

export const IconText = styled.span`
  font-size: 1.35rem;
  font-family: ${fonts.barlowSemiBold};
  margin-left: 0.8rem;
  line-height: 1.35rem;
  letter-spacing: 0.12px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .ReversalLinkContainer {
    width: 10px;
    height: 40px;
    position: absolute;
    top: 53px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
