import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';
import { service } from '../../../service/';

const InReviewPage = ({ history }) => {
  const dispatch = useDispatch();
  const getScore = (pathname = '') => pathname.slice(pathname.length - 1);
  const convertToInt = (string = '') => parseInt(string);

  const updateProgress = async newStatus => {
    const { isSuccess } = await service({ dispatch }).updateOnboardingProgress({
      newStatus
    });
    return isSuccess;
  };

  const {
    location: { pathname }
  } = history;

  useEffect(() => {
    const score = convertToInt(getScore(pathname));
    if (score && typeof score === 'number') {
      if (score >= 5) {
        const isSuccess = updateProgress('QUALIFIED');
        if (isSuccess) history.push('/interview');
        return;
      }
      const isSuccess = updateProgress('IN_REVIEW');
      if (isSuccess) history.push('/in-review');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <RegistrationPageLayout
      title="¡Gracias!"
      description="Te vamos a contactar pronto"
      isPrimaryButtonHidden={true}
      isModalVisible={true}
      showLogout={true}
    ></RegistrationPageLayout>
  );
};

export default InReviewPage;
