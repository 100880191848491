import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { cardsPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const getCardsService = async () => {
  try {
    const { data, status, error } = await customAxiosInstance().get(cardsPath);
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'cards-service'
    });
  } catch (error) {
    log.error('cards-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
