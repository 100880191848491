import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { resendActivationPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const resendActivationService = async email => {
  try {
    const {
      data,
      status,
      error
    } = await customAxiosInstance().post(resendActivationPath, { email });
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'resend-activation-service'
    });
  } catch (error) {
    log.error('resend-activation-service', error);
    return { error: { message: 'unable to send request' } };
  }
};
