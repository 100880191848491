// React
import React from 'react';

// Shared Components
import { Concept } from '../../credit/components/EntryBalance/EntryBalance.styles';
import HasPermissions from '../../../role-based-access/components/HasPermissions';

// Styled Components
import { AccountPageSideMenu } from '../Account.styles';
import { Container, Section } from '../Sections/Sections.styles';

// Helpers
import { states } from '../pages/Account';

const AccountPageSideNav = ({ state, handleTabChange }) => {
  return (
    <AccountPageSideMenu>
      <Concept text={'Secciones'} />
      <Container>
        <HasPermissions
          perform={'accountStatement:view'}
          accessAction={() => (
            <Section
              active={state === states.ACCOUNT_STATEMENTS}
              onClick={() => handleTabChange(states.ACCOUNT_STATEMENTS)}
            >
              {states.ACCOUNT_STATEMENTS}
            </Section>
          )}
          restrictedAction={() => <></>}
        />
        <HasPermissions
          //SUPER ADMIN ACCESS ONLY
          perform={''}
          accessAction={() => (
            <Section
              active={state === states.ACCOUNT_SECURITY}
              onClick={() => handleTabChange(states.ACCOUNT_SECURITY)}
            >
              {states.ACCOUNT_SECURITY}
            </Section>
          )}
          restrictedAction={() => <></>}
        />
      </Container>
    </AccountPageSideMenu>
  );
};

export default AccountPageSideNav;
