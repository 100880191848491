export const cardsLink = `/cards`;
export const activationLink = `/activar`;
export const newCardLink = `${cardsLink}/new`;
export const detailsLink = (creditCardId = ':cardId') =>
  `${cardsLink}/${creditCardId}`;
export const blockLink = (creditCardId = ':cardId', userId = ':userId') =>
  `${cardsLink}/${userId}/${creditCardId}/block`;
export const unblockLink = (creditCardId = ':cardId', userId = ':userId') =>
  `${cardsLink}/${userId}/${creditCardId}/unblock`;
export const reportLostLink = (creditCardId = ':cardId', userId = ':userId') =>
  `${cardsLink}/${creditCardId}/lost/${userId}`;
export const approvalCreateLink = (creditCardRequestId = ':cardRequestId') =>
  `${cardsLink}/${creditCardRequestId}/create-approval`;
export const approvalUpdateLink = (creditCardId = ':cardId') =>
  `${cardsLink}/${creditCardId}/update-approval`;
export const removeTagLink = ({
  tagId = ':tagId',
  tagLabel = ':tagLabel',
  creditCardId = ':creditCardId'
} = {}) => `${cardsLink}/remove-tag/${tagId}/${tagLabel}/${creditCardId}`;

export const VIRTUAL = 'virtual';
export const PHYSICAL = 'physical';

export const ACTIVE = 'active';
export const ADMIN_CREATE_REQUEST = 'admin create requested';
export const ADMIN_UPDATE_REQUEST = 'admin update requested';
