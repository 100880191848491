import { addTagAction } from '../redux/tag-action';

const createCardTagHandler = ({ dispatch, createCardTag }) => async (
  cardTag = {},
  saveOnRedux = true
) => {
  const { error } = await createCardTag(cardTag);

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  if (saveOnRedux) dispatch(addTagAction({ tag: cardTag }));

  return { isSuccess: true };
};

export { createCardTagHandler };
