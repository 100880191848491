const complianceCredentialsHandler = ({ complianceCredentials }) => async ({
  uuid,
  taxid,
  password
}) => {
  const { error, data } = await complianceCredentials({
    uuid,
    taxid,
    password
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  let isSuccess = true;
  if(data?.response === "BAD"){
    isSuccess = false;
  }

  return { isSuccess, data };
};

export { complianceCredentialsHandler };
