import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosAuxConfig';
import { buroSubmit } from '../utils/constants';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const buroSubmitService = async ({ uuid, nip }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      buroSubmit,
      {
        data: {
          customer: {
            uuid,
            nip
          }
        }
      }
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'kyc-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('kyc-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
