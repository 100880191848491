import "./index.scss";

import React, {Component} from "react";

class Index extends Component {
    constructor({size = "default", children, style}) {
        super(arguments[0]);
        this.size = size;
        this.children = children;
        this.style = style;
    }

    render() {
        const className = "heading " + this.size;
        return (
            <div className={className} style={this.style}>
                {this.children}
            </div>
        );

    }
}

export default Index;