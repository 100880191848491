import log from 'loglevel';

import { customAxiosInstance } from '../../../../../Services/axiosConfig';
import { extractContentFromResponse } from '../../../../../helpers/extractContentFromResponse';
import { paymentOrderPath, paymentOrdersPath } from './api-paths';

export const getPaymentOrder = async trackingKey => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      paymentOrderPath(trackingKey)
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'get-proof-of-payment',
      isSingleElement: true
    });
  } catch (error) {
    log.error('get-proof-of-payment', error);

    return { error: { message: 'Not able to send request!' } };
  }
};

export const getPaymentOrders = async batchProcessId => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      paymentOrdersPath(batchProcessId)
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'get-proofs-of-payment',
      isSingleElement: false
    });
  } catch (error) {
    log.error('get-proofs-of-payment', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
