export const validateResponse = res => {
  if (!res.data) return { userPermissions: {}, error: res };

  const data = res.data[0];

  if (res.status === 200 || res.status === 201) {
    return { userPermissions: data, error: false };
  }
  return { userPermissions: {}, error: res };
};
