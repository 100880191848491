import React, { useState } from 'react';

import Button from '../../../shared/components/Button';
import ButtonSubtle from '../../../users-crud/components/ButtonSubtle/ButtonSubtle';
import Selfie from '../Selfie';
import { Container, Divider, FileInput } from './SecondStep.styles';
import Title from '../../../shared/components/Title';
import { getBase64 } from '../../../../helpers/helpers';
import {
  PostINEService,
  PostSelfieService
} from '../../services/sub-account-service';

import { useLocalStorageState } from '../../hooks';

const SecondStep = ({ changeStep, form, setForm }) => {
  const [ine, setINE] = useState('');
  const [selfie, setSelfie] = useLocalStorageState('selfie');
  const [close, setClose] = useLocalStorageState(false);

  const handleINE = async e => {
    if (!e.target.value) return;
    const file = await getBase64(e.target.file);
    setINE(file);
  };

  const uploadFiles = async (name, file, service) => {
    const selfieResponse = await service({
      file_type: name,
      file_content: file
    });
    return selfieResponse;
  };

  const handleSubmit = async () => {
    uploadFiles('ine_reverso', ine, PostINEService);
    const { selfieResponse, error } = await uploadFiles(
      'selfie',
      selfie,
      PostSelfieService
    );

    if (error) return;

    setClose(true);
    setForm({ ...form, selfie: selfieResponse[0].storage_path });
  };

  return (
    <Container>
      <Title size="large" text="Registro" />
      <Selfie onChange={setSelfie} imageSrc={selfie} closeCamera={close} />
      <Divider />
      <FileInput
        label="Sube tu INE o Pasaporte"
        type="file"
        documentName="INE/Password"
        onChange={handleINE}
      />
      <Button
        size="lg"
        text="Continuar"
        className="stepTwoButton"
        onClick={handleSubmit}
        disabled={!(ine && selfie)}
      />
      <ButtonSubtle onClick={() => changeStep(1)}>Regresar</ButtonSubtle>
    </Container>
  );
};

export default SecondStep;
