/* eslint-disable no-case-declarations */
import {
  CLEAR_OBJECT_CREATE,
  CLEAR_OBJECT_GET,
  CREATE_USER,
  DELETE_USER,
  GET_USER_ID,
  GET_USERS,
  UPDATE_USER,
  PURGE,
} from './types';

const createObject = {
  data: {},
  success: false,
  error: false,
  res: {},
};

const getObject = {
  res: [],
  success: false,
  error: false,
};

const INITIAL_STATE = {
  createUser: createObject,
  deleteUser: createObject,
  getUserById: getObject,
  getUsers: getObject,
  updateUser: createObject,
  users: [],
};

// TODO: State machine
export default (state = {}, action) => {
  switch (action.type) {
    case CLEAR_OBJECT_CREATE:
      const create = action.payload;
      return { ...state, [create]: createObject };
    case CLEAR_OBJECT_GET:
      const get = action.payload;
      return { ...state, [get]: getObject };
    case CREATE_USER:
      return { ...state, createUser: action.payload };
    case DELETE_USER:
      return { ...state, deleteUser: action.payload };
    case GET_USER_ID:
      return { ...state, getUserById: action.payload };
    case GET_USERS:
      return { ...state, getUsers: action.payload };
    case UPDATE_USER:
      return { ...state, updateUser: action.payload };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
