import React, { memo, useEffect, useRef, useState, useCallback } from 'react';
import { Translation } from 'react-i18next';

import {
  Container,
  SectionContainer,
  Section,
  SubSection,
} from './styles';

import { getId } from '../../helpers';

const ApiSections = ({ documentation }) => {
  return (
    <Container data-test="ApiSections">
      <SectionContainer>

        <Section color="blue">
          <a href={`apidocs#getting-started`}>
            <Translation>{t => t('public-api-docs-getting-started')}</Translation>
          </a>
        </Section>

        <SubSection>
          <a href={`apidocs#overview`}>
            <Translation>{t => t('public-api-docs-overview')}</Translation>
          </a>
        </SubSection>

        <SubSection>
          <a href={`apidocs#recommended-environment`}>
            <Translation>{t => t('public-api-docs-recommended-environment')}</Translation>
          </a>
        </SubSection>

        <SubSection>
          <a href={`apidocs#install-prerequisites`}>
            <Translation>{t => t('public-api-docs-install-prerequisites')}</Translation>
          </a>
        </SubSection>

        <SubSection>
          <a href={`apidocs#download-deltai-python-sdk`}>
            <Translation>{t => t('public-api-docs-download-deltai-python-sdk')}</Translation>
          </a>
        </SubSection>

        <SubSection>
          <a href={`apidocs#install-deltai-python-sdk`}>
            <Translation>{t => t('public-api-docs-install-deltai-python-sdk')}</Translation>
          </a>
        </SubSection>

        <Section color="blue">
          <a href={`apidocs#make-requests`}>
            <Translation>{t => t('public-api-docs-make-requests')}</Translation>
          </a>
        </Section>

        <SubSection>
          <a href={`apidocs#create-api-keys`}>
            <Translation>{t => t('public-api-docs-create-api-keys')}</Translation>
          </a>
        </SubSection>

        <SubSection>
          <a href={`apidocs#manage-api-keys`}>
            <Translation>{t => t('public-api-docs-manage-api-keys')}</Translation>
          </a>
        </SubSection>

        <SubSection>
          <a href={`apidocs#environment-variables`}>
            <Translation>{t => t('public-api-docs-environment-variables')}</Translation>
          </a>
        </SubSection>

      </SectionContainer>

      {Object.keys(documentation).map((requestCategory, ix) => (
        <SectionContainer key={`category_${getId(requestCategory)}`}>
          <Section color="blue">
            <a href={`apidocs#${getId(requestCategory)}`}>
              <Translation>{t => t(requestCategory)}</Translation>
            </a>
          </Section>

          {documentation[requestCategory].map((request, ix) => (
            <SubSection key={`subsection_${getId(request.request_title)}`}>
              <a href={`apidocs#${getId(request.request_title)}`}>
                <Translation>{t => t(request.request_title)}</Translation>
              </a>
            </SubSection>
          ))}
        </SectionContainer>
      ))}
    </Container>
  );
};

export default memo(ApiSections);
