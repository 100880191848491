import styled from 'styled-components';

import Button from '../../../shared/components/Button';
import Title from '../../../shared/components/Title';

export const RegisterContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;

  .formTitle {
    font-size: 2.4rem;
  }
`;

export const SubContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.4rem;
`;

export const StyledButton = styled(Button)`
  margin-top: 5rem;
  width: 100%;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 4rem;
`;
