import React, { useEffect, useState, useCallback } from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import Modal from '../../../shared/components/Modal';
import ModalCardChanges from './modal-card-changes';

// Others
import { service } from '../../service';
import { alert } from '../../../../helpers/alertsManager';

const ApprovalModal = ({
  onApprove,
  onDecline,
  getCardRequest,
  approvalModal,
  setApprovalModal,
  cardRequestId,
  cardId
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cardDetails, setCardDetails] = useState({});

  const handleModalClose = useCallback(() => {
    setApprovalModal(false);
  }, [history]);

  const getRequestedCardDetails = async () => {
    const { isSuccess, card } = await getCardRequest({
      cardId,
      cardRequestId
    });

    if (isSuccess) {
      setCardDetails(card);
    }

    //TODO - Kwabena : Handle error case
  };

  useEffect(() => {
    getRequestedCardDetails();
    // eslint-disable-next-line
  }, []);

  const handleConfirmation = async () => {
    const cardRequestId = cardDetails.cardAdminRequestId;
    const { isSuccess } = await onApprove({ cardId, cardRequestId });

    if (isSuccess) {
      await service({ dispatch }).getCards();
      alert('success', 'Tarjeta aprobada');
      handleModalClose();
    }
  };

  const handleDecline = async () => {
    const cardRequestId = cardDetails.cardAdminRequestId;
    const { isSuccess } = await onDecline({ cardId, cardRequestId });

    if (isSuccess) {
      await service({ dispatch }).getCards();
      handleModalClose();
    }
  };

  return (
    <Modal
      open={approvalModal}
      onClose={handleModalClose}
      showCrossSign={false}
      disableBackDropClick={false}
      showCloseOnModal
    >
      <ModalCardChanges
        handleSubmit={handleConfirmation}
        handleModalClose={handleDecline}
        initialValues={cardDetails}
      />
    </Modal>
  );
};

ApprovalModal.propTypes = {
  onApprove: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired
};

export default ApprovalModal;
