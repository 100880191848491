/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FileInput from '../FileInput';

import { getFileName } from '../../../../helpers/helpers';
import { transformKycMultipart } from '../../../../Services/softwallService';
import { useEffect } from 'react';

const MultipartFileInput = ({
  documentName,
  documentId,
  onChange,
  ...otherProps
}) => {
  const [fileName, setFileName] = useState();

  const handleChange = e => {
    let multiPartFile = null;
    let file = '';

    if (e.target.files.length) {
      file = e.target.files[0];
      const name = getFileName(file.name, documentName);
      multiPartFile = transformKycMultipart(name, file, documentId);
    }

    let nameObtained = file ? file.name : '';
    setFileName(nameObtained);

    if (onChange) {
      onChange({
        target: {
          name: otherProps.name || nameObtained,
          value: multiPartFile,
          file: e.target.files[0]
        }
      });
    }
  };

  useEffect(() => {
    if (otherProps.resetInput)
      handleChange({ target: { value: '', files: [] } });
    // eslint-disable-next-line
  }, [otherProps.resetInput]);

  return (
    <FileInput
      fileName={fileName}
      onClick={otherProps.onClick}
      onChange={handleChange}
      {...otherProps}
    />
  );
};

MultipartFileInput.propTypes = {
  documentName: PropTypes.string,
  documentId: PropTypes.number,
  onChange: PropTypes.func
};

export default MultipartFileInput;
