import React, { useRef, useState } from 'react';

// Auxiliar libraries
import * as html2canvas from 'html2canvas';
import log from 'loglevel';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Styled components
import { useHistory } from 'react-router';
import { Container, HideContainer } from './styles';

// Components
import EmptyStateCopy from '../../../shared/components/EmptyStateCopy';
import ErrorBoundaryHandler from '../../../../ErrorBoundary';
import ManageTransactionTypes from './ManageTransactionTypes';
import Modal from '../../../shared/components/Modal';
import Voucher from '../ProofOfPayments/Voucher';
import RemoveTransactionTagModal from '../RemoveTransactionTagModal';

// Services
import { clearTags, setTagsAction } from '../../../tags/redux/tag-action';
import { getTransactionDetails } from './helpers/get-proof-of-transaction';
import { sendTransactionByEmail } from '../../../../Services/spei';
import { transactionTypes } from './constants';
import { detailsLink } from '../ProofOfPayments/MultiTransferModal/constants';
import { service } from '../../../tags/service';
import { REMOVE_TAG_FROM_TRANSACTION } from '../../../../reducers/types';

export const Component = ({ paymentOrders, notFound, type }) => {
  const [open, setOpen] = useState(false);
  const [transferDetails, setTransferDetails] = useState({});
  const [reversalTransactions, setReversalTransactions] = useState([]);
  const [reversalDetails, setReversalDetails] = useState({});
  const [multiTransferDetails, setMultiTransferDetails] = useState({});
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [tagRemove, setTagRemove] = useState(null);
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const sendToEmail = el => {
    const isMultiTransfer = !el.transaction_id; // Multitransfer order doesn't have a transaction id
    const transactionTypeId = el.transaction_type_id;
    const key = isMultiTransfer
      ? el.batch_process_id
      : el.tracking_key || el.credit_transaction_id;
    const isPaymentOrder = transactionTypes.paymentOrders.includes(
      transactionTypeId
    );

    const getTransactionType = () => {
      if (!isMultiTransfer && !isPaymentOrder) {
        return 'credit_card';
      }
      if (isMultiTransfer) {
        return 'multi_transfer';
      }
      if (isPaymentOrder) {
        return 'payment_order';
      }
    };

    const body = {
      transaction_identifier: String(key),
      transaction_type: getTransactionType()
    };

    sendTransactionByEmail(body)
      .then()
      .catch(e => log.error(e));
  };

  const setPaymentOrderDetails = data => ({
    ...data,
    secondary_text: data.beneficiary_name
  });

  const setCreditPurchaseDetails = data => {
    const details = data;

    if (!data.currency_code || data.currency_code === 'MXN') {
      details.total_origin_amount = '';
      details.exchange_rate = '';
    } else {
      details.secondary_text = data.country_name;
      details.total_origin_amount = `${data.total_origin_amount} ${data.currency_code}`;
      details.exchange_rate = `$ ${data.exchange_rate}`;
    }

    return details;
  };

  const setProofOfPaymentDetails = (transactionTypeId, data) =>
    transactionTypes.paymentOrders.includes(transactionTypeId)
      ? setPaymentOrderDetails(data)
      : setCreditPurchaseDetails(data);

  const getProofOfPayment = async (
    trackingKey,
    transactionTypeId,
    transaction_id
  ) => {
    const { data } = await getTransactionDetails(
      trackingKey,
      transactionTypeId
    );

    if (data) {
      setTransferDetails(
        setProofOfPaymentDetails(transactionTypeId, {
          ...data,
          transaction_id
        })
      );

      const tags = data.tags || [];
      dispatch(setTagsAction({ tags }));

      setOpen(true);
    }
  };

  const goToMultitransfersModal = async batch_process_id =>
    history.push(detailsLink(batch_process_id));

  const download = () => {
    componentRef.current.children.innerHTML = '&nbsp;';

    html2canvas(componentRef.current, { scale: 1 }).then(canvas => {
      const url = canvas.toDataURL();
      const a = document.createElement('a');

      a.href = url;
      a.download = 'comprobante-de-transaccion.png';
      a.click();
    });
  };

  const sendToEmailAction = (key, transactionTypeId) => ({
    method: e => {
      key.batch_process_id
        ? goToMultitransfersModal(key.batch_process_id)
        : sendToEmail(key, transactionTypeId);
    },
    icon: 'mail',
    class: 'mail',
    helperTooltip: 'Reenviar comprobante a mi correo'
  });

  const goToReversalsModal = reversals => {
    if (reversals.length > 0) {
      setReversalDetails({
        amount: reversals[0]?.amount,
        transaction_datetime: reversals[0]?.transaction_datetime,
        reference_number: reversals[0]?.reference_number_reversal,
        last_four_digits: reversals[0]?.last_four_digits,
        transaction_type_id: 6
      });
      setReversalTransactions(reversals);
      setOpen(true);
    }
  };

  const downloadAction = el => {
    const {
      batch_process_id,
      tracking_key,
      credit_transaction_id,
      transaction_id,
      transaction_type_id,
      tags
    } = el;

    return {
      method: e => {
        !transaction_id // Multitransfer order doesn't have a transaction id
          ? goToMultitransfersModal(batch_process_id)
          : getProofOfPayment(
              tracking_key || credit_transaction_id,
              transaction_type_id,
              transaction_id,
              tags
            );
      },
      icon: 'download',
      class: 'download',
      helperTooltip: 'Descargar comprobante'
    };
  };

  const handleClose = () => {
    setOpenRemoveModal(false);
    setOpen(false);
    setTransferDetails({});
    setReversalTransactions([]);
    setReversalDetails({});
    dispatch(clearTags());
  };

  const handleOpenRemoveTagModal = tag => {
    setTagRemove(tag);
    setOpenRemoveModal(true);
    setOpen(false);
  };

  const handleCloseRemoveTagModal = () => {
    setOpenRemoveModal(false);
    setOpen(true);
    getProofOfPayment(
      transferDetails.tracking_key || transferDetails.credit_transaction_id,
      transferDetails.transaction_type_id
    );
  };

  const handleDeleteTransactionTag = async () => {
    const { isSuccess } = await service({ dispatch }).deleteCardTransactionTag({
      tag: tagRemove
    });
    if (isSuccess && tagRemove?.transactionId) {
      dispatch({
        type: REMOVE_TAG_FROM_TRANSACTION,
        payload: {
          transactionId: tagRemove.transactionId,
          tagId: tagRemove.tagId
        }
      });
    }
  };

  return (
    <Container data-test="Transactions">
      {openRemoveModal && tagRemove && (
        <RemoveTransactionTagModal
          onClose={handleCloseRemoveTagModal}
          tag={tagRemove}
          customDelete={handleDeleteTransactionTag}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        disableBackDropClick={false}
        showCloseOnModal
        closeIconWhite
      >
        {transferDetails.amount && (
          <Voucher
            details={transferDetails}
            onClick={download}
            onClose={handleClose}
            onOpenRemoveModal={handleOpenRemoveTagModal}
          />
        )}

        {reversalTransactions.length > 0 && (
          <Voucher
            details={reversalDetails}
            onClick={download}
            onClose={handleClose}
            reversals={reversalTransactions}
          />
        )}
      </Modal>

      {notFound ? (
        <EmptyStateCopy
          copy="Ningún movimiento coincide con"
          searchParam={notFound}
        />
      ) : (
        <ManageTransactionTypes
          paymentOrders={paymentOrders}
          firstAction={sendToEmailAction}
          secondAction={downloadAction}
          multiTransferAction={multiTransfer =>
            goToMultitransfersModal(multiTransfer.batch_process_id)
          }
          reversalsAction={reversals => goToReversalsModal(reversals)}
          type={type}
        />
      )}
    </Container>
  );
};

Component.propTypes = {
  paymentOrders: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  notFound: PropTypes.string,
  type: PropTypes.string
};

Component.defaultProps = {
  notFound: '',
  type: ''
};

const Transactions = props => (
  <ErrorBoundaryHandler
    component={<Component {...props} />}
    name="Transactions"
  />
);

export default Transactions;
