import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { getAccountStatementPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const getAccountStatementService = async ({ year, month }) => {
  try {
    const { data, status, error } = await customAxiosInstance().get(
      `${getAccountStatementPath}/${year}/${month}`
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'account-service'
    });
  } catch (error) {
    log.error('account-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
