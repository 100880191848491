import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const Container = styled.div`
  width: 100%;
  overflow-x: scroll;
  background-color: ${colors.carbon4};
  min-height: 13.2rem;
  ${({ sm }) =>
    sm &&
    `
    height: 13.2rem;
  `}

  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.carbon2};
    border-radius: 3px;
    height: 16rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;

export const Grid = styled.table`
  width: 100%;
  border-spacing: 0px;
  background-color: ${colors.carbon4};
  thead {
    text-align: left;
  }
`;

export const HeadItem = styled.th`
  ${fonts.quickSandSemiBold};
  font-size: 1.35rem;
  color: ${colors.carbon};
  letter-spacing: 0.17px;
  height: 5.1rem;
  position: sticky;
  top: 0;
  background-color: ${colors.gray1};
  padding: 0 2.5rem;
`;

export const Item = styled.td`
  ${fonts.barlowRegular};
  font-size: 1.5rem;
  letter-spacing: 0.91px;
  color: ${colors.body};
  height: 4rem;
  background-color: ${colors.carbon4};
`;
