import { ENGINEER_ROLE_ID } from '../constants';

export const UserRowEntry = ({
  user_id,
  name,
  surname_1,
  surname_2,
  spend_limit,
  department_id,
  department_name,
  role_id,
  role_name,
  email,
  admin_flag,
  occupation_job,
  statuses,
  latest_delete_request,
  latest_update_request,
  phone_number,
  is_email_token_expired,
  remaining_spend_limit
}) => {
  return {
    userId: user_id,
    userName: `${name} ${surname_1 || ''} ${surname_2 || ''}`,
    name,
    surname_1,
    surname_2,
    spendingLimit: Number(spend_limit).toFixed(2),
    departmentId: department_id,
    departmentName: department_name,
    userType: role_name,
    email,
    adminFlag: admin_flag,
    position: occupation_job,
    statuses,
    adminUpdateRequest: latest_update_request,
    adminDeleteRequest: latest_delete_request,
    phone: phone_number,
    roleId: role_id,
    isEmailTokenExpired: is_email_token_expired,
    remainingSpendLimit: remaining_spend_limit
  };
};

export const CreateUserRequest = ({
  email,
  adminFlag,
  spendingLimit,
  roleId,
  name,
  department,
  position,
  surname1,
  surname2
}) => {
  // override Engineer values
  if(roleId === ENGINEER_ROLE_ID) {
    spendingLimit = '0';
    adminFlag = false;
  }

  return {
    email,
    role_id: roleId,
    admin_flag: adminFlag,
    spend_limit: spendingLimit,
    occupation_job: position,
    name,
    department_id: department,
    surname_1: surname1,
    surname_2: surname2
  };
};

export const CreateDepartment = ({ department_id, department_name }) => ({
  departmentId: department_id,
  departmentName: department_name
});

export const CreateDepartments = departmentsData =>
  departmentsData.map(departmentData => CreateDepartment(departmentData));

export const UpdateUserRequest = ({
  department,
  name,
  phone,
  position,
  roleId,
  spendingLimit,
  user_id,
  surname1,
  surname2
}) => {
  const person_data = {
    department_id: department,
    name,
    occupation_job: position,
    surname_1: surname1,
    surname_2: surname2
  };

  if (phone) person_data.phone_number = phone;

  return {
    user_id: user_id,
    user_data: {
      role_id: roleId,
      spend_limit: spendingLimit
    },
    person_data
  };
};

export const resendEmailObject = email => ({ email });
