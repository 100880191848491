import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { captureEvent, Severity } from '@sentry/browser';

import EntryEmptyState from '../micro-frontends/shared/components/EntryEmptyState';

const ErrorBoundaryHandler = ({ component, name }) => {
  return (
    <ErrorBoundary
      data-test="ErrorBoundaryHandler"
      fallbackRender={({ error, resetErrorBoundary }) => {
        captureEvent({
          level: Severity.Warning,
          message: `errorBoundary: ${error.message}`,
          extra: {
            component: { name },
            errorBoundary: true,
            errorName: error.name
          }
        });
        return (
          <EntryEmptyState
            title="¡Algo pasó!"
            description="Surgió un problema al cargar el componente. Por favor inténtalo de nuevo"
            action={resetErrorBoundary}
            actionText="Reintentar"
          />
        );
      }}
    >
      {component}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryHandler;
