import React, { useEffect, useState } from 'react';

import MultiTransferModal from './MultiTransferModal';
import Modal from '../../../../shared/components/Modal';

import { getMultiTransactionDetails } from '../../Transactions/helpers/get-proof-of-transaction';
import { getConfirmedBatches } from './infrastructure/download-confirmed-batches';
import { getFailedBatches } from './infrastructure/download-failed-batches';
import { getZipBatches } from './infrastructure/download-zip-batches';
import { useHistory, useParams } from 'react-router';
import { downloadFile, fileFormat } from '../../../../../helpers/fileManager';
import { transactionTypeIds } from '../../Transactions/constants';

const MultiTransferById = () => {
  const { batchId } = useParams();
  const [details, setDetails] = useState();
  const [open, setOpen] = useState();
  const history = useHistory();

  const getBatchDetails = async () => {
    const { data } = await getMultiTransactionDetails(batchId);
    const transactions = data[0];
    setDetails({
      ...transactions,
      transaction_type_id: transactionTypeIds.order
    });
    setOpen(true);
  };

  const downloadFailed = async () => {
    const { data, error } = await getFailedBatches({ batchId });

    if (error) return;

    const b64 = data[0]?.transactions;
    handleDownload(b64, `${details?.batch_description}-fallidas`, 'csv');
  };

  const downloadSuccess = async () => {
    const { data, error } = await getConfirmedBatches({ batchId });

    if (error) return;

    const b64 = data[0]?.transactions;
    handleDownload(b64, `${details?.batch_description}-exitosas`, 'csv');
  };

  const downloadZip = async () => {
    const { data, error } = await getZipBatches({ batchId });

    if (error) return;

    const b64 = data[0]?.transactions;
    handleDownload(b64, details?.batch_description, 'zip');
  };

  const handleDownload = (file, batchTitle, type) => {
    downloadFile(fileFormat.csv, 'base64', file, batchTitle, type);
  };

  const handleClose = () => {
    setOpen(false);
    history.push('/activity');
  };

  useEffect(() => {
    getBatchDetails();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableBackDropClick={false}
      showCloseOnModal
    >
      <MultiTransferModal
        details={details}
        downloadFailed={downloadFailed}
        downloadSuccess={downloadSuccess}
        downloadZip={downloadZip}
      />
    </Modal>
  );
};

export default MultiTransferById;
