import React from 'react';

// Auxiliar libraries
import { useSelector, useDispatch } from 'react-redux';

// Components
import CardActivation from '../../../shared/components/CardActivation';

// Others
import { selectEmail } from '../../redux/cards-selectors';
import { service } from '../../service';

const CardActivationModal = ({
  openModal,
  setCardActivationModal,
  togglecardActivationModal
}) => {
  const dispatch = useDispatch();
  const email = useSelector(selectEmail);

  const handleOnClose = async () => {
    await service({ dispatch }).getCards();
    togglecardActivationModal();
  };

  return (
    <CardActivation
      userEmail={email}
      open={openModal}
      handleOnClose={handleOnClose}
    />
  );
};

export default CardActivationModal;
