export const fileFormat = {
  csv: 'text/csv',
  pdf: 'application/pdf',
  xml: 'application/xml',
};

export const downloadFile = (
  format,
  encoding,
  file,
  name,
  extension,
  isBlob
) => {
  const link = `data:${format};${encoding},${file}`;
  const encodedLink = !isBlob ? encodeURI(link) : URL.createObjectURL(file);
  const a = document.createElement('a');

  a.href = encodedLink;
  a.download = `${name}.${extension}`;
  a.click();
};

export const downloadUrlFile = (
  url,
  fileName
) => {
  fetch(url)
    .then(resp => resp.blob())
    .then(blobobject => {
      const blob = window.URL.createObjectURL(blobobject);
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = blob;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(blob);
    })
    .catch(() => console.log('An error in downloadin gthe file sorry'));
};

export const removeExtension = fileName => {
  const indexOfDot = fileName.indexOf('.');
  if (indexOfDot !== -1) return fileName.slice(0, indexOfDot);
  return fileName;
};
