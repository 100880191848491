import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    fontSize: '1.6rem',
    color: '#989898'
  }
});

const Paragraph = ({ text }) => {
  const classes = useStyles();
  return <p className={classes.root}>{text}</p>;
};

export default Paragraph;
