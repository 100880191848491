import "./index.scss";

import React from "react";

const Index = ({ onClick, label, type = "primary", width = 200, disabled = false, style={} }) => {
    let className = "button " + type;
    if (disabled === true) {
        className += " disabled";
        onClick = undefined;
    }
    return (
        <div className={className} style={{ width: width, ...style }} onClick={onClick}>
            {label}
        </div>
    );
};

export default Index;