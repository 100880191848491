import { tagActions } from './tag-action-types';

const initialState = {
  tags: [],
  tagOptions: []
};

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case tagActions.ADD_TAG:
      return {
        ...state,
        tags: [...state.tags, action.payload]
      };
    case tagActions.ADD_TAG_CREDIT_TRANSACTION:
      return {
        ...state,
        tags: [...state.tags, action.payload]
      };
    case tagActions.ADD_SPEI_TAG:
      return {
        ...state,
        tags: [...state.tags, action.payload]
      };
    case tagActions.REMOVE_TAG:
      return {
        ...state,
        tags: state.tags.filter(
          tag => tag.tagId !== Number(action.payload.tagId)
        )
      };
    case tagActions.SET_TAGS:
      return {
        ...state,
        tags: action.payload
      };
    case tagActions.SET_TAG_OPTIONS:
      return {
        ...state,
        tagOptions: action.payload
      };
    case tagActions.SET_TAGS_SPEI:
      return {
        ...state,
        tags: action.payload
      };
    case tagActions.SET_TAGS_CARD_TRANSACTION:
      return {
        ...state,
        tags: action.payload
      };
    case tagActions.ADD_TAG_OPTION:
      return {
        ...state,
        tagOptions: [...state.tagOptions, action.payload]
      };
    case tagActions.REMOVE_TAG_OPTION:
      return {
        ...state,
        tagOptions: state.tagOptions.filter(
          tag => tag.tagId !== Number(action.payload)
        )
      };
    case tagActions.CLEAR_TAGS:
    case tagActions.PURGE:
      return initialState;
    default:
      return state;
  }
};

export default tagsReducer;
