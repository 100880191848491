import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const LabelNameContainer = styled.span`
  ${fonts.quickSandMedium};
  font-size: 1.4rem;
  color: ${colors.carbon};
  display: none;
  margin: 1rem 0 1.5rem 0.5rem;

  @media screen and (min-width: 768px) {
    display: inline-block;
  }
`;
