/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../../shared/components/Avatar/Avatar';
import Details from '../../../shared/components/Details';
import Title from '../../../shared/components/Title';

import { EntryContainer } from './BeneficiaryEntry.styles';

const Entry = ({
  colors,
  initials,
  alias,
  fullName,
  bankingDetails,
  accountCode
}) => (
  <EntryContainer data-test="entryComponent" className="entryComponent">
    <Avatar colors={colors}>{initials}</Avatar>

    <div className="entryDetails">
      <Title text={alias} tooltipContent={alias} />
      {fullName ? (
        typeof fullName === 'string' ? (
          <Details
            mainText={fullName}
            secondaryText={bankingDetails}
            secondaryTextDetails={accountCode}
            className={'mainText'}
            className2={'secondaryText'}
            data-test="fullName"
          />
        ) : (
          fullName
        )
      ) : null}
    </div>
  </EntryContainer>
);

Entry.propTypes = {
  alias: PropTypes.string,
  colors: PropTypes.object,
  fullName: PropTypes.any,
  initials: PropTypes.any
};

export default Entry;
