import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosAuxConfig';
import { buroGenerate } from '../utils/constants';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const buroGenerateService = async ({ customer, account }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
        buroGenerate,
      {
        data:{
          customer,
          account
        }
      },
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'kyc-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('kyc-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
