import { CardCreator } from '../domain/card-data';

const getCardChangeRequestHandler = ({ getCardChangeRequest }) => async ({
  cardId
}) => {
  const { error, data: cardData } = await getCardChangeRequest({
    cardId
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  const card = CardCreator({ data: cardData });

  return {
    isSuccess: true,
    card
  };
};

export { getCardChangeRequestHandler };
