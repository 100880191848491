import {
  USER_DATA,
  SAVE_USER_EMAIL,
  SAVE_REFRESH_TOKEN,
  SAVE_TOKEN,
  SAVE_TOKEN_EXP,
  SET_SPENDING_LIMIT,
  PURGE,
  UPDATE_USER_STATUS,
  CUSTOMER_INFO
} from './types';

const INITIAL_STATE = {
  token: null,
  tokenDetails: null,
  loading: false,
  refreshToken: null,
  email: null,
  user: {},
  spendingLimit: null
};

//TODO: Statemachine
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE;
    case SAVE_REFRESH_TOKEN:
      return { ...state, refreshToken: action.payload };
    case SAVE_TOKEN:
      return { ...state, token: action.payload };
    case SAVE_TOKEN_EXP:
      return { ...state, tokenDetails: action.payload };
    case SET_SPENDING_LIMIT:
      return { ...state, spendingLimit: action.payload };
    case USER_DATA:
      return { ...state, user: action.payload };
    case SAVE_USER_EMAIL:
      return { ...state, email: action.payload };
    case UPDATE_USER_STATUS:
      return { ...state, user: { ...state.user, status: action.payload } };
    case CUSTOMER_INFO:
      return { ...state, customer: action.payload };
    default:
      return state;
  }
};
