import React from 'react';

import { ModalContainer, StyledModal } from './Modal.styles';

const Modal = ({ children }) => {
  return (
    <ModalContainer>
      <StyledModal open={true} disableBackDropClick={true}>
        {children}
      </StyledModal>
    </ModalContainer>
  );
};

export default Modal;
