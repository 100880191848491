import React from 'react';

// Auxiliary libraries
import PropTypes from 'prop-types';

// Components
import Status from '../Status/Status';
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';

// Styles
import { RowContainer } from './styles';

// Helpers
import { UserRowEntry } from '../../object-creators/object-creators';
import { parseAmount } from '../../../../helpers/helpers';

export const Component = ({ details, getUser, columns, index }) => {
  const userDetails = UserRowEntry(details);

  const validateData = data => {
    return data ? data : 'No disponible';
  };

  const splitName = name => {
    if (!name) return;
    if (name.length > 20) return `${name.substring(0, 21)}...`;
    return name;
  };

  const translateUserType = userType => {
    if (userType === 'Admin') return 'Administrador';
    if (userType === 'Basic User') return 'Básico';
    return userType;
  };

  const getCurrentUser = () => {
    getUser(userDetails, 'update');
  };

  const isGreaterThan25Percent = () => {
    if (!userDetails.remainingSpendLimit) return true;

    const twentyFivePercent = userDetails.spendingLimit * 0.25;

    if (userDetails.remainingSpendLimit.replace(/,/g, '') > twentyFivePercent)
      return true;
    return false;
  };

  return (
    <RowContainer
      data-test="TableRow"
      columns={columns}
      index={index}
      onClick={() => {
        getCurrentUser();
      }}
    >
      <div>{validateData(splitName(userDetails.userName))}</div>
      <div className={!isGreaterThan25Percent() ? 'redColor' : ''}>
        {parseAmount(userDetails.remainingSpendLimit)}
      </div>
      <div>{validateData(parseAmount(userDetails.spendingLimit))}</div>
      <div>{splitName(userDetails.departmentName) || 'No asignado'}</div>
      <div>{validateData(translateUserType(userDetails.userType))}</div>
      <div>
        <Status
          getUser={getUser}
          userDetails={userDetails}
          userName={validateData(splitName(userDetails.userName))}
        />
      </div>
    </RowContainer>
  );
};

Component.propTypes = {
  details: PropTypes.object.isRequired,
  deleteUser: PropTypes.func,
  getUser: PropTypes.func,
  userId: PropTypes.number
};

const TableRow = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="TableRow from Users CRUD"
    />
  );
};

export default TableRow;
