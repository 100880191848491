/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';

// Auxiliary libraries
import PropTypes from 'prop-types';

// Components
import EntryEmptyState from '../../../shared/components/EntryEmptyState/EntryEmptyState';
import EmptyStatePreview from './EmptyStatePreview';

// Assets
import dummyUsers from './dummy-users';
import loading from '../../../../assets/waiting-red-lines.gif';

export const EmptyState = ({ createUser, reloadUsers, users, error }) => {
  const SyncingEmptyState = () => {
    return (
      <EntryEmptyState
        img={loading}
        imgWidth={80}
        data-test="EntryEmptyState"
        title="Sincronizando usuarios"
        description="Tus usuarios se están sincronizando. Esto podría tomar un momento."
      />
    );
  };

  const Error = () => {
    return (
      <EntryEmptyState
        data-test="EntryEmptyState"
        title="¡Algo pasó!"
        description="Surgió un problema al cargar tu información. Por favor inténtalo de nuevo"
        action={reloadUsers}
        actionText="Reintentar"
        disableAction={false}
      />
    );
  };

  const NoUsers = () => {
    return (
      <EntryEmptyState
        data-test="EntryEmptyState"
        title="Aún no tienes usuarios registrados"
        description="Crea tu primer usuario para tener la experiencia completa."
        action={createUser}
        actionText="Agregar usuario"
        disableAction={false}
      />
    );
  };

  return (
    <EmptyStatePreview
      data-test="EmptyState"
      EntryEmptyState={
        error ? <Error /> : !users ? SyncingEmptyState() : NoUsers()
      }
      label="Cuando agregues usuarios, lucirán de la siguiente manera. Recuerda que puedes crear usuarios de tipo:"
      labelSuccess="administrador"
      labelBold="básico"
      users={dummyUsers}
      columns={4}
    />
  );
};

EmptyState.propTypes = {
  createUser: PropTypes.func.isRequired,
  reloadUsers: PropTypes.func.isRequired,
  error: PropTypes.any,
  users: PropTypes.any
};

export default EmptyState;
