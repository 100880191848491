// React imports
import React, { Fragment, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Auxiliary librarys imports
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import { Grid, InputAdornment, TextField, withWidth } from '@material-ui/core/';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

// Own Components
import CustomTooltip from '../../CustomTooltip';
import {
  createContactCommunicationCompany,
  updateCompany
} from '../../../Services/Company';
import { getSpacing } from '../../../helpers/';
import log from 'loglevel';
import moment from 'moment';
import { PrimaryButton } from '../../Shared';
import {
  setIsUpdated,
  setUpdatedRequired,
  updateStep,
  validateIsRequired,
  persistFormData
} from '../../../actionCreators/onboardingStepsActions';

// Assets
import catalogue_mercantile_business from '../../../catalogs/catalog_mercantile_business.json';
import eSignature from '../../../assets/serial_number.svg';
import { useStyles } from './FormStyles';

const Form = props => {
  // Props
  const { width } = props;
  const {
    values,
    errors,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
    touched
  } = props;

  const {
    mercantil_business,
    nationality,
    phoneNumber,
    rfc,
    serial_number_fiel,
    incorporationDate
  } = values;

  // Redux
  const dispatch = useDispatch();
  const onboardingState = useSelector(state => state.onboardingStepsReducer);

  // Hooks
  const [numberPhone, setNumberPhone] = React.useState({
    contact_communication_type_id: 2, //Check the id reference on contact communication catalogue on src/catalogs/catalog_contact_communication_type.json
    description: ''
  });
  const [selectedDate, setSelectedDate] = React.useState(
    values?.incorporationDate
      ? new Date(values.incorporationDate)
      : new Date(incorporationDate)
  );
  const [userInfo, setUserInfo] = React.useState(values ? values : {});
  //eslint-disable-next-line
  const [updatedCompany, setUpdatedCompany] = React.useState(false);
  const [createdCommunication, setCreatedCommunication] = React.useState(false);

  // Other variables
  const mexicanNationalityId = 4; // According nationality catalog

  // Classes
  const classes = useStyles();

  const handleDateChange = date => {
    setSelectedDate(date);
    setFieldValue('incorporationDate', date);
    change.bind(null, 'incorporationDate');
  };

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
    setData(e);
    setNumber(e);
    setUpdatedRequired(dispatch, true);
  };

  const setData = e => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };

  const setNumber = e => {
    setNumberPhone({
      ...numberPhone,
      description: phoneNumber
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!onboardingState.isUpdateRequired) {
      setUpdatedCompany(true);
      setCreatedCommunication(true);
      return;
    }

    let updateCompanyBody = {
      mercantil_business: userInfo.mercantil_business,
      nationality_id: mexicanNationalityId,
      rfc: userInfo.rfc,
      serial_number_fiel: userInfo.serial_number_fiel,
      incorporation_date: moment(selectedDate).format('DD-MM-YYYY'),
      phone_number: phoneNumber
    };

    updateCompany(updateCompanyBody)
      .then(res => {
        if (res.status === 200) {
          setUpdatedCompany(true);
          const { currentStep } = onboardingState;
          persistFormData(dispatch, currentStep, values);
        }
      })
      .catch(e => log.error(e));
    createContactCommunicationCompany(numberPhone)
      .then(res => {
        if (res.status === 201) {
          setCreatedCommunication(true);
        }
      })
      .catch(e => log.error(e));
  };

  useEffect(() => {
    validateIsRequired(dispatch, onboardingState);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isValid) {
      setIsUpdated(dispatch, true);
    } else {
      setIsUpdated(dispatch, false);
    }
    //eslint-disable-next-line
  }, [isValid, onboardingState.isUpdateRequired]);

  useEffect(() => {
    if (updatedCompany && createdCommunication) {
      updateStep(dispatch, onboardingState, 'nextStep');
    }
    //eslint-disable-next-line
  }, [updatedCompany, createdCommunication]);

  //TO DO: Refactor inputs with formik properties
  return (
    <Fragment>
      {props ? (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={getSpacing(width)}>
            <Grid item xs={12} md={6}>
              <TextField
                select
                id="mercantil_business"
                name="mercantil_business"
                label="Giro mercantil"
                helperText={
                  touched.mercantil_business ? errors.mercantil_business : ''
                }
                className={`${classes.dropdown} ${classes.textField}`}
                error={
                  touched.mercantil_business &&
                  Boolean(errors.mercantil_business)
                }
                onChange={change.bind(null, 'mercantil_business')}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                value={mercantil_business}
                fullWidth
              >
                {!mercantil_business ? (
                  <option key="none" value={0}>
                    Selecciona un giro mercantil
                  </option>
                ) : null}
                {catalogue_mercantile_business.map(option => (
                  <option key={option.id} value={option.keyword}>
                    {option.keyword}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="incorporationDate"
                  name="incorporationDate"
                  label="Fecha de constitución"
                  className={classes.textField}
                  variant="dialog"
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  error={
                    touched.incorporationDate ||
                    Boolean(errors.incorporationDate)
                  }
                  helperText={
                    errors.incorporationDate &&
                    errors.incorporationDate ===
                      'incorporationDate must be a `date` type, but the final value was: `Invalid Date` (cast from the value `Invalid Date`).'
                      ? 'Formato inválido'
                      : errors.incorporationDate
                  }
                  disableFuture
                  openTo="year"
                  views={['year', 'month', 'date']}
                  okLabel="Escoger"
                  cancelLabel="Cancelar"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            helperText={touched.phoneNumber ? errors.phoneNumber : ''}
            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
            label="Teléfono"
            fullWidth
            value={phoneNumber}
            onChange={change.bind(null, 'phoneNumber')}
            className={classes.textField}
          />
          <TextField
            id="rfc"
            name="rfc"
            helperText={touched.rfc ? errors.rfc : ''}
            error={touched.rfc && Boolean(errors.rfc)}
            label="RFC"
            fullWidth
            value={rfc.toUpperCase()}
            onChange={change.bind(null, 'rfc')}
            className={classes.textField}
          />
          <div className={classes.checkbox}>
            <label htmlFor="nationality" className={classes.nationality}>
              <input
                id="nationality"
                name="nationality"
                type="checkbox"
                checked={nationality}
                className="checkbox-round"
                value={!nationality}
                onChange={change.bind(null, 'nationality')}
              />
              De nacionalidad mexicana
            </label>
          </div>
          <TextField
            id="serial_number_fiel"
            name="serial_number_fiel"
            helperText={
              touched.serial_number_fiel ? errors.serial_number_fiel : ''
            }
            error={
              touched.serial_number_fiel && Boolean(errors.serial_number_fiel)
            }
            label="Número de serie de Firma Electrónica Avanzada"
            fullWidth
            value={serial_number_fiel}
            className={classes.textField}
            onChange={change.bind(null, 'serial_number_fiel')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CustomTooltip
                    title={
                      <div style={{ width: '30rem' }}>
                        <p>
                          Son 20 dígitos que puedes encontrar en tu Constancia
                          de Firma Electrónica Avanzada ó en el comprobante de
                          Certificado de Sello Digital.
                        </p>
                        <img
                          alt="Número de serie de Firma Electrónica Avanzada"
                          style={{ width: '30rem' }}
                          src={eSignature}
                        />
                      </div>
                    }
                  />
                </InputAdornment>
              )
            }}
          ></TextField>

          <div className={classes.button}>
            <PrimaryButton
              type="submit"
              text="Continuar"
              disabled={
                !(
                  isValid &&
                  onboardingState.isUpdateRequired &&
                  onboardingState.isUpdated
                ) && !(!isValid && !onboardingState.isUpdateRequired)
              }
            />
          </div>
        </form>
      ) : null}
    </Fragment>
  );
};

export default withWidth()(Form);
