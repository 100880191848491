import styled from 'styled-components';

import Button from '../../../shared/components/Button';

export const InfoBarWrapper = styled.div`
  white-space: nowrap;

  .activityInfoBarItem {
    padding-right: 0;
    min-width: auto;
    width: fit-content;
    margin: 0;
    margin-bottom: 1.2rem;
  }

  .infoBarContentStart {
    min-width: 25%;
    max-width: 30%;

    @media (max-width: 768px) {
      max-width: none;
    }
  }

  .infoBarContentEnd {
    justify-content: space-evenly;
    width: 100%;
  }

  @media (max-width: 480px) {
    .infoBarContentEnd {
      justify-content: space-between;
    }
  }
`;

export const BasicUserButton = styled(Button)`
  float: right;
  margin: 3rem 0 4rem;
`;

export const InfoBarItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  height: 100%;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const InfoBarLink = styled.button`
  font-size: 13px;
  font-family: 'Barlow';
  font-weight: 600;
  text-decoration: none;
  color: #1790cf;
  margin-left: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 960px) {
    padding: 0.5rem;
    margin: 0;
  }
`;
