import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import {
  getShortDate,
  parseAmount,
  splitAmount
} from '../../../../helpers/helpers';
import { getActions } from '../../helpers';

// Assets
import LogoImg from '../../../../Dashboard4.0/assets/images/deltai-logo.svg';

// Styled Component
import {
  BodyContainer,
  Logo,
  VoucherContainer,
  IconContainer,
  IconText
} from './styles';

// Components
import Header from '../Header/Header';
import LabelGroup from '../LabelGroup/LabelGroup';
import CollectionVoucher from './CollectionVoucher';
import PaymentVoucher from './PaymentVoucher';
import Icon from '../../../shared/components/Icon/Icon';
import Modal from '../../../shared/components/Modal/Modal';

// Constants
import { INCOME, EXPENSE, STATUS } from '../../constants';

const InvoiceVoucher = ({ details, open, handleClose }) => {
  const {
    amount,
    invoice_type,
    invoice_date,
    payment_date,
    invoice_status,
    invoice_type_id
  } = details;

  const voucherTypes = {
    [INCOME]: {
      component: <CollectionVoucher details={details} />,
      text: 'Factura enviado',
      type: 'sent'
    },
    [EXPENSE]: {
      component: <PaymentVoucher details={details} />,
      text: 'Factura recibida',
      type: 'received'
    }
  };

  const actions = getActions(invoice_status, payment_date, invoice_type);
  if (invoice_type_id) {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        disableBackDropClick={false}
        showCloseOnModal
      >
        <VoucherContainer>
          <Header
            amount={splitAmount(parseAmount(amount))}
            className="amount"
            type={voucherTypes[invoice_type].type}
            text={voucherTypes[invoice_type].text}
          />
          <BodyContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Logo src={LogoImg} />
              <LabelGroup
                issuedDate={getShortDate(invoice_date)?.toLowerCase()}
                paidDate={
                  payment_date && invoice_status === STATUS.VIGENTE
                    ? getShortDate(payment_date).toLowerCase()
                    : null
                }
                align="right"
              />
            </div>

            {voucherTypes[invoice_type].component}
            <div
              style={{
                margin: '20px auto 0 auto',
                width: '80%',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center'
              }}
            >
              {actions.map((el, ix) => {
                if (el.disabled) {
                  return;
                }
                return (
                  <IconContainer onClick={el.method} key={`${el.text}`}>
                    <Icon icon={el.icon} className={el.class} />
                    {el.text ? (
                      <IconText className={el.class}>{el.text}</IconText>
                    ) : null}
                  </IconContainer>
                );
              })}
            </div>
          </BodyContainer>
        </VoucherContainer>
      </Modal>
    );
  }
  return null;
};

export default InvoiceVoucher;
