// React
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { PrimaryButton, WhiteButton } from '../Shared';

// Third party libraries
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { closeSession } from '../../Services/authService';
import { Context as userInfoContext } from '../../context/userInfoContext';
import { PURGE } from '../../reducers/types';
import { updateStep } from '../../actionCreators/onboardingStepsActions';

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
    height: '50rem',
    marginTop: '2.5rem'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3.2rem'
  }
});

const Calendly = props => {
  const [disabled, setDisabled] = React.useState(true);
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
    startCalendlyListener();
  });

  const onboardingState = useSelector(state => state.onboardingStepsReducer);

  const dispatch = useDispatch();
  let history = useHistory();
  const { saveUserToken } = useContext(userInfoContext);

  const logOut = () => {
    try {
      closeSession();
    } finally {
      dispatch({ type: PURGE });
      history.push('/');
      saveUserToken({});
    }
  };

  const goToNextPage = () => {
    updateStep(dispatch, onboardingState, 'nextStep');
  };

  const handleReturn = () => {
    updateStep(dispatch, onboardingState, 'prevStep');
  };

  const getCalendlyEvent = e => {
    return e.data.event && e.data.event.indexOf('calendly') === 0
      ? e.data.event.split('.')[1]
      : '';
  };

  const startCalendlyListener = () => {
    window.addEventListener('message', function (e) {
      const event = getCalendlyEvent(e);
      if (event === 'event_scheduled') setDisabled(false);
    });
  };

  const classes = useStyles();
  return (
    <div>
      <div id="schedule_form">
        <div
          className={`${classes.root} calendly-inline-widget`}
          data-url={props.dataUrl}
        ></div>
        <div className={classes.buttonsContainer}>
          <div>
            {props.exit ? (
              <PrimaryButton
                disabled={disabled}
                className={classes.button}
                onClick={logOut}
                text="Finalizar"
              />
            ) : (
              <React.Fragment>
                <WhiteButton copy="Regresar" onClick={handleReturn} />
                <PrimaryButton
                  disabled={disabled}
                  className={classes.button}
                  onClick={goToNextPage}
                  text="Continuar"
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendly;
