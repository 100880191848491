import React from 'react';
import { AlertLink, AlertSpan, AlertButton } from '../styles';

const requestIncreaseIntercom = () => {
  window.Intercom(
    'showNewMessage',
    'Me gustaría solicitar un incremento en mi línea de crédito'
  );
};

export const TransferAmountValidation = (
  creditLine,
  avaliableBalance,
  spendingLimit,
  transferAmount,
  userRoleId
) => {
  const isBasicUser = userRoleId === 3;

  if (
    !creditLine ||
    !avaliableBalance ||
    !spendingLimit ||
    !transferAmount ||
    !userRoleId
  ) {
    return {
      isValid: false,
      errorMessage:
        'línea de crédito, crédito, límite de gasto o cantidad no se puede encontrar'
    };
  }

  if (
    typeof creditLine !== 'number' ||
    typeof avaliableBalance !== 'number' ||
    typeof spendingLimit !== 'number' ||
    typeof transferAmount !== 'number' ||
    typeof userRoleId !== 'number'
  ) {
    return {
      isValid: false,
      errorMessage: 'Tipo de datos no válido, por favor ingrese un número'
    };
  }

  if (
    transferAmount <= creditLine &&
    transferAmount > avaliableBalance &&
    transferAmount > spendingLimit
  ) {
    return {
      isValid: false,
      errorMessage: isBasicUser
        ? () => (
            <AlertSpan>
              La cuenta no tiene suficiente crédito disponible para hacer esta
              transferencia y tu límite de gasto tampoco es suficiente. Contacta
              a tu administrador para que incremente tu límite de gasto y
              realice un depósito a la cuenta para incrementar el crédito
              disponible.
            </AlertSpan>
          )
        : () => (
            <AlertSpan>
              Esta transferencia excede tu límite de gasto y tu crédito
              disponible.{' '}
              <AlertLink
                href="https://ayuda.delt.ai/es/articles/5125116-como-verifico-los-limites-de-gasto-por-usuario-y-por-tarjeta"
                target={'_blank'}
              >
                Incrementa tu límite de gasto
              </AlertLink>{' '}
              y{' '}
              <AlertLink
                href="https://ayuda.delt.ai/es/articles/5334664-como-pago-el-saldo-que-debo-de-mi-credito-delt-ai"
                target={'_blank'}
              >
                deposita a tu cuenta.
              </AlertLink>
            </AlertSpan>
          )
    };
  }

  if (
    transferAmount <= creditLine &&
    transferAmount <= spendingLimit &&
    transferAmount > avaliableBalance
  ) {
    return {
      isValid: false,
      errorMessage: isBasicUser
        ? () => (
            <AlertSpan>
              Tu cuenta no tiene suficiente crédito disponible para hacer esta
              transferencia. Realiza un depósito
            </AlertSpan>
          )
        : () => (
            <AlertSpan>
              La cuenta no tiene suficiente crédito disponible para hacer esta
              transferencia. Contacta al administrador de tu cuenta para que
              realice un depósito.
            </AlertSpan>
          )
    };
  }

  if (
    transferAmount <= creditLine &&
    transferAmount <= avaliableBalance &&
    transferAmount > spendingLimit
  ) {
    return {
      isValid: false,
      errorMessage: isBasicUser
        ? () => (
            <AlertSpan>
              Esta transferencia excede tu límite de gasto. Contacta a un
              administrador de tu cuenta para que te suba el límite.
            </AlertSpan>
          )
        : () => (
            <AlertSpan>
              Esta transferencia excede tu límite de gasto.{' '}
              <AlertLink href="https://ayuda.delt.ai/es/articles/5125116-como-verifico-los-limites-de-gasto-por-usuario-y-por-tarjeta">
                Increméntalo
              </AlertLink>
            </AlertSpan>
          )
    };
  }

  if (transferAmount > spendingLimit) {
    return {
      isValid: false,
      errorMessage: isBasicUser
        ? () => (
            <AlertSpan>
              Esta transferencia excede tu límite de gasto. Contacta a un
              administrador de tu cuenta para que te suba el límite.
            </AlertSpan>
          )
        : () => (
            <AlertSpan>
              Esta transferencia excede tu límite de gasto.{' '}
              <AlertLink href="https://ayuda.delt.ai/es/articles/5125116-como-verifico-los-limites-de-gasto-por-usuario-y-por-tarjeta">
                Increméntalo
              </AlertLink>
            </AlertSpan>
          )
    };
  }

  // --For special case users who prefinance their accounts and have more avaliable balance/credit than their credit line
  // -- Reference HEL-1479
  if (transferAmount > creditLine && transferAmount <= avaliableBalance) {
    return { isValid: true };
  }

  if (transferAmount > creditLine) {
    return {
      isValid: false,
      errorMessage: isBasicUser
        ? () => (
            <AlertSpan>
              La línea de crédito no es lo suficientemente grande para realizar
              esta transferencia. Contacta al administrador de tu cuenta para
              pedir un incremento.
            </AlertSpan>
          )
        : () => (
            <AlertSpan>
              La línea de crédito o crédito disponible no es lo suficientemente
              grande para realizar esta transferencia.{' '}
              <AlertButton
                onClick={e => {
                  e.preventDefault();
                  requestIncreaseIntercom();
                }}
              >
                Pide un incremento
              </AlertButton>
            </AlertSpan>
          )
    };
  }

  return { isValid: true };
};
