export const loginLink = '/login';
export const forgotPasswordPath = '/forgot-password';
export const restorePasswordPath = '/password-restore';
export const emailConfirmationPath = '/email-confirmation';

export const loginPaths = [
  loginLink,
  forgotPasswordPath,
  restorePasswordPath,
  emailConfirmationPath,
];
