/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Amount from '../Amount/Amount';

import { AmountContainer, Content, TextContainer } from './ProgressBar.styles';

const ProgressBarContent = ({ amount, textContent, textColor, width }) => {
  return (
    <AmountContainer color={textColor} width={width}>
      <Content>
        {amount ? (
          <Amount
            color="white"
            integer={`${amount.sign} ${amount.integer}`}
            decimals={amount.decimals}
            className="amount"
          />
        ) : null}

        {textContent ? (
          <TextContainer color={textColor}>{textContent}</TextContainer>
        ) : null}
      </Content>
    </AmountContainer>
  );
};

ProgressBarContent.propTypes = {
  amount: PropTypes.any,
  textColor: PropTypes.string,
  textContent: PropTypes.string
};

export default memo(ProgressBarContent);
