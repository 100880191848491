/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

import React, { useEffect, useState, useCallback } from 'react';

// Auxiliar libraries
import log from 'loglevel';
import PropTypes from 'prop-types';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Modal from '../../../shared/components/Modal';
import ModalAuth from '../../components/modal-auth';
import InformationModal from '../../../shared/components/ModalInformation';

// Others
import {
  selectEmail,
  isValidCardIdSelector,
  validCardIdsSelector
} from '../../redux/cards-selectors';
import { service } from '../../service';
import { machine, states, events } from './state-machine-definition';
import { cardsLink } from '../../constants';

const UnBlockCardModal = ({
  unblockCardModal,
  setUnblockCardModal,
  cardId,
  userId
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [authError, setAuthError] = useState(null);
  const [state, setState] = useState(machine.value);
  const email = useSelector(selectEmail);
  const validCardIds = useSelector(validCardIdsSelector);
  const isValidCardId = useSelector(isValidCardIdSelector(cardId));

  useEffect(() => {
    const resetModalState = () =>
      setState(machine.transition(state, events.RESET));

    return resetModalState;
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleModalClose = useCallback(() => {
    setUnblockCardModal(false);
  }, [history]);

  if (!isValidCardId) {
    log.warn(
      `Invalid card id ${cardId} passed as url parameter! Valid ones: ${validCardIds.join(
        ', '
      )}`
    );
    return <Redirect to={cardsLink} />;
  }

  const handleConfirmation = () =>
    setState(machine.transition(state, events.CONFIRM));

  const handleAuth = async ({ password }) => {
    const { isSuccess, error } = await service().unblockCard({
      email,
      password,
      cardId,
      userId
    });
    if (isSuccess) {
      await service({ dispatch }).getCards();
      handleModalClose();
    } else {
      setAuthError(error);
    }
  };

  const getModalBasedOnState = () => {
    switch (state) {
      case states.UNBLOCK_CONFIRMATION:
        return (
          <InformationModal
            title="Desbloquear tarjeta"
            description="Al desbloquear una tarjeta, se puede volver a usar."
            primaryButtonText="Confirmar desbloqueo"
            onPrimaryButtonClick={handleConfirmation}
            secondaryButtonText="Cancelar"
            onSecondaryButtonClick={handleModalClose}
          />
        );
      case states.AUTHENTICATION:
        return (
          <ModalAuth
            handleSubmit={handleAuth}
            handleModalClose={handleModalClose}
            authError={authError}
          />
        );
      default:
    }
  };

  return (
    <Modal
      open={unblockCardModal}
      onClose={handleModalClose}
      showCrossSign={false}
      disableBackDropClick={false}
      showCloseOnModal
    >
      {getModalBasedOnState()}
    </Modal>
  );
};

UnBlockCardModal.propTypes = {
  history: PropTypes.object
};

export default UnBlockCardModal;
