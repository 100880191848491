import styled, { css } from 'styled-components';
import {
  colors,
  fonts
} from '../../../../Dashboard4.0/assets/styles/styles.js';

const getColor = ({ color }) => {
  const stateColors = {
    normal: colors.carbon,
    dark: colors.detailsBlack
  };

  return stateColors[color] || stateColors['normal'];
};

export const DateContainer = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  line-height: 0.73;
  letter-spacing: 0.33px;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  text-align: center;
  color: ${getColor};
  ${fonts.quicksandBold}
`;

const sharedDateYearStyle = css`
  font-size: 1.4rem;
  line-height: 0.71;
  width: max-content;
  letter-spacing: 1px;
  color: inherit;
  margin: 0.5rem 0;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const DayContainer = styled.p`
  ${sharedDateYearStyle}
  ${fonts.quickSandSemiBold}
`;

export const YearContainer = styled.p`
  ${sharedDateYearStyle}
  ${fonts.quickSandMedium}
`;
