import {
  API_DOCS_ENABLE,
  CLEAN_STATE,
  SET_USER_ROLE,
  PURGE,
  SET_ERROR,
  SET_ALL_PERMISSIONS,
  UPDATE_ROLE_PERMISSIONS
} from './role-permissions-types';

const initialState = {
  apiDocsEnabled: false,
  userType: null,
  allPermissions: false,
  rolePermissions: [],
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case API_DOCS_ENABLE:
      return { ...state, apiDocsEnabled: action.payload };
    case SET_USER_ROLE:
      return {
        ...state,
        userType: action.payload
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case SET_ALL_PERMISSIONS:
      return {
        ...state,
        allPermissions: action.payload
      };
    case UPDATE_ROLE_PERMISSIONS:
      return {
        ...state,
        rolePermissions: action.payload
      };
    case CLEAN_STATE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
