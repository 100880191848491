import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { selectAccountInfo } from './selectors';
import { getAuthToken, parseToken } from '../../../Services/authService.js';

export const setMixPanelTrackingEvent = (eventName, props = {}) => {
  if (process.env.REACT_APP_ENV !== 'prod-dash4') return;

  // add jwt data to props
  const token_text = getAuthToken();
  const parsed_token = parseToken(token_text);
  const claims = parsed_token.user_claims;
  const default_props = {
    // user
    user_role_id: claims.user_role_id,
    user_name: claims.name,
    email: claims.email,
    // company
    company_type_id: claims.company_type_id,
    company_name: claims.company_information.payer_name,
    contract_start_date: claims.company_information.contract_start_date,
    // spend
    current_user_spend: claims.current_user_spend,
    user_spend_limit: claims.user_spend_limit,
  };

  mixpanel.track(eventName, { ...props, ...default_props });
};

export const useMixPanel = () => {
  const accountInfo = useSelector(selectAccountInfo);

  const submitEvent = (eventName, props = {}) => {
    setMixPanelTrackingEvent(eventName, { ...accountInfo, ...props });
  };

  return [submitEvent];
};
