/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

// Auxiliary libraries
import PropTypes from 'prop-types';

// Components
import EntryComponent from '../../../activity/components/EntryComponent/EntryComponent';
import EmptyStateLabel from '../EmptyStateLabel/EmptyStateLabel';

// Styled
import { Container } from './EmptyStatePreview.styles';

export const EmptyStatePreview = ({
  columns,
  EntryEmptyState,
  data,
  label,
  labelBold,
  labelSuccess
}) => {
  return (
    <Container data-test="EmptyStatePreview">
      {EntryEmptyState}

      <EmptyStateLabel
        data-test="EmptyStateLabel"
        label={label}
        labelBold={labelBold}
        labelSuccess={labelSuccess}
      />

      {data.map(el => {
        return (
          <EntryComponent
            columns={columns}
            data-test="EntryComponent"
            key={el.tracking_key}
            details={el.details}
            isRejected={false}
            firstAction={el.firstAction}
            secondAction={el.secondAction}
          />
        );
      })}
    </Container>
  );
};

EmptyStatePreview.propTypes = {
  columns: PropTypes.number,
  EntryEmptyState: PropTypes.any.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  labelBold: PropTypes.string.isRequired,
  labelSuccess: PropTypes.string.isRequired
};

export default EmptyStatePreview;
