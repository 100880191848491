import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers/rootReducer';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2 // see "Merge Process" section for details. https://github.com/rt2zz/redux-persist/tree/master/src/stateReconciler
};

const pReducer = persistReducer(persistConfig, rootReducer);
let storeEnv = createStore(pReducer);

/*
Enabling Redux-dev-tools browser extension ONLY when
1.) React is running in DEVELOPMENT mode
2.) Redux devtools extension is installed in the browser
*/
if (
  process.env.REACT_APP_PRODUCTION === 'false' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25
  });

  const enhancer = composeEnhancers();
  storeEnv = createStore(pReducer, enhancer);
}

export const store = storeEnv;
export const persistor = persistStore(store);
