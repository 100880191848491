import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

const getRemoveStyles = ({ isMouseOverDelete }) => {
  const removeStyles = `
  border: solid 1px #f58a9d;  
  background-color: #fff4f6;  
  `;

  return isMouseOverDelete ? removeStyles : '';
};

const getOverlayStyles = ({ showOverlay }) => {
  const overlayStyles = `
  border: none;  
  box-shadow: none;
  `;

  return showOverlay ? overlayStyles : '';
};

export const EntryOutline = styled.div`
  border-radius: 12px;
  background-color: white;
  overflow: hidden;

  &:hover {
    box-shadow: 0 11px 22px -8px #e5e8ed;
    border: solid 1px ${colors.primary};
    cursor: pointer;
    ${getRemoveStyles}
    ${getOverlayStyles}
  }
`;

export const BeneficiaryEntryContainer = styled.div`
  display: flex;
  padding: 2.4rem 3.2rem;
  height: 9.7rem;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  .titleRemove {
    color: ${colors.subtleShade};
  }

  .detailsRemove {
    color: ${colors.carbon2};
  }

  .removeBeneficiary {
    visibility: hidden;
    background: none;
    width: fit-content;
    height: fit-content;
    position: absolute;
    right: 1.6rem;
    top: 1.2rem;
  }

  &:hover {
    .removeBeneficiary {
      visibility: visible;
    }
  }

  .entryComponent {
    flex: 1;
  }

  .entryDetails {
    width: 100%;
  }

  .detailsComponent {
    justify-content: space-between;
    margin-top: 4px;
    img {
      display: none;
    }

    .mainText {
      flex: 1;
      align-self: flex-start;
      padding-right: 15px;
    }

    .secondaryText {
      flex: 1;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    padding: 1.2rem 1.6rem;

    .detailsComponent {
      flex-direction: column;
      align-items: baseline;

      span {
        margin-bottom: 0.4rem;
      }
    }
  }
`;

export const AddBeneficiaryEntryContainer = styled.div`
  padding: 2.4rem 3.2rem;
  min-height: 9.7rem;
  border-radius: 12px;
  background-color: ${colors.carbon4};

  &:hover {
    box-shadow: 0 11px 22px -8px #e5e8ed;
    border: solid 1px ${colors.primary};
    cursor: pointer;
    ${getRemoveStyles}
    ${getOverlayStyles}
  }
`;

export const BeneficiaryDeleteOverlay = styled(BeneficiaryEntryContainer)`
  position: absolute;
  background-color: rgba(28, 38, 51, 0.63);
  backdrop-filter: blur(14px);
  top: 0;
  left: 0;

  .buttonsContainer {
    display: flex;

    button {
      font-size: 12px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .buttonRemoveCancel {
    color: ${colors.carbon2};
    border: 1px solid ${colors.carbon2};
  }
`;

export const EntryContainer = styled.div`
  display: flex;
  align-items: center;

  .entryDetails {
    margin-left: 3.2rem;
  }

  @media (max-width: 768px) {
    .entryDetails {
      margin-left: 2.2rem;
    }
  }
`;

export const RemoveEntryDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderDropdown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const BodyDropdown = styled.div`
  margin-left: 8rem;
`;

export const OptionDropdown = styled.div`
  margin: 1rem 0 2rem;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.2px;
  cursor: pointer;
  position: relative;
  &:nth-child(2) {
    margin: 0;
  }

  span {
    display: inline-block;
    margin-left: 3rem;
  }

  svg {
    position: absolute;
    left: -4px;
  }
`;
