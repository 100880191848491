import React, { memo, useState } from 'react';

import {
  Arrow,
  Container,
  CustomIcon,
  EntryBalanceContainer,
  LinkContainer,
  SectionContainer
} from './CommissionsAndFees.styles';

import EntryBalance from '../EntryBalance/EntryBalance';
import Item from './Item';
import Link from '../../../shared/components/Link';
import LineComplements from '../EntryBalance/LineComplements';

const CommissionsAndFees = ({ companySummary, className }) => {
  const [showDetails, setShowDetails] = useState(false);

  const unavailableMessage = 'No disponible';

  const {
    commission,
    interests_and_commissions,
    ordinary_interest,
    ordinary_interest_rate,
    tax_rate,
    vat
  } = companySummary;

  const handleClick = () => {
    setShowDetails(!showDetails);
  };
  return (
    <>
      <EntryBalanceContainer className={className} onClick={handleClick}>
        <EntryBalance
          concept="Intereses y Comisiones"
          amount={`${interests_and_commissions || unavailableMessage}`}
          balanceDate={<CustomIcon icon="next" showDetails={showDetails} />}
        />
      </EntryBalanceContainer>
      <SectionContainer isVisible={showDetails}>
        <div>
          <LineComplements type="linear" />
          <LineComplements type="linear" />
          <LineComplements type="linear" />
          <LineComplements type="linear" />
          <LineComplements type="linear" />
        </div>
        <Container>
          <Item
            concept="Intereses ordinarios"
            interestRate={`Tasa aplicable ${
              ordinary_interest_rate || unavailableMessage
            }%`}
            amount={ordinary_interest || unavailableMessage}
          />
          <Item
            concept="Comisiones"
            interestRate="-"
            amount={commission || unavailableMessage}
          />
          <Item
            concept="Impuestos"
            interestRate={`${tax_rate || unavailableMessage}%`}
            amount={vat || unavailableMessage}
          />
          <LinkContainer>
            <Link to={process.env.REACT_APP_COMMISSIONS_AND_FEES_URL}>
              Sobre Intereses y Comisiones <Arrow icon="arrow" />
            </Link>
          </LinkContainer>
        </Container>
      </SectionContainer>
    </>
  );
};

export default memo(CommissionsAndFees);
