/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import Amount from '../../../../shared/components/Amount/Amount';
import { HeaderContainer, Label } from './styles';

const Header = ({ type, amount, text }) => {
  return (
    <HeaderContainer className="header" type={type}>
      <>
        <Amount
          integer={`${amount.sign} ${amount.integer}`}
          decimals={amount.decimals}
          className="amount"
        />
        <Label>{text}</Label>
      </>
    </HeaderContainer>
  );
};

Header.propTypes = {
  amount: PropTypes.object.isRequired,
  string: PropTypes.string,
  type: PropTypes.string
};

export default Header;
