/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { StatusLabelContainer } from './styles';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';

const StatusLabel = ({ status, tooltip, color }) => {
  return (
    <StatusLabelContainer color={color}>
      {status} {tooltip ? <Tooltip text={tooltip} className="tooltip" /> : null}
    </StatusLabelContainer>
  );
};

StatusLabel.propTypes = {
  color: PropTypes.string,
  status: PropTypes.string,
  tooltip: PropTypes.string
};

export default StatusLabel;
