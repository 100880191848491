export const TITLE = 'Facturas';
export const EXPENSE = 'EXPENSE';
export const INCOME = 'INCOME';

export const STATUS = {
  VIGENTE: 'VIGENTE',
  CANCELADO: 'CANCELADO',
  POR_PAGAR: 'POR PAGAR ',
  POR_COBRAR: 'POR COBRAR',
  pending: 'pending',
  success: 'success',
  cancelled: 'cancelled'
};
