import React from 'react';
import PropTypes from 'prop-types';

import OnboardingLayout from '../../components/OnboardingLayout/OnboardingLayout';

const EmptyState = ({ onPrimaryButtonClick, otherProps }) => {
  return (
    <OnboardingLayout
      title="¡Algo pasó!"
      description={
        <>
          Tuvimos un problema de nuestro lado. <br></br>
          Por favor dale click a Reintentar.
        </>
      }
      primaryButtonText="Reintentar"
      isModalVisible={true}
      onPrimaryButtonClick={onPrimaryButtonClick}
      {...otherProps}
    />
  );
};

EmptyState.propTypes = {
  onPrimaryButtonClick: PropTypes.func.isRequired,
  otherProps: PropTypes.object
};

export default EmptyState;
