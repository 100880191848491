import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Context as userInfoContext } from '../../context/userInfoContext';
import CompanySignUpForm from './CompanySignUpForm';
import { MainTitle } from '../Shared';
import { PrimaryButton } from '../Shared';
import PersonSignUpForm from './PersonSignUpForm';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { validationSchema } from './validationSchema';
import withWidth from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({
  formButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2.5rem'
  },
  form: {
    width: '100%'
  },
  checkbox: {
    marginTop: '1.5rem',
    fontSize: '1.6rem'
  },
  centerContainer: {
    width: 'fit-content',
    margin: '0 auto'
  },
  redText: {
    color: 'red',
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  instructions: {
    fontSize: '1.8rem',
    color: '#989898'
  }
}));

const Signup = props => {
  const {
    state: { errorMessage },
    SignUp,
    clearErrorMessage
  } = useContext(userInfoContext);
  let history = useHistory();
  let initialState = {};

  const { width } = props;

  if (props.type === 'pyme') {
    initialState = {
      name: '',
      surname_1: '',
      surname_2: '',
      email: '',
      password: '',
      company_legal_name: '',
      company_type_id: 1,
      company_commercial_name: ''
    };
  }

  if (props.type === 'freelancer') {
    initialState = {
      name: '',
      surname_1: '',
      surname_2: '',
      email: '',
      password: '',
      company_type_id: 2
    };
  }

  const formik = useFormik({
    initialValues: {
      initialState
    },
    validationSchema,
    validateOnMount: true
  });

  const classes = useStyles();
  const [userInfo, setUserInfo] = useState(initialState);
  const [disabled, setDisabled] = useState(true);
  const [AcceptTerms, setAcceptTerms] = useState(false);
  const [AcceptUseMyVoiceAndImage, setUseMyVoiceAndImage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const setData = e => {
    formik.handleChange(e);
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };

  const changeTermState = () => {
    setAcceptTerms(!AcceptTerms);
  };

  const changeVoiceAndImgState = () => {
    setUseMyVoiceAndImage(!AcceptUseMyVoiceAndImage);
  };

  const submitForm = e => {
    e.preventDefault();
    SignUp(userInfo);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formProps = {
    classes,
    formik,
    submitForm,
    setData,
    showPassword,
    width,
    changeTermState,
    handleClickShowPassword,
    changeVoiceAndImgState,
    AcceptUseMyVoiceAndImage,
    AcceptTerms
  };

  useEffect(() => {
    formik.isValid && AcceptTerms && AcceptUseMyVoiceAndImage
      ? setDisabled(false)
      : setDisabled(true);
    // eslint-disable-next-line
  }, [AcceptTerms, AcceptUseMyVoiceAndImage, formik, props.history]);

  const injectPixel = () => {
    const body = document.querySelector('body');
    const pixel = document.createElement('IMG');
    pixel.setAttribute('src', process.env.REACT_APP_OJO7_PIXEL);
    body.appendChild(pixel);
  };

  useEffect(() => {
    if (errorMessage === false) {
      injectPixel();
      clearErrorMessage();
      history.push({
        pathname: '/confirm-account',
        state: userInfo
      });
    }
    // eslint-disable-next-line
  }, [errorMessage, props.history]);

  return (
    <div className={classes.centerContainer}>
      <div>
        <MainTitle text="Información Básica" />

        <form onSubmit={submitForm} className={classes.form}>
          {props.type === 'pyme' ? (
            <CompanySignUpForm
              formik={formik}
              classes={classes}
              submitForm={submitForm}
              setData={setData}
            />
          ) : (
            <p className={classes.instructions}>
              Completa tu información para continuar.
            </p>
          )}
          <PersonSignUpForm props={formProps} />
          <div className={classes.formButton}>
            <PrimaryButton text="Continuar" type="submit" disabled={disabled} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default withWidth()(Signup);
