import styled from 'styled-components';

export const ToggleButton = styled.button`
  background-color: ${({ checked }) => (checked ? '#45BD62' : '#ED2246')};
  position: relative;
  display: inline-flex;
  height: 2rem;
  width: 4rem;
  border-radius: 9999px;
  cursor: pointer;
  border-style: none;
  padding: 1.5px 2.5px;

  &:focus {
    outline: 0;
  }
`;

export const Dot = styled.span`
  transform: translate(
    ${({ checked }) => (checked ? '1.79rem' : '-0.1rem')},
    -0.05rem
  );
  display: inline-block;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 9999px;
  background-color: #fff;
`;
