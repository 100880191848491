/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

import React, { useEffect, useState, useCallback } from 'react';

// Auxiliar libraries
import log from 'loglevel';
import PropTypes from 'prop-types';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Modal from '../../../shared/components/Modal';
import InformationModal from '../../../shared/components/ModalInformation';

// Others
import { machine, states, events } from './state-machine-definition';
import { service } from '../../service';
import {
  isValidCardIdSelector,
  validCardIdsSelector
} from '../../redux/cards-selectors';
import { cardsLink } from '../../constants';

const ReportCardLostModal = ({
  userId,
  cardId,
  reportCardLostModal,
  setReportCardLostModal,
  toggleNewCardModal
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState(machine.value);
  const validCardIds = useSelector(validCardIdsSelector);
  const isValidCardId = useSelector(isValidCardIdSelector(cardId));

  useEffect(() => {
    const resetModalState = () =>
      setState(machine.transition(state, events.RESET));

    return resetModalState;
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleModalClose = useCallback(() => {
    setReportCardLostModal(false);
  }, [history]);

  const handleNewCard = () => {
    handleModalClose();
    toggleNewCardModal();
  };

  if (!isValidCardId) {
    log.warn(
      `Invalid card id ${cardId} passed as url parameter! Valid ones: ${validCardIds.join(
        ', '
      )}`
    );
    return <Redirect to={cardsLink} />;
  }

  const handleConfirmation = async () => {
    const { isSuccess } = await service().reportLostCard({ cardId, userId });

    if (isSuccess) {
      setState(machine.transition(state, events.CONFIRM));
      await service({ dispatch }).getCards();
    }
  };

  const getModalBasedOnState = () => {
    switch (state) {
      case states.LOST_CONFIRMATION:
        return (
          <InformationModal
            title="Tarjeta robada o extraviada"
            description="Al reportar una tarjeta como robada o extraviada, ésta se desactivará."
            primaryButtonText="Confirmar robo o extravío"
            onPrimaryButtonClick={handleConfirmation}
            secondaryButtonText="Cancelar"
            onSecondaryButtonClick={handleModalClose}
          />
        );
      case states.CHOOSE_NEXT_ACTION:
        return (
          <InformationModal
            title="Tarjeta robada o extraviada"
            description={
              <>
                <p>Hemos desactivado tu tarjeta por seguridad.</p>
                <b>¿Deseas crear una nueva?</b>
              </>
            }
            primaryButtonText="Sí, lo necesito"
            onPrimaryButtonClick={handleNewCard}
            secondaryButtonText="No, gracias"
            onSecondaryButtonClick={handleModalClose}
          />
        );
      default:
    }
  };

  return (
    <Modal
      open={reportCardLostModal}
      onClose={handleModalClose}
      showCrossSign={false}
      disableBackDropClick={false}
      showCloseOnModal
    >
      {getModalBasedOnState()}
    </Modal>
  );
};

ReportCardLostModal.propTypes = {
  history: PropTypes.object
};

export default ReportCardLostModal;
