import { getCustomerData } from './infrastructure/getCustomerData';
import { getSignUp } from './infrastructure/getSignUp';

import { getCustomerDataHandler } from './eventHandlers/getCustomerData';
import { getSignUpHandler } from './eventHandlers/getSignUp';

export const service = () => ({
  getCustomerData: getCustomerDataHandler({
    getCustomerData
  }),
  getSignUp:  getSignUpHandler({
    getSignUp
  })
});
