import {
  GET_INVOICES,
  SET_INVOICES_ERRORS,
  PURGE,
  SET_INVOICES_TYPE_FILTER
} from './types';

const INITIAL_STATE = {
  invoices: {
    id: null,
    res: {
      invoices: [],
      current_page: 1,
      total_pages: 1
    },
    success: false,
    error: false,
    activated: false,
    filterByType: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INVOICES_TYPE_FILTER:
      return { ...state, filterByType: action.payload };
    case GET_INVOICES:
      return { ...state, invoices: action.payload };
    case SET_INVOICES_ERRORS:
      return {
        ...state,
        invoices: { ...state.invoices, error: action.payload }
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
