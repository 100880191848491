/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Entry from './Entry';
import InputButton from '../../../shared/components/InputButton';
import RemoveEntry from './RemoveEntry';

import {
  EntryOutline,
  BeneficiaryEntryContainer
} from './BeneficiaryEntry.styles';

const BeneficiaryEntry = ({
  colors,
  alias,
  fullName,
  initials,
  bankingDetails,
  accountCode,
  showOverlay,
  isMouseOver,
  handleMouseOver,
  showDeleteOverlay,
  handleDelete,
  onClick
}) => {
  const handleShowDeleteOverlay = e => {
    e.stopPropagation();
    showDeleteOverlay();
  };

  const handleDeleteFc = e => {
    e.stopPropagation();
    handleDelete();
  };

  const handleCancel = e => {
    e.stopPropagation();
    showDeleteOverlay();
  };

  return (
    <EntryOutline
      style={{ height: '9.7rem' }}
      isMouseOverDelete={isMouseOver}
      showOverlay={showOverlay}
      data-test="outlineComponent"
    >
      <BeneficiaryEntryContainer onClick={onClick} data-test="beneficiaryEntry">
        <Entry
          initials={initials}
          colors={colors}
          alias={alias}
          fullName={fullName}
          bankingDetails={bankingDetails}
          accountCode={accountCode}
          data-test="entryComponent"
        />

        <InputButton
          type="remove"
          className="removeBeneficiary"
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOver}
          onClick={handleShowDeleteOverlay}
          data-test="removeItemButton"
        />
        {showOverlay ? (
          <RemoveEntry
            alias={alias}
            handleCancel={handleCancel}
            handleDelete={handleDeleteFc}
            data-test="removeEntryOverlay"
          />
        ) : null}
      </BeneficiaryEntryContainer>
    </EntryOutline>
  );
};

BeneficiaryEntry.propTypes = {
  alias: PropTypes.string,
  colors: PropTypes.object,
  fullName: PropTypes.string,
  initials: PropTypes.string,
  handleDelete: PropTypes.func,
  showDeleteOverlay: PropTypes.func,
  handleMouseOver: PropTypes.func,
  isMouseOver: PropTypes.bool,
  showOverlay: PropTypes.bool,
  bankingDetails: PropTypes.string,
  onClick: PropTypes.func
};

export default memo(BeneficiaryEntry);
