import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { resetPasswordPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const sendResetPasswordEmailService = async ({ email }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      resetPasswordPath,
      {
        email,
      },
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'reset-password-email-service',
    });
  } catch (error) {
    log.error('reset-password-email-service', error);

    return { error: { message: 'Unable to send request!' } };
  }
};
