import styled from 'styled-components';

import { AvatarContainer } from '../../../shared/components/Avatar/Avatar.styles';
import Button from '../../../shared/components/Button';
import Title from '../../../shared/components/Title';

import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const CustomTitle = styled(Title)`
  color: ${colors.carbon};
`;

export const EntryWrapper = styled.div`
  width: 100%;
  padding: 4.2rem 7.2rem 2.43rem 7.2rem;

  @media (max-width: 768px) {
    padding: 4rem 2.4rem 2.4rem;
  }
`;

export const SavedBeneficiaryContainer = styled.div`
  width: 100%;
  padding: 4rem 7.2rem 5.7rem 7.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CustomAvatar = styled(AvatarContainer)`
  height: 12rem;
  width: 12rem;
  font-size: 3.75rem;
`;

export const Alias = styled(Title)`
  color: ${colors.body};
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.4rem 0 4rem 0;
`;

export const Email = styled(Button)`
  &:hover {
    border: solid 1px ${colors.carbon1};
    color: ${colors.carbon1};
    cursor: default;
    box-shadow: none;
  }
`;
