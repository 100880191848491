import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosAuxConfig';
import { agreementList } from '../utils/constants';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const agreementListService = async ({ uuid, type }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
        agreementList,
      {
        data:{
          type_agreement: type,
          customer: {
            uuid
          }
        }
      },
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'kyc-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('kyc-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
