import styled from 'styled-components';

import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const CodeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  div {
    padding: 0 0.9rem;
  }
  input {
    text-align: center;
    padding-top: 0.9rem;
  }

  @media (max-width: 600px) {
    div {
      padding: 0 0.5rem;
    }
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 3.2rem;
  color: ${colors.deltai};
  font-size: 2.4rem;
`;
