import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Pages
import FreelancerForm from './pages/freelancerForm/FreelancerForm';
import RegisterPage from './pages/registerPage/Register';
import RegistrationSuccess from './pages/registrationSuccess';
import PymeForm from './pages/pymeForm/PymeForm';
import NotFoundPage from '../../routing/pages/notFoundPage';

// Others
import {
  registerFreelancerLink,
  registerPymeLink,
  registerUserTypeLink,
  confirmAccountPath
} from './constants';

const RegisterModule = () => (
  <Switch>
    <Route exact path={confirmAccountPath} component={RegistrationSuccess} />
    <Route exact path={registerPymeLink} component={PymeForm} />
    {process.env.REACT_APP_ACTIVATE_FREELANCER_ONBOARDING === 'true' && (
      <>
        <Route exact path={registerUserTypeLink} component={RegisterPage} />
        <Route exact path={registerFreelancerLink} component={FreelancerForm} />
      </>
    )}
    <Route component={NotFoundPage} />
  </Switch>
);

export default RegisterModule;
