'use client';
import Header from '../components/header';
import Panel from '../components/panel';
import HSpacing from '../components/spacing/horizontal-spacing';
import Heading from '../components/heading';
import Center from '../components/center';
import VSpacing from '../components/spacing/vertical-spacing';
import InputText from '../components/input-text';
import Button from '../components/button';
import Digits from '../components/digits';
import Modal from '../components/modal';
import PhoneNumber from '../components/phone-number';
import Footer from '../components/footer';

import EditBold from '../assets/edit-bold.svg';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CUSTOMER_INFO } from '../../../reducers/types';

import { service } from '../service';

import { alert } from '../../../helpers/alertsManager';

import { buroResendNipMessage, tpaCreditHistoryPath } from '../utils/constants';

const fontTextStyle = {
  fontWeight: 400,
  fontSize: '16px',
  letterSpacing: '0.22px',
  fontFamily: 'barlow'
};

const options = [
  { value: '457', label: '(+52) México', lada: `+52` },
  { value: '522', label: '(+1) USA', lada: `+1` },
  { value: '577', label: '(+1) Canada', lada: `+1` }
];

const TTW = 60 * 3;

function View({ history }) {
  const [nip, setNip] = useState('');
  const [timeCounter, setTimeCounter] = useState(TTW); // three times 60 seconds = 3 minutes
  const [openModal, setOpenModal] = useState(false);

  const customerData = useSelector(state => state.userInfoReducer.customer);
  const { customer, account } = customerData;
  const { gname, lname, slname, email, phone } = account.general;

  const dispatch = useDispatch();

  function formatTimeCounter(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? '0' : ''
    }${remainingSeconds} min`;
  }

  const setIntervalFunction = () => {
    let interval;
    const countDownUntilZero = () => {
      setTimeCounter(prevTime => {
        if (prevTime === 0) {
          clearInterval(interval);
          return 0;
        } else return prevTime - 1;
      });
    };
    interval = setInterval(countDownUntilZero, 1000);
    return interval;
  };

  useEffect(() => {
    let interval = setIntervalFunction();

    return () => clearInterval(interval);
  }, []);

  const handleChangePhone = async phone => {
    const { uuid } = customer;
    await service().customerUpdatePhone({ uuid, phone });
    dispatch({
      type: CUSTOMER_INFO,
      payload: {
        ...customerData,
        account: { ...account, general: { ...account.general, phone } }
      }
    });
    setOpenModal(false);
  };

  const handleResendNip = async () => {
    const { uuid } = customer;
    const response = await service().buroResendNip({ uuid });
    setTimeCounter(TTW);
    if (response.isSuccess) {
      alert('success', buroResendNipMessage);
    }
    setIntervalFunction();
  };

  const handleSendNip = async () => {
    const { uuid } = customer;
    const response = await service().buroNip({ uuid, nip });
    if (response.isSuccess) {
      history.replace(tpaCreditHistoryPath);
    }
  };

  const phoneWOLada = phone.replace('+52', '').replace('+1', '');

  return (
    <div>
      <Header />
      <HSpacing times={3} />
      <Panel>
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <PhoneNumber
            lada={options[0]}
            phone={phoneWOLada}
            onChange={phone => handleChangePhone(phone)}
          />
        </Modal>
        <Center>
          <Heading>Datos del Representante Legal</Heading>
        </Center>
        <HSpacing times={3} />
        <InputText
          style={{ color: '#6B727A' }}
          hintText="Nombre(s)"
          value={gname}
          onChange={() => {}}
          disabled={true}
        />
        <HSpacing times={2} />
        <InputText
          style={{ color: '#6B727A' }}
          hintText="Apellido(s)"
          value={`${lname} ${slname}`.trim()}
          onChange={() => {}}
          disabled={true}
        />
        <HSpacing times={2} />
        <InputText
          style={{ color: '#6B727A' }}
          hintText="Correo electronico corporativo"
          value={email}
          onChange={() => {}}
          disabled={true}
        />
        <HSpacing times={3} />
        <div
          style={{
            display: 'flex'
          }}
        >
          <InputText
            style={{
              width: '180px',
              color: '#6B727A'
            }}
            hintText="Lada"
            value={`${options[0].lada} México`}
            onChange={() => {}}
            disabled={true}
          />
          <VSpacing times={2} />
          <InputText
            style={{ color: '#6B727A' }}
            hintText="Número celular (whatsapp)"
            value={phoneWOLada}
            onChange={() => {}}
            disabled={true}
          />
        </div>

        <HSpacing times={5} />
        <Center>
          <Digits
            header={
              <Center>
                <div
                  style={{
                    width: '399px',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <div style={fontTextStyle}>
                    Escribe el NIP de 4 dígitos que te enviamos a
                  </div>
                  <Center>
                    <div
                      style={{
                        ...fontTextStyle,
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 600
                      }}
                    >
                      {`${phone}`}
                      <VSpacing times={1} />
                      <img
                        src={EditBold}
                        alt=""
                        onClick={() => setOpenModal(true)}
                        style={{
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </Center>
                </div>
                <HSpacing times={2} />
              </Center>
            }
            numberOfDigits={4}
            onFullfilled={setNip}
          />
        </Center>
        <Center>
          <div
            style={{
              width: '416.06px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <div style={fontTextStyle}>
              {timeCounter ? (
                <span>Si aún no lo recibes, puedes reenvíar el NIP en</span>
              ) : (
                <span>
                  Si aún no lo recibes, puedes{' '}
                  <span
                    style={{
                      cursor: 'pointer',
                      color: '#1790CF',
                      textDecoration: 'underline'
                    }}
                    onClick={handleResendNip}
                  >
                    reenvíar el NIP
                  </span>{' '}
                  en
                </span>
              )}
              <strong> {formatTimeCounter(timeCounter)}</strong>
            </div>
          </div>
        </Center>
        <HSpacing times={5} />
        <Center>
          <Button
            style={{ fontWeight: 600 }}
            type="primary"
            width={432}
            label="Continuar"
            onClick={handleSendNip}
            disabled={nip.length !== 4}
          />
        </Center>
      </Panel>
      <Footer/>
    </div>
  );
}

export default function Page({ history }) {
  return (
    <>
      <View history={history} />
    </>
  );
}
