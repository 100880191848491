// React imports
import React, { useEffect } from 'react';

// Auxiliary librarys imports
import Calendly from '../../Calendly/Calendly';
import Grid from '@material-ui/core/Grid';
import { LinearProgress } from '@material-ui/core/';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Own Components
import BankingInfoForm from '../../FormsPyme/CompanyBankingInfo/BankingInfoForm';
import FormStep1 from '../../FormsPyme/FormStep1/FormContainer';
import FormStep2 from '../../FormsPyme/FormStep2/FormContainer';
import FormStep3 from '../../FormsPyme/FormStep3/FormContainer';
import FormStep4 from '../../FormsPyme/FormStep4/FormContainer';
import FreelancerStep1 from '../../FormsFreelancer/FreelancerStep1/FreelancerStep1';
import FreelancerStep2 from '../../FormsFreelancer/FreelancerStep2/FreelancerStep2';
import { getProgress } from '../../../helpers';
import IdVerification from '../../IdVerification/IdVerification';
import { OnBoardingNavbar } from '../';
import SoftWall from '../../SoftWall';
import StepperComponent from '../StepperComponent/StepperComponent';
import {
  validateUserInfo,
  validateUserStatus
} from '../../../Services/authService';
import {
  setCurrentStep,
  setUserType,
  setLastStep
} from '../../../actionCreators/onboardingStepsActions';

// helpers
import { calendlyURL as CALENDLY_URL } from '../../../micro-frontends/prequalification/constants';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'light-gray'
  },
  barColorPrimary: {
    backgroundColor: '#22c70c'
  }
})(LinearProgress);

const useStyles = makeStyles({
  progressBar: {
    marginBottom: '3rem'
  },
  formsMargin: {
    marginBottom: '3rem'
  },
  root: {
    paddingBottom: '3rem'
  }
});

const FormTemplate = () => {
  // React
  let history = useHistory();

  // Redux
  const company_type_id = useSelector(
    state => state.userInfoReducer.user.company_type_id
  );
  const user = useSelector(state => state.userInfoReducer.user);
  const currentStep = useSelector(
    state => state.onboardingStepsReducer.currentStep
  );
  const stepsDescriptionFreelancer = useSelector(
    state => state.onboardingStepsReducer.stepsDescriptionFreelancer
  );
  const stepsDescriptionPyme = useSelector(
    state => state.onboardingStepsReducer.stepsDescriptionPyme
  );

  const dispatch = useDispatch();

  // Hooks
  const [freelancer] = React.useState(company_type_id === 2 ? true : false);
  const [progress, setProgress] = React.useState(getProgress(0, currentStep));

  // Styles
  const classes = useStyles();

  const updateOnboardingProgress = (status, nextStep, progress) => {
    const step = nextStep >= 0 ? nextStep : getStep(status);
    setCurrentStep(dispatch, step);
    setLastStep(dispatch, step);
    setProgress(progress || 100);
    return;
  };

  const redirect = (status, state) => {
    history.push({
      pathname: status,
      state: state
    });
  };

  const userStatus = validateUserInfo(user.status);

  //To redirect to corresponding view
  const validateUserState = status => {
    switch (status) {
      case 'uploadedDocs':
        updateOnboardingProgress('uploadedDocs');
        break;
      case 'softwall':
        updateOnboardingProgress(status);
        break;
      case '/confirm-account':
        redirect(status, {
          email: user.email
        });
        break;
      case 'success':
        validateUserStatus('User validated', history);
        break;
      case 'signature':
        updateOnboardingProgress(status, 9);
        break;
      case 'calendly':
        updateOnboardingProgress(status, 0);
        break;
      default:
        redirect(status);
        return;
    }
  };

  const getStep = key => {
    let step = null;

    if (freelancer) {
      step = stepsDescriptionFreelancer[key];
    } else {
      step = stepsDescriptionPyme[key];
    }

    return step;
  };

  // To get total steps before hooks declaration
  const getTotalSteps = () => {
    let totalSteps;

    if (freelancer) {
      totalSteps = Object.keys(stepsDescriptionFreelancer).length - 1;
    } else {
      totalSteps = Object.keys(stepsDescriptionPyme).length - 1;
    }

    return totalSteps;
  };

  useEffect(() => {
    if (company_type_id === 2) {
      setUserType(dispatch, 'freelancer');
    } else {
      setUserType(dispatch, 'PyME');
    }
    return () => {};
    // eslint-disable-next-line
  }, [currentStep, freelancer]);

  // Update bar progress
  useEffect(() => {
    setProgress(getProgress(getTotalSteps(), currentStep));

    // eslint-disable-next-line
  }, [currentStep, progress, freelancer]);

  useEffect(() => {
    if (currentStep === 9) {
      return history.push('/sign-contract');
    }
  }, [currentStep, history]);

  useEffect(() => {
    // To validate if the user's current status in the onboarding
    validateUserState(userStatus);
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <OnBoardingNavbar />
      <Grid container direction="row" justify="center">
        <Grid classes={classes.formsMargin} item xs={11} sm={7} md={7}>
          <div>
            <StepperComponent totalSteps={getTotalSteps()} step={currentStep} />
            <ColorLinearProgress
              className={classes.progressBar}
              variant="determinate"
              value={progress}
            />
          </div>

          {freelancer === false ? (
            <React.Fragment>
              {currentStep === 0 && <FormStep1 />}
              {currentStep === 1 && <FormStep2 />}
              {currentStep === 2 && <BankingInfoForm />}
              {currentStep === 3 && <FormStep3 />}
              {currentStep === 4 && <FormStep4 />}
              {currentStep === 5 && <IdVerification />}
              {currentStep === 6 && <Calendly dataUrl={CALENDLY_URL} />}
              {(currentStep === 7 || currentStep === 8) && (
                <SoftWall isFreelancer={freelancer} />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {currentStep === 0 && <FreelancerStep1 />}
              {currentStep === 1 && <FreelancerStep2 />}
              {currentStep === 2 && <BankingInfoForm isFreelancer={true} />}
              {currentStep === 3 && <FormStep3 isFreelancer={freelancer} />}
              {currentStep === 4 && <IdVerification />}
              {currentStep === 5 && <Calendly dataUrl={CALENDLY_URL} />}
              {(currentStep === 6 ||
                currentStep === 7 ||
                currentStep === 8) && <SoftWall isFreelancer={freelancer} />}
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default FormTemplate;
