import React from 'react';

import PropTypes from 'prop-types';

import Row from './styles';

const FormRow = ({ children }) => <Row data-test="form-row">{children}</Row>;

FormRow.propTypes = {
  children: PropTypes.string.isRequired
};

export default FormRow;
