import "./index.scss";

import React from "react";

const Modal = ({ isOpen, onClose, children,style = {},overlayStyle = {}}) => {
  
  const defaultStyles = {
    ...style
  }
  const overlayDefaultStyle = {
    ...overlayStyle
  }
  const handleCloseModal = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={handleCloseModal} style={overlayDefaultStyle}>
          <div className="modal" style={defaultStyles}>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
