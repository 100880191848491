import React, { useEffect } from 'react';

// Auxiliary libraries
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Components
import RouteValidations from './RouteValidations';

// Reducers and services
import { getUserPermissions } from '../services/permissionsService';
import {
  setAllPermissionsAction,
  setApiDocsEnableAction,
  setErrorAction,
  setRulesAction,
  setUserTypeAction
} from '../redux/role-permissions-actions';

const AuthPermissions = ({
  component: Component,
  path,
  redirect,
  validate,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const error = useSelector(state => state.rolePermissionsReducer.error);
  const userType = useSelector(state => state.rolePermissionsReducer.userType);
  const tokenDetails = useSelector(state => state.userInfoReducer.tokenDetails);

  const getRules = rules => {
    return rules.map(el => {
      return el.action_name;
    });
  };

  const getPermissions = async () => {
    const { userPermissions, error } = await getUserPermissions();

    if (error || !userPermissions) {
      dispatch(setErrorAction(error));
      return;
    }

    const {
      user_type,
      all_permissions,
      rules,
      api_docs_enabled
    } = userPermissions;

    dispatch(setUserTypeAction(user_type));
    dispatch(setAllPermissionsAction(all_permissions));
    dispatch(setRulesAction(getRules(rules)));
    dispatch(setApiDocsEnableAction(api_docs_enabled));
  };

  useEffect(() => {
    if (!userType && tokenDetails) {
      //If no tokenDetails exists, it means user has been logged out
      getPermissions();
    }
    // eslint-disable-next-line
  }, [userType, tokenDetails]);

  return (
    <RouteValidations
      {...otherProps}
      userType={userType}
      component={Component}
      error={error}
      validate={validate}
      redirect={redirect}
    />
  );
};

AuthPermissions.propTypes = {
  component: PropTypes.any,
  path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  redirect: PropTypes.string,
  validate: PropTypes.bool
};

export default AuthPermissions;
