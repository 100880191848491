import "./index.scss";

import React from "react";

export default function Index(options) {
    return (
        <div className="panel">
            <div className="content" style={options.style}>
                {options.children}
            </div>
        </div>
    )
}
