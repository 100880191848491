import React from 'react';
import PropTypes from 'prop-types';

import Label from '../../../shared/components/Label';
import { Container } from './styles';

import CardSvg from './card.svg';

const RemainingCards = ({ virtualCards, physicalCards, requestCards }) => {
  return (
    <Container>
      <img alt="card" src={CardSvg} />
      <div>
      </div>
    </Container>
  );
};

RemainingCards.propTypes = {
  virtualCards: PropTypes.number.isRequired,
  physicalCards: PropTypes.number.isRequired,
  requestCards: PropTypes.func.isRequired
};

export default RemainingCards;
