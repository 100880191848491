import { setTagsAction } from '../redux/tag-action';
import { TagsCreator } from '../domain/tagData';

const getCardTagsHandler = ({ dispatch, getCardTags }) => async ({
  cardId
}) => {
  const { error, data } = await getCardTags({ cardId });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  const tags = TagsCreator({ data });

  dispatch(setTagsAction({ tags }));

  return { isSuccess: true, data };
};

export { getCardTagsHandler };
