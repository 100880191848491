import "./index.scss";

import React from "react";

const CustomCheckbox = ({ onChange, value, text, name, style = {} }) => {
    return (
        <div>
            <label className="custom-checkbox-label">
                <input
                    type="checkbox"
                    checked={value}
                    onChange={() =>
                        name
                            ? onChange([{ name, value: !value }])
                            : onChange(!value)
                    }
                />
                <span className="custom-checkbox-checkmark"></span>
            </label>
            {text}
        </div>
    );
};

export default CustomCheckbox;
