/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import EntryLabel from '../EntryLabel/EntryLabel';
import { Container, Title } from './style';

const EntryDetails = ({
  completeRow,
  hasSeparator,
  hasReversals,
  details,
  title
}) => {
  return details && details[0]?.value ? (
    <>
      <Title>{title}</Title>
      <Container
        hasSeparator={hasSeparator}
        hasReversals={hasReversals}
        completeRow={completeRow}
        className="entryDetails"
        data-testid="EntryDetailsContainer"
      >
        {details?.map((el, ix) => {
          return el.value ? (
            <EntryLabel
              name={el.name}
              value={el.value}
              key={`${el.name} - ${ix}`}
            />
          ) : null;
        })}
      </Container>
    </>
  ) : null;
};

EntryDetails.propTypes = {
  completeRow: PropTypes.bool,
  hasSeparator: PropTypes.bool,
  details: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  title: PropTypes.string
};

export default EntryDetails;
