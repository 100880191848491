import React, { useState, useEffect, memo } from 'react';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { AddBeneficiaryWrapper } from './AddBeneficiary.styles';
import AddBeneficiaryForm from '../AddBeneficiaryForm/AddBeneficiaryForm';
import AddBeneficiaryHeader from '../AddBeneficiaryHeader';

import { getInitials, checkEmptySpaces } from '../../../../helpers';
import { removeBlankSpaces } from '../../../../helpers/clabeValidator';
import { validationSchema } from './validationSchema';

import {
  createBeneficiary,
  getBankings,
  setBeneficiary,
  updateBeneficiary
} from '../../../../actionCreators/speiAction';

import { setPage } from '../../../../actionCreators/changeViewAction';

const AddBeneficiary = ({ isInEditMode }) => {
  const dispatch = useDispatch();
  const bankingInstitutions = useSelector(
    state => state.speiReducer.bankings.res
  );

  const beneficiaryData = useSelector(state => state.speiReducer.beneficiary);
  const { success, data, res } = beneficiaryData;

  const [form, setForm] = useState(
    isInEditMode
      ? {
          id: beneficiaryData.beneficiary_id,
          alias: beneficiaryData.beneficiary_alias,
          account_code: beneficiaryData.beneficiary_account_code,
          rfc: beneficiaryData.beneficiary_rfc,
          email: beneficiaryData.beneficiary_email,
          name: beneficiaryData.beneficiary_full_name,
          type: beneficiaryData.beneficiary_account_type,
          initials: getInitials(beneficiaryData.beneficiary_full_name),
          bank: {
            id: beneficiaryData.banking_institution_id,
            name: beneficiaryData.beneficiary_banking_institution_name
          }
        }
      : {
          alias: '',
          account_code: '',
          rfc: '',
          email: '',
          name: '',
          type: 'debit_card_number',
          initials: 'NB',
          bank: ''
        }
  );

  const [initialState, setInitialState] = useState(true);

  const formik = useFormik({
    initialValues: form,
    validationSchema
  });

  const handleSubmit = e => {
    e.preventDefault();
    let body = {
      beneficiary_account_code: removeBlankSpaces(form.account_code),
      beneficiary_full_name: form.name,
      beneficiary_alias: form.alias,
      beneficiary_email: form.email,
      beneficiary_account_type: form.type,
      banking_institution_id: Number(form.bank.id),
      beneficiary_rfc: form.rfc
    };

    if (isInEditMode) {
      const { beneficiary_id } = beneficiaryData;
      updateBeneficiary(dispatch, { ...body, beneficiary_id });
    } else {
      createBeneficiary(dispatch, body);
    }
  };

  const handleChange = e => {
    if (e.target.name !== 'bank' && checkEmptySpaces(e.target.value)) return;
    formik.handleChange(e);
    setForm({ ...form, [e.target.name]: e.target.value });
    setInitialState(false);
  };

  const getBankingInstitution = prefix => {
    return bankingInstitutions.filter(bank => {
      return bank.prefix === prefix;
    });
  };

  const goToTransferForm = () => {
    setPage(dispatch, 'transactionFlow');
    setBeneficiary(dispatch, { ...data, ...res });
  };

  const goToEditForm = () => {
    setPage(dispatch, 'editBeneficiary');
    setBeneficiary(dispatch, { ...data, ...res });
    setInitialState(true);
  };

  const createBankChangeEvent = (number, type) => {
    const event = { target: { name: 'bank', value: '' } };
    if (number.length >= 3 && type === 'clabe_code') {
      let prefix = number.slice(0, 3);
      let bank = getBankingInstitution(prefix);
      event.target.value = bank[0] ? bank[0] : 'No encontrado';
    }
    return event;
  };

  useEffect(() => {
    getBankings(dispatch);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!initialState && form.type === 'clabe_code') {
      let event = createBankChangeEvent(form.account_code, form.type);
      handleChange(event);
    }
    //eslint-disable-next-line
  }, [form.account_code, form.type]);

  useEffect(() => {
    if (
      form.account_code &&
      form.type === 'debit_card_number' &&
      !beneficiaryData
    ) {
      handleChange({ target: { name: 'bank', value: '' } });
    }
    //eslint-disable-next-line
  }, [form.type, beneficiaryData]);

  const setAccountType = () => {
    const event = { target: { name: 'type', value: 'debit_card_number' } };
    if (form.account_code.replace(/\s/g, '').length > 16) {
      event.target.value = 'clabe_code';
    }
    handleChange(event);
  };

  useEffect(() => {
    if (!initialState) setAccountType();
    //eslint-disable-next-line
  }, [form.account_code]);

  useEffect(() => {
    if (form.name) setForm({ ...form, initials: getInitials(form.name) });
    //eslint-disable-next-line
  }, [form.name]);

  return (
    <AddBeneficiaryWrapper>
      <AddBeneficiaryHeader
        alias={form.alias || 'Alias'}
        initials={form.initials}
        fullName={form.name || 'Persona o Compañía'}
        email={form.email}
        saved={success}
        isInEditMode={isInEditMode}
      />
      <AddBeneficiaryForm
        handleChange={handleChange}
        formAction={!success ? handleSubmit : goToTransferForm}
        form={form}
        formik={formik}
        saved={success}
        initialState={initialState}
        banks={bankingInstitutions}
        handleReturn={goToEditForm}
      />
    </AddBeneficiaryWrapper>
  );
};

export default memo(AddBeneficiary);
