const confirmTwoFactorDeviceHandler = ({
  confirm2FA_device_service
}) => async ({ code }) => {
  const { error, data } = await confirm2FA_device_service({ code });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  return { isSuccess: true, data };
};

export { confirmTwoFactorDeviceHandler };
