import React from 'react';

import PropTypes from 'prop-types';

import { Header, Buttons } from './styles.js';

const PageButtonsHeader = ({ children }) => (
  <Header data-test="buttons-header">
    <Buttons>{children}</Buttons>
  </Header>
);

PageButtonsHeader.propTypes = {
  children: PropTypes.string.isRequired
};

export default PageButtonsHeader;
