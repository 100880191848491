import { enterpriseRoutes } from '../enterprise-module/enterprise-routing-controller/routingState';
import { freelancerRoutes } from '../freelancer-module/freelancer-routing-controller/routingState';
import { onboardingActionTypes } from './onboardingActionTypes';

const initialState = {
  addressPage: {},
  freelancerSteps: freelancerRoutes,
  enterpriseSteps: enterpriseRoutes,
  freelancerBasicInfo: {},
  bankInformation: {},
  enterpriseInfo: {},
  status: null
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case onboardingActionTypes.SET_ADDRESS:
      return {
        ...state,
        addressPage: action.payload
      };
    case onboardingActionTypes.SET_FREELANCER_STEPS:
      return {
        ...state,
        freelancerSteps: action.payload
      };
    case onboardingActionTypes.SET_ENTERPRISE_STEPS:
      return {
        ...state,
        enterpriseSteps: action.payload
      };
    case onboardingActionTypes.SET_FREELANCER_INFO:
      return {
        ...state,
        freelancerBasicInfo: action.payload
      };
    case onboardingActionTypes.SET_BANK_INFORMATION:
      return { ...state, bankInformation: action.payload };
    case onboardingActionTypes.SET_ENTERPRISE_INFO:
      return { ...state, enterpriseInfo: action.payload };
    case onboardingActionTypes.SET_STATUS:
      return { ...state, status: action.payload };
    case 'PURGE':
      return initialState;
    default:
      return state;
  }
};

export default onboardingReducer;
