import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { FileInput } from './BankAccountPage.styles';
import OnboardingErrorBoundary from '../OnboardingErrorBoundary/OnboardingErrorBoundary';
import OnboardingLayout from '../../components/OnboardingLayout';
import TextField from '../../../shared/components/TextField';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';

import setInputProps from '../../helpers/set-input-props';
import { validationSchema } from './validationSchema.js';
import { service } from '../../service.js';
import { selectCompanyTypeId } from '../../redux/onboardingSelectors.js';

const Component = ({ steps, activeStep, goBack, goToNextStep, storedData }) => {
  const [bankingInstitutions, setBankingInstitutions] = useState([]);
  const companyTypeId = useSelector(selectCompanyTypeId);
  const formik = useFormik({
    initialValues: storedData || { bank: '', clabe: '' },
    validationSchema
  });

  const handleFileChange = async e => {
    if (!e.target.value) return;
    formik.setFieldValue('accountStatement', e.target.value);
  };

  const handleSubmit = () => {
    goToNextStep({ ...formik.values, companyTypeId });
  };

  const getBank = (banks, prefix) => {
    return banks.filter(bank => {
      return bank.prefix === prefix;
    });
  };

  const getPrefix = clabe => {
    return clabe.slice(0, 3);
  };

  const getBankingInstitutions = async () => {
    const {
      isError,
      data: bankingInstitutions
    } = await service().getBankingInstitutions();

    if (isError) return [];

    setBankingInstitutions(bankingInstitutions);
  };

  const handleChange = e => {
    const value = e.target.value;
    const clabe = value.replace(/[^\d.-]/g, '');

    formik.setFieldValue('clabe', value);
    formik.setFieldValue('clabe', clabe.substring(0, 18));
  };

  const setBankField = clabe => {
    if (clabe.length >= 3) {
      const clabePrefix = getPrefix(clabe);
      const bank =
        getBank(bankingInstitutions, clabePrefix)?.[0]?.name || 'No encontrado';
      formik.setFieldValue('bank', bank);
    }
  };

  useEffect(() => {
    if (formik.values.clabe) setBankField(formik.values.clabe);
    // eslint-disable-next-line
  }, [formik.values.clabe, bankingInstitutions]);

  useEffect(() => {
    getBankingInstitutions();
  }, []);

  return (
    <OnboardingLayout
      title="Cuenta Bancaria"
      description="Ingresa los datos de la cuenta bancaria"
      onPrimaryButtonClick={handleSubmit}
      primaryButtonText="Continuar"
      isPrimaryButtonDisabled={
        !(formik.isValid && formik.values.accountStatement)
      }
      secondaryButtonText="Regresar"
      onSecondaryButtonClick={goBack}
      isModalVisible
      steps={steps}
      activeStep={activeStep}
    >
      <TextField
        {...setInputProps(formik, handleChange, 'CLABE', 'clabe', 'text')}
        value={formik.values.clabe}
      />
      <TextField
        {...setInputProps(formik, formik.handleChange, 'Banco', 'bank', 'text')}
        value={formik.values.bank}
        disabled
      />
      <FileInput
        label="Carátula del estado de cuenta"
        type="file"
        name="accountStatement"
        documentName="caratula"
        onChange={handleFileChange}
        documentId={15}
        icon="cer"
        endAdornment={
          <Tooltip
            className="endAdornmentTooltip"
            label="Estado de cuenta"
            text="Máximo dos meses de antigüedad"
          />
        }
      />
    </OnboardingLayout>
  );
};

const BankAccountPage = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="BankAccountPage"
      props={props}
    />
  );
};

export default BankAccountPage;
