import styled from 'styled-components';

import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';
import Tag from '../../../shared/components/Tag';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 6rem 0 4.5rem;
  ${fonts.quickSandSemiBold};
  font-size: 1.7rem;

  .tagActive {
    background: ${colors.blueBackground};
    color: #fff;
  }
`;

export const CustomTag = styled(Tag)`
  padding: 1rem 3.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 15.5px;
  background-color: rgba(23, 144, 207, 0.05);
  letter-spacing: 0.15px;
  ${fonts.quickSandMedium};
  font-size: 1.2rem;
  height: 3rem;
  width: 10rem;
`;
