import styled from 'styled-components';

import { fonts, colors } from '../../../../Dashboard4.0/assets/styles/styles';

const setColor = ({ color, isDeleteVisible }) => {
  const colorSelector = {
    purple: {
      fontColor: '#8949c9',
      backgroundColor: '#f2e5ff'
    },
    blue: {
      fontColor: colors.primary,
      backgroundColor: 'rgba(23, 144, 207, 0.15)'
    },
    FF8400: {
      fontColor: '#FF8400',
      backgroundColor: 'rgba(255, 132, 0, 0.1);'
    },
    '00D1FF': {
      fontColor: '00D1FF',
      backgroundColor: 'rgba(0, 209, 255, 0.1);'
    },
    FF0000: {
      fontColor: 'FF0000',
      backgroundColor: 'rgba(255, 0, 0, 0.1);'
    },
    '5200FF': {
      fontColor: '5200FF',
      backgroundColor: 'rgba(69, 189, 98, 0.1);'
    },
    '1C2633': {
      fontColor: '1C2633',
      backgroundColor: 'rgba(28, 38, 51, 0.1);'
    },
    FD2D6C: {
      fontColor: 'FD2D6C',
      backgroundColor: 'rgba(253, 45, 108, 0.1);'
    },
    '0057CA': {
      fontColor: '0057CA',
      backgroundColor: 'rgba(0, 87, 202, 0.1);'
    },
    '625B74': {
      fontColor: '625B74',
      backgroundColor: 'rgba(98, 91, 116, 0.1);'
    }
  };
  let fontColor = colors.carbon;
  let backgroundColor = colors.carbon3;

  if (color) {
    fontColor = colorSelector[color].fontColor;
    backgroundColor = colorSelector[color].backgroundColor;
  }

  return `
    color: ${fontColor};
    background-color: ${backgroundColor};
    svg path {
      fill: ${fontColor}
    }
    `;
};

const setDeleteVisibility = ({ isDeleteVisible }) => {
  return `${isDeleteVisible ? 'visible' : ''} `;
};

export const TagContainer = styled.span`
  border-radius: 10rem;
  display: inline-block;
  font-family: ${fonts.barlowMedium};
  font-size: 1.02rem;
  height: 3rem;
  line-height: 0.78;
  letter-spacing: 0.12px;
  text-align: center;
  padding: 0.5rem 0.8rem;
  position: relative;
  ${setColor}

  svg {
    visibility: hidden;
    position: absolute;
    right: 0.8rem;
    cursor: pointer;
  }

  &:hover {
    svg {
      visibility: ${setDeleteVisibility};
    }
  }
`;
