import React, { useEffect } from 'react';

const Calendly = ({ calendlyUrl, onScheduled = () => {}, ...props }) => {
  const getCalendlyEvent = e => {
    return e.data.event && e.data.event.indexOf('calendly') === 0
      ? e.data.event.split('.')[1]
      : '';
  };

  const startCalendlyListener = () => {
    window.addEventListener('message', function (e) {
      const event = getCalendlyEvent(e);
      if (event === 'event_scheduled') onScheduled();
    });
  };

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
    startCalendlyListener();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`calendly-inline-widget ${props.className} `}
      data-url={calendlyUrl}
    ></div>
  );
};

export default Calendly;
