import styled from 'styled-components';

import { fonts } from '../../../../Dashboard4.0/assets/styles/styles';
import Label from '../../../shared/components/Label/Label';

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.1;
  font-size: 1.7rem;
  margin-left: '1rem';
  ${fonts.quickSandSemiBold};
`;

export const StatusLabelContainer = styled(StatusContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.1;
  font-size: 1.4rem;
  margin-left: 0.8rem;
  ${fonts.quickSandSemiBold};
  color: ${props => (props.color ? props.color : '')};

  span {
    margin: 0 0.3rem 0 0.2rem;
  }

  .tooltip {
    top: 7rem;
    right: -3rem;
    left: auto;
    z-index: 10;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;

    span {
      display: none;
    }
  }
`;

export const CustomTooltip = styled(Label)`
  background: red;
  div {
    color: red;
    margin-top: 20rem;
  }
`;
