import { setOnboardingStatus } from '../../onboarding/redux/onboardingActions';

export const updateOnboardingProgressHandler = ({
  updateOnboardingProgress
}) => async ({ newStatus }) => {
  const { error, data } = await updateOnboardingProgress({ newStatus });

  if (error) {
    return {
      isSuccess: false
    };
  }

  return { isSuccess: true, data };
};
