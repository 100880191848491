/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import { TitleContainer } from './Title.styles';

const Title = ({ text, type, size, className, children }) => {
  return (
    <TitleContainer
      data-test="titleComponent"
      type={type}
      size={size}
      className={className}
    >
      {text || children}
    </TitleContainer>
  );
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
};

export default Title;
