import styled from 'styled-components';
import { fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const Container = styled.p`
  margin: 2rem 0;
  color: ${({ color }) => (color || '#1C2633')};
  text-align: ${({ textAlign }) => (textAlign || '')};
  font-size: ${({ size }) => (size || '1.4rem')};
  line-height: ${({ lineHeight }) => (lineHeight || '1.7rem')};
  letter-spacing: ${({ letterSpacing }) => (letterSpacing || '0.218182px')};
  ${({ fontStyle }) => (fontStyle || fonts.quickSandMedium)};
`;
