/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Amount from '../../../shared/components/Amount/Amount';
import Avatar from '../../../shared/components/Avatar/Avatar';
import Date from '../../../shared/components/Date';
import Details from '../../../shared/components/Details';
import HelperTooltip from '../../../shared/components/TooltipHelper/TooltipHelper';
import Icon from '../../../shared/components/Icon/Icon';
import StatusIcon from '../../../shared/components/StatusIcon/StatusIcon';
import Title from '../../../shared/components/Title';
import useViewportWidth from '../../../../hooks/useViewportWidth';
import TagList from '../../../tags/TagList/TagList';
import reversalLink from '../../../../Dashboard4.0/assets/images/reversal-link.svg';

// Assets
import { splitDate, splitAmount } from '../../../../helpers/helpers';

// Styled Components
import {
  AvatarContainer,
  Container,
  EntryContainer,
  EntryDetailsContainer,
  IconContainer,
  IconText
} from './EntryComponent.styles';
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';

export const Component = ({
  className,
  details,
  firstAction,
  secondAction,
  thirdAction,
  isRejected,
  multiTransferAction,
  reversalsAction,
  typeEntry
}) => {
  const {
    mainText,
    secondaryText,
    colors,
    description,
    amount,
    dateTime,
    isDateVisible,
    type,
    avatarIcon,
    svg: SvgComponent,
    tags,
    batch_process_name,
    isMultiTransferItem,
    beneficiary_banking_institution,
    has_reversal,
    reversal_transactions
  } = details;

  const reversal_transaction = reversal_transactions?.[0];
  const isMultiReversal = reversal_transactions?.length > 1;

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [date, setDate] = useState('');
  const [digits] = useState(() => splitAmount(amount));
  const [size, setSize] = useState('');
  const [rejectedClass] = useState(isRejected ? 'rejected' : '');

  const width = useViewportWidth();

  const handleMouseOver = isMouseOver => {
    if (width < 769) return;
    setIsMouseOver(isMouseOver);
  };

  const handleEntryClick = () => {
    if (width > 768 || !thirdAction.method) return;
    thirdAction.method();
  };

  const getSvgSize = () => {
    if (avatarIcon === 'deltai') setSize('3rem');
  };

  useEffect(() => {
    getSvgSize();
    setDate(splitDate(dateTime, 'DD MMM YYYY'));
    //eslint-disable-next-line
  }, []);

  const handleClick = () => {
    if (thirdAction && !isMultiTransferItem) thirdAction.method();
    if (isMultiTransferItem) multiTransferAction();
  };

  return (
    <Container
      onMouseEnter={() => handleMouseOver(true)}
      onMouseLeave={() => handleMouseOver(false)}
      onClick={handleEntryClick}
    >
      {has_reversal && !!reversal_transaction && (
        <EntryContainer
          className={className}
          onClick={handleEntryClick}
          data-test="entryComponent"
          data-testid="entryComponent-reversal"
          onMouseEnter={() => handleMouseOver(true)}
          onMouseLeave={() => handleMouseOver(false)}
          columns={typeEntry === 'multitransfer' ? 4 : null}
        >
          {isDateVisible && date && typeEntry !== 'multitransfer' ? (
            <Date day={date.day} month={date.month} year={date.year} />
          ) : (
            <span></span>
          )}
          {typeEntry !== 'multitransfer' ? (
            <AvatarContainer>
              <Avatar
                colors={
                  reversal_transaction.avatarIcon !== 'multitransfer'
                    ? reversal_transaction.colors
                    : null
                }
                className="entryAvatar"
              >
                {isMultiReversal ? (
                  <Icon
                    className="avatarIconColor"
                    icon={'multiReversal'}
                    width={size}
                    height={size}
                  />
                ) : (
                  <SvgComponent className="avatarIconColor" />
                )}
              </Avatar>
              <div className="ReversalLinkContainer">
                <img src={reversalLink} />
              </div>
            </AvatarContainer>
          ) : null}
          <EntryDetailsContainer>
            <div onClick={() => reversalsAction()}>
              <Title
                type={reversal_transaction.type}
                text={reversal_transaction.description}
                className={`${rejectedClass} ${
                  isMouseOver ? 'titleHover' : ''
                } `}
              />
            </div>
            {reversal_transaction.mainText ||
            reversal_transaction.secondaryText ? (
              <Details
                mainText={reversal_transaction.mainText}
                secondaryText={reversal_transaction.secondaryText}
              />
            ) : null}
            {reversal_transaction.tags?.length ? (
              <TagList
                className="cardTransactionTags"
                tags={reversal_transaction.tags}
                showAddNewTag={false}
                lengthValidation={7}
                isDeleteVisible={false}
              />
            ) : null}
          </EntryDetailsContainer>
          <>
            {isMouseOver ? (
              <>
                <Amount
                  color={reversal_transaction.type}
                  integer={`${digits.integer}`}
                  decimals={digits.decimals}
                  className={rejectedClass}
                />
                <div className="flex-container">
                  <HelperTooltip
                    icon={firstAction.icon}
                    helper={firstAction.helperTooltip}
                  >
                    <IconContainer onClick={firstAction.method}>
                      <Icon
                        icon={firstAction.icon}
                        className={firstAction.class}
                      />
                      {firstAction.text ? (
                        <IconText className={firstAction.class}>
                          {firstAction.text}
                        </IconText>
                      ) : null}
                    </IconContainer>
                  </HelperTooltip>

                  <HelperTooltip
                    helper={secondAction.helperTooltip}
                    icon={secondAction.icon}
                  >
                    <IconContainer onClick={secondAction.method}>
                      <Icon
                        icon={secondAction.icon}
                        className={secondAction.class}
                      />
                      {secondAction.text ? (
                        <IconText className={secondAction.class}>
                          {secondAction.text}
                        </IconText>
                      ) : null}
                    </IconContainer>
                  </HelperTooltip>
                </div>
              </>
            ) : (
              <>
                <Amount
                  color={reversal_transaction.type}
                  integer={`${digits.integer}`}
                  decimals={digits.decimals}
                  className={rejectedClass}
                />
                {reversal_transaction.status ? (
                  <StatusIcon status={reversal_transaction.status || type} />
                ) : null}
              </>
            )}
          </>
        </EntryContainer>
      )}
      <EntryContainer
        className={className}
        onMouseEnter={() => handleMouseOver(true)}
        onMouseLeave={() => handleMouseOver(false)}
        data-test="entryComponent"
        data-testid="entryComponent"
        columns={typeEntry === 'multitransfer' ? 4 : null}
      >
        {has_reversal ? (
          <span></span>
        ) : isDateVisible && date && typeEntry !== 'multitransfer' ? (
          <Date day={date.day} month={date.month} year={date.year} />
        ) : (
          <span></span>
        )}
        {typeEntry !== 'multitransfer' ? (
          <AvatarContainer>
            <Avatar
              colors={avatarIcon !== 'multitransfer' ? colors : null}
              className="entryAvatar"
            >
              {SvgComponent ? (
                <SvgComponent className="avatarIconColor" />
              ) : (
                <Icon
                  className="avatarIconColor"
                  icon={avatarIcon || 'issued'}
                  width={size}
                  height={size}
                />
              )}
            </Avatar>
          </AvatarContainer>
        ) : null}
        <EntryDetailsContainer>
          <div onClick={handleClick}>
            <Title
              type={type}
              text={description}
              className={`${rejectedClass} ${isMouseOver ? 'titleHover' : ''} `}
            />
          </div>
          {mainText || secondaryText ? (
            <Details mainText={mainText} secondaryText={secondaryText} />
          ) : null}
          {tags?.length ? (
            <TagList
              className="cardTransactionTags"
              tags={tags}
              showAddNewTag={false}
              lengthValidation={7}
              isDeleteVisible={false}
            />
          ) : null}
        </EntryDetailsContainer>

        <>
          {isMouseOver ? (
            <>
              <Amount
                color={type}
                integer={`${digits.sign}${digits.integer}`}
                decimals={digits.decimals}
                className={rejectedClass}
              />
              <div className="flex-container">
                <HelperTooltip
                  icon={firstAction.icon}
                  helper={firstAction.helperTooltip}
                >
                  <IconContainer onClick={firstAction.method}>
                    <Icon
                      icon={firstAction.icon}
                      className={firstAction.class}
                    />
                    {firstAction.text ? (
                      <IconText className={firstAction.class}>
                        {firstAction.text}
                      </IconText>
                    ) : null}
                  </IconContainer>
                </HelperTooltip>

                <HelperTooltip
                  helper={secondAction.helperTooltip}
                  icon={secondAction.icon}
                >
                  <IconContainer onClick={secondAction.method}>
                    <Icon
                      icon={secondAction.icon}
                      className={secondAction.class}
                    />
                    {secondAction.text ? (
                      <IconText className={secondAction.class}>
                        {secondAction.text}
                      </IconText>
                    ) : null}
                  </IconContainer>
                </HelperTooltip>
              </div>
            </>
          ) : (
            <>
              <Amount
                color={type}
                integer={`${digits.sign}${digits.integer}`}
                decimals={digits.decimals}
                className={rejectedClass}
              />
              {details.status ? (
                <StatusIcon status={details.status || type} />
              ) : null}
            </>
          )}
        </>
      </EntryContainer>
    </Container>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.number,
  details: PropTypes.object.isRequired,
  firstAction: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  secondAction: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isRejected: PropTypes.bool,
  thirdAction: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  typeEntry: PropTypes.string
};

const EntryComponent = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="EntryCmponent"
    />
  );
};

export default memo(EntryComponent);
