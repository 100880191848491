import React from 'react';

import Modal from '../../components/Modal';
import ModalInformation from '../../components/ModalInformation';

import { RegistrationContainer } from './styles.js';

import FooterLayout from './FooterLayout';
import HeadLayout from './HeadLayout';

const RegistrationPageLayout = ({
  children,
  showLogout = false,
  handleLogOut = null,
  isModalVisible = false,
  title,
  description,
  onPrimaryButtonClick,
  primaryButtonText,
  isPrimaryButtonDisabled,
  isPrimaryButtonHidden,
  secondaryButtonText,
  onSecondaryButtonClick,
  secondaryActionComponent
}) => {
  return (
    <RegistrationContainer>
      <HeadLayout showLogout={showLogout} handleLogOut={handleLogOut} />
      {isModalVisible ? (
        <Modal open disableBackDropClick>
          <ModalInformation
            title={title}
            description={description}
            isPrimaryButtonDisabled={isPrimaryButtonDisabled}
            isPrimaryButtonHidden={isPrimaryButtonHidden}
            secondaryButtonText={secondaryButtonText}
            primaryButtonText={primaryButtonText}
            onPrimaryButtonClick={onPrimaryButtonClick}
            onSecondaryButtonClick={onSecondaryButtonClick}
          >
            {children}
          </ModalInformation>
          <div className="modalSecondaryAction">{secondaryActionComponent}</div>
        </Modal>
      ) : (
        <>{children}</>
      )}
      <FooterLayout />
    </RegistrationContainer>
  );
};

export default RegistrationPageLayout;
