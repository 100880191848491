import styled from 'styled-components';

export const AddBeneficiaryWrapper = styled.div`
  width: 54rem;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
`;
