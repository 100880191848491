import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const classes = css`
  .labels {
    font-family: ${fonts.quickSandMedium};
    font-size: 1.45rem;
    font-weight: 600;
    letter-spacing: 0.18px;
  }
  .success {
    color: ${colors.success};
  }
  .bold {
    color: ${colors.body};
  }
`;

export const Container = styled.div`
  font-family: ${fonts.barlowRegular};
  margin: 4rem 0 5.6rem 0;
  font-size: 1.55rem;
  line-height: 1.42;
  letter-spacing: 0.19px;
  color: ${colors.carbon};
  ${classes}
`;
