import React from 'react';
import { AlertLink, AlertSpan, AlertButton } from '../styles';

const userSpendingLimitArticle =
  process.env.REACT_APP_USER_SPENDING_LIMITS_ARTICLE;

export const cardLimitValidation = (
  cardOwnerSpendLimit,
  cardLimit,
  userRoleId
) => {
  const isBasicUser = userRoleId === 3;

  if (!cardOwnerSpendLimit || !cardLimit || !userRoleId) {
    return {
      isValid: false,
      errorMessage:
        'línea de crédito, crédito, límite de gasto o cantidad no se puede encontrar'
    };
  }

  if (
    typeof cardOwnerSpendLimit !== 'number' ||
    typeof cardLimit !== 'number' ||
    typeof userRoleId !== 'number'
  ) {
    return {
      isValid: false,
      errorMessage: 'Tipo de datos no válido, por favor ingrese un número'
    };
  }

  if (cardLimit > cardOwnerSpendLimit) {
    return {
      isValid: false,
      errorMessage: isBasicUser
        ? () => (
            <AlertSpan>
              El límite de la tarjeta supera su límite de gasto. Contacta a un
              administrador de tu cuenta para que te suba el límite.
            </AlertSpan>
          )
        : () => (
            <AlertSpan>
              El límite de la tarjeta supera su límite de gasto.{' '}
              <AlertLink href={userSpendingLimitArticle}>
                Increméntalo
              </AlertLink>
            </AlertSpan>
          )
    };
  }
  return { isValid: true };
};
