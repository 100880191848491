import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const Row = styled.div`
  max-width: 94.8rem;
  width: 100%;
  height: 6rem;
  border-radius: 1.2rem;
  background-color: ${({ index }) =>
    (index % 2 !== 0 ? colors.carbon5 : 'white')};
  font-family: ${fonts.barlowMedium};
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  grid-template-columns: ${({ columns }) => columns};
  column-gap: 0.8rem;
  position: relative;

  &:hover {
    border: 1px solid;
    border-color: ${colors.blueBackground};
  }

  .ownerNameLabel {
    max-width: 12rem;
  }

  .cardName,
  .ownerName {
    white-space: nowrap;
    overflow: hidden;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .cardItemMenu {
    position: absolute;
    right: 0;
  }

  .statusContainer {
    @media screen and (max-width: 769px) {
      max-width: 40px;
    }

    .status {
      @media screen and (max-width: 769px) {
        font-size: 12px;
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  & > div {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${fonts.barlowMedium};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2125px;
    color: #1c2633;
    cursor: pointer;

    @media screen and (max-width: 769px) {
      font-size: 12px;
    }
  }

  .statusTooltip {
    left: -13rem;
    top: 3rem;
  }

  .tooltipContainer {
    margin-left: 0.8rem;
  }

  .redColor {
    color: ${colors.deltai};
  }

  @media (max-width: 768px) {
    & > a {
      font-size: 1.1rem;
    }
  }
`;

export const ApprovalLink = styled.span`
  text-decoration-line: underline;
  color: #168fce;
`;
