import React, { useState } from 'react';

import Menu from './Menu';

import { SideNavContainer } from './SideNav.styles';

const SideNav = ({ isMobileMenu }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <SideNavContainer isMenuOpen={isMenuOpen} isMobileMenu={isMobileMenu}>
      <Menu
        isMenuOpen={isMenuOpen}
        toggleMenuOpen={() => setIsMenuOpen(!isMenuOpen)}
      />
    </SideNavContainer>
  );
};

export default SideNav;
