export const selectEmail = state => state.userInfoReducer.email;
export const selectCards = state => state.cardsReducer.cards;
export const selectCardSuccess = state => state.cardsReducer.success;
export const selectCardsError = state => state.cardsReducer.cardsError;
export const selectUsers = state => state.cardsReducer.users;
export const selectUsersError = state => state.cardsReducer.usersError;
export const selectGetOwnerSpendLimit = state => userId =>
  state.cardsReducer.users.find(user => user.userId === userId)?.spendLimit;
export const validCardIdsSelector = state =>
  state.cardsReducer.cards.map(card => Number(card.creditCardId));
export const isValidCardIdSelector = cardId => state =>
  state.cardsReducer.cards
    .map(card => Number(card.creditCardId))
    .includes(Number(cardId));
export const validCardRequestIdsSelector = state =>
  state.cardsReducer.cards.map(card => Number(card.cardAdminRequestId));
export const isValidCardRequestIdSelector = cardRequestId => state =>
  state.cardsReducer.cards
    .map(card => Number(card.cardAdminRequestId))
    .includes(Number(cardRequestId));
export const isSuperAdminSelector = state =>
  state.userInfoReducer.user.user_role_name === 'Super Admin';
export const isAdminSelector = state =>
  state.userInfoReducer.user.user_role_name === 'Admin';
export const isBasicUserSelector = state =>
  state.userInfoReducer.user.user_role_name === 'Basic User';
export const userTypeSelector = state => state.rolePermissionsReducer.userType;
export const savedFilterByType = state => state.cardsReducer.filterByType;
export const selectAvailablePhysicalCards = state =>
  state.cardsReducer.availablePhysicalCards;
export const selectAvailableVirtualCards = state =>
  state.cardsReducer.availableVirtualCards;
