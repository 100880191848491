import { FullStoryAPI } from 'react-fullstory';

import {
  parseToken,
  saveAuthToken,
  saveRefreshToken
} from '../Services/authService';
import {
  SAVE_REFRESH_TOKEN,
  SAVE_TOKEN_EXP,
  SAVE_TOKEN,
  SAVE_USER_EMAIL,
  USER_DATA,
  UPDATE_USER_STATUS,
  CUSTOMER_INFO
} from '../reducers/types';
import { SET_USER_ROLE } from '../role-based-access/redux/role-permissions-types';

export const saveUserData = (data, email, dispatch) => {
  // leaving access_token and refresh_token for the time being to support the current flow
  let token = data.access_token || data.accessToken;
  let refreshToken = data.refresh_token || data.refreshToken;
  let userData = parseToken(token);
  userData.user_claims['email'] = email;
  let name = userData.user_claims.company_information.payer_name;
  let tokenDetails = {
    iat: userData.iat,
    exp: userData.exp
  };

  if (process.env.REACT_APP_ENABLE_INTERCOM === 'true') {
    const API_BASE = process.env.REACT_APP_INTERCOM_API_URL;
    const APP_ID = process.env.REACT_APP_INTERCOM_ID;

    window.intercomSettings = {
      api_base: API_BASE,
      app_id: APP_ID,
      email: userData.user_claims['email'],
      user_hash: userData.user_claims['user_hash']
    }

    window.Intercom('update', {
      name: name,
      email: email
    });
  }

  if (process.env.REACT_APP_ENABLE_FULLSTORY === 'true') {
    FullStoryAPI('identify', userData.user_claims.user_id, {
      displayName: name,
      email: email
    });
  }

  saveAuthToken(token);
  saveRefreshToken(refreshToken);
  if(data.customerData){
    dispatch({ type: CUSTOMER_INFO, payload: data.customerData });
  }

  dispatch({ type: USER_DATA, payload: userData.user_claims });
  dispatch({ type: SAVE_TOKEN_EXP, payload: tokenDetails });
  dispatch({ type: SAVE_USER_EMAIL, payload: email });
  dispatch({ type: SAVE_TOKEN, payload: token });
  dispatch({ type: SAVE_REFRESH_TOKEN, payload: refreshToken });
};

export const setTokenOnRefresh = (data, dispatch) => {
  let token = data[0].access_token;
  let userData = parseToken(token);
  let tokenDetails = {
    iat: userData.iat,
    exp: userData.exp
  };
  saveAuthToken(token);

  dispatch({ type: USER_DATA, payload: userData.user_claims });
  dispatch({ type: SAVE_TOKEN_EXP, payload: tokenDetails });
  dispatch({ type: SAVE_TOKEN, payload: token });
  dispatch({ type: SET_USER_ROLE, payload: null });
};

export const updateUserStatus = (status, dispatch) => {
  dispatch({ type: UPDATE_USER_STATUS, payload: status });
};
