// React
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Others
import PropTypes from 'prop-types';

// Components
import Accordion from './Accordion';

// Helpers
import { parseAmount } from '../../../../helpers';
import { getTransactions } from './helpers';
import { alert } from '../../../../helpers/alertsManager';
import {
  convertToJSON,
  processCSVFile
} from '../../../../Dashboard4.0/components/CSVFile/helpers';
import { getSpendingLimitHandler } from '../../../../Dashboard4.0/components/Transaction/eventHandlers/get-spending-limit-handler';
import { useCallback } from 'react';

const removeSpecialChars = value => {
  return Number(value.replace(/[^0-9.]+/g, ''));
};

const FileLoader = ({ file, setParsedFile, setFileError, dataParsed }) => {
  const [fileFormat, setFileFormat] = useState({ rows: [], cols: [] });
  const [totalAmount, setTotalAmount] = useState(0);
  const [ready, setReady] = useState(false);
  const [transactions, setTransactions] = useState({
    success: [],
    duplicates: [],
    errors: []
  });
  const beneficiaries = useSelector(
    state => state.speiReducer.beneficiaries.res
  );

  const getAmount = (rows, cols) => {
    const amount = rows
      .filter((_row, index) => index !== 0)
      .map(_row => removeSpecialChars(cols.length === 4 ? _row[0] : _row[5]))
      .reduce((acc, current) => {
        if (cols.length > 4) {
          return (
            parseFloat(Array.isArray(acc) ? acc[5] : acc) + parseFloat(current)
          );
        }
        return (
          parseFloat(Array.isArray(acc) ? acc[0] : acc) + parseFloat(current)
        );
      }, 0);

    setTotalAmount(parseFloat(amount));
    return amount;
  };

  const getSpendingLimit = useCallback(async () => {
    const { data, error } = await getSpendingLimitHandler();
    if (!error && totalAmount) {
      if (totalAmount > parseFloat(data.userSpendLimit)) {
        alert(
          'warning',
          `El monto de esta orden de multiTransfer es mayor a tu crédito disponible.
          Puedes proceder, pero no todas las transferencias serán ejecutadas con éxito.`,
          false
        );
      }
    }
  }, [totalAmount]);

  useEffect(() => {
    getSpendingLimit();
  }, [totalAmount, getSpendingLimit]);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = evt => {
      const data = processCSVFile(evt);
      const { rows, cols } = convertToJSON(data);

      // clean up & format
      let count = 1;
      const rowsReferences = rows.map((row, index) => {
        // trim columns
        row = row.map(el => el.trim());
        if (index === 0) return row;

        // add row count
        let count_ndx = cols.length === 7 ? 6 : 3;
        let needs_count = row[count_ndx] === '';
        if (needs_count) {
          row[count_ndx] = count.toString();
          count += 1;
        }

        // truncate long fields
        let trunc_ndx = cols.length === 7 ? 4 : 2;
        let needs_trunc = row[trunc_ndx].length >= 40;
        if (needs_trunc) {
          row[trunc_ndx] = row[trunc_ndx].substring(0, 20).concat('...');
        }

        return row;
      });

      const validatedTransactions = getTransactions(
        rowsReferences,
        cols,
        beneficiaries
      );

      const noErrorTransactions = validatedTransactions.success.concat(
        validatedTransactions.duplicates
      );

      dataParsed({
        amount: parseAmount(getAmount(noErrorTransactions, cols)),
        beneficiaries: noErrorTransactions.filter((row, index, self) => {
          if (
            index > 0 &&
            self
              .map(el => (cols.length === 4 ? el[1] : el[0]))
              .indexOf(cols.length === 4 ? row[1] : row[0]) === index
          ) {
            return row;
          }
        }).length,
        newsBeneficiaries: validatedTransactions.news
      });

      setTransactions(validatedTransactions);
      setFileFormat({ rows, cols });
      if (validateIsEmptyFile(rows)) {
        setFileError(
          'El documento está vacío. Por favor selecciona otro archivo.'
        );
      }

      if (!getIsColumnsNumberValid(rows)) {
        setFileError(
          'Por favor verifica el formato del documento. Recuerda no usar comas.'
        );
      }
      setReady(true);
      setParsedFile(data);
    };
    reader.readAsBinaryString(file);
  }, [file, setParsedFile, setFileError]);

  const validateIsEmptyFile = (rows = []) => {
    if (!rows[0] || !rows[1]) return true;
  };

  const getIsColumnsNumberValid = rows => {
    let isColumnsNumberValid = true;
    rows.map(row => {
      if (row.length > 8) isColumnsNumberValid = false;
    });
    return isColumnsNumberValid;
  };

  return (
    <Accordion
      transactions={transactions}
      rows={fileFormat.rows}
      ready={ready}
    />
  );
};

File.propTypes = {
  setParsedFile: PropTypes.func.isRequired,
  setFileError: PropTypes.func.isRequired,
  dataParsed: PropTypes.string.isRequired,
  file: PropTypes.shape({
    lastModified: PropTypes.number,
    lastModifiedDate: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    webkitRelativePath: PropTypes.string
  }).isRequired
};

export default FileLoader;
