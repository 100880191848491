import * as yup from 'yup';

const requiredFieldMessage = 'Campo requerido';

export const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Ingresa una contraseña de al menos 8 caracteres')
    .required(requiredFieldMessage),
  email: yup
    .string()
    .email('Ingresa un email válido')
    .required(requiredFieldMessage)
});

export const TwoFactorAuthValidation = yup.object({
  authCode: yup.string().length(6).required(requiredFieldMessage)
});
