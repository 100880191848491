import React from 'react';

import { Route } from 'react-router-dom';
import Softwall from './pages/softwall/Softwall';
import OnboardingErrorBoundary from './pages/OnboardingErrorBoundary/OnboardingErrorBoundary';

import {
  softwallTpaPath
} from './constants';



const Component = () => {

  return (
    <>
      <Route 
        path={softwallTpaPath}
        component={Softwall}
        redirect="/"
      />
    </>
  );
};

const OnboardingTpa = props => (
  <OnboardingErrorBoundary
    component={<Component {...props} />}
    name="Onboarding-tpa"
    isModalVisible
  />
);

export default OnboardingTpa;
