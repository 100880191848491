/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import EntryDetails from '../../../../shared/components/EntryDetails/EntryDetails';

// Helpers
import {
  getShortDate,
  parseAmount,
  splitAmount
} from '../../../../../helpers/helpers';

const CreditVoucher = ({ details, reversals = [], type }) => {
  return (
    <>
      <EntryDetails
        title="Comercio"
        hasSeparator
        details={[
          { name: 'Nombre del comercio', value: details.description },
          { name: 'País del comercio', value: details.country_name }
        ]}
      />
      <EntryDetails
        title="Tipo de cambio"
        hasSeparator
        details={[
          { name: 'Tipo de cambio', value: details.exchange_rate },
          {
            name: 'Monto original',
            value: `${details.total_origin_amount}`
          }
        ]}
      />
      <EntryDetails
        title="Detalles"
        details={[
          { name: 'No. de autorización', value: details.reference_number },
          {
            name: 'Tarjeta',
            value: details.last_four_digits
          },
          {
            name: 'Estatus',
            value: {
              errorMessage: details.error_message,
              status:
                details.transaction_type_id === 6 ? 'reversal' : details.status
            }
          }
        ]}
      />
      <EntryDetails
        title="Detalles"
        hasReversals
        details={reversals.flatMap(reversal => {
          const amount = splitAmount(parseAmount(reversal?.amount));
          return [
            {
              name: 'Fecha',
              value: getShortDate(
                reversal.transaction_datetime,
                'Do MMM YYYY, h:mm:ss a'
              )?.toLowerCase()
            },
            {
              name: 'No. de autorización',
              value: reversal.reference_number_reversal
            },
            {
              name: 'Tarjeta',
              value: reversal.last_four_digits
            },
            {
              name: 'Monto',
              value: `${amount.sign} ${amount.integer}`
            },
            {
              name: 'Estatus',
              value: {
                errorMessage: reversal.error_message,
                status:
                  reversal.transaction_type_id === 6
                    ? 'reversal'
                    : reversal.status
              }
            }
          ];
        })}
      />
    </>
  );
};

CreditVoucher.propTypes = {
  details: PropTypes.object.isRequired,
  type: PropTypes.string
};

export default CreditVoucher;
