/* eslint-disable no-return-await */
import { customAxiosInstance } from './axiosConfig';
import { getUserTokenInfo } from './authService';

const onBoardingPerson = `/api/dashboard/person`;
const baseUrl = '/api/dashboard/';
const companyBaseUrl = `${baseUrl}company`;

export const createContactCommunicationCompany = async doc => {
  return customAxiosInstance().post(
    `${companyBaseUrl}/contact-communication`,
    doc
  );
};

export const updateCompany = async doc => {
  return customAxiosInstance().put(companyBaseUrl, doc);
};

export const createCompanyAddress = async doc => {
  let userId = getUserTokenInfo().user_id;
  doc['user_id'] = userId;

  return customAxiosInstance().post(`${onBoardingPerson}/address`, doc);
};

export const createBankInformation = data => {
  return customAxiosInstance().post(`${baseUrl}bankinformation`, data);
};

//To create freelancer person
export const createFreelancerPerson = async data => {
  return await customAxiosInstance().put(onBoardingPerson, data);
};

//To create freelancer owner and employee person
export const createPerson = async data => {
  return await customAxiosInstance().post(onBoardingPerson, data);
};

//CreateEmployee | CreateOwner | CreateAttorneyInLaw | CreateFreelancer
export const updatePersonEntity = async data => {
  return await customAxiosInstance().put(onBoardingPerson, data);
};

//Create Member of the Board
export const createMemberOfTheBoard = async data => {
  return await customAxiosInstance().post(onBoardingPerson, data);
};

export const getCompanySummary = async () => {
  return await customAxiosInstance().get(companyBaseUrl);
};
