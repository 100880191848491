import styled from 'styled-components';

import { colors, fonts } from '../../../Dashboard4.0/assets/styles/styles';
import { getGridColumns } from '../../../Dashboard4.0/assets/styles/grid';

const colorValues = {
  blue: {
    fontColor: colors.primary,
    background: colors.primary4
  }
};

const setColor = ({ active }) => {
  let background = colors.white;
  let defaultColor = colors.carbon;

  if (active) {
    defaultColor = colorValues.blue.fontColor;
    background = colorValues.blue.background;
  }

  return `
    background: ${background};
    color: ${defaultColor};
`;
};

export const Container = styled.div`
  height: fit-content;
  width: 21.4rem;
  overflow: hidden;
  border-radius: 12px;
  border: solid 1px ${colors.carbon3};
  padding: 16px 0;
`;

export const Section = styled.div`
  font-family: ${fonts.quickSandSemiBold};
  font-weight: 600;
  line-height: 4;
  letter-spacing: 0.5px;
  width: 100%;
  height: 4rem;
  padding: 0 2.4rem;
  margin: 0;
  cursor: pointer;
  ${setColor}
  ${getGridColumns}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.carbon3};
`;
