import React from 'react';
import eSignature from '../../../assets/constancy_electronic_signature.svg';
import ineFront from '../../../assets/ine_face.svg';
import ineBack from '../../../assets/ine_fingerprint.svg';
import { companyId, freelancerId } from '../constants';

export const documentsCatalogue = {
  documents: {
    1: {
      kyc_document_type_id: 1,
      name: 'INE anverso',
      tooltip: true,
      customTooltip: (
        <div style={{ width: '100%' }}>
          <img style={{ width: '100%' }} src={ineFront} alt="" />
        </div>
      ),
      type: freelancerId,
      takePhoto: true,
      keyword: 'ine_anverso'
    },
    2: {
      kyc_document_type_id: 2,
      name: 'Comprobante de domicilio (máximo 2 meses de antigüedad)',
      tooltip: true,
      type: 'both',
      keyword: 'comprobante_domicilio',
      link: process.env.REACT_APP_PROOF_OF_RESIDENCE_ARTICLE,
      textLink: '¿Cómo debe ser mi comprobante de domicilio?'
    },
    3: {
      kyc_document_type_id: 3,
      name: 'Acta Constitutiva',
      tooltip: true,
      description:
        'Con datos de inscripción en el Registro Público de Comercio.',
      type: companyId,
      keyword: 'acta_constitutiva'
    },
    4: {
      kyc_document_type_id: 4,
      name: 'Constancia de Situación Fiscal (máximo 2 meses de antigüedad)',
      tooltip: true,
      type: 'both',
      keyword: 'cedula_id_fiscal',
      link: process.env.REACT_APP_CIF_ARTICLE,
      textLink: 'Genera una en línea'
    },
    5: {
      kyc_document_type_id: 5,
      name: 'Constancia de la Firma Electrónica Avanzada',
      tooltip: true,
      type: 'both',
      keyword: 'firma_electronica',
      link: process.env.REACT_APP_E_SIGNATURE_ARTICLE,
      textLink: '¿Qué es la constancia de firma electrónica?'
    },
    7: {
      kyc_document_type_id: 7,
      name: 'Constancia de la Firma Electrónica Avanzada',
      tooltip: true,
      customTooltip: (
        <div style={{ width: '100%' }}>
          <p>
            También puedes continuar con Comprobante de Certificado de Sello
            Digital.
          </p>
          <img style={{ width: '100%' }} src={eSignature} alt="" />
        </div>
      ),
      keyword: 'firma_electronica'
    },
    8: {
      kyc_document_type_id: 8,
      name: 'Constancia de poderes',
      tooltip: true,
      type: freelancerId,
      keyword: 'constancia_poderes',
      description:
        'La constancia de poderes es el instrumento público que te dieron en la notaría o un corredor público en el que fuiste nombrado apoderado o administrador único de la PYME, o te otorgaron facultades como el presidente del Consejo de Administración.'
    },
    9: {
      kyc_document_type_id: 9,
      name: 'INE anverso',
      tooltip: true,
      customTooltip: (
        <div style={{ width: '100%' }}>
          <img style={{ width: '100%' }} src={ineFront} alt="" />
        </div>
      ),
      type: freelancerId,
      takePhoto: true,
      keyword: 'ine_anverso'
    },
    11: {
      kyc_document_type_id: 11,
      name: 'INE reverso',
      tooltip: true,
      customTooltip: (
        <div style={{ width: '100%' }}>
          <img style={{ width: '100%' }} src={ineBack} alt="" />
        </div>
      ),
      type: freelancerId,
      takePhoto: true,
      keyword: 'ine_reverso'
    },
    12: {
      kyc_document_type_id: 12,
      name: 'INE reverso',
      tooltip: true,
      customTooltip: (
        <div style={{ width: '100%' }}>
          <img style={{ width: '100%' }} src={ineBack} alt="" />
        </div>
      ),
      type: freelancerId,
      takePhoto: true,
      keyword: 'ine_reverso'
    },
    13: {
      kyc_document_type_id: 13,
      name: 'Selfie',
      type: freelancerId,
      takePhoto: true,
      keyword: 'selfie'
    },
    14: {
      kyc_document_type_id: 14,
      name: 'Selfie',
      type: freelancerId,
      takePhoto: true,
      keyword: 'selfie'
    },
    15: {
      kyc_document_type_id: 15,
      name: 'Carátula del estado de cuenta',
      tooltip: true,
      type: 'both',
      keyword: 'caratula',
      description: 'No mayor a 2 meses de antigüedad'
    },
    16: {
      kyc_document_type_id: 16,
      name: 'Contrato firmado',
      type: freelancerId,
      keyword: 'contrato'
    },
    17: {
      kyc_document_type_id: 17,
      name: 'Boleta de inscripción en el Registro Público de Comercio',
      tooltip: true,
      type: companyId,
      keyword: 'registro_publico_comercio',
      description:
        'Por lo general, este documento se encuentra adjunto al Acta constitutiva'
    },
    18: {
      kyc_document_type_id: 18,
      name: 'otro',
      type: freelancerId,
      keyword: 'otro'
    }
  },
  company: [
    {
      kyc_document_type_id: 3,
      name: 'Acta Constitutiva'
    },
    {
      kyc_document_type_id: 4,
      name: 'Constancia de Situación Fiscal (máximo 2 meses de antigüedad)'
    },
    {
      kyc_document_type_id: 5,
      name: 'Constancia de la Firma Electrónica Avanzada'
    },
    {
      kyc_document_type_id: 2,
      name: 'Comprobante de domicilio (máximo 2 meses de antigüedad)'
    },
    {
      kyc_document_type_id: 17,
      name: 'Boleta de inscripción en el Registro Público de Comercio'
    }
  ],
  person: [
    {
      kyc_document_type_id: 4,
      name: 'Constancia de Situación Fiscal (máximo 2 meses de antigüedad)'
    },
    {
      kyc_document_type_id: 2,
      name: 'Comprobante de domicilio (máximo 2 meses de antigüedad)'
    },
    {
      kyc_document_type_id: 5,
      name: 'Constancia de la Firma Electrónica Avanzada'
    }
  ]
};
