import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Modal from './Modal';

import { userSelector } from '../../redux/crud-selectors';

import { labels, statuses } from '../Status/statuses';
import {
  approveProfile,
  approveUser,
  createUser,
  declineRequestedUpdate,
  declineUserDeleteRequest,
  deleteUser,
  resendEmailActivation,
  updateAdminRequest,
  updateUser
} from './event-handlers';

import { useMixPanel } from '../../../shared/mixpanel/';

const getLabels = type => {
  return labels[type] || labels.default;
};

const convertToFloat = value => {
  if (!value) return;
  const amount = value.replace(/,/g, '');
  return parseFloat(amount).toFixed(2);
};

const UserModal = ({ open, onClose, type, handleDelete }) => {
  const user = useSelector(userSelector);
  const labels = getLabels(type);
  const [sendMixPanelEvent] = useMixPanel();

  const handleSubmit = async (e, formik) => {
    e.preventDefault();
    const limit = convertToFloat(formik.values.spendingLimit);
    const values = {
      ...formik.values,
      spendingLimit: limit
    };

    const sendTrackingEvent = () => {
      sendMixPanelEvent('Add a user', {
        'Added User Spending Limit': values.spendingLimit
      });
    };

    switch (type) {
      case 'create':
        createUser(values, onClose, sendTrackingEvent);
        break;
      case 'update':
        updateUser(values, user.userId, onClose);
        break;
      case statuses.approval_user_creation:
        approveUser(user, onClose);
        break;
      case statuses.approval_profile_completion:
        approveProfile(user, onClose);
        break;
      case statuses.admin_delete_requested:
        deleteUser(user, onClose);
        break;
      case statuses.admin_update_requested:
        updateAdminRequest(user.updateRequestId, onClose);
        break;
      case statuses.invitation_sent:
        resendEmailActivation(user.email, onClose);
        break;
      case 'approve':
        approveUser(user, onClose);
        break;
      default:
        break;
    }
  };

  const deletRequestId = user?.adminDeleteRequest?.delete_request_id;

  const handleDecline = async () => {
    switch (type) {
      case statuses.admin_update_requested:
        declineRequestedUpdate(user.updateRequestId, onClose);
        break;
      case statuses.admin_delete_requested:
        declineUserDeleteRequest(deletRequestId, onClose);
        break;
      default:
        onClose();
        break;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      buttonText={labels.buttonText}
      handleDelete={handleDelete}
      handleSubmit={handleSubmit}
      handleDecline={handleDecline}
      submitText={labels.submitText}
      title={labels.title}
      subtitle={labels.subtitle || ''}
      type={type}
      disabled={labels.disabled || false}
    />
  );
};

UserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default UserModal;
