'use client'
import "./index.scss";

import React,{ Component } from "react";

import HSpacing from "../spacing/horizontal-spacing";
import VSpacing from "../spacing/vertical-spacing";
import BodyText from "../body-text";


class Header extends Component {
    constructor(props) {
        super(props);
    }
    // componentDidMount() {
    //     window.zESettings = {
    //         webWidget: {
    //           color: {
    //             theme: '#ed2246',
    //             launcher: '#ed2246',
    //             launcherText: '#ffffff',
    //             button: '#ed2246',
    //             resultLists: '#1790cf',
    //             header: '#ed2246',
    //             articleLinks: '#1790cf'
    //           }
    //         }
    //       };
      
    //       // Dynamically create and append the script tag
    //       const script = document.createElement('script');
    //       script.id = 'ze-snippet';
    //       script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ENV.ZENDESK_API_KEY}`;
    //       script.async = true;
    //       document.body.appendChild(script);
    // }


    // componentWillUnmount() {
    //     // Clean up function to remove zESettings when the component unmounts
    //     delete window.zESettings;
    //   }

    // goHome() {
    //     console.log("Aqui vamos saliendo");
    // }
    render() {
        
        return (
            <>
                

                <HSpacing times={5} />
                <div className="footer">
                    <div className="labels">
                        <BodyText style={{ color: "#939CA9" }}>
                            © {new Date().getFullYear()} Happy Days Tech
                        </BodyText>
                        <VSpacing times={12} />
                        <BodyText style={{ color: "#939CA9" }}>
                            Contáctanos con un correo a&nbsp;
                            <a
                                className="body_text default"
                                style={{
                                    color: "#939CA9",
                                    fontWeight: "600",
                                }}
                                href="malto:ayuda@delt.ai"
                            >
                                ayuda@delt.ai
                            </a>
                        </BodyText>
                        <VSpacing times={12} />
                        <BodyText style={{ color: "#939CA9" }}>
                            <a
                                className="body_text default"
                                style={{
                                    color: "#939CA9",
                                }}
                                href="https://delt-ai.zendesk.com/hc/es-419"
                            >
                                Centro de Ayuda
                            </a>
                        </BodyText>
                    </div>
                </div>
                <HSpacing times={4} />
            </>
        );
    }
}
export default Header;
