import React, { useState } from 'react';

// Auxiliary libraries
import { useFormik } from 'formik';

import EmailSent from '../EmailSent';
import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout';
import TextField from '../../../shared/components/TextField';

import { validationSchema } from './validationSchema';

import { FormWrapper } from './styles';

import { service } from '../../service';
import { loginLink } from '../../constants';

const ForgotPasswordPage = ({ history }) => {
  const [showEmailSent, setShowEmailSent] = useState(false);
  const initialValues = { email: '' };
  const formik = useFormik({
    initialValues,
    validationSchema
  });

  const sendEmail = async () => {
    const { email } = formik.values;
    const { isSuccess } = await service().sendResetPswdEmail({ email });

    if (isSuccess) {
      setShowEmailSent(true);
    }
  };

  const handleReturn = async () => {
    history.push(loginLink);
  };

  return (
    <>
      {!showEmailSent ? (
        <RegistrationPageLayout
          title="¿Olvidaste tu contraseña?"
          description="Ingresa tu correo electrónico para recuperarla."
          onPrimaryButtonClick={sendEmail}
          primaryButtonText="Restablecer"
          secondaryButtonText="Regresar"
          isPrimaryButtonDisabled={!formik.values.email || !formik.isValid}
          isModalVisible
          onSecondaryButtonClick={handleReturn}
        >
          <FormWrapper>
            <TextField
              data-test="email"
              className="formField"
              label="Correo electrónico"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.email && formik.touched.email}
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
            />
          </FormWrapper>
        </RegistrationPageLayout>
      ) : (
        <EmailSent />
      )}
    </>
  );
};

export default ForgotPasswordPage;
