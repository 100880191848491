import React from 'react';

// Auxiliary libraries
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

// Components
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';

// Others
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    marginTop: '3.5rem'
  },
  container: {
    '& div > span': {
      marginTop: '2.5rem'
    }
  },
  stepContainer: {
    padding: '0'
  },
  step: {
    width: '1.7rem',
    '& span > span ': {
      paddingRight: '0',
      paddingTop: '0.5rem',
      fontSize: '0.9rem',
      width: '10rem'
    },
    '& span > span > svg path': {
      d: "path('M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2')"
    },
    '& span > span > svg text': {
      display: 'none'
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column-reverse',
      color: '#939CA9',
      lineHeight: '11px;'
    }
  },
  active: {
    '& span > span > svg': {
      borderRadius: '50%',
      fill: colors.primary,
      width: '1.7rem',
      height: '1.7rem',
      boxShadow: '0px 0px 10px 2px rgba(23, 144, 207, 0.25)',
      paddingRight: '0'
    }
  },
  inactive: {
    '& span > span > svg': {
      width: '1.5rem',
      height: '1.5rem',
      border: ' 1px solid rgba(204, 210, 219, 0.8)',
      borderRadius: '50%',
      fill: colors.white,
      paddingRight: '0'
    }
  }
}));

const OnboardingStepper = ({ steps, activeStep }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleStep = path => () => {
    history.push(path);
  };

  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={activeStep} className={classes.container}>
        {steps.map((item, index) => (
          <Step key={index} className={classes.stepContainer}>
            <StepButton
              className={`${classes.step} ${
                activeStep === index
                  ? classes.active
                  : item.isComplete
                  ? classes.active
                  : classes.inactive
              }`}
              onClick={handleStep(item.path, index)}
              completed={item.isComplete}
            >
              {item.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default OnboardingStepper;
