import React from 'react';

// Auxiliary libraries
import PropTypes from 'prop-types';

// Styled
import { Container } from './EmptyStateLabel.styles';

const EmptyStateLabel = ({ label, labelBold, labelSuccess }) => {
  return (
    <Container data-test="EmptyStateLabel">
      {label}
      {labelBold && labelSuccess ? (
        <>
          <span className="labels bold"> {labelBold}</span> y{' '}
          <span className="labels success">{labelSuccess}</span>:
        </>
      ) : null}
    </Container>
  );
};

EmptyStateLabel.propTypes = {
  label: PropTypes.string.isRequired,
  labelBold: PropTypes.string.isRequired,
  labelSuccess: PropTypes.string.isRequired
};

export default EmptyStateLabel;
