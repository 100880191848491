import React, { useEffect, useState } from 'react';

import { PrivateRoute } from '../../../../routing/PrivateRoute';

// Auxiliary libraries
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import EmptyState from '../../pages/OnboardingErrorBoundary/EmptyState';
import OnboardingErrorBoundary from '../../pages/OnboardingErrorBoundary/OnboardingErrorBoundary';

import {
  events,
  handleMachine
} from '../state-machine/state-machine-definition';

import freelancerActionsManager from '../freelancer-routing-service/freelancer-actions-manager';
import { freelancerRoutes } from './routingState';
import {
  selectFreelancerSteps,
  selectPayerName,
  selectUser
} from '../../redux/onboardingSelectors';
import {
  setFreelancerSteps,
  setOnboardingStatus
} from '../../redux/onboardingActions';
import { service } from '../freelancer-routing-service/service';
import { newOnboardingPath } from '../../constants';

const Component = () => {
  // Selectors
  const onboardingSteps = useSelector(selectFreelancerSteps);
  const user = useSelector(selectUser);
  const userId = user.user_id;
  const userName = useSelector(selectPayerName);

  // Hooks
  const [state, setState] = useState(handleMachine().createMachine.value);
  const [steps, setSteps] = useState(Object.values(onboardingSteps));
  const [activeStep, setActiveStep] = useState(0);

  // Others
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (freelancerRoutes[location.pathname]) {
      setActiveStep(freelancerRoutes[location.pathname].index);

      if (location.pathname !== freelancerActionsManager[state].path) {
        return setState(
          handleMachine(
            freelancerRoutes[location.pathname].state
          ).createMachine.transition(state, events.REDIRECT)
        );
      }
    } else if (location.pathname.includes('enterprise')) history.push('/');
  }, [location, state, history]);

  useEffect(() => {
    return setSteps(Object.values(onboardingSteps));
  }, [onboardingSteps]);

  const handleComeBackStep = () => {
    const previousStep = handleMachine().createMachine.transition(
      state,
      events.COME_BACK_STEP
    );
    history.push(freelancerActionsManager[previousStep].path);
  };

  const handleGoForwardStep = async () => {
    await dispatch(
      setFreelancerSteps({
        ...onboardingSteps,
        [location.pathname]: {
          ...onboardingSteps[location.pathname],
          isComplete: true
        }
      })
    );

    await dispatch(setOnboardingStatus(state));

    if (state !== 'DOCUMENTS_STEP')
      setState(
        handleMachine().createMachine.transition(state, events.GO_FORWARD_STEP)
      );
  };

  const handleSubmit = data => {
    service(state).getFormProcess(
      dispatch,
      history,
      handleGoForwardStep,
      state,
      data
    );
  };

  return (
    <PrivateRoute path={service(state).getPath} exact>
      {state ? (
        service(state).getComponent({
          steps,
          goBack: handleComeBackStep,
          goToNextStep: data => handleSubmit(data),
          activeStep,
          user,
          userId,
          userName,
          history,
          storedData: service(state).getSelector(useSelector)
        })
      ) : (
        <EmptyState
          onPrimaryButtonClick={() => history.push(newOnboardingPath)}
        />
      )}
    </PrivateRoute>
  );
};

const FreelancerRoutesController = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="FreelancerRoutesController"
      props={props}
    />
  );
};

export default FreelancerRoutesController;
