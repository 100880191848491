import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

const getFontSize = ({ size }) => {
  if (size === 'large') return fontSizeLarge;

  return fontSizeMedium;
};

const fontSizeLarge = css`
  font-size: 2.4rem;
`;
const fontSizeMedium = css`
  font-size: 1.7rem;
`;

const getFontColor = ({ type }) => {
  if (type === 'success') return colors.success;
  if (type === 'primary') return colors.primary;

  return colors.detailsBlack;
};

export const CreditPayContainer = styled.div`
  width: 100%;
  max-height: 100vh;

  .creditPayTitle {
    color: ${colors.carbon};
    padding-bottom: 2rem;
  }

  .list {
    ${fonts.barlowRegular}
    font-size: 1.4rem;
    margin-top: 4.1rem;
    padding: 1rem;
  }

  .listItem {
    margin: 0 auto 4.5rem;
    display: flex;

    max-width: 60rem;

    &:nth-child(1) > p {
      margin: 8px 0;
    }

    &:nth-child(3) > p {
      margin: 0;
    }

    p {
      width: 77%;
    }

    span {
      font-weight: bold;
    }

    a {
      color: ${colors.body};
    }
  }

  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    background: linear-gradient(90deg, #1c2633 0%, #425d80 100%);
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    margin-top: 0.6rem;
    font-size: 1.6rem;
    margin-right: 1.6rem;
  }

  .payButtonSmall {
    @media (min-width: 760px) {
      display: none;
    }
  }
`;

export const InfoBarWrapper = styled.div`
  white-space: nowrap;
  width: 100%;

  .data {
    ${fonts.quickSandSemiBold}
    font-size: 1.7rem;
    line-height: 21px;
    letter-spacing: 1px;
  }

  .smallContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .payInfoBarItem {
    padding-right: 0;
    min-width: auto;
    width: fit-content;
    margin: 0 0 1.2rem 0;
    &:nth-child(2) {
      @media (min-width: 760px) {
        margin: 0 1.5rem 1.2rem 1.5rem;
      }
    }
  }
`;

export const CreditPayBody = styled.div`
  padding: 2rem;

  .footer {
    ${fonts.quickSandSemiBold}
    font-size: 1.4rem;
    max-width: 46rem;
    margin: auto;
    text-align: center;
  }
`;

export const StyledSpan = styled.a`
  font-weight: lighter;
  text-decoration: underline;
  cursor: pointer;
`;

export const ClientTitle = styled.h2`
  ${fonts.quickSandSemiBold}
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* color: ${getFontColor}; */
  /* ${getFontSize} */
`;
