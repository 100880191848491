import styled from 'styled-components';

import TagBase from '../../shared/components/Tag';

export const Tag = styled(TagBase)`
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.3rem;
  min-width: 10rem;

  span {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    padding: 0.4rem 0;
  }
`;

export const TagListContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  grid-row-gap: 1.2rem;
`;
