import { freelancerId } from '../constants';

const setEnterpriseBasicInfoHandler = ({
  setEnterpriseBasicInfo,
  setAttorneyInLow,
  uploadDocument
}) => async data => {
  const { error: enterpriseError } = await setEnterpriseBasicInfo(data);
  const { error: attorneyInLawError } = await setAttorneyInLow(data);

  if (data.proofOfPowers) {
    await uploadDocument(data.proofOfPowers, freelancerId);
  }

  if (enterpriseError || attorneyInLawError) {
    return { isSuccess: false, error: { enterpriseError, attorneyInLawError } };
  }

  return { isSuccess: true };
};

export { setEnterpriseBasicInfoHandler };
