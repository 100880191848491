/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// Component
import EntryDetails from '../../../../shared/components/EntryDetails/EntryDetails';

const TransactionVoucher = ({ details }) => {
  return (
    <>
      <EntryDetails
        title="Concepto"
        hasSeparator
        completeRow
        details={[{ value: details.description }]}
      />
      <EntryDetails
        title="Ordenante"
        hasSeparator
        details={[
          { name: 'Nombre ordenante', value: details.sender_name },
          {
            name: 'Banco ordenante',
            value: details.sender_banking_institution
          },
          { name: 'CLABE ordenante', value: details.sender_clabe_code }
        ]}
      />
      <EntryDetails
        title="Beneficiario"
        details={[
          { name: 'Nombre beneficiario', value: details.beneficiary_full_name },
          {
            name: 'Banco beneficiario',
            value: details.beneficiary_banking_institution
          },
          {
            name: 'CLABE beneficiario',
            value: details.beneficiary_clabe_code
          },
          {
            name: 'No. de tarjeta beneficiario',
            value: details.beneficiary_card_number
          },
          {
            name: 'Correo beneficiario',
            value: details.beneficiary_email
          }
        ]}
      />
      {!details.batch_process_name ? (
        <EntryDetails
          title="Detalles"
          details={[
            { name: 'Referencia', value: details.reference_number },
            {
              name: 'Clave de rastreo',
              value: details.tracking_key
            },
            {
              name: 'Estatus',
              value: {
                status: details.status,
                errorMessage: details.error_message
              }
            }
          ]}
        />
      ) : null}
    </>
  );
};

TransactionVoucher.propTypes = {
  details: PropTypes.object.isRequired
};

export default TransactionVoucher;
