import styled from 'styled-components';

import { colors } from '../../../../Dashboard4.0/assets/styles/styles';
import Button from '../../../shared/components/Button';

export const StyledButton = styled(Button)`
  background: none;
  color: ${colors.body};
  border-color: ${colors.body};
  margin: 4rem 0;
`;

export const Container = styled.div`
  font-size: 1.4rem;
  text-align: center;
`;
