import { customAxiosInstance } from './axiosConfig';

const transactionPath = `/api/dashboard/transaction`;
const transactionByTypePath = `/api/dashboard/transaction_by_type/`;

export const getTransactionsService = async page => {
  return customAxiosInstance().get(`${transactionPath}?page=${page}`);
};

export const getTransactionsByQuery = async ({ page, query }) => {
  return customAxiosInstance().get(
    `${transactionPath}/search?query=${query}&page=${page}`
  );
};

export const getTransactionsByType = async ({ page, type }) => {
  return customAxiosInstance().get(
    `${transactionByTypePath}${type}?page=${page}`
  );
};

export const getTransactionsFile = async () => {
  return customAxiosInstance().get(`${transactionPath}/csv`);
};
