import React from 'react';
import Input from '../Input/Input';
import Subtitle from '../Shared/Subtitle';

const CompanySignUpForm = ({ classes, formik, setData }) => {
  return (
    <div>
      <p className={classes.instructions}>
        Ingresa la información de la compañia y apoderado legal
      </p>
      <Subtitle text="De la compañía" />

      <Input
        label="Razón Social"
        placeholder="Happy Days Tech, S.A.P.I. de C.V"
        name="company_legal_name"
        id="company_legal_name"
        onBlur={formik.handleBlur}
        onChange={setData}
        error={
          formik.errors.company_legal_name && formik.touched.company_legal_name
        }
        helperText={formik.errors.company_legal_name}
      />

      <Input
        label="Nombre comercial"
        name="company_commercial_name"
        id="company_commercial_name"
        onBlur={formik.handleBlur}
        onChange={setData}
        error={
          formik.errors.company_commercial_name &&
          formik.touched.company_commercial_name
        }
        helperText={formik.errors.company_commercial_name}
      />

      <div style={{ marginTop: '4rem' }}>
        <Subtitle text="Del apoderado legal" />
      </div>
    </div>
  );
};

export default CompanySignUpForm;
