import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { restorePasswordPath } from './apiPaths';

import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const resetPasswordService = async ({ password, token }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      restorePasswordPath,
      {
        password,
        token,
      },
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'restore-password-service',
    });
  } catch (error) {
    log.error('restore-password-service');

    return { error: { message: 'Unable to send request' } };
  }
};
