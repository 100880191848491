import styled from 'styled-components';

export const CenterText = styled.div`
    font-family: Barlow;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
`

export const BulletPoint = styled.div`
    display: flex;
    align-items: center;
`

export const BulletPointInfo = styled.div`
    margin-left: 30px;
`