export const getOnboardingProgressHandler = ({
  getOnboardingProgress
}) => async () => {
  const { error, data } = await getOnboardingProgress();

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  return {
    isSuccess: true,
    data
  };
};
