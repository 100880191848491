/* eslint-disable no-useless-escape */

import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { tagOptionsSelector, tagsSelector } from '../redux/tag-selectors';

// Components
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Styled Components
import { AddTag, Container, TagOption, Tooltip } from './style';

// Services
import { service } from '../service';

const latinCharactersRegex = /^[\w() *\-!¡#$%=^&*,.¿?|°¬:;+/`"¨´~ñáéíóúäëïöü\'\][]*$/i;

const TagItem = ({ handleSaveTag }) => {
  const dispatch = useDispatch();
  const options = useSelector(tagOptionsSelector);
  const tagsSelected = useSelector(tagsSelector);
  const initialTagValue = { tagLabel: 'Agregar tag' };

  const [value, setValue] = useState(initialTagValue);
  const [inputValue, setInputValue] = useState('');

  const setStateWithValidCharacters = (string = '') => {
    const tag = string.trim();
    if (latinCharactersRegex.test(tag) && tag.length < 64) {
      setInputValue(tag);
    }
  };

  const defaultProps = {
    options: options.filter(option => {
      return !tagsSelected.find(tag => {
        return option.tagLabel === tag.tagLabel;
      });
    }),
    getOptionLabel: option => option.tagLabel
  };

  const handleFocus = () => {
    setValue({ tagLabel: '' });
  };

  const handleClose = () => {
    if (!inputValue) {
      setValue(initialTagValue);
      setInputValue('');
    }
  };

  const validateTagExists = (options = [], tagLabel) => {
    return Boolean(options.filter(tag => tag.tagLabel === tagLabel).length);
  };

  const handleEnterPress = e => {
    if (e?.nativeEvent?.key === 'Enter') {
      setInputValue(e.target.value);
      saveTag({ tagLabel: e.target.value });
    }
  };

  const saveTag = async tag => {
    if (!tag) return;
    const tagExists = validateTagExists(options, tag.tagLabel);
    if (!tagExists) {
      const { isSuccess, data } = await service({
        dispatch
      }).createTransactionTag({
        ...tag
      });
      if (isSuccess) handleSaveTag({ ...data });
    } else {
      handleSaveTag(tag);
    }
    setValue(initialTagValue);
    setInputValue('');
  };

  const getAutcompleteOptions = async () => {
    await service({ dispatch }).getTagOptions({ options });
  };

  const deleteTag = async ({ tag }) => {
    await service({ dispatch }).deleteTransactionTag({ tag });
    setInputValue('');
  };

  useEffect(() => {
    const abortCtrl = new AbortController();
    const options = { signal: abortCtrl.signal };
    getAutcompleteOptions({ options });
    return () => abortCtrl.abort();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <AddTag>
        <Autocomplete
          onFocus={handleFocus}
          onClose={handleClose}
          {...defaultProps}
          id="auto-complete"
          classes={{
            paper: 'paper',
            option: 'option',
            popperDisablePortal: 'popperDisablePortal'
          }}
          autoComplete
          includeInputInList
          renderOption={tag => (
            <Tooltip tooltipText={tag.tagLabel} className="helperTooltip">
              <TagOption
                key={tag.tagId}
                text={tag.tagLabel}
                isDeleteVisible
                color="blue"
                className="option"
                onDeleteClicked={e => {
                  e.stopPropagation();
                  deleteTag({ tag });
                }}
                onClick={() => {
                  setValue(tag);
                  saveTag(tag);
                }}
              />
            </Tooltip>
          )}
          renderInput={params => <TextField {...params} margin="normal" />}
          value={value}
          freeSolo
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setStateWithValidCharacters(newInputValue);
            handleEnterPress(event);
          }}
          data-testid="autocomplete"
        />
      </AddTag>
    </Container>
  );
};

export default TagItem;
