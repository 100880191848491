import React from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// styled
import {
  Container,
  ContentWrapper,
  FullScreenContentWrapper,
  Wrapper,
  FullScreenWrapper,
  MiddleComponent,
  TitleContainer,
  TitleButton
} from './Layout.styles';

// Components
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';
import Title from '../Title/Title';
import SideNav from '../SideNav/SideNav';
// import Button from '../Button/Button';
import { Button } from '@material-ui/core';

export const Component = ({
  title,
  titleButton,
  midleComponent,
  children,
  fullScreenMode,
  className
}) => {
  const CurrentContentWrapper = fullScreenMode
    ? FullScreenContentWrapper
    : ContentWrapper;
  const CurrentWrapper = fullScreenMode ? FullScreenWrapper : Wrapper;

  return (
    <Container>
      <SideNav isMobileMenu />
      <CurrentContentWrapper>
        <CurrentWrapper>
          <TitleContainer>
            {title ? (
              <Title size="large" text={title} className={className} />
            ) : null}
            {titleButton ? (
              <TitleButton
                onClick={titleButton.onClick}
                disabled={titleButton.disabled}
                startIcon={
                  titleButton?.startIcon ? titleButton.startIcon : null
                }
              >
                {titleButton.text}
              </TitleButton>
            ) : null}
          </TitleContainer>
          {midleComponent ? (
            <MiddleComponent>{midleComponent}</MiddleComponent>
          ) : null}
          {children}
        </CurrentWrapper>
      </CurrentContentWrapper>
    </Container>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  midleComponent: PropTypes.node,
  children: PropTypes.node.isRequired
};

Component.defaultProps = {
  title: null,
  midleComponent: null
};

const Layout = props => {
  return (
    <ErrorBoundaryHandler component={<Component {...props} />} name="Layout" />
  );
};

export default Layout;
