/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import EntryDetails from '../../../../shared/components/EntryDetails/EntryDetails';

const DepositVoucher = ({ details }) => {
  return (
    <>
      <EntryDetails
        title="Concepto"
        hasSeparator
        completeRow
        details={[{ value: details.description }]}
      />
      <EntryDetails
        title="Ordenante"
        hasSeparator
        details={[
          { name: 'Nombre ordenante', value: details.sender_name },
          {
            name: 'Banco ordenante',
            value: details.sender_banking_institution
          },
          { name: 'CLABE ordenante', value: details.sender_clabe_code }
        ]}
      />
      <EntryDetails
        title="Beneficiario"
        hasSeparator
        details={[
          { name: 'Nombre beneficiario', value: details.beneficiary_name },
          {
            name: 'Banco beneficiario',
            value: details.beneficiary_banking_institution
          }
        ]}
      />
      <EntryDetails
        title="Detalles"
        details={[
          { name: 'Referencia', value: details.reference_number },
          {
            name: 'Clave de rastreo',
            value: details.tracking_key
          },
          {
            name: 'Estatus',
            value: details.status
          }
        ]}
      />
    </>
  );
};

DepositVoucher.propTypes = {
  details: PropTypes.object.isRequired
};

export default DepositVoucher;
