import styled from 'styled-components';
import { colors, fonts } from '../../../../Dashboard4.0/assets/styles/styles';

export const RegistrationContainer = styled.div`
  width: 100%;
  max-width: 144rem;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8.1rem 0;
  margin: 0 auto;
  position: relative;

  .header {
    display: flex;
    position: absolute;
    top: 2rem;
    width: 100%;
    justify-content: center;
  }

  .deltaiLogo {
    width: auto;
    height: auto;
    max-width: 16.3rem;
  }

  .logoutBtn {
    position: absolute;
    right: 10.2rem;
    border: solid 1px #1c2633;
    color: #1c2633;
    background-color: white;
  }

  //modal styles
  .overlay-container {
    background: none;
    width: fit-content;
    height: fit-content;
    position: inherit;
  }

  .overlayArea {
    box-shadow: 0px 11px 22px -8px rgba(28, 38, 51, 0.3);
    border-radius: 1.2rem;
  }

  .modalSecondaryAction {
    ${fonts.quickSandSemiBold}
    font-size: 1.7rem;
    text-align: center;
    color: ${colors.carbon};
  }

  .headerTitle {
    color: ${colors.body};
  }

  @media (max-width: 500px) {
    .overlay-container {
      width: 100%;
    }

    .modalContainer {
      width: 100%;
      padding: 1.6rem 1.2rem;
    }

    .header {
      justify-content: flex-start;
    }

    .deltaiLogo {
      padding-left: 1rem;
    }
  }

  @media (max-width: 769px) {
    padding-bottom: 16rem;
    .overlay-container {
      width: 100%;
    }
    .overlayArea {
      box-shadow: none;
    }
    .logoutBtn {
      right: 2rem;
    }

    .deltaiLogo {
      width: 15rem;
    }
  }
`;

export const Footer = styled.div`
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  position: absolute;
  bottom: 4rem;

  @media (max-width: 600px) {
    padding: 0 1.2rem;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
`;

export const FooterItem = styled.span`
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.carbon};
  a {
    font-weight: 600;
    text-decoration: none;
    color: inherit;
  }
`;
