import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Pages
import InvoicesListPage from './pages/InvoicesListPage/InvoicesListPage';
import * as routes from './routes';

const InvoicesModule = () => {
  return (
    <Switch>
      <Route path={routes.invoicesList} component={InvoicesListPage} />
    </Switch>
  );
};

export default InvoicesModule;
