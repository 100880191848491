/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

import PropTypes from 'prop-types';

import { Svg } from './Icon.styles';
import { icons } from '../../../../Dashboard4.0/assets/images/icons';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';
import { ReactComponent as MultiTransferIcon } from '../../../../Dashboard4.0/assets/images/multitransfer.svg';

const Icon = ({ icon, className, width, height, color }) => {
  if (icon !== 'multitransfer') {
    return (
      <>
        {icon &&
          (icons[icon].isImage ? (
            <img data-testid="icon-image" src={icons[icon].src} alt="" />
          ) : (
            <Svg
              viewBox={icons[icon].viewBox}
              className={className}
              width={width || '1.4rem'}
              height={height || '1.4rem'}
              data-testid="icon-svg"
            >
              {
                <path
                  fill={
                    color && colors[color] ? colors[color] : icons[icon].fill
                  }
                  d={icons[icon].d}
                />
              }
            </Svg>
          ))}
      </>
    );
  }
  return <MultiTransferIcon />;
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default Icon;
