import React from 'react';

// Auxiliary libraries
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

// Components
import ClickableCard from '../ClickableCard/ClickableCard';
import InputCard from '../InputCard/InputCard';

// Services
import { getFileName } from '../../helpers/helpers';
import {
  manageModalDocs,
  updateDocuments
} from '../../actionCreators/softwallAction';

// Assets
import { documents } from './docsCatalog';

const useStyles = makeStyles({
  cards: {
    margin: '0 auto'
  }
});

const DocumentComponent = ({ array, documentsType, gridMdSpacing }) => {
  const classes = useStyles();

  //redux
  const dispatch = useDispatch();
  const missingDocsState = useSelector(
    state => state.softwallReducer.missingDocuments
  );
  const rejectedDocuments = useSelector(
    state => state.softwallReducer.rejectedDocuments
  );

  const uploadDocument = async (e, document) => {
    // if no files are selected, return
    if (e.target.files.length === 0) {
      return;
    }
    const documentID = e.target.name;
    const file = e.target.files[0];

    // Auxiliary method to extract name and file extension
    const fileName = getFileName(file.name, document.keyword);

    // Sets the structure for the file that's going to be uploaded
    let newDoc = {
      fileName: fileName,
      file: file,
      kyc: parseInt(documentID)
    };

    let state =
      documentsType === 'missing' ? missingDocsState : rejectedDocuments;

    updateDocuments(
      dispatch,
      {
        ...state,
        [documentID]: {
          ...array[documentID],
          data: newDoc,
          charged: true,
          fileName: file.name,
          person:
            documents[documentID].type === 'Apoderado' ||
            documents[documentID].type === 'Persona'
              ? true
              : false
        }
      },
      documentsType
    );
  };

  const openModal = document => {
    let body = {
      selfie: false,
      ineFront: false,
      ineBack: false,
      open: false
    };

    if (document === 14 || document === 13) {
      body = {
        selfie: true,
        ineFront: false,
        ineBack: false,
        open: true
      };
    }
    if (document === 1 || document === 9) {
      body = {
        selfie: false,
        ineFront: true,
        ineBack: false,
        open: true
      };
    }
    if (document === 11 || document === 12) {
      body = {
        selfie: false,
        ineFront: false,
        ineBack: true,
        open: true
      };
    }

    manageModalDocs(dispatch, body);
  };

  return (
    <Grid justify="center" container spacing={3} className={classes.cards}>
      {Object.keys(array).map((document, index) => {
        if (!array[document].success) {
          return (
            <Grid item xs={12} sm={7} md={gridMdSpacing} key={index}>
              {documents[document].takePhoto ? (
                <ClickableCard
                  tooltip={
                    documents[document] ? documents[document].tooltip : false
                  }
                  cardText={documents[document].name}
                  onClick={() => openModal(documents[document].id)}
                  isUploaded={array[document].charged}
                  file={array[document].fileName}
                />
              ) : (
                <InputCard
                  tooltip={
                    documents[document] ? documents[document].tooltip : false
                  }
                  onChange={e => uploadDocument(e, documents[document])}
                  name={documents[document].id}
                  cardText={documents[document].name}
                  takePhoto={documents[document].takePhoto}
                  onClick={() => openModal(documents[document].id)}
                  accept="image/*, .pdf"
                  isUploaded={array[document].charged}
                  file={array[document].fileName}
                />
              )}
            </Grid>
          );
        }

        return null;
      })}
    </Grid>
  );
};

export default DocumentComponent;
