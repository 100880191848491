import React, { useEffect, useState } from 'react';

// Auxiliar libraries
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Amount from '../../../shared/components/Amount/Amount';
import ErrorBoundaryHandler from '../../../../ErrorBoundary';
import InfoBar from '../../../shared/components/InfoBar/InfoBar';
import InfoBarItem from '../../../shared/components/InfoBar/InfoBarItem';
import Label from '../../../shared/components/Label';
import LabelName from '../LabelName';

// Modals
import CreditPayModal from '../../../credit/components/CreditPayModal';

// Actions
import { getCompanySum } from '../../../../actionCreators/companyActions';

// Assets
import { parseAmount, splitAmount } from '../../../../helpers';
import HasPermissions from '../../../../role-based-access/components/HasPermissions';
import { InfoBarWrapper, InfoBarItemContainer, InfoBarLink } from './styles';
import { PageContainer } from '../../../credit/pages/Credit.styles';

const initialState = {
  sign: '$',
  integer: '0',
  decimals: '00'
};

const Component = ({ setOpen }) => {
  // Hooks
  const [amountOwed_, setAmountOwed_] = useState(initialState);
  const [amountToPay_, setAmountToPay_] = useState(initialState);
  const [availableCredit_, setAvailableCredit_] = useState(initialState);
  const [creditLine_, setCreditLine_] = useState(initialState);
  const [isOpenPayModal, setOpenPayModal] = useState(false);

  // Redux
  const dispatch = useDispatch();

  const companySummary = useSelector(
    state => state.companyReducer.companySummary
  );

  const amountOwed = companySummary.amount_owed;
  const amountToPay = companySummary.amount_to_pay;
  const availableCredit = companySummary.available_credit;
  const creditLine = companySummary.credit_line;

  const updateState = (value, method) => {
    if (!value) return;
    method(splitAmount(parseAmount(value)));
  };

  const openIntercom = () => {
    window.Intercom(
      'showNewMessage',
      'Me gustaría solicitar un incremento en mi línea de crédito'
    );
  };

  useEffect(() => {
    getCompanySum(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (amountOwed) updateState(amountOwed, setAmountOwed_);
    if (amountToPay) updateState(amountToPay, setAmountToPay_);
    if (availableCredit) updateState(availableCredit, setAvailableCredit_);
    if (creditLine) updateState(creditLine, setCreditLine_);

    // eslint-disable-next-line
  }, [amountOwed, amountToPay, availableCredit, creditLine]);

  return (
    <>
      <InfoBarWrapper>
        <HasPermissions
          perform="accountStatement:view"
          accessAction={() => (
            <>
              <LabelName text="Crédito de tu cuenta" />
              <InfoBar
                columnGap={'true'}
                start={
                  <>
                    <InfoBarItem>
                      <Label text="LÍNEA DE CRÉDITO" />
                      <InfoBarItemContainer>
                        <Amount
                          integer={`${creditLine_.sign} ${creditLine_.integer}`}
                          decimals={creditLine_.decimals}
                        />
                        <InfoBarLink
                          onClick={() => {
                            openIntercom();
                          }}
                        >
                          Solicitar un aumento
                        </InfoBarLink>
                      </InfoBarItemContainer>
                    </InfoBarItem>
                  </>
                }
                middle={
                  <>
                    <InfoBarItem className="activityInfoBarItem">
                      <Label text="GASTASTE" />
                      <Amount
                        integer={`${amountOwed_.sign} ${amountOwed_.integer}`}
                        decimals={amountOwed_.decimals}
                      />
                    </InfoBarItem>
                    <InfoBarItem className="activityInfoBarItem">
                      <Label text="DISPONIBLE" />
                      <Amount
                        integer={`${availableCredit_.sign} ${availableCredit_.integer}`}
                        decimals={availableCredit_.decimals}
                      />
                    </InfoBarItem>
                    <InfoBarItem className="activityInfoBarItem">
                      <Label
                        text="POR PAGAR:"
                        textLabel={companySummary?.payment_deadline}
                      />
                      <InfoBarItemContainer>
                        <Amount
                          integer={`${amountToPay_.sign} ${amountToPay_.integer}`}
                          decimals={amountToPay_.decimals}
                        />
                        <InfoBarLink
                          onClick={() => {
                            setOpenPayModal(true);
                          }}
                        >
                          Pagar
                        </InfoBarLink>
                      </InfoBarItemContainer>
                    </InfoBarItem>
                  </>
                }
              />
            </>
          )}
          restrictedAction={() => <></>}
        />
      </InfoBarWrapper>
      <PageContainer>
        <CreditPayModal
          open={isOpenPayModal}
          onClose={() => setOpenPayModal(false)}
        />
      </PageContainer>
    </>
  );
};

Component.propTypes = {};

const UserDetails = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="UserDetails"
    />
  );
};

export default UserDetails;
