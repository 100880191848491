import {
  CREATE_USER,
  DELETE_USER,
  GET_USER_BY_ID,
  GET_USER_STATUS,
  GET_USERS,
  SET_USER,
  UPDATE_USER
} from './crud-types';

export const createUserAction = users => ({
  type: CREATE_USER,
  payload: users
});
export const deleteUserAction = user => ({ type: DELETE_USER, payload: user });
export const getUserByIdAction = user => ({
  type: GET_USER_BY_ID,
  payload: user
});
export const getUsersAction = users => ({ type: GET_USERS, payload: users });
export const getUserStatusAction = status => ({
  type: GET_USER_STATUS,
  payload: status
});
export const updateUserAction = user => ({ type: UPDATE_USER, payload: user });
export const setUserAction = user => ({ type: SET_USER, payload: user });
