import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  margin: -30px 0px;

  & div:not(:first-child) {
    margin-left: 50px;
  }
`;

export default Row;
