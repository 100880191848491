import React from 'react';

import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout';
import Registration from '../../components/Registration/Registration';

const RegistrationPage = () => {
  return (
    <RegistrationPageLayout>
      <Registration />
    </RegistrationPageLayout>
  );
};

export default RegistrationPage;
