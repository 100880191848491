import { getOnboardingStatusesService } from './infrastructure/getOnboardingStatuses';
import { updateOnboardingProgressService } from './infrastructure/updateOnboardingProgress';
import { getOnboardingProgressService } from './infrastructure/getOnboardingProgress';

import { getOnboardingStatusesHandler } from './eventHandlers/getOnboardingStatuses';
import { getOnboardingProgressHandler } from './eventHandlers/getOnboardingProgress';
import { updateOnboardingProgressHandler } from './eventHandlers/updateOnboardingProgress';

export const service = ({ dispatch } = {}) => ({
  getOnboardingStatuses: getOnboardingStatusesHandler({
    getOnboardingStatuses: getOnboardingStatusesService
  }),
  getOnboardingProgress: getOnboardingProgressHandler({
    getOnboardingProgress: getOnboardingProgressService
  }),
  updateOnboardingProgress: updateOnboardingProgressHandler({
    dispatch,
    updateOnboardingProgress: updateOnboardingProgressService
  })
});
