import { freelancerId } from '../../../micro-frontends/onboarding/constants';
import { companyOnboarding, freelancerOnboarding } from '../constants';

const getCompletedSteps = steps => {
  if (!steps) return [];

  const completedSteps = Object.values(steps).filter(el => {
    return el.isComplete;
  });
  return completedSteps;
};

const OnboardingSoftwallGuard = state => {
  const freelancerSteps = state.onboardingReducer.freelancerSteps;
  const enterpriseSteps = state.onboardingReducer.enterpriseSteps;
  const userType = state.userInfoReducer.user.company_type_id;
  const isFreelancer = userType === freelancerId;
  const onboardingPaths = isFreelancer ? freelancerSteps : enterpriseSteps;
  const onboardingSteps = isFreelancer
    ? freelancerOnboarding
    : companyOnboarding;

  return getCompletedSteps(onboardingPaths).length === onboardingSteps.length;
};

export default OnboardingSoftwallGuard;
