// Paths
import {
  enterpriseAddressPath,
  enterpriseBankPath,
  enterpriseDocuments,
  enterpriseIdentity,
  enterpriseInfoPath,
  enterpriseAttorneyPath
} from '../../constants';

// Actions
import {
  setAdressAction,
  setBankInformation,
  setEnterpriseInfoAction
} from '../../redux/onboardingActions';

// Selectors
import {
  selectAddressPage,
  selectBankInformationPage,
  selectEnterpriseInfo
} from '../../redux/onboardingSelectors';

// Components
import AddressPage from '../../pages/address/AddressPage';
import AttorneyInLaw from '../../pages/attorney-in-law/AttorneyInLaw';
import BankAccountPage from '../../pages/bank-account/BankAccountPage';
import DocumentsPage from '../../pages/documents/DocumentsPage';
import EnterpriseBasicInfo from '../../pages/enterprise-basic-info/EnterpriseBasicInfo';
import IdentityPage from '../../pages/identity/IdentityPage';

// Others
import { service } from '../../service';
import { states } from '../state-machine/state-machine-definition';
import log from 'loglevel';

const placeholderFn = data => log.info(data);

const enterpriseActionsManager = {
  [states.COMPANY_DATA_STEP]: {
    path: enterpriseInfoPath,
    selector: selectEnterpriseInfo,
    component: EnterpriseBasicInfo,
    isServiceRequired: false,
    service: data => service().setEnterpriseBasicInfo(data),
    action: data => setEnterpriseInfoAction(data),
    redirect: enterpriseAddressPath
  },
  [states.ADDRESS_STEP]: {
    path: enterpriseAddressPath,
    selector: selectAddressPage,
    component: AddressPage,
    service: data => service().setAddress(data),
    action: data => setAdressAction(data),
    redirect: enterpriseAttorneyPath
  },
  [states.REAL_OWNERSHIP]: {
    path: enterpriseAttorneyPath,
    selector: data => placeholderFn(data),
    component: AttorneyInLaw,
    service: data => service().createLegalPerson(data),
    redirect: enterpriseBankPath
  },
  [states.BANK_DATA_STEP]: {
    path: enterpriseBankPath,
    selector: selectBankInformationPage,
    component: BankAccountPage,
    service: ({ clabe, bank, accountStatement, companyTypeId }) =>
      service()
        .createBankInformation({ clabe, bank })
        .then(() => service().uploadDocument(accountStatement, companyTypeId)),
    action: data => setBankInformation(data),
    redirect: enterpriseIdentity
  },
  [states.IDENTITY_STEP]: {
    path: enterpriseIdentity,
    selector: data => placeholderFn(data),
    component: IdentityPage,
    isServiceRequired: false,
    service: null,
    action: data => placeholderFn(data),
    redirect: enterpriseDocuments
  },
  [states.DOCUMENTS_STEP]: {
    path: enterpriseDocuments,
    selector: data => placeholderFn(data),
    component: DocumentsPage,
    service: data => service().fileUploadManager(data),
    redirect: enterpriseDocuments
  }
};

export default enterpriseActionsManager;
