import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import CreatePassword from '../../components/CreatePassword/CreatePassword.jsx';
import Modal from '../../components/Modal/Modal.jsx';
import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout';
import { createPasswordService } from '../../services/sub-account-service.js';

// resetPasswordService prop added to allow reusabilty of this component in restore password flow
const CreatePasswordPage = ({ resetPasswordService }) => {
  const [token] = useState(useLocation().pathname.split('/')[2]);
  const history = useHistory();

  const createPassword = async body => {
    const service = resetPasswordService || createPasswordService;
    const { error } = await service(body);
    if (!error) history.push('/login');
  };

  useEffect(() => {
    if (!token) history.push('/');
    // eslint-disable-next-line
  }, [token]);

  return (
    <RegistrationPageLayout>
      <Modal>
        <CreatePassword createPassword={createPassword} token={token} />
      </Modal>
    </RegistrationPageLayout>
  );
};

export default CreatePasswordPage;
