import { signContractPath } from '../micro-frontends/sign-contract/constants';
import { ENGINEER_ROLE_ID } from '../micro-frontends/users-crud/constants';

import {
  customerInfoPath,
  tpaCreditHistoryPath,
  tpaSyntagePath,
  commonCustomerInfoPath
} from '../micro-frontends/kyc/utils/constants';

const activeStatuses = ['Active', 'User validated'];
const commonStatuses = {
  Active: '/activity',
  'User validated': '/activity',
  'Account not validated': '/register/new-confirm-account',
  'Pending approval': '/new-user-registration',
  'Pending profile completion': '/new-user-registration',
  'Pending password creation': '/new-user-invitation',
  'Pending profile completion approval': '/new-user-registration',
  'Waiting for review': '/awaiting-review',
  'Not qualified': '/not-qualified',
  'Documents not validated': '/new-onboarding',
  'Documents not uploaded': '/new-onboarding',
  'Incomplete Profile': '/new-onboarding',
  'Signature pending': signContractPath,
  // New status
  'waiting-for-root': commonCustomerInfoPath,
  created: customerInfoPath,
  'buro-submit': tpaCreditHistoryPath,
  'compliance-credentials': tpaSyntagePath,
  'compliance-pending': tpaSyntagePath,
  'compliance-failed': tpaSyntagePath,
  'completed': '/activity'
};

export const getPathBasedOnStatus = (status, userType, roleId) => {
  let targetPath = null;
  let savedPath = validateSavedPath();
  let isActiveEngineer =
    activeStatuses.includes(status) && roleId === ENGINEER_ROLE_ID;

  if (savedPath) {
    targetPath = savedPath;
  } else if (isActiveEngineer) {
    targetPath = '/apidocs';
  } else {
    targetPath = commonStatuses[status];
  }

  return targetPath;
};

const validateSavedPath = () => {
  const path = localStorage.getItem('path');
  return path;
};

export const redirectBasedOnStatus = (status, history, userType, roleId) => {
  const path = getPathBasedOnStatus(status, userType, roleId);
  history.push(path);
};
