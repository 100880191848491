import React from 'react';

import { captureEvent, Severity } from '@sentry/browser';
import { ErrorBoundary } from 'react-error-boundary';
import { Formik } from 'formik';
import { Form } from './FormContent';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { MainTitle } from '../../Shared';

import { useStyles } from './FreelancerStep1Styles';
import { validationSchema } from './validator';
import { currentOnboardingStepPersistedData } from '../../../selectors';

const initialValues = {
  gender: '',
  birthDate: moment().subtract(18, 'years').format('YYYY-MM-DD'),
  federalEntity: '',
  countryOfBirth: '',
  nationality: false,
  phoneNumber: '',
  curp: '',
  rfc: '',
  occupation: '',
  serialNumberFiel: ''
};

const Component = props => {
  const userInfoState = useSelector(state => state.userInfoReducer.user);
  const persistedFormData = useSelector(currentOnboardingStepPersistedData);

  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>
        {userInfoState && userInfoState.name ? (
          <MainTitle
            text={`Datos de ${userInfoState.name} ${userInfoState.surname_1}`}
          />
        ) : (
          <MainTitle text="Datos" />
        )}
        <p className={classes.description}>
          Completa la siguiente información para continuar:
        </p>
      </div>
      <div className={classes.formContainer}>
        <Formik
          className={classes.form}
          initialValues={persistedFormData || initialValues}
          validationSchema={validationSchema}
          isInitialValid={validationSchema.isValidSync(initialValues)}
        >
          {props => <Form {...props} />}
        </Formik>
      </div>
    </div>
  );
};

const FreelancerStep1 = props => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        captureEvent({
          level: Severity.Warning,
          message: `errorBoundary: ${error.message}`,
          extra: {
            component: 'FreelancerStep1',
            errorBoundary: true,
            errorName: error.name,
            props: props
          }
        });
        return <></>;
      }}
    >
      <Component {...props} />
    </ErrorBoundary>
  );
};

export default FreelancerStep1;
