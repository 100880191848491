// React
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';

//Auxiliary libraries
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations as ApmIntegrations } from '@sentry/apm';
import mixpanel from 'mixpanel-browser';
import { createBrowserHistory } from 'history';

//Own code
import './index.css';
import App from './App';
import { persistor, store } from './store';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

const validateEventMessage = message => {
  if (
    message.match(/status code 401/) ||
    message.match(/status code 403/) ||
    message.match(/status code 409/)
  ) {
    return true;
  }

  return false;
};

if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN,
    environment: process.env.REACT_APP_ENV,
    debug: process.env.REACT_APP_DEBUG_SENTRY,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    ignoreErrors: [
      'TypeError: h is not a function',
      'TypeError: onSubmit is not a function',
      'Error: Request failed with status code 401',
      'Error: Request failed with status code 402',
      'Error: Request failed with status code 403'
    ],
    integrations: [
      new ApmIntegrations.Tracing({
        tracingOrigins: [process.env.REACT_APP_API_BASE_PATH]
      }),
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          process.env.REACT_APP_API_BASE_PATH
        ],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: process.env.REACT_APP_TRACE_SAMPLE_RATE,
    replaysSessionSampleRate: process.env.REACT_APP_REPLAY_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: process.env.REACT_APP_REPLAY_ON_ERROR_SAMPLE_RATE,
    beforeSend(event) {
      if (event.message && validateEventMessage(event.message)) {
        return null;
      }
      return event;
    }
  });
}

if (process.env.REACT_APP_ENV === 'prod-dash4') {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
