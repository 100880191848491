// Paths
import {
  freelancerAddressPath,
  freelancerBankPath,
  freelancerDocuments,
  freelancerIdentity,
  freelancerInfoPath
} from '../../constants';

// Actions
import {
  setAdressAction,
  setFreelancerBasicInfoAction,
  setBankInformation
} from '../../redux/onboardingActions';

// Selectors
import {
  selectAddressPage,
  selectFreelancerBasicInfo,
  selectBankInformationPage
} from '../../redux/onboardingSelectors';

// Components
import AddressPage from '../../pages/address/AddressPage';
import BankAccountPage from '../../pages/bank-account/BankAccountPage';
import DocumentsPage from '../../pages/documents/DocumentsPage';
import FreelancerBasicInfo from '../../pages/FreelancerBasicInfo';
import IdentityPage from '../../pages/identity/IdentityPage';

// Others
import { states } from '../state-machine/state-machine-definition';
import { service } from '../../service';
import log from 'loglevel';

const placeholderFn = data => log.info(data);

const freelancerActionsManager = {
  [states.PERSONAL_DATA_STEP]: {
    path: freelancerInfoPath,
    selector: selectFreelancerBasicInfo,
    component: FreelancerBasicInfo,
    service: data => service().setFreelancerBasicInfo(data),
    action: data => setFreelancerBasicInfoAction(data),
    redirect: freelancerAddressPath
  },
  [states.ADDRESS_STEP]: {
    path: freelancerAddressPath,
    selector: selectAddressPage,
    component: AddressPage,
    service: data => service().setAddress(data),
    action: data => setAdressAction(data),
    redirect: freelancerBankPath
  },
  [states.BANK_DATA_STEP]: {
    path: freelancerBankPath,
    selector: selectBankInformationPage,
    component: BankAccountPage,
    service: ({ clabe, bank, accountStatement, companyTypeId }) =>
      service()
        .createBankInformation({ clabe, bank })
        .then(() => service().uploadDocument(accountStatement, companyTypeId)),
    action: data => setBankInformation(data),
    redirect: freelancerIdentity
  },
  [states.IDENTITY_STEP]: {
    path: freelancerIdentity,
    selector: data => placeholderFn(data),
    component: IdentityPage,
    isServiceRequired: false,
    service: null,
    action: data => placeholderFn(data),
    redirect: freelancerDocuments
  },
  [states.DOCUMENTS_STEP]: {
    path: freelancerDocuments,
    selector: data => placeholderFn(data),
    component: DocumentsPage,
    service: data => service().fileUploadManager(data),
    redirect: freelancerDocuments
  }
};

export default freelancerActionsManager;
