/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import EntryDetails from '../../../shared/components/EntryDetails/EntryDetails';

// Helpers
import { getStatus } from '../../helpers';

const PaymentVoucher = ({ details }) => {
  const {
    title,
    issuer_name,
    issuer_rfc,
    receiver_name,
    receiver_rfc,
    uuid,
    invoice_status,
    payment_date
  } = details;
  return (
    <>
      <EntryDetails
        title="Emisor"
        hasSeparator
        details={[
          { name: 'Nombre', value: issuer_name },
          {
            name: 'RFC',
            value: issuer_rfc
          }
        ]}
      />
      <EntryDetails
        title="Receptor"
        hasSeparator
        details={[
          {
            name: 'Nombre Completo',
            value: receiver_name
          },
          {
            name: 'RFC',
            value: receiver_rfc
          }
        ]}
      />

      <EntryDetails
        title="Detalles"
        details={[
          {
            name: 'UID',
            value: uuid
          },
          {
            name: 'Estatus',
            value: {
              status: getStatus(invoice_status, payment_date)
            }
          }
        ]}
      />
    </>
  );
};

PaymentVoucher.propTypes = {
  details: PropTypes.object.isRequired
};

export default PaymentVoucher;
