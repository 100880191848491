import styled from 'styled-components';
import { GridContainer } from '../shared/components/Layout/Layout.styles';
import { fonts, colors } from '../../Dashboard4.0/assets/styles/styles';

export const AccountPageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .infoBarContentStart {
    min-width: auto;
    max-width: 100%;
    width: 100%;
    justify-content: space-evenly;
  }

  .accountStatementsHeader {
    justify-items: left;
  }

  .accountDetailsBtnContainer {
    display: none;
  }

  .accountStatementsContainer {
    margin: 3.2rem 0;
  }

  .accountPageHeader {
    grid-template-columns: 3fr 7fr;
    margin: 10px 0;

    .accountsStatementDivider {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 1165px) {
    .infoBarContentStart {
      padding: 0 1.6rem;
    }
  }

  @media (max-width: 900px) {
    .infoBarContentStart {
      & > div:first-child {
        display: none;
      }

      & > div {
        margin: 0 1.2rem;
      }
    }
  }

  @media (max-width: 768px) {
    .infoBarContainer {
      margin: 0 3rem;
      width: auto;
    }

    .download {
      visibility: visible !important;
    }

    .infoBarBtnContainer {
      display: none;
    }

    .accountStatementsComponent {
      padding: 0 3rem;
    }

    .accountDetailsBtnContainer {
      display: block;
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 2rem 0;
    }

    .accountPageHeader {
      grid-template-columns: 1fr;

      & > h2 {
        display: none;
      }
    }

    .infoBarContentStart {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      height: 266px;
    }
  }
`;

export const Grid = styled(GridContainer)`
  grid-template-columns: 3fr 7fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    & > div:first-child {
      display: none;
    }
  }
`;
export const AccountPageMenuContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  align-items: ${props => (props.isMobile ? 'center' : '')};
`;

export const AccountPageSideMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  background-color: 'red';
`;

export const AccountPageMenu = styled.div`
  width: ${props => (props.isMobile ? '100%' : '75%')};
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const AccountSecurityPage = styled.div`
  .bold {
    font-weight: 600;
  }

  .info-text {
    margin-top: 30px;
    font-size: 16px;
  }

  label {
    font-size: 18px;
    margin-left: 2px;
  }

  .toggle-options-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .toggle-button-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const RadioButton = styled.p`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1.5rem;
  border-radius: 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid black;
  outline: none;
  cursor: pointer;
  background: ${props => (props.checked ? colors.deltai : 'transparent')};
  border-color: ${props => (props.checked ? colors.deltai : 'black')};
`;
