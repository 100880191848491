import * as yup from 'yup';

const requiredFieldMessage = 'Campo requerido';

export const validationSchema = yup.object({
  email: yup
    .string()
    .email('Ingresa un email válido')
    .required(requiredFieldMessage),
});
