export const unblockCardHandler = ({ changeCardStatus, authUser }) => async ({
  email,
  password,
  cardId,
  userId
}) => {
  const { error: authError } = await authUser({
    email,
    password
  });
  if (authError) {
    return { isSuccess: false, error: authError };
  }

  const { error } = await changeCardStatus({
    cardId,
    cardStatusId: 1,
    userId
  });

  if (error) {
    return { isSuccess: false, error };
  }
  // const card = CardCreator({ data: cardData });

  // dispatch(doCardNaiveUpdate({ card }));
  return { isSuccess: true };
};
