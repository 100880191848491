import styled from 'styled-components';
import { colors, fonts } from '../../../../../Dashboard4.0/assets/styles/styles';

const blueBg = 'linear-gradient(86.66deg, #1C2633 1.95%, #3C4D62 100%)';
const greenBg = 'linear-gradient(86.66deg, #45BD62 1.95%, #4ADA6C 100%)';

const getBackground = ({ type }) => {
  const types = {
    deposit: greenBg,
    credit: blueBg,
    reversal: greenBg,
    default: blueBg
  };
  return types[type] || types.default;
};

export const HeaderContainer = styled.div`
  background-image: ${getBackground};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(14px);
  height: 10.6rem;
  justify-content: center;

  .amount {
    font-family: ${fonts.quickSandMedium};
    font-size: 4rem;
    letter-spacing: 1.71px;
    color: ${colors.white};
    span {
      font-size: 2.6rem;
    }
  }

  @media (max-width: 768px) {
    padding: 2.3rem 0 2.3rem;
    width: 100%;
  }
`;

export const Label = styled.span`
  ${fonts.barlowRegular};
  font-size: 16px;
  color: ${colors.white};
  line-height: 19px;
  letter-spacing: 0.175px;
`;
