const setAddressHandler = ({ setAddress }) => async (addressObject, userId) => {
  const { error } = await setAddress(addressObject, userId);

  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true };
};

export { setAddressHandler };
