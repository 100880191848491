/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import afirme from '../../../../Dashboard4.0/assets/images/bank-afirme.svg';
import azteca from '../../../../Dashboard4.0/assets/images/bank-bacoazteca.svg';
import banbajio from '../../../../Dashboard4.0/assets/images/bank-banbajio.svg';
import bancoppel from '../../../../Dashboard4.0/assets/images/bank-bancoppel.svg';
import banorte from '../../../../Dashboard4.0/assets/images/bank-banorte.svg';
import banregio from '../../../../Dashboard4.0/assets/images/bank-banregio.svg';
import bbva from '../../../../Dashboard4.0/assets/images/bank-bbva.svg';
import cibanco from '../../../../Dashboard4.0/assets/images/bank-cibanco.svg';
import city from '../../../../Dashboard4.0/assets/images/bank-citybanamex.svg';
import compartamos from '../../../../Dashboard4.0/assets/images/bank-compartamosbanco.svg';
import famsa from '../../../../Dashboard4.0/assets/images/bank-famsa.svg';
import hsbc from '../../../../Dashboard4.0/assets/images/bank-hsbc.svg';
import inbursa from '../../../../Dashboard4.0/assets/images/bank-inbursa.svg';
import invex from '../../../../Dashboard4.0/assets/images/bank-invex.svg';
import multiva from '../../../../Dashboard4.0/assets/images/bank-multiiva.svg';
import sabadell from '../../../../Dashboard4.0/assets/images/bank-sabadell.svg';
import santander from '../../../../Dashboard4.0/assets/images/bank-santander.svg';
import scotia from '../../../../Dashboard4.0/assets/images/bank-scotiabank.svg';
import stp from '../../../../Dashboard4.0/assets/images/bank-stp.svg';

import { BankItemContainer } from './BankMenu.styles';

const BankItem = ({ bank, onClick }) => {
  const logoSelector = {
    7: city,
    8: bbva,
    9: santander,
    10: hsbc,
    11: banbajio,
    12: inbursa,
    15: scotia,
    16: banregio,
    17: invex,
    19: afirme,
    20: banorte,
    30: azteca,
    33: compartamos,
    34: famsa,
    35: multiva,
    38: bancoppel,
    42: cibanco,
    51: sabadell,
    77: stp
  };

  return (
    <BankItemContainer onClick={onClick}>
      {logoSelector[bank.id] ? (
        <img src={logoSelector[bank.id]} alt={`logo ${bank.name}`} />
      ) : (
        bank.name || ''
      )}
    </BankItemContainer>
  );
};

BankItem.propTypes = {
  bank: PropTypes.object,
  onClick: PropTypes.func
};

export default BankItem;
