/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import BatchContent from './BatchContent';
import HandleButtons from './HandleButtons';

import { ReactComponent as MultiTransferIcon } from '../../../../Dashboard4.0/assets/images/multitransfer.svg';
import { ReactComponent as TransferArrow } from '../../../../Dashboard4.0/assets/images/transfer-arrow.svg';
import {
  DetailsContainer,
  TransfersConfirmContainer,
  TransferName,
  TransferAmount,
  TransferBeneficiaries
} from './BatchTransfer.styles';

const TransfersConfirm = ({
  dataFile,
  done,
  onClose,
  parsedFile,
  name,
  submit,
  resetFile
}) => {
  return (
    <TransfersConfirmContainer>
      <BatchContent
        data-test="TransfersConfirm"
        title="Confirma la orden"
        className="titleBlack"
        subtitle={
          <>
            {dataFile ? (
              <>
                <TransferName>
                  <MultiTransferIcon />
                  {dataFile.multiTransferName}
                </TransferName>
                <TransferAmount>
                  <TransferArrow />
                  <TransferBeneficiaries>
                    {dataFile.amount}
                    <span>
                      {dataFile.beneficiaries}{' '}
                      {dataFile.beneficiaries > 1
                        ? 'beneficiarios'
                        : 'beneficiario'}
                    </span>
                  </TransferBeneficiaries>
                </TransferAmount>
              </>
            ) : null}
          </>
        }
        content={(
          <>
            <DetailsContainer>
              <HandleButtons
                longHeader
                file={{ transferName: name, parsedFile }}
                onClose={onClose}
                resetFile={resetFile}
                done={done}
              />
            </DetailsContainer>
          </>
        )}
      />
    </TransfersConfirmContainer>
  );
};

TransfersConfirm.propTypes = {
  dataFile: PropTypes.object,
  submit: PropTypes.func,
  onChange: PropTypes.func
};

export default TransfersConfirm;
