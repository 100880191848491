import * as yup from 'yup';
import { patterns_metadata } from '../../../../catalogs/patterns';
import {
  INVALID_CONSTITUTION_MESSAGE,
  LENGTH_MESSAGE,
  REQUIRED_MESSAGE
} from '../../constants';

export const validationSchema = yup
  .object({
    mercantilBusiness: yup.string().required(REQUIRED_MESSAGE),
    incorporationDate: yup
      .string()
      .test('date validator', 'Fecha inválida', function (date) {
        if (!date)
          return this.createError({
            message: REQUIRED_MESSAGE
          });

        const arrDate = date.split('/');
        const formattingDate = new Date(
          +arrDate[2],
          arrDate[1] - 1,
          +arrDate[0]
        );

        const parsedDate = Date.parse(formattingDate);
        const incorporationDateYear = formattingDate.getFullYear();
        const minDate = new Date();

        if (Number.isNaN(parsedDate)) {
          return this.createError({
            message: INVALID_CONSTITUTION_MESSAGE
          });
        }

        if (incorporationDateYear > minDate || incorporationDateYear < 1900) {
          return this.createError({
            message: INVALID_CONSTITUTION_MESSAGE
          });
        }

        return true;
      }),
    companyNationality: yup.string().required(REQUIRED_MESSAGE),
    personalCurp: yup
      .string()
      .length(18, 'Debes ingresar 18 caracteres')
      .matches(patterns_metadata.patternCurp, 'Ingresa un CURP válido')
      .required(REQUIRED_MESSAGE),
    personalRfc: yup
      .string()
      .min(13, LENGTH_MESSAGE)
      .max(13, LENGTH_MESSAGE)
      .matches(patterns_metadata.patternRFC, 'Ingresa un RFC válido')
      .required(REQUIRED_MESSAGE),
    companySerialNumberFiel: yup.string().required(REQUIRED_MESSAGE)
  })
  .shape();
