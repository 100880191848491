import React from 'react';

import DocumentsManager from '../../components/DocumentsManager';
import OnboardingErrorBoundary from '../OnboardingErrorBoundary/OnboardingErrorBoundary';
import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

const Component = ({
  documents,
  isPrimaryButtonDisabled,
  handleFileChange,
  uploadFiles,
  uploadedDocuments
}) => {
  return (
    <RegistrationPageLayout
      title="Desafortunadamente estos documentos han tenido un problema"
      description={`Te recomendamos verifiques que los documentos pertenezcan a la
      sección correspondiente y los datos del documento sean claros,
      si subes el mismo documento es posible que tenga el mismo
      problema.`}
      primaryButtonText="Continuar"
      onPrimaryButtonClick={uploadFiles}
      isModalVisible={true}
      showLogout={true}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
    >
      {documents?.length > 0 ? (
        <DocumentsManager
          documents={documents}
          onChangeFile={handleFileChange}
          isSoftwall={true}
          uploadedDocuments={uploadedDocuments}
        />
      ) : null}
    </RegistrationPageLayout>
  );
};

const RejectedDocuments = props => {
  return (
    <OnboardingErrorBoundary
      component={<Component {...props} />}
      name="RejectedDocuments"
      props={props}
    />
  );
};

export default RejectedDocuments;
