import styled from 'styled-components';

import { fonts, colors } from '../../../../Dashboard4.0/assets/styles/styles';

const setSpacing = ({ icon }) => {
  if (icon)
    return `
    svg {
      margin-right: 1rem;
    }  
  `;
};

export const LabelContainer = styled.span`
  align-items: center;
  display: flex;
  ${fonts.barlowSemiBold}
  font-size: 1.2rem;
  line-height: 1;
  letter-spacing: 0.35px;
  color: ${colors.pallet0Text};
  ${setSpacing}
`;

export const LabelText = styled.span`
  color: black;
  margin-left: 5px;
  ${fonts.quickSandSemiBold}

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
