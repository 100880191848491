import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';
import { twoFactorAuthPath } from './apiPaths';

export const confirmTwoFactorDeviceService = async ({ code }) => {
  try {
    const {
      data,
      status,
      error
    } = await customAxiosInstance().post(twoFactorAuthPath, { code: code });
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'Two Factor Authentication-service'
    });
  } catch (error) {
    log.error('Two Factor Authentication-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
