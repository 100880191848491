import React, { useState, useEffect } from 'react';

// Components
import Form from './Form';
import ModalAuth from '../../components/modal-auth';
import Modal from '../../../shared/components/Modal';
import InformationModal from '../../../shared/components/ModalInformation';
import ModalDeliveryAddress from '../new-card-modal/modal-delivery-address';
import CardSuccessModal from '../../components/modal-card-creation-success';

// Service
import { service } from '../../service';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { selectEmail } from '../../redux/cards-selectors';

// Others
import { machine, states, events } from './state.machine.definition';
import {
  getBodyOfErrorMessage,
  getTitleOfErrorMessage,
  PhysicalCardSuccessMessage
} from '../new-card-modal/response-handler';

const CardPinModal = ({ openModal, setCreateCardPinModal, cardId }) => {
  const [state, setState] = useState(machine.value);
  const [deliveryInfo, setDeliveryInfo] = useState();
  const [addressStates, setAddressStates] = useState([]);
  const [passwordLocal, setPasswordLocal] = useState(null);
  const [cardDetails, setCardDetails] = useState({});
  const [cardPinInfo, setCardPinInfo] = useState();
  const [authError, setAuthError] = useState(null);
  const [issueError, setIssueError] = useState();
  const email = useSelector(selectEmail);
  const dispatch = useDispatch();

  const handleAuth = async ({ password }) => {
    const { isSuccess, card, error } = await service().getCardDetails({
      email,
      password,
      cardId
    });

    if (isSuccess) {
      setCardDetails(card);
      setPasswordLocal(password);
      setState(machine.transition(state, events.AUTHENTICATED));

      await service({ dispatch }).getUsers();
    } else {
      setAuthError(error);
    }
  };

  const handleModalClose = () => {
    setCreateCardPinModal(false);
    setDeliveryInfo();
    setCardPinInfo();
  };

  const handleStateMachineReset = () => {
    setCardDetails({});
    setState(machine.transition(state, events.RESET));
  };

  const handleSumbitCardPinInfo = details => {
    setCardPinInfo(details);
    setState(machine.transition(state, events.SUCEED_WITH_CARD_PIN_INFO));
  };

  const handleSubmitDeliveryAddress = async deliveryAddress => {
    const { isSuccess, error, data } = await service({
      dispatch
    }).issuePhysicalCard({
      ...cardPinInfo,
      creditCardId: cardDetails.creditCardId,
      cardLimit: cardDetails.spendLimit,
      creditCardName: cardDetails.creditCardName,
      cardOwnerId: cardDetails.userId,
      password: passwordLocal,
      requestingCardForSelf: true,
      deliveryAddress,
      email
    });

    if (isSuccess) {
      setCardDetails(data);
      setState(machine.transition(state, events.SUCEED_WITH_PHYSCIAL_CARD));
    } else {
      setDeliveryInfo(deliveryAddress);
      setIssueError(error);
      setState(machine.transition(state, events.FAILED_WITH_PHYSCIAL_CARD));
    }

    await service({ dispatch }).getCards();
  };

  const handleComeBackStep = () =>
    setState(machine.transition(state, events.COME_BACK_STEP));

  const getStateAddresses = async () => {
    const {
      isSuccess: isStatesSuccess,
      addressStates
    } = await service().getAddressStates();
    if (isStatesSuccess) {
      setAddressStates(addressStates);
    }
  };

  useEffect(() => {
    getStateAddresses();
  }, []);

  const getModalBasedOnState = () => {
    switch (state) {
      case states.AUTHENTICATION:
        return (
          <ModalAuth
            handleSubmit={handleAuth}
            handleModalClose={handleModalClose}
            authError={authError}
          />
        );
      case states.PROVIDE_CARD_PIN_INFO:
        return <Form handleSubmit={handleSumbitCardPinInfo} />;
      case states.PHYSCIAL_CARD_ADDRESS:
        return (
          <ModalDeliveryAddress
            handleSubmit={handleSubmitDeliveryAddress}
            handleSecondaryAction={deliveryAddress => {
              setDeliveryInfo(deliveryAddress);
              handleComeBackStep();
            }}
            initialValues={deliveryInfo}
            addressStates={addressStates}
          />
        );
      case states.PHYSCIAL_CARD_SUCCESS:
        return (
          <CardSuccessModal
            details={cardDetails}
            handleModalClose={handleModalClose}
            handleCreateNewCard={handleStateMachineReset}
            title={PhysicalCardSuccessMessage}
            isEditingEnable={false}
          />
        );
      case states.PHYSCIAL_CARD_FAILURE:
        return (
          <InformationModal
            primaryButtonText="Entendido"
            onPrimaryButtonClick={handleModalClose}
            secondaryButtonText="Regresar"
            onSecondaryButtonClick={handleComeBackStep}
            title={getTitleOfErrorMessage(issueError)}
            description={getBodyOfErrorMessage(issueError)}
          />
        );

      default:
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      showCrossSign={false}
      disableBackDropClick={false}
      showCloseOnModal
    >
      {getModalBasedOnState()}
    </Modal>
  );
};

export default CardPinModal;
