import React from 'react';

const PrevSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="8"
      viewBox="0 0 5 8"
    >
      <defs>
        <filter id="9szka7atea">
          <feColorMatrix in="SourceGraphic" />
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#9szka7atea)"
        transform="translate(-33 -8)"
      >
        <path
          fill="#1C2633"
          d="M37.25 16c.202 0 .402-.08.55-.24.282-.303.264-.778-.04-1.06L34.853 12l2.909-2.7c.303-.282.32-.757.039-1.06-.281-.304-.756-.322-1.06-.04l-3.5 3.25c-.153.142-.24.342-.24.55 0 .208.087.408.24.55l3.5 3.25c.145.134.328.2.51.2"
        />
      </g>
    </svg>
  );
};

export default PrevSvg;
