import styled from 'styled-components';

export const BeneficiaryListContainer = styled.div`
  height: 100vh;
  padding: 1.2rem 0;

  .addNewEntry {
    margin: 1.2rem 2rem;
  }

  @media (min-width: 769px) {
    width: 100%;
    padding: 1.5rem 3.2rem 0 3.2rem;
    height: 49.3rem;

    .addNewEntry {
      margin: 0;
    }
  }
`;

export const ScrollableListContainer = styled.div`
  overflow: scroll;
  height: calc(100% - 29rem);
  scrollbar-width: none;
  padding-bottom: 12rem;

  @media (min-width: 769px) {
    margin-top: 0.2rem;
    height: calc(100% - 22rem);
    ${({ sm }) => sm && `  height: calc(100% - 29rem);`};
    padding-bottom: 2rem;
  }
`;
