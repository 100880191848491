import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100;
    margin-bottom: 4.6rem;
  }
`;

export const Text = styled.p`
  font-size: 1.8rem;
  text-align: center;
  max-width: 38.3rem;
  margin: 9rem 0 15rem;
  width: 100%;
`;
