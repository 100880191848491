export const getOnboardingStatusesHandler = ({
  getOnboardingStatuses
}) => async () => {
  const { error, data } = await getOnboardingStatuses();

  if (error) {
    return { isSuccess: false, error };
  }

  return {
    isSuccess: true,
    data
  };
};
