import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Pages
import ForgotPasswordPage from './pages/ForgotPasswordPage/';
import LoginPage from './pages/loginPage/loginPage';
import ResetPasswordPage from './pages/ResetPasswordPage/';

// Others
import {
  loginLink,
  forgotPasswordPath,
  restorePasswordPath,
  emailConfirmationPath
} from './constants';

const LoginModule = () => (
  <Switch>
    <Route
      exact
      path={[loginLink, `${emailConfirmationPath}/:token`]}
      component={LoginPage}
    />
    <Route exact path={forgotPasswordPath} component={ForgotPasswordPage} />
    <Route
      exact
      path={`${restorePasswordPath}/:token`}
      component={ResetPasswordPage}
    />
  </Switch>
);

export default LoginModule;
