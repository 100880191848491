const buroGenerateHandler = ({ buroGenerate }) => async ({ customer, account }) => {

  const { error, data } = await buroGenerate({
    customer,
    account
  });

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }
  let isSuccess = true;
  if(data?.response === "BAD"){
    isSuccess = false;
  }

  return { isSuccess, data };
};

export { buroGenerateHandler };
