import {
  API_DOCS_ENABLE,
  CLEAN_STATE,
  SET_ALL_PERMISSIONS,
  SET_ERROR,
  SET_USER_ROLE,
  UPDATE_ROLE_PERMISSIONS
} from './role-permissions-types';

export const cleanStateAction = () => ({ type: CLEAN_STATE });

export const setErrorAction = error => ({ type: SET_ERROR, payload: error });

export const setAllPermissionsAction = hasAllPermissions => ({
  type: SET_ALL_PERMISSIONS,
  payload: hasAllPermissions
});

export const setUserTypeAction = userType => ({
  type: SET_USER_ROLE,
  payload: userType
});

export const setRulesAction = permissions => ({
  type: UPDATE_ROLE_PERMISSIONS,
  payload: permissions
});

export const setApiDocsEnableAction = apiDocsEnabled => ({
  type: API_DOCS_ENABLE,
  payload: apiDocsEnabled
});
