import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../shared/components/Button/Button';
import Calendly from '../../../shared/components/Calendly/Calendly';
import Layout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

import { signContractPath } from '../../constants';
import { ButtonContainer, Wrapper } from './styles';

const SignatureSchedule = () => {
  const [showButton, setShowButton] = useState(false);
  const history = useHistory();
  const calendlyURL = `${process.env.REACT_APP_CALENDLY_URL}/firmar-contrato`;
  const goToScheduleSign = () => history.push(signContractPath);
  const goToDashboard = () => history.push('/activity');

  return (
    <Wrapper>
      <Layout
        showLogout
        title="Escoge cuando quieres visitarnos para firmar:"
        isPrimaryButtonHidden
        isModalVisible
        secondaryButtonText="Regresar"
        onSecondaryButtonClick={goToScheduleSign}
      >
        <Calendly
          calendlyUrl={calendlyURL}
          className="calendly"
          onScheduled={() => setShowButton(true)}
        />
        {showButton ? (
          <ButtonContainer>
            <Button size="lg" text="continuar" onClick={goToDashboard} />
          </ButtonContainer>
        ) : null}
      </Layout>
    </Wrapper>
  );
};

export default SignatureSchedule;
