import React from 'react';
import { Footer, FooterItem } from './styles';

const FooterLayout = () => {
  return (
    <Footer>
      <FooterItem>© 2020 Happy Days Tech</FooterItem>
      <FooterItem>
        Contáctanos con un correo a{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="mailto:ayuda@delt.ai"
        >
          ayuda@delt.ai
        </a>
      </FooterItem>
      <FooterItem>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="http://ayuda.delt.ai/es/"
        >
          Centro de Ayuda
        </a>
      </FooterItem>
    </Footer>
  );
};

export default FooterLayout;
