import React, { useEffect, useState } from 'react';

// Auxiliary libraries
import { useFormik } from 'formik';

// Components
import TextField from '../../../shared/components/TextField/TextField';

// Styled components
import { FormContainer } from '../CreatePassword/CreatePassword.styles';
import {
  SubContainer,
  StyledButton as Button,
  StyledTitle as Title
} from './styles';

// Validations
import { firstStepValidations } from './validations';
import Select from '../Select/Select';
import Datepicker from '../Datepicker/Datepicker';

const FirstStep = ({ changeStep, form, setForm }) => {
  const initialValues = {
    phoneNumber: '',
    birthdate: '',
    gender: '',
    curp: '',
    rfc: '',
    selfie: ''
  };
  const [formData, setFormData] = useState(initialValues);

  const formik = useFormik({
    initialValues,
    validationSchema: firstStepValidations
  });

  useEffect(() => {
    formik.setFieldTouched();
    // eslint-disable-next-line
  }, []);

  const handleChange = e => {
    if (!e.target.name) return;
    handleRfcAndCurpChange(e);
    formik.handleChange(e);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRfcAndCurpChange = e => {
    const name = e.target.name;
    if (name === 'curp' || name === 'rfc')
      e.target.value = e.target.value.toUpperCase();
  };

  const handleSelect = gender => {
    formik.setFieldValue('gender', gender.value);
    setFormData({ ...formData, gender: gender.value });
  };

  const handleSubmit = () => {
    setForm({ ...form, ...formData });
    changeStep(2);
  };

  return (
    <FormContainer data-test="FirstStep">
      <Title size="large" text="Registro" />
      <TextField
        name="phoneNumber"
        label="Número de teléfono"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formData.phoneNumber}
        error={formik.errors.phoneNumber && formik.touched.phoneNumber}
        helperText={formik.errors.phoneNumber}
      />
      <Datepicker
        name="birthdate"
        label="Fecha de nacimiento (DD/MM/YYYY)"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        hasValue={formData.birthdate}
        error={formik.errors.birthdate && formik.touched.birthdate}
        helperText={formik.errors.birthdate}
      />
      <Select
        label="Género"
        options={[
          { name: 'Hombre', id: '1' },
          { name: 'Mujer', id: '2' }
        ]}
        keys={{ value: 'id', label: 'name' }}
        onSelect={handleSelect}
        helperText={formik.errors.gender}
        error={formik.errors.gender && formik.touched.gender}
      />
      <SubContainer>
        <TextField
          name="curp"
          label="CURP"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formData.curp}
          error={formik.errors.curp && formik.touched.curp}
          helperText={formik.errors.curp}
        />
        <TextField
          name="rfc"
          label="RFC"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formData.rfc}
          error={formik.errors.rfc && formik.touched.rfc}
          helperText={formik.errors.rfc}
        />
      </SubContainer>
      <Button
        text="Continuar"
        size="lg"
        onClick={handleSubmit}
        disabled={!formik.isValid}
      />
    </FormContainer>
  );
};

export default FirstStep;
