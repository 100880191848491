import React from 'react';
import RegistrationPageLayout from '../../../shared/layouts/RegistrationPageLayout/RegistrationPageLayout';

import Image_Confirm from '../../../../assets/message_sent .svg';
import { Image } from './styles';

const SuccessPage = () => {
  return (
    <RegistrationPageLayout
      isPrimaryButtonHidden={true}
      title={
        <>
          Has completado tu registro.<br></br>
          Estamos validando tus <br></br>
          documentos. En cuanto <br></br>
          lo revisemos tendrás una respuesta.
        </>
      }
      description={<Image src={Image_Confirm} alt="email" width="360px" />}
      showLogout={true}
      isPrimaryButtonDisabled={true}
      isModalVisible={true}
    />
  );
};

export default SuccessPage;
