import styled from 'styled-components';

export const TooltipContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  padding-right: 0.5rem;

  width: fit-content;
`;

export const Helper = styled.span`
  display: none;
  position: absolute;
  min-width: max-content;
  top: 100%;
  left: -150%;
  font-size: 1.1rem;
  color: #b4bac3;
  background-color: rgba(38, 51, 67, 0.95);
  padding: 0.9rem 1.2rem;
  border-radius: 5px;

  svg {
    margin-right: 0.8rem;
    height: 1rem;
    width: 1rem;
  }

  svg path {
    fill: #b4bac3;
  }
`;

export const ChildHover = styled.div`
  margin: 0.9rem 1.6rem;
  &:hover {
    & + .helperText {
      display: flex;
      align-items: center;
    }
  }
`;
