/* eslint-disable react/forbid-prop-types */
import React from 'react';

// Auxiliary libraries
import PropTypes from 'prop-types';

// Components
import EmptyStateLabel from '../../../shared/components/EmptyStateLabel/EmptyStateLabel';
import TableRow from '../UsersTable/TableRow';

// Styled components
import { Container } from '../../../shared/components/EmptyStatePreview/EmptyStatePreview.styles';

export const EmptyStatePreview = ({
  users,
  EntryEmptyState,
  label,
  labelBold,
  labelSuccess
}) => {
  return (
    <Container data-test="EmptyStatePreview">
      {EntryEmptyState}

      <EmptyStateLabel
        data-test="EmptyStateLabel"
        label={label}
        labelBold={labelBold}
        labelSuccess={labelSuccess}
      />

      {users.map(el => {
        return <TableRow key={el.user_id} details={el} />;
      })}
    </Container>
  );
};

EmptyStatePreview.propTypes = {
  EntryEmptyState: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  labelBold: PropTypes.string.isRequired,
  labelSuccess: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired
};

export default EmptyStatePreview;
