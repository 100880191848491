import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(14px);
  padding-top: ${({ paddingHeader }) => paddingHeader || '5.6rem'};
`;

export const Description = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 15.5px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.218182px;

  color: ${colors.body};
  margin-top: 1rem;
  margin-bottom: 3rem;

  u {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  width: 54rem;
  padding: 1.6rem 4.8rem;
  overflow: auto;

  .headerTitle {
    text-align: center;
  }

  .formField {
    margin: 3.2rem 0;
  }

  .formButton {
    width: 100%;
    margin-top: 1.6rem;
  }

  .singleButton {
    margin-bottom: 1.6rem;
  }

  .tooltip {
    top: -3rem;
    right: -2rem;
    left: auto;
    z-index: 10;
    width: 30rem;

    a {
      color: inherit;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 54rem;
    padding: 1.6rem 2rem;

    .formButton {
      height: 4rem;
    }

    .formButton:last-child {
      margin: 1.6rem 0 10rem 0;
    }
  }
`;
