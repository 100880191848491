import styled, { css } from 'styled-components';

const classes = css`
  .cursor:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  width: 100%;
  ${classes}
`;
