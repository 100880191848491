// React imports
import React from 'react';

// Auxiliary librarys imports
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

// Own Components
import CustomTooltip from '../../components/CustomTooltip';
import { PrimaryButton } from '../../components/Shared';

// Other
import {
  registerFreelancerLink,
  registerPymeLink
} from '../../micro-frontends/register/constants';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    fontSize: '4.8rem',
    color: '#323132'
  },
  mainDescription: {
    fontSize: '1.8rem',
    color: '#323132'
  },
  subtitle: {
    fontSize: '2.4rem',
    color: '#323132',
    fontWeight: '600',
    marginTop: 0,
    marginBottom: 0
  },
  documentsTitle: {
    fontSize: '1.8rem',
    color: '#666566',
    fontWeight: '600',
    margin: '1rem'
  },
  documents: {
    fontSize: '1.4rem',
    color: '#666566',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem'
    }
  },
  contentHeight: {
    height: '100%'
  },
  buttonRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1.6rem 0',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  brownText: {
    color: '#989898',
    fontSize: '1.4rem'
  },
  paddingNone: {
    padding: 'none'
  },
  marginNone: {
    marginBottom: 0
  }
}));

const Welcome = props => {
  // Styles
  const classes = useStyles();

  // Redux
  const isLoggedIn = useSelector(state => state.userInfoReducer.token);

  const history = useHistory();

  const redirectToRegister = isPyme =>
    history.push(isPyme ? registerPymeLink : registerFreelancerLink);

  return (
    <div className={classes.contentHeight}>
      <div>
        <h1 className={classes.mainTitle}>¡Hola!</h1>
        <p className={classes.mainDescription}>
          Para facilitar el proceso, es necesario que tengas listos los
          siguientes documentos:
        </p>
      </div>
      <Card>
        <CardContent>
          <h2 className={classes.subtitle}>
            Tarjeta PYME o Startup
            <CustomTooltip title="Si tu empresa es de reciente creación y/o con número reducido de trabajadores" />
          </h2>

          <h3 className={classes.documentsTitle}>Documentos de la empresa:</h3>
          <ul className={classes.documents}>
            <li>
              Acta Constitutiva
              <CustomTooltip title="Con datos de inscripción en el Registro Público de Comercio" />
            </li>
            <li>
              Cédula de Identificación Fiscal
              <CustomTooltip title="También puedes continuar con la Constancia de Situación Fiscal" />
            </li>
            <li>
              Constancia de la Firma Electrónica Avanzada
              <CustomTooltip title="También puedes continuar con Comprobante de Certificado de Sello Digital" />
            </li>
            <li>Comprobante de domicilio no mayor a 3 meses de antigüedad</li>
            <li>Tu INE como apoderado legal de la empresa</li>
            <li>Acta donde te nombren como apoderado legal</li>
            <li>
              Estado de cuenta bancario no mayor a dos meses de antigüedad
            </li>
            <li>Boleta de inscripción en el Registro Público de Comercio</li>
          </ul>
        </CardContent>
      </Card>
      <div className={classes.buttonRight}>
        {!isLoggedIn && (
          <PrimaryButton
            text="Solicitar ahora"
            onClick={() => redirectToRegister(1)}
          />
        )}
      </div>
      {process.env.REACT_APP_ACTIVATE_FREELANCER_ONBOARDING === 'true' && (
        <>
          <Card>
            <CardContent>
              <h2 className={classes.subtitle}>
                Tarjeta Freelancer
                <CustomTooltip title="Si trabajas de forma independiente" />
              </h2>
              <h3 className={classes.documentsTitle}>Documentos:</h3>
              <ul className={classes.documents}>
                <li>
                  Cédula de Identificación Fiscal
                  <CustomTooltip title="También puedes continuar con la Constancia de Situación Fiscal" />
                </li>
                <li>
                  Constancia de la Firma Electrónica Avanzada
                  <CustomTooltip title="También puedes continuar con Comprobante de Certificado de Sello Digital" />
                </li>
                <li>
                  Comprobante de domicilio no mayor a 3 meses de antigüedad
                </li>
                <li>Tu INE</li>
                <li>
                  Estado de cuenta bancario no mayor a dos meses de antigüedad
                </li>
              </ul>
            </CardContent>
          </Card>
          <div className={`${classes.buttonRight} ${classes.marginNone}`}>
            {!isLoggedIn && (
              <PrimaryButton
                id="freelancer-button"
                text="Solicitar ahora"
                onClick={() => redirectToRegister(0)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Welcome;
