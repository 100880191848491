import {
  GET_TRANSACTIONS,
  SET_TRANSACTIONS_ERRORS,
  PURGE,
  SET_TRANSACTIONS_TYPE_FILTER,
  ADD_TAG_TO_TRANSACTION,
  REMOVE_TAG_FROM_TRANSACTION
} from './types';

const INITIAL_STATE = {
  transactions: {
    id: null,
    res: {
      transactions: [],
      current_page: 1,
      total_pages: 1
    },
    success: false,
    error: false,
    filterByType: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS_TYPE_FILTER:
      return { ...state, filterByType: action.payload };
    case GET_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case SET_TRANSACTIONS_ERRORS:
      return {
        ...state,
        transactions: { ...state.transactions, error: action.payload }
      };
    case ADD_TAG_TO_TRANSACTION: {
      const { transactionId, tag } = action.payload;
      const updatedTransactions = state.transactions.res.transactions.map(
        transaction => {
          if (transaction.transaction_id === transactionId) {
            return {
              ...transaction,
              tags: [...transaction.tags, tag]
            };
          }
          return transaction;
        }
      );
      return {
        ...state,
        transactions: {
          ...state.transactions,
          res: {
            ...state.transactions.res,
            transactions: updatedTransactions
          }
        }
      };
    }
    case REMOVE_TAG_FROM_TRANSACTION: {
      const { transactionId, tagId } = action.payload;
      const updatedTransactions = state.transactions.res.transactions.map(
        transaction => {
          if (transaction.transaction_id === transactionId) {
            const updatedTags = transaction.tags.filter(
              tag => tag.tag_id !== tagId
            );
            return {
              ...transaction,
              tags: [...updatedTags]
            };
          }
          return transaction;
        }
      );
      return {
        ...state,
        transactions: {
          ...state.transactions,
          res: {
            ...state.transactions.res,
            transactions: updatedTransactions
          }
        }
      };
    }
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
