import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../shared/components/Button';
import ButtonSubtle from '../ButtonSubtle/ButtonSubtle';
import { StyledModal as Modal } from './styles';
import Title from '../../../shared/components/Title';
import Label from '../../../shared/components/Label/Label';

const DeleteUser = ({
  handleClose,
  handleDelete,
  name = 'este usuario',
  open
}) => {
  return (
    <Modal
      open={open}
      disableBackDropClick={false}
      showCloseOnModal
      onClose={handleClose}
    >
      <Title className="deleteTitle" size="large" text={`¿Estás seguro?`} />
      <Label
        className="label"
        text="Al borrar este usuario, también se desactivan las tarjetas asociadas a él."
      />
      <div className="buttonsContainer">
        <Button
          size="lg"
          text="Sí, borrar usuarios y tarjetas asociadas."
          onClick={handleDelete}
        />
        <ButtonSubtle className="btnSubtle" onClick={handleClose}>
          Regresar
        </ButtonSubtle>
      </div>
    </Modal>
  );
};

DeleteUser.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default DeleteUser;
