import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { tagOptionsPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const deleteTransactionTagService = async ({
  tag: { tagLabel, tagId } = {}
}) => {
  try {
    const { data, status, error } = await customAxiosInstance().delete(
      tagOptionsPath,
      {
        data: {
          tag_label: tagLabel,
          tag_id: tagId
        }
      }
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'delete-transaction-tag-service',
      isSingleElement: true
    });
  } catch (error) {
    log.error('delete-transaction-tag-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
