import React, { useState, useEffect } from 'react';

// Auxiliary libraries
import log from 'loglevel';
import IdleTimer from 'react-idle-timer';
import { useSelector } from 'react-redux';

// Other components
import LogoutModal from '../LogoutModal';

// Redux
import { setTokenOnRefresh } from '../../../../actionCreators/authAction';
import { PURGE } from '../../../../reducers/types';

// Services
import {
  refreshTokenService,
  cleanLocalStorage,
  closeSession
} from '../../../../Services/authService';

import { alert } from '../../../../helpers/alertsManager';

const modalIdleTime = process.env.REACT_APP_IDLE_TIME_FOR_MODAL;
const logoutCountdown = process.env.REACT_APP_LOGOUT_LAG;
const toastVisbility = process.env.REACT_APP_TOAST_VISIBILITY_TIME;

const IdleModal = ({ dispatch, isLogOut, setIsLogOut }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeLeftToLogout, setTimeLeftToLogout] = useState(logoutCountdown);

  const refreshTokenData = useSelector(
    state => state.userInfoReducer.refreshToken
  );
  const tokenDetails = useSelector(state => state.userInfoReducer.tokenDetails);

  useEffect(() => {
    if (isOpen && timeLeftToLogout > 0) {
      const interval = setInterval(() => {
        setTimeLeftToLogout(timeLeftToLogout - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }

    if (timeLeftToLogout === 0) {
      handleLogout();
    }

    if (!isOpen) {
      setTimeLeftToLogout(logoutCountdown);
    }
    // eslint-disable-next-line
  }, [timeLeftToLogout, isOpen]);

  useEffect(() => {
    if (isLogOut && tokenDetails) {
      handleLogout();
    }
    // I couldn't find for what are this disable lint rules
    // eslint-disable-next-line
  }, [isLogOut]);

  const handleIdle = e => {
    if (tokenDetails) {
      setIsOpen(true);
    }
  };

  const handleLogout = async () => {
    try {
      await closeSession();
    } catch (error) {
      log.error('Loglevel: ', error);
    } finally {
      dispatch({ type: PURGE });
      setIsOpen(false);
      setIsLogOut(false);
      cleanLocalStorage();
      alert('info', 'Por seguridad cerramos tu sesión', toastVisbility);
    }
  };

  const refreshTokenFc = () => {
    if (!refreshTokenData) {
      handleLogout();
      return;
    }

    setIsOpen(false);
    refreshTokenService()
      .then(res => {
        setTokenOnRefresh(res.data, dispatch);
      })
      .catch(err => {
        log.error(err);
        dispatch({ type: PURGE });
      });
  };

  return (
    <>
      <IdleTimer
        element={document}
        onIdle={handleIdle}
        debounce={250}
        timeout={modalIdleTime}
      />

      <LogoutModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        disableBackDropClick
        title="¿Aún estás ahí?"
        secondaryText="Por seguridad, esta sesión se cerrará en"
        secondsLeft={timeLeftToLogout}
        logoutAction={handleLogout}
        stayLoggedInAction={refreshTokenFc}
        zIndex="9999"
      />
    </>
  );
};

export default IdleModal;
