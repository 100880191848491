import { createTheme } from '@material-ui/core';
import { colors } from './Dashboard4.0/assets/styles/styles';

export const Delt_aiMaterialTheme = createTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10
  },
  overrides: {
    MuiTabs: {
      root: {
        borderBottom: colors.carbon
      },
      indicator: {
        backgroundColor: colors.primary
      }
    },
    MuiTab: {
      wrapper: {
        fontFamily: 'Barlow',
        fontSize: '12px',
        '&:hover': {
          color: colors.primaryGs,
          opacity: 1
        }
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '1.6rem',
        marginBottom: '1.6rem'
      },
      root: {
        marginTop: '1.6rem',
        marginBottom: '1.6rem'
      }
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.9)'
      }
    },

    MuiFormLabel: {
      root: {
        fontSize: '1.4rem',
        fontFamily: 'Quicksand',
        fontWeight: '600',
        color: colors.carbon,
        '&$error ': {
          color: colors.deltai
        },
        '&:hover $notchedOutline': {
          borderColor: 'none'
        },
        '&.Mui-focused': {
          borderColor: 'none',
          color: colors.carbon
        }
      }
    },
    MuiAlert: {
      message: {
        width: '100%'
      }
    },

    MuiOutlinedInput: {
      root: {
        '&$error $notchedOutline': {
          borderColor: colors.deltai
        },
        '&:hover $notchedOutline': {
          borderColor: 'none'
        },
        '&:focus $notchedOutline': {
          borderColor: 'none'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(147, 156, 169,0.15)',
          borderRadius: '0.8rem'
        },
        fontFamily: 'Quicksand',
        fontWeight: '600'
      },

      input: {
        padding: '0px 24px',
        height: '7.1rem'
      },
      notchedOutline: {
        borderColor: 'rgba(147, 156, 169,0.15)',
        borderRadius: '0.8rem',
        border: 'solid 0.5px'
      }
    },
    MuiFormHelperText: {
      root: {
        '&$error ': {
          color: colors.deltai
        },
        fontFamily: 'Quicksand',
        color: colors.carbon
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.deltai
      }
    }
  }
});
