// Services
import {
  getTransactionsByQuery,
  getTransactionsByType,
  getTransactionsService
} from '../Services/transaction';

// Redux
import {
  GET_TRANSACTIONS,
  SET_TRANSACTIONS_ERRORS,
  SET_TRANSACTIONS_TYPE_FILTER
} from '../reducers/types';
import { setAction } from '.';

export const setFilterByType = (dispatch, body) => {
  dispatch({ type: SET_TRANSACTIONS_TYPE_FILTER, payload: body });
};

export const getTransactionsSchema = (
  dispatch,
  service,
  pageNumber,
  query,
  type
) => {
  const page = pageNumber || 1;
  let params = page;
  if (query) params = { page, query };
  if (type) params = { page, type };

  service(params)
    .then(res => {
      if (res && res.status === 200) {
        let payloadRes = {
          res: { ...res.data[0], transactions: res.data[0].transactions },
          success: true,
          error: false
        };
        setAction(res, GET_TRANSACTIONS, dispatch, payloadRes);
      } else {
        dispatch({ type: SET_TRANSACTIONS_ERRORS, payload: true });
      }
    })
    .catch(e => dispatch({ type: SET_TRANSACTIONS_ERRORS, payload: true }));
};

export const getTransactions = (dispatch, pageNumber) => {
  getTransactionsSchema(dispatch, getTransactionsService, pageNumber);
};

export const getTransactionsByText = (dispatch, pageNumber, query) => {
  getTransactionsSchema(dispatch, getTransactionsByQuery, pageNumber, query);
};

export const filterTransactionsByType = (dispatch, pageNumber, type) => {
  getTransactionsSchema(
    dispatch,
    getTransactionsByType,
    pageNumber,
    null,
    type
  );
};
