/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';

// Auxiliar libraries
import PropTypes from 'prop-types';

// Components
import { Container } from './Balance.styles';
import ComissionsAndFees from '../ComissionsAndFees';
import EntryBalance from '../EntryBalance/EntryBalance';
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';
import { getShortDate } from '../../../../helpers';

export const Component = ({
  companySummary,
  daysLeft,
  statementClosingDate,
  openingBalanceDate
}) => {
  const {
    amount_owed,
    credits_in_month,
    opening_balance,
    charges_in_month
  } = companySummary;

  return (
    <Container>
      <EntryBalance
        day={openingBalanceDate.day}
        month={openingBalanceDate.month}
        concept="Inicio del periodo"
        amount={opening_balance}
        className="divider"
      />
      <EntryBalance concept="Cargos" amount={charges_in_month} />
      <EntryBalance
        concept="Abonos"
        amount={credits_in_month}
        color="success"
      />
      <ComissionsAndFees className="divider" companySummary={companySummary} />
      <EntryBalance
        concept="Balance en este momento"
        balanceDate={getShortDate(new Date(), null, true)}
        amount={amount_owed}
        color="primary"
      />
      <EntryBalance
        day={statementClosingDate.day}
        month={statementClosingDate.month}
        concept="Corte del periodo"
        balanceHour={`Dentro de ${daysLeft} días`}
        color="grey"
      />
    </Container>
  );
};

Component.propTypes = {
  companySummary: PropTypes.object.isRequired,
  daysLeft: PropTypes.number.isRequired,
  statementClosingDate: PropTypes.object.isRequired,
  openingBalanceDate: PropTypes.object.isRequired
};

const Balance = props => {
  return (
    <ErrorBoundaryHandler component={<Component {...props} />} name="Balance" />
  );
};

export default memo(Balance);
