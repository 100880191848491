import { useState, useEffect } from 'react';

const getWidth = () =>
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

const useViewportWidth = () => {
  const [width, setWidth] = useState(() => getWidth());

  const handleResizing = () => {
    setWidth(getWidth());
  };

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined)
      window.addEventListener('resize', handleResizing);
    return () => window.removeEventListener('resize', handleResizing);
  }, []);

  return width;
};

export default useViewportWidth;
