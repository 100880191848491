import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Barlow',
    fontSize: '4.8rem',
    color: '#323132',
    margin: '0',
    [theme.breakpoints.down('md')]: {
      fontSize: '4.4rem'
    }
  }
}));

const MainTitle = props => {
  const classes = useStyles();
  return <h1 className={props.className || classes.root}>{props.text}</h1>;
};

export default MainTitle;
