// React
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


// Auxiliary libraries
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

// Own code

import {
  checkAPIStatus,
  closeApiStatusAlert
} from './actionCreators/apiStatusAction';
import { customAxiosInstance } from './Services/axiosConfig';
import { customAxiosInstance as customAxiosAuxInstance} from './Services/axiosAuxConfig';
import { startLoading, stopLoading } from './actionCreators/loadingActions';
import MissingDocumentsState from './context/missingDocuments/MissingDocumentsState';
import { Provider as BiometricProvider } from './context/BiometricContext';
import { Provider as FormProvider } from './context/formContext';
import { Provider as UserInfoProvider } from './context/userInfoContext';
import { Provider as ViewsProvider } from './context/viewsContext';
import { validateRequestPermission } from './Services/apiPermissions';
import { getPathBasedOnStatus } from './routing/index';
import { RfcProvider as RfcProvider} from './context/RfcContext';  


// Components
import AuthPermissions from './role-based-access/components/AuthPermissions';
import { BasicInfoTemplate } from './components/Shared';
import ConfirmAccount from './Views/ConfirmAccount/ConfirmAccount';
import CreatePasswordPage from './micro-frontends/sub-account-onboarding/pages/RegistrationPage/CreatePasswordPage';
import DashboardView from './Dashboard4.0/DashboardView';
import IdleModal from './micro-frontends/shared/components/IdleModal';
import { PrivateRoute } from './routing/PrivateRoute';
import Login from './micro-frontends/login/login';
import Kyc from './micro-frontends/kyc/kyc';
import RegistrationPage from './micro-frontends/sub-account-onboarding/pages/RegistrationPage/RegistrationPage';
import WaitingLogo from './components/WaitingLogo/WaitingLogo';
import WaitingRedLines from './components/WaitingRedLines/WaitingRedLines';
import Prequalification from './micro-frontends/prequalification/Prequalification';
import PrequalificationTpa  from './micro-frontends/prequalification-tpa/PrequalificationTpa';
import SignContract from './micro-frontends/sign-contract';
import NotFound from './routing/pages/notFoundPage.jsx';
import OnboardingRoutes from './routing/onboarding/OnboardingRoutes';

// Assets
import './App.css';
import CustomAlert from './components/CustomAlert';
import { isUserValidated, logOutUrl } from './Services/authService';

//Alert
import { alert } from './helpers/alertsManager';
import { getErrors, validateErrorCode } from './helpers/errorHandler';
import { getMessageResponse } from './helpers/responseHandler';

//routes
import { loginPaths } from './micro-frontends/login/constants';
import { kycPaths, errorMessages } from './micro-frontends/kyc/utils/constants';
import { registerPaths } from './micro-frontends/register/constants';
import { prequalificationPaths } from './micro-frontends/prequalification/constants';
import { prequalificationPaths as prequalificationPathsTpa } from './micro-frontends/prequalification-tpa/constants';
import { signContractPath } from './micro-frontends/sign-contract/constants';
import {
  newOnboardingPath,
  softwallPath
} from './micro-frontends/onboarding/constants';
import {
  companyOnboarding,
  freelancerOnboarding
} from './routing/onboarding/constants';

// Modules
import RegisterModule from './micro-frontends/register/register';
import PymeForm from './micro-frontends/register/pages/pymeForm/PymeForm';
import EnterpriseBasicInfo from './micro-frontends/onboarding/pages/enterprise-basic-info/EnterpriseBasicInfo'; 


// Theme
import { Delt_aiMaterialTheme } from './MuiTheme';
import OnboardingTpa from './micro-frontends/onboarding-tpa/OnboardingTpa';
import { onboardinsPaths } from './micro-frontends/onboarding-tpa/constants';

import { closeSession } from './Services/authService.js';
import { PURGE } from './reducers/types.js';

function App() {
  // Hooks to manage HTTP errors
  const [launchApp, setLaunchApp] = useState(true);

  const [logOutUrlVar] = useState(
    `${process.env.REACT_APP_API_BASE_PATH}${logOutUrl}`
  );
  const [isLogOut, setIsLogOut] = useState(false);

  const path = window.location.pathname;

  // Redux
  const dispatch = useDispatch();
  const apiStatus = useSelector(state => state.apiStatusReducer.status);
  const isLoggedIn = useSelector(state => state.userInfoReducer.token);
  const loading = useSelector(state => state.loadingReducer.loading);
  const user = useSelector(state => state.userInfoReducer.user);
  const customerData = useSelector(state => state.userInfoReducer.customer);

  const { status, company_type_id: userType, user_role_id: roleId } = user;
  let currentStatus = status;
  if(customerData && (status === 'Active' || status === 'User validated') && isLoggedIn) {
    currentStatus = customerData.lifecycle;
  }else if(!customerData && (status === 'Active' || status === 'User validated')){
    try {
      closeSession();
    } finally {
        dispatch({ type: PURGE });
    }
  }
  const redirectPath = getPathBasedOnStatus(currentStatus, userType, roleId);


  //Interceptor http errors
  customAxiosInstance().interceptors.response.use(
    res => {
      if (!res) return;
      stopLoading(dispatch);

      let message = getMessageResponse(res);
      if (message) alert('success', message);

      let response = res;

      if (res.data) {
        let body = [];
        if (res.data.body && res.data.body.length) {
          body = res.data.body[0].internal_error_code ? [] : res.data.body;
          response['internal_error_code'] =
            res.data.body[0].internal_error_code || null;
        }
        response['data'] = res.data.body ? body : res.data;
      }

      return response;
    },
    err => {
      stopLoading(dispatch);
      const errBody = err.response ? err.toJSON() : err;
      const message = getErrors(err);
      const { key } = validateErrorCode(err.response || null);

      // false as 3rd argument to prevent toast from closing
      if (message.length > 0) {
        message.forEach(el => alert('error', el));
      }

      if (
        key === 'invalid_sesion' &&
        errBody.config.url !== logOutUrlVar &&
        !isLogOut
      ) {
        setIsLogOut(true);
      }

      Sentry.captureException(err);
      return err.response ? err.response : err;
    }
  );

  customAxiosAuxInstance().interceptors.response.use(
    res => {
      if (!res) return;
      stopLoading(dispatch);

      let message = getMessageResponse(res);
      if (message) alert('success', message);

      let response = res;
      

      if (res.data) {
        let body = [];
        if (res.data.body && res.data.body.length) {
          if(response.data.body[0].response === "BAD"){
            alert('error', errorMessages[response.data.body[0]?.data?.exception?.code] || "Occurió un error, por favor contacte al administrador");
          }
          body = res.data.body[0].internal_error_code ? [] : res.data.body;
          response['internal_error_code'] =
            res.data.body[0].internal_error_code || null;
        }
        response['data'] = res.data.body ? body : res.data;
      }

      return response;
    },
    err => {
      console.log("err ", err);
      stopLoading(dispatch);
      // const errBody = err.response ? err.toJSON() : err;
      // const message = getErrors(err);
      // const { key } = validateErrorCode(err.response || null);

      // // false as 3rd argument to prevent toast from closing
      // if (message.length > 0) {
      //   message.forEach(el => alert('error', el));
      // }

      // if (
      //   key === 'invalid_sesion' &&
      //   errBody.config.url !== logOutUrlVar &&
      //   !isLogOut
      // ) {
      //   setIsLogOut(true);
      // }
      // alert('error', "Occurió un error, por favor contacte al administrador");

      // Sentry.captureException(err);
      return err.response ? err.response : err;
    }
  );

  customAxiosInstance().interceptors.request.use(
    config => {
      startLoading(dispatch);
      const isValid = validateRequestPermission(user.user_role_id, config);
      if (isValid) return config;
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: 'Permissions error', body: [] });
    },
    error => Promise.reject(error)
  );

  customAxiosAuxInstance().interceptors.request.use(
    config => {
      startLoading(dispatch);
      return config;
      // const isValid = validateRequestPermission(user.user_role_id, config);
      // if (isValid) return config;
      // // eslint-disable-next-line prefer-promise-reject-errors
      // return Promise.reject({ message: 'Permissions error', body: [] });
    },
    error => Promise.reject(error)
  );

  useEffect(() => {
    checkAPIStatus(dispatch);
    // eslint-disable-next-line
  }, []);

  //Clean up timer
  useEffect(() => {
    timer();
    return function clenup() {
      clearTimeout(timer); // FIXME this will clear the undefiend because timer func is not returning anything.
    };
  }, []);

  const timer = () => {
    // FIXME more descriptive name. what is this timer for?
    setTimeout(() => setLaunchApp(false), 1000);
  };

  useEffect(() => {
    if (path === '/activar' && !isLoggedIn)
      localStorage.setItem('path', '/activar');

    if (path.includes('/multitransfers') && !isLoggedIn) {
      localStorage.setItem('path', path);
    }
  }, [path, isLoggedIn]);

  return (
    <>
      {launchApp ? (
        <WaitingLogo />
      ) : (
        <ThemeProvider theme={Delt_aiMaterialTheme}>
          {apiStatus === 'unhealthy' && (
            <CustomAlert
              severity="warning"
              text="Estamos en mantenimiento, puede haber intermitencias en el sistema. Para más información, consulta "
              linkTo="https://status.delt.ai"
              onClick={() => closeApiStatusAlert(dispatch)}
            />
          )}

          <IdleModal
            isLogOut={isLogOut}
            dispatch={dispatch}
            setIsLogOut={setIsLogOut}
            alert={alert}
          />

          <ToastContainer />
          <MissingDocumentsState>
            <UserInfoProvider>
              <FormProvider>
                <ViewsProvider>
                  <BiometricProvider>
                  <RfcProvider>
                    <Router>
                      {loading && <WaitingRedLines />}
                      <Switch>
                        <PrivateRoute
                          exact
                          path={['/', '/freelancer', '/pyme']}
                          component={BasicInfoTemplate}
                          validate={!isLoggedIn}
                          redirect={redirectPath}
                        />
                        <PrivateRoute
                          path={loginPaths}
                          component={Login}
                          redirect="/"
                          validate={!isLoggedIn}
                        />

                        <PrivateRoute
                          path={kycPaths}
                          component={Kyc}
                          redirect="/"
                          validate={isLoggedIn && isUserValidated(user.status)}
                        />

                        <PrivateRoute
                          path={signContractPath}
                          validate={isLoggedIn}
                          redirect="/"
                          component={SignContract}
                        />
                        <Route
                          path="/confirm-account"
                          component={ConfirmAccount}
                          redirect="/"
                        />
                        <Route
                          path={registerPaths}
                          component={RegisterModule}
                        />
                        <PrivateRoute
                          path={prequalificationPaths}
                          component={Prequalification}
                          redirect="/"
                          validate={isLoggedIn}
                        />
                        <Route
                          path={prequalificationPathsTpa}
                          component={PrequalificationTpa}
                          redirect="/"
                        />
                        <Route
                          path={onboardinsPaths}
                          component={OnboardingTpa}
                          redirect="/"
                        />
                        <Route
                          path="/new-user-invitation"
                          component={CreatePasswordPage}
                          redirect="/"
                        />
                        <PrivateRoute
                          exact
                          path={'/new-user-registration'}
                          component={RegistrationPage}
                          validate={isLoggedIn}
                          redirect="/login"
                        />
                        <Route exact path="/register" component={PymeForm} />
                        <Route exact path="/onboarding" component={EnterpriseBasicInfo} />
                        <AuthPermissions
                          path={[
                            '/activity',
                            '/account',
                            '/activar',
                            '/credit',
                            '/team',
                            '/apidocs',
                            '/cards',
                            '/multitransfers',
                            '/invoices'
                          ]}
                          component={DashboardView}
                          validate={isLoggedIn && isUserValidated(user.status) && (currentStatus === 'completed' || currentStatus === undefined)}
                          redirect="/login"
                        />
                        <PrivateRoute
                          exact
                          path={[
                            newOnboardingPath,
                            freelancerOnboarding,
                            companyOnboarding,
                            softwallPath
                          ]}
                          component={OnboardingRoutes}
                          validate={isLoggedIn}
                          redirect="/"
                        />
                        <Route component={NotFound} />
                      </Switch>
                    </Router>
                   </RfcProvider>
                  </BiometricProvider>
                </ViewsProvider>
              </FormProvider>
            </UserInfoProvider>
          </MissingDocumentsState>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
