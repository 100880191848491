import {
  enterpriseAddressPath,
  enterpriseAttorneyPath,
  enterpriseIdentity,
  enterpriseBankPath,
  enterpriseInfoPath,
  enterpriseDocuments
} from '../../constants';
import { states } from '../state-machine/state-machine-definition';

export const enterpriseRoutes = {
  [enterpriseInfoPath]: {
    path: enterpriseInfoPath,
    state: states.COMPANY_DATA_STEP,
    index: Object.keys(states).indexOf(states.COMPANY_DATA_STEP),
    label: 'Datos de la empresa'
  },
  [enterpriseAddressPath]: {
    path: enterpriseAddressPath,
    state: states.ADDRESS_STEP,
    index: Object.keys(states).indexOf(states.ADDRESS_STEP),
    label: 'Domicilio Fiscal'
  },
  [enterpriseAttorneyPath]: {
    path: enterpriseAttorneyPath,
    state: states.REAL_OWNERSHIP,
    index: Object.keys(states).indexOf(states.REAL_OWNERSHIP),
    label: 'Propietario'
  },
  [enterpriseBankPath]: {
    path: enterpriseBankPath,
    state: states.BANK_DATA_STEP,
    index: Object.keys(states).indexOf(states.BANK_DATA_STEP),
    label: 'Cuenta Bancaria'
  },
  [enterpriseIdentity]: {
    path: enterpriseIdentity,
    state: states.IDENTITY_STEP,
    index: Object.keys(states).indexOf(states.IDENTITY_STEP),
    label: 'Identidad'
  },
  [enterpriseDocuments]: {
    path: enterpriseDocuments,
    state: states.DOCUMENTS_STEP,
    index: Object.keys(states).indexOf(states.DOCUMENTS_STEP),
    label: 'Documentos'
  }
};
