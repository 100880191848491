import styled from 'styled-components';
import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

export const LabelContainer = styled.span`
  color: ${colors.carbon};
`;

const getSelectedStyles = ({ selected }) => {
  return selected ? `color: ${colors.primary};` : '';
};

export const Label = styled.span`
  ${getSelectedStyles}
`;
