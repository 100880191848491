import React, { useState, useEffect } from 'react';

// Icons
import Loading from '../../../../assets/waiting-red-lines.gif';
import ActivationIcon from '../../../../assets/invoice-activation-icon.png';

// Actions
import { getInvoices } from '../../../../actionCreators/InvoicesAction';

// Shared Components
import Layout from '../../../shared/components/Layout/Layout';
import HeaderTable from '../../../shared/components/HeaderTable/HeaderTable';
import EntryEmptyState from '../../../shared/components/EntryEmptyState/EntryEmptyState';
import Modal from '../../../shared/components/Modal/Modal';

// Invoices Components
import FilterInvoices from '../../components/FilterInvoices/FilterInvoices';
import InvoicesBalance from '../../components/InvoicesBalance/InvoicesBalance';
import InvoicesTable from '../../components/InvoicesTable/InvoicesTable';
import InvoiceVoucher from '../../components/InvoiceVoucher/InvoiceVoucher';
import SatwsCredentialsModal from '../../components/sat-credentials-modal';
import ErrorBoundaryHandler from '../../../../ErrorBoundary/ErrorBoundary';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { clearTags } from '../../../tags/redux/tag-action';

// Constants
import { TITLE } from '../../constants';

const Component = () => {
  const [open, setOpen] = useState(false);
  const [showSatwsModal, setShowSatwsModal] = useState(false);
  const [invoicesDetails, setInvoicesDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const MailBoxActive = useSelector(state => state.invoicesReducer.activated);
  const InvoicesRes = useSelector(state => state.invoicesReducer.invoices);
  const invoices = InvoicesRes.res.invoices;
  const page = InvoicesRes.res.current_page;
  const totalPages = InvoicesRes.res.total_pages;
  const totalResults = InvoicesRes.res.total_results;
  const { success } = InvoicesRes;
  const { error } = InvoicesRes;

  const handleClose = () => {
    setOpen(false);
    setInvoicesDetails({});
    dispatch(clearTags());
  };

  const toggleSatwsModal = () => {
    setShowSatwsModal(!showSatwsModal);
  };

  const selectInvoice = el => {
    setOpen(true);
    setInvoicesDetails(el);
  };

  useEffect(() => {
    if (!MailBoxActive) return;
    getInvoices(dispatch, currentPage);
  }, []);

  return (
    <>
      <Layout
        title={TITLE}
        midleComponent={
          <>
            {/* TODO : Implement Invoices Balances  */}
            {/* <HasPermissions
              perform=""
              accessAction={() => <InvoicesBalance />}
              restrictedAction={() => <></>}
            /> */}
          </>
        }
      >
        {/* TODO : HANDLE INVOICES FILTERING */}
        {/* <FilterInvoices /> */}
        <HeaderTable
          texts={['FECHA', 'FACTURA', 'MONTO', 'ESTATUS']}
          columns="1.5fr 8fr 3fr 3fr"
        />

        {!error && !success ? (
          <EntryEmptyState
            img={Loading}
            imgWidth={80}
            data-test="EntryEmptyState"
            title="Sincronizando facturas"
            description="Tus facturas se están sincronizando, esto podría tomar algunos segundos."
          />
        ) : null}

        {error ? (
          <EntryEmptyState
            data-test="EntryEmptyState"
            title="¡Algo pasó!"
            description="Surgió un problema sincronizando tus facturas. Por favor inténtalo de nuevo."
            action={() => getInvoices(dispatch, 1)}
            actionText="Sincronizar"
          />
        ) : null}

        {!MailBoxActive ? (
          <EntryEmptyState
            img={ActivationIcon}
            data-test="EntryEmptyState"
            title="Carga tu buzón de facturas"
            description="Activa tu buzón si es tu primera vez aquí y aún no has autorizado el uso de tu CIEC."
            action={() => toggleSatwsModal()}
            actionText="Cargar"
          />
        ) : null}

        {success && invoices && invoices.length > 0 ? (
          <>
            <InvoicesTable invoices={invoices} onClick={selectInvoice} />
            {/* TODO : Handle page pagination */}
            {/* TODO : Handle notfound from searchbar */}
          </>
        ) : null}
      </Layout>

      {/* TODO : Invoices Voucher */}

      <InvoiceVoucher
        details={invoicesDetails}
        open={open}
        handleClose={handleClose}
        disableBackDropClick={false}
        showCloseOnModal
      />

      <SatwsCredentialsModal open={showSatwsModal} onClose={toggleSatwsModal} />
    </>
  );
};

const InvoicesList = props => {
  return (
    <ErrorBoundaryHandler
      component={<Component {...props} />}
      name="InvoicesListPage"
    />
  );
};

export default InvoicesList;
