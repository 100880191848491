const basePath = '/api/dashboard';

// TODO: Create const for ${basePath}/admin and similars

// FIXME: Typo 'adrress'
export const adrressStatesPath = `${basePath}/states`;
export const approveCardChangePath = `${basePath}/admin/update-card/approve/`;
export const approveCardCreationPath = `${basePath}/admin/create-card/approve/`;
export const availableCardsPath = `${basePath}/available-cards`;

export const cardsPath = `${basePath}/cards`;
export const cardsCreationRequestsPath = `${basePath}/admin/cards/request`;
export const cardChangeRequestPath = `${basePath}/admin/card/update-requests/`;
export const cardDetailsPath = `${basePath}/card/`;
export const checkRemainingLimitPath = `${basePath}/card/remaining-spend-limit`;

export const declineCardChangePath = `${basePath}/admin/update-card/reject/`;
export const declineCardCreationPath = `${basePath}/admin/create-card/reject/`;

export const issueCardPath = `${basePath}/card/create`;

export const personAdrressPath = `${basePath}/person/address`;

export const updateCardPath = `${basePath}/card/`;
export const updateCardStatusPath = `${basePath}/card/status/`;
export const usersPath = `${basePath}/team/company/user`;

export const validatePasswordPath = `${basePath}/validate_password`;
