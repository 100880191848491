import React from 'react';
import PropTypes from 'prop-types';
import { UserTypeParagraph, UserTypeTitle } from './styles';

const UserTypeLabel = ({ type, primaryInfo, secondaryinfo, icon }) => {
  return (
    <div className="userTypeLableContainer">
      <div className="avatarIconContainer">
        {icon && (
          <img
            src={icon}
            alt="Avatar Icon"
            width={'100%'}
            style={{
              objectFit: 'contain'
            }}
          />
        )}
      </div>
      <div className="userTypeInfoParagraph">
        {type && <UserTypeTitle>{type}</UserTypeTitle>}
        {primaryInfo && <UserTypeParagraph>{primaryInfo}</UserTypeParagraph>}
        {secondaryinfo && (
          <UserTypeParagraph>{secondaryinfo}</UserTypeParagraph>
        )}
      </div>
    </div>
  );
};

UserTypeLabel.propTypes = {
  type: PropTypes.string,
  primaryInfo: PropTypes.string,
  secondaryInfo: PropTypes.string,
  icon: PropTypes.string
};

export default UserTypeLabel;
