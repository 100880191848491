import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { confirmEmailPath } from './apiPaths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const confirmEmailService = async (token) => {
  try {
    const { error, data, status } = await customAxiosInstance().get(
      `${confirmEmailPath}/${token}`,
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'confirm-email-service',
    });
  } catch (error) {
    log.error('confirm-email-service', error);

    return { error: { message: 'Unable to send request' } };
  }
};
