import styled, { css } from 'styled-components';

export const classes = css`
  .singleInput {
    width: 6.7rem;
    input {
      font-size: 3.2rem;
      margin-left: -1.85rem;
      width: 6.5rem;
    }
  }

  @media (max-width: 600px) {
    .singleInput {
      width: 3.5rem;
      height: 3.5rem;

      input {
        font-size: 1.6rem;
        margin-left: 0;
        width: 1.5rem;
        height: 2rem;
      }
    }
  }
`;

export const Container = styled.div`
  ${classes}
`;
