/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import PorgressBarContent from './ProgressBarContent';

import { ProgressBarContainer, Progress } from './ProgressBar.styles';

const ProgressBar = ({ progress, amount, textContent, ...otherProps }) => {
  const ref = useRef(null);
  const [width, setWidth] = React.useState();

  const handleResizing = () => setWidth(ref.current.offsetWidth);

  useEffect(() => {
    handleResizing();
    if (typeof window !== 'undefined')
      window.addEventListener('resize', handleResizing);
    return () => window.removeEventListener('resize', handleResizing);
  }, []);

  return (
    <ProgressBarContainer
      data-test="progressBar"
      data-testid="progressBar"
      {...otherProps}
      ref={ref}
    >
      <PorgressBarContent
        amount={amount}
        textContent={textContent}
        textColor="gray"
      />
      <Progress progress={progress} className={otherProps.progressClass}>
        <PorgressBarContent
          amount={amount}
          textContent={textContent}
          textColor="white"
          width={width}
        />
      </Progress>
    </ProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  amount: PropTypes.any
};

export default memo(ProgressBar);
