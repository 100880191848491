const request2FA_uri_handler = ({ requestTwoFactorAuthURI }) => async () => {
  const { error, data } = await requestTwoFactorAuthURI();

  if (error) {
    return {
      isSuccess: false,
      error
    };
  }

  return { isSuccess: true, data };
};

export { request2FA_uri_handler };
