import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import questionIcon from '../../assets/question.svg';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '1.6rem',
    maxWidth: 'none',
    '& a': {
      color: 'lightblue'
    },
    textAlign: 'center'
  },
  imgSize: {
    width: '1.6rem'
  }
});

const CustomTooltip = ({ title, tooltipSize }) => {
  const classes = useStyles();

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title={title} interactive>
      <img
        src={questionIcon}
        alt="tooltip"
        className={tooltipSize || classes.imgSize}
      />
    </Tooltip>
  );
};

export default CustomTooltip;
