import log from 'loglevel';
import { customAxiosInstance } from '../../../Services/axiosConfig';
import { validatePasswordPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';

export const authUserService = async ({ email, password }) => {
  try {
    const { data, status, error } = await customAxiosInstance().post(
      validatePasswordPath,
      {
        email,
        password
      }
    );
    return extractContentFromResponse({
      data,
      status,
      error,
      moduleName: 'auth-user-service'
    });
  } catch (error) {
    log.error('auth-user-service', error);

    return { error: { message: 'Not able to send request!' } };
  }
};
