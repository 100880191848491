// React imports
import React, { useReducer } from 'react';

//Auxiliary libraries
import log from 'loglevel';

// Own Code
import { customAxiosInstance } from '../../Services/axiosConfig';
import {
  DOCUMENTS_TO_UPLOAD,
  DOCUMENTS_UPLOADED,
  GET_DOCUMENTS,
  GET_REJECTED_DOCS,
  SET_DOCS_AWAITING_REPLENISHMENT
} from './types';
import MissingDocumentsContext from './missingDocumentsContext';
import missingDocumentsReducer from './missingDocumentsReducer';

const companyDocumentsUrl = `/api/dashboard/company/kyc`;
const companyValidatedDocsUrl = `/api/dashboard/company/kyc-validated-files`;
const personDocumentsUrl = `/api/dashboard/person/kyc`;
const personValidatedDocsUrl = `/api/dashboard/person/kyc-validated-files`;

const MissingDocumentsState = ({children}) => {
  const initialState = {
    documentsToUpload: 0,
    documentsUploaded: 0,
    missingDocuments: null,
    rejectedDocs: [],
    userType: null,
    awaitingReplenishmentDocs: []
  };

  const getDocuments = async id => {
    let missingDocs = '';
    if (id === 1) {
      try {
        missingDocs = await customAxiosInstance().get(companyDocumentsUrl);
      } catch (error) {
        log.error(error);
      }
    } else {
      try {
        missingDocs = await customAxiosInstance().get(personDocumentsUrl);
      } catch (error) {
        log.error(error);
      }
    }

    dispatch({
      type: GET_DOCUMENTS,
      payload: missingDocs.data
    });
  };

  const postDocuments = (documents, userType, userId) => {
    //If the user is a freelancer, add user_id
    setDocumentsToUpload(documents.length);

    if (userType === 2) {
      postFreelancerDocs(documents, userId);
    } else if (userType === 1) {
      postCompanyDocs(documents, userId);
    }
  };

  const validateUploadedDocs = documentsToUpload => {
    setDocumentsUploaded((state.documentsUploaded += 1));

    if (documentsToUpload.length === state.documentsUploaded) {
      setDocumentsUploaded(0);
      setDocumentsToUpload(0);
    }
  };

  const postFreelancerDocs = documentsToUpload => {
    documentsToUpload.forEach(document => {
      customAxiosInstance(process.env.REACT_APP_AXIOS_KYC_TIMEOUT)
        .post(personDocumentsUrl, document)
        .then(() => {
          validateUploadedDocs(documentsToUpload);
        });
    });
  };

  const postCompanyDocs = documentsToUpload => {
    documentsToUpload.forEach(document => {
      if (document.kyc_document_type_id > 7) {
        customAxiosInstance(process.env.REACT_APP_AXIOS_KYC_TIMEOUT)
          .post(personDocumentsUrl, document)
          .then(() => {
            validateUploadedDocs(documentsToUpload);
          });
      } else {
        customAxiosInstance(process.env.REACT_APP_AXIOS_KYC_TIMEOUT)
          .post(companyDocumentsUrl, document)
          .then(() => {
            validateUploadedDocs(documentsToUpload);
          });
      }
    });
  };

  const setDocumentsUploaded = documents => {
    dispatch({
      type: DOCUMENTS_UPLOADED,
      payload: documents
    });
  };

  const setDocumentsToUpload = documents => {
    dispatch({
      type: DOCUMENTS_TO_UPLOAD,
      payload: documents
    });
  };

  const getRejectedDocuments = async userType => {
    let rejectedDocuments = [];
    let awaitingReplenishmentDocs = [];

    // TODO: Refactor
    if (userType === 2) {
      try {
        let documents = await customAxiosInstance().get(personValidatedDocsUrl);
        documents.data['person'].forEach(document => {
          // 5 = 'Rejected'
          if (document.kyc_document_status_id === 5) {
            // TODO: Validate if we need a separete component per status
            // rejectedDocuments.push(document);
            awaitingReplenishmentDocs.push(document);
          }
          // 3 = 'Awaiting replenishment'
          if (document.kyc_document_status_id === 3) {
            awaitingReplenishmentDocs.push(document);
          }
        });
      } catch (error) {
        log.error(error);
      }
    } else if (userType === 1) {
      try {
        let documents = await customAxiosInstance().get(
          companyValidatedDocsUrl
        );
        documents.data['person'].forEach(document => {
          if (document.kyc_document_status_id === 5) {
            // rejectedDocuments.push(document);
            awaitingReplenishmentDocs.push(document);
          }
          if (document.kyc_document_status_id === 3) {
            awaitingReplenishmentDocs.push(document);
          }
        });
        documents.data['company'].forEach(document => {
          if (document.kyc_document_status_id === 5) {
            // rejectedDocuments.push(document);
            awaitingReplenishmentDocs.push(document);
          }
          if (document.kyc_document_status_id === 3) {
            awaitingReplenishmentDocs.push(document);
          }
        });
      } catch (error) {
        log.error(error);
      }
    }

    if (rejectedDocuments) {
      dispatch({
        type: GET_REJECTED_DOCS,
        payload: rejectedDocuments
      });
    }

    if (awaitingReplenishmentDocs) {
      dispatch({
        type: SET_DOCS_AWAITING_REPLENISHMENT,
        payload: awaitingReplenishmentDocs
      });
    }
  };

  const [state, dispatch] = useReducer(missingDocumentsReducer, initialState);

  return (
    <MissingDocumentsContext.Provider
      value={{
        missingDocuments: state.missingDocuments,
        getDocuments,
        postDocuments,
        getRejectedDocuments,
        rejectedDocs: state.rejectedDocs,
        documentsUploaded: state.documentsUploaded,
        documentsToUpload: state.documentsToUpload,
        setDocumentsUploaded,
        setDocumentsToUpload,
        awaitingReplenishmentDocs: state.awaitingReplenishmentDocs
      }}
    >
      {children}
    </MissingDocumentsContext.Provider>
  );
};

export default MissingDocumentsState;
