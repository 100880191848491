import log from 'loglevel';

import { customAxiosInstance } from '../../../Services/axiosConfig';
import { availableCardsPath } from './api-paths';
import { extractContentFromResponse } from '../../../helpers/extractContentFromResponse';


export const getAvailableCardsService = async () => {

  try {
    const { data, status, error } = await customAxiosInstance().get(
      availableCardsPath
    );

    return extractContentFromResponse({
      data,
      status,
      error,
      isSingleElement: true,
      moduleName: 'available-cards-service'
    });

  } catch (error) {
    log.error('available-cards-service', error);

    return { error: { message: 'Not able to send request!' } };
  }

};
