import styled from 'styled-components';

import { colors } from '../../../../Dashboard4.0/assets/styles/styles';

import Icon from '../../../shared/components/Icon/Icon';

import {
  StyledDropdown,
  Options,
  LabelFor
} from '../../../shared/components/Dropdown/Dropdown.styles';

export const CustomDropdown = styled(StyledDropdown)`
  margin: 0;
`;

export const CustomOptions = styled(Options)`
  height: ${props => (props.height ? props.height : '23rem')};
  width: 20rem;
  overflow: scroll;
  right: 0;
`;

export const Label = styled(LabelFor)`
  padding: 0;
  width: 7.5rem;
`;

export const BankItemContainer = styled.div`
  text-align: end;
  margin: 0.8rem 0;
`;

export const CustomIcon = styled(Icon)`
  transform: rotate(90deg);
  margin-left: 0.8rem;
  width: 1rem;
  height: 1rem;

  path {
    fill: ${colors.carbon};
  }
`;
