import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Item from './Item';

import {
  HelperText,
  LabelFor,
  LabelWrapper,
  Options,
  SelectedLabel,
  StyledDropdwon,
  Wrapper
} from './styles';

const Select = ({
  error,
  helperText,
  label,
  options,
  keys,
  onSelect,
  selectedOption
}) => {
  const [hasValue, setHasValue] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    selectedOption ? selectedOption : ''
  );
  const [selectedLabel, setSelectedLabel] = useState('');

  const handleSelect = (e, label, value) => {
    setSelectedValue(value);
    setSelectedLabel(label);
    setHasValue(true);
    onSelect({ key: label, value: value });
  };

  const resetSelect = () => {
    setSelectedValue('');
  };

  return (
    <StyledDropdwon hasValue={hasValue}>
      <Wrapper>
        <LabelWrapper lineHeight={selectedLabel ? 3 : 4} hasValue={hasValue}>
          <LabelFor onClick={resetSelect}>{label}</LabelFor>
          {error ? <HelperText>{helperText}</HelperText> : null}
          <SelectedLabel>{selectedLabel}</SelectedLabel>
        </LabelWrapper>
      </Wrapper>

      <Options>
        {options
          ? options.map((el, ix) => {
              return (
                <Item
                  value={selectedValue === el[keys.value] ? 'true' : 'false'}
                  key={ix}
                  text={el[keys.label]}
                  onClick={e => handleSelect(e, el[keys.label], el[keys.value])}
                />
              );
            })
          : null}
      </Options>
    </StyledDropdwon>
  );
};

Select.propTypes = {
  error: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  keys: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Select;
